import React from 'react';
import {
  useQuery,
  queries,
  useMutation,
  mutations,
  OnboardingQueryData,
  UpdateUserResponse,
  UpdateUserVars,
} from '@data';
import { OnboardingFormBlueprint } from '@blueprints';
import { routes } from '@navigate';
import { FormConfig } from '@app/forms';
import { fields } from '@app/config';
import { useCopy } from '@app/utils';

interface SpouseIncomeValues {
  spouseIncome: number;
}

const SpouseIncome = ({ handleNext }) => {
  const { c } = useCopy('catch.onboarding.spouseIncome');
  const { loading, data } = useQuery<OnboardingQueryData>(queries.ONBOARDING, {
    fetchPolicy: 'cache-first', // @todo: this should really be the default regardless
  });

  const [update, { loading: submitting }] = useMutation<UpdateUserResponse, UpdateUserVars>(
    mutations.UPDATE_USER,
  );

  const formConfig: FormConfig<SpouseIncomeValues> = {
    initialValues: {
      spouseIncome: data?.viewer?.user?.spouseIncome,
    },
    fields: [fields.SPOUSE_INCOME],
    onSubmit: (values) => {
      update({
        variables: { user: { spouseIncome: values.spouseIncome } },
        optimisticResponse: {
          updateUserNew: {
            user: {
              ...data?.viewer?.user,
              id: data?.viewer?.user?.id || '',
              spouseIncome: values.spouseIncome,
              __typename: 'User',
            },
          },
        },
      });

      handleNext();
    },
  };

  return (
    <OnboardingFormBlueprint
      loading={loading}
      disabled={submitting}
      title={c('title')}
      subtitles={[c('subtitle')]}
      formConfig={formConfig}
    />
  );
};

export const SpouseIncomeView = {
  name: routes.SPOUSE_INCOME,
  component: SpouseIncome,
  options: OnboardingFormBlueprint.options,
};

export const GoalSpouseIncomeView = {
  name: routes.GOAL_SPOUSE_INCOME,
  component: SpouseIncome,
  options: OnboardingFormBlueprint.options,
};
