import React from 'react';
import { ComplexRow, FormLabel, IconSvg, LineItem, OptionGroup, Text } from '@uikit';
import { PickerField } from '@app/forms_old/inputs';
import { Option } from '@types';
import { InputProps } from '../types';
import { Stack } from '@app/layouts';

import Close from '@svg/close.svg';

interface MultiSelectInputProps<T> extends InputProps<T> {
  options: Array<Option>;
  style?: 'row' | 'option' | 'picker';
}

const adjustSelectedValues = (value: any, currentValues?: Array<any>) => {
  const selected = new Set(currentValues);

  // either add or remove it
  if (selected.has(value)) {
    selected.delete(value);
  } else {
    selected.add(value);
  }

  // return the updated set
  return [...selected];
};

const MultiSelectInput = <T extends any>({
  testID,
  name,
  value,
  label,
  sublabel,
  optional,
  help,
  onChange,
  onBlur,
  disabled,
  options,
  error,
  style,
}: MultiSelectInputProps<T>) => {
  if (style === 'row') {
    return (
      <Stack separatorComponent>
        {options.map((option) => (
          <ComplexRow
            key={option.value}
            testID={`${testID || name}-${option?.value}`}
            asset={{ svg: option?.svg }}
            label={option.label}
            sublabel={option?.sublabel}
            interaction="select"
            selectedByDefault={value?.includes(option.value)}
            onPress={(val) => {
              onChange(adjustSelectedValues(option.value, value));
              onBlur();
            }}
          />
        ))}
      </Stack>
    );
  }

  if (style === 'picker') {
    return (
      <>
        <FormLabel
          testID={testID}
          label={label}
          sublabel={sublabel}
          help={help}
          optional={optional}
        />
        <PickerField
          testID={testID}
          name={name}
          value={null}
          options={options}
          disabled={disabled}
          placeholder="Select one"
          onChange={(val) => {
            onChange(adjustSelectedValues(val, value));
            onBlur(); // we blur right away to trigger validation on change
          }}
          onBlur={onBlur}
          error={error}
          fullWidth
          grouped
        />
        <Stack spacing="1" separatorComponent topSpace>
          {options
            ?.filter((o) => value?.includes(o.value))
            ?.map((o) => (
              <LineItem
                key={o.value}
                left={o.label}
                right={
                  <IconSvg
                    size="xs"
                    color="subtle"
                    svgComponent={Close}
                    onPress={() => onChange(adjustSelectedValues(o.value, value))}
                  />
                }
              />
            ))}
        </Stack>

        {error ? (
          <Text color="error" size="fp">
            {error}
          </Text>
        ) : null}
      </>
    );
  }
  return (
    <>
      <OptionGroup
        small
        label={label}
        sublabel={sublabel}
        testID={testID}
        optional={optional}
        disabled={disabled}
        help={help}
        name={name}
        multi
        input={undefined}
        options={options}
        selectedOptions={value}
        onPress={(val) => {
          onChange(adjustSelectedValues(val, value));
          onBlur();
        }}
      />
      {error ? (
        <Text color="error" size="fp">
          {error}
        </Text>
      ) : null}
    </>
  );
};

export default MultiSelectInput;
