import React, { useMemo } from 'react';

import {
  useQuery,
  HealthApplicationQueryData,
  queries,
  useMutation,
  mutations,
  UpsertHealthApplicationVars,
  UpsertHealthApplicationResponse,
  UpsertApplicationMembersVars,
  UpsertApplicationMembersResponse,
  updates,
} from '@data';
import { optionPresets } from '@f';
import { generateMembers, boolToTri, useCopy, calcPovertyLine } from '@app/utils';
import { Link } from '@uikit';
import { SplitFormBlueprint } from '@blueprints';

const Household = ({ isGA, applicationID, handleNext }) => {
  const { c, $ } = useCopy('catch.ede');

  const { loading, data } = useQuery<HealthApplicationQueryData>(queries.HEALTH_APPLICATION, {
    variables: { applicationID },
    skip: !applicationID,
  });

  const [updateApplication, { loading: updatingApplication }] = useMutation<
    UpsertHealthApplicationResponse,
    UpsertHealthApplicationVars
  >(mutations.UPSERT_HEALTH_APPLICATION);

  const [updateMembers, { loading: updatingMembers }] = useMutation<
    UpsertApplicationMembersResponse,
    UpsertApplicationMembersVars
  >(mutations.UPSERT_APPLICATION_MEMBERS, {
    update: updates.UPDATE_APPLICATION_MEMBERS,
  });

  const {
    // applicationID,
    coverageState,
    coverageYear,
    maritalStatus,
    numTaxDependents,
    isSelfCoverage,
    isSpouseCoverage,
    numDependentsRequestCoverage,
    householdIncome,
  } = useMemo(() => {
    const application = data?.viewerTwo.health.application;

    const normalizedStatus = /MARRIED/.test(data?.viewer.user.filingStatus || '')
      ? 'MARRIED'
      : 'SINGLE';

    return {
      applicationID: application?.id,
      coverageState: application?.coverageState,
      coverageYear: application?.coverageYearNumber,
      maritalStatus: application?.maritalStatus || normalizedStatus,
      numTaxDependents: application?.numTaxDependents || data?.viewer.user.numTaxDependents,
      isSelfCoverage: application?.isSelfCoverage,
      isSpouseCoverage: application?.isSpouseCoverage,
      numDependentsRequestCoverage: application?.numDependentsRequestCoverage,
      householdIncome: application?.householdIncome,
    };
  }, [data?.viewerTwo.health.application, data?.viewer.user]);

  const handleSubmit = async (values) => {
    await Promise.all([
      updateApplication({
        variables: {
          input: {
            id: applicationID || '',
            numTaxDependents: values.numTaxDependents,
            maritalStatus: values.maritalStatus,
            householdIncome: Number(values.householdIncome),
            isSelfCoverage: boolToTri(values.isSelfCoverage || false),
            isSpouseCoverage: boolToTri(values.isSpouseCoverage || false),
            numDependentsRequestCoverage: Number(values.numDependentsRequestCoverage || 0),
          },
        },
      }),

      // handle creating members
      updateMembers({
        variables: {
          input: {
            applicationID,
            applicationMembersInput: generateMembers({
              applicant: data?.viewerTwo.health.application.applicant,
              members: data?.viewerTwo.health.application.members,
              numTaxDependents: values.numTaxDependents,
              maritalStatus: values.maritalStatus,
              isSelfCoverage: boolToTri(values.isSelfCoverage || false),
              isSpouseCoverage: boolToTri(values.isSpouseCoverage || false),
              numDependentsRequestCoverage: Number(values.numDependentsRequestCoverage || 0),
            }),
          },
        },
      }),
    ]);

    handleNext();
  };

  const fields = [
    {
      testID: 'S1',
      name: 'maritalStatus',
      required: true,
      type: 'option',
      options: optionPresets.MARITAL_STATUS,
      label: c('HealthEnrollment.S1_Q'),
      help: c('HealthEnrollment.S1_T'),
    },
    {
      testID: 'S2',
      name: 'numTaxDependents',
      required: true,
      type: 'stepper',
      headerText: c('HealthEnrollment.S2_Q', { coverageYear }),
      label: c('HealthEnrollment.S2_L'),
      help: c('HealthEnrollment.S2_T', {
        link1: <Link>{c('HealthEnrollment.S2_T_link1')}</Link>,
        link2: <Link>{c('HealthEnrollment.S2_T_link2')}</Link>,
        coverageYear,
        coverageYearNext: coverageYear ? coverageYear + 1 : '',
      }),
    },
    {
      type: 'section',
      fields: [
        {
          testID: 'S3',
          name: 'S3',
          type: 'label',
          label: c('HealthEnrollment.S3_L'),
          help: c('HealthEnrollment.S3_T', {
            link: (
              <Link key="link" href="https://healthcare.gov" target="_blank" inline small>
                {c('HealthEnrollment.S3_H')}
              </Link>
            ),
          }),
        },
        {
          name: 'isSelfCoverage',
          type: 'checkbox',
          label: 'Me',
        },
        {
          name: 'isSpouseCoverage',
          dependencies: ['maritalStatus'],
          display: ({ maritalStatus }) => maritalStatus === 'MARRIED',
          type: 'checkbox',
          label: 'My spouse',
        },
        {
          name: 'numDependentsRequestCoverage',
          type: 'stepper',
          inline: true,
          dependencies: ['numTaxDependents'],
          display: ({ numTaxDependents }) => numTaxDependents && numTaxDependents > 0,
          max: ({ numTaxDependents }) => numTaxDependents,
          label: 'My dependents',
        },
      ],
    },
    {
      testID: 'S4',
      name: 'householdIncome',
      type: 'option',
      label: ({ maritalStatus, numTaxDependents }) => {
        // set defaults
        const { fpls420 } = calcPovertyLine({
          maritalStatus: maritalStatus || 'SINGLE',
          numTaxDependents: numTaxDependents || 0,
          state: coverageState,
        });

        return c('HealthEnrollment.S4_Q', { amount: $(fpls420, { whole: true }) });
      },
      dependencies: ['maritalStatus', 'numTaxDependents'],
      options: ({ maritalStatus, numTaxDependents }) => {
        const { fpls420 } = calcPovertyLine({
          maritalStatus: maritalStatus || 'SINGLE',
          numTaxDependents: numTaxDependents || 0,
          state: coverageState,
        });

        return [
          {
            key: 'yes',
            label: 'Yes',
            value: householdIncome && householdIncome < fpls420 ? householdIncome : fpls420 - 1000,
          },
          {
            key: 'no',
            label: 'No',
            value: householdIncome && householdIncome > fpls420 ? householdIncome : fpls420 + 1000,
          },
          { key: 'notSure', label: 'Not sure', value: 0 },
        ];
      },
    },
  ];

  return (
    <SplitFormBlueprint
      loading={!data}
      submitting={updatingApplication || updatingMembers}
      title={c('Household.title')}
      subtitle={c('Household.subtitle')}
      formConfig={{
        initialValues: {
          maritalStatus,
          numTaxDependents,
          isSelfCoverage: true,
          isSpouseCoverage,
          numDependentsRequestCoverage,
          householdIncome: householdIncome > 0 ? householdIncome : undefined,
        },
        fields,
        onSubmit: handleSubmit,
      }}
    />
  );
};

export const HouseholdView = {
  name: 'EDE_HOUSEHOLD',
  component: Household,
  options: {
    ...SplitFormBlueprint.options,
    title: 'Household size',
  },
};
