import React, { useState, useEffect, useMemo, useCallback } from 'react';

import { OnboardingFormBlueprint } from '@blueprints';
import { useQuery, queries, RetirementQueryData } from '@data';
import { FormConfig } from '@app/forms';
import { fields } from '@app/config';
import { RetirementAccountTypes } from '@types';
import { useCopy, MIN_AGE_SEP_IRA } from '@util';
import { routes } from '@navigate';
import { RetirementAccountTypeSheet } from '@sheets';

interface RetirementAccountValues {
  accountType: RetirementAccountTypes;
}

export const RetirementAccountSelection = ({ handleNext, updateRetirement, submitting }) => {
  const { c } = useCopy('catch.module.retirement.AccountSelectionView');

  const [selectedAccount, setSelectedAccount] = useState<RetirementAccountTypes>();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { loading, data } = useQuery<RetirementQueryData>(queries.RETIREMENT, {
    fetchPolicy: 'cache-first',
  });

  const accountType = useMemo(() => data?.viewer?.goal?.accountType, [data]);
  const isUnder21 = useMemo(
    () => !!data?.viewer?.user?.age && data?.viewer?.user?.age < MIN_AGE_SEP_IRA,
    [data],
  );

  useEffect(() => {
    if (!loading && !!accountType) setSelectedAccount(accountType);
  }, [loading, accountType]);

  const handleSelect = useCallback((accountType: RetirementAccountTypes) => {
    setSelectedAccount(accountType);
    setIsOpen(true);
  }, []);

  const handleSubmit = useCallback(
    (accountType: RetirementAccountTypes) => {
      updateRetirement({ accountType });
      handleNext();
      setTimeout(function () {
        setIsOpen(false);
      }, 500);
    },
    [handleNext],
  );

  const formConfig: FormConfig<RetirementAccountValues> = {
    initialValues: {
      accountType: accountType !== 'UNKNOWN_WEALTH_ACCOUNT_TYPE' ? accountType : 'ROTH_IRA',
    },
    autoSubmit: false,
    fields: [
      Object.assign(
        isUnder21 ? fields.RETIREMENT_ACCOUNTS_UNDER_21 : fields.RETIREMENT_ACCOUNTS_OVER_21,
        {
          onPress: (accountType) => {
            handleSelect(accountType);
          },
        },
      ),
    ],
    onSubmit: () => {},
  };

  return (
    <OnboardingFormBlueprint
      loading={loading}
      disabled={submitting}
      title={c('title')}
      subtitles={[c('subtitle')]}
      formConfig={formConfig}
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
      modalContent={
        <RetirementAccountTypeSheet
          type={selectedAccount}
          onSubmit={() => handleSubmit(selectedAccount)}
        />
      }
    />
  );
};

export const RetirementAccountSelectionView = {
  name: routes.RETIREMENT_ACCOUNT_SELECTION,
  component: RetirementAccountSelection,
  options: OnboardingFormBlueprint.options,
};
