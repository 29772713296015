import { getYear, currentYear, nextYear, lastYear, getIndexOfNextDate, getToday } from '../time';

/**
 * @note: Q0 is used to denote Q4 of the previous year
 */

// start dates for each quarter
export const QUARTER_STARTS = [
  new Date(lastYear, 8, 1), // Sep. 1 (previous year)
  new Date(currentYear, 0, 1), // Jan. 1
  new Date(currentYear, 3, 1), // Apr. 1
  new Date(currentYear, 5, 1), // Jun. 1
  new Date(currentYear, 8, 1), // Sep. 1
];

// end dates for each quarter
export const QUARTER_ENDS = [
  new Date(lastYear, 11, 31), // Dec 31. (previous year)
  new Date(currentYear, 2, 31), // Mar. 31
  new Date(currentYear, 4, 31), // May 31
  new Date(currentYear, 7, 31), // Aug. 31
  new Date(currentYear, 11, 31), // Dec. 31
];

// estimated taxes due dates for each quarter. We use the Abound payment dates:
// https://docs.withabound.com/docs/2022-tax-deadlines
export const DUE_DATES = [
  new Date(currentYear, 0, 11, 21), // Jan. 11 at 4pm EST (21 UTC)
  new Date(currentYear, 3, 13, 20), // Apr. 13 at 4pm EST (20 UTC)
  new Date(currentYear, 5, 10, 20), // Jun. 10 at 4pm EST (20 UTC)
  new Date(currentYear, 8, 12, 20), // Sept. 12 at 4pm EST (20 UTC)
  new Date(nextYear, 0, 11, 21), // Jan. 11 (next year) at 4pm EST (21 UTC)
].map((date) => {
  // Move to following monday if day lands on weekend
  if (date.getDay() === 6) {
    // Saturday
    date.setDate(date.getDate() + 2);
  } else if (date.getDay() === 0) {
    // Sunday
    date.setDate(date.getDate() + 1);
  }

  // Handle possibly conflicting holidays:

  // Stationary holidays:
  // Special case April 16th (Emancipation day) being on Saturday, therefore observed on Friday, and pushing Friday tax day to Monday.
  const emancipationDay = new Date(date.getFullYear(), 3, 16, 20);
  if (emancipationDay.getDay() === 6 && date.getMonth() === emancipationDay.getMonth()) {
    date.setDate(date.getDate() + 3);
  }

  // Moving holidays:

  // MLK Day is on the 3rd Monday of January.
  if (date.getMonth() === 0 && date.getDay() === 1 && Math.ceil(date.getDate() / 7) === 3) {
    date.setDate(date.getDate() + 1);
  }

  return date;
});

const getQuarter = (num) => ({
  quarterNum: num === 0 ? 4 : num,
  taxYear: getYear(QUARTER_STARTS[num]),
  start: QUARTER_STARTS[num],
  end: QUARTER_ENDS[num],
  due: DUE_DATES[num],
});

const datesByType = {
  start: QUARTER_STARTS,
  end: QUARTER_ENDS,
  due: DUE_DATES,
};

/**
 * Given a type of date (start, end, due), finds the next date
 * i.e. if its currently Jan 3, the next quarter start would be Apr 1
 * returns the date object for that quarter (start end and due dates)
 */
export const getNextQuarterAfterDate = (date, dateType) => {
  const dates = datesByType[dateType];

  // find the index for the next date
  const index = getIndexOfNextDate(date, dates);

  // return the quarter dates (start, end, due) for that index
  return getQuarter(index);
};

/**
 * returns progress to the end of the quarter, between 0 and 1
 * @author @aa-io for Tax Tab Feb 2022
 */
export const getQuarterProgress = () => {
  const today = getToday();
  const index = getIndexOfNextDate(today, DUE_DATES);
  const { start, end } = getQuarter(index < 0 ? 4 : index);

  return (today - start) / (end - start);
};

/**
 * Returns the current quarter
 */
export const getCurrentQuarter = () => {
  const today = getToday();
  const index = getIndexOfNextDate(today, QUARTER_STARTS);
  if (index < 0) return 4;
  return index - 1;
};

export const quarters = {
  0: getQuarter(0),
  1: getQuarter(1),
  2: getQuarter(2),
  3: getQuarter(3),
  4: getQuarter(4),
};
