import React from 'react';
import { View } from 'react-native';
import shallow from 'zustand/shallow';

import { EmptyState, Button, useTheme } from '@uikit';
import { useCopy } from '@app/utils';
import { useActivity } from '@hooks';
import { useSheet, sheets } from '@app/navigate';
import ActivityEvent from '../components/ActivityEvent';

import Clock from '@svg/clock.svg';

const Activity = ({
  goalID = null,
  enrollmentID = null,
  pageSize = 20,
  showMore = true,
  loading: parentLoading,
  eventTypes,
  accentColor = 'brandLight',
  sourceID = null,
}) => {
  const { theme } = useTheme();
  const { c: c1 } = useCopy('catch.Activity');
  const { c: c2 } = useCopy('catch.basics');

  const { openSheet } = useSheet((state) => ({ openSheet: state.open }), shallow);

  const { events, initialLoading, loadingMore, fetchMore, canFetchMore } = useActivity({
    goalID,
    enrollmentID,
    pageSize,
    eventTypes,
    sourceID,
  });

  const anyLoading = initialLoading || parentLoading;

  if (anyLoading || events?.length === 0) {
    return <EmptyState loading={anyLoading} icon={Clock} title={c1('emptyState')} />;
  }

  return (
    <>
      {events.map((event, i) => (
        <ActivityEvent
          accentColor={accentColor}
          key={event?.id || i}
          event={event}
          isLast={i === events.length - 1}
          actions={{
            showReceipt: (event) => openSheet(sheets.EVENT_RECEIPT, { event }),

            // @todo E-3417
            showScheduled: (paycheck) => {},
            // open(routes.PAYCHECK_SCHEDULED, { paycheckId: paycheck.id }),
          }}
        />
      ))}
      {events?.length > 0 && !!showMore && canFetchMore && (
        <View style={[theme.alignCenter, theme.topGutter2]}>
          <Button loading={loadingMore} accentColor={accentColor} onPress={fetchMore} small alt>
            {c2('viewMore')}
          </Button>
        </View>
      )}
    </>
  );
};

export default Activity;
