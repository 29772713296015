import React, { useMemo } from 'react';

import { Copy } from '@types';
import { FormConfig, Fields, useForm, FormValues } from '@f';
import { BasicLayout, Toolbar } from '@layouts';
import { Button, Modal, useResponsive } from '@uikit';
import { useCopy } from '@app/utils';
import { pop } from '@navigate';

interface OnboardingFormBlueprintProps<TFormValues> {
  title: Copy;
  subtitles?: Array<Copy>;
  loading: boolean;
  disabled: boolean;
  formConfig: FormConfig<TFormValues>;
  showLabels?: boolean; // by default, they are hidden so allow this to be overridden
  isOpen?: boolean;
  onRequestClose?: () => void;
  toolbar?: React.FunctionComponent | Function;
  children?: React.FunctionComponent | Function;
  modalContent?: React.FunctionComponent;
  hideBack?: boolean;
}

//used for onboarding screens with forms
const OnboardingFormBlueprint = <TFormValues extends Record<string, any> = Record<string, any>>({
  title,
  subtitles,
  loading,
  lastScreen,
  disabled,
  formConfig,
  showLabels,
  modalContent,
  isOpen,
  onRequestClose = () => {},
  toolbar,
  children,
  hideBack,
}: OnboardingFormBlueprintProps<TFormValues>) => {
  const { c } = useCopy('catch.basics');
  const { isDesktop } = useResponsive();

  const initialValues = formConfig?.initialValues || {};
  const form = useForm<TFormValues>({
    loading,
    disabled,
    initialValues,
    fields: formConfig?.fields,
    hideLabels: !showLabels,
    autoSubmit: formConfig?.autoSubmit,
    onSubmit: formConfig?.onSubmit,
  });

  const tb = useMemo(() => {
    if (toolbar) {
      return (
        <FormValues dependencies={formConfig?.watch} control={form.methods.control}>
          {(values) =>
            toolbar({
              values,
              reset: (resetTo) => form.methods.reset(resetTo),
            })
          }
        </FormValues>
      );
    }
    return (
      !hideBack && (
        <Toolbar onBack={!hideBack ? pop : undefined} hasGradient={!isDesktop}>
          {form.isVisibleSubmitButton ? (
            <Button
              inherit
              testID="next-btn"
              onPress={form.submitForm}
              disabled={form.disableSubmit}
            >
              {lastScreen ? c('confirm') : c('next')}
            </Button>
          ) : null}
        </Toolbar>
      )
    );
  }, [toolbar, form, formConfig, hideBack]);

  return (
    // @ts-ignore
    <BasicLayout loading={loading} title={title} subtitles={subtitles} toolbar={tb}>
      <Fields form={form} fields={formConfig.fields} />
      {React.Children.toArray(children).map((component) => {
        return typeof component.props.children === 'function'
          ? !!formConfig?.watch && (
              <FormValues dependencies={formConfig?.watch} control={form.methods.control}>
                {(values) =>
                  component.props.children({
                    values,
                    reset: (resetTo) => form.methods.reset(resetTo),
                  })
                }
              </FormValues>
            )
          : component;
      })}
      {!!modalContent && (
        <Modal isOpen={!!isOpen} onRequestClose={onRequestClose} close>
          {modalContent}
        </Modal>
      )}
    </BasicLayout>
  );
};

OnboardingFormBlueprint.options = {};

export default OnboardingFormBlueprint;
