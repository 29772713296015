import React from 'react';
import { Field, Form } from 'react-final-form';

import { navigate } from '@navigate';
import { useCopy, copy } from '@app/utils';
import { useDeprecatedQuery } from '@data';
import { Layout, Toolbar } from '@layouts';

import { Loading, Button } from '@uikit';
import { formatCurrency, normalizeCurrency } from '@app/utils/format/currency';
import { defaults } from '@app/config/defaults';
import { TextField } from '@app/forms_old/inputs';

const options = {
  amount: {
    placeholder: '$0.00',
    format: formatCurrency,
    parse: (val) => normalizeCurrency(val),
  },
  amountInt: {
    placeholder: '$0',
    format: formatCurrency,
    parse: (val) => parseInt(normalizeCurrency(val)),
  },
};

const untaxedStates = /AK|FL|NV|SD|TN|TX|WA|WY|NH/;

const TaxPaymentAmount = ({ loading }) => {
  const { c, $ } = useCopy('catch.payments.taxPayments');
  const { c: basics } = useCopy('catch.basics');

  const { goalBalances, taxGoalID } = useDeprecatedQuery('goalBalances');
  const { fullName, workState, homeState } = useDeprecatedQuery('user');
  const balance = goalBalances?.TAX;

  var _jurisdictions = ['US'];
  if (!!workState && !untaxedStates.test(workState)) _jurisdictions.push(workState);
  if (!!homeState && homeState !== workState && !untaxedStates.test(homeState))
    _jurisdictions.push(homeState);

  const onSubmit = (values) =>
    navigate('TAX_PAYMENT_CONFIRM', {
      fullName,
      amount:
        values?.jurisdiction === 'US'
          ? parseFloat(values?.taxPayment)
          : parseInt(values?.taxPayment),
      jurisdiction: values?.jurisdiction,
      signatureText: values?.signatureText,
      taxGoalID,
    });

  if (loading) return <Loading accentColor="taxes" />;

  return (
    <Form
      onSubmit={onSubmit}
      subscription={{ values: true, invalid: true }}
      initialValues={{ taxPayment: 0, jurisdiction: 'US' }}
    >
      {({ handleSubmit, values, invalid }) => {
        const inputAmount = parseFloat(values?.taxPayment);
        const validBalance = inputAmount <= balance;
        const validMinimum = inputAmount >= defaults.limits.TAX_PAYMENT_MIN;
        const valid = validBalance && validMinimum;
        const invalidAmount = isNaN(inputAmount) || inputAmount <= 0;

        return (
          <>
            <Layout.Scroll>
              <Layout margins>
                <Layout.Header
                  title={c('title')}
                  titleSize="form"
                  subtitle={`${$(balance)} available`}
                />
              </Layout>
              <Layout topSpace bottomSpace margins>
                <Field
                  allowNull={false}
                  name={'taxPayment'}
                  component={TextField}
                  keyboardType="decimal-pad"
                  label={c('amountLabel')}
                  returnKeyType="next"
                  defaultValue={values?.taxPayment}
                  autoFocus
                  placeholder={options['amount'].placeholder}
                  format={options['amount'].format}
                  parse={
                    values?.jurisdiction === 'US'
                      ? options['amount'].parse
                      : options['amountInt'].parse
                  }
                  validate={() =>
                    !!valid
                      ? null
                      : !validBalance
                      ? c('error')
                      : !validMinimum
                      ? c('errorMin')
                      : c('error')
                  }
                  xl
                  fullWidth
                />
              </Layout>
            </Layout.Scroll>
            <Toolbar type="stacked">
              <Button inherit onPress={handleSubmit} disabled={!valid || invalidAmount || invalid}>
                {basics('continue')}
              </Button>
            </Toolbar>
          </>
        );
      }}
    </Form>
  );
};

export const TaxPaymentAmountView = {
  name: 'TAX_PAYMENT_AMOUNT',
  component: TaxPaymentAmount,
  options: {
    title: ({ quarterNum, taxYear }) =>
      copy.format(`catch.payments.taxPayments.navTitle`, {
        quarterNum,
        taxYear,
      }),
  },
};
