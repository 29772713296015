import { useEffect } from 'react';
import { Platform } from 'react-native';
import { useDeprecatedMutation } from '@data';
import { setupNotifications } from '@app/utils';

const Notifications = () => {
  const [add] = useDeprecatedMutation('addPushToken');
  useEffect(() => {
    setupNotifications({
      onRegister: (token) => add({ variables: { token, deviceType: Platform.OS.toUpperCase() } }),
    });
  }, []);
  return null;
};

export default Notifications;
