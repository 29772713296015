// @ts-nocheck
import React, { useMemo } from 'react';
import { Loading, stateIcons } from '@uikit';
import { HiccupBlueprint } from '@blueprints';
import { useQuery, queries, HealthExplorerQueryData, useDeprecatedQuery } from '@data';
import { useHealthHandoff } from '@hooks';
import { stateExchanges, useCopy } from '@app/utils';
import { navigate, routes, ScreenDefinition } from '@navigate';

const ExplorerStateHandoff = ({ onNext }) => {
  const { c } = useCopy('catch.healthHandoff');

  const { data } = useQuery<HealthExplorerQueryData>(queries.HEALTH_EXPLORER, {
    fetchPolicy: 'cache-first',
  });

  const state = useMemo(() => {
    return data?.viewerTwo?.healthExplorerData?.state;
  }, [data?.viewerTwo?.healthExplorerData]);

  const { url, loading } = useDeprecatedQuery('stateHealthReference', {
    variables: { state },
    skip: !state,
    fetchPolicy: 'cache-first',
  });

  const { handoff } = useHealthHandoff({
    handoffType: 'STATE_EXCHANGE',
    options: {
      externalUrl: url,
      onHandoff: () => (!!onNext ? onNext() : navigate(routes.STATE_RETURN)),
    }
  });

  // adding this here because otherwise its jumpy
  if (loading || !state) {
    return <Loading />;
  }

  return (
    <HiccupBlueprint
      loading={!state || loading}
      align="center"
      asset={{ icon: stateIcons[state], color: 'text' }}
      title={c('title')}
      subtitles={[
        c('stateSubtitle'),
        !state
          ? null
          : c('exchangeSubtitle', {
            stateExchangeName: stateExchanges[state]?.name || 'your state exchange',
          }),
      ]}
      onPress={handoff}
      buttonText={c('button')}
    />
  );
};

export const ExplorerStateHandoffView: ScreenDefinition = {
  name: routes.EXPLORER_STATE_HANDOFF,
  component: ExplorerStateHandoff,
  options: {},
};
