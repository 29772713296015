import React from 'react';
import { ViewStyle } from 'react-native';

import { AssetSize } from '@types';
import { Gradient, UIGradientName, CatchTheme } from '@uikit';

interface IconGradientProps {
  gradient: UIGradientName;
  iconSize: AssetSize;
  children: React.ReactElement;
  style?: ViewStyle | Array<ViewStyle>;
}

//creates a gradient background around an icon
const IconGradient = ({ gradient, iconSize, children, style }: IconGradientProps) => {
  const bgSize = CatchTheme.assets[iconSize] + 40;
  const size = {
    height: bgSize,
    width: bgSize,
    borderRadius: bgSize / 2,
  };

  return (
    <Gradient
      gradient={gradient}
      style={[size, { display: 'flex', alignItems: 'center', justifyContent: 'center' }, style]}
    >
      {children}
    </Gradient>
  );
};

export default IconGradient;
