import React from 'react';
import { View } from 'react-native';
import { useLocation } from 'react-router-dom';

import { useCopy } from '@app/utils';
import { TAB_NAMES, navigate, checkActiveTab } from '@navigate';
import { Layout } from '@layouts';
import { useAuth } from '@client/Auth';
import { Text, useTheme, Pressable } from '@uikit';
import { NEW_TAB_NAMES } from '@app/navigate/config/tabs';

const NavMenu = ({ background, onClose }) => {
  const { signOut } = useAuth();
  const { theme, themeColors } = useTheme();
  const { c } = useCopy('catch.module.me');
  const { pathname } = useLocation();

  const Item = ({ tab, onPress, copy }) => (
    <Pressable
      handleOnPress={() => {
        if (tab) navigate(tab);
        if (onPress) onPress();
        onClose();
      }}
      testID="tab-menu"
    >
      <View style={[theme.bottomSpace1, theme.topSpace1, theme.alignCenter, theme.row]}>
        <Text
          align="left"
          color={tab === 'SIGNOUT' ? 'credit' : checkActiveTab(tab, pathname) ? 'text' : 'subtle'}
          weight="medium"
          size="h4"
          gutter="none"
        >
          {copy ? copy : NEW_TAB_NAMES[tab]}
        </Text>
      </View>
    </Pressable>
  );

  return (
    <Layout
      topSpace
      bottomSpace
      margins
      style={[
        {
          backgroundColor: background || themeColors.pageColor,
          marginTop: -200, // so that acrylic gets applied above nav as well
          paddingTop: 200, // so that acrylic gets applied above nav as well
          position: 'absolute',
          zIndex: 999, // should be below the navbar
          top: 64,
          left: 0,
          right: 0,
        },
      ]}
    >
      {TAB_NAMES.map((tab) => (
        <Item key={tab} tab={tab} />
      ))}
      <Item tab="ACCOUNT" copy={c('AccountButton')} />
      <Item onPress={signOut} copy={c('SignOutButton')} />
    </Layout>
  );
};

export default NavMenu;
