import gql from 'graphql-tag';
import { isPast } from 'date-fns';

import {
  createLogger,
  fromDays,
  isWithin30Days,
  getSviDocTypes,
  getDmiDocTypes,
  getDmiDocSubTypes,
} from '@app/utils';

const Log = createLogger('application-issues');

export const APPLICATION_ISSUES = gql`
  query ApplicationIssues($enrollId: ID!) {
    viewer {
      id
      health {
        enrollment(id: $enrollId) {
          id
          ... on HealthInsuranceEnrollment {
            id
            status
            pathwayType
            application {
              id
              asyncStatus
              aptcHouseholdToApply
              aptcHouseholdAmount
              dmiNotices {
                id
                name
                dsrsIdentifier
              }
              dmis {
                id
                status
                statusCodes
                subType
                verificationType
                resolutionStatus
                resolveBy
                lastReviewed
                dependentID
              }

              svis {
                id
                status
                statusCode
                resolveBy
                lastReviewed
                generatedPhysicalDocuments {
                  id
                  name
                  dsrsIdentifier
                }
                dependentIDs
              }
              dmiDocumentUploads {
                id
                url
                type
                issueID
                createdOn
                key
              }
              sviDocumentUploads {
                id
                url
                type
                issueID
                createdOn
                key
              }
            }
            members {
              id
              givenName
              familyName
            }
          }
        }
      }
    }
  }
`;

const formatter = (data) => {
  const members = data?.viewer?.health?.enrollment?.members?.reduce(
    (acc, item) => ({ ...acc, [item.id]: item }),
    {},
  );
  const dmiDocs = data?.viewer?.health?.enrollment?.application?.dmiDocumentUploads || [];
  const dmiNotices = data?.viewer?.health?.enrollment?.application?.dmiNotices || [];
  const sviDocs = data?.viewer?.health?.enrollment?.application?.sviDocumentUploads || [];
  const docReducer = (issue) => (acc, doc) => doc.issueID === issue.id ? [...acc, doc] : acc;

  // DMI TODOs will be grouped together as they should have the same date
  const dmis =
    data?.viewer?.health?.enrollment?.application?.dmis
      ?.map((issue) => ({
        members: [members[issue.dependentID]] || [],
        issueType: 'dmi',
        uploadedDocs: dmiDocs.reduce(docReducer(issue), []),
        docOptions: getDmiDocTypes(issue.verificationType, issue.subType),
        subDocOptions: getDmiDocSubTypes(issue.verificationType),
        notices: dmiNotices,
        ...issue,
      }))
      ?.sort((a, b) => new Date(b.lastReviewed) - new Date(a.lastReviewed)) || [];

  // We filter the issues for their status and whether there's already any document uploaded
  // the deadline has not past on the resolveBy date
  const dmiTodos = dmis?.filter(
    (issue) =>
      /ACTION_NEEDED/.test(issue?.status) &&
      issue?.uploadedDocs?.length === 0 &&
      !isPast(new Date(issue.resolveBy)),
  );

  // SVI TODOs will have higher priority
  const svis =
    data?.viewer?.health?.enrollment?.application?.svis
      ?.map((issue) => ({
        members: issue?.dependentIDs?.map((dID) => members[dID]) || [],
        issueType: 'svi',
        uploadedDocs: sviDocs.reduce(docReducer(issue), []),
        docOptions: getSviDocTypes(issue.statusCode),
        notices: issue?.generatedPhysicalDocuments,
        ...issue,
      }))
      ?.sort((a, b) => new Date(b.lastReviewed) - new Date(a.lastReviewed)) || [];

  // We filter the issues for their status and whether there's already any document uploaded
  // the deadline has not past on the resolveBy date
  const sviTodos = svis?.filter(
    (issue) =>
      /ACTION_NEEDED/.test(issue.status) &&
      issue?.uploadedDocs?.length === 0 &&
      !isPast(new Date(issue.resolveBy)),
  );

  const { uploaded, healthDocs, actionRequired, expired } = svis?.concat(dmis).reduce(
    (acc, issue) => {
      const uploaded = [...acc.uploaded];
      const healthDocs = [...acc.healthDocs];
      const actionRequired = [...acc.actionRequired];
      const expired = [...acc.expired];
      // As soon as the user upload a doc, we mark it as 'PROCESSING' until EDE changes their status to complete
      if (/COMPLETE|PROCESSING/.test(issue.status) || issue?.uploadedDocs?.length !== 0) {
        const uploadedIssue = {
          ...issue,
          status: issue.status === 'COMPLETE' ? issue.status : 'PROCESSING',
        };
        uploaded.push(uploadedIssue);
        healthDocs.push(uploadedIssue);
      } else if (!!issue.resolveBy && !isPast(new Date(issue.resolveBy))) {
        healthDocs.push(issue);
        actionRequired.push(issue);
      }

      return { uploaded, healthDocs, actionRequired, expired };
    },
    { uploaded: [], expired: [], healthDocs: [], actionRequired: [] },
  );

  const dueDate = fromDays(actionRequired.find((issues) => issues.resolveBy)?.resolveBy);

  const dueBy = actionRequired.find((issues) => issues.resolveBy)?.resolveBy;

  // show aptc savings on success
  const app = data?.viewer?.health?.enrollment?.application;
  const monthlySavings = app?.aptcHouseholdToApply ?? app?.aptcHouseholdAmount;

  const slugs = healthDocs.reduce((acc, item) => {
    // TODO: handle notices
    const types = {
      PROCESSING: 'processing',
      COMPLETE: 'completed',
      EXPIRED: 'expired',
    };
    const type = types[item?.status] || (isWithin30Days(item?.resolveBy) ? 'urgent' : 'important');
    return {
      ...acc,
      [type]: (acc[type] || 0) + 1,
    };
  }, {});
  const applicationID = data?.viewer?.health?.enrollment?.application?.id;
  const pathwayType = data?.viewer?.health?.enrollment?.pathwayType;

  const asyncStatus = data?.viewer?.health?.enrollment?.application?.asyncStatus;
  Log.debug(healthDocs);

  return {
    pathwayType,
    asyncStatus,
    healthDocs,
    dueDate,
    dueBy,
    dmiTodos,
    sviTodos,
    uploaded,
    expired,
    actionRequired,
    slugs,
    applicationID,
    monthlySavings,
  };
};

export default {
  document: APPLICATION_ISSUES,
  formatter,
};
