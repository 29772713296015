import { Platform as NativePlatform } from 'react-native';
import { useResponsive } from '@uikit/hooks/useResponsive';

// Media is a helper that will only render its children if the current
// breakpoints is in the props
export const Media = ({ children, ...props }) => {
  const { shortRange, mediumRange, longRange } = useResponsive({});
  return children && (props[shortRange] || props[mediumRange] || props[longRange])
    ? children
    : null;
};

// Platform is a helper that only render its children if the current
// platform is in the props
export const Platform = ({ children, ...props }) => {
  if (!children) return null;
  const current = NativePlatform.OS;
  if (/ios|android/.test(current) && props.native) {
    return children;
  }
  return props[current] ? children : null;
};

Platform.OS = NativePlatform.OS;
Platform.isWeb = NativePlatform.OS === 'web';
Platform.isNative = NativePlatform.OS !== 'web';
Platform.select = NativePlatform.select;
