import create from 'zustand';
import { stackLookup } from '../stacks';

export const store = create((set, get) => ({
  // consolidated:
  passProps: {},
  options: {},
  matchParams: {},
  modal: null,
  page: null,

  // current params
  params: {},
  lastUpdated: undefined,

  /**
   * handles navigation data
   * first, strips out the navigation options from other data
   * next, updates the stored data
   * returns two items: the navigation options and the consolidated data obj
   */
  handleData: (data: object = {}) => {
    // separate out navigation props
    const { showCloseButton, replaceAnimation, pushAnimation, canGoBack, ...rest } = data;

    // updates the stored params
    const existing = get().params;
    set({ params: { ...existing, ...rest } });

    // returns combined data object
    return { ...existing, ...rest };
  },

  // updated:
  optionsByScreen: {},
  optionsByStack: {},
  getOptions: (screenName) => {
    const { optionsByScreen, optionsByStack } = get();

    const screenOptions = optionsByScreen[screenName];
    const stackOptions = optionsByStack[stackLookup(screenName)];

    // join them together, with screen overriding any stack defaults
    return {
      // and other defaults for all screens, too!
      presentation: 'formSheet',

      ...stackOptions,
      ...screenOptions,
    };
  },
}));
