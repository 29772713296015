import React, { useState, useMemo } from 'react';
import { IncomeAction } from '@types';
import { useCopy } from '@app/utils';
import { useQuery, queries, IncomeTransactionQueryData } from '@data';
import { ItemTriageBlueprint } from '@blueprints';
import ConfirmationBlueprint from '@app/blueprints/ConfirmationBlueprint';

const PaycheckSkipped = ({
  paychecks,
  paycheckID,
  addRule,
  processIncome,
  processing,
  handleNext,
}) => {
  const { c } = useCopy('catch.sequences.Paycheck.skipped');
  const [completedStatus, setCompletedStatus] = useState('Done');

  const { data } = useQuery<IncomeTransactionQueryData>(queries.INCOME_TRANSACTION, {
    variables: { paycheckID },
    skip: !paycheckID,
    fetchPolicy: 'cache-first',
    returnPartialData: true, // this allows us to grab the basic data from paycheck
  });

  const source = useMemo(() => {
    return data?.viewer.incomeTransaction.source;
  }, [data]);

  const handleProcessIncome = (status: IncomeAction) => {
    setCompletedStatus(status === 'SKIPPED' ? 'Skipped' : 'Not Income');

    processIncome({
      variables: {
        input: {
          transactionID: paycheckID,
          status,
        },
      },
    });
  };

  const handleIgnoreRule = async () => {
    addRule({
      incomeSourceID: source?.id || '',
      automationType: 'NEVER',
      userHandlesActivation: true,
    });

    //update status for paychecks from same source
    await Promise.all(
      paychecks?.map((paycheck) => {
        if (paycheck?.source?.id === source?.id && paycheck?.id !== paycheckID) {
          return processIncome({
            variables: {
              input: {
                transactionID: paycheck.id,
                status: 'NOT_INCOME',
              },
            },
          });
        }
      }),
    );

    handleNext();
  };

  return (
    <ConfirmationBlueprint
      loading={processing.loading}
      called={processing.called}
      error={!!processing.error}
      titles={{ done: completedStatus }}
      onSuccess={handleNext}
      // followUp={
      //   source?.canBeRule &&
      //   completedStatus === 'Not Income' && {
      //     title: `Always ignore ${source?.text}?`,
      //     asset: { ...Asset.configureSource(source), size: 'md' },
      //     action: {
      //       label: 'Ignore',
      //       type: 'CUSTOM',
      //       onAction: handleIgnoreRule,
      //     },
      //   }
      // }
    >
      <ItemTriageBlueprint
        question={c('title')}
        paycheck={data?.viewer.incomeTransaction}
        onPress={(val) => handleProcessIncome(val)}
        options={[
          {
            key: 'skip',
            label: c('skipTitle'),
            description: c('skipSubtitle'),
            value: 'SKIPPED',
          },
          {
            key: 'not-income',
            label: c('notIncomeTitle'),
            description: c('notIncomeSubtitle'),
            value: 'NOT_INCOME',
          },
        ]}
      />
    </ConfirmationBlueprint>
  );
};

export const PaycheckSkippedView = {
  name: 'PAYCHECK_SKIPPED',
  component: PaycheckSkipped,
  options: {},
};
