import React, { useCallback, useMemo } from 'react';
import { BasicFormBlueprint } from '@blueprints';
import { pop } from '@navigate';
import { sixtyDaysAgo, useCopy } from '@util';
import { getIntroInitialValues } from './sepUtils';
import { introOptions } from './sepFields';

//just meant to filter the subsequent SEP questions we display
export const SEPIntro = ({ applicationPhase, members, handleScreener }) => {
  const { c } = useCopy('catch.ede.SEP');

  // don't show
  const options = useMemo(() => {
    return introOptions.filter(({ value }) => {
      const skip = value === 'isGainedLawfulPresence' && applicationPhase === 'ONE';
      return !skip;
    });
  }, [introOptions, applicationPhase]);

  const onSubmit = useCallback(({ sep }) => {
    const values = Object.values(options).reduce(
      (acc, opt) => ({
        ...acc,
        [opt.value]: sep?.includes(opt.value) ? 'YES' : 'NO',
      }),
      {},
    );

    handleScreener(values);
  }, []);

  const initialValues = useMemo(() => {
    return getIntroInitialValues(members);
  }, [members]);

  return (
    <BasicFormBlueprint
      loading={false}
      submitting={false}
      title={c('prescreenTitle')}
      subtitle={c('genericSupport', { date: sixtyDaysAgo() })}
      formConfig={{
        initialValues,
        fields: [
          {
            type: 'multiSelect',
            name: 'sep',
            options,
            style: 'row',
          },
        ],
        onSubmit,
      }}
      onBack={pop}
    />
  );
};
