import React from 'react';
import { Text } from '@uikit';
import { useCopy } from '@app/utils';

const ApplicantAgeWarning: React.FC<{ givenName?: string; familyName?: string }> = ({
  givenName,
  familyName,
}) => {
  const { c } = useCopy('catch.ede.IdInfo');

  return (
    <>
      <Text size="fp" color="urgentLite" weight="bold">
        {c('65yrswarningTitle', {
          applicant: `${givenName} ${familyName}`,
        })}
      </Text>
      <Text size="fp" color="urgentLite">
        {c('65yrswarningDescription', {
          applicant: `${givenName} ${familyName}`,
        })}
      </Text>
    </>
  );
};

export default ApplicantAgeWarning;
