import React from 'react';
import { KeyboardAvoidingView, SafeAreaView, StyleSheet, View } from 'react-native';

import { useCopy } from '@app/utils';
import { pop } from '@navigate';
import { Container, FlowHeader, Layout, Narrow, Page, Toolbar } from '@layouts';
import { Text } from '@uikit/components/Text';
import { Button } from '@uikit/components/Button';
import { Loading } from '@uikit/components/Loading';

/**
 * @deprecated
 * This should use combination of new layout + blueprints
 */
const FlowLayout = ({
  title,
  subtitle,
  subtitles,
  color = 'page',
  pretitle,
  loading,
  nextButtonText,
  canClickNext = true,
  canGoBack = true,
  onNext,
  footer,
  disclaimer,
  disabled,
  hideBottomBar,
  hideNext,
  customBottomBar,
  narrow,
  children,
  asset,
  align,
  modal,
  render,
}) => {
  const { c } = useCopy('catch.basics');
  const Wrapper = narrow ? Narrow : Container;

  return (
    <Page flexible>
      <SafeAreaView style={[styles.container]}>
        <Layout.Scroll fullWidth bottomSpace topSpace>
          <FlowHeader
            render={render}
            narrow={narrow || modal}
            modal={modal}
            single={modal}
            color={color}
            title={title}
            subtitle={subtitle}
            subtitles={subtitles}
            asset={asset}
            align={align}
            center={align === 'center'}
            rightSpace={align !== 'center'}
            pretitle={pretitle}
            loading={loading}
          />

          {!!loading ? (
            <Loading />
          ) : (
            <Layout bottomSpace>
              <Wrapper>
                <KeyboardAvoidingView keyboardVerticalOffset={100}>{children}</KeyboardAvoidingView>
              </Wrapper>
              {footer}
            </Layout>
          )}
        </Layout.Scroll>
      </SafeAreaView>
      {disclaimer && (
        <View
          style={{
            width: '100%',
            maxWidth: '100%',
            marginBottom: 80,
          }}
        >
          {disclaimer}
        </View>
      )}
      {customBottomBar ||
        (!hideBottomBar && (
          <Toolbar narrow={narrow} onBack={!!canGoBack ? pop : undefined}>
            {!!disabled && !canClickNext ? (
              <Text size="p" align="right">
                {disabled}
              </Text>
            ) : !hideNext ? (
              <Button
                testID="next"
                inherit
                onPress={onNext}
                disabled={loading || !canClickNext}
                loading={loading}
                haptics="LIGHT"
              >
                {nextButtonText || c('next')}
              </Button>
            ) : (
              <></>
            )}
          </Toolbar>
        ))}
    </Page>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    overflow: 'hidden',
  },
});

export default FlowLayout;
