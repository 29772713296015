import React from 'react';
import { View } from 'react-native';

import { useCopy } from '@app/utils';
import { GuideBlueprint } from '@blueprints';
import { useTheme, LineItem, Loading, FinePrint } from '@uikit';
import { Stack } from '@layouts';
import { useDeprecatedQuery } from '@data';
import DonutChart from '@app/charts/base/Donut';

const DIGITS = 1;

export const TaxBreakdownGuideSheet = ({ calculation, shouldRunQuery }) => {
  const { c, p } = useCopy('catch.module.taxes.TaxesBreakdown');
  const { theme } = useTheme();

  const { result } = useDeprecatedQuery('calculateTaxes', {
    skip: !shouldRunQuery || !!calculation,
  });

  if (!calculation && !result)
    return (
      <GuideBlueprint name="TAX_BREAKDOWN_GUIDE" title={c('title')} subtitle={c('description')}>
        <Loading accentColor="taxes" />
      </GuideBlueprint>
    );

  const { userResponsibilities, totalLiability, estimatedPaycheckPercentage } =
    calculation || result;

  if (!userResponsibilities)
    return (
      <GuideBlueprint name="TAX_BREAKDOWN_GUIDE" title={c('title')} subtitle={c('description')}>
        <Loading accentColor="taxes" />
      </GuideBlueprint>
    );

  const { federalLiability, socialSecurityLiability, medicareLiability } = userResponsibilities;

  let chartData = [];
  let legendData = [];

  if (federalLiability > 0) {
    const percentage = (federalLiability / totalLiability) * estimatedPaycheckPercentage;
    chartData.push(percentage);
    legendData.push({
      label: 'federalTax',
      percentage,
      qaName: 'federalLiability',
    });
  }

  if (socialSecurityLiability > 0) {
    const percentage = (socialSecurityLiability / totalLiability) * estimatedPaycheckPercentage;
    chartData.push(percentage);
    legendData.push({
      label: 'socialSecurity',
      percentage,
      qaName: 'socialSecurityLiability',
    });
  }

  if (medicareLiability > 0) {
    const percentage = (medicareLiability / totalLiability) * estimatedPaycheckPercentage;
    chartData.push(percentage);
    legendData.push({
      label: 'medicare',
      percentage,
      qaName: 'medicareLiability',
    });
  }

  const sum = chartData.reduce((acc, val) => acc + val, 0);
  const remainder = 1 - sum;
  const _chartData = [...chartData, remainder];

  return (
    <GuideBlueprint name="TAX_BREAKDOWN_GUIDE" subtitle={c('description')} title={c('title')}>
      <Stack spacing="2">
        <View style={theme.alignCenter}>
          <DonutChart
            size={140}
            data={_chartData.map((num, idx) => ({
              y: num * 100,
              x: idx,
              color: idx < _chartData?.length - 1 ? 'taxes' : 'border',
            }))}
            text={p(sum, { minimumFractionDigits: DIGITS, maximumFractionDigits: DIGITS })}
          />
        </View>
        <Stack spacing="1" separatorComponent>
          {legendData.map((ld, i) => (
            <LineItem
              prominent
              key={i}
              testID={ld.qaName}
              left={c('label.' + ld.label)}
              right={p(ld.percentage, {
                minimumFractionDigits: DIGITS,
                maximumFractionDigits: DIGITS,
              })}
            />
          ))}
        </Stack>
        <FinePrint items={[c('disclaimer1'), c('disclaimer2')]} />
      </Stack>
    </GuideBlueprint>
  );
};

export default TaxBreakdownGuideSheet;
