import { isPast } from 'date-fns';
import { ApolloApplicationIssues, ApolloEDNDocument, ApolloWalletItem } from './fragments';
import { HealthApplicationQueryData, TransferQueryData } from './types';

/**
 * Helper that pulls the application members off of the health app query
 */
export const getMembers = (data?: HealthApplicationQueryData) => {
  const applicant = data?.viewerTwo?.health?.application?.applicant;
  const otherMembers = data?.viewerTwo?.health?.application?.members || [];
  return applicant ? [applicant, ...otherMembers] : otherMembers;
};

/**
 * Helper that figures out which DMI/SVIs have certain statuses
 */
export const getApplicationIssues = (application?: ApolloApplicationIssues) => {
  const members = application?.allMembers || [];
  const dmiUploads = application?.dmiDocumentUploads || [];
  const sviUploads = application?.sviDocumentUploads || [];

  const dmis = (application?.dmis || [])?.map((dmi) => {
    const uploads = dmiUploads?.filter((upload) => upload.issueID === dmi.id);
    const dependents = members?.filter((dep) => dep?.id === dmi?.dependentID);

    const isCompleted = dmi.status === 'COMPLETED';
    const isExpired = dmi.status === 'EXPIRED' || isPast(new Date(dmi?.resolveBy));
    const isProcessing = dmi.status === 'PROCESSING' || uploads?.length > 0;

    return {
      ...dmi,
      dependents,
      uploads,
      docType: 'DMI',
      resolveBy: new Date(dmi?.resolveBy), // cast as date
      copyType: dmi?.subType,
      computedStatus: isCompleted
        ? 'COMPLETED'
        : isExpired
        ? 'EXPIRED'
        : isProcessing
        ? 'PROCESSING'
        : 'ACTION_NEEDED',
    };
  });

  const svis = (application?.svis || [])?.map((svi) => {
    const uploads = sviUploads?.filter((upload) => upload.issueID === svi.id);
    const dependents = members?.filter((dep) => svi?.dependentIDs?.includes(dep?.id));

    const isCompleted = svi.status === 'COMPLETED';
    const isExpired = svi.status === 'EXPIRED' || isPast(new Date(svi?.resolveBy));
    const isProcessing = svi.status === 'PROCESSING' || uploads?.length > 0;

    return {
      ...svi,
      uploads,
      dependents,
      docType: 'SVI',
      copyType: svi?.statusCode, // MOVED_TO_NEW_SERVICE_AREA, MEC_LOSS, etc.
      resolveBy: new Date(svi?.resolveBy), // cast as date
      computedStatus: isCompleted
        ? 'COMPLETED'
        : isExpired
        ? 'EXPIRED'
        : isProcessing
        ? 'PROCESSING'
        : 'ACTION_NEEDED',
    };
  });

  // all documents should be sorted by when they are due by
  const healthDocs = [...dmis, ...svis]?.sort((a, b) => (a?.resolveBy > b?.resolveBy ? 1 : -1));

  const issuesByStatus = healthDocs.reduce(
    (acc, issue) => {
      const append = (issue, status, arr) =>
        issue?.computedStatus === status ? [...arr, issue] : arr;

      return {
        action: append(issue, 'ACTION_NEEDED', acc.action),
        processing: append(issue, 'PROCESSING', acc.processing),
        expired: append(issue, 'EXPIRED', acc.expired),
        completed: append(issue, 'COMPLETED', acc.completed),
      };
    },
    {
      action: [],
      processing: [],
      expired: [],
      completed: [],
    },
  );

  return {
    healthDocs,
    issuesByStatus,
  };
};

/**
 * Helper function to retrieve the most recent EDN
 */
export const getMostRecentEDN = (
  ednDocuments?: Array<ApolloEDNDocument>,
): ApolloEDNDocument | undefined => {
  const EDNs = ednDocuments ? [...ednDocuments] : [];
  return EDNs.sort((a, b) => (a.documentCreationDate < b.documentCreationDate ? 1 : -1))?.[0];
};

/**
 * Returns list of wallet types that do NOT already have an existing item
 */
export const getRemainingWalletTypes = (items?: Array<ApolloWalletItem>): Array<string> => {
  const found = (items || []).reduce((acc, item) => ({ ...acc, [item.vertical]: true }), {});
  return ['HEALTH', 'DENTAL', 'VISION', 'LIFE'].filter((type) => !found[type]);
};

/**
 * Gets list of bank accounts
 */

export const getBankAccounts = (data?: TransferQueryData): Array<object> => {
  return (data?.viewer?.bankLinks || []).reduce((acc, link) => {
    link?.accounts?.forEach((account) => {
      acc.push({
        logo: link.logo,
        bankName: link.bankName,
        accountName: account.name,
        ...account,
      });
    });

    return acc;
  }, []);
};
