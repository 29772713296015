import React, { useCallback, useMemo } from 'react';

import { formatBenefit, slugTypeMap, useCopy } from '@app/utils';
import { navigate, routes } from '@navigate';
import { GoalSlug } from '@types';
import { Button } from '@uikit';
import { Toolbar } from '@layouts';
import { IntroBlueprint } from '@blueprints';
import { benefitColorLookup } from '@app/utils/color';

import Bank from '@svg/bank.svg';
import Bolt from '@svg/bolt.svg';
import Chart from '@svg/chart.svg';

interface GoalIntroProps {
  slug: GoalSlug;
  handleNext: ({ skip }) => void;
  onboarding?: boolean; //true if we're coming here from the setup payroll screen in onboarding payroll setup
}

/**
 * Renders an intro screen to setting up a goal
 */
const GoalIntro: React.FC<GoalIntroProps> = ({ slug, handleNext, onboarding }) => {
  const { c } = useCopy(`catch.${slugTypeMap[slug] || 'custom-goal'}.intro`);
  const { c: c2 } = useCopy('catch.basics');

  const isCustom = useMemo(() => slugTypeMap[slug] === undefined, [slug]);

  const retirementOnboarding = slug === 'retirement' && onboarding === true;
  const accentColor = benefitColorLookup({ slug });

  const bullets = useMemo(
    () => [
      {
        id: 'ONE',
        label: c('step1'),
        sublabel: c('subtitle1'),
        svg: Bank,
      },
      {
        id: 'TWO',
        label: c('step2'),
        sublabel: c('subtitle2'),
        svg: Chart,
      },
      {
        id: 'THREE',
        label: c('step3'),
        sublabel: c('subtitle3'),
        svg: Bolt,
      },
    ],
    [slug, c],
  );

  const buttonPress = useCallback(
    (props) => {
      if (isCustom) {
        return navigate(routes.GOAL_CUSTOMIZE, { slug, creation: true });
      }
      return handleNext(props);
    },
    [handleNext],
  );

  const noSkipButtonPress = useCallback(() => buttonPress({ slug, skip: false }), [buttonPress]);
  const skipButtonPress = useCallback(() => buttonPress({ slug, skip: true }), [buttonPress]);

  return (
    <IntroBlueprint
      title={formatBenefit.name({ slug })}
      titleSize="page"
      align="center"
      type={accentColor}
      gradient={accentColor + 'Light'}
      bullets={bullets}
      toolbar={
        <Toolbar type="stack">
          <Button disabled inherit onPress={noSkipButtonPress} testID="next-btn">
            {c2('next')}
          </Button>
          {retirementOnboarding && (
            <Button alt onPress={skipButtonPress} testID="skip-btn">
              {c2('setUpLater')}
            </Button>
          )}
        </Toolbar>
      }
    />
  );
};

export const GoalIntroView = {
  name: 'GOAL_INTRO',
  component: GoalIntro,
  options: {
    drawBehindNav: true,
  },
};
