import gql from 'graphql-tag';
import { AgreementSet, HealthApplicationStatus, IdentityProvider, IdentityStatus } from '@types';
import { ApolloOpenEnrollmentDates, OpenEnrollmentFragment } from '../fragments';

export interface HealthRenewQueryData {
  viewer: {
    id: string;
    user: {
      id: string;
      givenName: string;
      familyName: string;
      dob: string;
      ssn: string;
      legalAddress: {
        street1: string;
        city: string;
        state: string;
        zip: string;
      };
    };
  };
  viewerTwo: {
    id: string;
    agreements: AgreementSet;
    identity: {
      id: string;
      providerType: IdentityProvider;
      status: IdentityStatus;
    };
    health: {
      policies: Array<{
        id: string;
      }>;
      applications: Array<{
        id: string;
        lastUsedRoute: string;
        status: HealthApplicationStatus;
        enrollmentGroups: Array<{
          id: string;
          selectedProviderPlanID: string;
        }>;
      }>;
      submitted: Array<{
        id: string;
        lastUsedRoute: string;
        status: HealthApplicationStatus;
        enrollmentGroups: Array<{
          id: string;
          selectedProviderPlanID: string;
        }>;
      }>;
    };
    healthExplorerData?: {
      id: string;
      coverageYear: number;
      healthPlans: {
        topPlans: {
          selectedPlan: {
            planID: string;
            healthPlan: {
              planID: string;
              name: string;
            };
          };
        };
      };
    };
    existingApplicationInfo: {
      hasActivePolicy: boolean;
    };
  };
  reference: {
    health: {
      openEnrollmentDates: ApolloOpenEnrollmentDates;
    };
  };
}

export const HealthRenewQuery = gql`
  query HealthRenewQuery($coverageYear: Int!, $renewalYear: Int!) {
    viewer {
      id
      user {
        id
        givenName
        familyName
        dob
        ssn
        legalAddress {
          street1
          city
          state
          zip
        }
      }
    }
    viewerTwo {
      id
      health {
        policies(
          input: {
            coverageYear: $renewalYear
            statuses: [ACTIVE, PENDING, ACTIVE_PENDING_PAYMENT, PENDING_START_DATE]
          }
        ) {
          id
          carrier
          planName
          policyStatus
          premium
          premiumEffective
          startDate
          endDate
          coverageYear @client
          exchangePolicyNumber
          productType
          providerPlan {
            planID: id
            name
            metalLevel
            type
            title @client
            metal @client
            issuer {
              id
              name
              title @client
            }
            deductibles {
              amount
              familyCost
              type
            }

            familyDeductible @client
            individualDeductible @client
          }
        }
        applications(input: { coverageYearNumber: [$coverageYear] }) {
          id
          lastUsedRoute
          status
          enrollmentGroups {
            id
            selectedProviderPlanID
          }
          enrollment {
            id
          }
          policies {
            id
            carrier
            planName
            policyStatus
            premium
            premiumEffective
            startDate
            endDate
            coverageYear @client
            exchangePolicyNumber
            productType
            providerPlan {
              planID: id
              name
              metalLevel
              type
              title @client
              metal @client
              issuer {
                id
                name
                title @client
              }
              deductibles {
                amount
                familyCost
                type
              }

              familyDeductible @client
              individualDeductible @client
            }
          }
        }
        submitted: applications(
          input: { coverageYearNumber: [$coverageYear], statuses: [SUBMITTED, ENROLLED] }
        ) {
          id
          lastUsedRoute
          status
          enrollmentGroups {
            id
            selectedProviderPlanID
          }
          enrollment {
            id
          }
          policies {
            id
            carrier
            planName
            policyStatus
            premium
            premiumEffective
            startDate
            endDate
            coverageYear @client
            exchangePolicyNumber
            productType
            providerPlan {
              planID: id
              name
              metalLevel
              type
              title @client
              metal @client
              issuer {
                id
                name
                title @client
              }
              deductibles {
                amount
                familyCost
                type
              }

              familyDeductible @client
              individualDeductible @client
            }
          }
        }
      }
      healthExplorerData {
        id
        coverageYear
        providerPlanID
        healthPlans {
          topPlans {
            selectedPlan {
              planID: id
              healthPlan {
                planID: id
                name
              }
            }
          }
        }
      }
      existingApplicationInfo {
        hasActivePolicy
      }
      agreements {
        id
        isPrivacyDataUseAgree
        isPrivacyTruthfulAgree
        isPrivacyAuthorizeAgree
      }
      identity(providerType: CMS) {
        id
        providerType
        status
      }
    }
    reference {
      health {
        openEnrollmentDates {
          ...OEDates
        }
      }
    }
  }
  ${OpenEnrollmentFragment}
`;
