import React, { useMemo } from 'react';

import { BottomActions } from '@common';
import { Toolbar } from '@layouts';
import { ExplainerIntroBlueprint } from '@blueprints';
import { Button, FinePrint } from '@app/_ui-kit';

import PriceTag from '@svg/tag.svg';
import Renew from '@svg/catch-roll.svg';
import Checkmark from '@svg/checkmark.svg';
import User from '@svg/user.svg';
import Shield from '@svg/shield.svg';
import Mail from '@svg/mail.svg';

const types = {
  AUTO_TAX: {
    render: 'taxesAlt',
    color: 'taxes',
    gradient: 'taxesLight',
    title: 'Auto Tax Payments',
    subtitle: 'Set it and forget it',
    button: 'Next',
    bullets: [
      {
        id: '1',
        label: 'Set aside when you get paid',
        sublabel: 'Personal payroll withholds for taxes every time you’re paid',
        text: '1',
        color: 'bg',
      },
      {
        id: '2',
        label: 'Pay taxes each quarter',
        sublabel: 'Catch sends your tax balance to the IRS and your state each quarter',
        text: '2',
        color: 'bg',
      },
      {
        id: '3',
        label: 'Make adjustments as needed',
        sublabel: 'Change withholding rates or the amount we send the government manually',
        text: '3',
        color: 'bg',
      },
      {
        id: 'mail',
        label: 'You may receive mail from the IRS about EFTPS enrollment',
        sublabel: 'You can ignore it',
        svg: Mail,
        color: 'fg',
      },
    ],
  },
  WINDOW_SHOPPING: (data) => ({
    render: 'coverageAlt',
    color: 'coverage',
    gradient: 'coverageLight',
    title: 'Quick Renew',
    subtitle: `Keep coverage for ${data?.coverageYear}`,
    button: data?.hasIDProof && data?.hasSelectedPlan && data?.hasHeadstart ? 'Done' : 'Next',
    fine: 'Catch is securely integrated with health exchanges.',
    bullets: [
      {
        label: 'Verify your identity',
        svg: data?.hasIDProof ? Checkmark : User,
        color: data?.hasIDProof ? 'snow' : undefined,
        bg: data?.hasIDProof ? 'coverage' : undefined,
      },
      {
        label: `Choose your ${data?.coverageYear} plan`,
        svg: data?.hasSelectedPlan ? Checkmark : PriceTag,
        color: data?.hasSelectedPlan ? 'snow' : undefined,
        bg: data?.hasSelectedPlan ? 'coverage' : undefined,
        sublabel: data?.hasSelectedPlan ? `You've selected ${data?.planName}` : undefined,
      },
      {
        label: 'Get a head start on your application',
        svg: data?.hasHeadstart ? Checkmark : Shield,
        color: data?.hasHeadstart ? 'snow' : undefined,
        bg: data?.hasHeadstart ? 'coverage' : undefined,
        sublabel: data?.hasStartedApplication
          ? `You've started your ${data?.coverageYear} application`
          : data?.hasConnectedCoverage
          ? `We'll use your current ${data?.renewalYear} coverage`
          : undefined,
      },
    ],
  }),
  QUICK_RENEW: (data) => ({
    render: 'coverageAlt',
    color: 'coverage',
    gradient: 'coverageLight',
    title: 'Quick Renew',
    subtitle: `Keep coverage for ${data?.coverageYear}`,
    button: 'Continue',
    fine: 'Catch is securely integrated with health exchanges.',
    bullets: [
      {
        label: 'Verify your identity',
        svg: data?.hasIDProof ? Checkmark : User,
        color: data?.hasIDProof ? 'snow' : undefined,
        bg: data?.hasIDProof ? 'coverage' : undefined,
      },
      {
        label: `Confirm your ${data?.coverageYear} plan`,
        svg: data?.hasSelectedPlan ? Checkmark : PriceTag,
        color: data?.hasSelectedPlan ? 'snow' : undefined,
        bg: data?.hasSelectedPlan ? 'coverage' : undefined,
        sublabel: data?.hasSelectedPlan ? `You've selected ${data?.planName}` : undefined,
      },
      {
        label: data?.hasSubmittedApplication ? 'Finish enrolling' : 'Finish your application',
        svg: Shield,
      },
    ],
  }),

  ROLL: {
    render: 'brand',
    color: 'income',
    gradient: 'incomeLight',
    title: '',
    subtitle: '',
    button: 'Next',
  },
  TAX_PAYMENT: {
    render: 'taxesAlt',
    color: 'taxes',
    gradient: 'taxesLight',
    title: 'Make a tax payment',
    subtitle: 'Send an estimated quarterly tax payment to the IRS',
    button: 'Next',
  },
  HEALTH_LINK: {
    render: 'coverageAlt',
    color: 'coverage',
    gradient: 'coverageLight',
    title: 'Connect your coverage',
    subtitle: 'Easily manage your coverage all in one place',
    button: 'Next',
    buttonSkip: 'Set up later',
    fine: 'Catch is securely integrated with health exchanges.',
    bullets: [
      {
        svg: PriceTag,
        label: 'Maximize savings',
        sublabel: 'Make sure you’re getting the tax credits you qualify for',
      },
      {
        svg: Renew,
        label: 'Automate renewals',
        sublabel: 'Never worry about renewing your health coverage',
      },
    ],
  },
  HEALTH_SLASHER: {
    render: 'coverageAlt',
    color: 'coverage',
    gradient: 'coverageLight',
    title: 'Slash your premiums',
    subtitle:
      'Connect your health insurance and Catch will search millions in tax credits to lower your monthly premiums',
    button: 'Next',
  },
  BANK_LINK: {
    render: 'link',
    color: 'income',
    gradient: 'brandLight',
    title: 'Let’s connect your bank account',
    subtitle:
      'Connect your bank account so that personal payroll can run to set aside for Taxes, Health Savings, and Time off.',
    button: 'Next',
  },
  WELCOME: {
    render: 'brand',
    color: 'brand',
    gradient: 'brandLight',
    title: 'Welcome to Catch',
    subtitle:
      'Free Personal Payroll and Benefits for people who are self-employed, freelance, or contract. We’ll automatically take care of taxes, retirement, health insurance and other benefits, no matter how you get paid! ',
    button: 'Next',
  },
  EFTPS_AUTH: {
    render: 'taxesAlt',
    color: 'taxes',
    gradient: 'taxesLight',
    title: 'Tax Payment Authorization',
    subtitle:
      'A quick update on our payment terms and authorizations– this allows Catch to offer much smoother tax payments',
    button: 'Agree',
  },
};

/**
 * @todo once 22.4.0 is released, remove catch.paid.* from copy sheet
 */
const SetupIntro = ({
  type,
  data,
  actions,
  children,
  onNext,
  onSkip,
  loading,
  disabled,
  context,
  fine,
}) => {
  const content = useMemo(() => {
    return typeof types[type] === 'function' ? types[type](data) : types[type];
  }, [type, data]);

  return (
    <ExplainerIntroBlueprint
      title={content?.title}
      subtitles={[content?.subtitle]}
      pageColor="sheet"
      gradient={content?.gradient}
      color={content?.color}
      render={content?.render}
      context={context}
      bullets={content?.bullets}
      toolbar={
        <Toolbar type="stack">
          {(!!content?.fine || !!fine) && (
            <FinePrint align="center" items={[fine, content?.fine]} />
          )}

          {!!actions && <BottomActions actions={actions} />}
          {!actions && onSkip && content?.buttonSkip && (
            <Button
              accentColor={content?.color + 'Light'}
              disabled={loading}
              testID="paid-intro-next-button"
              onPress={() => onSkip()}
            >
              {content?.buttonSkip}
            </Button>
          )}
          {!actions && (
            <Button
              wide
              inherit
              disabled={loading || disabled}
              testID="paid-intro-next-button"
              onPress={() => onNext()}
            >
              {content?.button}
            </Button>
          )}
        </Toolbar>
      }
    >
      {children}
    </ExplainerIntroBlueprint>
  );
};

SetupIntro.options = {
  drawBehindNav: true,
};

export default SetupIntro;
