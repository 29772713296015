import React from 'react';
import { Platform, SafeAreaView } from 'react-native';
import { useHeaderHeight } from './useHeaderHeight';
import { useTheme } from '@uikit';
import { BaseColor } from '@types';

export interface PageProps {
  children?: React.ReactNode;
  color?: BaseColor;
  topSpace?: boolean;
  margins?: boolean;
  fixAndroidMarginBehindNav?: boolean; //Header appearance on Android overlaps with status bar on same screens
}

const Page: React.FC<PageProps> = ({ children, fixAndroidMarginBehindNav = false, color }) => {
  // @ts-ignore
  const { theme } = useTheme({ navMode: 'visible' });
  const { platformOffset } = useHeaderHeight();

  return (
    <SafeAreaView
      style={[
        theme.flex1,
        {
          paddingTop: fixAndroidMarginBehindNav && Platform.OS === 'android' ? platformOffset : 0,
        },
      ]}
    >
      {children}
    </SafeAreaView>
  );
};

export default Page;
