import { HealthLinkQueryData } from './queries/HealthLinkQuery';
import { HealthExplorerQueryData } from './queries/HealthExplorerQuery';
import { gql } from 'graphql-tag';
import { ApolloCache, FetchResult } from '@apollo/client';
import { queries } from './queries';
import { HealthSlasherQueryData } from './queries/HealthSlasherQuery';
import { UpsertApplicationMembersResponse } from './types';

type UpdateName =
  | 'UPSERT_GOAL'
  | 'ADD_BANK_LINK'
  | 'ADD_PENDING_ACCOUNT'
  | 'DELETE_BANK_LINK'
  | 'DELETE_PENDING_ACCOUNT'
  | 'UPSERT_INCOME_PREFERENCES'
  | 'VERIFY_EMAIL'
  | 'VERIFY_PHONE'
  | 'UPSERT_EXPLORER_APPLICANTS'
  | 'UPDATE_AGREEMENTS'
  | 'UPDATE_EXISTING_APPLICATIONS'
  | 'UPDATE_EXPLORER'
  | 'ADD_PREFILL'
  | 'ELIG_REFETCHED'
  | 'UPDATE_ENROLLMENT_GROUPS'
  | 'UPDATE_APPLICATION_MEMBERS'
  | 'REMOVE_APPLICATION_MEMBER'
  | 'REMOVE_EXPLORER_APPLICANT';

const viewerID = 'Viewer:current';
const viewerTwoID = 'ViewerTwo:current';

type UpdateFunction = (cache: ApolloCache<any>, mutationResult: FetchResult) => void;

export const updates: Record<UpdateName, UpdateFunction> = {
  UPSERT_GOAL: (cache, result) => {
    cache.modify({
      id: viewerID,
      fields: {
        goals(goals, { readField }) {
          const existingGoals = goals || [];

          const newGoalRef = cache.writeFragment({
            data: result?.data?.upsertGoal,
            fragment: gql`
              fragment NewGoal on Goal {
                id
                slug
                goalType
                name
                status
                paycheckPercentage
                totalBalance
                availableBalance
                pendingBalance
                pausedEndDate
              }
            `,
          });

          // don't add the goal if we already have it listed
          if (existingGoals.some((ref) => readField('id', ref) === result?.data?.upsertGoal.id)) {
            return existingGoals;
          }

          return [...existingGoals, newGoalRef];
        },
      },
    });
  },
  ADD_BANK_LINK: (cache, result) => {
    cache.modify({
      id: viewerID,
      fields: {
        bankLinks(bankLinks, { readField }) {
          const existingBankLinks = bankLinks || [];

          const newBankLinkRef = cache.writeFragment({
            data: result?.data?.addBankLinkItem?.bankLink,
            fragment: gql`
              fragment NewBankLink on BankLink {
                id
                providerType
              }
            `,
          });

          // don't add the goal if we already have it listed
          if (
            existingBankLinks.some(
              (ref) => readField('id', ref) === result?.data?.addBankLinkItem?.bankLink.id,
            )
          ) {
            return existingBankLinks;
          }

          return [...existingBankLinks, newBankLinkRef];
        },
      },
    });
  },
  ADD_PENDING_ACCOUNT: (cache, result) => {
    cache.modify({
      id: viewerID,
      fields: {
        pendingAccounts(pendingAccounts, { readField }) {
          const existingPendingAccounts = pendingAccounts || [];

          const newPendingAccountRef = cache.writeFragment({
            data: result?.data?.addPendingAccount,
            fragment: gql`
              fragment NewPendingAccount on PendingAccount {
                id
              }
            `,
          });

          // don't add the goal if we already have it listed
          if (
            existingPendingAccounts.some(
              (ref) => readField('id', ref) === result?.data?.addPendingAccount?.id,
            )
          ) {
            return existingPendingAccounts;
          }

          return [...existingPendingAccounts, newPendingAccountRef];
        },
      },
    });
  },
  DELETE_BANK_LINK: (cache, result) => {
    cache.modify({
      id: viewerID,
      fields: {
        bankLinks(bankLinks, { readField }) {
          const existingBankLinks = bankLinks || [];

          return existingBankLinks.filter((bankLink) => {
            return readField('id', bankLink) !== result?.data?.deleteBankLink;
          });
        },
      },
    });
  },
  DELETE_PENDING_ACCOUNT: (cache, result) => {
    cache.modify({
      id: viewerID,
      fields: {
        pendingAccounts(pendingAccounts, { readField }) {
          const existingPendingAccounts = pendingAccounts || [];

          return existingPendingAccounts.filter((account) => {
            return readField('id', account) !== result?.data?.deletePendingAccount.id;
          });
        },
      },
    });
  },
  UPSERT_INCOME_PREFERENCES: (cache, result) => {
    cache.modify({
      id: viewerID,
      fields: {
        incomePreferences(prefs) {
          return { ...prefs, ...result?.data?.upsertIncomePreferences };
        },
      },
    });
  },
  VERIFY_PHONE: (cache, result) => {
    // gets the user ID from cache
    const user: { id: string } | null = cache.readFragment({
      id: viewerID, // The value of the to-do item's cache ID
      fragment: gql`
        fragment UserID on Viewer {
          id
        }
      `,
    });

    // only update if new phone was successful
    if (result?.data?.verifyPhone.newPhone && user) {
      cache.modify({
        id: `User:${user.id}`,
        fields: {
          phoneNumber() {
            return result?.data?.verifyPhone.newPhone;
          },
        },
      });

      cache.modify({
        id: viewerID,
        fields: {
          isAliasMatchAndVerified(current) {
            return {
              ...current,
              phoneMatchAndVerified: true,
            };
          },
        },
      });
    }
  },
  VERIFY_EMAIL: (cache, result) => {
    // gets the user ID from cache
    const user: { id: string } | null = cache.readFragment({
      id: viewerID, // The value of the to-do item's cache ID
      fragment: gql`
        fragment UserID on Viewer {
          id
        }
      `,
    });

    // only update if new email was successful
    if (result?.data?.verifyEmail.newEmail && user) {
      cache.modify({
        id: `User:${user.id}`,
        fields: {
          email() {
            return result?.data?.verifyEmail.newEmail;
          },
        },
      });

      cache.modify({
        id: viewerID,
        fields: {
          isAliasMatchAndVerified(current) {
            return {
              ...current,
              emailMatchAndVerified: true,
            };
          },
        },
      });
    }
  },
  UPSERT_EXPLORER_APPLICANTS: (cache, result) => {
    // gets the explorer ID from cache
    const data = cache.readFragment({
      id: viewerTwoID,
      fragment: gql`
        fragment HealthExplorerID on ViewerTwo {
          id
          healthExplorerData {
            id
          }
        }
      `,
    });

    const explorerID = data?.healthExplorerData?.id;

    // updates the explorer
    cache.modify({
      id: `HealthExplorerData:${explorerID}`,
      fields: {
        dependents(deps) {
          return result?.data?.upsertHealthExplorerDependents || deps;
        },
      },
    });
  },
  UPDATE_AGREEMENTS: (cache, result) => {
    const updated = result?.data?.upsertHealthPrivacy || result?.data?.confirmGoals?.agreements;

    if (updated) {
      cache.modify({
        id: `Agreements:current`,
        fields: {
          authorizedAboundEftpsEnrollment(val) {
            return updated.authorizedAboundEftpsEnrollment !== undefined
              ? updated.authorizedAboundEftpsEnrollment
              : val;
          },
          acknowledgedUnitAccountDisclosures(val) {
            return updated.acknowledgedUnitAccountDisclosures !== undefined
              ? updated.acknowledgedUnitAccountDisclosures
              : val;
          },
          isPrivacyAuthorizeAgree(val) {
            return updated.isPrivacyAuthorizeAgree !== undefined
              ? updated.isPrivacyAuthorizeAgree
              : val;
          },
          isPrivacyDataUseAgree(val) {
            return updated.isPrivacyDataUseAgree !== undefined
              ? updated.isPrivacyDataUseAgree
              : val;
          },
          isPrivacyTruthfulAgree(val) {
            return updated.isPrivacyTruthfulAgree !== undefined
              ? updated.isPrivacyTruthfulAgree
              : val;
          },
        },
      });
    }
  },

  UPDATE_EXISTING_APPLICATIONS: (cache, result) => {
    const initial: HealthLinkQueryData | null = cache.readQuery({
      query: queries.HEALTH_LINK,
    });

    const updated =
      result?.data?.edeApplicationSearch || result?.data?.importAllExistingApplications;

    const updatedAppInfo = updated || {};

    if (initial) {
      cache.writeQuery({
        query: queries.HEALTH_LINK,
        data: {
          viewer: {
            ...initial.viewer,
          },
          viewerTwo: {
            ...initial.viewerTwo,
            existingApplicationInfo: {
              ...initial.viewerTwo?.existingApplicationInfo,
              ...updatedAppInfo,
            },
          },
          reference: {
            ...initial.reference,
          },
        },
      });
      cache.modify({
        id: `Nudge:_HEALTH_CALLED_LOOKUP`,
        fields: {
          isDismissed(val) {
            return true;
          },
        },
      });
    }
  },
  UPDATE_EXPLORER: (cache, result) => {
    const initial: HealthExplorerQueryData | null = cache.readQuery({
      query: queries.HEALTH_EXPLORER,
    });

    const updatedExplorer = result?.data?.upsertHealthExplorerData || {};

    if (initial) {
      cache.writeQuery({
        query: queries.HEALTH_EXPLORER,
        data: {
          viewer: {
            ...initial.viewer,
          },
          viewerTwo: {
            healthExplorerData: {
              ...initial.viewerTwo.healthExplorerData,
              ...updatedExplorer,
            },
          },
        },
      });
    }
  },
  ADD_PREFILL: (cache, result) => {
    const initial: HealthSlasherQueryData | null = cache.readQuery({
      query: queries.HEALTH_SLASHER,
      variables: { coverageYears: [2023] },
    });

    const prefills = initial?.viewerTwo?.health?.prefills || [];

    const application =
      result?.data?.reportHealthEnrollmentChanges?.insuranceEnrollment?.healthApplication;

    // check if the new prefill already exists in list
    const alreadyExists = prefills.some((app) => app?.id === application?.id);

    if (initial && !alreadyExists) {
      cache.writeQuery({
        query: queries.HEALTH_SLASHER,
        variables: { coverageYears: [2023] },
        data: {
          viewer: {
            ...initial.viewerTwo,
            health: {
              ...initial.viewerTwo.health,
              prefills: [application, ...prefills],
            },
          },
        },
      });
    }
  },
  ELIG_REFETCHED: (cache, { didRefetch, applicationID }) => {
    if (applicationID && didRefetch) {
      cache.modify({
        id: `HealthApplication:${applicationID}`,
        fields: {
          shouldRefetchEligibility() {
            return false; // no need to refetch anymore!
          },
        },
      });
    }
  },
  UPDATE_ENROLLMENT_GROUPS: (cache, { data }) => {
    const enrollmentGroups = data?.upsertEnrollmentGroups?.enrollmentGroups || [];
    const applicationID = enrollmentGroups?.[0]?.application?.id;

    if (!!applicationID) {
      cache.modify({
        id: `HealthApplication:${applicationID}`,
        fields: {
          enrollmentGroups(existing, { toReference }) {
            return enrollmentGroups.map((eg) => {
              return toReference({
                __typename: 'EnrollmentGroup',
                id: eg.id,
              });
            });
          },
        },
      });
    }
  },
  UPDATE_APPLICATION_MEMBERS: (cache, result) => {
    const data: UpsertApplicationMembersResponse = result.data;
    const members = data?.upsertApplicationMembers || [];

    const self = members?.find((m) => m?.relation === 'SELF');
    const others = members?.filter((m) => m?.relation !== 'SELF');

    const applicationID = self?.applicationID;

    // updates the application
    if (!!applicationID) {
      cache.modify({
        id: `HealthApplication:${applicationID}`,
        fields: {
          applicant(existing, { toReference }) {
            return toReference({ __typename: 'ApplicationMember', id: self.id });
          },
          members(existing, { toReference }) {
            return others.map((m) => {
              return toReference({ __typename: 'ApplicationMember', id: m.id });
            });
          },
        },
      });
    }
  },
  REMOVE_APPLICATION_MEMBER: (cache, { memberID, applicationID }) => {
    if (!!applicationID) {
      cache.modify({
        id: `HealthApplication:${applicationID}`,
        fields: {
          members(existingMembers, { readField }) {
            return existingMembers.filter((member) => {
              return readField('id', member) !== memberID;
            });
          },
        },
      });
    }
  },
  REMOVE_EXPLORER_APPLICANT: (cache, { applicantID }) => {
    console.log('herE?');
    // gets the explorer ID from cache
    const data = cache.readFragment({
      id: viewerTwoID,
      fragment: gql`
        fragment HealthExplorerID on ViewerTwo {
          id
          healthExplorerData {
            id
          }
        }
      `,
    });

    // @ts-ignore
    const explorerID = data?.healthExplorerData?.id;

    // updates the explorer
    cache.modify({
      id: `HealthExplorerData:${explorerID}`,
      fields: {
        dependents(existingApplicants, { readField }) {
          return existingApplicants.filter((applicant) => {
            return readField('id', applicant) !== applicantID;
          });
        },
      },
    });
  },
};
