export const getTotalIncome = ({ estimatedW2Income, estimated1099Income, workType }) => {
  const estimatedW2 = Number(estimatedW2Income) || 0;
  const estimated1099 = Number(estimated1099Income) || 0;

  switch (workType) {
    case 'WORK_TYPE_W2':
      return estimatedW2;
    case 'WORK_TYPE_1099':
      return estimated1099;
    case 'WORK_TYPE_DIVERSIFIED':
    default:
      return estimatedW2 + estimated1099;
  }
};

export const getHouseholdIncome = ({ estimatedIncome, spouseIncome, filingStatus }) => {
  const income = Number(estimatedIncome) || 0;
  const spouse = Number(spouseIncome) || 0;
  return filingStatus === 'MARRIED' ? income + spouse : income;
};

export const newAutopilotCopy = {
  ALWAYS: 'Autoroll',
  SOMETIMES: 'Ask',
  NEVER: 'Ignore',
  DEFAULT: 'Ask',
};

export const newAutopilotEnum = {
  ALWAYS: 'AUTO',
  SOMETIMES: 'ASK',
  NEVER: 'IGNORE',
  DEFAULT: 'ASK',
};
