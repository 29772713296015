import React from 'react';
import { Button, Option } from '@uikit';
import { Stack, ExplorerLayout } from '@layouts';
import { routes, ScreenDefinition } from '@navigate';
import { useCopy, sixtyDaysAgo } from '@app/utils';

const ExplorerQLE = ({ handleNext }) => {
  const { c: basics } = useCopy('catch.basics');
  const { c: healthBasics } = useCopy(`catch.healthBasics.QLE`);

  const QLEOptions = [
    'COVERAGE_LOSS',
    'MOVE',
    'ELIGIBILITY',
    'BIRTH',
    'COVERAGE_OPTIONS',
    'DIVORCE',
    'DEATH',
    'DISABILITY',
    'ADOPTION',
    'CUSTODY',
    'FILING_STATUS',
    'CITIZENSHIP',
    'INCARCERATION',
    'TRIBE_MEMBERSHIP',
  ];

  return (
    <ExplorerLayout
      title={`Have any of the following things happened to you since ${sixtyDaysAgo()}?`}
      customToolbar={
        <Button alt onPress={() => handleNext({ hasQLE: false })}>
          {basics('noneAbove')}
        </Button>
      }
    >
      <Stack spacing="+gap" group>
        {QLEOptions?.map((opt, i) => (
          <Option
            testID={opt}
            key={opt}
            label={healthBasics(opt)}
            onPress={() => handleNext({ hasQLE: true })}
          />
        ))}
      </Stack>
    </ExplorerLayout>
  );
};

export const ExplorerQLEView: ScreenDefinition = {
  name: routes.EXPLORER_QLE,
  component: ExplorerQLE,
  options: {},
};
