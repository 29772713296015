import React from 'react';
import shallow from 'zustand/shallow';
import { IconSvg } from '@uikit/components/Asset/assets/IconSvg';
import Menu from '@svg/dots-horizontal.svg';
import { useSheet, SheetName } from '../sheets';

interface Props {
  sheet: SheetName;
  data: object;
}

const MenuButton: React.FC<Props> = ({ sheet, data }) => {
  const { open } = useSheet((state) => ({ open: state.open }), shallow);

  // if for any reason the sheet is not defined, dont show it at all
  if (!sheet) return null;

  // @ts-ignore
  return (
    <IconSvg
      size="sm"
      svgComponent={Menu}
      onPress={() => open(sheet, data)}
      style={{ marginRight: -6 }}
    />
  );
};

export default MenuButton;
