import gql from 'graphql-tag';
import access from 'safe-access';

export const GOAL_BALANCES = gql`
  query GoalBalancesQuery {
    viewer {
      id
      taxGoal: goal(slug: "taxes") {
        id
        slug
        totalBalance
        availableBalance
      }
      ptoGoal: goal(slug: "timeoff") {
        id
        slug
        totalBalance
        availableBalance
      }
      retirementGoal: goal(slug: "retirement") {
        id
        slug
        totalBalance
        availableBalance
      }
      healthExpensesGoal: goal(slug: "health-expenses") {
        id
        slug
        totalBalance
        availableBalance
      }
      familyLeaveGoal: goal(slug: "family-leave") {
        id
        slug
        totalBalance
        availableBalance
      }
      emergencySavingsGoal: goal(slug: "emergency-savings") {
        id
        slug
        totalBalance
        availableBalance
      }
    }
  }
`;

const formatter = (data) => {
  const get = access(data);

  return {
    taxGoalID: get('viewer.taxGoal.id'),
    goalBalances: {
      TAX: get('viewer.taxGoal.availableBalance'),
      PTO: get('viewer.ptoGoal.availableBalance'),
      HEALTH_EXPENSES: get('viewer.healthExpensesGoal.availableBalance'),
      FAMILY_LEAVE: get('viewer.familyLeaveGoal.availableBalance'),
      RETIREMENT: get('viewer.retirementGoal.totalBalance'),
      EMERGENCY_SAVINGS: get('viewer.emergencySavingsGoal.totalBalance'),
    },
  };
};

export default {
  document: GOAL_BALANCES,
  formatter,
};
