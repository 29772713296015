import create from 'zustand';

/**
 * Stores the current user locale
 */
const store = create((set, get) => ({
  locale: 'en',
  changeLocale: locale => set({ locale }),
  nextLanguage: () => (get().locale === 'en' ? 'Español' : 'English'),
}));

export const useLocale = store;

export const toggleLanguage = () => {
  const { locale, changeLocale } = store.getState();
  changeLocale(locale === 'en' ? 'es-US' : 'en');
};
