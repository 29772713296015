import React from 'react';
import { StyleSheet, View, ViewStyle } from 'react-native';
import { Action } from '@types';
import { handleAction } from '@navigate';
import { Text, Pressable, useTheme, CatchTheme } from '@uikit';

interface NavLinkProps {
  active: boolean;
  action: Action;
  stacked?: boolean;
  centered?: boolean;
  hide?: boolean;
  style?: ViewStyle;
}

const NavLink: React.FC<NavLinkProps> = ({
  action,
  stacked,
  active,
  centered,
  hide,
  style,
  children,
}) => {
  const { theme } = useTheme();

  return (
    <View
      style={[
        stacked && styles.linkStackedOuter,
        styles.linkContainer,
        hide && styles.hidden,
        centered && styles.centered,
        style,
      ]}
    >
      <Pressable
        handleOnPress={() => handleAction(action)}
        hoveredStyle={[theme.skeletonBg]}
        style={[styles.link, stacked && styles.linkStacked, active && theme.skeletonBg]}
      >
        <Text weight="medium" color={active ? 'text' : 'subtle'} size="fp">
          {children}
        </Text>
      </Pressable>
    </View>
  );
};

/**
 * @todo standardize with other things
 */
const styles = StyleSheet.create({
  hidden: {
    transform: `translateX(-24px)`,
    opacity: 0,
    pointerEvents: 'none',
  },
  linkContainer: {
    flexDirection: 'row',
    outline: 'none',
    alignItems: 'center',
    marginHorizontal: 16,
    paddingTop: 1,
    opacity: 1,
    transform: 'none',
    transition: `${CatchTheme.animation.duration}ms ${CatchTheme.animation.curve}`,
  },
  centered: {
    marginLeft: 16,
    marginRight: 16,
  },
  link: {
    display: 'flex',
    paddingVertical: 8,
    paddingHorizontal: 16,
    marginHorizontal: -16,
    borderRadius: 99,
    lineHeight: 1,
    flexDirection: 'row',
    alignItems: 'center',
    outline: 'none',
  },
  linkStackedOuter: {
    flex: 1,
    paddingVertical: 0,
    marginHorizontal: -24,
  },
  linkStacked: {
    borderRadius: 0,
    flex: 1,
    opacity: 1,
    paddingVertical: 8,
    paddingHorizontal: 24,
    marginHorizontal: -40,
  },
});

export default NavLink;
