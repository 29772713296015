import React from 'react';
import { View } from 'react-native';
import { useCopy } from '@app/utils';
import { Text } from './Text';
import { Progress } from './Progress';
import { useTheme } from '../hooks/useTheme';

const handleRound = (percent) => {
  if (percent >= 100) return 100;
  if (percent === 0) return 0;
  if (percent < 1) return 1;
  return Math.trunc(percent);
};

export const GoalMeter = ({ target, balance, hideText, color }) => {
  const { theme } = useTheme();
  const progress = balance / target;

  const goalMet = progress >= 1;
  const { c: basics, $ } = useCopy('catch.basics');
  const { c: kit } = useCopy('catch.kit.goalMeter');

  if (!target) return null;

  return (
    <View style={[theme.yGutter1, theme.alignCenter]}>
      <Progress width={'100%'} progress={progress > 1 ? 1 : progress} thick color={color} />
      {!hideText && (
        <Text
          size="fp"
          color={goalMet ? 'success' : 'subtle'}
          weight={goalMet ? 'bold' : 'regular'}
          align="center"
          style={theme.topGutter1b}
        >
          {goalMet
            ? basics('goalMet')
            : kit('toTarget', {
                progress: handleRound(progress * 100),
                target: $(target, { whole: 0 }),
              })}
        </Text>
      )}
    </View>
  );
};

export default GoalMeter;
