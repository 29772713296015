import React, { useMemo } from 'react';
import { View, StyleSheet } from 'react-native';
import { Routes, Route, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { TransitionGroup } from 'react-transition-group';

import {
  close,
  store,
  stackLookup,
  Screen,
  LocationOverride,
  normalizePath,
  useLocationOverride,
} from '@navigate';
import { Modal } from '@uikit';
import { desnake } from '@app/utils';
import { standalone, layers, tabs } from '@pages';
import MagicNav from './MagicNav';

const ModalContent = () => {
  const location = useLocationOverride(); // this should be the default location

  return (
    <TransitionGroup>
      <Routes location={location}>
        {[...layers, ...tabs].map((Stack) => (
          <Route
            key={Stack.config.stackName}
            path={normalizePath({ stack: Stack.config.stackName })}
            element={
              <View style={styles.reset}>
                <Stack />
              </View>
            }
          />
        ))}

        {standalone.map((page) => (
          <Route
            key={page.name}
            path={normalizePath({ route: page.name })}
            element={
              <View style={styles.reset}>
                <Screen layout="sheet" name={page.name} Component={page.component} />
              </View>
            }
          />
        ))}
      </Routes>
    </TransitionGroup>
  );
};

const styles = StyleSheet.create({
  reset: {
    top: 48,
    position: 'absolute',
    flex: 1,
    width: '100%',
    bottom: 0,
  },
});

const MagicModals = () => {
  const location = useLocation(); // this should be the default location

  const { route } = useMemo(() => {
    const parsed = queryString.parse(location.search);
    const route = desnake(parsed?.flow || '');
    const stack = stackLookup(route);
    return { route, stack };
  }, [location]);

  if (!route) {
    return null;
  }

  const options = store.getState().getOptions(route);

  return (
    <LocationOverride route={route}>
      <Modal presentation={options.presentation} preventOverlayDismiss onRequestClose={close}>
        <MagicNav layout="sheet" route={route} data={location.state} />
        <ModalContent />
      </Modal>
    </LocationOverride>
  );
};

export default MagicModals;
