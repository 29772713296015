import React from 'react';
import { Platform } from 'react-native';
import { Pressable, useTheme, CatchTheme, useResponsive, Text } from '@uikit';

interface WidgetProps {
  title?: string;
  subtitleValue?: string;
  subtitleSuffix?: string;
  onPress?: () => void;
  children: any;
  isListBased?: boolean;
}

export const Widget = ({
  title = '',
  subtitleValue,
  subtitleSuffix,
  onPress = () => {},
  isListBased,
  children,
}: WidgetProps) => {
  const { theme } = useTheme();
  const { isMobile } = useResponsive();
  const CARD_WIDTH = isMobile ? '100%' : CatchTheme.constants.promptWidthLg;

  return (
    <Pressable
      handleOnPress={onPress}
      hoveredStyle={[theme._hover]}
      style={[
        theme.largeCorners,
        theme.xSpace3,
        theme.topSpace3,
        isListBased ? theme.bottomSpace1 : theme.bottomSpace3,
        theme.cardShadow,
        theme.thinBorder,
        Platform.OS === 'android' ? theme.sheetBg : theme.cardBg,
        {
          overflow: 'visible',
          maxWidth: CARD_WIDTH,
        },
      ]}
    >
      <Text size="fp" color="subtle" gutter="bottom">
        {title}
      </Text>
      {(subtitleValue || subtitleSuffix) && (
        <Text size="lg">
          <Text weight="medium">{subtitleValue}</Text>
          <Text color="subtle"> {subtitleSuffix}</Text>
        </Text>
      )}
      {children}
    </Pressable>
  );
};

export default Widget;
