import React, { useCallback, useMemo } from 'react';

import {
  getMembers,
  HealthApplicationQueryData,
  mutations,
  queries,
  useDeprecatedMutation,
  useMutation,
  useQuery,
} from '@data';
import { navigate, routes, sheets, useSheet } from '@navigate';
import { Currency, handleHealthResponse, useCopy } from '@util';
import {
  determineCoveredNonApplicantChild,
  determineDeprivationNavigation,
  determineOtherCoverage,
  determineOutsideHousehold,
} from '@navigate/EDENavigationGuard';
import {
  formatPayload,
  getInitialValues,
  getTotalIncomeForMember,
} from '@pages/health/application/incomeConfirm/incomeConfirmUtils';
import { fields } from '@pages/health/application/incomeConfirm/incomeConfirmFields';
import { Stack } from '@layouts';
import { LineItem } from '@uikit';
import { HealthMemberIncomeRow } from '@common';
import { MultiMemberSplitFormBlueprint } from '@blueprints';
import shallow from 'zustand/shallow';
import { loaders } from '@app/config';

const PREFIX = 'catch.ede.IncomeConfirm';

const IncomeConfirm = ({ applicationID, enrollId }) => {
  if (!enrollId) {
    navigate(routes.HEALTH_RESUME);
  }

  const { c } = useCopy(PREFIX);

  const { loading, data } = useQuery<HealthApplicationQueryData>(queries.HEALTH_APPLICATION, {
    variables: { applicationID },
    skip: !applicationID,
  });

  const { pathwayType, coverageYear, isRequestingFinancialAssistance, parsedQuestions, members } =
    useMemo(() => {
      return {
        pathwayType: data?.viewerTwo?.health?.application?.pathwayType,
        coverageYear: data?.viewerTwo?.health?.application?.coverageYearNumber,
        isRequestingFinancialAssistance:
          data?.viewerTwo?.health?.application?.screening?.isRequestingFinancialAssistance,
        parsedQuestions: data?.viewerTwo?.health?.application?.uiQuestionsToDisplay,
        members: getMembers(data),
      };
    }, [data]);

  const { openSheet, closeAll } = useSheet(
    (state) => ({
      openSheet: () => state.open(sheets.LOADER, loaders.HEALTH_LONG_WAIT),
      closeAll: state.closeAll,
    }),
    shallow,
  );

  const [upsert, { loading: upserting }] = useMutation(
    mutations.UPSERT_APPLICATION_MEMBERS,
    handleHealthResponse({}),
  );

  const [ensure, { loading: ensuring }] = useDeprecatedMutation('ensureHealthApplication', {
    onCompleted: ({ isIncomeDifferent, members }) => {
      const isQHPOrAPTCEligible = members.some(
        (d) => d.preliminaryQHPStatus === 'YES' || d.preliminaryAPTCStatus === 'YES',
      );

      const isMedicaidPrelim = members.some((d) => d.preliminaryMedicaidStatus === 'YES');

      if (isIncomeDifferent) {
        navigate(routes.EDE_MEMBER_INCOME_DISCREPANCY);
      } else if (isQHPOrAPTCEligible && pathwayType !== 'CALIFORNIA') {
        navigate(routes.EDE_SEP);
      } else if (isMedicaidPrelim) {
        navigate(routes.EDE_MEMBER_MEDICAL_BILLS);
      } else if (determineCoveredNonApplicantChild(members, isRequestingFinancialAssistance)) {
        navigate(routes.EDE_DEPENDENTS_CURRENT_COVERAGE);
      } else if (determineOutsideHousehold(members)) {
        navigate(routes.EDE_OUTSIDE_HOUSEHOLD);
      } else if (determineDeprivationNavigation(parsedQuestions)) {
        navigate(routes.EDE_MEMBER_WORK_HOURS);
      } else if (determineOtherCoverage(members)) {
        navigate(routes.EDE_OTHER_COVERAGE);
      } else {
        navigate(routes.EDE_REVIEW);
      }
    },
  });

  const saveMember = useCallback(
    (values) => {
      return upsert({
        variables: {
          input: {
            applicationID,
            applicationMembersInput: formatPayload({ values }),
          },
        },
      });
    },
    [applicationID],
  );

  const getTitle = useCallback(
    (member) => {
      const givenName = member?.givenName;
      return c('loadingTitle', { givenName, coverageYear, loading });
    },
    [coverageYear, loading],
  );
  const getSubTitle = useCallback(
    (member) => {
      const givenName = member?.givenName;
      return c('subtitle', { givenName, coverageYear });
    },
    [coverageYear],
  );

  const onSubmit = useCallback(async () => {
    openSheet(sheets.LOADER);
    await ensure({ variables: { applicationID } });
    closeAll();
  }, [ensure]);

  return (
    <MultiMemberSplitFormBlueprint
      members={members}
      loading={loading}
      submitting={ensuring}
      title={getTitle}
      subtitle={getSubTitle}
      getInitialValues={getInitialValues}
      data={{ coverageYear }}
      fields={fields}
      onNext={saveMember}
      onComplete={onSubmit}
      precontent={({ member }) => (
        <Stack spacing="3" style={{ marginBottom: 60 }}>
          <Stack spacing="1">
            <LineItem
              noSpace
              prominent={true}
              left={c('sectionTitle')}
              right={<Currency whole>{getTotalIncomeForMember(member) || 0}</Currency>}
            />
            <>
              {member?.incomeSources?.map((i, index) => (
                <HealthMemberIncomeRow key={index} incomeSource={i} />
              ))}
              {member?.deductions?.map((d, index) => (
                <HealthMemberIncomeRow key={index} incomeSource={d} expense={true} />
              ))}
            </>
          </Stack>
        </Stack>
      )}
    />
  );
};

export const IncomeConfirmView = {
  name: 'EDE_MEMBER_INCOME_CONFIRM',
  component: IncomeConfirm,
  options: {
    ...MultiMemberSplitFormBlueprint.options,
    title: 'Income',
  },
};
