import gql from 'graphql-tag';
import { PROVIDER, DRUG, SCORED_HEALTH_PLAN, HEALTH_PLAN, TOP_PLANS } from './healthPlans';

export const EXPLORER_APPLICANT = gql`
  fragment ExplorerApplicant on HealthExplorerDependent {
    id
    age
    relation
    aptcEligible
    hasMec
    isApplying
    isParent
    isPregnant
    isSmoker
  }
`;

export const EXPLORER_DATA = gql`
  fragment ExplorerData on HealthExplorerData {
    id
    coverageYear
    isSEP
    countyfips
    state
    zipcode
    pathwayType
    income
    providerPlanID
    canCreateEnrollment
    dependents {
      id
      ...ExplorerApplicant
    }
    healthPreference {
      financialPreference
      plannedUsage
      drugsWanted {
        ...Drug
      }
      providersWanted {
        ...Provider
      }
    }
    marketplaceEligibility {
      advancePremiumTaxCredit: aptc
      costSharingReductions: csr
      isMedicaidCHIP: isMedicaidChip
    }

    healthPlans {
      plans {
        planID: id
        ...ScoredHealthPlan
      }
      topPlans {
        ...TopPlans
      }
    }

    healthPlanDetails {
      id
      ...HealthPlan
    }
  }
  ${PROVIDER}
  ${DRUG}
  ${EXPLORER_APPLICANT}
  ${SCORED_HEALTH_PLAN}
  ${TOP_PLANS}
  ${HEALTH_PLAN}
`;
