import React from 'react';
import { Platform, View } from 'react-native';

import { useDeprecatedQuery } from '@data';
import { useResponsive, s, SimpleRow, Button, CatchTheme } from '@uikit';
import { Page, Layout, Stack } from '@layouts';
import { useCopy } from '@app/utils';
import { handleAction, routes, links, useCurrentRoute } from '@navigate';
import { useAuth } from '@client';
import { date } from '@app/utils/format/date';
import VersionLabel from '@app/dev/VersionLabel';

import User from '@svg/user.svg';
import Linked from '@svg/linked.svg';
import Settings from '@svg/settings.svg';
import Refer from '@svg/gift.svg';
import Docs from '@svg/file.svg';
import Help from '@svg/help-circle.svg';
import Avatar from '../components/Avatar';

const sidebarTitles = [
  { title: 'info', icon: User, action: { type: 'ROUTE', route: routes.ACCOUNT_INFO } },
  { title: 'accounts', icon: Linked, action: { type: 'ROUTE', route: routes.LINKED_BANKS } },
  { title: 'settings', icon: Settings, action: { type: 'ROUTE', route: routes.ACCOUNT_SETTINGS } },
  // { title: 'referral', icon: Refer, action: { type: 'ROUTE', route: routes.REFERRAL } },
  { title: 'documents', icon: Docs, action: { type: 'ROUTE', route: routes.STATEMENTS } },
  { title: 'help', icon: Help, action: { type: 'HELP' } },
];

const ProfileMenu = () => {
  const { c } = useCopy('catch.module.me');

  const { avatarImage, joinDate, fullName, waiting, userID } = useDeprecatedQuery('user');
  const signOut = useAuth((state) => state.signOut);

  // ts-ignore
  const { longRange, isNative } = useResponsive();
  const isMobile = longRange === 'mobile';
  const route = useCurrentRoute();

  return (
    // @ts-ignore
    <Page>
      {/* @ts-ignore */}
      <Layout.Scroll margins topSpace bottomSpace>
        <View
          style={[
            !isMobile && Platform.OS === 'web' && { width: CatchTheme.constants.promptWidthSm },
            s?.bottomSpace4,
            s?.transitionBg,
          ]}
        >
          {/* @ts-ignore */}
          <Layout topSpace bottomSpace>
            <Stack spacing="1">
              <Avatar img={avatarImage} id={userID} loading={waiting} size="xl" />
              <Layout.Header
                bottomSpace={isMobile}
                loading={waiting}
                titleSize="form"
                title={fullName}
                subtitleSize="fp"
                subtitle={`Joined ${date(joinDate, 'FULL', { addSuffix: true })}`}
              />
            </Stack>
            {isMobile && (
              <Button
                full
                small
                accentColor="brand"
                onPress={() => handleAction({ type: 'ROUTE', route: routes.REFERRAL })}
              >
                {`${c('MeView.referralLink')}, ${c('ReferralView.title2')}`}
              </Button>
            )}
          </Layout>

          <Stack separatorComponent>
            {sidebarTitles.map(({ title, icon, action }) => (
              <SimpleRow
                light={!icon}
                key={title}
                label={c(`MeView.${title}Link`)}
                onPress={() => handleAction(action)}
                interaction={isMobile && !!icon ? 'navigation' : undefined}
                testID="navigation-row"
                textColor={
                  !icon
                    ? 'subtler'
                    : isMobile || isNative || route === action.route
                    ? 'text'
                    : 'subtle'
                }
                asset={{
                  svg: icon,
                  size: 'xs',
                  containerSize: 'xs',
                  color: route === action.route || isMobile || isNative ? 'text' : 'subtler',
                }}
              />
            ))}
          </Stack>
          <SimpleRow
            light
            key={'disclosures'}
            label={c(`MeView.disclosuresLink`)}
            onPress={() => handleAction({ type: 'LINK', route: links.LEGAL })}
            testID="navigation-row"
            textColor={'subtler'}
            asset={{ size: 'xs', containerSize: 'xs' }}
          />
          <SimpleRow
            light
            key={'sign-out'}
            label={c(`SignOutButton`)}
            onPress={() => signOut()}
            testID="sign-out"
            textColor="credit"
            asset={{ size: 'xs', containerSize: 'xs' }}
          />

          <Layout centerAll topSpace>
            <VersionLabel align={isMobile ? 'center' : 'flex-start'} always />
          </Layout>
        </View>
      </Layout.Scroll>
    </Page>
  );
};

export default ProfileMenu;
