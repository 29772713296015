import { Platform } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';

function getStorage(storageType) {
  if (global[storageType] != null) {
    return global[storageType];
  }

  let data = {};

  return {
    setItem: (id, val) => (data[id] = String(val)),
    getItem: (id) => (data.hasOwnProperty(id) ? data[id] : undefined),
    removeItem: (id) => delete data[id],
    clear: () => (data = {}),
  };
}

// Support for web and native cache storage
const localStorage = Platform.select({
  web: getStorage('localStorage'),
  default: AsyncStorage,
});

const sessionStorage = getStorage('sessionStorage');

export { localStorage, sessionStorage };
