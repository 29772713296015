import { useState } from 'react';
import { Platform } from 'react-native';

import S3 from 'aws-sdk/clients/s3';
import * as AWS from 'aws-sdk/global';

import { createLogger, Env } from '@app/utils';
import * as Cognito from './Cognito';

const Log = createLogger('aws-upload');

const fileTypes = {
  JPG: 'JPEG',
  JPEG: 'JPEG',
  'image/jpeg': 'JPEG',
  'image/png': 'PNG',
  PNG: 'PNG',
  'application/pdf': 'PDF',
  'image/heif': 'HEIF',
  'image/heic': 'HEIC',
  HEIF: 'HEIF',
  HEIC: 'HEIC',
};
const mimeTypes = {
  JPEG: 'image/jpeg',
  PNG: 'image/png',
  PDF: 'application/pdf',
  HEIC: 'image/heic',
  HEIF: 'image/heif',
};
// 10MB
const DEFAULT_MAX = 10000000;

/**
 * Updates the AWS config with the latest cognito credentials and creates
 * a new instance of the S3 helper class
 * @params {String} jwt
 * @return {Class} s3
 */
function createS3(jwt) {
  return new Promise((resolve, reject) => {
    const region = Env.authCreds.region;
    const credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: Env.authCreds.identityPoolId,
      Logins: {
        [`cognito-idp.${region}.amazonaws.com/${Env.authCreds.userPoolId}`]: jwt,
      },
    });
    const opts = {
      region,
      credentials,
    };
    Log.debug(opts);
    AWS.config.update(opts);
    // Sometimes they might be expired for some reason
    AWS.config.credentials.refresh((err) => {
      if (err) {
        Log.debug(err);
      }
      const instance = new S3({
        apiVersion: '2006-03-01',
        params: {
          Bucket: Env.uploads.bucket,
        },
        region,
      });
      resolve(instance);
    });
  });
}

export function useUpload({
  filePrefix,
  onSuccess,
  onDelete,
  maxSize = DEFAULT_MAX,
  acceptedFileTypes = fileTypes,
}) {
  const [files, setFile] = useState({});
  /**
   * Gets the latest cognito credentials, instanciate the S3
   * create an upload promise and awaits the results from AWS
   * the callback posts the result to the api
   * See the FileInput component for the payload description
   */
  async function handleAWS({
    name,
    file,
    onProgress,
    onStart,
    onStop,
    onError,
    onCompleted,
    onTooHeavy,
    onWrongFormat,
  }) {
    if (file?.size > maxSize) {
      onTooHeavy(true);
      return;
    }
    if (!acceptedFileTypes[file?.type]) {
      onWrongFormat(true);
      return;
    }
    if (onStart) {
      onStart();
    }
    setFile({ ...files, [name]: file });
    try {
      // 1) Get the cognito credentials
      const jwt = await Cognito.getJWTToken();
      // 2) instanciate the S3 api
      const s3 = await createS3(jwt);

      const fileType = fileTypes[file.type];
      // 4) Append a nonce and filetype too
      const fileKey = `${filePrefix}-${Date.now()}.${fileType}`;
      // prep the payload cross platform changes

      Log.debug(mimeTypes[fileType]);

      const payload = Object.assign(
        { Key: fileKey, ACL: 'private', ServerSideEncryption: 'AES256' },
        Platform.select({
          web: {
            Body: file,
          },
          default: {
            Body: file.data,
            ContentType: mimeTypes[fileType],
          },
        }),
      );

      // 6) Create an upload promise
      const upload = s3.upload(payload).on('httpUploadProgress', ({ total, loaded }) => {
        if (total && loaded) {
          onProgress(loaded / total);
        }
      });
      // 7) Wait for the promise to come back
      const result = await upload.promise();

      Log.debug(result);
      // Tell the FileInput component we're done uploading
      if (onStop) {
        onStop();
      }
      // 8) Post the result to the api
      if (onSuccess) {
        onSuccess({ result, fileKey, fileType, name, fileObject: file });
      }
    } catch (e) {
      Log.debug(e);
      if (onStop) {
        onStop();
      }
      onError(true);
    }

    if (onCompleted) {
      onCompleted();
    }
  }

  function deleteFile(name) {
    setFile({ ...files, [name]: null });
    Log.debug(`Deleting ${name}`);
    if (onDelete) {
      onDelete(name);
    }
  }

  return { upload: handleAWS, files, deleteFile };
}
