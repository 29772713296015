import React from 'react';
import { routes } from '@navigate';
import { BasicLayout, Toolbar } from '@app/layouts';
import { Button, Option, OptionGroup } from '@app/_ui-kit';

import { HealthSlasherStackScreenProps } from '../HealthSlasherStack';

import Alert from '@svg/information.svg';
import Check from '@svg/check.svg';
import Roll from '@svg/catch-roll.svg';
import Warning from '@svg/warning-triangle.svg';

type SlasherResultOutcome = 'POSITIVE' | 'NEGATIVE' | 'NOCHANGE';

const COPY: Record<SlasherResultOutcome, any> = {
  POSITIVE: {},
  NEGATIVE: {
    title: 'Looks like you may be getting credits based on a lower income estimate',
    subtitle:
      'You can adjust your credits now, or wait until tax day to pay back ineligible credits you may be receiving.',
    icon: Alert,
    color: 'coverage',
  },
  NOCHANGE: {
    title: 'Looks like you’re getting all the credits you qualify for',
    subtitle: 'We’ll keep an eye out for new tax credits that become available.',
    icon: Check,
    color: 'coverage',
  },
};

/**
 * Handles the case where APTC is the same or gone up
 * We do not actually do any submission on this page
 */
const HealthSlasherHiccup = ({
  handleNext,
  handleExit,
  outcome,
}: HealthSlasherStackScreenProps) => {
  const copy = COPY[outcome];

  return (
    <BasicLayout
      icon={{ svg: copy.icon, gradient: copy.color }}
      title={copy.title}
      subtitles={[copy.subtitle]}
      toolbar={
        <Toolbar type="stack">
          {outcome === 'NOCHANGE' && <Button onPress={handleExit}>Done</Button>}
        </Toolbar>
      }
    >
      {outcome === 'NEGATIVE' && (
        <OptionGroup>
          <Option
            onPress={handleNext}
            label="Adjust my premiums"
            description="Lower my tax bill"
            asset={{ svg: Roll, ...iconSizing(false) }}
          />
          <Option
            onPress={handleExit}
            label="Leave as is"
            description="Pay more at tax time"
            asset={{
              svg: Warning,
              ...iconSizing(false),
            }}
          />
        </OptionGroup>
      )}
    </BasicLayout>
  );
};

const iconSizing = (selected) => ({
  bg: selected ? 'fg' : 'border',
  color: selected ? 'bg' : 'fg',
  shape: 'circle',
  size: 'xs',
  containerSize: 'md',
});

export const HealthSlasherHiccupView = {
  name: routes.HEALTH_SLASHER_HICCUP,
  component: HealthSlasherHiccup,
  options: {},
};
