import React from 'react';
import { View } from 'react-native';

import { open, routes, ScreenDefinition } from '@navigate';
import { Layout, Page, Section } from '@app/layouts';
import { ComplexRow, Text, Loading, CatchTheme } from '@app/_ui-kit';
import { useDeprecatedQuery } from '@app/data';
import { useCopy } from '@app/utils';
import { groupBy } from '@app/utils/misc';
import { useTaxPaymentPeriod } from '@hooks';
import { TaxQuarterDonut } from '@app/charts/TaxQuarterDonut';
import { taxPaymentIcon, taxPaymentStatus, taxPaymentJurisdiction } from '@app/utils/format/status';

const isCurrentYear = (date) => date === `${new Date().getFullYear()}`;

const TaxPaymentEvent = ({ payment }) => {
  const { $ } = useCopy('catch.basics');
  return (
    <ComplexRow
      asset={{ ...taxPaymentIcon(payment), containerSize: 'md' }}
      key={payment?.id}
      label={`${$(payment?.amount)} ${payment?.timeframe} ${taxPaymentJurisdiction(
        payment?.jurisdiction,
      )}`}
      sublabel={taxPaymentStatus(payment)}
      onPress={() => {
        open(routes.TAX_PAYMENT, { paymentId: payment?.id });
      }}
    />
  );
};

export const TaxPaymentHistoryContent = ({
  years,
  taxGoal,
  SectionComponent: GroupComponent = View,
  onlyShowRecent,
  allPayments,
}) => {
  const { $ } = useCopy('catch.basics');
  const { quarter, quarterProgress, dueDate } = useTaxPaymentPeriod();

  const canPopulateFuturePayments = false && taxGoal?.autoTax;
  const currentYear = new Date().getFullYear();
  if (!years[currentYear] && canPopulateFuturePayments) years[currentYear] = [];

  return onlyShowRecent ? (
    <Section title="Payments">
      {canPopulateFuturePayments && (
        <ComplexRow
          key={'scheduled'}
          label={`${quarter} Payment`}
          sublabel={`Scheduled for ${dueDate}`}
          asset={{
            size: 'md',
            element: <TaxQuarterDonut percent={quarterProgress} size={CatchTheme.assets.md} />,
          }}
          accessory={
            <Text size="fp" color="subtle">
              {$(taxGoal?.totalBalance)}
            </Text>
          }
        />
      )}
      <GroupComponent>
        {allPayments?.slice(0, 4)?.map((pmt, indx) => (
          <TaxPaymentEvent payment={pmt} key={pmt?.id} />
        ))}
      </GroupComponent>
    </Section>
  ) : (
    <>
      {Object.entries(years)
        ?.reverse()
        ?.map(([k, v]) => (
          <Section
            key={k}
            title={k}
            subtitle={`${$(
              v
                ?.filter((p) => p.status !== 'CANCELED')
                ?.reduce((item, acc) => (item += acc?.amount), 0),
            )} paid`}
          >
            {isCurrentYear(k) && canPopulateFuturePayments && (
              <ComplexRow
                label={`${quarter} Payment`}
                sublabel={`Scheduled for ${dueDate}`}
                asset={{
                  size: 'md',
                  element: (
                    <TaxQuarterDonut percent={quarterProgress} size={CatchTheme.assets.md} />
                  ),
                }}
                accessory={
                  <Text size="fp" color="subtle">
                    {$(taxGoal?.totalBalance)}
                  </Text>
                }
              />
            )}

            <GroupComponent>
              {v?.map((pmt, indx) => (
                <TaxPaymentEvent payment={pmt} key={indx} />
              ))}
            </GroupComponent>
          </Section>
        ))}
    </>
  );
};

export const TaxPaymentHistory = () => {
  const { taxPayments, taxGoal, waiting } = useDeprecatedQuery('taxGoal');

  var years = groupBy(taxPayments, 'year');

  return (
    <Page color="sheet" margins>
      <Layout.Scroll margins>
        {waiting ? (
          <Loading accentColor="taxes" />
        ) : (
          <TaxPaymentHistoryContent years={years} taxGoal={taxGoal} />
        )}
      </Layout.Scroll>
    </Page>
  );
};

export const TaxPaymentHistoryView: ScreenDefinition = {
  name: routes.TAX_PAYMENT_HISTORY,
  component: TaxPaymentHistory,
  options: {},
};
