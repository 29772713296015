import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Copy } from '@app/types';
import { Text } from './Text';
import { useTheme } from '../hooks/useTheme';

interface BadgeProps {
  label?: Copy;
}

export const Badge: React.FC<BadgeProps> = ({ label }) => {
  const { theme } = useTheme();

  return (
    <View style={[theme.borderColor, styles.container]}>
      <Text size="sm">{label}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    borderRadius: 50000,
    paddingVertical: 4,
    paddingHorizontal: 8,
  },
});

export default Badge;
