import gql from 'graphql-tag';
import { WorkType, FilingStatus } from '@types';

export interface UpdateUserVars {
  user: {
    nickname?: string;
    givenName?: string;
    familyName?: string;
    dob?: string;
    jobTitle?: string;
    workType?: WorkType;
    filingStatus?: FilingStatus;
    estimated1099Income?: number;
    estimatedW2Income?: number;
    numTaxDependents?: number;
    workState?: string;
    spouseIncome?: number;
    legalAddress?: {
      street1?: string;
      street2?: string;
      city?: string;
      state?: string;
      zip?: string;
    };
    isControlPerson?: boolean;
    isFirmAffiliated?: boolean;
    isPoliticallyExposed?: boolean;
    subjectBackupWithholding?: boolean;
    trustedContact?: {
      name?: string;
      email?: string;
      phoneNumber?: string;
    };
  };
}

export interface UpdateUserResponse {
  updateUserNew: {
    user: {
      id: string;
      nickname?: string;
      givenName?: string;
      familyName?: string;
      dob?: string;
      jobTitle?: string;
      workType?: WorkType;
      filingStatus?: FilingStatus;
      estimated1099Income?: number;
      estimatedW2Income?: number;
      numTaxDependents?: number;
      workState?: string;
      spouseIncome?: number;
      legalAddress?: {
        street1?: string;
        street2?: string;
        city?: string;
        state?: string;
        zip?: string;
      };
      isControlPerson?: boolean;
      isFirmAffiliated?: boolean;
      isPoliticallyExposed?: boolean;
      subjectBackupWithholding?: boolean;
      ssn?: string;
      trustedContact?: {
        name?: string;
        email?: string;
        phoneNumber?: string;
      };
      __typename: 'User';
    };
    error?: {
      code?: string;
      field?: string;
      message?: string;
    };
  };
}

export const UpdateUser = gql`
  mutation UpdateUser($user: UpdateUserInput!) {
    updateUserNew(input: $user) {
      user {
        id
        nickname
        givenName
        familyName
        dob
        ssn
        jobTitle
        workType
        filingStatus
        estimated1099Income
        estimatedW2Income
        numTaxDependents
        workState
        spouseIncome
        legalAddress {
          street1
          street2
          city
          state
          zip
        }
        isControlPerson
        isFirmAffiliated
        isPoliticallyExposed
        subjectBackupWithholding
        dba
        ein
      }
      error {
        code
        field
        message
      }
    }
  }
`;
