import React from 'react';
import { View } from 'react-native';
import { Text } from '@uikit';

const FieldFooter = ({ subtext, error, errorItem }) => {
  return (
    <View>
      {error && (
        <Text size="fp" color="credit">
          {error}
        </Text>
      )}
      {!error && errorItem}
      {subtext && (
        <Text size="fp" color="subtle">
          {subtext}
        </Text>
      )}
    </View>
  );
};

export default FieldFooter;
