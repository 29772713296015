import React from 'react';
import { routes } from '@navigate';
import { HealthSlasherStackScreenProps } from '../HealthSlasherStack';
import { Layout } from '@app/layouts';

/**
 * Quick Renew Application Details
 * - Shows the 2023 application details like income, members, etc.
 */
const HealthRenewDetails = ({ handleNext, loading }: HealthSlasherStackScreenProps) => {
  return <Layout.Header title="App Details" />;
};

export const HealthRenewDetailsView = {
  name: routes.HEALTH_RENEW_DETAILS,
  component: HealthRenewDetails,
  options: {},
};
