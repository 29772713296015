import React, { useMemo } from 'react';
import { PolicyStatusType } from '@app/types';
import { Stack } from '@app/layouts';
import { Badge } from '@uikit';

// the set of statuses to show for
const statuses = {
  ACTIVE_PENDING_PAYMENT: 'Pending Payment',
  PENDING_START_DATE: 'Upcoming',
  PENDING: 'Docs Required',
  CANCELLED: 'Cancelled',
  TERMINATED: 'Terminated',
};

interface CoverageBadgesProps {
  type: 'PLAN_DETAILS' | 'POLICY_STATUS' | 'NONE';
  policyStatus?: PolicyStatusType;
  metalLevel?: string;
  planType?: string; // HMO, PPO etc.
  hsaEligible?: boolean;
}

const CoverageBadges: React.FC<CoverageBadgesProps> = ({
  type,
  policyStatus,
  metalLevel,
  planType,
  hsaEligible,
}) => {
  const badges = useMemo(() => {
    const list: Array<{ id: string; label: string }> = [];

    if (type === 'POLICY_STATUS' && !!policyStatus && policyStatus in statuses) {
      list.push({ id: 'status', label: statuses[policyStatus] });
    }

    if (type === 'PLAN_DETAILS') {
      if (!!planType) list.push({ id: 'plan-type', label: planType });
      if (!!metalLevel) list.push({ id: 'metal-level', label: metalLevel });
      if (!!hsaEligible) list.push({ id: 'hsa-eligible', label: 'HSA eligible' });
    }

    return list;
  }, [type, policyStatus, metalLevel, planType, hsaEligible]);

  if (badges.length === 0) return null;

  return (
    <Stack horizontal spacing="0b">
      {badges.map((badge) => (
        <Badge key={badge.id} label={badge.label} />
      ))}
    </Stack>
  );
};

export default CoverageBadges;
