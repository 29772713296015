import React from 'react';
import { useCopy, handleHealthErrors } from '@app/utils';
import { useDeprecatedMutation } from '@data';
import { SheetBlueprint } from '@app/blueprints';
import { popToast } from '@uikit';
import { exit, routes, SheetComponentProps } from '@navigate';

const CancelPolicySheet: React.FC<SheetComponentProps['CANCEL_POLICY']> = ({
  enrollmentID,
  enrollmentType,
  actionEffectiveDate,
  closeSheet,
}) => {
  const { c } = useCopy('catch.ede.cancelPolicyPrompt');
  const { c: basics } = useCopy('catch.basics');

  const [cancel, { loading }] = useDeprecatedMutation('terminateHealthEnrollment', {
    onCompleted: () => {
      popToast({ type: 'success', title: 'Coverage successfully terminated' });
      exit(routes.COVERAGE);
    },
    ...handleHealthErrors,
  });

  const onSubmit = () =>
    cancel({
      variables: {
        id: enrollmentID,
        cancelActiveCoverage: true,
        actionEffectiveDate,
      },
    });

  return (
    <SheetBlueprint
      loading={loading}
      title={c('title', { enrollmentType })}
      subtitles={[c('warning')]}
      actions={[
        { label: basics('neverMind'), onAction: closeSheet },
        { label: c('confirm'), destructive: true, onAction: onSubmit },
      ]}
    />
  );
};

export default CancelPolicySheet;
