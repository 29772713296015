import React, { useState } from 'react';
import { View, Platform, StyleSheet, unstable_createElement } from 'react-native';

import { useTheme } from '@uikit';
import { useID } from '@uikit/hooks/useID';
import { Text } from '../Text';
import { HelpText } from '../HelpText';

/**
 * @todo this component is drunk
 */
const Checkbox = ({
  disabled,
  onChange,
  onChangeSpecial,
  onValueChange,
  value,
  small,
  label,
  tldrLabel,
  largeHeader,
  style,
  gutter,
  checked,
  divider,
  description,
  alignTop,
  extraLabel,
  testID,
  medium,
  children,
  fp,
  contStyles,
  labelStyle,
  labelSize,
  wrapperStyle,
  inside = false,
  tooltip,
  ...other
}) => {
  const { theme } = useTheme();
  const fieldID = useID();
  const descID = useID();
  const [isFocused, setIsFocused] = useState(false);
  const [isPressed, setIsPressed] = useState(false);

  const handleChange = (event) => {
    const value = event.nativeEvent.target.checked;
    event.nativeEvent.value = value;
    onChange && onChange(event);
    onValueChange && onValueChange(value);
  };
  const handleFocus = (e) => {
    if (!isPressed) {
      setIsFocused(true);
    }
  };
  const handleBlur = () => setIsFocused(false);
  const webControl = Platform.select({
    web: () =>
      unstable_createElement('input', {
        id: fieldID,
        type: 'checkbox',
        checked: value,
        disabled: disabled,
        onBlur: handleBlur,
        onFocus: handleFocus,
        onChange: handleChange,
        onMouseUp: () => setIsPressed(false),
        onMouseDown: () => setIsPressed(true),
        'aria-describedby': testID,
        'aria-labeledby': testID,
        style: [styles.nativeControl, styles.cursorInherit],
        testID,
      }),
    default: () => {},
  });
  const containerStyles = [theme.checkboxContainer];
  const checkboxStyles = [theme.checkbox];
  const checkboxControlStyles = [theme.checkboxControl];
  if (checked) {
    checkboxControlStyles.push(theme.checkboxControlChecked);
    checkboxStyles.push(theme.checkboxChecked);
  }
  if (disabled) {
    checkboxStyles.push(theme.disabled);
  }
  if (isFocused) {
    containerStyles.push(theme.elementFocused);
  }
  if (small) {
    checkboxControlStyles.push(theme.checkboxControlSmall);
    checkboxStyles.push(theme.checkboxSmall);
  }
  const fakeEvent = {
    nativeEvent: {
      target: {
        checked: !value,
      },
    },
    target: {
      checked: !value,
    },
  };

  const hasSummary = largeHeader || description;
  const useSubtleSublabel = fp || tldrLabel;
  return (
    <View
      accessibilityRole={Platform.select({
        default: 'checkbox',
        web: undefined,
      })}
      onStartShouldSetResponder={() => Platform.OS !== 'web'}
      onResponderTerminationRequest={() => Platform.OS === 'web'}
      onResponderRelease={() => handleChange(fakeEvent)}
      style={[
        styles.wrapper,
        disabled && styles.cursorDefault,
        gutter && theme[`${gutter}Gutter2`],
        divider && theme.bottomSpace2,
        divider && theme.dividerHorizontal,
        alignTop && theme.startRow,
        style,
      ]}
      {...other}
    >
      <View style={[containerStyles, contStyles]}>
        <View style={checkboxStyles} />
        <View style={checkboxControlStyles} />
        {webControl()}
      </View>
      {(label || description) && (
        <View style={[theme.flex, theme.flex1, theme.leftGutter1]}>
          {tldrLabel && (
            <Text
              htmlFor={fieldID}
              nativeID={testID}
              color="text"
              size="p"
              weight="semibold"
              accessibilityRole={Platform.select({
                web: inside ? 'none' : 'label',
              })}
            >
              {tldrLabel}
            </Text>
          )}
          {label && (
            <View style={[theme.flex, theme.spaceBetween, { flexDirection: 'row' }]}>
              <Text
                fluid
                htmlFor={fieldID}
                nativeID={testID}
                key="label-text"
                color={hasSummary ? 'text' : useSubtleSublabel ? 'secondary' : 'text'}
                size={hasSummary ? 'p' : useSubtleSublabel ? 'fp' : 'fp'}
                weight={hasSummary ? 'medium' : useSubtleSublabel ? 'regular' : 'regular'}
                style={labelStyle}
                accessibilityRole={Platform.select({
                  web: inside ? 'none' : 'label',
                })}
              >
                {label}
              </Text>
              {tooltip && <HelpText content={tooltip} />}
            </View>
          )}
          {description && (
            <Text fluid nativeID={descID} color="subtle" size="fp">
              {description}
            </Text>
          )}
          {children}
        </View>
      )}
      {extraLabel && <View style={theme.leftSpace2}>{extraLabel}</View>}
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%',
    ...Platform.select({ web: { cursor: 'pointer' } }),
  },
  cursorDefault: {
    ...Platform.select({ web: { cursor: 'default' } }),
  },
  cursorInherit: {
    ...Platform.select({ web: { cursor: 'inherit' } }),
  },
  nativeControl: {
    ...StyleSheet.absoluteFillObject,
    height: '100%',
    margin: 0,
    opacity: 0,
    padding: 0,
    width: '100%',
  },
});

export default Checkbox;
