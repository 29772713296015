import gql from 'graphql-tag';
import access from 'safe-access';
import { source } from '../fragments';

export const INCOME_TRANSACTIONS_PAGED = gql`
  query IncomeTransactionsPaged($sourceID: ID, $page: Pagination, $incomeAction: [IncomeAction!]) {
    viewer {
      id
      incomeTransactionsPaged(sourceID: $sourceID, pagination: $page, incomeAction: $incomeAction) {
        pageInfo {
          currentPageNumber
          hasNextPage
          hasPrevPage
          totalItems
        }
        edges {
          id
          ... on IncomeTransaction {
            id
            incomeAmount: amount
            transferAmount
            date
            status
            transfer {
              id
              status
            }
            source {
              id
              ...Source
            }
          }
        }
      }
    }
  }
  ${source.fragments.source}
`;

const formatter = (data) => {
  const get = access(data?.viewer);
  const transactions = get('incomeTransactionsPaged.edges');
  const pageInfo = get('incomeTransactionsPaged.pageInfo');

  return { transactions, pageInfo };
};

export default {
  document: INCOME_TRANSACTIONS_PAGED,
  formatter,
};
