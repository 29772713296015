import React from 'react';
import { reduxForm } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { View, StyleSheet } from 'react-native';

import { Loading, Checkbox, Text, useTheme } from '@uikit';
import { healthFormValidations as validators } from '@app/forms_old/validators';
import { HealthField } from '@app/forms_old/fields';
import { Split, Layout, FlowLayout } from '@layouts';
import { BigQuestion, PersonCard } from '@common';
import { getCopy, getFullName } from '@app/utils';

const PREFIX = 'catch.ede.WorkHours';
export const COPY = {
  updateAllMembers: (vals) => <FormattedMessage id={`${PREFIX}.updateAllMembers`} values={vals} />,
  backButton: <FormattedMessage id={`${PREFIX}.backButton`} />,
};

const WorkHoursForm = ({
  handleSubmit,
  initialValues,
  setActiveChild,
  setUpdateAll,
  activeChild,
  updateAll,
  loading,
  invalid,
  parents,
}) => {
  const { theme } = useTheme();
  const children = initialValues.members || [];
  const numChildren = children.length;
  const activeChildName = getFullName(children[activeChild]);

  const goToNextChild = () => {
    if (activeChild < numChildren - 1 && !updateAll) {
      setActiveChild(activeChild + 1);
    } else {
      handleSubmit();
    }
  };
  return (
    <FlowLayout onNext={goToNextChild} canClickNext={!invalid && !loading}>
      <Layout>
        <Split>
          <BigQuestion
            title={getCopy('242_L')}
            subtitle={getCopy('242_Q', { childName: activeChildName })}
          />
          <View />
        </Split>
      </Layout>
      <Split>
        <Text size="fp" color="subtle">
          {getCopy('242_T')}
        </Text>
        {loading ? (
          <Loading accentColor="coverage" />
        ) : (
          <View style={styles.topOffset}>
            {parents.map((p, i) => (
              <PersonCard
                key={`p-${i}`}
                givenName={p.givenName}
                familyName={p.familyName}
                checked
                row
                containerStyle={theme.cardPadding}
                shadow={false}
              >
                <HealthField
                  lID="242_L"
                  type="text"
                  name={`members[${activeChild}].parent${i + 1}WeeklyWorkHourQuantity`}
                  format="hours"
                  compound
                  short
                />
              </PersonCard>
            ))}
            {numChildren > 1 && activeChild < numChildren - 1 && (
              <Checkbox
                label={COPY['updateAllMembers']({
                  category: numChildren - activeChild - 1,
                  childName: getFullName(children[numChildren - 1]),
                  children: children
                    .reduce((acc, c, i) => {
                      if (i > activeChild && i < numChildren - 1) {
                        return [...acc, getFullName(c)];
                      }
                      return acc;
                    }, [])
                    .join(', '),
                })}
                checked={updateAll}
                onChange={() => setUpdateAll(!updateAll)}
                gutter="bottom"
                alignTop
              />
            )}
          </View>
        )}
      </Split>
    </FlowLayout>
  );
};

const styles = StyleSheet.create({
  topOffset: {
    marginTop: -88,
  },
});

export default reduxForm({
  form: 'workHoursForm',
  enableReinitialize: true,
  validate: validators.workHoursForm,
})(WorkHoursForm);
