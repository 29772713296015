import React, { useCallback, useState } from 'react';
import { Clipboard, StyleSheet } from 'react-native';

import { Text, Asset, CatchTheme } from '@uikit';
import { Segment } from '@app/utils';
import { Layout, Stack } from '@app/layouts';
import { useSheet } from '@navigate';

import QRCode from '@svg/qr-code.svg';
import Copy from '@svg/copy.svg';

const ReferralCode = ({ url, code, loading }) => {
  const [copied, markAsCopied] = useState(false);
  const { open: openSheet } = useSheet();

  const handleCopy = () => {
    markAsCopied(true);
    Clipboard.setString(url);
    Segment.copiedRefLink(code);
    setTimeout(() => markAsCopied(false), 3000);
  };

  const showQRCode = useCallback(() => {
    openSheet('QRCODE', { url });
  }, [openSheet]);

  return (
    <Layout centerAll>
      <Layout color="brandLight" style={styles.gradientStyle}>
        <Stack horizontal spacing="4" flexItem={1}>
          <Asset svg={QRCode} onPress={showQRCode} color="brand" />
          <Text
            mono
            technical
            align="center"
            size={copied ? 'p' : 'lg'}
            loading={loading}
            skeletonWidth={120}
            color={copied ? 'brand' : 'text'}
          >
            {copied ? 'copied' : code}
          </Text>
          <Asset svg={Copy} onPress={handleCopy} color="brand" disabled={copied} />
        </Stack>
      </Layout>
    </Layout>
  );
};

const styles = StyleSheet.create({
  gradientStyle: {
    padding: CatchTheme.spacing.mobile.paddingX * 3,
    width: 300,
    borderRadius: CatchTheme.corners.md,
  },
});

export default ReferralCode;
