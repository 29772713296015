import { formatPhone } from '@app/utils/format_old/misc';
import { onlyNums } from '@app/utils/format_old/strings';
import { baseEmail } from './primitives';

type AliasType = 'phone' | 'email';

/**
 * isAnyValidPhone
 * Checks a string to see if it matches ANY possible phone number
 * To see examples of which numbers are considered valid, visit https://regex101.com/r/OPmODy/1
 */
export const isAnyValidPhone = (str) => {
  return /^(\+)?1?-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/i.test(str);
};

/**
 * formatAnyValidPhone
 * For any phone numbers that pass isAnyValidPhone, we can convert them into dashed phone #s
 *
 * We strip down to digits only, take the last 10 characters (cutting out country code, essentially)
 * Then, reformat with the dashes so that we get XXX-XXX-XXXX
 */
export const formatAnyValidPhone = (str) => {
  const nums = onlyNums(str); // strip down to digits
  const last10 = nums.slice(nums.length - 10); // take last 10 characters
  return formatPhone(last10); // format with dashes
};

/**
 * Determines alias type
 *
 * - If the entry is entirely numbers or dashes, we assume that its a phone number
 * - As soon as there's a non-numerical char, its an email
 * - If we go beyond 10 digits, assume its an email as well
 */
export const checkAliasType = (str: string): AliasType | false => {
  // if plus sign, digit, parens, or dash and not 10 numbers yet
  if (/^[0-9\-+()]+$/i.test(str) && onlyNums(str).length <= 11) {
    return 'phone';
  }

  return 'email';
};

/**
 * Handles validation of email + phone together
 * - First, we check the alias type
 * - Then, we can use either a phone validator or email validator
 */
export const validateAlias = (alias: string): string | undefined => {
  if (!alias) return 'Required';

  const aliasType = checkAliasType(alias);

  if (aliasType === 'phone') {
    if (!isAnyValidPhone(alias)) {
      return 'Must be valid phone number';
    }
  } else {
    return baseEmail(alias);
  }
};

/**
 * Formats alias (either as phone or email)
 * Any valid phone numbers, we format as XXX-XXX-XXXX
 * Otherwise, return as is
 */
export const formatAlias = (value: string): string => {
  if (isAnyValidPhone(value)) {
    return formatAnyValidPhone(value);
  }

  return value;
};

/**
 * Formats alias (either as phone or email)
 * Any valid phone numbers are formatted as +1XXXXXXXXXX
 * Otherwise, return as is
 */
export const formatAliasForCognito = (alias: string): string => {
  const aliasType = checkAliasType(alias);

  // todo: update this so that it is idempotent with respect to phone numbers
  return aliasType === 'phone' ? `+1${onlyNums(alias)}` : alias;
};

export const formatUsernameForCognito = (alias: string): string => {
  const aliasType = checkAliasType(alias);

  if (aliasType === 'phone') {
    return onlyNums(alias);
  } else {
    return alias.toLowerCase().replace('@', '_');
  }
};
