import React from 'react';
import { StyleSheet } from 'react-native';

import { Copy, AssetProps, InteractionAccessoryType } from '@types';
import { Layout } from '@layouts';

import { ComplexRow } from './Row';
import { useResponsive } from '../hooks/useResponsive';
import { CatchTheme } from '../themes/catch';

interface BannerProps {
  asset?: AssetProps;
  title: Copy;
  subtitle?: Copy;
  accessory?: InteractionAccessoryType;
  onPress?: any;
  style?: any;
  bg?: string;
  testID: string;
  invert?: boolean;
  disabled?: boolean;
}

/**
 *
 * @deprecated
 * we need to combine <Banner/> and <PromptCard size="ALERT"/> as the main ComplexRow wrappers
 *
 * */
export const Banner = ({
  title,
  subtitle,
  bg = 'skeleton',
  accessory,
  onPress,
  style,
  asset,
  testID,
  invert,
  disabled,
}: BannerProps) => {
  const { res, isMobile } = useResponsive(styles);

  return (
    // @ts-ignore
    <Layout mobileSnap={isMobile} color={bg} style={[res.banner, style]}>
      <ComplexRow
        testID={testID}
        onPress={onPress}
        label={title}
        sublabel={subtitle}
        disabled={disabled}
        asset={asset ? { size: 'xs', ...asset } : undefined}
        interaction={accessory}
        labelColor={invert ? 'snow' : 'text'}
        sublabelColor={invert ? 'snow' : 'subtle'}
      />
    </Layout>
  );
};

const styles = {
  banner: StyleSheet.create({
    // eslint-disable-next-line react-native/no-unused-styles
    mobile: {
      width: 'auto',
      flex: 1,
      borderRadius: 0,
      paddingHorizontal: CatchTheme.spacing.mobile.margins,
    },
    // eslint-disable-next-line react-native/no-unused-styles
    default: {
      flex: 1,
      overflow: 'hidden',
      borderRadius: CatchTheme.corners.md,
      paddingHorizontal: CatchTheme.spacing.mobile.margins,
    },
  }),
};

export default Banner;
