import { OpenLinkOptions } from './types';
import { Segment } from '../analytics';
import { oauthStorage } from './oauthStorage';

export const openLink = ({
  token,
  receivedRedirectUri,
  next,
  onSuccess,
  onEvent,
  onExit,
}: OpenLinkOptions): void => {
  // @ts-ignore
  const handler = Plaid.create({
    token,
    receivedRedirectUri,
    onSuccess: async (publicToken, metadata) => {
      oauthStorage.reset();

      const account = {
        id: metadata?.account?.id,
        verificationStatus: metadata?.account?.verification_status,
      };

      const accounts = metadata.accounts.map((acc) => ({
        id: acc.id,
        mask: acc.mask,
      }));

      const institutionID = metadata.institution.institution_id;

      onSuccess(publicToken, { account, accounts, institutionID });
    },
    onEvent: Segment.plaidEvent,
    onExit: (error, metadata) => {
      onExit(error, metadata);
    },
  });

  // sets initial oauth storage
  if (!receivedRedirectUri) {
    oauthStorage.setToken(token);
    oauthStorage.setUri(window?.location?.pathname);
    if (next) oauthStorage.setNext(next);
  }

  handler.open();
};
