import gql from 'graphql-tag';

export const ELIGIBILITY_RESULTS = gql`
  query EligibilityResults($enrollId: ID!) {
    reference {
      health {
        openEnrollmentDates {
          windowShoppingOpenTime
          startDate
          endDate
          startTime
          endTime
        }
      }
    }
    viewer {
      id
      health {
        enrollment(id: $enrollId) {
          id
          ... on HealthInsuranceEnrollment {
            id
            application {
              id
              asyncStatus
              stateDeterminesChipEligibility
              stateDeterminesMedicaidEligibility
              aptcHouseholdAmount
              aptcHouseholdToApply
              medicaidEligibleMembers {
                id
              }
              chipEligibleMembers {
                id
              }
              sepEligibleMembers {
                id
              }
              exchangeEligibleMembers {
                id
              }
              iepEligibleMembers {
                id
              }
              coverageState
              coverageYearNumber
              isRequestingFinancialAssistance
              dmis {
                id
                dependentID
                status
                resolveBy
              }
              svis {
                id
                dependentIDs
                statusCode
                status
                resolveBy
              }
            }
            members {
              id
              givenName
              middleName
              familyName
              nameSuffix
              relation
              isRequestingCoverage
              isTaxDependent
              homeAddress {
                state
              }
              exchangeEligibility {
                startDate
                endDate
              }
              aptcEligibility {
                startDate
                endDate
                amount
              }
              csrEligibility {
                startDate
                endDate
                csrLevel
              }
              sepEligibility {
                startDate
                endDate
                sepReason
              }
              chipEligibility {
                startDate
                endDate
              }
              medicaidEligibility {
                startDate
                endDate
              }
              metalLevelOption {
                allowedMetalLevels
              }
              #Medicare eligibility
              initialEnrollmentEligibility {
                startDate
                endDate
              }
            }
            plans {
              total
            }
          }
        }
      }
    }
  }
`;

const formatter = (data) => {
  return {
    members: data?.viewer?.health?.enrollment?.members,
  };
};

export default { document: ELIGIBILITY_RESULTS, formatter };
