import React, { useCallback } from 'react';
import { GoalSlug, FilingStatus } from '@types';
import { useGoalSetup, useHealthLink } from '@hooks';
import {
  navigate,
  exit,
  routes,
  Stack,
  StackComponent,
  StackDefinition,
  stacks,
  useCurrentRoute,
} from '@navigate';

// goal setup
import { GoalIntroView } from './goalSetup/GoalIntroView';
import { GoalEstimatorView } from '../shared/EstimatorView';
import { GoalConfirmView } from '../shared/GoalConfirmView';
import { GoalEmploymentTypeView } from '../personalPayroll/EmploymentTypeView';
import { GoalTaxFilingStatusView } from '../personalPayroll/TaxFilingStatusView';
import { GoalDependentsView } from '../personalPayroll/DependentsView';
import { GoalEstimatedIncomeView } from '../personalPayroll/EstimatedIncomeView';
import { GoalWorkStateView } from '../personalPayroll/WorkStateView';
import { GoalSpouseIncomeView } from '../personalPayroll/SpouseIncomeView';

const config: StackDefinition = {
  stackName: stacks.GOAL_SETUP_STACK,
  options: {
    layout: 'page',
    navMode: 'flow',
    buttons: { help: true },
  },
  screens: [
    GoalIntroView,
    GoalEmploymentTypeView,
    GoalEstimatedIncomeView,
    GoalWorkStateView,
    GoalTaxFilingStatusView,
    GoalSpouseIncomeView,
    GoalDependentsView,
    GoalEstimatorView,
    GoalConfirmView,
  ],
};

interface HandleNextProps {
  slug?: GoalSlug;
  filingStatus?: FilingStatus;
  skip?: boolean;
}

const GoalSetupStack: StackComponent = () => {
  const { isPayrollSetup, isAccountSetup, isW2 } = useGoalSetup();

  const route = useCurrentRoute();
  const { lookup, searching } = useHealthLink();

  const handleNext = useCallback(
    (updates?: HandleNextProps) => {
      switch (route) {
        case routes.GOAL_INTRO:
          if (updates?.skip === true) {
            exit(routes.HOME);
          } else {
            if (!isPayrollSetup) {
              navigate(routes.GOAL_EMPLOYMENT_TYPE);
            } else {
              navigate(routes.GOAL_ESTIMATOR);
            }
          }
          break;
        case routes.GOAL_EMPLOYMENT_TYPE:
          navigate(routes.GOAL_ESTIMATED_INCOME);
          break;
        case routes.GOAL_ESTIMATED_INCOME:
          navigate(routes.GOAL_WORK_STATE);
          break;
        case routes.GOAL_WORK_STATE:
          navigate(isW2 ? routes.GOAL_ESTIMATOR : routes.GOAL_TAX_FILING_STATUS);
          break;
        case routes.GOAL_TAX_FILING_STATUS:
          const filingStatus = updates?.filingStatus || '';
          navigate(
            /MARRIED/.test(filingStatus) ? routes.GOAL_SPOUSE_INCOME : routes.GOAL_DEPENDENTS,
          );
          break;
        case routes.GOAL_SPOUSE_INCOME:
          navigate(routes.GOAL_DEPENDENTS);
          break;
        case routes.GOAL_DEPENDENTS:
          navigate(routes.GOAL_ESTIMATOR);
          break;
        case routes.GOAL_ESTIMATOR:
          if (!isAccountSetup) {
            navigate(routes.ACCOUNT_SETUP_INTRO, { onboarding: false });
          } else if (updates?.slug === 'retirement') {
            navigate(routes.RETIREMENT_CURRENT_SAVINGS);
          } else {
            navigate(routes.GOAL_CONFIRM, { slug: updates?.slug });
          }
          break;

        default:
          console.log({ route });
          break;
      }
    },
    [route, isAccountSetup, isPayrollSetup],
  );

  return (
    <Stack
      stackName={config.stackName}
      screens={config.screens}
      options={config.options}
      data={{ handleNext, lookup, searching, hasSSN: isAccountSetup }}
    />
  );
};

GoalSetupStack.config = config;
export default GoalSetupStack;
