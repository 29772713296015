import { getRouteFromURL } from './getRouteFromURL';
import { useLocationOverride } from './LocationOverride';
import { Route } from '../routes';

export const useCurrentRoute = (): Route => {
  const location = useLocationOverride();
  return getRouteFromURL(location);
};

export const useCurrentParams = (): any => {
  const location = useLocationOverride();
  return location?.state || {};
};
