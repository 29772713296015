import React from 'react';
import { Linking } from 'react-native';

import { Button, useResponsive } from '@uikit';
import { Toolbar, CalloutLayout } from '@layouts';
import { useCopy } from '@app/utils';

const PREFIX = 'catch.ede.CIC.govHandoff';

const CICGovHandoff = () => {
  const { c } = useCopy(PREFIX);
  const { isNative } = useResponsive();

  const url = 'https://www.healthcare.gov/';

  const handleHandoff = async () => {
    if (isNative) {
      Linking.openURL(url);
    }
  };

  return (
    <CalloutLayout
      render="coverage"
      title={c('title')}
      subtitles={[c('subtitle1'), c('subtitle2')]}
      toolbar={
        <Toolbar>
          <Button href={url} onPress={handleHandoff}>
            {c('button')}
          </Button>
        </Toolbar>
      }
    />
  );
};

export const CICGovHandoffView = {
  name: 'CIC_GOV_HANDOFF',
  component: CICGovHandoff,
  options: {},
};
