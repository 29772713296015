import React from 'react';
import { Copy } from '@types';
import { Stack } from '@layouts';
import Text from './Text';
import HelpText from './HelpText';
import TestID from './TestID';

export interface FormLabelProps {
  testID?: string;
  label?: Copy;
  sublabel?: Copy;
  help?: Copy;
  optional: boolean;
}

export const FormLabel: React.FC<FormLabelProps> = ({
  testID,
  label,
  sublabel,
  help,
  optional,
}) => {
  if (!label && !help) return null;

  return (
    <Stack horizontal flexItem={0}>
      <Stack>
        {!!optional && (
          <Text size="fp" color="subtle" gutter="bottom">
            Optional
          </Text>
        )}
        {!!label && (
          <Text size="p" weight="medium">
            {label}
            <TestID>{testID}</TestID>
          </Text>
        )}
        {!!sublabel && (
          <Text size="fp" color="subtle">
            {sublabel}
          </Text>
        )}
      </Stack>
      {!!help && <HelpText content={help} />}
    </Stack>
  );
};

export default FormLabel;
