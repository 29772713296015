import React, { useCallback, useMemo } from 'react';

import { sortGoals } from '@app/utils/format/goals';
import { date } from '@app/utils/format/date';
import { useGoals, useOnboarding, useTier } from '@hooks';
import { formatBenefit, goalTypeToSlug, useCopy } from '@app/utils';
import { Layout, RootLayout, Section, Stack } from '@layouts';
import { Asset, ComplexRow, Text } from '@uikit';
import { useResponsive } from '@app/_ui-kit/hooks/useResponsive';
import { exit, navigate, open, routes, ScreenDefinition, ScreenOptions } from '@navigate';
import { PromptCard } from '@app/_common';
import { Actions, Prompts } from '@app/config';
import { useQuery, /* PlanQueryData */ queries, BankLinksQueryData } from '@data';

import SavingsIcon from '@svg/piggybank.svg';
import RetirementIcon from '@svg/beach.svg';
import ContributionBar from '@app/charts/ContributionBar';
import { hasBankError } from '@app/utils/logic/promptLogic';

const options: ScreenOptions = {
  bg: 'retirementLight',
  title: 'Saved',
  buttons: {
    contextMenu: [Actions.EDIT_PLAN],
    account: true,
    activity: true,
    accentColor: 'retirement',
  },
};

/**
 * The "Saved" Page
 * @todo move to new plan query
 */
const SavedPageComponent = () => {
  const { c: c2, $, p } = useCopy('catch.plan.PlanCard');
  const { isMobile } = useResponsive();

  // useQuery<PlanQueryData>(queries.PLAN, {
  //   context: { failQuietly: true },
  // });

  const { data: bankData } = useQuery<BankLinksQueryData>(queries.BANK_LINKS);
  const bankError = hasBankError(bankData?.viewer?.bankLinks || []);

  const { state, goals, savingsGoals, investmentGoals } = useGoals();
  const { goalsToAdd, waiting, refetch } = useOnboarding();
  const { locked } = useTier();

  const showBanner = locked || bankError;

  const hasAnyCustomGoals = useMemo(
    () => goals?.filter((g) => g.type === 'CUSTOM_SAVINGS')?.length !== 0,
    [goals],
  );

  const goalSections = [
    {
      name: 'Investment',
      goals: investmentGoals,
      color: 'retirementLight',
      slug: 'retirement',
      icon: RetirementIcon,
      prompt: Prompts.RETIREMENT_SETUP,
    },
    {
      name: 'Savings',
      goals: savingsGoals,
      color: 'savedLight',
      slug: 'timeoff',
      icon: SavingsIcon,
      prompt: Prompts.SAVINGS_SETUP,
    },
  ];

  const clickOnGoal = useCallback(
    (goal) => {
      goal.status === 'DRAFT' || goal.status === 'NOT_STARTED'
        ? open(routes.GOAL_INTRO, { slug: goal.slug, onboarding: false })
        : navigate(routes.PLAN_DETAIL, { slug: goal.slug });
    },
    [goals, goalsToAdd],
  );

  const addCustomGoal = useCallback(() => {
    // If they already have a custom goal, head straight to the naming
    navigate(hasAnyCustomGoals ? routes.GOAL_CUSTOMIZE : routes.GOAL_INTRO, {
      slug: 'custom-goal',
      onboarding: false,
      creation: true,
    });
  }, [hasAnyCustomGoals]);

  return (
    <RootLayout
      title="Saved"
      loading={waiting}
      color={options.bg}
      onRefresh={refetch}
      fixAndroidMarginBehindNav
      menuItems={[{ ...Actions.EDIT_PLAN, onAction: () => open('PLAN_EDIT') }]}
      content={<ContributionBar />}
      extra={
        showBanner && (
          <Layout mobileSnap={isMobile}>
            <Stack spacing="0b">
              {!!locked && (
                <PromptCard
                  size="ALERT"
                  {...Prompts?.ACCOUNT_LOCKED}
                  overrideAction={() => exit()}
                />
              )}
              {/* {!!bankError && !locked && (
                <PromptCard
                  size="ALERT"
                  {...Prompts?.LINK_BANK}
                  gradient={null}
                  overrideAction={() => navigate(routes.LINKED_BANKS)}
                />
              )} */}
            </Stack>
          </Layout>
        )
      }
    >
      <Stack>
        {goalSections?.map(
          (section, idx) =>
            section?.goals?.length > 0 &&
            !waiting && (
              <Section key={section?.name} title={section?.name}>
                {sortGoals(section?.goals).map((item, i) => {
                  const subtitle = c2(`${item.type}.description`, {
                    ...item,
                    state: item.state || state,
                    end: item.endDate && date(item.endDate, 'SHORT'),
                    numDays: item.plannedTarget + item.unplannedTarget,
                    numWeeks: Math.round(item.numDays / 7),
                  });
                  return (
                    <ComplexRow
                      loading={waiting}
                      testID={item?.type}
                      key={item?.id}
                      interaction="navigation"
                      accessory={
                        <Text weight="regular" size="fp">
                          {p(item.paycheckPercentage)}
                        </Text>
                      }
                      onPress={() => clickOnGoal(item)}
                      label={<Text weight="semibold">{formatBenefit.name(item)}</Text>}
                      sublabel={
                        <Text>
                          <Text weight="medium">
                            {item.status === 'DRAFT' ? 'Setting up' : $(item.totalBalance)}
                          </Text>
                          <Text color="subtle" weight="light">
                            {'  '}
                            {!isMobile && subtitle?.replace('UNKNOWN_WEALTH_ACCOUNT_TYPE', '')}
                          </Text>
                        </Text>
                      }
                      asset={Asset.configureBenefit(item.goalType, item.icon)}
                    />
                  );
                })}
              </Section>
            ),
        )}
        <Stack>
          {false && goalsToAdd?.length > 0 && (
            <Section>
              {goalsToAdd?.map(({ type }) => {
                return (
                  <ComplexRow
                    noPadding
                    disabledAppearance
                    testID={type}
                    loading={waiting}
                    interaction="navigation"
                    key={type}
                    onPress={() => {
                      open(routes.GOAL_INTRO, { slug: goalTypeToSlug(type), onboarding: false });
                    }}
                    accessory={
                      <Text size="ffp" color="subtle">
                        Add
                      </Text>
                    }
                    asset={{
                      ...Asset.configureBenefit(type, null, 'xs'),
                      bg: 'bg',
                      borderColor: 'border',
                      style: {
                        borderStyle: 'dashed',
                        borderWidth: 0.5,
                      },
                    }}
                    label={formatBenefit.name({
                      type,
                    })}
                  />
                );
              })}

              {/* Always show a custom goal option */}
              <ComplexRow
                noPadding
                loading={waiting}
                key="CUSTOM_SAVINGS"
                testID="CUSTOM_SAVINGS"
                onPress={addCustomGoal}
                disabledAppearance
                interaction="navigation"
                accessory={
                  <Text size="ffp" color="subtle">
                    Add
                  </Text>
                }
                asset={{
                  ...Asset.configureBenefit('CUSTOM_SAVINGS', 'TARGET', 'xs'),

                  bg: 'bg',
                  borderColor: 'border',
                  style: {
                    borderStyle: 'dashed',
                    borderWidth: 1,
                  },
                }}
                label="Custom Goal"
              />
            </Section>
          )}
        </Stack>
      </Stack>
    </RootLayout>
  );
};

export const PlanView: ScreenDefinition = {
  name: 'PLAN',
  component: SavedPageComponent,
  options,
};

export default PlanView;
