import { routes } from '@navigate';
import { SEPIneligible } from '@common';

export const SEPOutDateView = {
  name: routes.EDE_SEP_OUT_OF_DATE,
  component: SEPIneligible,
  options: {
    title: 'Special Enrollment',
  },
};
