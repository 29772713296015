import React, { useEffect, useState } from 'react';
import shallow from 'zustand/shallow';

import { getRouteFromURL, navigate, routes, ScreenDefinition, start } from '@navigate';
import { useAuth, useAuthEvents } from '@client';
import { useBiometrics } from '@app/client/utils';
import { HealthPathway, OnboardingBenefit } from '@types';
import { getExitOptions } from './util/context';
import { StartOptions } from '@navigate/types';
import { getSignupContextOptions } from '@app/pages/auth/util/context';
import { useMutation } from '@apollo/client';
import { mutations, useDeprecatedMutation } from '@app/data';
import { AuthBlueprint } from '@app/blueprints';
import { Button, Link, Text } from '@uikit';
import { useCopy, Env } from '@app/utils';
import { Stack } from '@app/layouts';

interface LoginState {
  alias?: string;
  password?: string;
}

const LoginComponent: React.FC<{
  initialAlias: string;
  initialPassword: string;
  benefit?: OnboardingBenefit;
  vertical?: OnboardingBenefit;
  hid?: string;
  pathway?: HealthPathway;
  phone?: string;
  email?: string;
  next?: string;
  state?: string;
  income?: string;
  zip?: string;
}> = ({
  initialAlias,
  initialPassword,
  next,
  hid: healthID,
  email: initialEmail,
  phone: initialPhone,
  benefit,
  pathway,
  state,
  income,
  zip,
}) => {
  const { c } = useCopy('catch.module.login.SignInForm');

  const [initialValues, setInitialValues] = useState<LoginState>({
    alias: initialAlias || initialPhone || initialEmail,
    password: initialPassword,
  });

  const [updateUser] = useMutation(mutations.UPDATE_USER);
  const [_importHealth] = useDeprecatedMutation('importPublicExplorer', {});

  const { signIn, submitting, error } = useAuth(
    (state) => ({
      signIn: state.signIn,
      submitting: state.loading,
      error: state.error,
    }),
    shallow,
  );

  const {
    confirmedExpressBenefit,
    onboardingType,
    properBenefit,
    signupContext,
    usedPublicExplorer,
  } = getSignupContextOptions({
    benefit,
    healthID,
  });

  /**
   * continue onboarding/express path
   */
  const handleExit = () => {
    const defaultOptions: StartOptions = {
      open: getRouteFromURL(next?.pathname),
      data: next?.state,
    };

    const exitOptions = getExitOptions({
      confirmedExpressBenefit,
      onboardingType,
    });

    if (!!usedPublicExplorer) {
      _importHealth({
        variables: { id: healthID },
      });
    }

    if (signupContext) {
      updateUser({
        variables: {
          user: {
            signupContext,
          },
        },
      });
    }

    start('MAIN_TABS', exitOptions || defaultOptions);
  };

  const { enabled, prompt } = useBiometrics();

  useEffect(() => {
    async function biometricsCheck() {
      const credentials = await prompt();
      setInitialValues(credentials);
      signIn(credentials);
    }

    if (enabled) biometricsCheck();
  }, [enabled]);

  const handleSubmit = ({ alias, password }) => {
    signIn({ alias, password });
  };

  useAuthEvents({
    onSignedIn: handleExit,
  });

  return (
    <AuthBlueprint
      loading={false}
      submitting={submitting}
      error={error}
      title="Welcome back"
      subtitle="Sign in to Catch"
      actions={({ handleSubmit, submitting, disabled }) => (
        <Button
          testID="sign-in"
          haptics="LIGHT"
          disabled={disabled}
          onPress={handleSubmit}
          loading={submitting}
          full
        >
          {c('submitButton')}
        </Button>
      )}
      formConfig={{
        initialValues,
        fields: [
          { type: 'alias', name: 'alias', required: true },
          {
            type: 'password',
            name: 'password',
            label: 'Password',
            passwordType: 'existing',
            requirements: false,
            required: true,
          },
        ],
        onSubmit: handleSubmit,
      }}
      postContent={
        <Stack spacing="1">
          <Link
            color="text"
            weight="regular"
            onPress={() => navigate(routes.FORGOT_PASSWORD)}
            size="fp"
          >
            {c('passwordLink')}
          </Link>
          <Text color="subtle" size="fp">
            {c('signupLabel')}{' '}
            <Link
              onPress={() =>
                navigate(routes.REGISTER, {
                  hid: healthID,
                  benefit,
                  zip,
                  income,
                  state,
                  pathway,
                  email: initialEmail,
                })
              }
              size="fp"
              color="text"
              weight="regular"
            >
              {c('signupLink')}
            </Link>
          </Text>
        </Stack>
      }
    />
  );
};

export const LoginView: ScreenDefinition = {
  name: 'LOGIN',
  component: LoginComponent,
  guest: true,
  options: {
    title: 'Sign In',
    largeTitle: true,
    drawBehindNav: true,
  },
};
