import React, { useRef, useMemo, useEffect } from 'react';
import { Animated, PanResponder } from 'react-native';

import { Text } from '../Text';
import { Asset } from '../Asset';
import { useTheme } from '@uikit/hooks/useTheme';
import { useHover } from '@uikit/hooks/useHover';
import { useResponsive } from '@uikit/hooks/useResponsive';
import { Platform } from '@layouts';
import Close from '@svg/close.svg';

const TOAST_WIDTH = 424;
const SWIPE_THRESHOLD = 48;
function clamp(value, min, max) {
  return min < max
    ? value < min
      ? min
      : value > max
      ? max
      : value
    : value < max
    ? max
    : value > min
    ? min
    : value;
}

/**
 * @param {String} type success|neutral|error|important
 * @param {String} id automatically generated by the store
 * @param {String} title bold main text
 * @param {String} msg secondary text
 * @param {Function} onDismiss remove the toast from the store
 * @param {Boolean} isLast if false, display a gutter
 * @param {Number} autoCloseIn override the default delay
 */
export const Toast = ({ type, id, title, msg, onDismiss, isLast, autoCloseIn }) => {
  const { theme, bp } = useTheme();
  const { width } = useResponsive();
  const toastWidth = bp < 2 ? width - (bp < 1 ? 32 : 48) : 375;
  const toastHeight = -80;
  const tlValue = Platform.select({ web: toastWidth, native: toastHeight });
  const { current: pan } = useRef(new Animated.Value(tlValue));
  function animateEntrance() {
    Animated.spring(pan, {
      toValue: 0,
      friction: 8,
      useNativeDriver: true,
    }).start();
  }
  function animateExit() {
    Animated.spring(pan, {
      toValue: tlValue,
      friction: 8,
      useNativeDriver: true,
    }).start(onDismiss);
  }
  function resetState() {
    pan.setValue(TOAST_WIDTH);
    onDismiss();
  }
  const panResponder = useMemo(
    () =>
      PanResponder.create({
        onStartShouldSetPanResponder: () => true,
        onPanResponderGrant: (e, gestureState) => {
          // pan.setOffset(pan._value);
          // pan.setValue(0);
        },
        onPanResponderMove: Animated.event(
          [
            null,
            Platform.select({
              web: {
                dx: pan,
              },
              native: {
                dy: pan,
              },
            }),
          ],
          // Can't use the native driver with event
          { useNativeDriver: false },
        ),
        onPanResponderRelease: (e, { vx, vy }) => {
          pan.flattenOffset();
          let velocity;
          if (Platform.OS === 'web') {
            if (vx >= 0) {
              velocity = clamp(vx, 3, 5);
            } else if (vx < 0) {
              velocity = clamp(vx * -1, 3, 5) * -1;
            }
            // pulling in the wrong direction
            if (pan._value < 0) {
              return;
            }
          } else {
            if (vy >= 0) {
              velocity = clamp(vy, 3, 5);
            } else if (vy < 0) {
              velocity = clamp(vy * -1, 3, 5) * -1;
            }
            // pulling in the wrong direction
            if (pan._value > 0) {
              return;
            }
          }
          if (Math.abs(pan._value) > SWIPE_THRESHOLD) {
            Animated.decay(pan, {
              useNativeDriver: true,
              velocity: velocity,
              deceleration: 0.98,
            }).start(resetState);
          } else {
            Animated.spring(pan, {
              useNativeDriver: true,
              toValue: 0,
              friction: 8,
            }).start();
          }
        },
      }),
    [],
  );
  const [hovered, handlers] = useHover();
  const timer = useRef(
    ((cb, delay) => {
      let timer,
        start,
        remaining = delay;
      return {
        resume: () => {
          start = Date.now();
          clearTimeout(timer);
          timer = setTimeout(cb, remaining);
        },
        pause: () => {
          clearTimeout(timer);
          remaining -= Date.now() - start;
        },
        cleanup: () => clearTimeout(timer),
      };
    })(animateExit, autoCloseIn || 4500),
  );
  useEffect(() => {
    animateEntrance();
    return timer.current.cleanup;
  }, []);
  useEffect(() => {
    if (hovered) {
      timer.current.pause();
    } else {
      timer.current.resume();
    }
  }, [hovered]);
  return (
    <Animated.View
      style={[
        theme.toastBase,
        theme[`${type}Toast`],
        theme.majorShadow,
        !isLast && theme.bottomGutter1b,
        { width: toastWidth },
        {
          transform: [
            Platform.select({
              web: {
                translateX: pan.interpolate({
                  inputRange: [0, TOAST_WIDTH],
                  outputRange: [0, TOAST_WIDTH],
                  extrapolate: 'clamp',
                }),
              },
              native: {
                translateY: pan.interpolate({
                  inputRange: [-80, 0],
                  outputRange: [-80, 0],
                  extrapolate: 'clamp',
                }),
              },
            }),
          ],
        },
        {
          opacity: pan.interpolate({
            inputRange: Platform.select({
              native: [-80, -40, 0],
              web: [0, 200, TOAST_WIDTH],
            }),
            outputRange: Platform.select({ native: [0, 1, 1], web: [1, 1, 0] }),
            extrapolate: 'clamp',
          }),
        },
      ]}
      {...handlers}
      {...panResponder.panHandlers}
    >
      <Text size="fp" weight="bold" selectable={false} key="label-text" group>
        {title}
      </Text>
      {!!msg && (
        <Text size="ffp" selectable={false} key="message-text" style={{ opacity: 0.5 }}>
          {msg}
        </Text>
      )}
      <Platform web>
        <Asset
          onPress={onDismiss}
          svg={Close}
          size="glyph"
          containerSize="sm"
          shape="circle"
          bg="skeleton"
          absolute
          containerStyle={{ top: 12, right: 12 }}
        />
      </Platform>
    </Animated.View>
  );
};

export default Toast;
