import React, { useState } from 'react';
import { View } from 'react-native';
import { useSpring, animated } from 'react-spring/native';
import { FormLabel, Text, Link, useTheme, Asset } from '@uikit';
import { TextField } from '@app/forms_old/inputs';
import Checkmark from '@svg/checkmark.svg';
import { InputProps } from '../types';
import * as pwValidation from '../utils/validation/password';

const AView = animated(View);

interface PasswordInputProps<T> extends InputProps<T> {
  passwordType: 'existing' | 'new';
  requirements?: boolean;
}

const Requirement = ({ description, isValid }) => {
  const { theme } = useTheme();

  return (
    <View style={[theme.row, theme.bottomGutter1]}>
      <Asset svg={Checkmark} size="xs" color={isValid ? 'positive' : 'subtle'} />
      <Text
        gutter="left"
        size="fp"
        color={isValid ? 'positive' : 'subtle'}
        weight={isValid ? 'medium' : 'regular'}
      >
        {description}
      </Text>
    </View>
  );
};

const PasswordInput = React.forwardRef(
  <T extends any>(
    {
      testID,
      autoFocus,
      passwordType,
      name,
      value,
      label,
      sublabel,
      help,
      optional,
      disabled,
      placeholder,
      onChange,
      onBlur,
      onKeyPress,
      error,
      requirements,
    }: PasswordInputProps<T>,
    ref,
  ) => {
    const [visible, setVisible] = useState<boolean>(false);
    const [focused, setFocused] = useState<boolean>(false);

    const { height } = useSpring({ height: focused ? 164 : 0, from: { height: 0 } });
    const { opacity } = useSpring({ opacity: focused ? 1 : 0 });

    return (
      <>
        <FormLabel
          testID={testID}
          label={label}
          sublabel={sublabel}
          help={help}
          optional={optional}
        />
        <TextField
          ref={ref}
          keyboard={passwordType === 'existing' ? 'existingPassword' : 'newPassword'}
          testID={testID}
          disabled={disabled}
          autoFocus={autoFocus}
          name={name}
          value={value}
          onFocus={() => setFocused(true)}
          onChangeText={(val) => onChange(val)}
          onBlur={() => {
            onBlur();
            setFocused(false);
          }}
          onKeyPress={onKeyPress}
          placeholder={placeholder}
          error={error}
          hideError={focused}
          fullWidth
          secureTextEntry={!visible}
          siblings={
            <Link size="fp" color="subtle" onPress={() => setVisible(!visible)}>
              {visible ? 'Hide' : 'Show'}
            </Link>
          }
        />
        {requirements !== false && (
          <AView style={[{ overflow: 'hidden', height }]}>
            <AView style={{ opacity }}>
              <Requirement
                description="8 or more characters"
                isValid={pwValidation.testLength(value)}
              />
              <Requirement
                description="Capital letter"
                isValid={pwValidation.testUpperCase(value)}
              />
              <Requirement
                description="Lowercase letter"
                isValid={pwValidation.testLowerCase(value)}
              />
              <Requirement description="Number" isValid={pwValidation.testNumber(value)} />
              <Requirement
                description="Symbol/special character"
                isValid={pwValidation.testSymbol(value)}
              />
            </AView>
          </AView>
        )}
      </>
    );
  },
);

PasswordInput.displayName = 'PasswordInput';
export default PasswordInput;
