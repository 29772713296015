import { IncomeSkippedQuery } from './queries/IncomeSkippedQuery';
import { OpenEnrollmentQuery } from './queries/OpenEnrollmentQuery';
import { DocumentNode } from 'graphql';

// keep these in alphabetical order
import { AccountSetupQuery } from './queries/AccountSetupQuery';
import { BankLinksQuery } from './queries/BankLinksQuery';
import { CountiesQuery } from './queries/CountiesQuery';
import { CoverageQuery } from './queries/CoverageQuery';
import { CoverageDetailQuery } from './queries/CoverageDetailQuery';
import { DocumentsQuery, DocumentQuery } from './queries/DocumentsQuery';
import { DocumentUploadsQuery } from './queries/DocumentUploadsQuery';
import { EnrollmentGroupsQuery } from './queries/EnrollmentGroupsQuery';
import { EligibilityResultsQuery } from './queries/EligibilityResultsQuery';
import { ExplorerPlansQuery } from './queries/ExplorerPlansQuery';
import { GoalSetupQuery } from './queries/GoalSetupQuery';
import { HealthApplicationQuery } from './queries/HealthApplicationQuery';
import { HealthBenefitsQuery } from './queries/HealthBenefitsQuery';
import { HealthExplorerQuery } from './queries/HealthExplorerQuery';
import { HealthLinkQuery } from './queries/HealthLinkQuery';
import { HealthProgressLookupQuery } from './queries/HealthProgressLookupQuery';
import { HealthRenewQuery } from './queries/HealthRenewQuery';
import { HealthSlasherQuery } from './queries/HealthSlasherQuery';
import { HomeQuery } from './queries/HomeQuery';
import { IncomeQuery } from './queries/IncomeQuery';
import { IncomeAutomationRuleQuery } from './queries//IncomeAutomationRuleQuery';
import { IncomeSourcesQuery } from './queries/IncomeSourcesQuery';
import { IncomeTransactionQuery } from './queries/IncomeTransactionQuery';
import { OnboardingQuery } from './queries/OnboardingQuery';
import { PaychecksQuery } from './queries/PaychecksQuery';
import { PayrollQuery } from './queries/PayrollQuery';
import { PlanQuery } from './queries/PlanQuery';
import { PlanDetailQuery } from './queries/PlanDetailQuery';
import { PrivacyAgreementsQuery } from './queries/PrivacyAgreementsQuery';
import { RetirementQuery } from './queries/RetirementQuery';
import { TaxesQuery } from './queries/TaxesQuery';
import { UserDetailsQuery } from './queries/UserDetailsQuery';
import { WalletQuery } from './queries/WalletQuery';
import { TransferQuery } from './queries/TransferQuery';

type QueryName =
  | 'ACCOUNT_SETUP'
  | 'BANK_LINKS'
  | 'COUNTIES'
  | 'COVERAGE'
  | 'COVERAGE_DETAIL'
  | 'DOCUMENTS'
  | 'DOCUMENT'
  | 'DOCUMENT_UPLOADS'
  | 'ENROLLMENT_GROUPS'
  | 'ELIGIBILITY_RESULTS'
  | 'EXPLORER_PLANS'
  | 'GOAL_SETUP'
  | 'HEALTH_APPLICATION'
  | 'HEALTH_BENEFITS'
  | 'HEALTH_EXPLORER'
  | 'HEALTH_LINK'
  | 'HEALTH_RENEW'
  | 'HEALTH_SLASHER'
  | 'HEALTH_PROGRESS_LOOKUP'
  | 'HOME'
  | 'INCOME'
  | 'INCOME_AUTOMATION_RULE'
  | 'INCOME_SKIPPED'
  | 'INCOME_SOURCES'
  | 'INCOME_TRANSACTION'
  | 'ONBOARDING'
  | 'OPEN_ENROLLMENT'
  | 'PAYCHECKS'
  | 'PAYROLL'
  | 'PLAN'
  | 'PLAN_DETAIL'
  | 'PRIVACY_AGREEMENTS'
  | 'RETIREMENT'
  | 'TAXES'
  | 'TRANSFER'
  | 'USER_DETAILS'
  | 'WALLET';

export const queries: Record<QueryName, DocumentNode> = {
  ACCOUNT_SETUP: AccountSetupQuery,
  BANK_LINKS: BankLinksQuery,
  COUNTIES: CountiesQuery,
  COVERAGE: CoverageQuery,
  COVERAGE_DETAIL: CoverageDetailQuery,
  DOCUMENTS: DocumentsQuery,
  DOCUMENT: DocumentQuery,
  DOCUMENT_UPLOADS: DocumentUploadsQuery,
  ENROLLMENT_GROUPS: EnrollmentGroupsQuery,
  ELIGIBILITY_RESULTS: EligibilityResultsQuery,
  EXPLORER_PLANS: ExplorerPlansQuery,
  GOAL_SETUP: GoalSetupQuery,
  HEALTH_APPLICATION: HealthApplicationQuery,
  HEALTH_BENEFITS: HealthBenefitsQuery,
  HEALTH_EXPLORER: HealthExplorerQuery,
  HEALTH_LINK: HealthLinkQuery,
  HEALTH_RENEW: HealthRenewQuery,
  HEALTH_SLASHER: HealthSlasherQuery,
  HEALTH_PROGRESS_LOOKUP: HealthProgressLookupQuery,
  HOME: HomeQuery,
  INCOME: IncomeQuery,
  INCOME_AUTOMATION_RULE: IncomeAutomationRuleQuery,
  INCOME_SKIPPED: IncomeSkippedQuery,
  INCOME_SOURCES: IncomeSourcesQuery,
  INCOME_TRANSACTION: IncomeTransactionQuery,
  ONBOARDING: OnboardingQuery,
  OPEN_ENROLLMENT: OpenEnrollmentQuery,
  PAYCHECKS: PaychecksQuery,
  PAYROLL: PayrollQuery,
  PLAN: PlanQuery,
  PLAN_DETAIL: PlanDetailQuery,
  PRIVACY_AGREEMENTS: PrivacyAgreementsQuery,
  RETIREMENT: RetirementQuery,
  TAXES: TaxesQuery,
  TRANSFER: TransferQuery,
  USER_DETAILS: UserDetailsQuery,
  WALLET: WalletQuery,
};
