import React from 'react';
import { Platform } from 'react-native';
import shallow from 'zustand/shallow';
import { PresentationMode } from '@types';
import { SheetName, ContentComponentProps, useSheet } from '@navigate';
import { ActionSheet, ModalNav, UIAccentColor } from '@uikit';

// sheet imports
import TransferOptionsSheet from './TransferOptionsSheet';
import PlanDetailMenuSheet from './PlanDetailMenuSheet';
import TaxesMenuSheet from './TaxesMenuSheet';
import ManageBankAccountSheet from './ManageBankAccountSheet';
import DeleteBankAccountSheet from './DeleteBankAccountSheet';
import PauseGoalSheet from './PauseGoalSheet';
import TaxBreakdownGuideSheet from './TaxBreakdownGuideSheet';
import EventReceiptSheet from './EventReceiptSheet';
import HelpTextSheet from './HelpTextSheet';
import TaxPaymentGuideSheet from './TaxPaymentGuideSheet';
import RetirementProjectionGuideSheet from './RetirementProjectionGuideSheet';
import LoaderSheet from './LoaderSheet';
import PlanSelectedSheet from './PlanSelectedSheet';
import CoverageDetailMenuSheet from './CoverageDetailMenuSheet';
import CoverageManagedOutsideSheet from './CoverageManagedOutsideSheet';
import HealthScreenedOutSheet from './HealthScreenedOutSheet';
import RetirementAccountTypeSheet from './RetirementAccountTypeSheet';
import RetirementPortfolio from './RetirementPortfolioSheet';
import RetirementWithdrawalSheet from './RetirementWithdrawalSheet';
import GoalEstimatorSheet from './GoalEstimatorSheet';
import PostPlaidSheet from './PostPlaidSheet';
import QRCodeSheet from './QRCodeSheet';
import RefetchEligibilitySheet from './RefetchEligibilitySheet';
import CoverageMenuSheet from './CoverageMenuSheet';
import PaymentRedirectSheet from './PaymentRedirectSheet';
import UploadAvatar from './AvatarSheet';
import CancelPolicySheet from './CancelPolicySheet';
import AddToWalletSheet from './AddToWalletSheet';

interface SheetConfig {
  Component: React.FC<ContentComponentProps>;
  presentation?: PresentationMode; // size of sheet for web, otherwise defaults to actionSheet
  preventClose?: boolean;
  accentColor?: UIAccentColor;
}

const content: Record<SheetName, SheetConfig> = {
  TRANSFER_OPTIONS: {
    Component: TransferOptionsSheet,
  },
  PLAN_DETAIL_MENU: {
    Component: PlanDetailMenuSheet,
  },
  COVERAGE_MENU: {
    Component: CoverageMenuSheet,
  },
  COVERAGE_DETAIL_MENU: {
    Component: CoverageDetailMenuSheet,
  },
  COVERAGE_MANAGED_OUTSIDE: {
    Component: CoverageManagedOutsideSheet,
  },
  TAXES_MENU: {
    Component: TaxesMenuSheet,
  },
  MANAGE_BANK_ACCOUNT: {
    Component: ManageBankAccountSheet,
  },
  DELETE_BANK_ACCOUNT: {
    Component: DeleteBankAccountSheet,
  },
  PAUSE_GOAL: {
    Component: PauseGoalSheet,
  },
  TAX_BREAKDOWN_GUIDE: {
    Component: TaxBreakdownGuideSheet,
    presentation: 'formSheet',
  },
  TAX_PAYMENT_GUIDE: {
    Component: TaxPaymentGuideSheet,
    presentation: 'formSheet',
  },
  RETIREMENT_PROJECTION_GUIDE: {
    Component: RetirementProjectionGuideSheet,
    presentation: 'formSheet',
  },
  EVENT_RECEIPT: {
    Component: EventReceiptSheet,
  },
  HELP_TEXT: {
    Component: HelpTextSheet,
  },
  LOADER: {
    Component: LoaderSheet,
    preventClose: true,
  },
  NEXT_PLAN_SELECTION: {
    Component: PlanSelectedSheet,
    accentColor: 'coverage',
  },
  HEALTH_SCREENED_OUT: {
    Component: HealthScreenedOutSheet,
  },
  GOAL_ESTIMATOR: {
    Component: GoalEstimatorSheet,
  },
  RETIREMENT_ACCOUNT_TYPE: {
    Component: RetirementAccountTypeSheet,
    presentation: 'pageSheet',
    accentColor: 'retirement',
  },
  RETIREMENT_PORTFOLIO: {
    Component: RetirementPortfolio,
    presentation: 'pageSheet',
    accentColor: 'retirement',
  },
  POST_PLAID: {
    Component: PostPlaidSheet,
  },
  QRCODE: {
    Component: QRCodeSheet,
  },
  REFETCH_ELIGIBILITY: {
    Component: RefetchEligibilitySheet,
  },
  PAYMENT_REDIRECT: {
    Component: PaymentRedirectSheet,
    accentColor: 'coverage',
  },
  UPLOAD_AVATAR: {
    Component: UploadAvatar,
  },
  CANCEL_POLICY: {
    Component: CancelPolicySheet,
    accentColor: 'coverage',
  },
  RETIREMENT_WITHDRAWAL: {
    Component: RetirementWithdrawalSheet,
    accentColor: 'retirement',
  },
  ADD_TO_WALLET: {
    Component: AddToWalletSheet,
    accentColor: 'coverage',
  },
};

const showNav = ({ presentation }) => {
  if (Platform.OS === 'web') {
    if (presentation && presentation !== 'actionSheet') {
      return true;
    }
  }
  return false;
};

/**
 * Renders the current sheets
 */
const SheetsProvider: React.FC = () => {
  const { sheets, closeById } = useSheet(
    (state) => ({ sheets: state.active, closeById: state.closeById }),
    shallow,
  );

  if (sheets.length === 0) {
    return null;
  }
  return (
    <>
      {sheets.map((sheet, index) => {
        const { Component, presentation, accentColor, preventClose } = content[sheet.name];

        if (!Component) {
          return null;
        }

        const handleClose = () => closeById(sheet.id);

        return (
          <ActionSheet
            key={index}
            isOpen={true}
            onRequestClose={handleClose}
            preventClose={preventClose}
            navBg={Component?.navBg}
            accentColor={accentColor}
            presentation={presentation || 'actionSheet'}
          >
            {showNav({ presentation }) && (
              <ModalNav left={{ icon: 'close' }} onLeft={handleClose} />
            )}
            <Component {...sheet.data} closeSheet={handleClose} />
          </ActionSheet>
        );
      })}
    </>
  );
};

export default SheetsProvider;
