import gql from 'graphql-tag';

const TRANSFER_EVENT = gql`
  query TransferEvent {
    transferEvents @client {
      id
    }
  }
`;

const formatter = (data, options) => {
  return data;
};

export default {
  document: TRANSFER_EVENT,
  formatter,
};
