import React from 'react';
import { stacks, Stack, StackComponent, StackDefinition } from '@navigate';

import { TaxPaymentHistoryView } from './taxes/TaxPaymentHistoryView';
import { TaxPaymentView } from './taxes/TaxPaymentView';

const config: StackDefinition = {
  stackName: stacks.TAX_HISTORY_STACK,
  options: {
    layout: 'sheet',
    accentColor: 'taxes',
    bg: 'sheet',
  },
  screens: [TaxPaymentHistoryView, TaxPaymentView], // @todo E-3445 typescript
};

const TaxHistoryStack: StackComponent = () => {
  return <Stack stackName={config.stackName} screens={config.screens} options={config.options} />;
};

TaxHistoryStack.config = config;
export default TaxHistoryStack;
