import React from 'react';

import { Button, ComplexRow, Link } from '@uikit';
import { BasicLayout, Toolbar } from '@layouts';
import { useCopy, isWithinLast60Days, isWithinNext60Days } from '@app/utils';
import { navigate, routes } from '@navigate';

const PREFIX = 'catch.ede.CIC.sepOutdated';

const CICSEPMixed = ({ members, change }) => {
  /**@todo check members and change forwarded */
  const { c } = useCopy(PREFIX);
  const isFuture = /FUTURE/.test(change);
  const isValid = isFuture ? isWithinNext60Days : isWithinLast60Days;

  const handleSubmit = () => {
    navigate(routes.CIC_APP_REVIEW_INTRO);
  };

  return (
    <BasicLayout
      title={c('mixedTitle')}
      subtitles={[
        c(`subtitle`, {
          link: (
            <Link key="sepLink" inline>
              {c('sepLink')}
            </Link>
          ),
        }),
      ]}
      toolbar={
        <Toolbar>
          <Button onPress={handleSubmit}>{c('done')}</Button>
        </Toolbar>
      }
    >
      {members.map((m, i) => (
        <ComplexRow
          key={m?.name}
          label={m?.name}
          accessory={isValid(m.changeDate) ? 'check' : 'x'}
        />
      ))}
    </BasicLayout>
  );
};

export const CICSEPMixedView = {
  name: routes.CIC_SEP_MIXED,
  component: CICSEPMixed,
  options: {
    bg: 'page',
  },
};
