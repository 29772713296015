import { useQuery, queries, useData, HealthApplicationQueryData } from '@data';
import { routes, navigate } from '@navigate';

export const useExistingApps = (applicationID) => {
  const { data } = useQuery<HealthApplicationQueryData>(queries.HEALTH_APPLICATION, {
    variables: { applicationID },
    skip: !applicationID,
    fetchPolicy: 'cache-first',
  });

  // On good responses, we want to check previous applications before routing to next
  const { fetch, loading } = useData('previousApplications', {
    variables: {
      input: {
        coverageYear: data?.viewerTwo?.health?.application?.coverageYearNumber,
        state: data?.viewerTwo?.health?.application?.coverageState,
      },
    },
    lazy: true,
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      navigate(
        res?.applications?.length > 0
          ? routes.EDE_APPLICATION_IMPORT
          : routes.EDE_CONFIRM_APPLICANTS,
      );
    },
  });

  const checkForExistingApplications = () => {
    // check if this application is already identified with CMS
    if (!!data?.viewerTwo?.health?.application?.insuranceApplicationIdentifier) {
      navigate(routes.EDE_CONFIRM_APPLICANTS);
    } else {
      fetch();
    }
  };

  return {
    checking: loading,
    checkForExistingApplications,
  };
};
