import gql from 'graphql-tag';
import {
  GoalStatus,
  GoalSlug,
  GoalType,
  RiskLevel,
  RiskComfort,
  RetirementAccountTypes,
} from '@types';

export interface RetirementQueryData {
  viewer: {
    id: string;
    user: {
      id: string;
      dob: string;
      age: number;
      isControlPerson: boolean;
      isFirmAffiliated: boolean;
      isPoliticallyExposed: boolean;
    };
    goal: {
      id: string;
      slug: GoalSlug;
      status: GoalStatus;
      goalType: GoalType;
      name: string;
      riskLevel: RiskLevel;
      riskComfort: RiskComfort;
      accountType: RetirementAccountTypes;
      externalSavings: number;
      portfolio: {
        id: string;
        name: string;
      };
    };
  };
  reference: {
    wealth: {
      portfolios: {
        id: string;
        name: string;
        contents: {
          name: string;
          ticker: string;
          weight: number;
        };
      };
    };
  };
}

export const RetirementQuery = gql`
  query RetirementQuery {
    viewer {
      id
      user {
        id
        dob
        age @client
        isControlPerson
        isFirmAffiliated
        isPoliticallyExposed
      }
      goal(slug: "retirement") {
        id
        slug
        status
        goalType
        name
        ... on RetirementGoal {
          riskLevel
          riskComfort
          externalSavings
          accountType
          portfolio {
            id
            ... on Portfolio {
              id
              name
            }
          }
        }
      }
    }
    reference {
      wealth {
        portfolios {
          id
          name
          contents {
            name
            ticker
            weight
          }
        }
      }
    }
  }
`;
