import React, { useMemo } from 'react';
import { SectionList, View } from 'react-native';

import {
  boolToTri,
  capitalizeAll,
  lastFour,
  safeFormatDate,
  useCopy,
  formatList,
  capitalize,
} from '@app/utils';
import {
  useQuery,
  queries,
  HealthApplicationQueryData,
  getMembers,
  useMutation,
  mutations,
} from '@data';
import { navigate, pop, Route, routes } from '@navigate';
import { Button, ComplexRow, Divider, Link } from '@uikit';
import { SectionTitle, SplitLayout, Toolbar } from '@layouts';

interface ReviewField {
  display?: boolean;
  title: string;
  value: any;
  path: Route;
}

interface Section {
  key: string;
  title: string;
  data: Array<ReviewField>;
  display?: boolean;
}

const formatName = (m) => {
  const middle = m?.middleName ? `${m.middleName} ` : '';
  const suffix = m?.nameSuffix || m?.suffix ? ` ${m?.nameSuffix || m?.suffix}` : '';
  return `${m?.givenName || m?.firstName} ${middle}${m?.familyName || m?.lastName}${suffix}`;
};

const formatBool = (val) => {
  return capitalize(boolToTri(val));
};

const formatPhone = (phone) => {
  // check if the phone is stored formatted or not
  return phone?.includes('-')
    ? phone
    : `${phone?.substring(0, 3)}-${phone?.substring(3, 6)}-${phone?.substring(6, 10)}`;
};

const formatAddress = (address) => {
  const street2 = address?.street2 ? `${address?.street2}, ` : '';
  return `${address?.street1}, ${street2}${address?.city}, ${address?.state}`;
};

const formatSSN = (ssn) => {
  return ssn ? `••• - •• - ${lastFour(ssn)}` : 'None';
};

const formatPeople = (members) => {
  return members?.map((m) => `${formatName(m)}\n`);
};

const formatDate = (date) => {
  return safeFormatDate(date, 'MMM d, yyyy');
};

const formatSEP = (member) => {
  let sep = '';

  if (member?.isLostCoverageLast60Days) {
    sep += `Lost coverage ${formatDate(member?.coverageLostDate)}\n`;
  }
  if (member?.isWillLoseCoverageNext60Days) {
    sep += `Will lose coverage ${formatDate(member?.coverageLostDateFuture)}\n`;
  }
  if (member?.isMarriedLast60Days) {
    sep += `Married on ${formatDate(member?.whenMarried)}\n`;
  }
  if (member?.isReleasedFromIncarceration) {
    sep += `Released from incarceration on ${formatDate(member?.whenReleasedFromIncarceration)}\n`;
  }
  if (member?.isMoved) {
    sep += `Moved on ${formatDate(member?.whenRelocated)}\n`;
  }
  if (member?.isGainDependent) {
    sep += `Adopted or placed in foster care on ${formatDate(member?.whenBecomeADependent)}\n`;
  }
  if (member?.isGainedLawfulPresence) {
    sep += `Gained lawful presence ${formatDate(member?.whenLawfulPresence)}\n`;
  }

  return sep || 'None';
};

const ReviewApplication = ({ applicationID }) => {
  const { c, $ } = useCopy('catch.ede');

  const { loading, data } = useQuery<HealthApplicationQueryData>(queries.HEALTH_APPLICATION, {
    variables: { applicationID },
    skip: !applicationID,
  });

  const [upsert, { loading: upserting }] = useMutation(mutations.UPSERT_HEALTH_APPLICATION);

  const sections: Array<Section> = useMemo(() => {
    const app = data?.viewerTwo?.health?.application;
    const applicant = app?.applicant;
    const allMembers = getMembers(data);

    const household: Section = {
      key: 'household',
      title: 'Household',
      data: [
        { title: 'Coverage state', value: app?.coverageState, path: routes.EDE_COVERAGE_INFO },
        {
          title: 'Coverage year',
          value: app?.coverageYearNumber,
          path: routes.EDE_COVERAGE_INFO,
        },
        {
          title: 'Members',
          value: formatPeople(allMembers),
          path: routes.EDE_CONFIRM_APPLICANTS,
        },
        {
          title: 'Marital status',
          value: capitalizeAll(app?.maritalStatus),
          path: routes.EDE_HOUSEHOLD,
        },
        {
          title: 'Num tax dependents',
          value: app?.numTaxDependents,
          path: routes.EDE_HOUSEHOLD,
          display: app?.numTaxDependents > 0,
        },
      ],
    };

    const assistors = {
      key: 'assistors',
      title: 'Assistance',
      data: app?.applicationAssistors?.map((assistor) => ({
        title: formatName(assistor),
        value: c(`enums.${assistor?.applicationAssistorType?.replace('ASSISTOR_TYPE_', '')}`),
        path: routes.EDE_PRO_ASSISTANCE,
      })),
      display: app?.applicationAssistors?.length > 0,
    };

    const communications: Section = {
      key: 'comms',
      title: 'Communications',
      data: [
        {
          title: 'Written Language',
          value: capitalizeAll(applicant?.writtenLanguageType),
          path: routes.EDE_ID_INFO,
        },
        {
          title: 'Spoken Language',
          value: capitalizeAll(applicant?.spokenLanguageType),
          path: routes.EDE_ID_INFO,
        },
        {
          title: 'Contact Method',
          value: capitalizeAll(applicant?.contactMethod),
          path: routes.EDE_ID_INFO,
        },
        { title: 'Email', value: applicant?.email, path: routes.EDE_ID_INFO },
        { title: 'Phone', value: formatPhone(applicant?.phone?.number), path: routes.EDE_ID_INFO },
        {
          title: 'Home Address',
          value: formatAddress(applicant?.homeAddress),
          path: routes.EDE_ID_INFO,
        },
        {
          title: 'Mailing Address',
          value: formatAddress(applicant?.mailingAddress),
          path: routes.EDE_ID_INFO,
        },
      ],
    };

    const applicants: Array<Section> = allMembers.map((m) => ({
      key: m?.id,
      title: formatName(m),
      data: [
        {
          title: 'Relation',
          value: capitalizeAll(m?.relation),
          path: routes.EDE_CONFIRM_APPLICANTS,
        },
        {
          title: 'Requesting Coverage',
          value: formatBool(m?.isRequestingCoverage),
          path: routes.EDE_CONFIRM_APPLICANTS,
        },
        { title: 'Date of Birth', value: formatDate(m?.dob), path: routes.EDE_MEMBER_INFO },
        { title: 'Sex', value: capitalizeAll(m?.sex), path: routes.EDE_MEMBER_INFO },
        { title: 'SSN', value: formatSSN(m?.ssn), path: routes.EDE_MEMBER_INFO },
        {
          title: 'Race',
          value: formatList(m?.race, (field) => field),
          path: routes.EDE_MEMBER_INFO,
        },
        {
          title: 'Pregnant',
          value: formatBool(m?.isPregnant),
          display: m?.uiQuestionsToDisplay?.['147'],
          path: routes.EDE_MEMBER_QUESTIONS,
        },
        {
          title: 'Incarcerated',
          value: capitalize(m?.incarcerationType?.split('_').join(' ')),
          display: m?.uiQuestionsToDisplay?.['250'],
          path: routes.EDE_MEMBER_QUESTIONS,
        },
        {
          title: 'Foster Care',
          value: formatBool(m?.isFosterCare1825),
          display: m?.uiQuestionsToDisplay?.['149'],
          path: routes.EDE_MEMBER_QUESTIONS,
        },
        {
          title: 'Full Time Student',
          value: formatBool(m?.isFullTimeStudentStatus),
          display: m?.uiQuestionsToDisplay?.['144'],
          path: routes.EDE_MEMBER_QUESTIONS,
        },
        {
          title: 'Medicaid Denied',
          value: m?.medicaidDeniedDate ? formatDate(m?.medicaidDeniedDate) : 'No',
          path: routes.EDE_MEDICAID_DENIAL,
        },
        {
          title: 'Medicaid Ended',
          value: m?.medicaidEndDate ? formatDate(m?.medicaidEndDate) : 'No',
          path: routes.EDE_MEDICAID_DENIAL,
        },
        {
          title: 'Income',
          value: $(m?.annualTaxIncome?.incomeAmount),
          path: routes.EDE_MEMBER_INCOME_CONFIRM,
        },

        {
          title: 'Other Coverage',
          value: formatList(m?.otherCoverages, (coverage) =>
            c(`enums.${coverage?.insuranceMarketType}`),
          ),
          path: routes.EDE_OTHER_COVERAGE,
        },
      ],
    }));

    const specialEnrollment: Section = {
      key: 'sep',
      title: 'Special Enrollment',
      data: allMembers?.map((member) => ({
        title: formatName(member),
        value: formatSEP(member),
        path: routes.EDE_SEP,
      })),
    };

    // filter out non displayed things
    const review = [household, assistors, communications, ...applicants, specialEnrollment];

    return review
      .filter((section) => section.display !== false)
      .map((section) => {
        return {
          ...section,
          data: section?.data?.filter((item) => item.display !== false),
        };
      });
  }, [data?.viewerTwo?.health?.application]);

  const handleNext = (applicationID) => {
    upsert({
      variables: {
        input: {
          id: applicationID,
          lastUsedRoute: 'review',
        },
      },
    });

    navigate(routes.EDE_AGREEMENTS);
  };

  return (
    <SplitLayout
      loading={!data}
      title={c('ReviewApplication.title')}
      subtitle={c('ReviewApplication.subtitle')}
      toolbar={
        <Toolbar onBack={pop}>
          <Button
            disabled={upserting}
            loading={upserting}
            inherit
            testID="enroll"
            onPress={() => handleNext(applicationID)}
          >
            Next
          </Button>
        </Toolbar>
      }
    >
      <SectionList<{ title: string; data: Array<ReviewField> }>
        sections={sections}
        renderItem={({ item }) => {
          return (
            <ComplexRow
              label={item.title}
              sublabel={item.value}
              accessory={<Link onPress={() => navigate(item.path)}>Edit</Link>}
            />
          );
        }}
        renderSectionHeader={({ section }) => {
          return <SectionTitle>{section.title}</SectionTitle>;
        }}
        ItemSeparatorComponent={() => <Divider />}
        renderSectionFooter={() => <View style={{ marginBottom: 32 }} />}
      />
    </SplitLayout>
  );
};

export const ReviewApplicationView = {
  name: routes.EDE_REVIEW,
  component: ReviewApplication,
  options: {
    ...SplitLayout.options,
    title: 'Application review',
  },
};
