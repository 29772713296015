import { KYCStatus } from '@types';

type CIPStatus = 'CIP_DOCS_REQUIRED' | 'CIP_ONGOING_REVIEW';

// mapping of statuses for reference
const cip: Record<CIPStatus, CIPStatus> = {
  CIP_DOCS_REQUIRED: 'CIP_DOCS_REQUIRED',
  CIP_ONGOING_REVIEW: 'CIP_ONGOING_REVIEW',
};

interface CIPState {
  status?: CIPStatus;
  needsCIPDocs: boolean;
}

/**
 * Checks whether user is in ANY cip review state
 * - A user is in some form of CIP if their wealth kyc is review or pending
 */
export const inCIP = (wealthKYC: KYCStatus): boolean => {
  return /KYC_REVIEW|KYC_PENDING/.test(wealthKYC);
};

/**
 * Checks the CIP status for a user
 * - Given the wealth kyc and whether user has uploaded docs
 * - User is in CIP_DOCS_REQUIRED if in review, with no docs yet
 * - User is in CIP_ONGOING_REVIEW if kyc is KYC_PENDING or KYC_REVIEW + docs
 *
 * - returns the status, along with a needsDocs boolean
 *
 * note: this logic is in a bunch of spot, lets just standardize it
 */
export const getCIPStatus = ({
  wealthKYC,
  uploadedDocs,
}: {
  wealthKYC: KYCStatus;
  uploadedDocs: boolean;
}): CIPState => {
  if (!inCIP(wealthKYC)) return { status: undefined, needsCIPDocs: false };

  // if user is in review, without any docs, they need to upload
  if (wealthKYC === 'KYC_REVIEW' && !uploadedDocs) {
    return { status: cip.CIP_DOCS_REQUIRED, needsCIPDocs: true };
  }

  // otherwise, this user is just in review
  return { status: cip.CIP_ONGOING_REVIEW, needsCIPDocs: false };
};
