import React from 'react';
import { Image as RNImage } from 'react-native';
import { Skeleton } from './Skeleton';

/**
 * Any image that has larger dimensions than asset sizes. (full width images)
 */
export const Image = ({ loading, uri, containerStyle, style }) => {
  return (
    <Skeleton loading={loading} style={containerStyle}>
      <RNImage source={{ uri }} style={style} />
    </Skeleton>
  );
};

export default Image;
