import React, { useMemo, useState, useEffect } from 'react';
import { View } from 'react-native';
import { gql, useQuery } from '@apollo/client';

import { Button, ComplexRow, Text, Asset } from '@uikit';
import { routes } from '@navigate';
import { BasicLayout, Stack, Toolbar } from '@app/layouts';
import { PoliciesList } from '@app/partial';
import { getCarrierAsset, precisionRound, Segment, useCopy } from '@app/utils';
import { OptionInput, optionPresets } from '@app/forms';

import Sparkles from '@svg/sparkles-2.svg';
import Check from '@svg/shield-tick.svg';
import Clock from '@svg/clock.svg';
import ArrowDown from '@svg/arrow-down.svg';

const COPY = {
  SAVINGS: (amount) => ({
    title: `Lower your premiums by ${amount} per month!`,
    subtitle: 'Lower your monthly payments today.',
    icon: Sparkles,
    centered: true,
    button: 'Lower my premiums',
    accentColor: 'success',
  }),
  ACTIVE: {
    title: 'Coverage connected',
    subtitle: "You're all set to manage it in Catch",
    icon: Check,
    button: 'Continue',
  },
  INACTIVE: {
    title: 'No marketplace coverage found for you',
    subtitle:
      'Catch finds tax credits for people currently on ACA marketplace plans. If you think this is an error, let us know.',
    icon: Clock,
    button: 'Continue',
  },
};

const IMPORTED_PLANS = gql`
  query Policies($income: Dollars) {
    viewerTwo {
      id
      health {
        policies(input: {}) {
          id
          carrier
          planName
          policyStatus
          premium
          premiumEffective
          startDate
          endDate
          coverageYear @client
          exchangePolicyNumber
          productType
          providerPlan {
            id
            metalLevel
            issuer {
              id
              name
            }
          }
          application {
            id
            status
            eligibility {
              totalAptc
            }
            estimated: eligibility(householdIncomeOverride: $income) {
              totalAptc
            }
          }
        }
      }
    }
    viewer {
      id
      user {
        id
        signupParams
      }
    }
  }
`;

/**
 *
 * This screen imports the apps/policies and then displays them
 *
 * @see https://www.figma.com/file/CJexcruPziVz3e8B219TSP/Health-Link?node-id=1468%3A88028
 * possible @todo: add in-progress apps (hence the commented portions)
 *
 * FURTHER:
 * @note this should serve as a prototype for the list on the coverage tab,
 * especially as we try to move away from enrollments and towards policies and apps
 * @see https://www.figma.com/file/Rc5o9izjndv8XUWagoJ1iE/Coverage?node-id=444%3A42602
 */
const HealthLinkImport = ({ handleNext, importAll, explorerIncome, hasApplications }) => {
  const { $ } = useCopy();

  // set loading so it covers both the import and the refetch without flashes
  const [loading, setLoading] = useState(true);
  const [answer, setAnswer] = useState();

  const { data, refetch } = useQuery(IMPORTED_PLANS, {
    variables: { income: explorerIncome },
  });

  const { policies, hasActivePolicy, policy } = useMemo(() => {
    const policies = data?.viewerTwo?.health?.policies || [];
    const activePolicies = policies?.filter((p) => /ACTIVE|PENDING/.test(p.policyStatus));
    const hasActivePolicy = activePolicies?.length > 0;
    const policy = activePolicies?.filter((p) => /HEALTH/.test(p.productType))[0];

    return {
      policies: activePolicies,
      hasActivePolicy,
      policy,
    };
  }, [data?.viewerTwo?.health?.policies]);

  const { premium, possiblePremium, hasDiff } = useMemo(() => {
    const estimatedAPTC = policy?.application?.estimated?.totalAptc;

    const possiblePremium = precisionRound(
      policy?.premium < estimatedAPTC ? 0 : policy?.premium - estimatedAPTC,
      2,
    );

    return {
      premium: policy?.premiumEffective,
      possiblePremium,
      hasDiff: possiblePremium < policy?.premiumEffective,
    };
  }, [policy]);

  const results = useMemo(() => {
    if (hasActivePolicy && hasDiff) return 'SAVINGS';
    if (hasActivePolicy) return 'ACTIVE';
    if (hasApplications) return 'INACTIVE';
    return 'INACTIVE';
  }, [hasActivePolicy, hasApplications, hasDiff]);

  const content = useMemo(() => {
    if (typeof COPY[results] === 'function') {
      return COPY[results]($(premium - possiblePremium, { whole: true }));
    } else {
      return COPY[results];
    }
  }, [results]);

  const survey = (
    <OptionInput
      testID="marketplace-coverage"
      name="marketplace-coverage"
      label="Do you currently have marketplace coverage?"
      options={optionPresets.YES_NO_BOOLEAN}
      value={answer}
      horizontal
      onChange={(hasCoverage) => {
        // track when a user attested to marketplace coverage but none found
        if (hasCoverage) {
          Segment.track('Health Link Mismatched Attestation', {
            resultsType: results,
          });
        }

        setAnswer(hasCoverage);
      }}
    />
  );

  const children = {
    ACTIVE: <PoliciesList policies={policies} interactive={false} />,
    INACTIVE: survey,
    NONE: survey,
    SAVINGS: (
      <Stack spacing="2">
        <ComplexRow
          testID="original"
          asset={getCarrierAsset(policy?.carrier, 'HEALTH')}
          label={policy?.carrier}
          sublabel={policy?.planName}
          accessory={
            <Text size="lg" weight="bold" strike color="subtle">
              {$(premium, { whole: true })}/mo
            </Text>
          }
          bg="skeleton"
        />
        <View style={{ alignItems: 'center' }}>
          <Asset svg={ArrowDown} color="subtle" />
        </View>

        <ComplexRow
          testID="estimated"
          asset={getCarrierAsset(policy?.carrier, 'HEALTH')}
          label={policy?.carrier}
          sublabel={policy?.planName}
          bg="successBase"
          accessory={
            <Text size="lg" weight="bold" color="success">
              {$(possiblePremium, { whole: true })}/mo
            </Text>
          }
        />
      </Stack>
    ),
  };

  useEffect(() => {
    // always fetch regardless tbh
    importAll({
      onCompleted: async () => {
        await refetch();
        setLoading(false);
      },
    });
  }, []);

  return (
    <BasicLayout
      showLoaderOnly
      centered={content.centered}
      loading={loading}
      icon={{ svg: content.icon, bg: content.accentColor || 'coverage' }}
      title={content.title}
      subtitles={[content.subtitle]}
      toolbar={
        <Toolbar type="stack">
          <Button
            testID="continue"
            inherit={!content?.accentColor}
            disabled={loading}
            accentColor={content?.accentColor}
            onPress={handleNext}
          >
            {content.button}
          </Button>
        </Toolbar>
      }
    >
      {children[results]}
    </BasicLayout>
  );
};

export const HealthLinkImportView = {
  name: routes.HEALTH_LINK_IMPORT,
  component: HealthLinkImport,
  options: {},
};
