import React from 'react';
import { View } from 'react-native';
import { Platform } from 'react-native';

import { Action } from '@types';
import { Layout, Stack } from '@layouts';
import { Asset, useTheme, ContextMenu, Pressable, Text, IconSvg } from '@uikit';
import { brandColors, Actions } from '@app/config';
import { useCopy } from '@app/utils';
import { date as _date } from '@app/utils/format/date';
import { CatchTheme } from '@app/_ui-kit/themes/catch';
import { handleAction } from '@navigate';

import More from '@svg/dots-horizontal.svg';

interface IncomePromptCardParams {
  name: string;
  title: string;
  asset?: any;
  actions?: Action[];
  amount: number;
  date: any;
  hex: any;
  idx?: number;
  memo?: string;
  disabled?: boolean;
}

/**
 * @todo use copy sheet
 */
const IncomePromptCard = ({
  name,
  title,
  amount,
  memo,
  actions = [],
  asset,
  date,
  idx,
  hex,
  disabled,
}: IncomePromptCardParams) => {
  const { $ } = useCopy('catch.basics');
  const { theme, themeColors } = useTheme();
  const CARD_WIDTH = CatchTheme.constants.promptWidthSm;
  const textColor = brandColors[title]?.darkOverlay ? 'snow' : 'text';

  return (
    <View style={disabled && theme.disabledIncome}>
      <Pressable
        style={[
          theme.xlargeCorners,
          theme.fadeInLeft,
          theme.allSpace1,
          theme.cardShadow,
          theme.thinBorder,
          {
            overflow: 'visible',
            width: CARD_WIDTH,
            maxWidth: CARD_WIDTH,
            backgroundColor: hex || brandColors[title]?.overlay || themeColors.sheetColor,
            boxSizing: 'border-box',
          },

          Platform.select({
            web: {
              animationDelay: `${250 + 50 * idx}ms`,
              animationDuration: `${500 + 75 * idx}ms`,
            },
          }),
        ]}
      >
        <View style={[theme.largeCorners, { overflow: 'hidden' }]}>
          <Stack
            separatorComponent={
              actions?.filter((action) => !!action && !action?.hidden)?.length === 1
            }
          >
            <>
              <View style={styles.contextMenu}>
                <ContextMenu options={[Actions.HELP]}>
                  <Asset
                    containerSize="xs"
                    size="xs"
                    svg={More}
                    color={textColor}
                    bg="skeleton"
                    shape="circle"
                  />
                </ContextMenu>
              </View>

              <View style={[theme.topSpace1, theme.xSpace1]}>
                <Stack horizontal spacing="0b">
                  <Asset {...asset} size="md" style={[theme.cardShadow]} />
                  <Text color={textColor} size="p" align="center" weight="medium">
                    {title}
                  </Text>
                </Stack>
                <Layout topSpace bottomSpace>
                  <Text color={textColor} size={48} weight="medium" align="center" group>
                    {$(amount)}
                  </Text>
                  <Text
                    color={textColor}
                    size="p"
                    weight="light"
                    align="center"
                    group
                    style={[theme.lightFade]}
                  >
                    {memo}
                    {_date(date, 'RELATIVE')}
                  </Text>
                </Layout>
              </View>
            </>
            <Stack horizontal spacing="0b" align="center" style={theme.spaceBetween}>
              {actions?.map((action, idx) => (
                <Pressable
                  key={idx}
                  hoveredStyle={theme.fullOpacity}
                  style={[
                    theme.ySpace2,
                    theme.xSpace3,
                    theme.alignCenter,
                    theme.smallCorners,
                    theme.lightFade,
                  ]}
                  handleOnPress={() =>
                    handleAction(action, { nudgeId: name, ...action.routeProps })
                  }
                >
                  <IconSvg svgComponent={action?.icon?.svg} size="sm" color={textColor} />
                  <Text
                    color={textColor}
                    align="center"
                    testID={action.testID || action.label || `ACTION_${idx}`}
                  >
                    {action.label}
                  </Text>
                </Pressable>
              ))}
            </Stack>
          </Stack>
        </View>
      </Pressable>
    </View>
  );
};

const styles = {
  contextMenu: {
    position: 'absolute',
    zIndex: 99,
    right: 16,
    top: 16,
  },
};

export default IncomePromptCard;
