import React from 'react';

import { routes, ScreenDefinition } from '@navigate';
import { useDeprecatedQuery } from '@app/data';
import { Button, Text, stateIcons } from '@app/_ui-kit';
import { Layout, Page } from '@app/layouts';
import { useCopy } from '@app/utils';
import { date } from '@app/utils/format/date';
import { taxPaymentStatus, taxPaymentEntity } from '@app/utils/format/status';

import IRS from '@svg/irs.svg';

/**
 * Tax payments status is updated through Abound webhooks. They can be triggered
 * by creating payments in a specific amount. References below
 * @see {@link https://docs.withabound.com/docs/sandbox-test-values-for-taxpayments}
 */

export const TaxPayment = ({ paymentId }) => {
  const { $ } = useCopy('catch.basics');
  const { taxPayment, waiting } = useDeprecatedQuery('taxPayment', {
    variables: { paymentId: paymentId },
    skip: !paymentId,
  });

  return (
    <Page color="sheet" margins>
      <Layout topSpace bottomSpace>
        <Layout.Header
          bottomSpace
          loading={waiting}
          align="center"
          title={`${taxPayment?.timeframe} ${date(taxPayment?.createdOn, 'YEAR')}`}
          subtitle={taxPaymentStatus(taxPayment)} /** @todo status casing */
        />

        <Layout topSpace bottomSpace>
          <Layout.Header
            asset={
              taxPayment?.jurisdiction === 'US'
                ? {
                    svg: IRS,
                    color: 'text',
                  }
                : {
                    icon: stateIcons[taxPayment?.jurisdiction],
                    color: 'text',
                  }
            }
            titleSize="display"
            title={$(taxPayment?.amount)}
            subtitle={`Estimated payment to ${taxPaymentEntity(taxPayment?.jurisdiction)}`}
            align="center"
            loading={waiting}
          />
        </Layout>
      </Layout>

      {taxPayment?.confirmationCode && (
        <Text loading={waiting} mono align="center" group>
          Confirmation # {taxPayment?.confirmationCode}
        </Text>
      )}

      {taxPayment?.document?.url && (
        <Layout center>
          <Button small alt accentColor="taxesLight" href={taxPayment?.document?.url}>
            Download voucher
          </Button>
        </Layout>
      )}
    </Page>
  );
};

export const TaxPaymentView: ScreenDefinition = {
  name: routes.TAX_PAYMENT,
  component: TaxPayment,
  options: {},
};
