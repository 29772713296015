import React from 'react';
import { openIntercom, useCopy } from '@app/utils';
import { useAllowTransfers, useLaunchTaxPayments } from '@hooks';
import { SheetComponentProps, open, navigate, routes, sheets, useSheet } from '@navigate';
import { ComplexRow, Loading, SimpleRow } from '@uikit';
import { SheetBlueprint } from '@blueprints';
import { Stack } from '@layouts';
import { benefitColorLookup } from '@app/utils/color';
import Payment from '@svg/arrow-up-right.svg';
import Withdraw from '@svg/arrow-up-left.svg';
import Deposit from '@svg/arrow-down-right.svg';

const assetProps = {
  shape: 'circle',
  size: 'xs',
  containerSize: 'md',
};

const TransferOptionsSheet: React.FC<SheetComponentProps['TRANSFER_OPTIONS']> = ({ goal }) => {
  const { c } = useCopy('catch');
  const { replace } = useSheet();

  const headers = {
    needsLink: {
      title: c('basics.linkYourBank'),
      subtitle: c('basics.linkYourBankDescription'),
    },
    default: {
      title: c('basics.transfer'),
    },
  };

  const { allowTaxPayment: taxPaymentsAllowed, open: openTaxPayments } = useLaunchTaxPayments();

  const { disabledDeposits, disabledWithdrawals, disabledPayments, hasAnyBankLink, loading } =
    useAllowTransfers({ benefit: goal });

  const showTaxPaymentsRow =
    (!goal || goal?.goalType === 'TAX') && !!taxPaymentsAllowed && !disabledPayments;

  const openTransfer = (transferType) => {
    open('TRANSFER_AMOUNT', {
      transferType,
      goal,
    });
  };

  // no transfers to insurance
  if (goal?.goalType === 'HEALTH' || goal?.goalType === 'DENTAL') {
    return null;
  }

  const onDisabled = (reason) => {
    switch (reason) {
      case 'BANKLINK_ACTIONABLE':
        return () => navigate(routes.LINKED_BANKS);
      case 'BANKLINK_TEMP':
      case 'NO_BALANCE_TO_WITHDRAW':
      case 'REACHED_RETIREMENT_LIMIT':
      case 'ACCOUNT_TIER':
      case 'LOCKED':
        return openIntercom;

      case 'NO_RETIREMENT_WITHDRAW':
        return () => replace(sheets.RETIREMENT_WITHDRAWAL);

      case 'DENIED':
      case 'REVIEW':
      case 'NOT_SETUP':
      default:
        return openIntercom;
    }
  };

  const options = [
    // {
    //   type: 'deposit',
    //   asset: {
    //     svg: Deposit,
    //     color: 'fg',
    //     bg: 'savedLightColor',
    //     ...assetProps,
    //   },
    //   label: c('basics.depositLong'),
    //   onPress: () => openTransfer('deposit'),
    //   disabled: disabledDeposits?.disabled,
    //   reason: c(`transfer.disabled.${disabledDeposits?.reason}`),
    //   onDisabled: onDisabled(disabledDeposits?.reason),
    // },
    // {
    //   type: 'payment',
    //   hidden: !showTaxPaymentsRow,
    //   asset: {
    //     svg: Payment,
    //     color: 'fg',
    //     bg: 'taxesLightColor',
    //     ...assetProps,
    //   },
    //   label: c('basics.taxPaymentLong'),
    //   onPress: openTaxPayments,
    //   disabled: disabledPayments?.disabled,
    //   reason: c(`transfer.disabled.${disabledPayments?.reason}`),
    //   onDisabled: onDisabled(disabledPayments?.reason),
    // },
    {
      type: 'withdraw',
      label: c('basics.withdrawLong'),
      onPress: () => openTransfer('withdraw'),
      disabled: true || disabledWithdrawals?.disabled,
      reason: 'Catch shut down on April 6',
      onDisabled: onDisabled(disabledWithdrawals?.reason),
      asset: {
        svg: Withdraw,
        color: 'fg',
        bg: 'coverageLightColor',
        ...assetProps,
      },
    },
  ];

  return (
    <>
      {loading ? (
        <Loading accentColor={benefitColorLookup({ goalType: goal?.goalType })} />
      ) : (
        <SheetBlueprint
          {...headers[!hasAnyBankLink ? 'needsLink' : 'default']}
          altRows={hasAnyBankLink}
          hasBottomActions // this is required because the rows are acting like bottom actions for spacing purposes
        >
          {hasAnyBankLink && (
            <Stack separatorComponent>
              {options
                ?.filter((o) => !o.hidden)
                .map((o) => (
                  <>
                    <ComplexRow
                      key={o.type}
                      inset
                      onPress={o.onPress}
                      disabled={o.disabled}
                      asset={o.asset}
                      label={o.label}
                    />
                    {o.reason && (
                      <SimpleRow
                        inset
                        label={o.reason}
                        onPress={o.onDisabled}
                        accessory={o.onDisabled ? 'arrow' : undefined}
                      />
                    )}
                  </>
                ))}
            </Stack>
          )}
        </SheetBlueprint>
      )}
    </>
  );
};

export default TransferOptionsSheet;
