import React from 'react';
import { View } from 'react-native';
import { Accordion, ComplexRow, useTheme } from '@uikit';

const PersonRow = ({
  name,
  onPress,
  isLast,
  checked,
  children,
  bare,
  secondary,
  raised,
  accessory,
}) => {
  const { theme } = useTheme();
  const button = (
    <ComplexRow
      asset={{ render: 'person' }}
      accessory={accessory}
      onPress={onPress}
      label={name}
      sublabel={secondary}
      raised={raised}
      inset={true}
      bg="skeleton"
    />
  );
  if (bare) {
    return button;
  }
  return (
    <Accordion openCtrl={checked} divider={!isLast} button={button}>
      <View style={[theme.allSpace2, theme.leftSpace7b]}>{children}</View>
    </Accordion>
  );
};

export default PersonRow;
