// @todo
import React, { useCallback } from 'react';
import { Text, Link } from '@uikit';
import { TextField } from '@app/forms_old/inputs';
import {
  useMutation,
  mutations,
  ChangePhoneResponse,
  ChangePhoneVars,
  ChangeEmailResponse,
  ChangeEmailVars,
} from '@data';
import { createLogger } from '@app/utils';
import { InputProps } from '../types';
import { checkAliasType } from './AliasInput';
import { useAuth } from '@app/client';

const Log = createLogger('code-input');

interface ConfirmationCodeInputProps<T> extends InputProps<T> {
  alias: string;
}

// only stores 6 digit codes
export const parseCode = (val) => {
  return val.replace(/\D+/g, '').slice(0, 6);
};

const ConfirmationCodeInput = React.forwardRef(
  <T extends any>(
    {
      testID,
      autoFocus,
      name,
      value,
      label,
      disabled,
      onChange,
      onBlur,
      onKeyPress,
      reset,
      error,
      alias,
    }: ConfirmationCodeInputProps<T>,
    ref,
  ) => {
    const { authState, states, resendCode, code } = useAuth();

    // mutations for changing phone/email while already logged in
    const [changePhone, { loading: changingPhone }] = useMutation<
      ChangePhoneResponse,
      ChangePhoneVars
    >(mutations.CHANGE_PHONE);
    const [changeEmail, { loading: changingEmail }] = useMutation<
      ChangeEmailResponse,
      ChangeEmailVars
    >(mutations.CHANGE_EMAIL);

    const changeAlias = useCallback(() => {
      if (!alias) {
        Log.debug('Alias must be configured on form field!');
      } else {
        if (authState !== states.SIGNED_IN) {
          console.log('resending)');
          resendCode();
        } else if (checkAliasType(alias) === 'phone') {
          changePhone({ variables: { phoneNumber: alias } });
        } else {
          changeEmail({ variables: { email: alias } });
        }
      }
    }, [alias]);

    const resending = code?.sending || changingPhone || changingEmail;

    return (
      <>
        <TextField
          ref={ref}
          testID={testID}
          disabled={disabled}
          autoFocus={autoFocus}
          keyboard="code"
          name={name}
          value={value}
          onChangeText={(val) => onChange(parseCode(val))}
          onBlur={onBlur}
          onKeyPress={onKeyPress}
          label={label}
          placeholder="••••••"
          error={error}
          fullWidth
          large
        />
        <Text size="fp">
          Didn&apos;t receive a code?{' '}
          <Link
            disabled={resending}
            onPress={() => {
              changeAlias();
              reset();
            }}
          >
            {resending ? 'Resending...' : 'Resend code'}
          </Link>
        </Text>
      </>
    );
  },
);

ConfirmationCodeInput.displayName = 'ConfirmationCodeInput';
export default ConfirmationCodeInput;
