import React, { useMemo } from 'react';

import { HomeQueryData } from '@data';
import { Widget } from '@uikit';
import { useCopy, currentYear } from '@util';
import { navigate, routes } from '@navigate';
import IncomeBar from '@app/charts/IncomeBar';

interface IncomeWidgetProps {
  data?: HomeQueryData;
}

const shouldDisplay = (data?: HomeQueryData): boolean => {
  return (data?.viewer?.planHistorySummary?.grossIncome || 0) > 0;
};

const IncomeWidget = ({ data }: IncomeWidgetProps) => {
  const { $ } = useCopy('catch.basics');

  const grossIncome = useMemo(
    () => data?.viewer?.planHistorySummary?.grossIncome || 0,
    [data?.viewer?.planHistorySummary],
  );

  const joinDate = useMemo(() => new Date(data?.viewer?.user?.joinDate), [data?.viewer?.user]);

  if (!shouldDisplay(data)) {
    return null;
  }

  return (
    <Widget
      onPress={() => navigate(routes.INCOME)}
      title="Income"
      subtitleValue={$(grossIncome, { whole: true })}
      subtitleSuffix={`made ${
        joinDate.getFullYear() === currentYear
          ? 'since ' + joinDate.toLocaleString('default', { month: 'long' })
          : 'this year'
      }`}
    >
      <IncomeBar widget incomeByMonth={data?.viewerTwo?.incomeForYear} />
    </Widget>
  );
};

IncomeWidget.shouldDisplay = shouldDisplay;
export default IncomeWidget;
