import gql from 'graphql-tag';
import access from 'safe-access';
import { getTotalIncome, getHouseholdIncome } from '@app/utils';

const WORK = gql`
  query Work {
    viewer {
      id
      user {
        id
        workType
        filingStatus
        jobTitle
      }
      incomeState
      income {
        estimatedW2Income
        estimated1099Income
      }
      spouseIncome
    }
  }
`;

const formatter = (data) => {
  const get = access(data?.viewer);

  const workType = get('user.workType');
  const filingStatus = get('user.filingStatus');
  const jobTitle = get('user.jobTitle');
  const estimated1099Income = get('income.estimated1099Income');
  const estimatedW2Income = get('income.estimatedW2Income');
  const spouseIncome = get('spouseIncome');
  const incomeState = get('incomeState');

  // computed total income
  const estimatedIncome = getTotalIncome({
    estimatedW2Income,
    estimated1099Income,
    workType,
  });

  // computed household income
  const householdIncome = getHouseholdIncome({
    estimatedIncome,
    spouseIncome,
    filingStatus,
  });

  return {
    // raw data
    workType,
    filingStatus,
    jobTitle,
    incomeState,
    estimated1099Income,
    estimatedW2Income,
    spouseIncome,

    // computed incomes
    estimatedIncome,
    householdIncome,
  };
};

const mock = (values) => {
  return {
    request: { query: WORK },
    result: {
      data: {
        viewer: {
          user: {
            id: '123', // note: required for mocks to work
            workType: values?.workType || '',
            filingStatus: values?.filingStatus || '',
            jobTitle: values?.jobTitle || '',
          },
          incomeState: values?.incomeState || '',
          income: {
            estimatedW2Income: values?.estimatedW2Income || '',
            estimated1099Income: values?.estimated1099Income || '',
          },
          spouseIncome: values?.spouseIncome || '',
        },
      },
    },
  };
};

export default {
  document: WORK,
  formatter,
  mock,
};
