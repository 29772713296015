import React from 'react';
import { routes } from '@navigate';
import { Proofing } from '@app/partial';

/**
 * Health Link IDP
 * @see https://www.figma.com/file/xkXhK2lvfZyzMp6GUMFPuc/Onboarding?node-id=4083%3A50646
 */
const Identity = ({ handleNext, canSearch }) => {
  return <Proofing canSearch={canSearch} handleSuccess={handleNext} />;
};

export const HealthLinkIdentityView = {
  name: routes.HEALTH_LINK_IDENTITY,
  component: Identity,
  options: {},
};

export const HealthRenewIdentityView = {
  name: routes.HEALTH_RENEW_IDENTITY,
  component: (props) => <Identity {...props} canSearch />,
  options: {},
};
