import { isBefore, subDays, addDays } from 'date-fns';
import { getToday } from '../time';
import { getNextQuarterAfterDate, getQuarterProgress } from '../const/QUARTERS';

// allow 2 days for processing payments
const PAYMENT_BUFFER = 0;
// removed blackout periods per Sam Thayer 1/25/21

/**
 * Checks whether we are in a tax payment period currently
 * and returns boolean flag isTaxPaymentPeriod,
 * along with quarter details
 */
export const checkTaxPaymentPeriod = () => {
  // use this function so that we can overwrite "today" for testing
  const today = getToday();

  // gets information for next quarter that is due at least 2 days from today
  const { due, quarterNum, taxYear } = getNextQuarterAfterDate(
    addDays(today, PAYMENT_BUFFER),
    'due',
  );

  // only prompt when between 3 weeks before due date and 2 days before due date
  const shouldPromptTaxPeriod =
    isBefore(subDays(due, 21), today) && isBefore(today, subDays(due, PAYMENT_BUFFER));

  return {
    shouldPromptTaxPeriod,
    quarterNum,
    taxYear,
    quarterDueDate: due,
    quarterProgress: getQuarterProgress(),
  };
};
