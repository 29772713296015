import React from 'react';

import { Text, ComplexRow } from '@uikit';
import { formatProvider, capitalize } from '@app/utils';

/**
 * @deprecated use Simple/Complex Row instead
 */
const ProviderResult = ({ doctor, select }) => {
  return (
    <ComplexRow
      sensitive
      label={formatProvider(doctor?.name)}
      sublabel={doctor?.taxonomy}
      onPress={() => select(doctor)}
      accessory={
        <Text size="fp" color="subtle">
          {capitalize(doctor?.address?.city)}, {doctor?.address?.state}
        </Text>
      }
    />
  );
};

export default ProviderResult;
