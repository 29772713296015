import React from 'react';
import { View } from 'react-native';
import { CountUp } from 'use-count-up';

import { Text } from '@uikit';
import { Stack } from '@app/layouts';

/**
 * @todo make this more generic, perhaps combine or use with ResultCard
 */
const BigMoney = ({ amount, color, digits = 2, label, countup = false, ...props }) => {
  return (
    <Stack horizontal>
      <Text color={color} inline size={props?.small ? 'h1' : 'h2'} weight="bold">
        {countup ? (
          <CountUp
            isCounting
            end={amount}
            start={0}
            prefix="$"
            easing="easeOutCubic"
            duration={1 + amount / 500}
            thousandsSeparator=","
            decimalPlaces={digits}
          />
        ) : (
          Number(amount)?.toFixed(digits)
        )}
      </Text>

      <View>
        {typeof label === 'string' ? (
          <Text color={color} inline weight="bold" size="h4">
            {label}
          </Text>
        ) : (
          label
        )}
      </View>
    </Stack>
  );
};

export default BigMoney;
