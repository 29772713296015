/**
 * We need the following user data to successfully ID proof
 * (note: this is different than the data we need to perform an EDE lookup)
 */
export const hasIdentityData = (user) => {
  return (
    !!user?.dob &&
    !!user?.ssn &&
    !!user?.givenName &&
    !!user?.familyName &&
    !!user?.legalAddress?.street1
  );
};
