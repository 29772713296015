import gql from 'graphql-tag';

const Source = gql`
  fragment Source on IncomeTransactionSource {
    id
    ruleID
    text
    count
    sourceDetail
    canBeRule
    taggedSource {
      id
      name
      logo {
        url
      }
    }
  }
`;

export const IncomeTransactionFragments = {
  source: Source,
  transactionSource: gql`
    fragment PaycheckSource on IncomeTransaction {
      id
      subtag
      source {
        id
        ...Source
      }
    }
    ${Source}
  `,
  sourceFromRule: gql`
    fragment RuleSource on IncomeAutomationRule {
      id
      source {
        id
        ...Source
      }
    }
    ${Source}
  `,
};

export const formatSource = (data) => {
  if (!data?.source) return null;
  return {
    id: data?.source?.id,
    ruleID: data?.source?.ruleID,
    tagID: data?.source?.taggedSource?.id,
    tag: data?.source?.text,
    subtag: data?.subtag,
    count: data?.source?.count,
    logoUrl: data?.source?.taggedSource?.logo?.url,
    ...data?.source,
  };
};

export const formatTransactionTag = (data) => {
  if (!data?.source) return null;
  return {
    id: data?.source?.id,
    tag: data?.source?.name,
    logoUrl: data?.source?.logo?.url,
    workType: data?.source?.workType,
  };
};

export default {
  fragments: IncomeTransactionFragments,
  formatSource,
  formatTransactionTag,
};
