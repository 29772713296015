import React from 'react';
import { Copy } from '@types';
import { InputProps } from '../types';

import { Checkbox, ComplexRow, Pressable, useTheme } from '@uikit';
import { FieldWrapper } from '@forms/inputs/FieldWrapper';
import { Stack } from '@app/layouts';

interface PersonRowInputProps<T> extends InputProps<T> {
  personName?: string;
  label: Copy;
  secondary: {
    checked: string;
    unchecked: string;
  };
  inset?: boolean;
  accessory?: any;
}

const PersonRowInput = <T extends any>({
  label,
  personName,
  name,
  onChange,
  onFocus,
  value,
  disabled,
  testID,
  secondary = { checked: '', unchecked: '' },
  inset = true,
  Accessory,
  ...rest
}: PersonRowInputProps<T>) => {
  const { theme } = useTheme();
  const input = { value, onChange };
  const slugifiedLabel = label?.toLowerCase().replace(' ', '-');

  return (
    <Pressable
      accesible
      accessibilityLabel={testID}
      disabled={disabled}
      handleOnPress={() => input.onChange(!input.value)}
      onFocus={onFocus}
      style={theme.fullWidth}
      testID={`member-${slugifiedLabel}`}
    >
      <FieldWrapper style={theme.fullWidth} noGutter fullWidth>
        <ComplexRow
          full
          wide
          style={[
            theme.xSpace1b,
            theme.ySpace1b,
            theme.justifyStart,
            theme.mediumCorners,
            theme[`checkbox${input.value ? 'Selected' : 'Deselected'}Bg`],
          ]}
          asset={{ render: 'person' }}
          accessory={
            <Stack horizontal spacing="1">
              <Checkbox
                data-name={input.name}
                inside
                name={input.name}
                value={!!input.value}
                checked={!!input.value}
                onValueChange={input.onChange}
                testID={`${testID}-input`}
                style={{ cursor: 'pointer' }}
                {...rest}
              />
              {Accessory && <Accessory />}
            </Stack>
          }
          // onPress={onPress}
          label={label}
          sublabel={secondary[!!input.value ? 'checked' : 'unchecked']}
          inset={inset}
          bg="skeleton"
        />
      </FieldWrapper>
    </Pressable>
  );
};

export default PersonRowInput;
