import React from 'react';
import { Form, Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';

import { exit, routes, ScreenDefinition } from '@navigate';
import { useDeprecatedMutation, useDeprecatedQuery } from '@data';
import { FlowLayout, Layout } from '@layouts';
import { popToast } from '@uikit';
import { useCopy } from '@app/utils';
import FileDropper from '@common/components/FileDropper';

const filterBothSidedDocs = (docsNeeded) => {
  if (docsNeeded?.some((doc) => doc.documentType === 'PHOTO_ID_FRONT')) {
    return docsNeeded.filter(
      (doc) => doc.documentType === 'PHOTO_ID_FRONT' || doc.documentType === 'PHOTO_ID_BACK',
    );
  }

  return docsNeeded;
};

/**
 * This will be the future
 * (WIP) The new generic Doc Upload, powered by viewerTwo.identity
 *
 * @todo some name formatting
 * @todo multiple providers
 *
 * Temporary fix is in place right now to prioritize PHOTO_ID_FRONT
 * uploads. If a user has other documents to be uploaded, it has to
 * upload PHOTO_ID_FRONT first.
 *
 * Multiple documents are uploaded individually but submitted all at
 * once
 */
const DocUpload = () => {
  const { c } = useCopy('catch.plans.UploadKycImageView');
  const { c: docType } = useCopy('catch.enum.docType');

  const {
    refetch,
    userId,
    docsNeeded,
    loading: userLoading,
  } = useDeprecatedQuery('docUploads', {
    variables: {
      input: { statusList: ['REQUIRED', 'INVALID'] },
    },
  });

  const docsFiltered = filterBothSidedDocs(docsNeeded);
  const docRequest = docsFiltered?.[0];
  const [uploadDoc] = useDeprecatedMutation('uploadDocument');
  const [submitDoc, { loading: submitting }] = useDeprecatedMutation('submitDocuments');

  const onSubmit = async ({ documents }) => {
    try {
      const {
        data: {
          submitDocuments: { error },
        },
      } = await submitDoc({
        variables: {
          input: {
            documentList: documents,
          },
        },
      });

      if (error) {
        await refetch();
        popToast({
          type: 'error',
          title: c('toast.failure.title'),
          msg: error,
        });
      } else {
        popToast({
          type: 'neutral',
          title: c('toast.success.title'),
          msg: c('toast.success.description'),
        });
        exit(routes.HOME);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const values = docsFiltered?.map((doc) => ({
    documentType: doc.documentType,
    documentRequiredID: doc.id,
    documentID: null,
  }));

  return (
    <Form
      onSubmit={onSubmit}
      subscription={{ valid: true }}
      keepDirtyOnReinitialize
      mutators={{
        ...arrayMutators,
      }}
      initialValues={{
        documents: values,
      }}
    >
      {({ handleSubmit, valid }) => (
        <FlowLayout
          subtitle={!!docRequest ? c('descriptionGeneric') : ''}
          onNext={handleSubmit}
          canClickNext={valid && !!docRequest}
          loading={userLoading || submitting}
          nextButtonText={c('submitBtn')}
          color="page"
          narrow
          title={
            !!docRequest
              ? c('titleGeneric', { docType: docType(docRequest?.documentType) })
              : c('titleFallback')
          }
        >
          {/* <SegmentedControl
              index={tabIdx}
              setIndex={setTabIdx}
              controls={[{ title: 'Upload' }, { title: 'Choose Existing' }]}
            /> */}
          <FieldArray name="documents">
            {({ fields }) => {
              const { value } = fields;

              return fields.map((name, i) => {
                const field_values = value[i];
                const { documentType } = field_values;
                const isDocTypeID = /PHOTO_ID/.test(documentType);
                const side = isDocTypeID && /PHOTO_ID_(.*)$/.exec(documentType)[1];

                return (
                  <Layout key={name} bottomSpace={i !== fields.length - 1}>
                    <Field
                      name={`${name}`}
                      validate={(value) => (value?.documentID ? undefined : 'required')}
                    >
                      {({ input }) => (
                        <FileDropper
                          filePrefix={userId}
                          copyPrefix={
                            isDocTypeID &&
                            `catch.ID${side.slice(0, 1)}${side.slice(1).toLowerCase()}Upload`
                          }
                          handleDelete={() => {
                            input.onChange({
                              ...field_values,
                              documentID: null,
                            });
                          }}
                          name={documentType}
                          handleDrop={async ({ fileKey, fileType }) => {
                            const { data } = await uploadDoc({
                              variables: {
                                input: { key: fileKey, fileType, documentType },
                              },
                            });
                            input.onChange({
                              ...field_values,
                              documentID: data?.uploadDocument?.id,
                            });
                          }}
                        />
                      )}
                    </Field>
                  </Layout>
                );
              });
            }}
          </FieldArray>
          {/* {tabIdx === 1 && (
              <Stack separatorComponent>
                {allUploads?.map((doc, idx) => (
                  <SimpleRow
                    key={idx}
                    interaction="select"
                    selectedByDefault={doc?.id === pendingId}
                    onPress={() =>
                      doc?.id === pendingId ? setPendingId(null) : setPendingId(doc?.id)
                    }
                    label={
                      <Text weight="medium">
                        {docType(doc?.documentType)}
                        {'  '}
                        <Text color="subtler" weight="regular">
                          uploaded {date(doc?.createdOn, 'RELATIVE')}
                        </Text>
                      </Text>
                    }
                  />
                ))}
              </Stack>
            )} */}
        </FlowLayout>
      )}
    </Form>
  );
};

export const DocumentUpload: ScreenDefinition = {
  name: routes.DOC_UPLOAD,
  component: DocUpload,
  options: {
    layout: 'page',
    navMode: 'flow',
  },
};
