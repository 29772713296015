import gql from 'graphql-tag';
import { IncomeAction, CatchTransactionTag } from '@types';

export interface PaycheckData {
  id: string;
  date: Date;
  amount: number;
  status: IncomeAction;
  source: {
    id: string;
    text: string;
    taggedSource: CatchTransactionTag;
    __typename: 'IncomeTransactionSource';
  };
  __typename: 'IncomeTransactionConnection';
}

export interface PaychecksQueryData {
  viewer: {
    id: string;
    paychecks: {
      edges: PaycheckData[];
    };
  };
}

export const PaychecksQuery = gql`
  query PaychecksQuery {
    viewer {
      id
      paychecks: incomeTransactionsPaged(
        pagination: { pageNumber: 1, pageSize: 10 }
        incomeAction: [USER_PENDING]
      ) {
        edges {
          id
          ... on IncomeTransaction {
            id
            amount
            date
            status
            source {
              id
              text
              taggedSource {
                id
                name
                logo {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;
