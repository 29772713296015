import React from 'react';
import shallow from 'zustand/shallow';
import { useDeprecatedQuery, useDeprecatedMutation } from '@data';
import { useLaunchTaxPayments, useSnooze, useAllowTransfers } from '@app/hooks';
import { canDeleteBenefit, conditionalItem, deslug } from '@app/utils';
import { SheetBlueprint } from '@blueprints';
import { Actions } from '@app/config';
import { routes, open, useSheet, sheets } from '@app/navigate';
import Pause from '@svg/pause.svg';

const TaxesMenuSheet = () => {
  const { taxGoal } = useDeprecatedQuery('taxGoal');
  const { canPause, pause, resume } = useSnooze(taxGoal);
  const [removeGoal, { loading: deleting }] = useDeprecatedMutation('upsertGoal', {
    onCompleted: () => {},
  });

  const { allowTaxPayment: taxPaymentsAllowed, open: openTaxPayments } = useLaunchTaxPayments();
  const { disabledPayments } = useAllowTransfers({ benefit: taxGoal });

  const { openSheet, replaceSheet } = useSheet(
    (state) => ({ replaceSheet: state.replace, openSheet: state.open }),
    shallow,
  );

  return (
    <SheetBlueprint
      title={deslug('taxes')}
      actions={
        taxGoal.isSetup
          ? [
              {
                ...Actions.EDIT_WITHHOLDING,
                onAction: () => open(routes.GOAL_EDIT, { slug: 'taxes' }),
                disabled: true,
              },
              {
                ...Actions.TRANSFER,
                disabled: true,
                onAction: () =>
                  replaceSheet(sheets.TRANSFER_OPTIONS, {
                    goal: taxGoal,
                    goalType: taxGoal.goalType,
                  }),
              },
              ...conditionalItem(!!taxPaymentsAllowed && !disabledPayments, {
                ...Actions.TAX_PAYMENT,
                onAction: openTaxPayments,
                disabled: true,
              }),
              /** @todo use donut for the view below */
              {
                ...Actions.TAX_RECOMMENDATION,
                onAction: () => openSheet(sheets.TAX_BREAKDOWN_GUIDE, { shouldRunQuery: true }),
                disabled: true,
              },
              {
                testID: 'pause-resume-goal',
                glyph: Pause,
                label: canPause ? 'Pause' : 'Resume',
                onAction: canPause ? pause : resume,
                disabled: true,
              },

              ...conditionalItem(
                false && taxGoal?.totalBalance === 0 && canDeleteBenefit(taxGoal?.goalType),
                {
                  ...Actions.REMOVE_GOAL,
                  loading: deleting,
                  onAction: () =>
                    // @ts-ignore
                    removeGoal({
                      variables: {
                        input: {
                          id: taxGoal?.id,
                          status: 'DELETED',
                        },
                      },
                    }),
                },
              ),
            ]
          : [
              {
                ...Actions.EDIT_WITHHOLDING,
                label: 'Set Up Tax Withholding',
                onAction: () => open(routes.GOAL_INTRO, { slug: 'taxes' }),
              },
            ]
      }
    />
  );
};

export default TaxesMenuSheet;
