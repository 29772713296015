import React from 'react';
import { VictoryLine } from '../resolve';
import { ChartProps } from './types';

interface LineChartProps extends ChartProps {
  data: Array<any>;
  style: any;
  x: string;
  y: string;
  domain: any;
  animate: any;
}

export const LineChart = ({ data = [], x, y, domain, style, animate }: LineChartProps) => (
  <VictoryLine
    padding={{ top: 0, bottom: 0 }}
    style={style}
    data={data}
    height={120}
    x={x}
    y={y}
    domain={domain}
    interpolation="monotoneX"
    animate={animate}
  />
);

export default LineChart;
