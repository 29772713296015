import { useEffect, useMemo, useState } from 'react';
import { Dimensions, StatusBar } from 'react-native';

import { Keyboard } from 'react-native';

export const useKeyboardStatus = () => {
  /* State */
  const [isShown, setIsShown] = useState<boolean>(false);
  const [isOpening, setIsShowing] = useState<boolean>(false);
  const [isClosing, setIsHiding] = useState<boolean>(false);
  /* Computed */
  const isTransitioning = useMemo<boolean>(() => isOpening || isClosing, [isOpening, isClosing]);
  const screenHeight = Dimensions?.get('screen')?.height;
  const windowHeight = Dimensions?.get('window')?.height;
  const navbarHeight = screenHeight - windowHeight + Number(StatusBar?.currentHeight);

  /* Handlers */
  const handleShown = () => [setIsShown(true), setIsShowing(false)];
  const handleHidden = () => [setIsShown(false), setIsHiding(false)];
  const handleShowStart = () => setIsShowing(true);
  const handleHideStart = () => setIsHiding(true);
  /* Subscriptions */
  const shownSub = Keyboard.addListener('keyboardDidShow', handleShown);
  const startedShowingSub = Keyboard.addListener('keyboardWillShow', handleShowStart);
  const startedHidingSub = Keyboard.addListener('keyboardWillHide', handleHideStart);
  const hiddenSub = Keyboard.addListener('keyboardDidHide', handleHidden);

  useEffect(() => {
    const subscriptions = [shownSub, startedShowingSub, startedHidingSub, hiddenSub];

    /* Clean up subscriptions */
    return () => subscriptions.forEach((s) => s.remove());
  }, []);

  return {
    isShown,
    isOpening,
    isClosing,
    isTransitioning,
    navbarHeight,
  };
};
