import gql from 'graphql-tag';
import { WorkType, FilingStatus } from '@types';
import { ApolloOpenEnrollmentDates, OpenEnrollmentFragment } from '../fragments';

export interface HealthExplorerQueryData {
  viewer: {
    id: string;
    user: {
      id: string;
      estimatedW2Income: number;
      estimated1099Income: number;
      spouseIncome: number;
      dob?: string;
      age?: number;
      householdIncome: number;
      workType?: WorkType;
      filingStatus?: FilingStatus;
      legalAddress?: {
        zip?: string;
      };
    };
  };
  viewerTwo: {
    healthExplorerData: {
      id: string;
      pathwayType?: string; // @todo
      zipcode?: string;
      countyfips?: string;
      state?: string;
      coverageYear?: number;
      income?: number;
      possibleCoverageYears?: Array<number>;
      dependents?: Array<{
        id: string;
        age: number;
        relation: string; // @todo;
        aptcEligible: boolean;
        hasMec: boolean;
        isApplying: boolean;
        isParent: boolean;
        isPregnant: boolean;
        isSmoker: boolean;
      }>;
    };
  };
  reference: {
    health: {
      openEnrollmentDates: ApolloOpenEnrollmentDates;
    };
  };
}

export const HealthExplorerQuery = gql`
  query HealthExplorerQuery {
    viewer {
      id
      user {
        id
        estimatedW2Income
        estimated1099Income
        spouseIncome
        workType
        filingStatus
        dob
        age @client
        householdIncome @client
        legalAddress {
          zip
        }
      }
    }
    viewerTwo {
      id
      healthExplorerData {
        id
        pathwayType
        zipcode
        countyfips
        state
        coverageYear
        income
        possibleCoverageYears
        dependents {
          id
          age
          relation
          aptcEligible
          hasMec
          isApplying
          isParent
          isPregnant
          isSmoker
        }
      }
    }
    reference {
      health {
        openEnrollmentDates {
          ...OEDates
        }
      }
    }
  }
  ${OpenEnrollmentFragment}
`;
