import { boolToTri } from '@app/utils';
import { introOptions } from './sepFields';
import { endOfYesterday, isWithinInterval, parseISO, startOfDay } from 'date-fns';

export const getIntroInitialValues = (members = []) => {
  const sep = [];

  introOptions.forEach(({ value }) => {
    if (members.some((m) => m[value])) {
      sep.push(value);
    }
  });

  return { sep };
};

export const getInitialValues = (member) => {
  // CR63 calculations
  const timeframe = { start: startOfDay(new Date("3/31/23")), end: endOfYesterday() }; // from 3/31 to yesterday
  const isUnwindingSEP = member?.isMedicaidEnd && isWithinInterval(parseISO(member?.medicaidEndDate), timeframe);
      
  return {
    ...member,

    // CR63 - computes unwinding sep situations
    isUnwindingSEP,

    // married
    hasCoverage60DaysBeforeMarriage: boolToTri(member?.hasCoverage60DaysBeforeMarriage),
    hasLivedOutsideUSLast60DaysBeforeMarriage: boolToTri(
      member?.hasLivedOutsideUSLast60DaysBeforeMarriage,
    ),

    // relocated
    hasCoverageLast60DaysBeforeRelocation: boolToTri(member?.hasCoverageLast60DaysBeforeRelocation),
    isMovedFromOutsideUs: boolToTri(member?.isMovedFromOutsideUs),
    previousZipcode: {
      zip: member.previousZipcode || '',
      county: member?.previousCountyLivedName || '',
    },
  };
};

export const formatPayload = ({ applicationID, members }) => {
  return {
    variables: {
      input: {
        applicationID,
        applicationMembersInput: members.map((values) => {
          return {
            id: values?.id,

            // lost coverage
            isLostCoverageLast60Days: boolToTri(values?.isLostCoverageLast60Days),
            coverageLostDate: values?.coverageLostDate,
            planName: values?.planName,

            // will lose coverage
            isWillLoseCoverageNext60Days: boolToTri(values?.isWillLoseCoverageNext60Days),
            coverageLostDateFuture: values?.coverageLostDateFuture,

            // married
            isMarriedLast60Days: boolToTri(values?.isMarriedLast60Days),
            whenMarried: values?.whenMarried,
            hasCoverage60DaysBeforeMarriage: values?.hasCoverage60DaysBeforeMarriage,
            hasLivedOutsideUSLast60DaysBeforeMarriage:
              values?.hasLivedOutsideUSLast60DaysBeforeMarriage,

            // lawful presence
            isGainedLawfulPresence: boolToTri(values?.isGainedLawfulPresence),
            whenLawfulPresence: values?.whenLawfulPresence,

            // relocated
            isMoved: boolToTri(values?.isMoved),
            whenRelocated: values?.whenRelocated,
            hasCoverageLast60DaysBeforeRelocation: values?.hasCoverageLast60DaysBeforeRelocation,
            isMovedFromOutsideUs: values?.isMovedFromOutsideUs,
            previousZipcode: values?.previousZipcode?.zip,
            previousCountyLivedName: values?.previousZipcode?.county,

            // incarceration
            isReleasedFromIncarceration: boolToTri(values?.isReleasedFromIncarceration),
            whenReleasedFromIncarceration: values?.whenReleasedFromIncarceration,

            // gained dependent
            isGainDependent: boolToTri(values?.isGainDependent),
            whenBecomeADependent: values?.whenBecomeADependent,
          };
        }),
      },
    },
  };
};
