import { BankDisconnectType, RelinkMode } from '@types';
import {
  ApolloBankLink,
  ApolloBankAccount,
  ApolloPendingAccount,
} from '../../data/queries/BankLinksQuery';

import { truncate, lastFour } from '../format_old/misc';
import { BANK_ERROR_CODES, LINK_MODES } from '../const';

export interface BankAccount {
  id: string;
  bankLinkID?: string;
  isPrimary: boolean;
  isWatched: boolean;
  isPending: boolean;
  isInactive: boolean; // not watched, but not pending
  isDisconnected: boolean;
  disconnectType?: BankDisconnectType;
  relinkMode?: RelinkMode;
  detail: string;
  bankName: string;
  logo?: string;
  numAccounts: number;
}

export interface BankDisconnect {
  id: string; // the bank link id
  bankName: string;
  disconnectType: BankDisconnectType;
  mode?: RelinkMode;
}

export const isBankDisconnected = (link: ApolloBankLink): boolean => {
  return link.syncStatus !== 'GOOD';
};

export const formatBankDetail = (accountName: string, accountNumber: string): string => {
  return `${truncate(accountName, 20)} ${lastFour(accountNumber)}`;
};

export const formatBankAccount = (
  account: ApolloBankAccount,
  link: ApolloBankLink,
): BankAccount => {
  const disconnectType = BANK_ERROR_CODES[link.errorCode];

  return {
    id: account.id,
    bankLinkID: link.id,
    isPrimary: account.isPrimary,
    isWatched: account.isWatched,
    isPending: false,
    isInactive: !account.isWatched,
    isDisconnected: isBankDisconnected(link),
    disconnectType,
    relinkMode: LINK_MODES[disconnectType],
    detail: formatBankDetail(account.accountName, account.accountNumber),
    bankName: link.bankName,
    logo: link.plaidBank?.logo,
    numAccounts: link.accounts.length,
  };
};

export const formatPendingAccount = (pendingAccount: ApolloPendingAccount): BankAccount => {
  return {
    id: pendingAccount.id,
    isPrimary: pendingAccount.isPrimary,
    isWatched: false,
    isPending: true,
    isInactive: false,
    isDisconnected: false,
    detail: formatBankDetail(pendingAccount.accountName, pendingAccount.accountNumber),
    bankName: pendingAccount.bankName,
    numAccounts: 1,
  };
};

export const formatDisconnects = (bankLinks: Array<ApolloBankLink>): Array<BankDisconnect> => {
  const links = bankLinks || [];

  return links
    .filter((bankLink) => isBankDisconnected(bankLink))
    .map((bankLink) => {
      const disconnectType = BANK_ERROR_CODES[bankLink.errorCode];

      return {
        id: bankLink.id,
        bankName: bankLink.bankName,
        disconnectType,
        mode: LINK_MODES[disconnectType],
      };
    });
};

/**
 * Given list of bank links (and optionally, a set of pending accounts),
 * returns a list of standardized, formatted bank accounts
 */
export const formatBankAccounts = (
  bankLinks: Array<ApolloBankLink>,
  pendingAccounts?: Array<ApolloPendingAccount>,
): { active: Array<BankAccount>; pending: Array<BankAccount>; inactive: Array<BankAccount> } => {
  let active: Array<BankAccount> = [];
  let inactive: Array<BankAccount> = [];

  (bankLinks || []).forEach((link) => {
    link.accounts.forEach((account) => {
      const list = account.isWatched ? active : inactive;
      list.push(formatBankAccount(account, link));
    });
  });

  const pending = (pendingAccounts || []).map((pending) => {
    return formatPendingAccount(pending);
  });

  return {
    active,
    pending,
    inactive,
  };
};
