import React from 'react';

import { FormattedMessage } from 'react-intl';

import { Pressable, Text, useTheme, IconSvg } from '@uikit';

import User from '@svg/user.svg';
import Check from '@svg/check.svg';

const PREFIX = 'catch.ede.MemberList';
const COPY = {
  SELF: <FormattedMessage id={`${PREFIX}.SELF`} />,
  SPOUSE: <FormattedMessage id={`${PREFIX}.SPOUSE`} />,
  CHILD: <FormattedMessage id={`${PREFIX}.CHILD`} />,
};

const PersonFlag = ({ name, relation, togglePerson, isValid }) => {
  const { theme } = useTheme();
  return (
    <Pressable handleOnPress={togglePerson} style={[isValid && theme.sageDarkBg]}>
      <IconSvg svgComponent={isValid ? Check : User} color={isValid ? 'primaryText' : 'text'} />
      <Text size="h6" weight="bold" color={isValid ? 'primary' : 'text'}>
        {name || COPY[relation]}
      </Text>
    </Pressable>
  );
};

export default PersonFlag;
