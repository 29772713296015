import React, { useMemo } from 'react';
import { getRemainingWalletTypes, queries, useQuery, WalletQueryData } from '@data';
import { Stack } from '@layouts';
import { SheetBlueprint } from '@blueprints';
import { Asset, SimpleRow } from '@uikit';
import { formatBenefit } from '@app/utils';
import { open, routes, SheetComponentProps } from '@app/navigate';

const AddToWalletSheet: React.FC<SheetComponentProps['ADD_TO_WALLET']> = () => {
  const { data } = useQuery<WalletQueryData>(queries.WALLET);
  const verticals = useMemo(() => getRemainingWalletTypes(data?.viewer?.wallet), [data]);

  return (
    <SheetBlueprint title="Add to Wallet" altRows hasBottomActions>
      <Stack altRows>
        {verticals.map((vertical) => (
          <SimpleRow
            testID={`add-${vertical}`}
            inset
            key={vertical}
            asset={Asset.configureBenefit(vertical)}
            label={formatBenefit.name({ type: vertical })}
            interaction="navigation"
            onPress={() => open(routes.WALLET_ITEM, { item: { vertical } })}
          />
        ))}
      </Stack>
    </SheetBlueprint>
  );
};

export default AddToWalletSheet;
