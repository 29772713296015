// Wrap redux-form-validators
import { date, email, length, numericality, required } from 'redux-form-validators';
import { isFuture } from '@app/utils/time';
import { COPY } from './copy';
import {
  baseRequire,
  common,
  noSpecialChars,
  passwordValidation,
  phoneNumber,
  zipcode,
} from './primitives';
import createValidator from './createValidator';

// Forms

export const updateSSNForm = {
  ssn: common.ssn,
};

export const bankLinkForm = {
  userName: [required({ msg: COPY['baseRequire'] })],
  password: common.required,
};

export const userInfoForm = {
  givenName: common.name,
  familyName: common.name,
  nickname: common.name,
  'legalAddress.street1': common.address1,
  'legalAddress.city': common.required,
  'legalAddress.state': common.required,
  'legalAddress.zip': common.zipcode,
  phoneNumber: common.phoneNumber,
  dob: common.dob,
};

export const signInForm = {
  alias: common.required,
  password: common.required,
};

export const signBackInForm = {
  password: common.required,
};

export const workInfoForm = {
  workState: common.required,
  filingStatus: common.required,
  estimated1099Income: common.required,
  estimatedW2Income: common.required,
  employerName: common.required,
  spouseIncome: common.required,
};

export const workTypeForm = {
  workType: common.required,
};

export const registerForm = {
  password: passwordValidation,
  is18: [baseRequire, (val) => !val],
};

export const updateFilingStatusForm = {
  filingStatus: common.required,
};

export const updateIncomeForm = {
  estimatedIncome: common.required,
};

export const updateSpouseIncomeForm = {
  spouseIncome: common.required,
};

export const bankLinkMFA = { answer: common.required };
export const passwordReset = { email: common.email };
export const customSavingsGoal = { name: common.required };
export const annualIncome = { income: common.required };
export const pto = { target: common.required };
export const genericSavings = {
  target: common.required,
  endDate: [
    (value, values) => {
      if (values.hasTimeFrame === 'yes') {
        return required({ msg: COPY['endDate.required'] })(value);
      }
    },
    (value) => {
      if (!isFuture(value)) {
        return COPY['endDate.isFuture'];
      }
    },
  ],
  monthlyAmount: [
    (value, values) => {
      if (values.hasTimeFrame === 'no') {
        return required({ msg: COPY['monthlyAmount'] })(value);
      }
    },
  ],
};
export const taxGoal = {
  filingStatus: common.required,
  spouseIncome: [
    (value, values) => {
      if (values.filingStatus === 'MARRIED') {
        return common.required;
      }
    },
  ],
};

export const taxInfo = createValidator({
  yearOfLastCompletedFiling: common.required,
  filingName: common.required,
  filingStatus: common.required,
  'addressOfLastCompletedFiling.street1': common.required,
  'addressOfLastCompletedFiling.city': common.required,
  'addressOfLastCompletedFiling.state': common.required,
  'addressOfLastCompletedFiling.zip': common.zipcode,
  confirmationEmailAddress: common.email,
});

export const identityVerification = {
  identificationType: common.required,
  issuingCountry: common.required,
  issuingState: common.required,
  documentNumber: common.required,
  issuedDate: common.date,
  expirationDate: common.date,
};

export const userBeneficiary = {
  givenName: common.name,
  familyName: common.name,
  relationship: common.required,
  dob: common.dob,
  ssn: common.required,
  street1: common.required,
  city: common.required,
  state: common.required,
  zip: common.required,
};

export const confirmationCode = {
  code: [...common.required],
};
export const passwordResetConfirmation = {
  code: common.required,
  newPassword: passwordValidation,
  // @NOTE in case we decide to bring it back?
  // newPasswordConfirmation: [
  //   baseRequire,
  //   matches('newPassword', COPY['passwordConfirmation']),
  // ],
};

export const eddForm = {
  'edd.primaryIncome': common.required,
  'edd.netWorth': common.required,
  'employment.status': common.required,
  'employment.employerName': common.name,
  'employment.employmentLength': common.required,
  'employment.position': common.name,
  'employment.occupation': common.required,
  'employment.address.street1': common.address1,
  'employment.address.city': common.required,
  'employment.address.state': common.required,
  'employment.address.zip': common.zipcode,
};

export const eddDeposits = {
  'edd.initialDepositAmount': common.required,
  'edd.initialDepositInstitution': common.required,
};

export const changePassword = {
  oldPassword: common.required,
  newPassword: passwordValidation,
};

export const changeEmail = {
  alias: common.email,
};

export const changePhone = {
  alias: common.phoneNumber,
};

export const trustedContact = {
  tcName: common.required,
  tcEmail: common.email,
};

export const householdIncomeForm = {
  totalHouseholdIncome: [baseRequire, numericality({ '>': 0 })],
};

export const householdPeopleForm = {
  totalPeopleHousehold: [baseRequire, numericality({ '>': 0 })],
};

export const userContact = {
  givenName: common.name,
  familyName: common.name,
  relation: common.required,
  email: [email({ msg: COPY['email'] })],
};

export const addTaxDependent = {
  givenName: common.name,
  familyName: common.name,
  relation: common.required,
};

export const doctorInput = {
  name: common.required,
  type: common.required,
  phoneNumber: [phoneNumber],
  'address.zipcode': [zipcode],
};

export const providerInput = {
  name: common.required,
  phoneNumber: [phoneNumber],
  'address.zipcode': [zipcode],
};

export const idDocumentInput = {
  type: common.required,
  issuingCountry: common.required,
  // Only require issuing state if the document is a
  // DRIVERS_LICENSE or STATE_ID
  issuingState: [
    required({
      msg: COPY['baseRequire'],
      if: (values) => /DRIVERS|STATE/.test(values?.type),
    }),
  ],
  expirationDate: [
    required({
      msg: COPY['baseRequire'],
      if: (values) => !/SSN_CARD/.test(values?.type),
    }),
    date({
      format: 'mm/dd/yyyy',
      '>': 'today',
      msg: {
        // @ts-ignore - Converted this file to TS and not sure why this line is complaining
        '>': 'Document cannot be expired',
      },
      if: (values) => !/SSN_CARD/.test(values?.type),
    }),
  ],
  issuedDate: [
    required({
      msg: COPY['baseRequire'],
      if: (values) => !/SSN_CARD/.test(values?.type),
    }),
    date({
      format: 'mm/dd/yyyy',
      '<': 'today',
      msg: {
        // @ts-ignore - Converted this file to TS and not sure why this line is complaining
        '<': 'Document cannot be issued in the future',
      },
      if: (values) => !/SSN_CARD/.test(values?.type),
    }),
  ],
  //Document number validation is based off bbva requirements
  documentNumber: [
    required({
      msg: COPY['baseRequire'],
      if: (values) => !/SSN/.test(values?.type),
    }),
    length({ min: 6, if: (values) => !/SSN/.test(values?.type) }),
    noSpecialChars,
  ],
  upload: common.required,
};

export const regulatoryForm = {
  isControlPerson: common.required,
  isFirmAffiliated: common.required,
  isPoliticallyExposed: common.required,
};

export const walletForm = {
  carrier: common.required,
};
