import gql from 'graphql-tag';
import {
  HealthApplicationContext,
  HealthApplicationStatus,
  HealthPathwayType,
  PolicyStatusType,
  SupportedPathway,
} from '@types';
import {
  ApolloOpenEnrollmentDates,
  ApolloWalletItem,
  OpenEnrollmentFragment,
  WalletItemFragment,
} from '../fragments';

export interface CoverageQueryData {
  viewer: {
    id: string;
    user: {
      id: string;
      supportedPathways?: Array<SupportedPathway>;
    };
    wallet?: Array<ApolloWalletItem>;
  };
  viewerTwo: {
    id: string;
    health: {
      applications: Array<{
        id: string;
        applicationContext: HealthApplicationContext;
        lastUsedRoute: string;
        status: HealthApplicationStatus;
        coverageYearNumber: number;
        coverageState: string;
        pathwayType: HealthPathwayType;
        isGeorgiaAccess: boolean;
        progress: boolean;
        lastUserUpdate: Date;
        enrollment: {
          id: string;
        };
      }>;
      policies: Array<{
        id: string;
        startDate?: string;
        endDate?: string;
        coverageYear: number;
        planName: string;
        carrier: string;
        premium: number;
        premiumEffective: number;
        policyStatus: PolicyStatusType;
        enrollmentID: string;
        productType: 'PLANTYPE_DENTAL' | 'PLANTYPE_HEALTH';
        providerPlan: {
          id: string;
          metalLevel: string;
          issuer: {
            id: string;
            name: string;
          };
        };
      }>;
    };
  };
  reference: {
    health: {
      openEnrollmentDates: ApolloOpenEnrollmentDates;
    };
  };
}

// @todo always use current year + next year
export const CoverageQuery = gql`
  query CoverageQuery($coverageYears: [Int!]) {
    viewer {
      id
      user {
        id
        supportedPathways
      }
      wallet {
        id
        ...WalletItem
      }
    }
    viewerTwo {
      id
      health {
        applications(
          input: {
            coverageYearNumber: $coverageYears
            statuses: [IN_PROGRESS, SUBMITTED, DRAFT, PENDING_ENROLLMENT, ENROLLMENT_STUCK]
          }
        ) {
          id
          applicationContext
          lastUsedRoute
          progress @client
          status
          coverageYearNumber
          coverageState
          pathwayType
          isGeorgiaAccess @client
          lastUserUpdate
          enrollment {
            id
          }
        }
        policies(input: { coverageYearNumber: $coverageYears }) {
          id
          startDate
          endDate
          dueDate @client
          coverageYear @client
          planName
          carrier
          premium
          premiumEffective
          policyStatus
          enrollmentID
          productType
          providerPlan {
            id
            metalLevel
            issuer {
              id
              name
            }
          }
        }
      }
      healthExplorerData {
        id
        state
        pathwayType
      }
    }
    reference {
      health {
        openEnrollmentDates {
          ...OEDates
        }
      }
    }
  }
  ${OpenEnrollmentFragment}
  ${WalletItemFragment}
`;
