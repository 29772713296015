import React from 'react';

import { useCopy } from '@util';
import { Text, Button } from '@uikit';
import { Section, Toolbar, BasicLayout } from '@layouts';
import { routes } from '@navigate';

import { accountTypes } from '../pages/plan/shared/accountTypes';
import AccountLimits from '../pages/plan/shared/AccountLimits';

/**
 *
 */
export const RetirementAccountTypeSheet = ({ type, onSubmit }) => {
  const { c } = useCopy('catch.module.retirement.AccountSelectionView');

  if (!type) return null;

  return (
    <BasicLayout
      title={accountTypes[type]?.label}
      subtitles={[accountTypes[type]?.description]}
      toolbar={
        <Toolbar>
          <Button testID="submit" inherit onPress={onSubmit}>
            Choose
          </Button>
        </Toolbar>
      }
    >
      <Section title="Why this account type?">
        <Text>{c(`${type}.recommendation.base`)}</Text>
      </Section>
      <Section title={'Limits'}>
        <AccountLimits selectedAccountType={type} />
      </Section>
    </BasicLayout>
  );
};

export const RetirementAccountTypeView = {
  name: routes.RETIREMENT_ACCOUNT_TYPE,
  component: RetirementAccountTypeSheet,
  options: {},
};

export default RetirementAccountTypeSheet;
