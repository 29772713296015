import React from 'react';
import { FormLabel } from '@uikit';
import { PickerField } from '@app/forms_old/inputs';
import { Option } from '@types';
import { InputProps } from '../types';

interface DropdownInputProps<T> extends InputProps<T> {
  options: Array<Option>;
}

const DropdownInput = React.forwardRef(
  <T extends any>(
    {
      testID,
      autoFocus,
      name,
      value,
      label,
      sublabel,
      help,
      optional,
      disabled,
      onChange,
      onBlur,
      onKeyPress,
      error,
      options,
    }: DropdownInputProps<T>,
    ref,
  ) => {
    return (
      <>
        <FormLabel
          testID={testID}
          label={label}
          sublabel={sublabel}
          help={help}
          optional={optional}
        />
        <PickerField
          ref={ref}
          testID={testID}
          name={name}
          value={value}
          options={options}
          autoFocus={autoFocus}
          disabled={disabled}
          onChange={(val) => {
            onChange(val);
            onBlur(); // we blur right away to trigger validation on change
          }}
          onBlur={onBlur}
          onKeyPress={onKeyPress}
          error={error}
          fullWidth
        />
      </>
    );
  },
);

DropdownInput.displayName = 'DropdownInput';
export default DropdownInput;
