import { KeyboardSet } from '@types';

/**
 * @author @aa-io
 *
 * @todo
 * whoever tackles forms might want to consolidate the config option pairs since they rarely deviate. Depends on how the types will be used
 *
 * info filled = AutoCompleteType + TextContentType
 * keyboard used = InputMode +  KeyboardType
 * */

export type NativeAutoCompleteType =
  | 'off'
  | 'username'
  | 'password'
  | 'email'
  | 'name'
  | 'tel'
  | 'street-address'
  | 'postal-code'
  | 'cc-number'
  | 'cc-csc'
  | 'cc-exp'
  | 'cc-exp-month'
  | 'cc-exp-year';

export type WebAutoCompleteType =
  | 'honorific-prefix'
  | 'given-name'
  | 'additional-name'
  | 'additional-name-initial'
  | 'family-name'
  | 'honorific-suffix'
  | 'nickname'
  | 'address-line1'
  | 'address-line2'
  | 'address-line3'
  | 'locality'
  | 'city'
  | 'administrative-area'
  | 'state'
  | 'province'
  | 'region'
  | 'country-name'
  | 'tel-country-code'
  | 'tel-national'
  | 'tel-area-code'
  | 'tel-local'
  | 'tel-local-prefix'
  | 'tel-local-suffix'
  | 'tel-extension'
  | 'fax'
  | 'fax-country-code'
  | 'fax-national'
  | 'fax-area-code'
  | 'fax-local'
  | 'fax-local-prefix'
  | 'fax-local-suffix'
  | 'fax-extension'
  | 'cc-name'
  | 'cc-given-name'
  | 'cc-additional-name'
  | 'cc-family-name'
  | 'language'
  | 'bday'
  | 'bday-year'
  | 'bday-month'
  | 'bday-day'
  | 'org'
  | 'organization-title'
  | 'sex'
  | 'gender-identity'
  | 'url'
  | 'photo';

export type AutoCompleteType = WebAutoCompleteType | NativeAutoCompleteType;

export type TextContentType =
  | 'URL'
  | 'addressCity'
  | 'addressCityAndState'
  | 'addressState'
  | 'countryName'
  | 'emailAddress'
  | 'familyName'
  | 'fullStreetAddress'
  | 'givenName'
  | 'jobTitle'
  | 'location'
  | 'middleName'
  | 'name'
  | 'namePrefix'
  | 'nameSuffix'
  | 'nickname'
  | 'organizationName'
  | 'postalCode'
  | 'streetAddressLine1'
  | 'streetAddressLine2'
  | 'sublocality'
  | 'telephoneNumber'
  | 'username'
  | 'password'
  | 'newPassword'
  | 'oneTimeCode';

export type KeyboardType =
  | 'default'
  | 'decimal'
  | 'numeric'
  | 'tel'
  | 'search'
  | 'email'
  | 'url'
  | 'phone-pad'
  | 'email-address';

export type InputMode =
  | 'default'
  | 'number-pad'
  | 'tel'
  | 'decimal-pad'
  | 'numeric'
  | 'email-address'
  | 'phone-pad';

export interface KeyboardConfig {
  keyboardType?: KeyboardType;
  inputMode?: InputMode;
  autoCompleteType?: AutoCompleteType;
  autoComplete?: AutoCompleteType;
  textContentType?: TextContentType;
  autoCorrect?: Boolean;
  autoFill?: Boolean;
}

export const keyboards: Record<KeyboardSet, KeyboardConfig> = {
  phoneAlias: {
    keyboardType: 'phone-pad',
    inputMode: 'phone-pad',
    autoCompleteType: 'tel',
    textContentType: 'telephoneNumber',
  },
  emailAlias: {
    keyboardType: 'email-address',
    inputMode: 'email-address',
    autoCompleteType: 'email',
    textContentType: 'emailAddress',
  },
  alias: {
    keyboardType: 'email-address',
    inputMode: 'email-address',
    autoCompleteType: 'username',
    textContentType: 'username',
  },
  existingPassword: {
    autoCompleteType: 'password',
    textContentType: 'password',
  },
  newPassword: {
    autoCompleteType: 'password',
    textContentType: 'newPassword',
  },
  email: {
    keyboardType: 'email-address',
    inputMode: 'email-address',
    autoCompleteType: 'email',
    textContentType: 'emailAddress',
  },
  preferredName: {
    keyboardType: 'default',
    inputMode: 'default',
    autoCompleteType: 'nickname',
    textContentType: 'nickname',
  },
  firstName: {
    keyboardType: 'default',
    inputMode: 'default',
    autoCompleteType: 'given-name',
    textContentType: 'givenName',
  },
  middleName: {
    keyboardType: 'default',
    inputMode: 'default',
    autoCompleteType: 'additional-name',
    textContentType: 'middleName',
  },
  lastName: {
    keyboardType: 'default',
    inputMode: 'default',
    autoCompleteType: 'family-name',
    textContentType: 'familyName',
  },
  prefix: {
    keyboardType: 'default',
    inputMode: 'default',
    autoCompleteType: 'honorific-prefix',
    textContentType: 'namePrefix',
  },
  suffix: {
    keyboardType: 'default',
    inputMode: 'default',
    autoCompleteType: 'honorific-suffix',
    textContentType: 'nameSuffix',
  },
  occupation: {
    keyboardType: 'default',
    inputMode: 'default',
    autoCompleteType: 'organization-title',
    textContentType: 'jobTitle',
  },
  address: {
    keyboardType: 'default',
    inputMode: 'default',
    autoCompleteType: 'address-line1',
    textContentType: 'streetAddressLine1',
  },
  addressLine1: {
    keyboardType: 'default',
    inputMode: 'default',
    autoCompleteType: 'street-address',
    textContentType: 'streetAddressLine1',
  },
  addressLine2: {
    keyboardType: 'default',
    inputMode: 'default',
    autoCompleteType: 'address-line2',
    textContentType: 'streetAddressLine2',
  },
  city: {
    keyboardType: 'default',
    inputMode: 'default',
    autoCompleteType: 'city',
    textContentType: 'addressCity',
  },
  state: {
    keyboardType: 'default',
    inputMode: 'default',
    autoCompleteType: 'state',
    textContentType: 'addressState',
  },
  zip: {
    keyboardType: 'numeric',
    inputMode: 'number-pad',
    autoCompleteType: 'postal-code',
    textContentType: 'postalCode',
  },
  phone: {
    keyboardType: 'phone-pad',
    inputMode: 'phone-pad',
    autoCompleteType: 'tel',
    textContentType: 'telephoneNumber',
  },
  dob: {
    keyboardType: 'numeric',
    inputMode: 'number-pad',
    autoCompleteType: 'bday',
  },
  date: { keyboardType: 'numeric', inputMode: 'number-pad' },
  ssn: { keyboardType: 'numeric', inputMode: 'number-pad' },
  jobTitle: {
    keyboardType: 'default',
    inputMode: 'default',
    autoCompleteType: 'organization-title',
    textContentType: 'jobTitle',
  },
  money: { keyboardType: 'numeric', inputMode: 'number-pad' },
  number: { keyboardType: 'numeric', inputMode: 'number-pad' },
  code: {
    keyboardType: 'numeric',
    inputMode: 'number-pad',
    textContentType: 'oneTimeCode',
  },
};

export const getKeyboardConfig = (keyboard: KeyboardSet) => {
  return keyboards[keyboard];
};
