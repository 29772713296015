import gql from 'graphql-tag';
import { PaycheckWorkType, IncomeAction } from '@types';

export interface UpdateIncomeTransactionVars {
  input: {
    transactionID: string;
    status: IncomeAction;
    expenses?: number;
    paycheckType?: PaycheckWorkType;
    goalBreakdownChanges?: Array<{
      incomeTransactionGoalBreakdownID: string;
      percentageAmount: number;
      isSkipped: boolean;
    }>;
  };
}

export interface UpdateIncomeTransactionResponse {
  updateIncomeTransaction: {
    id: string;
    status: IncomeAction;
    expenses?: number;
    goalBreakdowns: Array<{
      id: string;
      status: IncomeAction;
      amount: number;
      percentage: number;
    }>;
  };
}

export const UpdateIncomeTransaction = gql`
  mutation UpdateIncomeTransaction($input: UpdateIncomeTransactionInput!) {
    updateIncomeTransaction(input: $input) {
      id
      status
      expenses
      goalBreakdowns {
        id
        status
        amount
        percentage
      }
    }
  }
`;
