import React, { useState } from 'react';

import { useCopy, launchPlaid } from '@app/utils';
import { routes, ScreenDefinition } from '@navigate';
import { Button } from '@uikit';
import { CalloutLayout, Toolbar } from '@layouts';

const AddBankLink = () => {
  const [loading, setLoading] = useState(false);
  /* @ts-ignore */
  const { c: basics } = useCopy('catch.basics');

  return (
    <CalloutLayout
      loading={false}
      render="link"
      title={basics('linkYourBank')}
      subtitles={[basics('linkYourBankDescription')]}
      toolbar={
        <Toolbar>
          <Button
            testID="link-new-bank"
            full
            accentColor="brand"
            loading={loading}
            onPress={() =>
              launchPlaid({
                mode: 'create',
                next: {
                  route: routes.ACCOUNT_SETUP_INTRO,
                },
                setLoading,
              })
            }
          >
            {basics('chooseBank')}
          </Button>
        </Toolbar>
      }
    />
  );
};

export const AddBankLinkView: ScreenDefinition = {
  name: routes.ADD_BANK_LINK,
  component: AddBankLink,
  options: {},
};
