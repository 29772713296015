import React from 'react';

import { FlowLayout, Layout } from '@layouts';
import { SimpleRow } from '@uikit/components/Row';
import { routes } from '@navigate';
import { useCopy } from '@app/utils';

import UserSvg from '@uikit/icons/svg/user.svg';
import CalendarSvg from '@uikit/icons/svg/calendar.svg';
import HomeSvg from '@uikit/icons/svg/home.svg';
import WallpaperSvg from '@uikit/icons/svg/wallpaper.svg';
import ContractSvg from '@uikit/icons/svg/contract.svg';
import LockSvg from '@uikit/icons/svg/lock.svg';

export const AccountSetupIntro = ({ handleNext }) => {
  const { c: legal } = useCopy('catch.plans.PlanLegalView');
  const { c: user } = useCopy('catch.user');
  const { c: util } = useCopy('catch.util');

  return (
    <FlowLayout
      narrow
      onNext={handleNext}
      title={legal('title')}
      subtitle={legal('subtitle')}
      disclaimer={
        <Layout center margins>
          <SimpleRow
            fp
            textColor="subtler"
            asset={{ svg: LockSvg, color: 'subtler', size: 'xs' }}
            label={legal('footer')}
          />
        </Layout>
      }
    >
      <Layout bottomSpace>
        <SimpleRow asset={{ svg: UserSvg }} label={user('LegalNameField.label')} />
        <SimpleRow asset={{ svg: CalendarSvg }} label={user('DobField.label')} />
        <SimpleRow asset={{ svg: HomeSvg }} label={user('LegalAddressField.label')} />
        <SimpleRow asset={{ svg: WallpaperSvg }} label={user('SSNField.label')} />
        <SimpleRow asset={{ svg: ContractSvg }} label={util('LegalInfoModal.sectionTitle')} />
      </Layout>
    </FlowLayout>
  );
};

export const AccountSetupIntroView = {
  name: routes.ACCOUNT_SETUP_INTRO,
  component: AccountSetupIntro,
  options: {},
};
