import { useQuery, queries, GoalSetupQueryData } from '@data';

/**
 * useGoalSetup hook
 * helps route user to relevant screen while setting up a goal
 */
export const useGoalSetup = () => {
  const { loading, data } = useQuery<GoalSetupQueryData>(queries.GOAL_SETUP, {
    fetchPolicy: 'cache-first',
  });

  const user = data?.viewer?.user;
  const workType = user?.workType;
  const isW2 = workType === 'WORK_TYPE_W2';

  //check if payroll data has already been filled out in onboarding or not
  //for 1099, we actually only need to check if filing status was filled out since it's the last part before estimator
  //for W2, check if the work state was filled in
  const isPayrollSetup = isW2 ? !!user?.workState : !!user?.filingStatus;

  //check if account setup was done
  //again we check the last part of account setup which is SSN
  const isAccountSetup = !!user?.ssn;

  return {
    loading,
    ...user,
    isW2,
    isPayrollSetup,
    isAccountSetup,
  };
};
