import React, { useCallback, useMemo } from 'react';
import {
  useDeprecatedMutation,
  useDeprecatedQuery,
  useQuery,
  queries,
  HealthApplicationQueryData,
} from '@data';
import ApplicationImportForm from '@forms/ApplicationImportForm';
import { goToHealthApp, handleHealthErrors } from '@app/utils';
import { SplitFormBlueprint } from '@app/blueprints';
import { navigate, routes } from '@app/navigate';

const ApplicationImport = ({ applicationID }) => {
  const { data } = useQuery<HealthApplicationQueryData>(queries.HEALTH_APPLICATION, {
    variables: { applicationID },
    skip: !applicationID,
  });

  const application = data?.viewerTwo?.health?.application;

  const { coverageYear, coverageState } = useMemo(() => {
    return {
      coverageYear: application?.coverageYearNumber,
      coverageState: application?.coverageState,
    };
  }, [application]);

  const { loading, applications } = useDeprecatedQuery('previousApplications', {
    skip: !coverageYear,
    variables: {
      input: {
        coverageYear,
        state: coverageState,
      },
    },
  });

  const [importApplication, { loading: importing }] = useDeprecatedMutation('importApplication', {
    onCompleted: ({ importExistingApplication }) => {
      goToHealthApp({ ...importExistingApplication, lastUsedRoute: 'confirm-applicants' });
    },
    ...handleHealthErrors,
  });

  const handleSubmit = useCallback(
    (values) => {
      const { isWindowShopping, oeCoverageYear } =
        data?.reference?.health?.openEnrollmentDates || {};
      const appCoverageYear = application?.coverageYearNumber;
      const previousApp = applications?.find((app) => app.id === values.applicationID);

      // when applying for OE year, but it's still window shopping period
      if (appCoverageYear === oeCoverageYear && isWindowShopping) {
        navigate(routes.EDE_WINDOW_SHOPPING);
      } else {
        importApplication({
          refetchQueries: ['PreviousApplications'],
          variables: {
            id: values.applicationID,
            isPriorYear: appCoverageYear > previousApp?.coverageYear,
            enrollmentId: application?.enrollment?.id,
            coverageYear: appCoverageYear,
          },
        });
      }
    },
    [importApplication, data],
  );

  return (
    <ApplicationImportForm
      onSubmit={handleSubmit}
      applications={applications}
      submitting={importing}
      initialValues={{ applicationID: applications?.[0]?.id }}
      loading={loading}
    />
  );
};

export const ApplicationImportView = {
  name: 'EDE_APPLICATION_IMPORT',
  component: ApplicationImport,
  options: {
    ...SplitFormBlueprint.options,
    title: 'Application',
  },
};
