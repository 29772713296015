import React from 'react';
import { View, StyleSheet } from 'react-native';

import { Layout, Page, Media, Container, Platform } from '@layouts';
import { useResponsive, s, useTheme } from '@uikit';

import ProfileMenu from '../sections/ProfileMenu';

const SettingsLayout = ({ title, children }) => {
  const { res } = useResponsive(responsiveMap);
  const { theme, showLayout } = useTheme();
  return (
    <Page>
      <Container noMargins>
        <View style={[res.container, s?.transitionBg, showLayout && theme._overlay]}>
          {Platform.OS === 'web' && (
            <Media desktop>
              <View>
                <ProfileMenu />
              </View>
            </Media>
          )}
          <Layout.Scroll>
            <Layout topSpace={Platform.OS === 'web'} bottomSpace={Platform.OS === 'web'} margins>
              {!!title && Platform.OS === 'web' && (
                <Layout.Header topSpace titleSize="form" title={title} />
              )}
              {children}
            </Layout>
          </Layout.Scroll>
        </View>
      </Container>
    </Page>
  );
};

const responsiveMap = {
  container: StyleSheet.create({
    default: {
      width: '100%',
      flex: 1,
      height: '100%',
    },
    desktop: {
      flexDirection: 'row',
    },
  }),
};

export default SettingsLayout;
