import gql from 'graphql-tag';

export interface DeletePendingAccountVars {
  accountID: string;
}

export interface DeletePendingAccountResponse {
  deletePendingAccount: {
    id: string;
  };
}

export const DeletePendingAccount = gql`
  mutation DeletePendingAccount($accountID: ID!) {
    deletePendingAccount(id: $accountID) {
      id
    }
  }
`;
