import gql from 'graphql-tag';

export const TEXT_NOTIFICATIONS = gql`
  query textNotifications {
    viewer {
      id
      notificationSettings {
        pushTokens {
          id
          token
          deviceType
        }
        text {
          healthInsurance
        }
      }
    }
  }
`;

const formatter = (data) => {
  return {
    notificationSettings: data?.viewer?.notificationSettings,
    textNotificationSettings: data?.viewer?.notificationSettings?.text?.healthInsurance,
  };
};

export default {
  document: TEXT_NOTIFICATIONS,
  formatter,
};
