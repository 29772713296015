import React from 'react';
import { KeyboardSet } from '@types';
import { FormLabel } from '@uikit';
import { TextField } from '@app/forms_old/inputs';
import { InputProps } from '../types';

interface TextInputProps<T> extends InputProps<T> {
  placeholder: string;
  keyboard?: KeyboardSet;
  format: (v: string) => string | null;
  characters?: RegExp; // when we only want to allow certain characters
  length?: number;
}

export const parse = (val, { characters, length } = {}) => {
  const text = characters ? val?.replace(characters, '') : val; // strip down to
  return length ? text?.substring(0, length) : text;
};

const TextInput = React.forwardRef(
  <T extends any>(
    {
      testID,
      autoFocus,
      name,
      keyboard,
      value,
      label,
      sublabel,
      optional,
      placeholder,
      disabled,
      onChange,
      onFocus,
      onBlur,
      onKeyPress,
      onSubmitEditing,
      error,
      help,
      format,
      characters,
      length,
    }: TextInputProps<T>,
    ref,
  ) => {
    return (
      <>
        <FormLabel
          testID={testID}
          label={label}
          sublabel={sublabel}
          help={help}
          optional={optional}
        />
        <TextField
          ref={ref}
          testID={testID}
          autoFocus={autoFocus}
          keyboard={keyboard}
          value={value}
          onChangeText={(val) => onChange(parse(val, { characters, length }))}
          onFocus={onFocus}
          disabled={disabled}
          onBlur={onBlur}
          onKeyPress={onKeyPress}
          onSubmitEditing={onSubmitEditing}
          placeholder={placeholder}
          error={error}
          format={format}
          fullWidth
        />
      </>
    );
  },
);

TextInput.displayName = 'TextInput';
export default TextInput;
