import React, { useRef } from 'react';
import { MultipleFieldErrors } from 'react-hook-form';
import { View } from 'react-native';
import { Text, FormLabel } from '@uikit';
import { PickerField, TextField } from '@app/forms_old/inputs';
import { stateItems } from '@app/utils'; // should be moved to a config/global
import { InputProps } from '../types';

interface AddressInputProps<T> extends InputProps<T> {
  errors?: MultipleFieldErrors;
  disabledFields?: {
    street1?: boolean;
    street2?: boolean;
    city?: boolean;
    state?: boolean;
    zip?: boolean;
  };
}

const parseZip = (val: string): string => {
  return val?.replace(/\D+/g, '').slice(0, 5);
};

const AddressInput = React.forwardRef(
  <T extends any>(
    {
      testID,
      autoFocus,
      name,
      value, // to allow for easy on change
      label,
      sublabel,
      help,
      optional,
      disabled,
      disabledFields,
      onChange,
      onBlur,
      onKeyPress,
      onSubmitEditing,
      error,
      errors,
    }: AddressInputProps<T>,
    ref,
  ) => {
    const inputRefs = useRef([]);

    const focusInput = (name) => {
      if (inputRefs.current[name]) {
        inputRefs.current[name].focus();
      }
    };

    return (
      <>
        <FormLabel label={label} sublabel={sublabel} help={help} optional={optional} />
        <TextField
          ref={ref}
          testID={`${testID}-street1`}
          disabled={disabled || disabledFields?.street1}
          autoFocus={autoFocus}
          keyboard="addressLine1"
          value={value?.street1}
          onChangeText={(val) => onChange({ ...value, street1: val })}
          onKeyPress={onKeyPress}
          placeholder="Street address"
          fullWidth
          error={!!errors?.address1}
          onSubmitEditing={() => focusInput('street2')}
          grouped
        />
        <TextField
          ref={(el) => (inputRefs.current['street2'] = el)}
          testID={`${testID}-street2`}
          disabled={disabled || disabledFields?.street2}
          keyboard="addressLine2"
          value={value?.street2}
          onChangeText={(val) => onChange({ ...value, street2: val })}
          onKeyPress={onKeyPress}
          onSubmitEditing={() => focusInput('city')}
          placeholder="Apartment, suite, building #"
          fullWidth
          error={!!errors?.street}
          grouped
        />
        <TextField
          ref={(el) => (inputRefs.current['city'] = el)}
          testID={`${testID}-city`}
          disabled={disabled || disabledFields?.city}
          keyboard="city"
          value={value?.city}
          onChangeText={(val) => onChange({ ...value, city: val })}
          onSubmitEditing={() => focusInput('state')}
          onKeyPress={onKeyPress}
          placeholder="City"
          fullWidth
          error={!!errors?.city}
          grouped
        />
        <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <View style={{ flex: 3, marginRight: 8 }}>
            <PickerField
              ref={(el) => (inputRefs.current['state'] = el)}
              testID={`${testID}-state`}
              disabled={disabled || disabledFields?.state}
              keyboard="state"
              name={name}
              value={value?.state}
              options={stateItems}
              placeholder="State"
              onChange={(state) => onChange({ ...value, state })}
              onSubmitEditing={() => focusInput('zip')}
              fullWidth
              error={!!errors?.state}
              grouped
            />
          </View>
          <View style={{ flex: 2, marginLeft: 8 }}>
            <TextField
              ref={(el) => (inputRefs.current['zip'] = el)}
              testID={`${testID}-zip`}
              disabled={disabled || disabledFields?.zip}
              keyboard="zip"
              value={value?.zip}
              onChangeText={(val) => onChange({ ...value, zip: parseZip(val) })}
              onKeyPress={onKeyPress}
              onBlur={onBlur}
              onSubmitEditing={onSubmitEditing}
              placeholder="Zip code"
              fullWidth
              error={!!errors?.zip}
              grouped
            />
          </View>
        </View>
        {error && (
          <Text color="error" size="fp">
            {error}
          </Text>
        )}
      </>
    );
  },
);

AddressInput.displayName = 'AddressInput';
export default AddressInput;
