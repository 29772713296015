import gql from 'graphql-tag';
import { events } from '../fragments';

const MAKE_TAX_PAYMENT = gql`
  mutation MakeTaxPayment($payment: MakeTaxPaymentInput!) {
    makeTaxPayment(input: $payment) {
      paymentEvent {
        id
        ...PaymentEvent
      }
      error
    }
    # upsertNudges(input: $nudges) {
    #   nudgeIdentifier
    #   isDismissed
    # }
  }
  ${events.paymentEvent}
`;

export default {
  document: MAKE_TAX_PAYMENT,
  refetchQueries: ['TransferGoals', 'Activity'],
  getErrors: (data) => [data?.makeTaxPayment?.error],
};
