import React from 'react';
import { KeyboardAvoidingView, StyleSheet } from 'react-native';
import { Copy } from '@types';
import { Gradient, Loading, UIGradientName, useResponsive } from '@uikit';
import { Layout } from './Layout';
import { ToolbarProps } from './Toolbar';
import Page from './Page';
import LayoutContext, { useLayoutContext } from './LayoutContext';
import { theme } from '@app/_ui-kit/themes/catch';

interface BasicLayoutProps {
  showLoaderOnly?: boolean; // hides the header content while loading
  title: Copy;
  titleSize?: string;
  subtitleSize?: string;
  subtitles?: Array<Copy>;
  loading?: boolean;
  centered?: boolean;
  toolbar: React.ReactElement<ToolbarProps>;
  fixAndroidMarginBehindNav?: boolean;
  children: any;
  icon?: {
    svg: React.ReactElement;
    gradient?: UIGradientName;
  };
  style?: any;
}

/**
 *
 * @todo abstract the "readable color" logic from Button.tsx
 * and use it here for the icon stroke color
 */
const BasicLayout: React.FC<BasicLayoutProps> = ({
  showLoaderOnly,
  loading,
  title,
  subtitles,
  centered,
  toolbar,
  children,
  icon,
  fixAndroidMarginBehindNav,
  style,
}) => {
  const { isAndroid, res, isDesktop, shortRange } = useResponsive(styles);
  const { accentColor, layout } = useLayoutContext();

  const hideHeader = showLoaderOnly && loading;

  return (
    <KeyboardAvoidingView
      behavior="padding"
      style={{ flex: 1 }}
      keyboardVerticalOffset={isAndroid ? 0 : 0}
    >
      <Gradient
        gradient={layout !== 'page' ? undefined : `${accentColor}Light`}
        style={{ flex: 1 }}
      >
        <Gradient
          color={layout === 'page' ? 'page' : 'sheet'}
          color2={layout === 'page' ? 'pageTransparent' : 'sheetTransparent'}
          style={{ flex: 1 }}
        >
          <Page fixAndroidMarginBehindNav={fixAndroidMarginBehindNav}>
            <Layout.Scroll center fullWidth bottomSpace topSpace>
              <Layout
                margins={layout === 'sheet'}
                color={
                  layout === 'sheet' ? 'sheet' : shortRange === 'l' && layout === 'page' && 'card'
                }
                topSpace
                bottomSpace
                style={[
                  layout !== 'sheet' && res.webContainer,
                  layout !== 'sheet' && res.webContainerShadow,
                  style,
                ]}
              >
                <Layout style={[isDesktop && layout !== 'sheet' && res.webContainerInner]}>
                  {!!title && !hideHeader && (
                    <Layout.Header
                      light
                      title={title}
                      subtitles={subtitles}
                      rightSpace={false}
                      titleSize="form"
                      bottomSpace
                      align={centered ? 'center' : undefined}
                      asset={
                        !!icon && {
                          ...icon,
                          shape: 'circle',
                          size: 'md',
                          color: 'white',
                          containerSize: 'xl',
                        }
                      }
                    />
                  )}
                  {loading ? <Loading /> : <>{children}</>}
                </Layout>
                {!!isDesktop && layout === 'page' && !!toolbar && (
                  <LayoutContext context="sheet" accentColor={accentColor}>
                    <Layout style={[res.toolbarOffset]}>{toolbar}</Layout>
                  </LayoutContext>
                )}
              </Layout>
            </Layout.Scroll>
            <LayoutContext context="sheet" accentColor={accentColor}>
              {(!isDesktop || layout !== 'page') && !!toolbar && toolbar}
            </LayoutContext>
          </Page>
        </Gradient>
      </Gradient>
    </KeyboardAvoidingView>
  );
};

/**
 * this is styled to match the pxLayout of website
 * Certainly not the best implementation
 */
const styles = {
  webContainer: StyleSheet.create({
    desktop: {
      padding: theme.spacing.desktop.margins, // 64
      maxWidth: theme.containers.pageSheet, // 800
      marginHorizontal: 'auto',
      borderRadius: theme.spacing.desktop.margins, // 24
      overflow: 'hidden',
    },
    default: {
      padding: theme.spacing.desktop.margins, // 64
      marginHorizontal: 'auto',
    },
  }),
  webContainerShadow: StyleSheet.create({
    desktop: {
      boxShadow: '0 25px 100px -10px rgb(0 0 0 / 5%)',
    },
    default: {},
  }),
  webContainerInner: StyleSheet.create({
    desktop: {
      padding: theme.spacing.desktop.margins, // 64
    },
  }),
  toolbarOffset: StyleSheet.create({
    desktop: { marginTop: 100 },
  }),
};

export default BasicLayout;
