import React from 'react';
import { View } from 'react-native';

import { ComplexRow, Text, useTheme } from '@uikit';
import { safeFormatDate, toDate, useCopy } from '@app/utils';
import { PersonRow } from '@app/_common';
import { Stack } from '@app/layouts';

/**
 * remove all the manual spacing and combine with SuccessDMITodos
 */
const SuccessSVITodos = ({ sviTodos }) => {
  const { theme } = useTheme();
  const { c } = useCopy('catch.ede.Success');
  const { c: c1 } = useCopy('catch.ede.DMI');

  return (
    <Stack spacing="+gap">
      {sviTodos?.map((sviTodo) =>
        sviTodo?.members.map((m, i) => (
          <View key={`${i}-member`} style={[theme.flex1]}>
            <ComplexRow 
              label={`${m?.givenName} ${m?.familyName}`}
              sublabel={`${c('proof') ? c('proof') + ' ' : ''}${c1(
                `${sviTodo.statusCode}.title`,
              )}`}   
              accessory={
                <Text weight="medium">
                  {safeFormatDate(toDate(sviTodo.resolveBy), 'MMMM d, yyyy')}
                </Text>
              }      
            />
    
          </View>
        )),
      )}
    </Stack>
  );
};

export default SuccessSVITodos;
