import React from 'react';
import { SheetComponentProps } from '@navigate';
import { Asset } from '@uikit';
import { useCopy, openIntercom } from '@app/utils';
import { SheetBlueprint } from '@app/blueprints';
import { useDeprecatedQuery } from '@app/data';

const RetirementWithdrawalSheet: React.FC<SheetComponentProps['RETIREMENT_WITHDRAWAL']> = ({
  closeSheet,
}) => {
  const { c } = useCopy('catch.retirement.WithdrawWarning');
  const { retirementGoal } = useDeprecatedQuery('transferGoals');

  return (
    <SheetBlueprint
      asset={Asset.configureBenefit('RETIREMENT')}
      title={c('title')}
      subtitle={c('description', { accountType: retirementGoal?.accountType })}
      actions={[
        {
          label: c('contactButton'),
          onAction: () => {
            closeSheet();
            openIntercom();
          },
        },
      ]}
    />
  );
};

export default RetirementWithdrawalSheet;
