import gql from 'graphql-tag';
import { ApolloPlanSearch, PlanSearchFragment } from '../fragments';

export interface ExplorerPlansQueryData {
  viewer: {
    id: string;
    health: {
      healthExplorerData: {
        id: string;
        providerPlanID: string;
        plans: ApolloPlanSearch;
      };
    };
  };
  viewerTwo: {
    id: string;
    statePlanSearch: ApolloPlanSearch;
  };
}

export const ExplorerPlansQuery = gql`
  query ExplorerPlansQuery {
    viewerTwo {
      id
      healthExplorerData {
        id
        providerPlanID
        coverageYear
        state
        healthPlans {
          ...PlanSearch
        }
      }
    }
  }
  ${PlanSearchFragment}
`;
