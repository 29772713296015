import React from 'react';
import { stacks, Stack } from '@navigate';
import { CoverageView } from './coverage/CoverageView';
import { CoverageDetailView } from './coverage/CoverageDetailView';
import { TabStackComponent, TabConfig } from '../types';

const config: TabConfig = {
  stackName: stacks.COVERAGE_STACK,
  options: { largeTitle: true, drawBehindNav: true, accentColor: 'coverage' },
  screens: [CoverageView, CoverageDetailView],
  tabIcon: 'COVERAGE',
  title: 'Coverage',
};

const CoverageStack: TabStackComponent = () => {
  return <Stack stackName={config.stackName} screens={config.screens} options={config.options} />;
};

CoverageStack.config = config;
export default CoverageStack;
