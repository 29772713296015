import React, { useState, useMemo, useEffect } from 'react';
import { close, navigate, routes } from '@navigate';
import { calculateTaxes } from '@catch-co/tax-calculator';
import { Button } from '@uikit';
import { Toolbar, BasicLayout } from '@layouts';
import { useForm, Fields, formatPayload } from '@app/forms';
import { fields } from '@app/config';
import { ConfirmationBlueprint } from '@blueprints/ConfirmationBlueprint';
import { WorkType, FilingStatus, FieldsConfig, GoalSlug } from '@types';
import {
  UserDetailsQueryData,
  queries,
  useQuery,
  useMutation,
  mutations,
  UpdateUserResponse,
  UpdateUserVars,
} from '@data';

type EditFieldType =
  | 'NICKNAME'
  | 'LEGAL_NAME'
  | 'DOB'
  | 'LEGAL_ADDRESS'
  | 'JOB_TITLE'
  | 'EMPLOYMENT_TYPE'
  | 'WORK_STATE'
  | 'FILING_STATUS'
  | 'ESTIMATED_INCOME'
  | 'SPOUSE_INCOME'
  | 'BUSINESS';

interface EditUserDetailsProps {
  slug: GoalSlug;
  field: EditFieldType;
}

interface FormValues {
  nickname?: string;
  givenName?: string;
  familyName?: string;
  dob?: string;
  legalAddress?: {
    street1?: string;
    street2?: string;
    city?: string;
    state?: string;
    zip?: string;
  };
  workType?: WorkType;
  jobTitle?: string;
  estimated1099Income?: number;
  estimatedW2Income?: number;
  workState?: string;
  filingStatus?: FilingStatus;
  spouseIncome?: number;
}

const configs: Record<EditFieldType, FieldsConfig> = {
  NICKNAME: [fields.NICKNAME],
  LEGAL_NAME: [fields.LEGAL_NAME],
  DOB: [fields.DOB],
  LEGAL_ADDRESS: [fields.LEGAL_ADDRESS],
  JOB_TITLE: [fields.JOB_TITLE],
  EMPLOYMENT_TYPE: [fields.EMPLOYMENT_TYPE],
  WORK_STATE: [fields.WORK_STATE],
  FILING_STATUS: [fields.FILING_STATUS],
  ESTIMATED_INCOME: [fields.INCOME_1099, fields.INCOME_W2],
  SPOUSE_INCOME: [fields.SPOUSE_INCOME],
  BUSINESS: [fields.DBA, fields.EIN],
};

const EditUserDetails: React.FC<EditUserDetailsProps> = ({ field }: EditUserDetailsProps) => {
  const [followUp, setFollowUp] = useState<boolean>(false);

  const [initial, setInitial] = useState<
    { total: number; federal: number; state: number } | undefined
  >();

  const { loading, data } = useQuery<UserDetailsQueryData>(queries.USER_DETAILS, {
    fetchPolicy: 'cache-first',
  });

  const [update, { loading: submitting, called, error }] = useMutation<
    UpdateUserResponse,
    UpdateUserVars
  >(mutations.UPDATE_USER);

  const fields = useMemo(() => {
    return field && field in configs ? configs[field] : [];
  }, [field]);

  useEffect(() => {
    // only set the taxes if filing status and work state are both defined
    if (data?.viewer.user.filingStatus && data?.viewer.user.workState) {
      const recs = calculateTaxes({
        grossIncome: data?.viewer.user.estimatedIncome,
        filingStatus: data?.viewer.user.filingStatus,
        state: data?.viewer.user.workState,
        spouseIncome: data?.viewer.user.spouseIncome,
        numDependents: data?.viewer.user.numTaxDependents,
      });

      if (!initial) {
        // no recommendation, probably just opened the modal
        setInitial({
          total: recs.roundedPaycheckPercentage,
          federal: recs.roundedPaycheckPercentageFederal,
          state: recs.roundedPaycheckPercentageState,
        });
      } else {
        if (
          data?.viewer?.taxGoal?.slug === 'taxes' &&
          (recs.roundedPaycheckPercentageFederal !== initial.federal ||
            recs.roundedPaycheckPercentageState !== initial.state)
        ) {
          setFollowUp(true);
        }
      }
    }
  }, [data?.viewer.user]);

  const initialValues = {
    nickname: data?.viewer?.user.nickname,
    givenName: data?.viewer?.user.givenName,
    familyName: data?.viewer?.user.familyName,
    dob: data?.viewer?.user.dob,
    legalAddress: data?.viewer?.user.legalAddress,
    workType: data?.viewer?.user.workType,
    jobTitle: data?.viewer?.user.jobTitle,
    estimated1099Income: data?.viewer?.user.estimated1099Income,
    estimatedW2Income: data?.viewer?.user.estimatedW2Income,
    workState: data?.viewer?.user.workState,
    filingStatus: data?.viewer?.user.filingStatus,
    spouseIncome: data?.viewer?.user.spouseIncome,
    dba: data?.viewer?.user.dba,
    ein: data?.viewer?.user.ein,
  };

  const form = useForm<FormValues>({
    loading,
    disabled: submitting,
    initialValues: initialValues,
    fields,
    onSubmit: (values) => {
      const payload = formatPayload(values, fields);
      update({ variables: { user: payload } });
    },
  });
  return (
    <ConfirmationBlueprint
      loading={submitting}
      called={called}
      error={!!error}
      onSuccess={close}
      followUp={
        followUp
          ? {
              accentColor: 'taxes',
              render: 'taxes',
              title: "We've updated your tax recommendation",
              action: {
                type: 'ROUTE',
                label: 'View',
                testID: 'recommendation',
                onAction: () =>
                  navigate(routes.GOAL_ESTIMATOR, { slug: 'taxes', standalone: true }),
              },
            }
          : undefined
      }
    >
      <BasicLayout
        loading={loading}
        title="Update your info"
        toolbar={
          <Toolbar>
            <Button alt testID="cancel" onPress={close} disabled={form.disableSubmit}>
              Cancel
            </Button>
            <Button
              testID="update"
              onPress={form.submitForm}
              disabled={form.disableSubmit}
              loading={submitting}
            >
              Update
            </Button>
          </Toolbar>
        }
      >
        <Fields form={form} fields={fields} />
      </BasicLayout>
    </ConfirmationBlueprint>
  );
};

export const EditUserDetailsView = {
  name: routes.EDIT_USER_DETAILS,
  component: EditUserDetails,
  options: {
    bg: 'sheet',
    layout: 'sheet',
  },
};
