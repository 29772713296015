import React from 'react';
import { stacks, Stack } from '@navigate';
import { IncomeView } from './income/IncomeView';
import { TabStackComponent, TabConfig } from '../types';

const config: TabConfig = {
  stackName: stacks.INCOME_STACK,
  options: {},
  screens: [IncomeView], // @todo E-3445 typescript
  tabIcon: 'INCOME',
  title: 'Income',
};

const IncomeStack: TabStackComponent = () => {
  return <Stack stackName={config.stackName} screens={config.screens} options={config.options} />;
};

IncomeStack.config = config;

export default IncomeStack;
