/**
 * index.js is our main(). It brings together all required libraries and
 * polyfills to render our React application
 */
import { AppRegistry } from 'react-native';
import Env from '@app/utils/env';

// Setup
import AppProvider from '@app/web/AppProvider';
import { default as apolloClient } from '@client/Apollo';
import {
  translationMessages,
  configureStore,
  init as initLogger,
  Log,
  initAnalytics,
} from '@app/utils';

const MOUNT_NODE = document.getElementById('app');

// Logger will log messages in dev and prod or send
// them to Sentry in production
initLogger();
Log.info(Env, 'env');

function setup() {
  const store = configureStore();

  if (!Env.isLocal) {
    initAnalytics(Env.analytics.segmentKey, Env.isDevLike);
  }

  AppRegistry.registerComponent('CatchApp', () => AppProvider);

  AppRegistry.runApplication('CatchApp', {
    initialProps: {
      store,
      client: apolloClient,
      messages: translationMessages,
    },
    rootTag: MOUNT_NODE,
  });
}

setup();
