import React, { useMemo } from 'react';
import {
  useQuery,
  HealthApplicationQueryData,
  queries,
  getMembers,
  useMutation,
  mutations,
} from '@data';
import { navigate, routes } from '@navigate';
import { useCopy } from '@app/utils';
import { BasicFormBlueprint } from '@app/blueprints';

const PREFIX = 'catch.ede.CIC.incomeEntry';

const CICIncomeEntry = ({ applicationID }) => {
  const { c } = useCopy(PREFIX);

  const { data } = useQuery<HealthApplicationQueryData>(queries.HEALTH_APPLICATION, {
    variables: { applicationID },
    skip: !applicationID,
  });

  const householdIncome = useMemo(() => {
    return getMembers(data)?.reduce((income, m) => {
      return income + (m?.annualTaxIncome?.incomeAmount || 0);
    }, 0);
  }, [data]);

  const [report, { loading: reporting }] = useMutation(mutations.REPORT_HEALTH_ENROLLMENT_CHANGES, {
    onCompleted: (data) => {
      const { applicationID } = data?.reportHealthEnrollmentChanges?.insuranceEnrollment || {};
      navigate(routes.CIC_PRELIM_ELIG, { applicationID });
    },
  });

  const onSubmit = (values) => {
    report({
      variables: {
        input: {
          applicationInput: {
            householdIncome: Math.round(values.householdIncome),
          },
        },
      },
    });
  };

  return (
    <BasicFormBlueprint
      title={c('title')}
      loading={!data}
      submitting={reporting}
      formConfig={{
        initialValues: { householdIncome },
        fields: [
          {
            testID: 'S4',
            name: 'householdIncome',
            type: 'amount',
            label: 'Household income (per year)',
            required: true,
            amountType: 'integer',
            placeholder: 'Annual income',
          },
        ],
        onSubmit,
      }}
    />
  );
};

export const CICIncomeEntryView = {
  name: routes.CIC_INCOME_ENTRY,
  component: CICIncomeEntry,
  options: {},
};
