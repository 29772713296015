import React, { useState, useMemo } from 'react';
import shallow from 'zustand/shallow';
import { boolToTri, calcPovertyLine, Env, lowercase, useCopy } from '@app/utils';
import { SegmentedControl, Link } from '@uikit';
import {
  useQuery,
  queries,
  HealthApplicationQueryData,
  useMutation,
  mutations,
  UpsertHealthApplicationResponse,
  UpsertHealthApplicationVars,
} from '@data';
import { routes, useSheet, sheets, navigate } from '@navigate';
import { SplitFormBlueprint } from '@app/blueprints';
import { optionPresets } from '@app/forms';

// format object values
const formatObjectValues = (obj, formatter) => {
  if (!obj) return {};

  return Object.keys(obj).reduce((formatted: object, key: string) => {
    const updated: object = { ...formatted, [key]: formatter(obj[key]) };
    return updated;
  }, {});
};

const Screening = ({ applicationID, householdRevisit }) => {
  const { open } = useSheet((state) => ({ open: state.open }), shallow);
  const { c, $ } = useCopy('catch.ede');
  const [phase, setPhase] = useState(2);

  const { data } = useQuery<HealthApplicationQueryData>(queries.HEALTH_APPLICATION, {
    variables: { applicationID },
    skip: !applicationID,
  });

  const {
    maritalStatus,
    householdIncome,
    numTaxDependents,
    coverageState,
    coverageYear,
    qualifiesForFinancialHelp,
    screening,
    householdCategory,
    applicantsCategory,
    explorerPlan,
  } = useMemo(() => {
    const householdIncome = data?.viewerTwo.health.application.householdIncome || 0;
    const numTaxDependents = data?.viewerTwo.health.application.numTaxDependents || 0;
    const maritalStatus = data?.viewerTwo.health.application.maritalStatus;
    const { fpls420 } = calcPovertyLine({
      numTaxDependents,
      maritalStatus: data?.viewerTwo.health.application.maritalStatus,
      state: data?.viewerTwo.health.application.coverageState,
    });

    const numApplicants =
      (data?.viewerTwo.health.application.numDependentsRequestCoverage || 0) +
      (data?.viewerTwo.health.application.isSelfCoverage ? 1 : 0) +
      (data?.viewerTwo.health.application.isSpouseCoverage ? 1 : 0);

    return {
      applicationID: data?.viewerTwo.health.application.id,
      coverageState: data?.viewerTwo.health.application.coverageState,
      coverageYear: data?.viewerTwo.health.application.coverageYearNumber,
      maritalStatus,
      householdIncome,
      numTaxDependents,
      fpls420,
      qualifiesForFinancialHelp: householdIncome < fpls420,
      numApplicants,
      householdCategory: numTaxDependents > 0 || maritalStatus === 'MARRIED' ? 'many' : 'one',
      applicantsCategory: numApplicants > 2 ? 'many' : numApplicants === 2 ? 'two' : 'one',
      screening: formatObjectValues(data?.viewerTwo.health.application.screening, boolToTri),
      explorerPlan: data?.viewerTwo?.healthExplorerData?.healthPlanDetails,
    };
  }, [data?.viewerTwo.health.application]);

  const [upsert, { loading: upserting }] = useMutation<
    UpsertHealthApplicationResponse,
    UpsertHealthApplicationVars
  >(mutations.UPSERT_HEALTH_APPLICATION);

  const onSubmit = (values) => {
    upsert({
      variables: {
        input: {
          id: applicationID,
          applicationPhase: phase === 2 ? 'TWO' : 'ONE',
          ...values,
        },
      },
      optimisticResponse: {
        upsertHealthApplication: {
          id: applicationID,
          applicationPhase: phase === 2 ? 'TWO' : 'ONE',
        },
      },
    });

    navigate(householdRevisit ? routes.EDE_MEMBER_INFO : routes.EDE_PRO_ASSISTANCE);
  };

  return (
    <SplitFormBlueprint
      loading={!data}
      submitting={upserting}
      title={c('Screening.title', { householdRevisit: !!householdRevisit })}
      subtitle={c('Screening.subtitle', { householdRevisit: !!householdRevisit })}
      precontent={
        !!Env.isDevLike && (
          <SegmentedControl
            index={phase - 1}
            controls={[{ title: 'Phase 1' }, { title: 'Phase 2' }]}
            setIndex={(i) => {
              setPhase(i + 1);
            }}
          />
        )
      }
      formConfig={{
        onError: (fields) => {
          const failedScreeners = fields.filter((f) => f !== 'agreesToFullPrice');

          if (failedScreeners.length > 0) {
            open(sheets.HEALTH_SCREENED_OUT, {
              failedQuestion: fields?.[0],
              coverageYear,
            });
          }
        },
        initialValues: {
          ...screening,
          numTaxDependents,
          isPhase1: phase === 1,
          explorerPlan,
        },
        fields: [
          {
            type: 'section',
            title: c('Screening.sectionFinAssist'),
            fields: [
              {
                testID: !householdIncome ? 'S5' : qualifiesForFinancialHelp ? 'S6' : 'S7',
                type: 'option',
                name: 'isRequestingFinancialAssistance',
                required: true,
                label: !householdIncome
                  ? c('HealthEnrollment.S5_Q')
                  : qualifiesForFinancialHelp
                  ? c('HealthEnrollment.S6_Q')
                  : c('HealthEnrollment.S7_Q'),
                help: !householdIncome
                  ? c('HealthEnrollment.S5_T')
                  : qualifiesForFinancialHelp
                  ? c('HealthEnrollment.S6_T')
                  : c('HealthEnrollment.S7_T'),
                options: [
                  { key: 'yes', label: c('Screening.apply'), value: 'YES' },
                  { key: 'no', label: c('Screening.payFull'), value: 'NO' },
                ],
                dependencies: ['isRequestingFinancialAssistance'],
                fieldAddition: ({ isRequestingFinancialAssistance }) => {
                  if (isRequestingFinancialAssistance === 'NO') {
                    return { component: 'FINANCIAL_ASSISTANCE_WARNING', props: { explorerPlan } };
                  }
                },
              },
              {
                required: true,
                type: 'checkbox',
                name: 'agreesToFullPrice',
                label: ({ explorerPlan }) =>
                  `I understand I will not be eligible for savings and my earlier selected plan will cost ${$(
                    explorerPlan?.premium,
                  )}, instead of ${$(explorerPlan?.premiumWithCredit)}`,
                dependencies: ['isRequestingFinancialAssistance', 'explorerPlan'],
                display: ({ isRequestingFinancialAssistance, explorerPlan }) => {
                  return (
                    explorerPlan &&
                    explorerPlan?.premiumWithCredit < explorerPlan?.premium &&
                    isRequestingFinancialAssistance === 'NO'
                  );
                },
              },
            ],
          },
          {
            name: 'household-section',
            type: 'section',
            title: c('Screening.sectionHousehold'),
            fields: [
              {
                testID: 'S8',
                type: 'option',
                label: c('HealthEnrollment.S8_Q', {
                  category: householdCategory,
                  state: coverageState,
                }),
                help: c('HealthEnrollment.S8_T'),
                name: 'areAllSameAddress',
                options: optionPresets.YES_NO_TRINARY,
                required: true,
                equals: 'YES',
              },
              {
                testID: 'S9',
                type: 'option',
                label: c('HealthEnrollment.S9_Q', {
                  maritalStatus: lowercase(maritalStatus),
                  coverageYear,
                }),
                help: c('HealthEnrollment.S9_T', {
                  coverageYear,
                  followingYear: coverageYear ? coverageYear + 1 : '',
                }),
                name: 'isPlanningToFileTax',
                dependencies: ['isRequestingFinancialAssistance'],
                display: (values) => values.isRequestingFinancialAssistance === 'YES',
                options: optionPresets.YES_NO_TRINARY,
                required: true,
                equals: 'YES',
              },
              {
                testID: 'S10',
                type: 'option',
                label: c('HealthEnrollment.S10_Q', {
                  maritalStatus: lowercase(maritalStatus),
                }),
                help: c('HealthEnrollment.S10_T'),
                name: 'isResponsibleForChildNotOnTaxReturn',
                dependencies: ['isRequestingFinancialAssistance'],
                display: (values) => values.isRequestingFinancialAssistance === 'YES',
                options: optionPresets.YES_NO_TRINARY,
                required: true,
                equals: 'NO',
              },

              {
                testID: 'S12',
                type: 'option',
                label: c('HealthEnrollment.S12_Q', {
                  category: householdCategory,
                }),
                name: 'isPregnantOrHadChildLast60Days',
                help: c('HealthEnrollment.S12_T'),
                dependencies: ['isRequestingFinancialAssistance', 'isPhase1'],
                display: (values) =>
                  values.isRequestingFinancialAssistance === 'YES' && values.isPhase1,
                options: optionPresets.YES_NO_TRINARY,
                required: true,
                equals: 'NO',
              },
              {
                testID: 'S11',
                type: 'option',
                label: c('HealthEnrollment.S11_Q', {
                  category: householdCategory,
                }),
                help: c('HealthEnrollment.S11_T'),
                name: 'isAnyoneFullTimeStudent1822',
                dependencies: ['isRequestingFinancialAssistance', 'isPhase1'],
                display: (values) =>
                  values.isRequestingFinancialAssistance === 'YES' && values.isPhase1,
                options: optionPresets.YES_NO_TRINARY,
                required: true,
                equals: 'NO',
              },
              {
                testID: 'S19',
                type: 'option',
                label: c('HealthEnrollment.S19_Q', {
                  category: householdCategory,
                }),
                help: c('HealthEnrollment.S19_T', {
                  link1: (
                    <Link
                      inline
                      target="_blank"
                      href="http://www.ncsl.org/research/state-tribal-institute/list-of-federal-and-state-recognized-tribes.aspx"
                    >
                      {c('HealthEnrollment.S19_T_link1')}
                    </Link>
                  ),
                  link2: (
                    <Link
                      inline
                      target="_blank"
                      href="https://www.healthcare.gov/american-indians-alaska-natives/medicaid-chip/"
                    >
                      {c('HealthEnrollment.S19_T_link2')}
                    </Link>
                  ),
                }),
                name: 'isIndianOrAlaskan',
                dependencies: ['isRequestingFinancialAssistance'],
                options: optionPresets.YES_NO_TRINARY,
                required: true,
                equals: 'NO',
              },
            ],
          },
          {
            name: 'applicants-section',
            type: 'section',
            title: c('Screening.sectionApplicants'),
            fields: [
              {
                testID: 'S14',
                type: 'option',
                label: c('HealthEnrollment.S14_Q', { category: applicantsCategory }),
                name: 'areApplicationsCitizens',
                help: c('HealthEnrollment.S14_T', {
                  link1: (
                    <Link
                      inline
                      href="https://www.healthcare.gov/immigrants/us-citizens-and-nationals/"
                    >
                      {c('HealthEnrollment.S14_T_link1')}
                    </Link>
                  ),
                }),
                dependencies: ['isPhase1'],
                display: (values) => values.isPhase1,
                options: optionPresets.YES_NO_TRINARY,
                required: true,
                equals: 'YES',
              },

              {
                testID: 'S15',
                type: 'option',
                label: c('HealthEnrollment.S15_Q', { category: applicantsCategory }),
                name: 'isCanProvideSsn',
                help: c('HealthEnrollment.S15_T', {
                  link1: (
                    <Link inline target="_blank" href="https://www.healthcare.gov">
                      {c('HealthEnrollment.S15_T_link1')}
                    </Link>
                  ),
                }),
                dependencies: ['isPhase1'],
                display: (values) => values.isPhase1,
                options: optionPresets.YES_NO_TRINARY,
                required: true,
                equals: 'YES',
              },

              {
                testID: 'S16',
                type: 'option',
                label: c('HealthEnrollment.S16_Q', { category: applicantsCategory }),
                name: 'isApplicationNameSameAsSsn',
                help: c('HealthEnrollment.S16_T', {
                  link1: (
                    <Link inline target="_blank" href="https://www.healthcare.gov">
                      {c('HealthEnrollment.S16_T_link1')}
                    </Link>
                  ),
                }),
                dependencies: ['isPhase1'],
                display: (values) => values.isPhase1,
                options: optionPresets.YES_NO_TRINARY,
                required: true,
                equals: 'NO',
              },
              {
                testID: 'S17',
                type: 'option',
                label: c('HealthEnrollment.S17_Q', { category: applicantsCategory }),
                name: 'isNaturalizedOrDerivedCitizenship',
                help: c('HealthEnrollment.S17_T', {
                  link1: (
                    <Link
                      inline
                      target="_blank"
                      href="https://www.healthcare.gov/immigrants/us-citizens-and-nationals/"
                    >
                      {c('HealthEnrollment.S17_T_link1')}
                    </Link>
                  ),
                }),
                dependencies: ['isPhase1'],
                display: (values) => values.isPhase1,
                options: optionPresets.YES_NO_TRINARY,
                required: true,
                equals: 'NO',
              },
              {
                testID: 'S18',
                type: 'option',
                label: c('HealthEnrollment.S18_Q', { category: applicantsCategory }),
                name: 'isIncarcerated',
                help: c('HealthEnrollment.S18_T'),
                dependencies: ['isPhase1'],
                display: (values) => values.isPhase1,
                options: optionPresets.YES_NO_TRINARY,
                required: true,
                equals: 'NO',
              },

              {
                testID: 'S20',
                type: 'option',
                label: c('HealthEnrollment.S20_Q', { category: applicantsCategory }),
                name: 'isCoveredThroughJobOrCobra',
                help: c('HealthEnrollment.S20_T'),
                dependencies: ['isRequestingFinancialAssistance'],
                display: (values) => values.isRequestingFinancialAssistance === 'YES',
                options: optionPresets.YES_NO_TRINARY,
                required: true,
                equals: 'NO',
              },

              {
                testID: 'S21',
                type: 'option',
                label: c('HealthEnrollment.S21_Q', { category: applicantsCategory }),
                name: 'isFormerFosterCareAndUnder25',
                help: c('HealthEnrollment.S21_T'),
                dependencies: ['isPhase1'],
                display: (values) => values.isPhase1,
                options: optionPresets.YES_NO_TRINARY,
                required: true,
                equals: 'NO',
              },

              {
                testID: 'S27',
                type: 'option',
                label: c('HealthEnrollment.S27_Q', { category: applicantsCategory }),
                name: 'isOfferedIchra',
                help: c('HealthEnrollment.S27_T'),
                options: optionPresets.YES_NO_TRINARY,
                required: true,
                equals: 'NO',
              },
            ],
          },
          {
            name: 'dependents-section',
            type: 'section',
            title: c('Screening.sectionDependents'),
            dependencies: ['numTaxDependents'],
            display: (values) => values.numTaxDependents > 0,
            fields: [
              {
                testID: 'S22',
                type: 'option',
                label: c('HealthEnrollment.S22_Q', { category: householdCategory, coverageYear }),
                help: c('HealthEnrollment.S22_T'),
                name: 'isClaimingAllDependentsOnTax',
                dependencies: ['isRequestingFinancialAssistance'],
                display: (values) => values.isRequestingFinancialAssistance === 'YES',
                options: optionPresets.YES_NO_TRINARY,
                required: true,
                equals: 'YES',
              },
              {
                testID: 'S23',
                type: 'option',
                label: c('HealthEnrollment.S23_Q', { category: householdCategory }),
                help: c('HealthEnrollment.S23_T'),
                name: 'isDependentSingleChild25OrYounger',
                dependencies: ['isPhase1'],
                display: (values) => values.isPhase1,
                options: optionPresets.YES_NO_TRINARY,
                required: true,
                equals: 'YES',
              },

              {
                testID: 'S25',
                type: 'option',
                label: c('HealthEnrollment.S25_Q', { category: householdCategory }),
                name: 'isStepchildOrGrandchild',
                help: c('HealthEnrollment.S25_T'),
                dependencies: ['isPhase1'],
                display: (values) => values.isPhase1,
                options: optionPresets.YES_NO_TRINARY,
                required: true,
                equals: 'NO',
              },

              {
                testID: 'P2_S14',
                type: 'option',
                label: c('HealthEnrollment.P2_S14_Q', { category: householdCategory }),
                name: 'areDependentsChildOrStepchildSingleUnder26',
                dependencies: ['isPhase1'],
                display: (values) => !values.isPhase1,
                options: optionPresets.YES_NO_TRINARY,
                required: true,
                equals: 'YES',
              },
              {
                testID: 'S26',
                type: 'option',
                label: c('HealthEnrollment.S26_Q', { category: householdCategory }),
                name: 'areDependentsLivingWithParentNotOnTaxReturn',
                dependencies: ['isRequestingFinancialAssistance'],
                display: (values) => values.isRequestingFinancialAssistance === 'YES',
                options: optionPresets.YES_NO_TRINARY,
                required: true,
                equals: 'NO',
              },
            ],
          },
        ],
        onSubmit,
      }}
    />
  );
};

export const ScreeningView = {
  name: 'EDE_SCREENING',
  component: Screening,
  options: {
    ...SplitFormBlueprint.options,
    title: 'Background info',
  },
};
