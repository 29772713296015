import gql from 'graphql-tag';
import { goals as goalsFragments } from '../fragments';

export const GOALS = gql`
  query Goals {
    viewer {
      id
      goals {
        id
        slug
        ...Goal
      }
      user {
        id
        workState
      }
    }
  }
  ${goalsFragments.fullGoal}
`;

const formatter = (data) => ({
  goals: data?.viewer?.goals,
  state: data?.viewer?.user?.workState,
});

export default {
  document: GOALS,
  formatter,
};
