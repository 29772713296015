import React, { useMemo } from 'react';
import { View } from 'react-native';

import { useCopy, device, Segment, Env, share } from '@app/utils';
import { useDeprecatedQuery } from '@data';
import { ReferralCode, SettingsLayout } from '@common';
import { Button, FinePrint, Link, useResponsive, useTheme, CatchTheme } from '@uikit';
import { Layout, Stack } from '@layouts';

import Gift from '@svg/gift.svg';

const Referral = () => {
  const { c } = useCopy('catch.module.me.ReferralView');
  const { c: c_w } = useCopy('catch.widgets.referral');

  const { isMobile } = useResponsive();
  const { themeColors } = useTheme();

  const { referralCode, loading } = useDeprecatedQuery('user');
  const { settledBalance, ...status } = useDeprecatedQuery('status');
  const { total, count, ...rewards } = useDeprecatedQuery('latestRewards', {
    filter: (r) => /Referral Reward/.test(r.description),
  });

  const url = `${Env.webUrl}/?r=${referralCode}`;

  const handleShare = () => {
    Segment.copiedRefLink(referralCode);
    share({
      message: c_w('message'),
      url,
      options: {
        copyLinkOnly: true,
      },
    });
  };

  const ShareToolbar = useMemo(
    () => (
      <Layout bottomSpace={isMobile}>
        <Stack spacing="1" itemStyle={{ alignItems: 'center' }}>
          <FinePrint
            align="center"
            items={[
              c('termsLink', {
                link: (
                  <Link
                    color={themeColors.brandColor}
                    href={'https://catch.co/legal/catch-referral-offers'}
                    target="_blank"
                    inline
                  >
                    terms
                  </Link>
                ),
              }),
            ]}
          />
          <Button accentColor="brand" onPress={handleShare} disabled={loading} wide>
            {device.canShare ? (isMobile ? 'Share' : 'Share Invite Code') : 'Copy Invite Code'}
          </Button>
        </Stack>
      </Layout>
    ),
    [loading, isMobile],
  );

  const balanceReqNotMet = !status.loading && (!settledBalance || settledBalance < 100);
  const title = `$${total || 0}`;
  const subtitle =
    balanceReqNotMet && !count ? c('encourageReferral') : c('earned', { count: count });

  return (
    <>
      <SettingsLayout>
        {!isMobile && (
          <View
            style={{
              position: 'absolute',
              borderWidth: 1,
              borderColor: themeColors.borderColor,
              borderRadius: CatchTheme.corners.round,
              width: '80%',
              height: '100%',
              left: '50%',
              top: '53%',
              transform: [{ translateX: '-50%' }, { translateY: '-50%' }],
            }}
          />
        )}
        <Layout topSpace>
          <Stack spacing="2">
            <Layout.Header
              titleSize="form"
              light
              align="center"
              title={`${c('title1')} ${c('title2')}`}
              subtitle={c('description', {
                reward: c('reward'),
              })}
              asset={{
                svg: Gift,
                gradient: 'brand',
                shape: 'circle',
                size: 'lg',
                color: 'white',
                containerSize: 'xxxl',
              }}
            />

            <ReferralCode url={url} code={referralCode} loading={loading} />
            <Layout topSpace bottomSpace>
              <Layout.Header
                loading={loading || status.loading || rewards.loading}
                align="center"
                title={title}
                titleSize="xl"
                titleColor="brand"
                subtitle={subtitle}
                subtitleSize="fp"
                subtitleMaxSize={200}
                subtitleColor="fg2"
                bottomSpace
              />
            </Layout>
          </Stack>
        </Layout>
        {!isMobile && ShareToolbar}
      </SettingsLayout>
      {isMobile && ShareToolbar}
    </>
  );
};

export const ReferralView = {
  name: 'REFERRAL',
  component: Referral,
  options: {},
};
