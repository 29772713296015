import React, { Suspense, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Routes, Route, generatePath } from 'react-router-dom';

import { Loading, ToastContainer, useTheme } from '@uikit';
import { webTitle, defaultTitle } from '@app/utils';
import { SystemError } from '@common';
import { init, paths, normalizePath, Screen, prefixes, history } from '@navigate';
import screens, { stacks, tabs, layers, guest as GuestStack, standalone } from '@app/pages';
import NotFoundView from '@app/pages/misc/NotFoundView';
import { redirects, handlers, Handler } from './redirects';
import RequireAuth from './RequireAuth';
import MainLayout from './MainLayout';

init({ screens, stacks });

// lil helper fn to better compare paths across different types
const getPath = (obj) => {
  switch (obj.type) {
    case 'redirect':
      return obj.from;
    case 'handler':
      return obj.path;
    default: {
      // standalone screen or stack?
      if (obj.config?.stackName) {
        return prefixes[obj.config.stackName];
      } else {
        return paths[obj.name];
      }
    }
  }
};

// creates a fully sorted comparative list of redirects, screens and special handlers
const sorted = [
  ...redirects,
  ...handlers,
  ...tabs,
  ...layers,
  ...standalone.filter((s) => !s.guest),
].sort((a, b) => {
  return getPath(a) > getPath(b) ? -1 : 1;
});

const App = () => {
  const { themeColors } = useTheme();
  useEffect(() => {
    document.body.style.backgroundColor = themeColors.pageColor;
  }, [themeColors]);

  return (
    <>
      <Helmet titleTemplate={`%s | ${webTitle}`} defaultTitle={defaultTitle}>
        <meta name="description" content="A portable benefits platform" />
      </Helmet>
      <Suspense fallback={<Loading full page />}>
        <Routes>
          <Route
            path="disclosures"
            element={
              <Handler
                onStart={() => {
                  // this is a one off from other redirects, since we want
                  // it to happen even when the user is not signed in
                  window.location.href = 'https://catch.co/legal';
                }}
              />
            }
          />
          <Route
            key={GuestStack.config.stackName}
            index
            path={normalizePath({ stack: GuestStack.config.stackName })}
            element={<GuestStack layout="page" />}
          />
          <Route path="" element={<RequireAuth />}>
            <Route path="" element={<MainLayout />}>
              {sorted.map((obj) => {
                switch (obj.type) {
                  case 'redirect':
                    return (
                      <Route
                        key={obj.from}
                        path={obj.from}
                        element={
                          <Handler
                            onStart={(params) => {
                              history.replace(generatePath(obj.to, params));
                            }}
                          />
                        }
                      />
                    );
                  case 'handler':
                    return <Route key={obj.path} path={obj.path} element={obj.element} />;
                  default:
                    if (obj.config?.stackName) {
                      const Stack = obj;
                      return (
                        <Route
                          key={obj.config.stackName}
                          index
                          path={normalizePath({ stack: obj.config.stackName })}
                          element={<Stack layout="page" />}
                        />
                      );
                    } else {
                      return (
                        <Route
                          key={obj.name}
                          path={normalizePath({ route: obj.name })}
                          element={
                            <Screen layout="page" name={obj.name} Component={obj.component} />
                          }
                        />
                      );
                    }
                }
              })}
              <Route path="*" element={<NotFoundView />} />
            </Route>
          </Route>
          <Route path="*" element={<NotFoundView />} />
        </Routes>
      </Suspense>
      <SystemError />
      <ToastContainer />
    </>
  );
};

export default App;
