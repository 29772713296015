import { useState } from 'react';

const fork = (...fns) => (...args) => fns.forEach(fn => fn && fn(...args));

/**
 * useModal handles the open/closed state of a modal
 * - can optionally provide an initial state
 * - also provides toggles for displaying
 */
export function useModal(initial, options = {}) {
  const [isOpen, setModal] = useState(initial || false);

  const openModal = () => setModal(true);
  const closeModal = () => setModal(false);
  const toggleModal = () => setModal(!isOpen);

  return {
    isOpen,
    setModal,
    toggleModal: fork(toggleModal, options.onToggle),
    openModal: fork(openModal, options.onOpen),
    closeModal: fork(closeModal, options.onclose),
  };
}
