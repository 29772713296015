import React from 'react';
import { useCopy } from '@app/utils';
import useDeprecatedMutation from '@data/useDeprecatedMutation';
import { close, routes } from '@navigate';
import { useAliasVerification } from '@hooks/useAliasVerification';
import { BasicFormBlueprint } from '@app/blueprints';

interface Props {
  alias?: string;
  type: 'email' | 'phoneNumber';
}

export const ChangeAlias: React.FC<Props> = ({ alias, type }) => {
  const { c } = useCopy('catch.profile.ChangeAlias');
  const [updateUser] = useDeprecatedMutation('updateUser');

  const { loading, error, step, requestedAlias, submitAlias, submitCode } = useAliasVerification({
    aliasType: type,
    onDismiss: close,
    onSuccess: ({ alias }) => {
      const input = type === 'email' ? { email: alias } : { phoneNumber: alias };
      updateUser({ variables: { input } });
    },
  });

  const configs = {
    alias: {
      initialValues: {
        alias,
      },
      fields: [
        {
          name: 'alias',
          type: type === 'email' ? 'email' : 'phone',
          required: true,
        },
      ],
      onSubmit: (values) => {
        submitAlias(values.alias || '');
      },
    },
    code: {
      initialValues: {},
      fields: [
        {
          type: 'code',
          name: 'code',
          alias: requestedAlias,
          required: true,
        },
      ],
      onSubmit: (values) => {
        submitCode({ code: values.code });
      },
    },
  };

  return (
    <BasicFormBlueprint
      loading={false}
      submitting={loading}
      title={c('title', { aliasType: type })}
      subtitle={c(step === 'alias' ? 'subtitle' : 'code', {
        alias: requestedAlias,
      })}
      error={error && c('error')}
      formConfig={step === 'alias' ? configs.alias : configs.code}
    />
  );
};

export const ChangeAliasView = {
  name: routes.CHANGE_ALIAS,
  component: ChangeAlias,
  options: {
    bg: 'sheet',
    layout: 'sheet',
  },
};
