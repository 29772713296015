import gql from 'graphql-tag';

const ADD_PUSH_TOKEN = gql`
  mutation AddPushToken($token: String!, $deviceType: DeviceType!) {
    addPushToken(token: $token, deviceType: $deviceType) {
      id
      token
      deviceType
    }
  }
`;

export default {
  document: ADD_PUSH_TOKEN,
};
