import gql from 'graphql-tag';
import { transferGoals, validateTransfer, activity } from '../queries';

const DEPOSIT_TO_GOAL = gql`
  mutation DepositToGoal($input: DepositToGoalInput!) {
    depositToGoal(input: $input)
  }
`;

export default {
  document: DEPOSIT_TO_GOAL,

  /**
   * These queries should be refetched after any money transfer
   * - the balances on transfer goals have changed, so they need to be refetched
   * - goals may no longer be kickstartable
   */
  refetchQueries: [
    { query: transferGoals.document },
    { query: validateTransfer.document },
    // refetches activity on the goal specific page
    {
      query: activity.document,
      variables: ({ goalID }) => ({
        page: { pageNumber: 1, pageSize: 10 },
        goalID,
        enrollmentID: null,
      }),
    },
    // refetches activity on the plan page
    {
      query: activity.document,
      variables: {
        page: { pageNumber: 1, pageSize: 10 },
        goalID: null,
        enrollmentID: null,
      },
    },
  ],

  /**
   * wait for our refetchQueries to finish resolving before marking as resolved
   */
  awaitRefetch: true,
};
