import React from 'react';
import { stacks, Stack, StackComponent, StackDefinition } from '@navigate';
import { Activity } from './Activity';
import { ReceiptView } from './ReceiptView';

const config: StackDefinition = {
  stackName: stacks.ACTIVITY_STACK,
  options: { layout: 'sheet' },
  screens: [Activity, ReceiptView], // @todo E-3445 typescript
};

const ActivityStack: StackComponent = () => {
  return <Stack stackName={config.stackName} screens={config.screens} options={config.options} />;
};

ActivityStack.config = config;

export default ActivityStack;
