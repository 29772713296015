// interface Eligibility {
//   advancePremiumTaxCredit: aptc
//   costSharingReductions: csr
//   isMedicaidCHIP: isMedicaidChip
//   relation
// }

/**
 * @todo
 * This should be computed on platform side, not here
 *
 * @todo move to @catch-co/health-utils
 */
export function determineSavingsEligibility(eligibilityByMember = []) {
  let eligibility = {};
  if (eligibilityByMember?.find((d) => /SELF/.test(d?.relation))?.age >= 65) {
    // @todo - make this medicare once #4257 is merged on platform
    return (eligibility = { eligibility: 'MEDICARE', applicants: 'ALL' });
  } else if (
    eligibilityByMember?.some((h) => !!h.isMedicaidCHIP && /SELF|SPOUSE/.test(h?.relation))
  ) {
    return (eligibility = { eligibility: 'MEDICARE', applicants: 'ALL' });
  } else if (eligibilityByMember?.some((h) => h.advancePremiumTaxCredit > 0)) {
    eligibility = {
      aptcEligible: true,
      amount: eligibilityByMember?.[0]?.advancePremiumTaxCredit,
    };
  } else {
    eligibility = { aptcEligible: false, csrEligible: false };
  }

  if (eligibilityByMember?.find((h) => /CHILD/.test(h?.relation))?.isMedicaidCHIP) {
    return (eligibility = {
      ...eligibility,
      stack: 'CHIP',
      applicants: 'CHILD',
    });
  }

  if (eligibilityByMember?.some((h) => !!h.costSharingReductions)) {
    return (eligibility = {
      ...eligibility,
      csrEligible: true,
      level: eligibilityByMember?.[0]?.costSharingReductions,
    });
  }

  if (eligibilityByMember?.find((d) => /SPOUSE/.test(d?.relation))?.age >= 65) {
    return (eligibility = {
      ...eligibility,
      // @todo - make this medicare once #4257 is merged on platform
      stack: 'MEDICARE',
      applicants: 'SPOUSE',
    });
  }

  return eligibility;
}
