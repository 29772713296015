import gql from 'graphql-tag';
import access from 'safe-access';
import { inCIP } from '@app/utils';

/**
 * A query for getting the general status of the page
 * - Special cases for a user being kyc denied or account locked
 * - Special cases for when a user has not completed checkup
 */
const STATUS = gql`
  query Status {
    viewerTwo {
      id
      accountStatus {
        id
        isLocked
        isBankingReady
      }
    }
    viewer {
      id
      user {
        id
        kycSavings {
          status
          needed
        }
      }
      savingsAccount {
        settledBalance
        balance
      }
      savingsAccountMetadata {
        kycStatus
        savingsProviderType
      }
      wealthAccountMetadata {
        isAccountReady
        kycStatus
      }
    }
  }
`;
const formatter = (data) => {
  const get = access(data);

  const savingsKYC = get('viewer.savingsAccountMetadata.kycStatus');
  const wealthKYC = get('viewer.wealthAccountMetadata.kycStatus');

  return {
    hasData: !!data,

    isKYCGood: get('viewer.savingsAccountMetadata.kycStatus') === 'KYC_GOOD',
    isKYCDenied: get('viewer.savingsAccountMetadata.kycStatus') === 'KYC_DENIED',
    bankProvider: get('viewer.savingsAccountMetadata.savingsProviderType'),
    isAccountLocked: data?.viewerTwo?.accountStatus?.isLocked,
    isAccountReady: data?.viewerTwo?.accountStatus?.isBankingReady,

    // this might be better ideas
    locked: data?.viewerTwo?.accountStatus?.isLocked,
    denied: get('viewer.savingsAccountMetadata.kycStatus') === 'KYC_DENIED',
    hasDDA: data?.viewerTwo?.accountStatus?.isBankingReady,
    needed: get('viewer.user.kycSavings.needed'),
    savingsKYC,
    wealthKYC,
    review: /KYC_PENDING|KYC_REVIEW/.test(savingsKYC),
    wealthReview: inCIP(wealthKYC),
    isWealthReady: !!get('viewer.wealthAccountMetadata.isAccountReady'),
    settledBalance: get('viewer.savingsAccount.settledBalance'),
    balance: get('viewer.savingsAccount.balance'),
  };
};

export default {
  document: STATUS,
  formatter,
};
