import { capitalizeAll } from './strings';
import { safeFormatDate, toGQLDate } from '../time';
import { boolToTri } from './misc';

/**
 * Helper function to format drug names
 */
export const formatDrug = (drug) => {
  return capitalizeAll(drug);
};

/**
 * Helper function to format provider names
 */
export const formatProvider = (provider) => {
  return capitalizeAll(provider);
};

export const formDate = (val) => (val ? safeFormatDate(val, 'MM/dd/yyyy') : null);
export const integerize = (val) => (val !== null ? parseInt(val) : undefined);
export const floatize = (val) => (val !== null ? parseFloat(val) : undefined);
export const normalize = (val) => val || undefined;
export const nullify = (val) => val || null;
export const normalizeTrinary = (val) => (val !== null ? boolToTri(val) : null);
export const normalizeDate = (val) => (!!val ? toGQLDate(val) : undefined);
