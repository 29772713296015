import gql from 'graphql-tag';
import access from 'safe-access';
import { convertUnixTimeToAge } from '@app/utils';

const VALIDATE_TRANSFER = gql`
  query ValidateTransfer {
    viewer {
      id
      user {
        id
        dob
      }
      recentDeposits
      goals {
        id
        availableBalance
        goalType
        slug
        ... on RetirementGoal {
          totalContributions
        }
      }
    }
  }
`;

const formatter = (data) => {
  const get = access(data);

  return {
    data: {
      age: convertUnixTimeToAge(get('viewer.user.dob')),
      recentDeposits: get('viewer.recentDeposits') || 0,
      balancesByID: (get('viewer.goals') || []).reduce((obj, goal) => {
        return { ...obj, [goal.id]: goal };
      }, {}),
      balancesByGoalType: (get('viewer.goals') || []).reduce((obj, goal) => {
        return { ...obj, [goal.goalType]: goal };
      }, {}),
    },
  };
};

const mock = () => {
  return {
    request: { query: VALIDATE_TRANSFER },
    result: {
      data: {
        viewer: {
          recentDeposits: 50,
          goals: [{ id: 'tax-goal', goalType: 'TAX', availableBalance: 100 }],
        },
      },
    },
  };
};

export default {
  document: VALIDATE_TRANSFER,
  formatter,
  mock,
};
