import React, { useState, useEffect } from 'react';

import { Loading } from '@uikit';
import { Layout } from '@layouts';
import { routes } from '@navigate';
import { useHealthStart } from '@hooks';

/**
 * Shows a loader while we figure out how
 * to route the user to the spot in their application
 */
export const HealthResume = () => {
  const [hasBeenCalled, setCalled] = useState(false);

  const { loading, handleNext } = useHealthStart({
    defaultRoute: routes.EXPLORER_INTRO,
  });

  useEffect(() => {
    if (!loading && !hasBeenCalled) {
      handleNext();
      setCalled(true);
    }
  }, [loading]);

  return (
    <Layout fullHeight color="page" topSpace>
      <Loading full accentColor="coverage" />
    </Layout>
  );
};

export const HealthResumeView = {
  name: routes.HEALTH_RESUME,
  component: HealthResume,
  options: {
    bg: 'page',
  },
};
