import gql from 'graphql-tag';
import { plan } from '../queries';

const SUBMIT_ENROLLMENT = gql`
  mutation SubmitEnrollment($applicationID: ID!) {
    submitEnrollment(id: $applicationID) {
      currentAppStatus
      policyResponseCode
      policyResponseSubcode
      memberResponseCode
      memberResponseSubcode
      error {
        code
        field
        message
      }
    }
  }
`;

export default {
  document: SUBMIT_ENROLLMENT,
  refetchQueries: [
    {
      query: plan.document,
    },
  ],
  awaitRefetch: true,
  getErrors: (data) => (data?.submitEnrollment?.error ? [data?.submitEnrollment?.error] : null),
};
