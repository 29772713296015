import React from 'react';
import { close } from '@navigate';
import { SetupIntro } from '@common';
import { Link } from '@app/_ui-kit';
import { useCopy } from '@app/utils';
import { useDeprecatedMutation } from '@app/data';

const EftpsAuth = () => {
  const { c } = useCopy('catch.agreements');

  const [upsert, { loading }] = useDeprecatedMutation('upsertLegal', {
    onCompleted: () => close({ closeSheets: true }),
    variables: {
      input: {
        authorizedAboundEftpsEnrollment: true,
      },
    },
  });

  return (
    <SetupIntro
      loading={loading}
      color="taxes"
      type="EFTPS_AUTH"
      onNext={upsert}
      fine={c('eftps', {
        link: (
          <Link href={'https://catch.co/legal/eftps'} target="_blank" inline color="taxes">
            the EFTPS and tax payments agreement
          </Link>
        ),
      })}
    />
  );
};

export const EftpsAuthView = {
  name: 'EFTPS_AUTH',
  component: EftpsAuth,
  options: {
    ...SetupIntro.options,
    drawBehindNav: true,
    hideDismiss: true,
  },
};
