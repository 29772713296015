import React, { useMemo } from 'react';
import shallow from 'zustand/shallow';
import { loaders } from '@app/config';
import { navigate, routes, useSheet, sheets } from '@navigate';
import {
  HealthApplicationQueryData,
  mutations,
  queries,
  useDeprecatedMutation,
  useMutation,
  useQuery,
} from '@data';
import { Log, useCopy, renderQuestions, handleHealthErrors, getFullName } from '@app/utils';
import { MultiMemberSplitFormBlueprint } from '@app/blueprints';
import { getInitialValues, formatPayload } from './memberInfoUtils';
import { fields } from './memberInfoFields';

const PREFIX = 'catch.ede.MemberInfo';

const MemberInfo = ({ isGA, applicationID, enrollId }) => {
  const { c } = useCopy(PREFIX);

  const { openLoader } = useSheet(
    (state) => ({ openLoader: () => state.open(sheets.LOADER, loaders.HEALTH_LONG_WAIT) }),
    shallow,
  );

  const { loading, data } = useQuery<HealthApplicationQueryData>(queries.HEALTH_APPLICATION, {
    variables: { applicationID },
    skip: !applicationID,
  });

  const { applicationPhase, isRequestingFinancialAssistance, members } = useMemo(() => {
    if (data?.viewerTwo.health.application) {
      const { applicant, members, screening, applicationPhase } =
        data?.viewerTwo.health.application || {};
      return {
        members: members ? [applicant, ...members] : applicant,
        isRequestingFinancialAssistance: screening.isRequestingFinancialAssistance,
        applicationPhase,
      };
    } else {
      return {
        members: [],
        isRequestingFinancialAssistance: undefined,
      };
    }
  }, [data?.viewerTwo.health.application]);

  const { filerName, spouseName } = useMemo(() => {
    return members.reduce(
      (names, m) => {
        if (m.relation === 'SELF') return { ...names, filerName: getFullName(m) };
        if (m.relation === 'SPOUSE') return { ...names, spouseName: getFullName(m) };
        return names;
      },
      { filerName: undefined, spouseName: undefined },
    );
  }, [members]);

  const [ensure, { loading: ensuring }] = useDeprecatedMutation('ensureHealthApplication', {
    ...handleHealthErrors,
    enrollId,
    onCompleted: ({ uiQuestionsToDisplay }) => {
      const { renderMemberQuestions, renderImmigrationQuestions } = renderQuestions({
        uiQuestionsToDisplay,
      });

      if (renderMemberQuestions) {
        navigate(routes.EDE_MEMBER_QUESTIONS);
      } else if (renderImmigrationQuestions) {
        navigate(routes.EDE_IMMIGRATION_DETAILS);
      } else if (isRequestingFinancialAssistance) {
        navigate(routes.EDE_MEDICAID_DENIAL);
      } else {
        navigate(routes.EDE_SEP);
      }
    },
  });

  const titleMap = {
    SELF: c('title'),
    SPOUSE: c('spouseTitle'),
    CHILD: c('dependentTitle'),
  };

  const [upsert] = useMutation(mutations.UPSERT_APPLICATION_MEMBERS);

  Log.debug(members);

  const handleNext = () => {
    const { isWindowShopping, oeCoverageYear } = data?.reference?.health?.openEnrollmentDates || {};
    const appCoverageYear = data?.viewerTwo?.health?.application?.coverageYearNumber;

    // when applying for OE year, but it's still window shopping period
    if (appCoverageYear === oeCoverageYear && isWindowShopping) {
      navigate(routes.EDE_WINDOW_SHOPPING);
    } else {
      openLoader();
      ensure({ variables: { applicationID } });
    }
  };

  return (
    <MultiMemberSplitFormBlueprint
      loading={loading}
      submitting={ensuring}
      title={(values) =>
        values?.givenName ? c('nameTitle', { name: values?.givenName }) : titleMap[values?.relation]
      }
      members={members}
      fields={fields}
      data={{
        isGA,
        applicationPhase,
        filerName,
        spouseName,
        isRequestingFinancialAssistance,
        SSNs: members?.map((member) => ({
          id: member.id,
          ssn: member.ssn,
        })),
      }}
      getInitialValues={getInitialValues}
      onNext={async (values) => {
        await upsert(formatPayload({ applicationID, values }));
      }}
      onComplete={handleNext}
    />
  );
};

export const MemberInfoView = {
  name: routes.EDE_MEMBER_INFO,
  component: MemberInfo,
  options: {
    ...MultiMemberSplitFormBlueprint.options,
    title: 'Personal details',
  },
};
