import React from 'react';

import { StyleSheet, View } from 'react-native';

import { useTheme } from '../hooks/useTheme';

/**
 * Divider is useful for separating different concerns on a view
 * @NOTE 100% height does not always work so we add height (or width) props
 */
export const Divider = ({ white, short, tiny, vertical, height, width, style, color, ...rest }) => {
  const { theme } = useTheme();
  return (
    <View
      style={[
        styles.base,
        theme.borderBg,
        !!color && (theme[`${color}DividerBg`] || theme[`${color}Bg`]),
        vertical && styles.vertical,
        short && styles.short,
        tiny && styles.tiny,

        !!height && { height },
        !!width && { width },
        style,
      ]}
      {...rest}
    />
  );
};

const styles = StyleSheet.create({
  base: {
    height: 1,
    width: '100%',
    opacity: 1,
  },
  vertical: {
    height: '100%',
    width: 1,
  },
  short: {
    width: '50%',
    margin: 'auto',
    alignSelf: 'center',
  },
  tiny: {
    width: '25%',
    margin: 'auto',
  },
});

export default Divider;
