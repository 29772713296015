// @ts-nocheck
import React, { useMemo, useEffect } from 'react';
import { Linking } from 'react-native';
import { HiccupBlueprint } from '@blueprints';
import { useQuery, queries, HealthExplorerQueryData, useDeprecatedQuery } from '@data';
import { Env, useCopy } from '@app/utils';
import { useNudge } from '@app/hooks';
import { open, routes, ScreenDefinition } from '@navigate';
import { Loading, Link, stateIcons } from '@uikit';

const ExplorerStateReturn = () => {
  const { c } = useCopy('catch.healthExplorer.explorerStateReturn');

  const { data } = useQuery<HealthExplorerQueryData>(queries.HEALTH_EXPLORER);

  const { coverageYear, state } = useMemo(() => {
    return {
      coverageYear: data?.viewerTwo?.healthExplorerData?.coverageYear,
      state: data?.viewerTwo?.healthExplorerData?.state,
    };
  }, [data?.viewerTwo?.healthExplorerData]);

  const { dismissNudge: toggleExited } = useNudge(`healthEnrollmentIsNotExited${coverageYear}`);

  const { name, url, loading } = useDeprecatedQuery('stateHealthReference', {
    variables: { state },
    skip: !state,
    fetchPolicy: 'cache-first',
  });

  const openURL = () => {
    Env.isNative ? Linking.openURL(url) : window.open(url);
  };

  useEffect(() => {
    if (coverageYear) toggleExited();
  }, [coverageYear]);

  if (!state || loading) return <Loading accentColor="coverage" />;

  return (
    <HiccupBlueprint
      title={c('title')}
      align="center"
      subtitles={[
        c('description'),
        <Link key="state-exchange-link" onPress={openURL}>
          Back to {name}
        </Link>,
      ]}
      onPress={() => open(routes.WALLET_ITEM, { item: { vertical: 'HEALTH' } })}
      buttonText={c('btnAddToWallet')}
      asset={{ icon: stateIcons[state], color: 'text' }}
    />
  );
};

export const ExplorerStateReturnView: ScreenDefinition = {
  name: routes.STATE_RETURN,
  component: ExplorerStateReturn,
  options: {},
};
