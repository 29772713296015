import React from 'react';
import { differenceInHours } from 'date-fns';

import { /* useQuery, IncomeQueryData, queries, */ useDeprecatedQuery } from '@data';
import { exit, open, routes } from '@navigate';
import { currentYear, useCopy } from '@app/utils';
import { Asset, Button, ComplexRow, Text, ZStack } from '@uikit';
import { ButtonGroup, Layout, RootLayout, Section, Stack } from '@layouts';
import { paycheckSource } from '@app/utils/format/income';
import { date } from '@app/utils/format/date';
import IncomeBar from '@app/charts/IncomeBar';
import { IncomeSourceEvents } from '../sources/IncomeSourceEvents';
import { PromptCard } from '@app/_common';
import { Prompts } from '@app/config';
import IncomeSourceRow from '@components/IncomeSourceRow';
import { useTier } from '@app/hooks';

const placeholder = [{}, {}, {}, {}, {}, {}, {}];

const options = {
  title: 'Income',
  largeTitle: true,
  drawBehindNav: true,
  bg: 'incomeLight',
  buttons: {
    account: true,
    activity: true,
  },
};

const IncomeComponent = () => {
  // useQuery<IncomeQueryData>(queries.INCOME, { returnPartialData: true }); // @todo use this instead
  const { locked } = useTier();

  const { $ } = useCopy('catch.basics');

  const {
    stats: { thisYear },
    incomeSources,
    incomeByMonth = [],
    waiting,
    refetch,
    paychecks,
    delayedPaychecks,
    needsBank,
  } = useDeprecatedQuery('income', {
    returnPartialData: true,
    variables: {
      currentYear,
      prevFrom: `${currentYear - 1}-01-01`,
      prevTo: `${currentYear - 1}-12-31`,
    },
  });

  const { transactions, waiting: loadingIncome } = useDeprecatedQuery('incomeTransactionsPaged', {
    variables: {
      page: { pageSize: 50, pageNumber: 1 },
      incomeAction: [
        'SKIPPED',
        'APPROVED',
        'EXECUTED',
        'SETTLED',
        'CANCELED',
        'FAILED',
        'HISTORY_SKIPPED',
      ],
    },
  });

  const hasAnyPendingIncome = paychecks?.length > 0;
  const hasAnyScheduledIncome = delayedPaychecks?.length > 0;
  const handleSourcePress = (sourceID) => open(routes.INCOME_SOURCE_DETAIL, { sourceID });

  return (
    <>
      <RootLayout
        title="Income"
        loading={waiting}
        color={options.bg}
        onRefresh={refetch}
        content={
          <Stack spacing="1">
            <Layout.Header
              title={$(thisYear?.grossIncome)}
              subtitle="made this year"
              titleSize="page"
              align="center"
              light
              loading={waiting || !thisYear}
            />
            <IncomeBar incomeByMonth={incomeByMonth} />
            <Stack spacing="0b">
              <IncomeSourceRow
                loading={waiting}
                handleSourcePress={handleSourcePress}
                incomeSources={
                  incomeSources?.filter(
                    (is) => is?.incomeAutomationRule?.automationType !== 'NEVER',
                  ) || placeholder
                }
              />
            </Stack>
          </Stack>
        }
        controls={
          <ButtonGroup>
            {!!waiting ? (
              <Button key="LOADING" alt loading={waiting} />
            ) : (
              [
                <Button
                  testID="income-breakdown"
                  accentColor="incomeLight"
                  key="STATS"
                  disabled={waiting}
                  onPress={() => open(routes.INCOME_STATS)}
                >
                  Breakdown
                </Button>,
                <Button
                  testID="manage-sources"
                  accentColor="income"
                  key="AUTO"
                  disabled={true || waiting || locked}
                  onPress={() => open(routes.MANAGE_INCOME_SOURCES)}
                >
                  Manage
                </Button>,
              ]
            )}
          </ButtonGroup>
        }
        extra={
          !!locked && (
            <PromptCard
              mobileSnap
              size="ALERT"
              {...Prompts?.ACCOUNT_LOCKED}
              overrideAction={() => exit()}
            />
          )
        }
      >
        <Stack mobileGap>
          {needsBank && !waiting && (
            <Section>
              <PromptCard wide {...Prompts.LINK_BANK} />
            </Section>
          )}
          {/* {!!hasAnyPendingIncome && (
            <Section title="New Income">
              <ZStack maxItems={10}>
                {paychecks?.map((paycheck, idx) => (
                  <ComplexRow
                    testID={`pending-${idx}`}
                    disabled={locked}
                    key={paycheck?.id}
                    asset={Asset.configureSource(paycheck.source)}
                    label={`${$(paycheck.amount)} from ${paycheckSource(paycheck.source)}`}
                    sublabel={date(paycheck.date, 'RELATIVE')}
                    interaction={'navigation'}
                    onPress={() =>
                      open(routes.PAYCHECK_INCOME, {
                        paycheckID: paycheck?.id,
                      })
                    }
                  />
                ))}
              </ZStack>
            </Section>
          )} */}

          {/* {!!hasAnyScheduledIncome && (
            <Section title="Scheduled">
              <ZStack>
                {delayedPaychecks?.map((paycheck, idx) => (
                  <ComplexRow
                    testID={`pacycheck-${idx}`}
                    interaction={'navigation'}
                    accessory={
                      <Text size="fp" weight="medium" color="income">
                        Edit
                      </Text>
                    }
                    key={paycheck?.id}
                    asset={Asset.configureSource(paycheck.source)}
                    label={`${$(paycheck.amount)} from ${paycheckSource(paycheck.source)}`}
                    sublabel={`Rolling in ${differenceInHours(
                      Date.parse(paycheck.autoDelayBatchTime),
                      Date.now(),
                    )}h`}
                    onPress={() =>
                      open(routes.PAYCHECK_INCOME, {
                        paycheckID: paycheck?.id,
                      })
                    }
                  />
                ))}
              </ZStack>
            </Section>
          )} */}

          <Section title="Activity">
            <IncomeSourceEvents events={transactions} querying={loadingIncome} />
          </Section>
        </Stack>
      </RootLayout>
    </>
  );
};

export const IncomeView = {
  name: 'INCOME',
  component: IncomeComponent,
  options,
};

export default IncomeView;
