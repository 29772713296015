import React, { useState } from 'react';
import shallow from 'zustand/shallow';

import { useAuth, useAuthEvents } from '@client/Auth';
import { navigate, routes, start } from '@navigate';
import { mutations, useDeprecatedMutation, useMutation } from '@data';
import { createLogger, Segment, useCopy, Env } from '@app/utils';
import { Button, Link, Text } from '@app/_ui-kit';
import { HealthPathway, OnboardingBenefit } from '@app/types';
import { getExitOptions } from './util/context';
import { getSignupContextOptions } from '@app/pages/auth/util/context';
import { AuthBlueprint } from '@app/blueprints';
import { Platform } from '@app/layouts';
import { RegistrationDisclosures } from '@app/_common';

const Log = createLogger('signup:view');

// needs to be shared with signin
interface RegisterComponentParams {
  initialVals?: any;
  benefit?: OnboardingBenefit;
  vertical?: OnboardingBenefit;
  r?: string;
  hr?: boolean;
  hid?: string;
  pathway?: HealthPathway;
  phone?: string;
  email?: string;
  zip?: string;
  partner?: string;
  next?: string;
  income?: string;
}

/**
 * does a lot of lifting on the signup params from the website or partner sites
 * @todo share types with signIn ...
 */
const RegisterComponent = ({
  initialVals,
  r: signupCode,
  hr: hideSignupCode,
  hid: healthID, // the PX session
  pathway,
  phone: initialPhone,
  email: initialEmail,
  zip,
  vertical,
  benefit,
  partner,
  income,
  next, // otherwise, this navigation prop gets sent on signup params and fails
  ...additionalSignupParams
}: RegisterComponentParams) => {
  // determine whether to use combo
  const allowBoth = Platform.OS === 'web';

  // handle for toggling email vs phone inputs
  const [aliasType, setAliasType] = useState('phone');
  const otherType = aliasType === 'phone' ? 'email' : 'phone';
  const toggle = () => setAliasType(otherType);

  const signupFields = [
    { name: 'alias', type: allowBoth ? 'alias' : aliasType, required: true },
    { name: 'password', type: 'password', passwordType: 'new', label: 'Password', required: true },
    {
      name: 'signupCode',
      type: 'text',
      label: 'Referral code',
      optional: false, // hides the optional flag (even though its optional)
      Toggle: ({ showField }) => (
        <Link size="fp" weight="medium" onPress={showField}>
          Have a referral code?
        </Link>
      ),
    },
  ];

  const { c } = useCopy('catch');

  const {
    confirmedExpressBenefit,
    usedPublicExplorer,
    requestedBenefit,
    signupContext,
    onboardingType,
    properBenefit,
  } = getSignupContextOptions({ benefit, healthID, pathway });

  const expressBenefitCopy = !!confirmedExpressBenefit && c(`benefits.${properBenefit}`);
  const signupParams = JSON.stringify(additionalSignupParams);

  /** mutations */
  const [updateUser] = useMutation(mutations.UPDATE_USER);
  // const [_upsertExplorer, { loading: upserting }] = useMutation(mutations.UPSERT_EXPLORER);
  const [_importHealth, { loading: importing }] = useDeprecatedMutation('importPublicExplorer', {});

  // needs to be shared with signin
  const importHealth = async ({ onCompleted }) => {
    if (!!usedPublicExplorer) {
      await _importHealth({
        variables: { id: healthID },
        onCompleted,
      });
    } else onCompleted();
  };

  /**
   * continue onboarding/express path
   */
  const handleExit = async () => {
    Log.debug('Choosing onboard path...');

    updateUser({
      variables: {
        user: {
          signupContext,
        },
      },
    });

    const exitOptions = getExitOptions({
      confirmedExpressBenefit,
      onboardingType,
    });

    Log.debug(`Starting onboarding: ${onboardingType}`);

    let onCompleted = () => {};

    if (exitOptions) {
      onCompleted = () => start('MAIN_TABS', exitOptions);
    } else {
      onCompleted = () => start('ONBOARDING_STACK', {});
    }

    if (
      onboardingType === 'HealthApplication' ||
      onboardingType === 'PremiumSlasher' ||
      onboardingType === 'HealthExplorer'
    ) {
      await importHealth({ onCompleted });
    } else {
      onCompleted();
    }
  };

  const { signUp, error, loading } = useAuth(
    (state) => ({
      temporaryAlias: state.temporaryCredentials.alias,
      loading: state.loading,
      step: state.authState,
      steps: state.states,
      signUp: state.signUp,
      confirmCode: state.confirmCode,
      error: state.error,
    }),
    shallow,
  );

  useAuthEvents({
    onPendingConfirmation: ({ temporaryAlias }) => {
      Segment.identifyUser(null, {
        alias: temporaryAlias,
        referral_link: Platform.OS === 'web' ? document.referrer : null,
        initial_user: true,
      });
      Segment.pageView();
      Segment.submitRegistration({ alias: temporaryAlias });
    },
    onSignedIn: ({ user }) => {
      if (!!user) {
        Segment.identifyUser(user.id, {
          email: user.email,
          phoneNumber: user.phoneNumber,
          firstname: user.givenName,
          coupon_code: user.referralCode || signupCode,
          partner,
        });
        Segment.userCreated(user.email || user.phoneNumber, signupContext);
      }
      handleExit();
    },
  });

  Log.debug({
    signupContext: {
      signupCode,
      hideSignupCode,
      healthID,
      pathway,
      initialPhone,
      initialEmail,
      zip,
      vertical,
      benefit,
      requestedBenefit,
      confirmedExpressBenefit,
      onboardingType,
      partner,
      ...additionalSignupParams,
    },
  });

  const initialValues = {
    alias: initialPhone || initialEmail,
    password: initialVals?.password || '',
    signupCode,
    zip,
    signupParams,
    signupContext,
    expressBenefit: confirmedExpressBenefit,
  };

  const handleAuto = () => {
    const name =
      localStorage.getItem('devAlias') ||
      prompt('email alias, por favor (email part before the @)');
    localStorage.setItem('devAlias', name);
    const email = `${name}+2fa_${Date.now()}@catch.co`;
    signUp({ ...initialValues, alias: email, password: '1Like$oup' });
  };

  return (
    <AuthBlueprint<{}>
      loading={false}
      submitting={loading}
      title={c('module.login.registerform.title')}
      subtitle={c('module.login.registerform.subtitle')}
      error={error}
      formConfig={{
        initialValues,
        fields: signupFields,
        onSubmit: signUp,
      }}
      actions={({ handleSubmit, disabled, submitting }) =>
        Platform.OS === 'web'
          ? [
              <Button
                key="submit"
                testID="submit"
                full
                disabled={disabled}
                loading={submitting}
                onPress={handleSubmit}
              >
                Create account
              </Button>,
              Env.isDevLike ? (
                <Button
                  testID="auto"
                  full
                  accentColor="brandLight"
                  disabled={submitting}
                  onPress={handleAuto}
                >
                  Just make me one
                </Button>
              ) : null,
            ]
          : [
              <Button
                key="toggle"
                disabled={submitting}
                testID="toggle"
                accentColor="brandLight"
                onPress={toggle}
              >
                Use {otherType}
              </Button>,
              <Button
                key="submit"
                disabled={submitting}
                testID="submit"
                full
                onPress={handleSubmit}
                loading={submitting}
              >
                Next
              </Button>,
            ]
      }
      postContent={
        <>
          <Platform web>
            <Text color="subtler" size="fp">
              {c('module.login.registerform.signinLabel')}{' '}
              <Link
                onPress={() =>
                  navigate(routes.LOGIN, {
                    hid: healthID,
                    benefit,
                    zip,
                    income,
                    pathway,
                    email: initialEmail,
                  })
                }
                size="fp"
                color="text"
                weight="regular"
              >
                {c('module.login.registerform.signinLink')}
              </Link>
            </Text>
          </Platform>
          <RegistrationDisclosures />
        </>
      }
    />
  );
};

export const RegisterView = {
  name: routes.REGISTER,
  component: RegisterComponent,
  guest: true,
  options: {
    title: 'Get started',
    largeTitle: true,
    drawBehindNav: true,
  },
};
