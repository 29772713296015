import React from 'react';
import { ViewStyle } from 'react-native';
import { Gradient, UIGradientName } from '@uikit';
import { useLayoutContext } from './LayoutContext';
import Page from './Page';
import { PageProps } from '@layouts/Page';

interface GradientPageProps extends PageProps {
  gradient?: UIGradientName;
  inherit?: boolean; // whether to inherit the gradient from layout
  style?: ViewStyle | Array<ViewStyle>;
  angle?: number;
}

const PageGradient: React.FC<GradientPageProps> = ({
  angle = 130,
  inherit,
  style,
  gradient,
  ...pageProps
}) => {
  const { layout, accentColor } = useLayoutContext();

  return (
    <Gradient
      angle={angle}
      gradient={inherit ? `${accentColor}Light` : gradient}
      color={pageProps.color}
      color2={layout}
      style={[style, { flex: 1 }]}
    >
      <Page {...pageProps} color="transparent" />
    </Gradient>
  );
};

export default PageGradient;
