import { routes } from '@navigate/routes';

export const healthRouteMap = {
  intro: routes.EDE_INTRO,
  'coverage-info': routes.EDE_COVERAGE_INFO,
  privacy: routes.EDE_PRIVACY, // @todo check if the user already did this
  household: routes.EDE_HOUSEHOLD,
  screening: routes.EDE_SCREENING,
  'pro-assistance': routes.EDE_PRO_ASSISTANCE,
  'id-info': routes.EDE_ID_INFO, // @todo check if the user already did this
  'id-questions': routes.EDE_IDENTITY, // @todo check if the user already did this
  'id-verify': routes.EDE_IDENTITY, // @todo check if the user already did this
  'id-denied': routes.EDE_IDENTITY, // @todo check if the user already did this
  identity: routes.EDE_IDENTITY,
  'application-import': routes.EDE_APPLICATION_IMPORT,
  'confirm-applicants': routes.EDE_CONFIRM_APPLICANTS,
  'member-info': routes.EDE_MEMBER_INFO,
  'member-questions': routes.EDE_MEMBER_QUESTIONS,
  'immigration-details': routes.EDE_IMMIGRATION_DETAILS,
  'medicaid-denial': routes.EDE_MEDICAID_DENIAL,
  'member-income-list': routes.EDE_MEMBER_INCOME_LIST,
  'member-deductions-list': routes.EDE_MEMBER_INCOME_DETAILS,
  'member-income-details': routes.EDE_MEMBER_INCOME_DETAILS,
  'member-income-confirm': routes.EDE_MEMBER_INCOME_CONFIRM,
  'member-income-discrepancy': routes.EDE_MEMBER_INCOME_DISCREPANCY,
  sep: routes.EDE_SEP,
  'outside-household': routes.EDE_OUTSIDE_HOUSEHOLD,
  'dependents-current-coverage': routes.EDE_DEPENDENTS_CURRENT_COVERAGE,
  'member-medical-bills': routes.EDE_MEMBER_MEDICAL_BILLS,
  'member-work-hours': routes.EDE_MEMBER_WORK_HOURS,
  'other-coverage': routes.EDE_OTHER_COVERAGE,
  review: routes.EDE_REVIEW,
  agreements: routes.EDE_AGREEMENTS,
  results: routes.EDE_RESULTS,
  dmi: routes.EDE_DMI,
  'split-eligibility': routes.EDE_SPLIT_ELIGIBILITY,
  plans: routes.EDE_PLANS,
  'aptc-agreement': routes.EDE_APTC_AGREEMENT,
  enroll: routes.EDE_ENROLL,
  success: routes.EDE_SUCCESS,
  'sep-out-of-date': routes.EDE_SEP_OUT_OF_DATE,
};
