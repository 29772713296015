import gql from 'graphql-tag';

export interface TaxesQueryData {
  viewer: {
    id: string;
  };
}

export const TaxesQuery = gql`
  query TaxesQuery {
    viewer {
      id
    }
  }
`;
