import React from 'react';
import { View, StyleSheet } from 'react-native';

import { Text } from '@uikit/components/Text';
import { Switch } from '@uikit/components/Switch';
import { useID } from '@uikit/hooks/useID';
import { useResponsive } from '@uikit/hooks/useResponsive';
import { useTheme } from '@uikit/hooks/useTheme';
import { Tooltip } from '@uikit/components/Tooltip';
import Info from '@uikit/icons/Info';

const SwitchField = ({
  input: fieldInput,
  meta: fieldMeta,
  value,
  onChange,
  error,
  focused,
  hideError,
  bare,
  label,
  sublabel,
  tooltip,
  labelFirst,
  style,
  disabled,
}) => {
  const fieldID = useID();
  const sublabelID = useID();
  const { isNative } = useResponsive();
  const input = fieldInput || { value, onChange };
  const meta = fieldMeta || { error, active: focused };
  const { theme } = useTheme();

  const hasError = (!hideError && meta.touched && meta.error) || !!error;

  const field = (
    <Switch
      data-name={input.name}
      accessibilityRole="switch"
      aria-describedby={sublabel ? sublabelID : undefined}
      onToggle={input.onChange}
      isOn={input.value}
      onFocus={input.onFocus}
      onBlur={input.onBlur}
      focused={meta.active}
      nativeID={fieldID}
      disabled={disabled}
    />
  );
  if (bare) {
    return field;
  }

  return (
    <View style={[styles.switchContainer, style]}>
      {!labelFirst && field}
      <View style={[styles.labelContainer, labelFirst && theme.leftGutter0]}>
        {!!label && (
          <Text
            size="p"
            weight="medium"
            accessibilityRole={isNative ? undefined : 'label'}
            htmlFor={fieldID}
          >
            {label}
          </Text>
        )}
        {!!sublabel && (
          <Text size="fp" nativeID={sublabelID}>
            {sublabel}
          </Text>
        )}
      </View>
      {!!tooltip && (
        <Tooltip label={tooltip} direction="top">
          <Info style={styles.tooltip} />
        </Tooltip>
      )}
      {labelFirst && field}
    </View>
  );
};

const styles = StyleSheet.create({
  switchContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    maxWidth: '100%',
  },
  labelContainer: {
    marginLeft: 16,
    flex: 1,
  },
  tooltip: {
    marginBottom: 12,
    marginLeft: 8,
  },
});

export default SwitchField;
