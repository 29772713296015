import React, { forwardRef } from 'react';
import { View, StyleSheet } from 'react-native';
import { useTheme } from '@uikit/hooks/useTheme';
import FieldLegend from './FieldLegend';
import InlineLabel from './InlineLabel';
import FieldFooter from './FieldFooter';

const FieldWrapper = forwardRef(
  (
    {
      row,
      style,
      label,
      sublabel,
      required,
      children,
      extralabel,
      grouped,
      subtext,
      tooltip,
      fullWidth,
      divider,
      fieldID,
      labelID,
      sublabelID,
      groupLabelID,
      groupSublabelID,
      role,
      error,
      errorItem,
      centerAlignError,
      startAlignTooltip,
      centerLabel,
      largeLabel,
      noGutter,
      disabled,
      horizontal,
      first,
    },
    ref,
  ) => {
    const { theme } = useTheme();
    const showLegend = Boolean(label || sublabel || tooltip);
    const showFooter = Boolean(subtext || error || errorItem);

    const fLegend = !horizontal ? (
      <FieldLegend
        label={label}
        required={required}
        fieldID={fieldID}
        labelID={labelID}
        tooltip={tooltip}
        sublabel={sublabel}
        sublabelID={sublabelID}
        extralabel={extralabel}
        fullWidth={fullWidth}
        centerLabel={centerLabel}
        largeLabel={largeLabel}
        startAlignTooltip={startAlignTooltip}
        tooltipOnly
      />
    ) : (
      <InlineLabel
        label={label}
        required={required}
        fieldID={fieldID}
        labelID={labelID}
        tooltip={tooltip}
        sublabel={sublabel}
        extralabel={extralabel}
        fullWidth={fullWidth}
        centerLabel={centerLabel}
        largeLabel={largeLabel}
        startAlignTooltip={startAlignTooltip}
        tooltipOnly
      />
    );

    return (
      <View
        style={[
          theme.fieldWrapper,
          !noGutter && theme.bottomGutter3,
          fullWidth && theme.fullWidth,
          grouped && theme.groupedField,
          grouped && first && theme.groupedFieldFirst,
          divider && theme.dividerHorizontal,
          divider && theme.dividerSpace,
          disabled && theme.disabled,
          { maxWidth: '100%' },
          extralabel && { flexDirection: 'column-reverse' },
          centerAlignError && theme.alignCenter,
          centerLabel && styles.centerWrapper,
          style,
          horizontal && theme.row,
          horizontal && theme.spaceBetween,
          horizontal && theme.wrap,
        ]}
        ref={ref}
      >
        {extralabel && <FieldFooter error={error} subtext={subtext} errorItem={errorItem} />}
        {showLegend && !extralabel && fLegend}
        <View
          accessibilityRole={role}
          aria-labelledby={groupLabelID}
          aria-describedby={groupSublabelID}
          style={[row && theme.row, fullWidth && theme.fullWidth]}
        >
          {children}
        </View>
        {showLegend && extralabel && fLegend}
        {showFooter && !extralabel && (
          <FieldFooter error={error} subtext={subtext} center={centerLabel} errorItem={errorItem} />
        )}
      </View>
    );
  },
);

const styles = StyleSheet.create({
  centerWrapper: {
    alignItems: 'center',
  },
});

FieldWrapper.displayName = 'FieldWrapper';
export default FieldWrapper;
