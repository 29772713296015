import React from 'react';

import { OnboardingFormBlueprint } from '@blueprints';
import { useQuery, queries, RetirementQueryData } from '@data';
import { RiskComfort } from '@types';
import { FormConfig } from '@app/forms';
import { fields } from '@app/config';
import { useCopy } from '@app/utils';
import { routes } from '@navigate';

interface RetirementComfortValues {
  riskComfort: RiskComfort;
}

/**
 * screen series required for retirement compliance,
 * specifically determination of objectives and sophistication.
 * ask Samuel Thayer for more
 * also @see RetirementRiskLevelView
 */
const RetirementRiskComfort = ({ handleNext, updateRetirement }) => {
  const { c } = useCopy('catch.module.retirement.RiskComfortView');

  const { loading, data } = useQuery<RetirementQueryData>(queries.RETIREMENT, {
    fetchPolicy: 'cache-first',
  });

  const formConfig: FormConfig<RetirementComfortValues> = {
    initialValues: { riskComfort: data?.viewer?.goal?.riskComfort },
    fields: [fields.RISK_COMFORT],
    onSubmit: (values) => {
      updateRetirement({ riskComfort: values.riskComfort });
      handleNext();
    },
  };

  return (
    <OnboardingFormBlueprint
      loading={loading}
      disabled={false}
      title={c('title')}
      formConfig={formConfig}
    />
  );
};

export const RetirementRiskComfortView = {
  name: routes.RETIREMENT_RISK_COMFORT,
  component: RetirementRiskComfort,
  options: OnboardingFormBlueprint.options,
};
