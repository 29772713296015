import { getRestrictions, queries, useDeprecatedQuery, useQuery } from '@data';
import { useMemo } from 'react';
import { useTier } from './useTier';

/**
 * - LOCKED: Account is locked
 * - REVIEW: Account is in review
 * - DENIED: Account has been denied
 * - NOT_SETUP: no DDA created/not ready
 * - NO_BALANCE_TO_WITHDRAW: No settled balance (withdraw)
 * - NO_RETIREMENT_WITHDRAW: ''
 * - Bank link bad (but actionable)
 * - Bank link bad (temproary)
 * - Can't withdraw from retirement
 */
export const useAllowTransfers = ({ benefit } = {}) => {
  const { hasReachedLimit } = useDeprecatedQuery('retirementLimit', { returnPartialData: true });
  const { goalsById, hasApprovedIncome, ...goals } = useDeprecatedQuery('transferGoals');
  const { primaryErrorCode, hasPrimaryBankLink, hasAnyBankLink, ...bankStatus } =
    useDeprecatedQuery('syncStatus');

  const { restrictions } = useTier();

  const {
    loading: l,
    waiting,
    locked,
    denied,
    review,
    hasDDA,
    availableBalance,
    pendingBalance,
    settledBalance,
    balance,
    ...account
  } = useDeprecatedQuery('status', { fetchPolicy: 'cache-first' });

  // Transfer should be disabled whenever anything is loading/
  const loading = l || bankStatus.loading || account.loading || goals.loading;

  // this is not currently used (but likely should be) – this check is currently done in transferGoals.js
  var all, withdraw, deposit, payment;

  if (locked) {
    all = { disabled: true, reason: 'LOCKED' };
  } else if (denied) {
    all = { disabled: true, reason: 'DENIED' };
  } else if (review) {
    all = { disabled: true, reason: 'REVIEW' };
  } else if (!hasDDA) {
    all = { disabled: true, reason: 'NOT_SETUP' };
  } else {
    all = { disabled: false }; // not used ...? ...?
  }

  if (benefit?.goalType === 'RETIREMENT') {
    withdraw = { disabled: true, reason: 'NO_RETIREMENT_WITHDRAW' };
  } else if (settledBalance === 0 && balance === 0) {
    withdraw = {
      disabled: true,
      reason: 'NO_BALANCE_TO_WITHDRAW',
    };
  } else if (settledBalance === 0 || benefit?.settledBalance === 0 || benefit?.totalBalance === 0) {
    withdraw = {
      disabled: true,
      reason: 'NO_BALANCE_TO_WITHDRAW',
    };
  } else if (!!benefit && !goalsById[benefit?.id]?.canWithdraw) {
    withdraw = {
      disabled: true,
      reason: 'NO_BALANCE_TO_WITHDRAW',
    };
  } else {
    withdraw = {
      disabled: false,
    };
  }

  if (!hasAnyBankLink) {
    deposit = { disabled: true, reason: 'NO_BANK_LINK' };
  } else if (!!primaryErrorCode) {
    deposit = { disabled: true, reason: 'BANKLINK_ACTIONABLE' };
  } else if (!hasApprovedIncome && !(settledBalance > 0)) {
    deposit = { disabled: true, reason: 'NEED_INCOME_APPROVAL_FIRST' };
  } else if (restrictions.SINGLE_DEPOSIT) {
    deposit = { disabled: true, reason: 'ACCOUNT_TIER' };
  } else if (restrictions.IRA_TRANSFER && benefit?.goalType === 'RETIREMENT') {
    deposit = { disabled: true, reason: 'ACCOUNT_TIER' };
  } else if (hasReachedLimit && benefit?.goalType === 'RETIREMENT') {
    deposit = {
      disabled: true,
      reason: 'REACHED_RETIREMENT_LIMIT',
    };
  }

  if (restrictions.TAX_PAYMENT) {
    payment = { disabled: true, reason: 'ACCOUNT_TIER' };
  }

  return {
    loading,
    waiting,
    disabled: {
      deposit: deposit,
      withdrawal: withdraw,
      payment: payment,
    },
    disabledDeposits: deposit,
    disabledWithdrawals: withdraw,
    disabledPayments: payment,
    hasAnyBankLink,
    locked,
    denied,
  };
};
