const noop = () => {};

export const getCredentials = noop;
export const setCredentials = noop;
export const hasCredentials = noop;

export const status = noop;
export const toggle = noop;
export const supported = noop;
export const reset = noop;
