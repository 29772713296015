import React from 'react';

import { stacks, Stack, StackComponent, StackDefinition } from '@navigate';

import { MeView } from './MeView';
import { UserInfoView } from './UserInfoView';
import { AccountSettingsView } from './AccountSettingsView';
import { LinkedAccountsView } from './LinkedAccountsView';
import { StatementsView } from './StatementsView';
import { ReferralView } from './ReferralView';

const config: StackDefinition = {
  stackName: stacks.ME_STACK,
  options: { accentColor: 'brand', presentation: 'pageSheet' },
  screens: [
    MeView,
    UserInfoView,
    AccountSettingsView,
    LinkedAccountsView,
    StatementsView,
    ReferralView,
  ], // @todo E-3445 typescript
};

const MeStack: StackComponent = () => {
  return <Stack stackName={config.stackName} screens={config.screens} options={config.options} />;
};

MeStack.config = config;

export default MeStack;
