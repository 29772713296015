import gql from 'graphql-tag';
import access from 'safe-access';
import { differenceInDays } from 'date-fns';

export const NUDGES = gql`
  query Nudges {
    viewer {
      id
      nudges {
        nudgeIdentifier
        isDismissed
        lastUpdated
      }
    }
  }
`;

const formatter = (data, options) => {
  const get = access(data);

  /**
   * generates a list of nudgeIdentifiers that have been dismissed
   * we will use this to filter out any dismissed nudges in NudgeSection
   */
  const nudges = get('viewer.nudges') || [];

  const dismissedNudges = [];
  const snoozedNudges = [];

  nudges.forEach((n) => {
    if (!!n.isDismissed) {
      dismissedNudges.push(n.nudgeIdentifier);
    }
  });

  // checks for which nudges should still be snoozed
  if (options.snooze) {
    nudges.forEach((n) => {
      const snoozeTime = differenceInDays(new Date(), new Date(n.lastUpdated));
      if (!!n.isDismissed && snoozeTime < options.snooze) {
        snoozedNudges.push(n.nudgeIdentifier);
      }
    });
  }

  return {
    nudges,
    dismissedNudges,
    snoozedNudges,
  };
};

const mock = {
  request: { query: NUDGES },
  result: {
    data: {
      viewer: {
        nudges: [
          {
            nudgeIdentifier: 'dismissed-nudge',
            isDismissed: true,
            lastUpdated: new Date(),
          },
          {
            nudgeIdentifier: 'undismissed-nudge',
            isDismissed: false,
            lastUpdated: null,
          },
        ],
      },
    },
  },
};

export default {
  document: NUDGES,
  formatter,
  mock,
};
