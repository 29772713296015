import React, { useState, useCallback, useMemo } from 'react';
import { calculateTaxes } from '@catch-co/tax-calculator';
import {
  useQuery,
  queries,
  useMutation,
  mutations,
  PayrollQueryData,
  UpsertGoalResponse,
  UpsertGoalVars,
  updates,
} from '@data';
import { OnboardingFormBlueprint } from '@blueprints';
import { routes, sheets, useSheet, open, close } from '@navigate';
import { Stack } from '@layouts';
import { FormConfig } from '@app/forms';
import { fields } from '@app/config';
import {
  deslug,
  useCopy,
  slugToGoalTypeName,
  goalTitles,
  DEFAULT_GOAL_CONTRIBUTION,
  MIN_GOAL_CONTRIBUTION,
  precisionRound,
} from '@app/utils';
import { GoalSlug } from '@types';
import { Text, Banner, Link, popToast } from '@uikit';
import { slugNameMap } from '@util/goals';

interface EstimatorProps {
  standalone?: boolean;
  slug: GoalSlug;
  handleNext: ({ slug }: { slug: GoalSlug }) => void;
}

export interface EstimatorValues {
  workState?: string;
  paycheckPercentage: number;

  // for validation
  totalPaycheckPercentage: number;
}

/**
 * Couple of things to note:
 * 1) This Estimator is used in the payroll onboarding section
 * 2) Tax uses a recommended withholding value that is calculated using a util called calculateTaxes
 * 3) Retirement has an initial value of 5%, and the other non-tax goals 1% and no recommended value
 */
const Estimator = ({ slug, handleNext, standalone }: EstimatorProps) => {
  //tax and non-tax goals have different configurations
  const TAXES = slug === 'taxes';
  const { c: estimatorCopy } = useCopy('catch.plans.EstimatorView');

  const copyPath =
    slug in slugNameMap
      ? `catch.payroll.withholding.${slug}`
      : 'catch.payroll.withholding.custom-goal';
  const { c, $ } = useCopy(copyPath);
  const { open: openSheet } = useSheet();

  const [exceedsMaxPct, setExceedsMaxPct] = useState<boolean>(false);

  const { loading, data } = useQuery<PayrollQueryData>(queries.PAYROLL, {
    variables: { slug },
    fetchPolicy: 'cache-first', // @todo: this should really be the default regardless
  });

  const [upsertGoal, { loading: submitting }] = useMutation<UpsertGoalResponse, UpsertGoalVars>(
    mutations.UPSERT_GOAL,
    {
      onCompleted: () => {
        if (standalone) {
          close();
        } else {
          handleNext({ slug });
        }
      },
    },
  );

  //allow users to edit income without going back a few screens
  const onEditIncome = useCallback(
    () =>
      open(routes.EDIT_USER_DETAILS, {
        slug,
        field: 'ESTIMATED_INCOME',
      }),
    [slug],
  );

  //keep track of total percentage across goals so we don't upsert over 100%
  //ideally we'd keep track of this as a local field in the cache
  const totalPaycheckPercentage = useMemo(
    () =>
      !!data?.viewer?.goals
        ? precisionRound(
            data?.viewer?.goals?.reduce((acc, goal) => {
              // only include ready goals, dont include the one we're adjusting atm
              return /ACTIVE|PAUSED|GOAL_MET/.test(goal.status) && goal.slug !== slug
                ? acc + goal?.paycheckPercentage
                : acc;
            }, 0),
            2,
          )
        : 0,
    [data?.viewer.goals],
  );

  //do a lookup on goals to check if we've already upserted this value
  const paycheckPercentage =
    data?.viewer?.goal?.paycheckPercentage ||
    data?.viewer?.goals?.find((goal) => goal?.slug === slug)?.paycheckPercentage;

  //needed to calculate withholding amount, note we only get to this page if the user is a 1099 employee
  const grossIncome = useMemo(() => {
    const { workType, estimated1099Income, estimatedW2Income } = data?.viewer.user || {};

    if (workType === 'WORK_TYPE_DIVERSIFIED') {
      return Number(estimatedW2Income) + Number(estimated1099Income);
    }

    if (workType === 'WORK_TYPE_W2') {
      return estimatedW2Income;
    }

    return estimated1099Income;
  }, [data?.viewer?.user]);

  const recommended = useMemo(() => {
    if (!loading && !!data?.viewer?.user?.workState && !!data?.viewer?.user?.filingStatus) {
      return calculateTaxes({
        state: data?.viewer?.user?.workState,
        grossIncome,
        spouseIncome: data?.viewer?.user?.spouseIncome,
        numDependents: data?.viewer?.user?.numTaxDependents,
        filingStatus: data?.viewer?.user?.filingStatus,
      });
    }
  }, [loading, data?.viewer?.user]);

  const initialValues = {
    workState: data?.viewer?.user?.workState,
    paycheckPercentage:
      paycheckPercentage ||
      (TAXES && recommended?.roundedPaycheckPercentageFederal) ||
      DEFAULT_GOAL_CONTRIBUTION[slug] ||
      MIN_GOAL_CONTRIBUTION,
    totalPaycheckPercentage,
  };

  const formConfig: FormConfig<EstimatorValues> = {
    initialValues,
    fields: [fields.PAYCHECK_PERCENTAGE],
    watch: [fields.PAYCHECK_PERCENTAGE.name],
    onSubmit: (values) => {
      const input = { slug, paycheckPercentage: precisionRound(values.paycheckPercentage, 2) };

      //check if we're upserting greater than 100%:
      if (totalPaycheckPercentage + input.paycheckPercentage > 1) {
        popToast({
          type: 'error',
          title: estimatorCopy('error.title'),
          msg: estimatorCopy('error.description'),
        });
      } else {
        upsertGoal({
          variables: {
            input,
          },
          optimisticResponse: {
            upsertGoal: {
              ...data?.viewer?.goal,
              ...input,
              id: data?.viewer?.goal?.id || '',
              status: data?.viewer?.goal?.status,
              __typename: slugToGoalTypeName(slug),
            },
          },
          update: updates.UPSERT_GOAL,
        });
      }
    },
  };

  const subtitles = useMemo(() => {
    return [
      c('subtitle'),
      TAXES
        ? c('description', {
            link: (
              <Link
                onPress={() =>
                  openSheet(sheets.TAX_BREAKDOWN_GUIDE, {
                    calculation: recommended,
                    shouldRunQuery: false,
                  })
                }
              >
                {c('descriptionLink')}
              </Link>
            ),
          })
        : undefined,
    ];
  }, [TAXES, recommended, c]);

  return (
    <OnboardingFormBlueprint
      showLabels
      lastScreen={standalone}
      loading={loading}
      disabled={submitting || exceedsMaxPct}
      title={c('title', { goal: goalTitles[slug] || deslug(slug) })}
      subtitles={subtitles}
      formConfig={formConfig}
    >
      <>
        {({ values }) => {
          const currTotalPct = totalPaycheckPercentage + values.reduce((acc, v) => acc + v, 0);

          if (currTotalPct >= 1 && exceedsMaxPct === false) {
            setExceedsMaxPct(true);
          } else if (currTotalPct < 1 && exceedsMaxPct === true) {
            setExceedsMaxPct(false);
          }

          return (
            <Stack topSpace spacing="4">
              {exceedsMaxPct && (
                <Banner bg="importantCalloutBg" title={estimatorCopy('warning.description')} />
              )}
              <Banner
                subtitle={c('banner', {
                  withholding: (
                    <Text size="fp">
                      {$(
                        values.reduce((acc, value) => acc + grossIncome * value, 0),
                        { whole: true },
                      )}
                    </Text>
                  ),
                  income: (
                    <Link size="fp" onPress={onEditIncome}>
                      {$(grossIncome, { whole: true })}
                    </Link>
                  ),
                })}
              />
            </Stack>
          );
        }}
      </>
    </OnboardingFormBlueprint>
  );
};

export const PayrollEstimatorView = {
  name: routes.SETUP_ESTIMATOR,
  component: Estimator,
  options: OnboardingFormBlueprint.options,
};

export const GoalEstimatorView = {
  name: routes.GOAL_ESTIMATOR,
  component: Estimator,
  options: { ...OnboardingFormBlueprint.options },
};
