/**
 * findLastIndex
 * - Given an array, finds the last index where the func returns true
 */
export const findLastIndex = (arr, func) => {
  // Uses find index to find the first instance in the reversed array
  const index = [...arr].reverse().findIndex(func);

  // Maintain returning -1 if not found, otherwise calculate index from length
  return index < 0 ? index : arr.length - 1 - index;
};

/**
 * Returns an array that conditionally has the item in it or not, based on a condition
 *
 * useful for building an array inline since we can do something like:
 * [item1, item2, item3, ...conditionalItem(check, item4)]
 */
export const conditionalItem = (condition, item) => {
  return condition ? [item] : [];
};
