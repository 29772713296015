import { boolToTri } from '@app/utils';

const normalizePhone = (phone) => {
  if (!phone || !(phone?.number && phone?.type)) return undefined;

  return {
    number: phone?.number,
    ext: phone?.ext,
    type: phone?.type,
  };
};

const normalizeAddress = (address) => ({
  street1: address?.street1 || undefined,
  street2: address?.street2 || undefined,
  city: address?.city || undefined,
  state: address?.state || undefined,
  zip: address?.zip || undefined,
});

export const getInitialValues = (data, isGA) => {
  const { applicant, applicationPhase, coverageState } = data?.viewerTwo?.health?.application || {};
  const user = data?.viewer?.user || {};

  // home address should be populated UNLESS it doesn't match the coverage state
  let homeAddress = applicant?.homeAddress || user?.legalAddress;
  if (!!coverageState && coverageState !== homeAddress?.state) {
    homeAddress = { state: coverageState };
  }

  return {
    id: applicant?.id,
    isGA,
    applicationPhase,
    coverageState,
    writtenLanguageType: applicant?.writtenLanguageType || 'ENGLISH',
    spokenLanguageType: applicant?.spokenLanguageType || 'ENGLISH',
    isRequestingCoverage: boolToTri(applicant?.isRequestingCoverage) ?? 'YES',
    contactMethod: applicant?.contactMethod ?? 'EMAIL',
    digitalContactMethod: applicant?.digitalContactMethod ?? (isGA ? "EMAIL" : undefined),

    givenName: applicant?.givenName || user?.givenName || user?.nickname,
    middleName: applicant?.middleName || user?.middleName,
    familyName: applicant?.familyName || user?.familyName,
    nameSuffix: applicant?.nameSuffix,

    phone: {
      ...applicant?.phone,
      number: applicant?.phone?.number || user?.phoneNumber,
    },

    secondaryPhone: applicant?.secondaryPhone,

    email: applicant?.email || user?.email,

    homeAddress,
    mailingAddress: applicant?.mailingAddress,
    mailingAddressOption:
      homeAddress && JSON.stringify(homeAddress) === JSON.stringify(applicant?.mailingAddress)
        ? true
        : null,

    dob: applicant?.dob || user?.dob,
    sex: applicant?.sex,
    ssn: applicant?.ssn,
  };
};

export const formatPayload = ({ applicationID, values }) => {
  return {
    variables: {
      input: {
        applicationID,
        applicationMembersInput: [
          {
            id: values?.id,
            spokenLanguageType: values?.spokenLanguageType || undefined,
            writtenLanguageType: values?.writtenLanguageType || undefined,
            isRequestingCoverage: values?.isRequestingCoverage || undefined,
            familyName: values?.familyName || undefined,
            middleName: values?.middleName || undefined,
            givenName: values?.givenName || undefined,
            nameSuffix: values?.nameSuffix || undefined,
            contactMethod: values?.contactMethod || undefined,
            dob: values?.dob || undefined,
            sex: values?.sex || undefined,
            email: values?.email || undefined,
            ssn: values?.ssn || undefined,
            phone: normalizePhone(values?.phone),
            secondaryPhone: normalizePhone(values?.secondaryPhone),
            homeAddress: normalizeAddress(values?.homeAddress),
            mailingAddress: normalizeAddress(
              values?.mailingAddressOption ? values?.homeAddress : values?.mailingAddress,
            ),
          },
        ],
      },
    },
  };
};
