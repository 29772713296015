import React, { useCallback } from 'react';

import { Layout, Split } from '@layouts';
import { useCopy, constants } from '@app/utils';
import { mutations, useMutation } from '@data';
import IneligibleForMedicaidForm from '@app/forms_old/IneligibleForMedicaidForm';

const MedicaidDetermination = ({
  stateDeterminesMedicaidEligibility,
  ineligibleForMedicaid,
  state,
  applicationID,
}) => {
  const { c } = useCopy('catch.ede.EligibilityResults');

  const [upsertHealthApplicants] = useMutation(mutations.UPSERT_APPLICATION_MEMBERS);
  const handleMedicaidDeterminationRequest = useCallback((values, dispatch, { upsert }) => {
    const input = Object.keys(values)
      .filter((key) => !!values[key])
      .map((dep) => ({ id: dep, isRequestingMedicaidDetermination: 'YES' }));
    upsert({ variables: { id: applicationID, input } });
  }, []);

  return (
    <Split>
      <Layout.Header
        title={c('fullMedicaidTitle')}
        subtitles={[
          c('fullMedicaidText', {
            agencyName: constants.medicaidCHIPNames[state]['medicaid'],
          }),
          c('fullMedicaidQuestion', {
            agencyName: constants.medicaidCHIPNames[state]['medicaid'],
            stateDeterminesMedicaidEligibility,
          }),
        ]}
      />

      {/* according to correspondence between our engineering team and CMS, we need to add the medicaid determination identifiers to the dependents and call 'ensure' again */}
      <IneligibleForMedicaidForm
        members={ineligibleForMedicaid}
        onSubmit={handleMedicaidDeterminationRequest}
        upsert={upsertHealthApplicants}
      />
    </Split>
  );
};

export default MedicaidDetermination;
