import {
  GoalSlug,
  GoalStatus,
  GoalType,
  IncomeTransactionSource,
  Address,
  AgreementSet,
} from '@types';
import gql from 'graphql-tag';

export interface AccountSetupQueryData {
  viewer: {
    id: string;
    user: {
      id: string;
      email?: string;
      phoneNumber?: string;
      nickname?: string;
      givenName?: string;
      familyName?: string;
      ssn?: string;
      legalAddress?: Address;
      dob?: string;
    };
    isAliasMatchAndVerified: {
      emailMatchAndVerified?: boolean;
      phoneMatchAndVerified?: boolean;
    };
    goals: {
      id: string;
      slug: GoalSlug;
      type: GoalType;
      status: GoalStatus;
      paycheckPercentage: number;
    };
    incomeSources: Array<IncomeTransactionSource>;
  };
  viewerTwo: {
    id;
    agreements: AgreementSet;
  };
}

export const AccountSetupQuery = gql`
  query AccountSetupQuery {
    viewerTwo {
      id
      agreements {
        id
        acknowledgedUnitAccountDisclosures
        authorizedAboundEftpsEnrollment
      }
    }
    viewer {
      id
      user {
        id
        email
        phoneNumber
        nickname
        givenName
        familyName
        ssn
        dob
        legalAddress {
          street1
          street2
          city
          state
          zip
          country
        }
      }
      isAliasMatchAndVerified {
        emailMatchAndVerified
        phoneMatchAndVerified
      }
      goals {
        id
        slug
        type: goalType
        status
        paycheckPercentage
        ... on CustomGoal {
          icon
          name
        }
      }
      incomeSources {
        id
        ruleID
        text
        canBeRule
        incomeAutomationRule {
          id
          automationType
        }
        taggedSource {
          id
          name
          logo {
            url
          }
        }
      }
    }
  }
`;
