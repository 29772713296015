import React from 'react';

import { navigate } from '@navigate';
import { SetupIntro } from '@common';
import { Link } from '@app/_ui-kit';
import { useCopy } from '@app/utils';
import { useDeprecatedMutation } from '@app/data';

const TaxPaymentIntro = () => {
  const { c } = useCopy('catch.agreements');

  const [upsert] = useDeprecatedMutation('upsertLegal', {
    onCompleted: () => navigate('TAX_PAYMENT_AMOUNT'),
    variables: {
      input: {
        authorizedAboundEftpsEnrollment: true,
      },
    },
  });

  return (
    <SetupIntro
      color="taxes"
      type="TAX_PAYMENT"
      onNext={upsert}
      fine={c('eftps', {
        link: (
          <Link href={'https://catch.co/legal/eftps'} target="_blank" inline color="taxes">
            the EFTPS and tax payments agreement
          </Link>
        ),
      })}
    />
  );
};

export const TaxPaymentIntroView = {
  name: 'TAX_PAYMENT_INTRO',
  component: TaxPaymentIntro,
  options: SetupIntro.options,
};
