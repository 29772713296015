import React from 'react';

import { View } from 'react-native';
import { reduxForm, Field } from 'redux-form';

import { Button, useTheme } from '@uikit';
import { CheckboxField } from '@app/forms_old/inputs';
import { useCopy } from '@app/utils';
import { getFullName } from '@app/utils';

const PREFIX = 'catch.ede.EligibilityResults';

const IneligibleForMedicaidForm = ({ handleSubmit, members }) => {
  const { theme } = useTheme();
  const { c } = useCopy(PREFIX);

  return (
    <>
      <View style={theme.bottomGutter1}>
        {members?.map((member, idx) => (
          <View style={theme.bottomGutter0b} key={`inel-${idx}`}>
            <Field
              bare
              component={CheckboxField}
              name={member.id}
              id={member.id}
              options={[
                {
                  label: getFullName(member),
                },
              ]}
              values={{ YES: true, NO: false }}
            />
          </View>
        ))}
      </View>
      <Button testID="send-to-medicaid" alt gutter="bottom" onPress={handleSubmit}>
        {c('sendToMedicaidButton')}
      </Button>
    </>
  );
};

const withRedux = reduxForm({
  form: 'ineligibleForMedicaidForm',
  enableReinitialize: true,
});

export default withRedux(IneligibleForMedicaidForm);
