import gql from 'graphql-tag';
import access from 'safe-access';

import {
  formatSortedPlans,
  getTotalIncome,
  getHouseholdIncome,
  formatHealthPlan,
  currentYear,
  nextYear,
} from '@app/utils';

import { EXPLORER_DATA } from '../fragments';

const EXPLORER = gql`
  query Explorer {
    viewer {
      id
      user {
        id
        dob
        workType
        filingStatus
        legalAddress {
          zip
        }
      }
      income {
        estimatedW2Income
        estimated1099Income
      }
      spouseIncome
      health {
        healthExplorerData {
          id
          ...ExplorerData
        }
      }
    }
  }
  ${EXPLORER_DATA}
`;

const formatter = (data, options) => {
  const get = access(data?.viewer);
  const explorer = get('health.healthExplorerData') || {};
  const user = get('user') || {};

  // @todo: this should be handled on the platform side
  const plans = explorer.healthPlans;

  const applicants = explorer.dependents?.length > 0 ? explorer.dependents : [{ relation: 'SELF' }];

  const scoredPlans = formatSortedPlans({
    sortedPlans: plans || [],
  });

  const eligibility = explorer.marketplaceEligibility?.[0];

  // computed household income
  const income = getHouseholdIncome({
    estimatedIncome: getTotalIncome({
      estimatedW2Income: get('income.estimatedW2Income'),
      estimated1099Income: get('income.estimated1099Income'),
      workType: get('user.workType'),
    }),
    spouseIncome: get('spouseIncome'),
    filingStatus: get('user.filingStatus'),
  });

  // only show/format the plan if it is actually defined
  const hasPlan = !!explorer.healthPlanDetails?.planId;
  const selectedPlan = hasPlan
    ? formatHealthPlan({ healthPlan: explorer.healthPlanDetails })
    : null;

  const eligibilityByMember = explorer.marketplaceEligibility?.map((e, i) => ({
    ...applicants?.[i],
    ...e,
  }));

  return {
    canCreateEnrollment: explorer.canCreateEnrollment,
    zipcode: explorer.zipcode || user?.legalAddress?.zip,
    countyfips: explorer.countyfips,
    state: explorer.state,
    coverageYear: explorer.coverageYear,
    income: explorer.income || income,
    pathwayType: explorer.pathwayType,
    possibleYears: [currentYear, nextYear] /** @todo: this should populated by P-1376 */,
    applicants,
    scoredPlans,
    selectedPlan,
    numPlans: scoredPlans?.plans.length,
    aptc: eligibility?.aptc,
    csr: eligibility?.csr,
    marketplaceEligibility: explorer.marketplaceEligibility,
    eligibility,
    eligibilityByMember,
    explorerID: explorer.id,
  };
};

export default {
  document: EXPLORER,
  formatter,
};
