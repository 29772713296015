import React, { useCallback, useMemo } from 'react';

import { useQuery, TaxesQueryData, queries, useDeprecatedQuery } from '@data';
import { ButtonGroup, Header, Layout, RootLayout, Section, Stack } from '@layouts';
import { Button, CatchTheme, ComplexRow, LineItem, Loading, ZStack } from '@app/_ui-kit';
import { useNudge, useSnooze, useTaxGoal, useTaxPaymentPeriod, useTier } from '@app/hooks';
import { goalIsSetup, useCopy, Env } from '@app/utils';
import { Activity, PromptCard } from '@common';
import { Prompts } from '@app/config';
import { TaxQuarterDonut } from '@app/charts/TaxQuarterDonut';
import { open, routes, ScreenDefinition, sheets, useSheet } from '@app/navigate';
import { groupBy } from '@app/utils/misc';
import { TaxPaymentHistoryContent } from '@pages/taxes/taxes/TaxPaymentHistoryView';

const options = {
  drawBehindNav: true,
  bg: 'taxesLight',
  title: 'Taxes',
  largeTitle: true,
  buttons: {
    sheetMenu: sheets.TAXES_MENU,
    account: true,
    activity: true,
  },
};

const TaxesComponent = () => {
  useQuery<TaxesQueryData>(queries.TAXES); // @todo use this instead

  const { c: basics, p, $ } = useCopy('catch.basics');
  const { c: c2 } = useCopy('catch.PlanSummary');

  const { restrictions, locked } = useTier();

  const { taxPayments } = useDeprecatedQuery('taxGoal', {
    returnPartialData: true,
    context: { failQuietly: true },
  });

  const {
    existingTaxGoal: taxGoal,
    loading,
    waiting,
    refetch,
    upsertTaxGoal,
    needsEftps,
  } = useTaxGoal();
  const { isDismissed: autoTaxNudgeIsDismissed } = useNudge('ACTIVATE_AUTO_TAX');

  const { quarter, quarterProgress, dueDate } = useTaxPaymentPeriod();
  const { resume } = useSnooze(taxGoal);

  const isSetup = useMemo(() => goalIsSetup(taxGoal), [taxGoal]);
  const isPaused = useMemo(() => /PAUSED/.test(taxGoal?.status), [taxGoal]);
  const slug = 'taxes';

  const editPercentage = () => open(routes.GOAL_EDIT, { slug: 'taxes' });
  const { open: openSheet, closeAll: closeAllSheets } = useSheet();

  const allTaxPmts = taxPayments?.filter((p) => p.status !== 'CANCELED');
  const taxPaymentsByYear = useMemo(() => {
    return groupBy(allTaxPmts, 'year');
  }, [taxPayments]);
  const showAutopayPrompt =
    (!taxPayments || taxPayments?.length === 0) && !taxGoal?.autoTax && !autoTaxNudgeIsDismissed;
  const showEftpsPrompt = !!taxGoal && !!needsEftps && !showAutopayPrompt;
  const madeTaxPayment = taxPayments?.length > 0 && Object.keys(taxPaymentsByYear).length > 0;

  /* @todo handle first time, disabled, etc */
  const handleToggle = useCallback(
    (autoTaxToggle) => {
      upsertTaxGoal({
        skip: slug,
        variables: {
          input: {
            slug,
            autoTax: autoTaxToggle,
          },
        },
      });
    },
    [upsertTaxGoal, slug],
  );

  const goToGoalIntro = useCallback(() => {
    open(routes.GOAL_INTRO, { slug });
    closeAllSheets();
  }, [closeAllSheets]);

  const sheetConfig = useMemo(() => {
    return isSetup ? { name: sheets.TAXES_MENU } : undefined;
  }, [taxGoal]);

  const HistoryZStack: React.FC = ({ children }) => <ZStack maxItems={4}>{children}</ZStack>;

  return (
    <>
      <RootLayout
        title={options?.title}
        loading={waiting}
        color={options.bg}
        onRefresh={refetch}
        refreshing={loading}
        sheetConfig={sheetConfig}
        fixAndroidMarginBehindNav
        content={
          <Stack style={{ justifyContent: 'center', flex: 1 }}>
            <TaxQuarterDonut
              gauge
              quarter={quarter}
              percent={quarterProgress}
              size={CatchTheme.constants.rootHeaderHeight / 2}
            />
            <Header
              title={false && taxGoal?.autoTax ? 'Payment scheduled' : `${dueDate}`}
              subtitle={false && taxGoal?.autoTax ? `for ${dueDate}` : 'Next payment due'}
              align="center"
              light
              style={{ marginTop: -24 }}
            />
          </Stack>
        }
        controls={
          isSetup && (
            <ButtonGroup
              loading={waiting}
              textRight={$(taxGoal?.totalBalance)}
              textLeft={
                c2('goalStatusLabel', {
                  status: taxGoal?.status,
                  rate: p(taxGoal?.paycheckPercentage || 0),
                }) || '0%'
              }
            >
              <Button
                inherit
                full
                testID="plan-summary-flag-button"
                onPress={isPaused ? resume : editPercentage}
                alt
                disabled={true || locked}
              >
                {basics('edit')}
              </Button>

              <Button
                inherit
                testID="plan-summary-transfer-button"
                full
                haptics="LIGHT"
                disabled={true || locked}
                onPress={() =>
                  openSheet(sheets.TRANSFER_OPTIONS, {
                    goal: taxGoal,
                    goalType: taxGoal.goalType,
                  })
                }
              >
                Withdraw
              </Button>
            </ButtonGroup>
          )
        }
      >
        {waiting ? (
          <Loading />
        ) : (
          isSetup && (
            <Stack separatorComponent mobileGap>
              <Layout bottomSpace>
                {(taxGoal?.autoTax || madeTaxPayment) && (
                  <>
                    <TaxPaymentHistoryContent
                      onlyShowRecent
                      years={taxPaymentsByYear}
                      allPayments={allTaxPmts}
                      taxGoal={taxGoal}
                      SectionComponent={HistoryZStack}
                    />

                    {madeTaxPayment && (
                      <Button
                        alt
                        accentColor="taxesLight"
                        small
                        onPress={() => open(routes.TAX_PAYMENT_HISTORY)}
                      >
                        View all
                      </Button>
                    )}
                  </>
                )}
              </Layout>

              <Section title="Activity">
                <Stack spacing="2" separatorComponent>
                  {taxGoal?.pendingBalance > 0 && (
                    <LineItem left="Pending" right={$(taxGoal?.pendingBalance)} />
                  )}
                  <Activity goalID={taxGoal?.id} accentColor="taxesLight" />
                </Stack>
              </Section>
            </Stack>
          )
        )}
      </RootLayout>
    </>
  );
};

export const TaxesView: ScreenDefinition = {
  name: 'TAXES',
  component: TaxesComponent,
  options,
};

export default TaxesView;
