import React from 'react';
import { stacks, Stack, StackComponent, StackDefinition } from '@navigate';
import { TaxPaymentIntroView } from './payments/TaxPaymentIntroView';
import { TaxPaymentAmountView } from './payments/TaxPaymentAmountView';
import { TaxPaymentConfirmView } from './payments/TaxPaymentConfirmView';
import { EftpsAuthView } from './payments/EftpsAuth';
import { AutoTaxPaymentIntroView } from './payments/AutoTaxPaymentIntroView';

import { useDeprecatedQuery } from '@app/data';

const config: StackDefinition = {
  stackName: stacks.TAX_PAYMENT_STACK,
  options: {
    layout: 'sheet',
    accentColor: 'taxes',
  },
  screens: [
    EftpsAuthView,
    TaxPaymentIntroView,
    TaxPaymentAmountView,
    TaxPaymentConfirmView,
    AutoTaxPaymentIntroView,
  ], // @todo E-3445 typescript
};

const TaxPaymentStack: StackComponent = () => {
  const { taxGoal } = useDeprecatedQuery('taxGoal');

  return (
    <Stack
      stackName={config.stackName}
      screens={config.screens}
      options={config.options}
      data={{ hasAuto: taxGoal?.autoTax }}
    />
  );
};

TaxPaymentStack.config = config;
export default TaxPaymentStack;
