import gql from 'graphql-tag';
import { EMAIL_NOTIFICATIONS } from '../queries/emailNotifications';
import { TEXT_NOTIFICATIONS } from '../queries/textNotifications';

const UPDATE_NOTIFICATION_SETTINGS = gql`
  mutation UpdateNotificationSettings($input: UpdateNotificationSettingsInput!) {
    updateNotificationSettings(input: $input) {
      email {
        myBenefits
        recommendedBenefits
        featureAnnouncements
      }
      text {
        healthInsurance
      }
    }
  }
`;

function updateCache(cache, { data: { updateNotificationSettings } }) {
  try {
    const { viewer } = cache.readQuery({
      query: EMAIL_NOTIFICATIONS,
    });
    cache.writeQuery({
      query: EMAIL_NOTIFICATIONS,
      data: {
        viewer: {
          ...viewer,
          notificationSettings: {
            ...viewer.notificationSettings,
            email: {
              ...viewer.notificationSettings.email,
              ...updateNotificationSettings.email,
            },
          },
        },
      },
    });
    const { viewer: textViewer } = cache.readQuery({
      query: TEXT_NOTIFICATIONS,
    });
    cache.writeQuery({
      query: TEXT_NOTIFICATIONS,
      data: {
        viewer: {
          notificationSettings: {
            ...textViewer.notificationSettings,
            text: {
              ...textViewer.notificationSettings.text,
              healthInsurance: {
                ...textViewer.notificationSettings.text.healthInsurance,
                ...updateNotificationSettings.text.healthInsurance,
              },
            },
          },
        },
      },
    });
  } catch (e) {}
}

export default {
  document: UPDATE_NOTIFICATION_SETTINGS,
  updateCache,
};
