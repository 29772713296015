import { gql } from '@apollo/client';

export default gql`
  query AnnualReview($toDate: Date, $fromDate: Date, $year: Int!) {
    viewerTwo {
      id
      incomeForYear(year: $year) {
        month
        totalIncome
      }
      documents {
        taxDocuments {
          id
          date
          providerId
          createdOn
          ext
          documentType
          type
          detail
          month
          planType
        }
      }
      taxPayments {
        id
        createdOn
        amount
        timeframe
        isAuto
        status
        jurisdiction
        document {
          id
          date
          providerId
          createdOn
          ext
          documentType
          type
          planType
        }
      }
      goalContributionsForYear(year: $year) {
        goalType
        goalID
        goalSlug
        goalName
        data {
          month
          year
          totalContributionsValue
        }
      }
      health {
        policies(input: { coverageYearNumber: [$year] }) {
          id
          endDate
          startDate
          aptcAmount
          premium
          premiumEffective
          productType
          subscriberID
          providerPlan {
            id
            name
            issuer {
              id
              name
            }
          }
        }
      }
      accounts {
        accountNumber
        id
        providerType
        goalType
        goalId
      }
    }
    viewer {
      id
      income {
        estimated1099Income
        estimatedW2Income
      }
      retirementGoal: goal(slug: "retirement") {
        id
        slug
        ... on RetirementGoal {
          totalContributions(year: $year)
        }
      }
      incomeSources(input: { period: CUSTOM, toDate: $toDate, fromDate: $fromDate }) {
        id
        text
        taggedSource {
          id
          name
          logo {
            url
          }
        }
        incomeAutomationRule {
          automationType
          id
          workType
        }
      }
      planHistorySummary(period: CUSTOM, toDate: $toDate, fromDate: $fromDate) {
        grossIncome
        grossIncome1099
        grossIncomeW2
        totalSavings
      }
      wealthAccounts {
        id
        accountID
        folioID
        accountType
      }
    }
  }
`;
