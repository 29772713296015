import { generatePath } from 'react-router';
import queryString from 'query-string';
import { ensnake } from '@app/utils';
import { paths, Route } from '../routes';
import { history } from '../history';
import { store } from '../store/store';

/**
 * Navigates as push
 * Uses the generatePath helper to
 * convert /plan/:slug/intro -> /plan/taxes/intro when slug is defined on data
 */
export const push = (screenName: Route, data: object) => {
  // @ts-ignore
  const parsed = queryString.parse(history.location.search);

  // updated state joins any URL params, existing state, and incoming data
  const updatedState = {
    // @ts-ignore
    ...store.getState().matchParams,
    // @ts-ignore
    ...history.location.state,
    ...data,
  };

  // if there is already a flow open, continue in flow
  if (parsed.flow) {
    // @ts-ignore
    const path = `${history.location.pathname}?flow=${ensnake(screenName)}`;
    // @ts-ignore
    history.push(path, { ...updatedState, canGoBack: true });
  } else {
    // otherwise, push the new url to the stack
    const path = generatePath(paths[screenName], updatedState);
    // @ts-ignore
    history.push(path, updatedState);
  }
};
