import React from 'react';
import { AmountType } from '@types';
import { FormLabel } from '@uikit';
import { TextField } from '@app/forms_old/inputs';
import { InputProps } from '../types';
import { copy } from '@app/utils';

interface TextInputProps<T> extends InputProps<T> {
  amountType: 'integer' | 'decimal';
}

// shows a formatted value like $ 25,000
export const formatAmount = (val, amountType: AmountType, allowNegative?: boolean) => {
  if (allowNegative && val === '-') {
    return '- $';
  }

  if (val === 0) {
    return '$ 0';
  }

  if (val) {
    const isNegative = val < 0;
    const absoluteValue = Math.abs(val);
    const sign = isNegative ? '- $' : '$';

    if (amountType === 'decimal') {
      return `${sign} ${copy.num(absoluteValue)}`;
    }

    const num = parseInt(`${absoluteValue}`.replace(/\D+/g, ''), 10);
    return `${sign} ${num.toLocaleString('en-US')}`;
  }

  return val || '';
};

// stores the actual value as a base-10 integer
export const parseAmount = (val, amountType: AmountType, allowNegative?: boolean) => {
  const multiplier = val.includes('-') ? -1 : 1;

  if (allowNegative && val === '-') {
    return '-';
  }

  const digits = val?.replace(/\D+/g, '');

  if (digits) {
    if (amountType === 'decimal') {
      return parseFloat(`${(digits / 100) * multiplier}`);
    }

    return parseInt(digits, 10) * multiplier;
  } else {
    return '';
  }
};

const AmountInput = <T extends any>({
  testID,
  autoFocus,
  amountType,
  name,
  value,
  label,
  sublabel,
  help,
  optional,
  disabled,
  onChange,
  onBlur,
  onKeyPress,
  error,
  subtext,
  large,
}: TextInputProps<T>) => {
  return (
    <>
      <FormLabel
        testID={testID}
        label={label}
        sublabel={sublabel}
        help={help}
        optional={optional}
      />
      <TextField
        testID={testID}
        autoFocus={autoFocus}
        keyboard="money"
        value={formatAmount(value, amountType, true)}
        onChangeText={(val) => onChange(parseAmount(val, amountType, true))}
        onBlur={onBlur}
        onKeyPress={onKeyPress}
        disabled={disabled}
        placeholder="$"
        error={error}
        fullWidth
        subtext={subtext}
        large={large}
        center={large}
      />
    </>
  );
};

export default AmountInput;
