import React from 'react';
import { View } from 'react-native';
import { Corners } from '@types';

import { useTheme } from '../hooks/useTheme';

interface Props {
  focused?: boolean;
  corners?: Corners;
}

/**
 * A focus around any elementt
 * - posititons an absolute border around an item (with 2pt width + 2pt spacing)
 * - visible when focused is set to true
 *
 * @todo: focus radius should be +2 or so to account for corners
 */
export const Focus = ({ focused, corners }: Props) => {
  const { theme } = useTheme();

  return <View style={[theme.buttonFocused, focused && theme.elementFocused]} />;
};

export default Focus;
