import { Tier, TierPermissionMap } from '@app/types';

export const TIER_RESTRICTIONS: Record<Tier, TierPermissionMap> = {
  TIER_0: {
    MULTIPLE_BANK_LINKS: true,
    IRA_TRANSFER: true,
    TAX_PAYMENT: true,
    SINGLE_DEPOSIT: true,
    TRANSFER_LIMIT: 2000,
  },
  TIER_1: {
    MULTIPLE_BANK_LINKS: true,
    IRA_TRANSFER: false,
    TAX_PAYMENT: false,
    SINGLE_DEPOSIT: false,
    TRANSFER_LIMIT: 5000,
  },
  TIER_2: {
    MULTIPLE_BANK_LINKS: false,
    IRA_TRANSFER: false,
    TAX_PAYMENT: false,
    SINGLE_DEPOSIT: false,
    TRANSFER_LIMIT: 10000,
  },
  TIER_3: {
    MULTIPLE_BANK_LINKS: false,
    IRA_TRANSFER: false,
    TAX_PAYMENT: false,
    SINGLE_DEPOSIT: false,
    TRANSFER_LIMIT: 50000,
  },
};
