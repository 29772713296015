import React, { useMemo } from 'react';
import { routes, exit } from '@navigate';
import { HealthRenewStackScreenProps } from '../HealthRenewStack';
import { SetupIntro } from '@app/_common';
import { useQuery, queries, HealthRenewQueryData } from '@data';
import { hasAgreedToPrivacy, Segment } from '@app/utils';

/**
 * Quick Renew Intro
 *
 * @todo check for an existing 2023 application
 * @todo lookup previous apps to import as 2023
 * @todo check for 2023 BAR app to use instead
 * @todo must be properly ID proofed
 *
 * pick the plan
 * make sure agreements are done
 * make sure id proof is done
 * do any part of the application if possible
 * provide an easy resume point
 */
const HealthRenewIntro = ({ oeCoverageYear, handleNext }: HealthRenewStackScreenProps) => {
  const { loading, data } = useQuery<HealthRenewQueryData>(queries.HEALTH_RENEW, {
    variables: {
      coverageYear: oeCoverageYear,
      renewalYear: oeCoverageYear ? parseInt(oeCoverageYear) - 1 : undefined,
    },
    skip: !oeCoverageYear,
  });

  const {
    hasSelectedPlan,
    hasAgreements,
    hasIDProof,
    planName,
    hasStartedApplication,
    hasConnectedCoverage,
    isWindowShopping,
    isOpenEnrollment,
    hasSubmittedApplication,
  } = useMemo(() => {
    const selectedPlan = data?.viewerTwo?.healthExplorerData?.healthPlans?.topPlans?.selectedPlan;
    const applications = data?.viewerTwo?.health?.applications || [];
    const submitted = data?.viewerTwo?.health?.submitted || [];
    const isWindowShopping = data?.reference?.health?.openEnrollmentDates?.isWindowShopping;
    const isOpenEnrollment = data?.reference?.health?.openEnrollmentDates?.isOpenEnrollment;

    return {
      hasSelectedPlan: !!selectedPlan,
      planName: selectedPlan?.healthPlan?.name,
      hasAgreements: hasAgreedToPrivacy(data?.viewerTwo?.agreements),
      hasIDProof: data?.viewerTwo?.identity?.status === 'VERIFIED',
      hasStartedApplication: applications.length > 0,
      hasConnectedCoverage: data?.viewerTwo?.health?.policies?.length > 0,
      isWindowShopping,
      isOpenEnrollment,
      hasSubmittedApplication: submitted?.length > 0,
    };
  }, [data]);

  const onNext = () => {
    if (
      isWindowShopping &&
      hasIDProof &&
      hasSelectedPlan &&
      (hasConnectedCoverage || hasStartedApplication)
    ) {
      Segment.track('Window Shopping Completed', { coverageYear: oeCoverageYear });
      exit();
    } else {
      handleNext();
    }
  };

  // this flow should only be used during window shopping or OE
  if (!isWindowShopping && !isOpenEnrollment) {
    return null;
  }

  return (
    <SetupIntro
      loading={loading}
      type={isWindowShopping ? 'WINDOW_SHOPPING' : 'QUICK_RENEW'}
      onNext={onNext}
      data={{
        coverageYear: oeCoverageYear,
        renewalYear: oeCoverageYear ? parseInt(oeCoverageYear) - 1 : '',
        planName,
        hasSelectedPlan,
        hasConnectedCoverage,
        hasStartedApplication,
        hasAgreements,
        hasIDProof,
        hasHeadstart: hasConnectedCoverage || hasStartedApplication,
        hasSubmittedApplication,
      }}
    />
  );
};

export const HealthRenewIntroView = {
  name: routes.HEALTH_RENEW_INTRO,
  component: HealthRenewIntro,
  options: {
    ...SetupIntro.options,
    bg: 'coverageLight',
    title: '',
  },
};
