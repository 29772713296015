import React, { useState } from 'react';

import { useDeprecatedQuery } from '@data';
import { Stack } from '@app/layouts';
import { IconSvg, Button, Loading, Text, useTheme, CatchTheme } from '@uikit';
import { useCopy } from '@app/utils';
import { useResponsive } from '@uikit/hooks/useResponsive';

import { LineChart } from './base/LineChart';
import { Layout } from '../layouts/Layout';

import CaratUp from '@uikit/icons/svg/carat-up.svg';
import CaratDown from '@uikit/icons/svg/carat-down.svg';

const DEFAULT_RANGE = 'LAST_YEAR';
const rangeSelections = {
  LAST_WEEK: {
    name: 'week',
    name_mobile: 'week',
    title: 'this week',
  },
  LAST_MONTH: {
    name: 'month',
    name_mobile: 'month',
    title: 'this month',
  },
  LAST_YEAR: {
    name: 'year',
    name_mobile: 'year',
    title: 'this year',
  },
  ALL: {
    name: 'all',
    name_mobile: 'allMobile',
    title: 'all time',
  },
};

const RetirementLineChart = ({ wealthAccountID }) => {
  const { c, p } = useCopy('catch.retirement.range');
  const { isMobile } = useResponsive();
  const { themeColors } = useTheme();
  const [range, setRange] = useState(DEFAULT_RANGE);

  const { retirementTimeSeries } = useDeprecatedQuery('retirementTimeSeries', {
    variables: {
      range: range,
      id: wealthAccountID,
    },
  });

  let prevData, data;

  if (retirementTimeSeries) prevData = retirementTimeSeries;

  // samples the data to smooth out the curve interpolation
  if (!!prevData && prevData?.length > 0) {
    data = [];
    const _num = Math.min(24, prevData.length);
    let interval = Math.floor(prevData.length / _num);

    for (let i = 0; i < _num; i++) {
      data.push(prevData[i * interval]);
    }
  } else {
    data = prevData;
  }

  const growth =
    prevData?.length &&
    prevData[prevData.length - 1][`${rangeSelections[range].name}MoneyWeightedReturn`] / 100;

  const max = prevData?.length
    ? Math.max.apply(
        Math,
        prevData?.map(function (o) {
          return o.marketValue;
        }),
      )
    : 0;

  const min = prevData?.length
    ? Math.min.apply(
        Math,
        prevData?.map(function (o) {
          return o.marketValue;
        }),
      )
    : 0;

  return !!data ? (
    <>
      <Stack horizontal style={{ justifyContent: 'flex-end', marginTop: -24 }}>
        <IconSvg
          fill
          svgComponent={growth >= 0 ? CaratUp : CaratDown}
          color={growth >= 0 ? 'positive' : 'subtle'}
          size="xs"
        />
        <Text size="fp" weight="semibold" color={growth >= 0 ? 'positive' : 'subtle'}>
          {p(growth || 0, { minimumFractionDigits: 1 })}
        </Text>
        <Text size="fp" color="subtle">
          {` ${rangeSelections[range].title}`}
        </Text>
      </Stack>

      <Layout mobileSnap={isMobile} style={{ height: 'auto', flex: 1 }}>
        {data?.length > 0 && (
          <LineChart
            data={data}
            y="marketValue"
            domain={{ y: [min - min / 100, max + max / 100] }}
            style={{
              data: {
                stroke: themeColors.textColor,
                strokeWidth: 1.5,
              },
            }}
            animate={{
              duration: CatchTheme.animation.durationLong,
              onLoad: { duration: CatchTheme.animation.durationShort },
            }}
          />
        )}
      </Layout>

      <Stack
        horizontal
        spacing="0b"
        align="center"
        style={{ alignItems: 'center', alignSelf: 'center' }}
      >
        {Object.keys(rangeSelections).map((key) => (
          <Button
            caps
            key={key}
            mini
            testID={`time-series-last-${rangeSelections[key].name}-button`}
            onPress={() => setRange(key)}
            alt
            accentColor={range === key ? 'fg' : 'grayBg'}
          >
            {isMobile ? c(rangeSelections[key].name_mobile) : c(rangeSelections[key].name)}
          </Button>
        ))}
      </Stack>
    </>
  ) : (
    <Loading />
  );
};

export default RetirementLineChart;
