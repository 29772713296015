import gql from 'graphql-tag';
import access from 'safe-access';
import { transfers } from '../fragments';

/**
 * Note: we've adjusted the way we handle dismissing rewards
 * - Previously, we dismissed via DOORDASH_REWARD but have
 * since migrated to referral-home-alert
 * - Since
 */
const LATEST_REWARDS = gql`
  query LatestRewards {
    viewer {
      id
      ...Rewards
      nudge: nudge(nudgeIdentifier: "referral-home-alert") {
        lastUpdated
      }
      original: nudge(nudgeIdentifier: "DOORDASH_REWARD") {
        lastUpdated
      }
    }
  }
  ${transfers.rewards}
`;

const formatter = (data, { filter }) => {
  const get = access(data);
  const originalUpdated = get('viewer.original.lastUpdated');
  const lastUpdated = get('viewer.nudge.lastUpdated');
  const { rewards } = transfers.formatRewards(data);
  const lastDismissed = lastUpdated || originalUpdated;

  const totals = rewards.reduce(
    (acc, reward) => {
      if (!!filter) {
        // if the filter is defined, dont add if filter doesnt return true
        if (!filter(reward)) return acc;
      } else {
        // Otherwise, default filter should be nudges
        if (!lastDismissed || lastDismissed > reward?.createdOn) return acc;
      }

      // @todo: this  needs to be validated before merge
      return { total: acc.total + reward.amount, count: acc.count + 1 };
    },
    { total: 0, count: 0 },
  );

  return {
    hasRewards: totals.count > 0,
    count: totals.count,
    total: totals.total,
  };
};

export default {
  document: LATEST_REWARDS,
  formatter,
};
