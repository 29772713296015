import React from 'react';
import { View } from 'react-native';

import { Failcone, Modal, ActionSheetContent, Button, s } from '@uikit';
import { useCopy } from '@app/utils';

/**
 * @todo use SheetBlueprint
 */
const ErrorModal = ({ onClose }) => {
  const { c } = useCopy('catch.module.errors.ErrorModal');

  return (
    <Modal onRequestClose={onClose}>
      <ActionSheetContent
        icon={Failcone}
        title={c('title')}
        subtitles={[c('captionP1'), c('captionP2')]}
      >
        <View style={s.alignCenter}>
          <Button alt onPress={onClose}>
            {c('button')}
          </Button>
        </View>
      </ActionSheetContent>
    </Modal>
  );
};

export default ErrorModal;
