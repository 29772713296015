import React, { useEffect, useMemo } from 'react';

import { useCopy, safeFormatDate, Segment } from '@app/utils';
import { OpenEnrollmentQueryData, queries, useDeprecatedQuery, useQuery } from '@data';
import { exit, routes } from '@navigate';
import { HiccupBlueprint } from '@blueprints';
import { getCarrierAsset } from '@app/utils';

/**
 * This page is shown when a user gets to stopping point
 * in the week before
 *
 * @TODO: do we want to add this back to application flow?
 */
const WindowShopping = ({ enrollId }) => {
  const { c: basics } = useCopy('catch.basics');
  const { c } = useCopy('catch.health.windowShopping');
  const { selectedPlan } = useDeprecatedQuery('healthPlans', {
    variables: { enrollId },
    skip: !enrollId,
  });

  const { data } = useQuery<OpenEnrollmentQueryData>(queries.OPEN_ENROLLMENT);

  const { startTime, nextOEYear } = useMemo(() => {
    return {
      startTime: new Date(data?.reference?.health?.openEnrollmentDates?.startTime),
      nextOEYear: data?.reference?.health?.openEnrollmentDates?.nextOEYear,
    };
  }, [data]);

  useEffect(() => {
    Segment.track('Window Shopping Completed', {
      coverageYear: nextOEYear,
    });
  }, []);

  return (
    <HiccupBlueprint
      asset={getCarrierAsset(selectedPlan?.issuer?.name)}
      title={c('title', { date: safeFormatDate(startTime, 'MMMM d') })}
      subtitles={[
        c('p1', { hubYear: nextOEYear, startTime: safeFormatDate(startTime, 'MMMM d') }),
        c('p2'),
      ]}
      onPress={() => exit(routes.HOME)}
      buttonText={basics('soundsGood')}
    />
  );
};

export const WindowShoppingView = {
  name: routes.EDE_WINDOW_SHOPPING,
  component: WindowShopping,
  options: {
    title: 'Window Shopping',
  },
};
