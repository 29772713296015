import React from 'react';
import {
  useMutation,
  mutations,
  UpsertExplorerResponse,
  UpsertExplorerVars,
  HealthExplorerQueryData,
  useQuery,
  queries,
  updates,
} from '@data';
import { useCopy } from '@app/utils';
import { routes, ScreenDefinition } from '@navigate';
import { HealthZipTriage } from '@partial';
import { useHealthStore } from '@app/client/useHealthStore';

const ExplorerZipCode = ({ handleNext }) => {
  const { c } = useCopy('catch');
  const { setHealthContext } = useHealthStore();
  const { data } = useQuery<HealthExplorerQueryData>(queries.HEALTH_EXPLORER);

  const [upsert, { loading: upserting }] = useMutation<UpsertExplorerResponse, UpsertExplorerVars>(
    mutations.UPSERT_EXPLORER,
    { update: updates.UPDATE_EXPLORER },
  );

  const onSubmit = ({ coverageYear, location }) => {
    upsert({
      variables: {
        input: {
          coverageYear,
          countyfips: location.fips,
          state: location.state,
          zipcode: location.zip,
          pathwayType: location.pathway,
        },
      },
    });

    if (location.state === 'GA') {
      setHealthContext("GA");
    }

    handleNext({ coverageYear });
  };

  return (
    <HealthZipTriage
      loading={!data}
      submitting={upserting}
      title={c('healthExplorer.explorerZip.OEPtitle')}
      oeDates={data?.reference?.health?.openEnrollmentDates}
      onSubmit={onSubmit}
    />
  );
};

export const ExplorerZipCodeView: ScreenDefinition = {
  name: routes.EXPLORER_ZIPCODE,
  component: ExplorerZipCode,
  options: {},
};
