import { isBefore, isAfter } from 'date-fns';

import dateValidation from './date';
import { COPY } from './copy';
import { eighteenYearsAgo, oneHundredTenYearsAgo } from '@app/utils/time';

const currentYear = () => new Date().getFullYear();

const toDate = (d) => {
  if (typeof d !== 'string') return d;
  const [m, dd, y] = d.split('/');
  const date = new Date(y, m - 1, dd);
  return date;
};

export const dobValidator = (value) => {
  const date = toDate(value);
  let errorMsg = '';
  if (!isBefore(date, new Date())) {
    errorMsg = COPY['notValid']();
  } else if (!isBefore(date, eighteenYearsAgo())) {
    errorMsg = COPY['tooYoung']({ currentYear: currentYear() });
  } else if (!isAfter(date, oneHundredTenYearsAgo())) {
    errorMsg = 'Date is out of range';
  }
  return errorMsg;
};

export default [dateValidation, dobValidator];
