/**
 * Used for branding the income cards on the homepage
 */
export const brandColors = {
  'Cash App': { overlay: '#00D03155', textLight: '#00D031', textDark: '#00D031' },
  Doordash: {
    overlay: '#8D1700',
    darkOverlay: true,
    textLight: '#FF310A',
    textDark: '#FF310A',
  },
  Upwork: {
    overlay: '#13544E',
    darkOverlay: true,
    textLight: '#1BA704',
    textDark: '#1BA704',
  },
  GrubHub: {
    overlay: '#36296B',
    darkOverlay: true,
  },
  Airbnb: {
    overlay: '#C02C45',
    darkOverlay: true,
  },
  Poshmark: { overlay: '#803843', darkOverlay: true },
  Lyft: { overlay: '#523BE4', darkOverlay: true },
  Gusto: { overlay: '#CA4D3C', darkOverlay: true },
  Stripe: { overlay: '#635BFF', darkOverlay: true },
  Instacart: { overlay: '#0AAD0A', darkOverlay: true },
  Square: { overlay: '#222324', darkOverlay: true },
  'Square Payroll': { overlay: '#222324', darkOverlay: true },
  Vagaro: { overlay: '#AA564F', darkOverlay: true },
  TriNet: { overlay: '#CF6C1F', darkOverlay: true },
  Justworks: { overlay: '#1A84DE', darkOverlay: true },
  PayPal: { overlay: '#013087', darkOverlay: true },
  Venmo: { overlay: '#028CFF', darkOverlay: true },
  Zelle: { overlay: '#6D1FD4', darkOverlay: true },
  Uber: { overlay: '#000000', darkOverlay: true },
  'Visa Direct': { overlay: '#1434CB', darkOverlay: true },
  Grubhub: { overlay: '#5729F8', darkOverlay: true },
  Etsy: { overlay: '#232347', darkOverlay: true },
  Shipt: { overlay: '#014A55', darkOverlay: true },
  TheCut: { overlay: '#000000', darkOverlay: true },
  Intuit: { overlay: '#304791', darkOverlay: true },
  Shopify: { overlay: '#008060', darkOverlay: true },
  Turo: { overlay: '#242021', darkOverlay: true },
  GlossGenius: { overlay: '#33343E', darkOverlay: true },
  Walmart: { overlay: '#014F9A', darkOverlay: true },
  'Uber Eats': { overlay: '#068848', darkOverlay: true },
  Hyer: { overlay: '#38527F', darkOverlay: true },
  Wethos: { overlay: '#711fff', darkOverlay: true },
};
