import React from 'react';

import { Activity as ActivityComponent } from '@common';
import { SheetBlueprint } from '@app/blueprints';
import { Layout } from '@app/layouts';

const _Activity = () => {
  return (
    <Layout.Scroll>
      <SheetBlueprint>
        <ActivityComponent />
      </SheetBlueprint>
    </Layout.Scroll>
  );
};

export const Activity = {
  name: 'ACTIVITY',
  component: _Activity,
  options: { title: 'Activity', presentation: 'pageSheet' },
};
