import React from 'react';

import { useCopy } from '@app/utils';
import { Button } from '@uikit';
import { ButtonGroup, FlowLayout } from '@layouts';
import { exit, routes, handleAction } from '@navigate';

const AccountSetupDenied = ({ slug }) => {
  const { c } = useCopy('catch.plans.PlanIdentityFailureView');

  return (
    // @ts-ignore
    <FlowLayout title={c('title')} subtitles={[c('p1'), c('p2')]} hideBottomBar>
      <ButtonGroup>
        {/* @ts-ignore */}
        <Button onPress={() => exit(routes.PLAN)}>{c('exitButton')}</Button>
        {/* @ts-ignore */}
        <Button alt onPress={() => handleAction({ type: 'HELP' })}>
          {c('chatButton')}
        </Button>
      </ButtonGroup>
    </FlowLayout>
  );
};

export const AccountSetupDeniedView = {
  name: routes.ACCOUNT_SETUP_DENIED,
  component: AccountSetupDenied,
  options: {},
};
