import React, { useMemo, useState } from 'react';
import shallow from 'zustand/shallow';

import { queries, useQuery, BankLinksQueryData } from '@data';
import { BANK_ERROR_CODES, useCopy, launchPlaid } from '@app/utils';
import { routes, sheets, useSheet } from '@navigate';
import { PromptCard, SettingsLayout } from '@common';
import { Asset, ComplexRow, Loading, Button } from '@uikit';
import { Section, Stack } from '@layouts';
import { formatBankAccounts, BankAccount, formatBankDetail } from '@format/formatBanks';
import { useTier } from '@app/hooks';
import { PromptList, Prompts } from '@app/config';
import { LINK_MODES } from '@app/utils/const';

import Warning from '@svg/warning-triangle.svg';

export const LinkedAccounts = () => {
  const [plaidLoading, setLoading] = useState(false);
  const { c } = useCopy('catch.banklink.BankLinkView');
  const { open } = useSheet((state) => ({ open: state.open }), shallow);
  const { restrictions, locked } = useTier();

  const { loading, data } = useQuery<BankLinksQueryData>(queries.BANK_LINKS);

  // flatten all bank accounts and pending accounts
  const sections = useMemo(() => {
    const { active, inactive, pending } = formatBankAccounts(
      data?.viewer?.bankLinks,
      data?.viewer?.pendingAccounts,
    );

    let sections: Array<{
      title: string;
      accounts: Array<BankAccount>;
    }> = [];

    if (active.length > 0) sections.push({ title: '', accounts: active });
    if (pending.length > 0) sections.push({ title: 'Pending', accounts: pending });
    if (inactive.length > 0) sections.push({ title: 'Inactive', accounts: inactive });

    return sections;
  }, [data]);

  const errorLinks = data?.viewer?.bankLinks?.filter((link) => link?.syncStatus !== 'GOOD');

  const idErrorLinks = data?.viewer?.bankLinks?.filter(
    (link) => link?.identityErrorCode === 'PRODUCTS_NOT_SUPPORTED',
  );

  return (
    <SettingsLayout title="Linked Banks">
      <Stack mobileGap topSpace>
        {false ? (
          <Loading />
        ) : (
          <Stack>
            <Stack spacing="+gap">
              {/* {!!locked && <PromptCard mobileSnap size="ALERT" {...Prompts.ACCOUNT_LOCKED} />}
              {errorLinks?.map((link) => (
                <PromptCard
                  gradient="mediumLight"
                  mobileSnap
                  size="ALERT"
                  key={link?.id}
                  icon={Warning}
                  title={`${link?.bankName} is disconnected`}
                  subtitle="Catch functionality may be reduced"
                  overrideAction={() => {
                    const account = link?.accounts?.[0];
                    const disconnectType = BANK_ERROR_CODES[link.errorCode];
                    open(sheets.MANAGE_BANK_ACCOUNT, {
                      account: {
                        detail: formatBankDetail(account.accountName, account.accountNumber),
                        isDisconnected: true,
                        disconnectType,
                        relinkMode: LINK_MODES[disconnectType],
                        bankName: link?.bankName,
                        bankLinkID: link?.id,
                        ...link?.accounts?.[0],
                      },
                    });
                  }}
                />
              ))}
              {idErrorLinks?.map((link) => (
                <PromptCard
                  mobileSnap
                  size="ALERT"
                  key={link?.id}
                  icon={Warning}
                  title={`${link?.bankName} doesn't support account owner verification`}
                  subtitle="Catch functionality may be reduced"
                />
              ))} */}
            </Stack>

            {/* {sections.map((section) => {
              return (
                <Section key={section.title} title={section.title}>
                  <Stack separatorComponent>
                    {section.accounts.map((bankAccount) => {
                      return (
                        <ComplexRow
                          key={bankAccount.id}
                          testID={bankAccount.id}
                          asset={Asset.configureBank(bankAccount.logo)}
                          label={bankAccount.detail}
                          sublabel={bankAccount.bankName}
                          onPress={() => open(sheets.MANAGE_BANK_ACCOUNT, { account: bankAccount })}
                          disabled={locked}
                          disabledAppearance={bankAccount.isDisconnected || bankAccount.isInactive}
                          accessory={bankAccount.isDisconnected ? 'error' : 'menu'}
                        />
                      );
                    })}
                  </Stack>
                </Section>
              );
            })}

            {(data?.viewer?.bankLinks?.length === 0 || !restrictions?.MULTIPLE_BANK_LINKS) && (
              <Button
                alt
                full
                disabled={locked}
                accentColor="incomeLight"
                testID="link-new-bank"
                loading={plaidLoading}
                onPress={() => launchPlaid({ mode: 'create', setLoading })}
              >
                {c('linkNewBank')}
              </Button>
            )} */}
          </Stack>
        )}
      </Stack>
    </SettingsLayout>
  );
};

export const LinkedAccountsView = {
  name: routes.LINKED_BANKS,
  component: LinkedAccounts,
  options: {
    title: 'Linked Banks',
    largeTitle: true,
  },
};
