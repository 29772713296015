import React from 'react';

import { useCopy } from '@app/utils';
import { Text, Link } from '@uikit';
import { Stack } from '@layouts';
import { GuideBlueprint } from '@blueprints';

export const TaxPaymentGuideSheet = () => {
  const { c } = useCopy(`catch.module.taxes.taxPayments.InfoSheets.calculations`);

  /**
   * these external links are updated annually by IRS,
   * checked through https://archive.org/web/
   */
  const links = [
    {
      name: '1040-ES',
      href: 'https://www.irs.gov/pub/irs-pdf/f1040es.pdf',
    },
    {
      name: 'ScheduleC',
      href: 'https://www.irs.gov/pub/irs-pdf/f1040sc.pdf',
    },
  ];

  return (
    <GuideBlueprint
      name="TAX_PAYMENT_GUIDE"
      title={c('title')}
      subtitle={c('description.p1')}
      actions={links.map((link) => ({
        label: link.name,
        type: 'LINK',
        route: link.href,
      }))}
    >
      <Stack spacing="2">
        {links.map((link, i) => {
          const { name } = link;
          return (
            <Stack key={name} spacing="0b">
              <Link inline href={link.href} target="_blank" size="p" weight="bold">
                {c(`${name}.title`)}
              </Link>
              <Text size="fp" weight="regular">
                {c(`${name}.description`, {
                  link1: (
                    <Text weight="medium" size="fp">
                      {c(`${links[0].name}.title`)}
                    </Text>
                  ),
                  link2: (
                    <Text weight="medium" size="fp">
                      {c(`${links[1].name}.title`)}
                    </Text>
                  ),
                })}
              </Text>
            </Stack>
          );
        })}
      </Stack>
    </GuideBlueprint>
  );
};

export default TaxPaymentGuideSheet;
