import gql from 'graphql-tag';

export const TAX_PAYMENTS = gql`
  query TaxPayments($paymentId: ID!) {
    viewerTwo {
      id
      taxPayment(id: $paymentId) {
        id
        createdOn
        jurisdiction
        amount
        confirmationCode
        timeframe
        yearOfLastCompletedFiling
        signatureText
        filingName
        isAuto
        status
        providerStatus
        document {
          id
          url
        }
      }
    }
  }
`;

const formatter = (data) => ({
  taxPayment: data?.viewerTwo?.taxPayment,
});

export default {
  document: TAX_PAYMENTS,
  formatter,
};
