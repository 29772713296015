import React, { useMemo, useState, useCallback } from 'react';
import { Button, ActionSheet, Divider } from '@uikit';
import { useCopy } from '@app/utils';
import Plus from '@svg/plus.svg';
import { SheetBlueprint } from '@app/blueprints';

interface Props {
  type: 'income' | 'deduction';
  append: (item: any) => void;
}

const otherCoverageOptions = [
  {
    label: 'Individual insurance (including Marketplace or other non-group coverage)',
    value: 'MARKETPLACE',
  },
  { label: 'Medicaid', value: 'STATE_MEDICAID' },
  { label: 'Children’s Health Insurance Program (CHIP)', value: 'STATE_CHIP' },
  { label: 'Medicare', value: 'MEDICARE' },
  {
    label: 'TRICARE (Don’t check if this person has Direct Care or Line of Duty)',
    value: 'TRICARE',
  },
  { label: 'Veterans Affairs (VA) Health Care Program', value: 'VA' },
  { label: 'Peace Corps', value: 'PEACE_CORPS' },
  { label: 'Other full benefit coverage', value: 'OTHER_FULL_BENEFIT' },
  {
    label: 'Other limited benefit coverage (like a school accident policy)',
    value: 'OTHER_LIMITED_BENEFIT',
  },
];

const OtherCoverageFooter: React.FC<Props> = ({ type, append, isLast }) => {
  const { c } = useCopy('catch.ede.enums');

  const [isOpen, setOpen] = useState(false);
  const open = useCallback(() => setOpen(true), []);
  const close = useCallback(() => setOpen(false), []);

  const options = useMemo(() => {
    return otherCoverageOptions.map(({ value }) => ({
      label: c(value),
      onAction: () => {
        append({ insuranceMarketType: value });
        setOpen(false);
      },
    }));
  }, [type]);

  return (
    <>
      <ActionSheet isOpen={isOpen} onRequestClose={close}>
        <SheetBlueprint actions={options} />
      </ActionSheet>
      <Button onPress={open} small testID="add-other-coverage" svg={Plus}>
        Coverage
      </Button>
      {!isLast && <Divider style={{ marginTop: 16, marginBottom: 16 }} />}
    </>
  );
};

export default OtherCoverageFooter;
