import gql from 'graphql-tag';

export const VALIDATE_GOAL = gql`
  query ValidateGoal {
    viewer {
      id
      goals {
        id
        paycheckPercentage
        status
        slug
      }
    }
  }
`;

const formatter = (data, options) => {
  // Pass the goal id to prevent from including the rate if it exists
  const totalPercentage =
    data?.viewer?.goals?.reduce((tot, goal) => {
      if (options?.goalID && goal.id === options?.goalID) {
        return tot;
      }

      if (goal.status !== 'ACTIVE') {
        return tot;
      }

      return tot + (goal.paycheckPercentage || 0);
    }, 0) || 0;

  const isValid = options?.percentage + totalPercentage <= 0.99;

  return {
    isValid,
    below100Percent: options?.percentage + totalPercentage <= 0.99,
    totalPercentage,
    otherPlans: Math.round(totalPercentage * 100),
  };
};

export default {
  document: VALIDATE_GOAL,
  formatter,
};
