import React from 'react';

import { Text, Accordion, Link } from '@uikit';
import { Section, Stack } from '@layouts';
import { useCopy } from '@app/utils';
import { useHealthStore } from '@app/client/useHealthStore';

const healthCareUrl = 'https://catch.co/guides/health/essential-health-benefits';

/**
 * "Pre-application Informational Content"
 * note: be careful. this is required and monitored by CMS and GA
 * see Communications Toolkit(s) for detailed requirements
 *
 * This displays on the application intro page
 *
 * @todo @ga (not available as of 7/25/22):
 * - urls
 * - spanish translations
 */
const HealthFaqDrawer = () => {
  const { context } = useHealthStore(); // for FFM vs GA
  const isGa = context === 'GA';

  const { c } = useCopy(isGa ? 'catch.health.HealthFaqGa' : 'catch.health.HealthFaq');

  let questions = isGa ? [1, 2, 3, 4, 5, 6, 7] : [1, 2, 3, 4, 5, 6];

  return (
    <Section title={c('title')} subtitle={c('subtitle')}>
      <Stack separatorComponent>
        {questions.map((q, i) => (
          <Accordion title={c(`question${q}`)} small key={i}>
            <Text size="fp" color="secondary">
              {c(`answer${q}`)}
            </Text>
            {!!c(`link${q}`) && (
              <Link size="fp" href={healthCareUrl} target="blank" gutter="top">
                {c(`link${q}`)}
              </Link>
            )}
          </Accordion>
        ))}
      </Stack>
    </Section>
  );
};

export default HealthFaqDrawer;
