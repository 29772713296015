import gql from 'graphql-tag';

export const EMAIL_NOTIFICATIONS = gql`
  query EmailNotifications {
    viewer {
      id
      notificationSettings {
        email {
          myBenefits
          recommendedBenefits
          featureAnnouncements
        }
      }
    }
  }
`;

function formatter(data) {
  return {
    myBenefits: data?.viewer?.notificationSettings?.email?.myBenefits,
    recommendedBenefits: data?.viewer?.notificationSettings?.email?.recommendedBenefits,
    featureAnnouncements: data?.viewer?.notificationSettings?.email?.featureAnnouncements,
  };
}

export default {
  document: EMAIL_NOTIFICATIONS,
  formatter,
};
