import { applyMiddleware, createStore } from 'redux';
import { formMiddleware } from '../middlewares';
import { createRootReducer } from '../reducers';

let store = {};

function configureStore() {
  const middlewares = [formMiddleware];
  const createAppStore = applyMiddleware(...middlewares)(createStore);
  const reducers = createRootReducer();

  store = createAppStore(reducers);
  store.injectedReducers = {};
  store.injectedSagas = {};

  return store;
}

export { store };

export default configureStore;
