import gql from 'graphql-tag';

export const WorkDetailsFragments = {
  details: gql`
    fragment WorkDetails on Viewer {
      id
      user {
        id
        workType
        filingStatus
      }
      incomeState
      income {
        estimatedW2Income
        estimated1099Income
      }
      spouseIncome
    }
  `,
};

export default {
  ...WorkDetailsFragments,
};
