import gql from 'graphql-tag';

export interface AddPendingAccountVars {
  pendingAccountID: string;
  publicToken: string;
}

export interface AddPendingAccountResponse {
  addPendingAccount: {
    id: string;
    isPrimary: boolean;
    accountName: string;
    accountNumber: string;
    bankName: string;
  };
}

export const AddPendingAccount = gql`
  mutation AddPendingAccount($pendingAccountID: String!, $publicToken: String!) {
    addPendingAccount(id: $pendingAccountID, publicToken: $publicToken) {
      id
      isPrimary
      accountName: name
      accountNumber: mask
      bankName: institutionName
    }
  }
`;
