import React from 'react';
import { Stack, stacks } from '@navigate';
import { HomeView } from './HomeView';
import { TabStackComponent, TabConfig } from '../types';

const config: TabConfig = {
  stackName: stacks.HOME_STACK,
  options: {},
  screens: [HomeView],
  tabIcon: 'HOME',
  title: 'Home',
};

const HomeStack: TabStackComponent = () => {
  return <Stack stackName={config.stackName} screens={config.screens} options={config.options} />;
};

HomeStack.config = config;

export default HomeStack;
