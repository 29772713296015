import React, { useCallback, useMemo } from 'react';
import {
  stacks,
  Stack,
  StackComponent,
  StackDefinition,
  useCurrentRoute,
  routes,
  navigate,
  exit,
} from '@navigate';
import { useQuery, queries, HealthExplorerQueryData, useDeprecatedQuery } from '@data';
import { ExplorerApplicantsView } from './explorer/applicants/ExplorerApplicantsView';
import { ExplorerDoctorsView } from './explorer/ExplorerDoctorsView';
import { ExplorerIntroView } from './explorer/ExplorerIntroView';
import { ExplorerPlanSelectionView } from './explorer/ExplorerPlanSelectionView';
import { ExplorerQLEView } from './explorer/ExplorerQLEView';
import { ExplorerRiskView } from './explorer/ExplorerRiskView';
import { ExplorerRXView } from './explorer/ExplorerRXView';
import { ExplorerSavingsView } from './explorer/ExplorerSavingsView';
import { ExplorerSEPIneligibleView } from './explorer/ExplorerSEPIneligibleView';
import { ExplorerSEPView } from './explorer/ExplorerSEPView';
import { ExplorerStateHandoffView } from './explorer/ExplorerStateHandoffView';
import { ExplorerStateReturnView } from './explorer/ExplorerStateReturnView';
import { ExplorerHouseholdView } from './explorer/ExplorerHouseholdView';
import { ExplorerUsageView } from './explorer/ExplorerUsageView';
import { ExplorerZipCodeView } from './explorer/ExplorerZipCodeView';

const config: StackDefinition = {
  stackName: stacks.HEALTH_EXPLORER_STACK,
  options: { layout: 'page', navMode: 'flow', accentColor: 'coverage' },
  screens: [
    ExplorerIntroView,
    ExplorerZipCodeView,
    ExplorerApplicantsView,
    ExplorerStateHandoffView,
    ExplorerStateReturnView,
    ExplorerHouseholdView,
    ExplorerSavingsView,
    ExplorerRiskView,
    ExplorerUsageView,
    ExplorerRXView,
    ExplorerDoctorsView,
    ExplorerPlanSelectionView,
    ExplorerSEPView,
    ExplorerSEPIneligibleView,
    ExplorerQLEView,
  ],
};

interface ExplorerNavigationProps {
  coverageYear?: number;
  hasQLE?: boolean;
}

/**
 *
 *
 * H X: H E A L T H / E X P L O R E R
 * In-app Health Plan Explorer Stack
 *
 */
const HealthExplorerStack: StackComponent = () => {
  const route = useCurrentRoute();
  const { data } = useQuery<HealthExplorerQueryData>(queries.HEALTH_EXPLORER);

  const { explorerID, pathwayType, state, oe } = useMemo(
    () => ({
      explorerID: data?.viewerTwo?.healthExplorerData?.id,
      coverageYear: data?.viewerTwo?.healthExplorerData?.coverageYear,
      pathwayType: data?.viewerTwo?.healthExplorerData?.pathwayType,
      state: data?.viewerTwo?.healthExplorerData?.state,
      oe: data?.reference?.health?.openEnrollmentDates,
    }),
    [data?.viewerTwo?.healthExplorerData],
  );

  // Navigation
  const handleNext = useCallback(
    (data?: ExplorerNavigationProps) => {
      switch (route) {
        case routes.EXPLORER_INTRO:
          navigate(routes.EXPLORER_ZIPCODE);
          break;
        case routes.EXPLORER_ZIPCODE:
          navigate(
            data?.coverageYear === oe?.sepYear ? routes.EXPLORER_SEP : routes.EXPLORER_APPLICANTS,
          );
          break;
        case routes.EXPLORER_SEP:
          navigate(routes.EXPLORER_QLE);
          break;
        case routes.EXPLORER_QLE:
          navigate(data?.hasQLE ? routes.EXPLORER_APPLICANTS : routes.EXPLORER_SEP_INELIGIBLE);
          break;
        case routes.EXPLORER_APPLICANTS:
          navigate(routes.EXPLORER_HOUSEHOLD);
          break;
        case routes.EXPLORER_HOUSEHOLD:
          navigate(routes.EXPLORER_SAVINGS);
          break;
        case routes.EXPLORER_SAVINGS:
          navigate(routes.EXPLORER_RISK_TOLERANCE);
          break;
        case routes.EXPLORER_RISK_TOLERANCE:
          navigate(routes.EXPLORER_USAGE);
          break;
        case routes.EXPLORER_USAGE:
          navigate(routes.EXPLORER_PRESCRIPTIONS);
          break;
        case routes.EXPLORER_PRESCRIPTIONS:
          navigate(routes.EXPLORER_DOCTORS);
          break;
        case routes.EXPLORER_DOCTORS:
          navigate(routes.EXPLORER_CHOOSE_PLAN);
          break;
        case routes.EXPLORER_SEP_INELIGIBLE:
          exit(routes.COVERAGE);
          break;
        default:
          break;
      }
    },
    [route, oe],
  );

  const stackData = useMemo(() => {
    return {
      explorerID,
      pathwayType,
      handleNext,
    };
  }, [pathwayType, handleNext]);

  // fetches this whenever the state changes so we have it
  useDeprecatedQuery('stateHealthReference', {
    variables: { state },
    skip: !state,
    fetchPolicy: 'cache-first',
  });

  return (
    <Stack
      stackName={config.stackName}
      screens={config.screens}
      options={config.options}
      data={stackData}
    />
  );
};

HealthExplorerStack.config = config;
export default HealthExplorerStack;
