import React, { useState } from 'react';
import { View } from 'react-native';
import { reduxForm, FieldArray, formValueSelector } from 'redux-form';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { FileDropper } from '@common';
import { useCopy, constants, getFullName } from '@app/utils';
import { healthFormValidations as validators } from '@app/forms_old/validators';
import { default as HealthField } from '@app/forms_old/fields/HealthField';
import { Page, Layout, Toolbar } from '@layouts';
import {
  useTheme,
  Button,
  Loading,
  Link,
  Text,
  ComplexRow,
  Pressable,
  Trash,
  DocumentUploadSuccess,
} from '@uikit';

const formName = 'dmiUploadForm';
const PREFIX = 'catch.ede.DMI';
const DocumentUploader = ({
  onCompleted,
  file,
  onRemove,
  idx,
  filePrefix,
  copyPrefix,
  renderPreview,
}) => {
  const [uri, setUri] = useState('');
  const handleRemove = () => onRemove(idx);

  const handleUpload = ({ fileKey, fileType, fileObject, result }) => {
    setUri(result.Location);
    onCompleted({
      key: fileKey,
      filetype: fileType,
      file: fileObject,
    });
  };

  return (
    <FileDropper
      name={filePrefix}
      file={file}
      handleDrop={handleUpload}
      handleDelete={handleRemove}
      filePrefix={filePrefix}
      copyPrefix={copyPrefix}
      renderPreview={renderPreview}
    />
  );
};

const UploadList = ({
  fields,
  docType,
  issueType,
  intl: { formatMessage },
  statusCode,
  members,
}) => {
  const { theme } = useTheme();
  // Might be useful for debugging
  const filePrefix = members[0]?.familyName;
  return (
    <>
      {docType === 'OTHER' && (
        <Text style={[theme.fp, theme.mediumText, theme.bottomGutter]}>
          <FormattedMessage id={`${PREFIX}.docInfo`} />{' '}
          <Link
            // URL is specific to the language so we format it like copy
            href={formatMessage({
              id: `${PREFIX}.${issueType === 'svi' ? statusCode : issueType}.url`,
            })}
            glyph="external"
            target="_blank"
            small
            inline
          >
            <FormattedMessage id={`${PREFIX}.docInfoLink`} />
          </Link>
        </Text>
      )}
      {fields.map((field, idx) => (
        <View style={theme.bottomGutter2} key={idx}>
          <DocumentUploader
            onRemove={fields.remove}
            onCompleted={fields.push}
            file={(fields.get(idx) || {}).file}
            renderPreview={({ uri, handleDelete }) => {
              const file = (fields.get(idx) || {}).file;
              return (
                <ComplexRow
                  label={file?.name}
                  bg="debitLight"
                  style={theme.cardPaddingSm}
                  asset={
                    !/pdf/.test(file?.name)
                      ? {
                          uri: uri,
                          size: 'xl',
                          shape: 'rounded',
                          shadow: 'major',
                        }
                      : {
                          icon: DocumentUploadSuccess,
                          size: 'xl',
                          shape: 'rounded',
                          shadow: 'major',
                        }
                  }
                  accessory={
                    <Pressable handleOnPress={handleDelete}>
                      <Trash color="text" />
                    </Pressable>
                  }
                />
              );
            }}
          />
        </View>
      ))}
      <DocumentUploader
        onRemove={fields.remove}
        onCompleted={fields.push}
        filePrefix={filePrefix}
        idx={fields?.length}
        copyPrefix={fields.length !== 0 && 'catch.ede.DMI'}
      />
    </>
  );
};
const withDocType = connect((state, ownProps) => ({
  docType: formValueSelector(formName)(state, `${ownProps.issueType}DocumentType`),
}));

const DMIUploadForm = ({
  members,
  statusCode,
  subType,
  issueType,
  onClose,
  loading,
  uploading,
  success,
  invalid,
  docOptions,
  subDocOptions,
  verificationType,
  handleSubmit,
}) => {
  const { theme } = useTheme();
  const { c } = useCopy(PREFIX);

  return (
    <Page color="sheet">
      {loading || uploading ? (
        <Loading
          status={success && 'success'}
          titles={{ success: c('successTitle') }}
          subtitles={{ success: c('successSubtitle') }}
        />
      ) : (
        <Layout topSpace fullHeight>
          <Layout.Scroll margins topSpace>
            <Layout bottomSpace>
              <Text size="fp" color="subtle">
                {c('caption')}
              </Text>
              <Text size="h4">{members[0].givenName}</Text>
              <Text style={[theme.p, theme.bottomGutter]}>
                {c(`docUploadSubtitle`, {
                  category: 'name',
                  name: statusCode === 'ADOPTION' ? members[0].givenName : getFullName(members),
                  type: (
                    <FormattedMessage
                      id={
                        issueType === 'svi'
                          ? `${PREFIX}.${statusCode}.title`
                          : `catch.ede.enums.${subType}`
                      }
                    />
                  ),
                })}
              </Text>
            </Layout>
            <HealthField
              fullWidth
              lID="263_L"
              type="select"
              options={docOptions}
              name={`${issueType}DocumentType`}
              setName={`${issueType}DocumentTypes`}
            />
            {subDocOptions &&
              Object.keys(subDocOptions).map((k) => (
                <HealthField
                  key={k}
                  fullWidth
                  lID="264_L"
                  type="select"
                  renderIf={`${issueType}DocumentType`}
                  equals={k}
                  options={subDocOptions[k]}
                  name={`${issueType}DocumentSubType`}
                />
              ))}
            <FieldArray
              name="documents"
              issueType={issueType}
              statusCode={statusCode}
              component={injectIntl(withDocType(UploadList))}
              members={members}
            />
            <Link
              href={constants.helpUrls[statusCode || verificationType]}
              target="_blank"
              gutter="top"
              align="right"
              inline
              style={theme.topGutter2}
            >
              {c('docHelpLink')} →
            </Link>
          </Layout.Scroll>
          <Toolbar>
            <Button alt onPress={onClose}>
              {c('cancelBtn')}
            </Button>
            <Button onPress={handleSubmit} disabled={invalid || uploading}>
              {c('uploadBtn')}
            </Button>
          </Toolbar>
        </Layout>
      )}
    </Page>
  );
};
const withForm = reduxForm({
  form: formName,
  validate: validators.dmiUploadForm,
});

export default withForm(DMIUploadForm);
