import React from 'react';
import { Button } from '@uikit';
import { routes, ScreenDefinition } from '@navigate';
import { useCopy } from '@app/utils';
import { CalloutLayout, Toolbar } from '@layouts';

const ExplorerSEP = ({ handleNext }) => {
  const { c } = useCopy('catch.basics');

  return (
    <CalloutLayout
      loading={false}
      render="coverage"
      title="It's currently Special Enrollment"
      subtitles={[
        "You can enroll in health coverage if you've had a recent qualifying life event.",
        "We'll help you determine if you're eligible for special enrollment.",
      ]}
      toolbar={
        <Toolbar>
          <Button inherit testID="get-started" onPress={handleNext}>
            {c('getStarted')}
          </Button>
        </Toolbar>
      }
    />
  );
};

export const ExplorerSEPView: ScreenDefinition = {
  name: routes.EXPLORER_SEP,
  component: ExplorerSEP,
  options: {},
};
