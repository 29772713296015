import React, { useMemo } from 'react';
import { ScreenDefinition } from '@navigate';
import { Platform, RootLayout } from '@layouts';
import { Notifications, Prompts } from '@common';

import { currentYear } from '@util';
import { useQuery, HomeQueryData, queries } from '@data';
import { getPrompts } from '@app/utils';
import { useDebug } from '@app/hooks';

const HomeComponent = () => {
  const { loading, data, refetch } = useQuery<HomeQueryData>(queries.HOME, {
    variables: {
      year: currentYear,
      taxYear: 2023,
      prevTaxYear: 2022,
      coverageYears: [currentYear, currentYear + 1],
    },
  });

  const { showLayout } = useDebug();
  const prompts = useMemo(() => getPrompts(data, { isDebug: showLayout }), [data, showLayout]);

  return (
    <RootLayout
      full
      onRefresh={refetch}
      refreshing={loading}
      loading={loading && !data}
      title="Home"
      color="pageBg"
    >
      <Prompts prompts={prompts} data={data} />

      <Platform native>
        <Notifications />
      </Platform>
    </RootLayout>
  );
};

export const HomeView: ScreenDefinition = {
  name: 'HOME',
  component: HomeComponent,
  options: {
    title: 'Home',
    largeTitle: true,
    drawBehindNav: true,
    bg: 'page',
    buttons: {
      account: true,
      activity: true,
    },
  },
};

export default HomeView;
