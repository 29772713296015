// @todo
import React from 'react';
import { View } from 'react-native';
import { FormLabel } from '@uikit';
import { PickerField, TextField } from '@app/forms_old/inputs';
import { InputProps } from '../types';
import { optionPresets } from '../config/options';

interface PhoneInputProps<T> extends InputProps<T> {
  subfields?: {
    number: boolean;
    ext?: boolean;
    type?: boolean;
  };
}

export const formatPhone = (val) => {
  return val;
};

// stores the actual value as XXX-XXX-XXXX
export const parsePhone = (val) => {
  if (!val) return '';
  // strip out everything except last 10 digits
  // for example +1 (555) 555 - 5555 -> 5555555555
  const digits = val.replace(/\D+/g, '');

  const first = digits.substring(0, 3);
  const middle = digits.substring(3, 6);
  const last = digits.substring(6, 10);

  if (digits.length < 3) {
    return first;
  }

  if (digits.length === 3) {
    return val === first ? first : `${first}-`;
  }

  if (digits.length < 6) {
    return `${first}-${middle}`;
  }

  if (digits.length === 6) {
    return val === `${first}-${middle}` ? `${first}-${middle}` : `${first}-${middle}-`;
  }

  return `${first}-${middle}-${last}`;
};

const PhoneInput = React.forwardRef(
  <T extends any>(
    {
      testID,
      autoFocus,
      name,
      value,
      label,
      sublabel,
      help,
      optional,
      disabled,
      onChange,
      onBlur,
      onKeyPress,
      error,
      subfields,
      errors,
    }: PhoneInputProps<T>,
    ref,
  ) => {
    return (
      <>
        <FormLabel
          label={label || 'Phone number'}
          sublabel={sublabel}
          help={help}
          optional={optional}
        />
        {!subfields ? (
          <TextField
            ref={ref}
            testID={testID}
            disabled={disabled}
            autoFocus={autoFocus}
            keyboard="phone"
            name={name}
            value={formatPhone(value)}
            onChangeText={(val) => onChange(parsePhone(val))}
            onBlur={onBlur}
            onKeyPress={onKeyPress}
            placeholder="555-555-5555"
            error={error}
            fullWidth
          />
        ) : (
          <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <View style={{ flex: 5, marginRight: 8 }}>
              <TextField
                ref={ref}
                testID={`${testID}-number`}
                disabled={disabled}
                autoFocus={autoFocus}
                keyboard="phone"
                value={formatPhone(value?.number)}
                onChangeText={(val) => onChange({ ...value, number: parsePhone(val) })}
                onBlur={onBlur}
                onKeyPress={onKeyPress}
                placeholder="555-555-5555"
                fullWidth
                error={!!errors?.number}
                grouped
              />
            </View>
            <View style={{ flex: 2, marginRight: 8 }}>
              <TextField
                testID={`${testID}-ext`}
                disabled={disabled}
                value={value?.ext}
                onChangeText={(ext) => onChange({ ...value, ext })}
                onKeyPress={onKeyPress}
                placeholder="Ext."
                fullWidth
                error={!!errors?.ext}
                grouped
              />
            </View>
            <View style={{ flex: 4, marginRight: 8 }}>
              <PickerField
                testID={`${testID}-type`}
                disabled={disabled}
                name={name}
                value={value?.type}
                options={optionPresets.PHONE_TYPE}
                onChange={(type) => onChange({ ...value, type })}
                fullWidth
                error={!!errors?.type}
                grouped
              />
            </View>
          </View>
        )}
      </>
    );
  },
);

PhoneInput.displayName = 'PhoneInput';
export default PhoneInput;
