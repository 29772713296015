import gql from 'graphql-tag';
import { nudges } from '../queries';

export const UPSERT_NUDGE = gql`
  mutation upsertNudge($input: NudgeInput!) {
    upsertNudge(input: $input) {
      nudgeIdentifier
      isDismissed
      lastUpdated
    }
  }
`;

function updateNudges(cache, { data: { upsertNudge } }) {
  try {
    const { viewer } = cache.readQuery({
      query: nudges.document,
    });

    const index = viewer.nudges.findIndex((n) => n?.id === upsertNudge?.id);

    const updatedNudges = [...viewer.nudges];

    if (index < 0) {
      updatedNudges.push(upsertNudge);
    } else {
      updatedNudges[index] = upsertNudge;
    }
  } catch (e) {}
}

export default {
  document: UPSERT_NUDGE,
  updateCache: updateNudges,
  refetchQueries: ['Nudges', 'Home'],
};
