import gql from 'graphql-tag';
import access from 'safe-access';
import { paychecks } from '../fragments';

const SET_MANAGE_INCOME_SOURCES = gql`
  mutation SetAutopilotRules($input: UpsertIncomeAutomationRulesInput!) {
    upsertIncomeAutomationRules(input: $input) {
      incomeAutomationRules {
        id
        automationType
        workType
        source {
          id
        }
        incomeTransactionsPaged(
          pagination: { pageNumber: 1, pageSize: 10 }
          incomeAction: [USER_PENDING]
        ) {
          edges {
            id
            ... on IncomeTransaction {
              ...PaycheckCard
            }
          }
        }
      }
    }
  }
  ${paychecks.paycheckCard}
`;

const formatter = (data) => {
  const get = access(data);
  const rules = get('upsertIncomeAutomationRules.incomeAutomationRules') || [];
  const paychecks = [];

  rules.forEach((rule) => {
    const transactions = rule?.incomeTransactionsPages?.edges || [];
    transactions.forEach((p) => {
      paychecks.push(p);
    });
  });

  return paychecks;
};

export default {
  document: SET_MANAGE_INCOME_SOURCES,
  formatter,
  refetchQueries: ['IncomeAutomationRules', 'IncomeAutomationRule', 'Subscriptions'],
};
