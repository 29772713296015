import React from 'react';
import { View, StyleSheet } from 'react-native';
import { useTheme } from '../hooks/useTheme';

export const Progress = ({ width, progress, thick, trackColor, color, disabled }) => {
  const { theme, themeColors } = useTheme();

  return (
    <View
      style={[
        { width: width },
        theme.track,
        disabled ? theme.progressTrackDisabledBg : theme.grayBgBg,
        thick && styles.thickTrack,
        trackColor && theme[`${trackColor}Bg`],
      ]}
    >
      <View
        accessibilityRole="progressbar"
        aria-valuemin="0"
        aria-valuemax="100"
        aria-valuenow={Math.round(progress * 100)}
        style={[
          styles.progress,
          disabled ? theme.disabledTrackBg : theme.progressBg,
          color && { backgroundColor: themeColors[`${color}Color`] },
          { width: `${progress * 100}%` },
        ]}
      />
    </View>
  );
};

Progress.defaultProps = {
  width: 220,
};

const styles = StyleSheet.create({
  progress: {
    height: '100%',
    width: '100%',
    borderRadius: 10,
  },
  thickTrack: {
    height: 6,
  },
});

export default Progress;
