/**
 * Given an income transaction,
 * translates it into same format as a transfer event
 */
export const translateIncomeTransaction = (incomeTx) => {
  return {
    id: incomeTx?.id,
    time: incomeTx?.autoDelayBatchTime || incomeTx?.date,
    type: 'INCOME',
    isAutopilot: incomeTx?.isAutopilot,
    isSkipped: incomeTx?.status === 'SKIPPED',
    status:
      incomeTx?.status === 'SKIPPED'
        ? 'SKIPPED'
        : incomeTx?.status === 'CANCELED'
        ? 'FAILED'
        : incomeTx?.transfer?.status,
    context: { goalID: null },
    source: incomeTx?.source,
    transactionRecords: incomeTx?.goalBreakdowns,
    incomeAmount: incomeTx?.amount || incomeTx?.incomeAmount,
    amount: incomeTx?.status === 'SKIPPED' ? null : incomeTx?.transferAmount,
    transferAmount: incomeTx?.status === 'SKIPPED' ? null : incomeTx?.transferAmount,
    bankName: incomeTx?.bankName,
    accountName: incomeTx?.account?.name,
    accountNumber: incomeTx?.account?.accountNumber,
    __typename: 'TransferEvent',
  };
};
