import React from 'react';
import {
  useQuery,
  queries,
  useMutation,
  mutations,
  OnboardingQueryData,
  UpdateUserResponse,
  UpdateUserVars,
} from '@data';
import { OnboardingFormBlueprint } from '@blueprints';
import { routes } from '@navigate';
import { FormConfig } from '@app/forms';
import { fields } from '@app/config';
import { useCopy } from '@app/utils';

interface WorkStateValues {
  workState: string;
}

const WorkState = ({ handleNext }) => {
  const { c } = useCopy('catch.onboarding.workState');
  const { loading, data } = useQuery<OnboardingQueryData>(queries.ONBOARDING, {
    fetchPolicy: 'cache-first', // @todo: this should really be the default regardless
  });

  const [update, { loading: submitting }] = useMutation<UpdateUserResponse, UpdateUserVars>(
    mutations.UPDATE_USER,
  );

  const formConfig: FormConfig<WorkStateValues> = {
    initialValues: { workState: data?.viewer?.user?.workState },
    fields: [fields.WORK_STATE],
    onSubmit: (values) => {
      update({
        variables: { user: { workState: values.workState } },
        optimisticResponse: {
          updateUserNew: {
            user: {
              ...data?.viewer?.user,
              id: data?.viewer?.user?.id || '',
              workState: values.workState,
              __typename: 'User',
            },
          },
        },
      });

      handleNext();
    },
  };

  return (
    <OnboardingFormBlueprint
      loading={loading}
      disabled={submitting}
      title={c('title')}
      subtitles={[c('subtitle')]}
      formConfig={formConfig}
    />
  );
};

export const WorkStateView = {
  name: routes.WORK_STATE,
  component: WorkState,
  options: OnboardingFormBlueprint.options,
};

export const GoalWorkStateView = {
  name: routes.GOAL_WORK_STATE,
  component: WorkState,
  options: OnboardingFormBlueprint.options,
};
