import gql from 'graphql-tag';
import access from 'safe-access';
import { banks } from '../fragments';

const PRIMARY_ACCOUNT = gql`
  query PrimaryAccount {
    viewer {
      id
      primaryAccount {
        id
        ...PrimaryAccount
      }
      pendingAccounts {
        id
        ...PendingAccount
      }
    }
  }
  ${banks.primaryAccount}
  ${banks.pendingAccount}
`;

const formatter = (data) => {
  const get = access(data);

  // If the primary account was found, return formatted version
  const primary = banks.formatPrimaryAccount(get('viewer.primaryAccount'));
  if (primary) return { primaryAccount: primary };

  // Otherwise, check if there's a primary pending account
  const pendingAccounts = get('viewer.pendingAccounts') || [];
  const index = pendingAccounts.findIndex((p) => p.isPrimary);

  return {
    primaryAccount: index > -1 ? banks.formatPendingAccount(pendingAccounts[index]) : null,
  };
};

export default {
  document: PRIMARY_ACCOUNT,
  formatter,
};
