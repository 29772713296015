import React, { useMemo } from 'react';

import { lowercase, newAutopilotCopy, precisionRound } from '@app/utils';
import { navigate, routes, open } from '@navigate';
import { Layout, Stack, SectionTitle } from '@layouts';
import { ComplexRow, Switch, Text, Button, Option } from '@uikit';
import { Asset } from '@uikit/components/Asset';
import { formatGoalName } from '@app/utils/format/formatGoals';
import {
  useQuery,
  queries,
  useMutation,
  mutations,
  updates,
  UpsertIncomePreferencesVars,
  UpsertIncomePreferencesResponse,
  IncomeSourcesQueryData,
  IncomeSourcesQueryVars,
} from '@data';

/**
 * Manage Income Source Page
 * */
const ManageIncomeSources = () => {
  const { loading, data } = useQuery<IncomeSourcesQueryData, IncomeSourcesQueryVars>(
    queries.INCOME_SOURCES,
    {
      returnPartialData: true,
      variables: {
        query: { prefix: '' },
        page: { pageSize: 50, pageNumber: 1 },
      },
    },
  );

  const [setTransferDelay] = useMutation<
    UpsertIncomePreferencesResponse,
    UpsertIncomePreferencesVars
  >(mutations.UPSERT_INCOME_PREFERENCES, {
    update: updates.UPSERT_INCOME_PREFERENCES,
  });

  const sections = useMemo(() => {
    const sorted = (data?.viewer?.incomeSources ? [...data?.viewer?.incomeSources] : []).sort(
      (a, b) => (a.text > b.text ? 1 : -1),
    );

    const { active, ignore } = sorted.reduce(
      (acc, src) => {
        if (src.incomeAutomationRule?.automationType === 'NEVER') {
          return { ...acc, ignore: [...acc.ignore, src] };
        } else {
          return { ...acc, active: [...acc.active, src] };
        }
      },
      { active: [], ignore: [] },
    );

    if (ignore.length > 0) {
      return [
        { title: '', sources: active },
        { title: 'Ignored', sources: ignore },
      ];
    } else {
      return [{ title: '', sources: active }];
    }
  }, [data]);

  const bankLinks = useMemo(() => {
    return data?.viewer?.bankLinks || [];
  }, [data]);

  const goals = useMemo(() => {
    return data?.viewer?.goals || [];
  }, [data]);

  const options = useMemo(() => {
    let bankCount = 0;

    bankLinks.forEach((b) => {
      b.accounts.forEach((acc) => {
        if (acc.isWatched) {
          bankCount++;
        }
      });
    });

    let totalPercentage = 0;
    let goalNames = [];

    goals.forEach((goal) => {
      if (goal.status === 'ACTIVE') {
        totalPercentage += goal.paycheckPercentage * 100;
        goalNames.push(lowercase(formatGoalName(goal)));
      }
    });

    let goalString = 'Account not set up';

    if (goalNames.length === 1) {
      goalString = `For ${goalNames[0]}`;
    }

    if (goalNames.length === 2) {
      goalString = `For ${goalNames[0]} and ${goalNames[1]}`;
    }

    if (goalNames.length === 3) {
      goalString = `For ${goalNames[0]}, ${goalNames[1]}, and ${goalNames[2]}`;
    }

    if (goalNames.length >= 4) {
      goalString = `For ${goalNames[0]}, ${goalNames[1]}, and ${goalNames.length - 2} others`;
    }

    return {
      bankCount,
      totalPercentage,
      goalString,
    };
  }, [bankLinks, goals]);

  const toggleTransferDelay = (selected) => {
    setTransferDelay({
      variables: {
        isDelayAutoIncome: selected,
      },
      optimisticResponse: {
        upsertIncomePreferences: {
          isDelayAutoIncome: selected,
          __typename: 'IncomePreferences',
        },
      },
    });
  };

  return (
    <Layout.Scroll>
      <Layout margins bottomSpace>
        <Stack spacing="1">
          <>
            {sections.map((section) => (
              <Stack key={section.title} bottomSpace>
                {section.sources.map((source) => {
                  const isAuto = source.incomeAutomationRule?.automationType === 'ALWAYS';
                  return (
                    <ComplexRow
                      disabledAppearance={source.incomeAutomationRule?.automationType === 'NEVER'}
                      loading={loading && !source.text}
                      key={source.id}
                      label={source.text}
                      sublabel={source.sourceDetail}
                      onPress={() => navigate(routes.INCOME_SOURCE_DETAIL, { sourceID: source.id })}
                      asset={{
                        ...Asset.configureSource(source),
                        shape: 'circle',
                        textSize: 'lg',
                        size: 'md',
                      }}
                      accessory={
                        <Text
                          size="fp"
                          weight={isAuto ? 'medium' : undefined}
                          color={isAuto ? 'income' : 'subtle'}
                        >
                          {
                            newAutopilotCopy[
                              source.incomeAutomationRule?.automationType || 'DEFAULT'
                            ]
                          }
                        </Text>
                      }
                    />
                  );
                })}
              </Stack>
            ))}
          </>
          {/* <Layout center topSpace>
            <Stack>
              <SectionTitle>Options</SectionTitle>

              <Stack spacing="0b">
                <Option
                  label="Autoroll delay"
                  description="Wait 12 hours before transfer"
                  loading
                  onPress={() =>
                    toggleTransferDelay(!data?.viewer?.incomePreferences?.isDelayAutoIncome)
                  }
                  accessory={
                    <Switch
                      isOn={data?.viewer?.incomePreferences?.isDelayAutoIncome}
                      onToggle={toggleTransferDelay}
                    />
                  }
                />

                <Option
                  label={`Rolling ${precisionRound(options.totalPercentage, 0)}%`}
                  description={options.goalString}
                  onPress={() => navigate(routes.PLAN_EDIT, { goBack: true })}
                />
                <Option
                  label={`${options.bankCount} connected bank accounts`}
                  accessory="link"
                  onPress={() => open(routes.LINKED_BANKS)}
                />
              </Stack>
            </Stack>
          </Layout> */}
        </Stack>
      </Layout>
    </Layout.Scroll>
  );
};

export const ManageIncomeSourcesView = {
  name: 'MANAGE_INCOME_SOURCES',
  component: ManageIncomeSources,
  options: {
    title: 'Manage',
    accentColor: 'income',
  },
};
