import gql from 'graphql-tag';
import access from 'safe-access';
import { formatSortedPlans } from '@app/utils';
import { SCORED_HEALTH_PLAN, TOP_PLANS } from '../fragments/healthPlans';

const CALIFORNIA = gql`
  query California($eid: ID) {
    viewerTwo {
      id
      caPlanSearch(explorerID: $eid) {
        plans {
          planID: id
          ...ScoredHealthPlan
        }
        topPlans {
          ...TopPlans
        }
      }
    }
  }
  ${SCORED_HEALTH_PLAN}
  ${TOP_PLANS}
`;

const formatter = (data, options) => {
  const get = access(data?.viewerTwo);
  const plans = get('caPlanSearch');

  const scoredPlans = formatSortedPlans({
    sortedPlans: plans,
  });

  return { scoredPlans };
};

export default {
  document: CALIFORNIA,
  formatter,
};
