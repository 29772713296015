import { date, required, acceptance } from 'redux-form-validators';
import { ARRAY_ERROR } from 'final-form';
import { subDays } from 'date-fns';

import { useCopy } from '@app/utils';
import { getYesterday } from '@app/utils/time';

import createValidator from './createValidator';
import { common, ssnValidation } from './primitives';
import dateValidation from './date';
import {
  validateApplicant,
  validateSEP,
  partnerApplicant,
  partnerApplicationContact,
  allScreeningPhase1,
  allScreening,
  forFinancialAssistance,
  memberInfoQuestions,
  phaseTwoMemberInfo,
} from './healthFields';

const PREFIX = 'catch.ede.validation';

export const requiredMsg = {
  id: `${PREFIX}.required`,
};
const rangeBefore90DaysAgoMsg = {
  id: `${PREFIX}.rangeBefore90DaysAgo`,
};
const rangeAfterTodayMsg = {
  id: `${PREFIX}.rangeAfterToday`,
};

export const dateFormatMsg = {
  id: `${PREFIX}.dateFormat`,
};

export const memberListForm = (values) => {
  const errors = {};
  if (!values.members || !values.members.length) {
    errors.members = { _error: 'At least one member required' };
  } else {
    const membersArrayErrors = values.members.reduce((acc, m, i) => {
      const mErrors = {};
      if (!m || !m.givenName) {
        mErrors.givenName = 'Required';
        acc[i] = mErrors;
      }
      if (!m || !m.age) {
        mErrors.age = 'Required';
        acc[i] = mErrors;
      }
      return acc;
    }, []);
    if (membersArrayErrors.length) {
      errors.members = membersArrayErrors;
    }
  }
  return errors;
};
export const screeningForm = {
  hasDependentsPhase1: createValidator({
    ...allScreeningPhase1,
    isDependentSingleChild25OrYounger: [required()],
    isStepchildOrGrandchild: [required()],

    isClaimingAllDependentsOnTax: [required(forFinancialAssistance)],
    areDependentsLivingWithParentNotOnTaxReturn: [required(forFinancialAssistance)],
  }),
  noDependentsPhase1: createValidator({
    ...allScreeningPhase1,
  }),
  hasDependents: createValidator({
    ...allScreening,
    areDependentsChildOrStepchildSingleUnder26: [required()],
    isClaimingAllDependentsOnTax: [required(forFinancialAssistance)],
    areDependentsLivingWithParentNotOnTaxReturn: [required(forFinancialAssistance)],
  }),
  noDependents: createValidator({
    ...allScreening,
  }),
};

export const screeningFormWarning = (values) => {
  // remove unnecessary warnings when fully transitioned to phase 2
  const supported = {
    areAllSameAddress: 'YES',
    isPlanningToFileTax: 'YES',
    isResponsibleForChildNotOnTaxReturn: 'NO',
    isAnyoneFullTimeStudent1822: 'NO',
    isPregnantOrHadChildLast60Days: 'NO',
    areCitizens: 'YES',
    isCanProvideSsn: 'YES',
    isApplicationNameSameAsSsn: 'NO',
    isNaturalizedOrDerivedCitizenship: 'NO',
    isIncarcerated: 'NO',
    isIndianOrAlaskan: 'NO',
    isOfferedIchra: 'NO',
    isCoveredThroughJobOrCobra: 'NO',
    isFormerFosterCareAndUnder25: 'NO',
    isClaimingAllDependentsOnTax: 'YES',
    isDependentSingleChild25OrYounger: 'YES',
    isStepchildOrGrandchild: 'NO',
    areDependentsChildOrStepchildSingleUnder26: 'YES',
    areDependentsLivingWithParentNotOnTaxReturn: 'NO',
  };
  const warnings = {};
  Object.keys(values).forEach((key) => {
    const val = values[key];
    if (typeof val === 'string' && val.length > 0) {
      if (!!supported[key] && supported[key] !== val) {
        warnings[key] = 'Unsupported case';
        warnings._warning = 'screening_failed';
      }
    }
  });
  return warnings;
};

export const idQuestionsForm = createValidator({
  idv0: [required()],
  idv1: [required()],
});

export const healthHouseholdContactForm = createValidator({
  preferredLanguage: [required()],
  noticeMethod: [required()],
  contactInfo: [required()],
  homeAddress_street1: [required()],
  homeAddress_zip: common.zipcode,
  homeAddress_city: [required()],
  homeAddress_state: [required()],
  mailingAddress_street1: [required()],
  mailingAddress_zip: common.zipcode,
  mailingAddress_city: [required()],
  mailingAddress_state: [required()],
});

export const memberInfoForm = {
  ONE: createValidator({ ...memberInfoQuestions, ...phaseTwoMemberInfo }),
  TWO: createValidator({ ...memberInfoQuestions, ...phaseTwoMemberInfo }),
};

export const immigrationDetailsForm = (presenceType, isGA = false) => {
  if (presenceType === 'citizen') {
    return createValidator({
      isNaturalizedCitizen: common.required,
    });
  } else if (presenceType === 'noncitizen') {
    if (isGA) {
      return createValidator({
        isLawfulPresenceStatus: common.required,
        isVeteran: common.required,
        lawfulPresenceGrantDate: common.required,
      });
    }

    return createValidator({
      isLawfulPresenceStatus: common.required,
    });
  }
};

export const applicantsForm = (values) => {
  const errors = {};
  if (values.applicants && values.applicants.length) {
    const applicantsArrayErrors = values.applicants.reduce((acc, m, i) => {
      const mErrors = validateApplicant(m);
      const hasError = Object.keys(mErrors).some((k) => mErrors[k]);
      if (hasError) {
        acc[i] = mErrors;
      }
      return acc;
    }, []);
    if (applicantsArrayErrors.length) {
      errors.members = applicantsArrayErrors;
    }
  }
  return errors;
};

export const removeApplicantForm = createValidator({
  removeReason: [required()],
  removeDate: [
    required({
      if: (values) => values.removeReason === 'DEATH' || values.removeReason === 'DIVORCE',
    }),
  ],
});

export const sepForm = (values) => {
  const errors = {};

  if (values.members && values.members.length) {
    const membersArrayErrors = values.members.reduce((acc, m, i) => {
      const mErrors = validateSEP(m);
      const hasError = Object.keys(mErrors).some((k) => mErrors[k]);

      if (hasError) {
        acc[i] = mErrors;
      }
      return acc;
    }, []);
    if (membersArrayErrors.length) {
      errors.members = membersArrayErrors;
    }
  }
  return errors;
};

export const magiForm = createValidator({
  blindOrDisabledIndicator: [required()],
  longTermCareIndicator: [required()],
});

export const incomeListForm = (values) => {
  const errors = { members: [] };
  if (!values.members?.some((m) => m.incomeThisMonth === 'YES')) {
    errors.members[ARRAY_ERROR] = 'No members earned income';
  }
  return errors;
};

export const incomeListMember = createValidator({
  incomeThisMonth: [required()],
});

// these validations with a period don't work, hacking a solution into the form for now
export const incomeConfirmForm = createValidator({
  'annualTaxIncome.isVariableIncome': [required()],
  isUnknownIncome: [required({ if: (values) => values.isVariableIncome === false })],
  hasReceivedUnemploymentCompensation: [required()],
});

export const medicaidDenialForm = createValidator({
  isMedicaidDenied: [required()],
  medicaidDeniedDate: [
    required({ if: (values) => values.isMedicaidDenied === 'YES' }),
    date({
      format: 'mm/dd/yyyy',
      if: (values) => values.isMedicaidDenied === 'YES',
      '>': subDays(new Date(), 90),
      '<': new Date(),
      msg: {
        '>': rangeBefore90DaysAgoMsg,
        '<': rangeAfterTodayMsg,
        format: dateFormatMsg,
      },
    }),
  ],
  isAppliedDuringOeOrLifeChange: [required({ if: (values) => values.isMedicaidDenied === 'YES' })],
  isEnrolledInHealthCoverage: [
    required({
      if: (values) => values.isAppliedDuringOeOrLifeChange === 'NO',
    }),
  ],
  isMedicaidEndingSoon: [required({ if: (values) => values.isMedicaidDenied === 'NO' })],
  isHouseholdChanged: [required({ if: (values) => values.isMedicaidEndingSoon === 'YES' })],
  hasLivedInUS5Years: [
    required({
      if: (values) => values.isMedicaidDeniedDueToImmigration === 'YES',
    }),
  ],
  isImmigrationStatusChangeSinceDenied: [
    required({
      if: (values) => values.hasLivedInUS5Years === 'YES',
    }),
  ],
  medicaidEndDate: [
    dateValidation,
    required({ if: (values) => values.isMedicaidEndingSoon === 'YES' }),
  ],
});

export const agreementsForm = createValidator({
  isMedicaidRequirementAgreement: [required()],
  isAbsentParentAgreement: [required()],
  isChangeInformationAgreement: [required()],
  isTerminateCoverageOtherMecFoundAgreement: [required()],
  isRenewalAgreement: [required()],
  renewEligibilityYearQuantity: [required({ if: (val, vals) => vals.isRenewalAgreement === 'NO' })],
  isPenaltyOfPerjuryAgreement: [required()],
  signatureText: [common.signatureRedux({ name: 'fullName' })],
});

export const dmiUploadForm = (values) => {
  const errors = {};
  if (!values.documents || !values.documents.length) {
    errors.documents = {
      _error: 'At least one document is required to submit',
    };
  }
  if (
    (!values.dmiDocumentType || !values.dmiDocumentType.length) &&
    (!values.sviDocumentType || !values.sviDocumentType.length)
  ) {
    errors.dmiDocumentType = {
      _error: 'Required',
    };
    errors.sviDocumentType = {
      _error: 'Required',
    };
  }
  return errors;
};

/**
 * made for California-only ID upload form
 */
const requireIfNotSSN = required({ unless: (values) => /SSN/.test(values?.type) });
const validDate = date({
  format: 'mm/dd/yyyy',
  unless: (values) => /SSN/.test(values?.type),
  message: {
    format: dateFormatMsg,
  },
});
export const idUploadForm = createValidator({
  upload: [required()],
  type: [required()],
  documentNumber: [requireIfNotSSN],
  issuedDate: [requireIfNotSSN, validDate],
  expirationDate: [requireIfNotSSN, validDate],
  issuingCountry: [required({ if: (values) => /PASSPORT/.test(values?.type) })],
  issuingState: [required({ if: (values) => /DRIVERS|STATE/.test(values?.type) })],
});

export const workHoursForm = (values, props) => {
  const errors = {};
  if (!values.members || !values.members.length) {
    errors.members = {
      _error: 'At least one child required',
    };
  } else {
    const hasSpouse = props.parents.length === 2;
    const membersArrayErrors = values.members.reduce((acc, m, i) => {
      const mErrors = {};
      if (!m || !m.parent1WeeklyWorkHourQuantity) {
        mErrors.parent1WeeklyWorkHourQuantity = 'Required';
        acc[i] = mErrors;
      }
      if (hasSpouse && (!m || !m.parent2WeeklyWorkHourQuantity)) {
        mErrors.parent2WeeklyWorkHourQuantity = 'Required';
        acc[i] = mErrors;
      }
      return acc;
    }, []);
    if (membersArrayErrors.length) {
      errors.members = membersArrayErrors;
    }
  }
  return errors;
};

export const proAssistanceForm = (values) => {
  const errors = {};
  if (!values.isProfessionalAssistance) {
    errors.isProfessionalAssistance = {
      _error: 'Required',
    };
  }

  const applicationAssistors = values?.applicationAssistors?.filter(
    (ass) => ass.type !== 'AGENT_OR_BROKER',
  );
  if (values.isProfessionalAssistance === 'YES' && !(applicationAssistors?.length > 0)) {
    errors.assistorTypes = {
      _error: 'Required',
    };
  }

  const assistorErrors = applicationAssistors?.reduce((acc, a, i) => {
    const aErrors = {};
    if (!a.firstName) {
      aErrors.firstName = 'Required';
      acc[i] = aErrors;
    }
    if (!a.lastName) {
      aErrors.lastName = 'Required';
      acc[i] = aErrors;
    }
    switch (a.type) {
      case 'AGENT_OR_BROKER':
        if (!a.assistorNPN) {
          aErrors.assistorNPN = 'Required';
        }
        break;
      default:
        break;
    }
    return acc;
  }, []);
  if (assistorErrors.length) {
    errors.assistors = assistorErrors;
  }
  return errors;
};

export const householdForm = createValidator({
  maritalStatus: [required()],
});

export const applicationImportForm = createValidator({
  applicationID: [required()],
});

export const partnerApplicationForm = (relation) =>
  createValidator(
    relation === 'SELF'
      ? {
          ...partnerApplicant,
          ...partnerApplicationContact,
        }
      : partnerApplicant,
  );

export const CICCancelPolicy = ({ policy, applicantName }) => {
  const { c } = useCopy('catch.validations');

  return createValidator({
    date: [
      date({
        format: 'mm/dd/yyyy',
        '<=': new Date(policy?.endDate),
        '>': new Date(policy?.startDate),
        msg: {
          '<=': c('endDate.before', { endDate: 'the policy end date' }),
          '>': c('endDate.after', { date: 'the policy start date' }),
          format: c('date'),
        },
      }),
    ],
    signature: [common.signature(applicantName)],
  });
};

export const removeMemberCIC = (val) => {
  const validateMember = createValidator({ removeDate: common.date });
  let errors = {};
  if (
    !val?.removeMembers?.length ||
    (!!val?.removeMembers &&
      val?.removeMembers?.length > 0 &&
      !val.removeMembers.some((d) => d.removeDate))
  ) {
    errors['general'] = 'required';
  }
  const removeMembersErrors = val.removeMembers.reduce((acc, d, i) => {
    const removeMemberError = validateMember(d);
    const hasError = Object.keys(removeMemberError).some((k) => removeMemberError[k]);
    if (hasError) {
      acc[i] = removeMemberError;
    }
    return acc;
  }, []);
  if (removeMembersErrors.length) {
    errors.removeMembers = removeMembersErrors;
  }
  return errors;
};
