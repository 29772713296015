import React from 'react';

import { ChartProps } from './types';
import Donut from './base/Donut';
import { CatchTheme } from '@app/_ui-kit';

interface TaxQuarterDonutProps extends ChartProps {}

/**
 *
 */
export const TaxQuarterDonut = ({
  quarter,
  percent,
  size = CatchTheme.constants.rootHeaderHeight / 2,
  color,
  gauge,
}: TaxQuarterDonutProps) => {
  const data = [
    { x: 'Q', y: percent, color: color || 'taxes' },
    { x: 'L', y: 1 - percent, color: 'border' },
  ];

  return (
    <Donut
      data={data}
      text={quarter}
      size={size}
      startAngle={gauge ? -125 : undefined}
      endAngle={gauge ? 125 : undefined}
    />
  );
};

export default TaxQuarterDonut;
