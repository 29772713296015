// @ts-nocheck
import React, { useMemo } from 'react';
import {
  useQuery,
  queries,
  HealthExplorerQueryData,
  useMutation,
  UpsertExplorerVars,
  UpsertExplorerResponse,
  mutations,
  updates,
} from '@data';
import { navigate, pop, routes, ScreenDefinition } from '@navigate';
import { BasicFormBlueprint } from '@app/blueprints';
import { useCopy } from '@app/utils';

const ExplorerHousehold = () => {
  const { c } = useCopy('catch.healthExplorer.explorerTaxHousehold');

  const { data } = useQuery<HealthExplorerQueryData>(queries.HEALTH_EXPLORER);
  const explorer = useMemo(() => {
    return data?.viewerTwo.healthExplorerData;
  }, [data?.viewerTwo.healthExplorerData]);

  const income = useMemo(() => {
    return explorer?.income || data?.viewer.user.householdIncome;
  }, [data?.viewer.user.householdIncome, explorer]);

  const [upsert, { loading: upserting }] = useMutation<UpsertExplorerResponse, UpsertExplorerVars>(
    mutations.UPSERT_EXPLORER,
    {
      update: updates.UPDATE_EXPLORER,
      onCompleted: () => {
        navigate(routes.EXPLORER_SAVINGS, {
          pathwayType: explorer?.pathwayType,
          explorerID: explorer?.id,
        });
      },
    },
  );

  const onSubmit = (values) => {
    upsert({ variables: { input: { income: values.income } } });
  };

  return (
    <BasicFormBlueprint
      loading={!data}
      submitting={upserting}
      title={c('title')}
      onBack={pop}
      formConfig={{
        initialValues: { income },
        fields: [
          {
            type: 'amount',
            name: 'income',
            amountType: 'integer',
            required: true,
            large: true,
            subtext:
              'Combined total earnings (before taxes) for everyone in your tax household. Your best guess is fine.',
          },
        ],
        onSubmit,
      }}
    />
  );
};

export const ExplorerHouseholdView: ScreenDefinition = {
  name: routes.EXPLORER_HOUSEHOLD,
  component: ExplorerHousehold,
  options: {},
};
