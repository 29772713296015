import React from 'react';
import shallow from 'zustand/shallow';
import { useAuth, useAuthEvents } from '@client';
import { exit, routes } from '@navigate';
import { useCopy } from '@app/utils';
import { Banner, Button, popToast } from '@uikit';
import { BasicFormBlueprint } from '@app/blueprints';
import { Toolbar } from '@app/layouts';

const ChangePassword = () => {
  const { c } = useCopy('catch');

  const { loading, error, changePassword } = useAuth(
    (state) => ({
      loading: state.loading,
      error: state.error,
      changePassword: state.changePassword,
    }),
    shallow,
  );

  useAuthEvents({
    onPasswordChanged: async () => {
      popToast({
        type: 'success',
        title: c('profile.ChangePassword.successTitle'),
      });
      exit();
    },
  });

  return (
    <BasicFormBlueprint
      loading={false}
      submitting={loading}
      title={c('profile.ChangePassword.title')}
      error={error}
      formConfig={{
        initialValues: {},
        fields: [
          {
            name: 'oldPassword',
            type: 'password',
            passwordType: 'existing',
            label: c('PasswordField.oldPwLabel'),
            requirements: false,
            required: true,
          },
          {
            name: 'newPassword',
            type: 'password',
            passwordType: 'new',
            label: c('PasswordField.changePwLabel'),
            required: true,
          },
        ],
        onSubmit: (values) => {
          changePassword(values);
        },
      }}
      toolbar={({ disabled, handleSubmit }) => (
        <Toolbar>
          <Button testID="cancel" alt onPress={() => exit()}>
            {c('profile.ChangePassword.cancelBtn')}
          </Button>
          <Button testID="submit" disabled={disabled} onPress={handleSubmit}>
            {c('profile.ChangePassword.submitBtn')}
          </Button>
        </Toolbar>
      )}
      postContent={error && <Banner bg="negativeLight" title={error} />}
    />
  );
};

export const ChangePasswordView = {
  name: routes.CHANGE_PASSWORD,
  component: ChangePassword,
  options: {
    bg: 'sheet',
    layout: 'sheet',
  },
};
