import { constants } from '@util';

const applicationAssistorTypesEnum = Object.entries(constants.applicationAssistorTypes).reduce(
  (acc, entry) => {
    return {
      ...acc,
      [entry[1]]: entry[0],
    };
  },
  {},
);

export const getInitialValues = ({ applicationAssistors }) => {
  const initialApplicationAssistors = Object.fromEntries([
    ...applicationAssistors.map(({ applicationAssistorType, type, ...aa }) => [
      applicationAssistorTypesEnum[applicationAssistorType],
      {
        type: applicationAssistorTypesEnum[applicationAssistorType],
        ...aa,
        name: {
          givenName: aa.firstName,
          familyName: aa.lastName,
          middleName: aa.middleName,
          nameSuffix: aa.suffix,
        },
        // RN will get weird if initial value is a number
        assistorNPN: aa.assistorNPN ? String(aa.assistorNPN) : aa.assistorNPN,
      },
    ]),

    // Always make sure `AGENT_OR_BROKER` is selected.
    ['AGENT_OR_BROKER', {}],
  ]);

  const initialAssistorTypes = Object.keys(initialApplicationAssistors);

  return {
    isProfessionalAssistance: applicationAssistors.length > 0 ? 'YES' : undefined,
    applicationAssistors: initialApplicationAssistors,
    assistorTypes: initialAssistorTypes,
  };
};

type ApplicationAssistorData = {
  organizationName: string;
  organizationID: string;
  assistorNPN?: string;
  name: {
    givenName: string;
    familyName: string;
    middleName: string;
    nameSuffix: string;
  };
};

export const formatPayload = ({ applicationID, values }) => {
  const rawApplicationAssistors: Record<string, ApplicationAssistorData> =
    values.applicationAssistors || {};

  if ('AGENT_OR_BROKER' in rawApplicationAssistors) {
    delete rawApplicationAssistors['AGENT_OR_BROKER'];
  }

  const reformatedAssistors =
    values.isProfessionalAssistance === 'YES'
      ? Object.entries(rawApplicationAssistors)
          .map(([type, data]) => ({
            applicationAssistorType: constants.applicationAssistorTypes[type],
            type: type,
            organizationName: data.organizationName,
            organizationID: data.organizationID,
            firstName: data.name.givenName,
            lastName: data.name.familyName,
            middleName: data.name.middleName,
            suffix: data.name.nameSuffix,
            assistorNPN: data.assistorNPN ? Number(data.assistorNPN) : data.assistorNPN,
          }))
          .filter(({ applicationAssistorType }) => !!applicationAssistorType)
      : [];

  return {
    id: applicationID,
    lastUsedRoute: 'pro-assistance',
    applicationAssistors: reformatedAssistors,
  };
};
