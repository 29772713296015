// @ts-nocheck
import React, { useMemo } from 'react';
import { View } from 'react-native';
import { Card, Stack, ExplorerLayout, Layout } from '@layouts';
import { BigMoney } from '@common';
import { useDeprecatedQuery, useDeprecatedMutation } from '@data';
import { useCopy, determineSavingsEligibility, getCarrierAsset } from '@app/utils';
import { navigate, exit, routes, ScreenDefinition, open } from '@navigate';
import { Text, MedicaidMedicareChip, Link, Asset, Loading, AssetsGroup, Button } from '@uikit';

const MEDEligibilityPage = ({ eligibility, applicants }) => {
  return (
    <>
      <MedicaidMedicareChip />
      <EligibilityText eligibility={eligibility} applicants={applicants} />
    </>
  );
};

/**
 * @todo we need to change the copy to sound like less of a guarantee, per CMS
 */
export const APTCEligibility = ({
  aptcEligible,
  csrEligible,
  amount,
  loading,
  screening = false,
}) => {
  const { c } = useCopy('catch.healthExplorer.Savings');
  const { c: period } = useCopy('catch.period');

  return (
    <Card shadow>
      <Stack spacing="1">
        {!screening && (
          <Layout.Header
            align="center"
            loading={loading}
            title={c('aptcTitle', { aptcEligible })}
            subtitle={c('aptcDescription', { aptcEligible })}
          />
        )}
        {!!aptcEligible && (
          <View style={{ alignItems: 'center' }}>
            <BigMoney
              amount={Math.round(amount)}
              digits={0}
              color="debit"
              countup
              label={period('monthly')}
            />
            <Text size="fp" weight="medium" color="debit">
              {c('estimatedTitle')}
            </Text>
          </View>
        )}
        {!!csrEligible && (
          <>
            {!screening && (
              <>
                <Text color="debit" weight="bold" align="center">
                  {c('bonusTitle')}
                </Text>
                <Text size="fp" align="center" color="subtle">
                  {c('csrDefinition', { level: 'Silver' })}
                </Text>
              </>
            )}
          </>
        )}
      </Stack>
    </Card>
  );
};

const AvailablePlans = ({ plans = [], aptcEligible }) => {
  const { $ } = useCopy('catch.healthBasics');
  const logos = Array.from(new Set(plans.map((p) => p?.healthPlan?.provider)));
  const premiums = plans.reduce(
    (acc, p) => {
      acc.originals.push(p?.healthPlan?.originalPremium);
      acc.savings.push(p?.healthPlan?.premium);
      return acc;
    },
    { originals: [], savings: [] },
  );

  const lowestPremium = Math.min(...premiums.savings);
  const lowestOriginalPremium = Math.min(...premiums.originals);

  return (
    <Stack spacing="+gap">
      <AssetsGroup
        containerSize="lg"
        size="sm"
        bg="sheet"
        shadow="major"
        assets={logos?.slice(0, 4)?.map((c) => getCarrierAsset(c))}
      />
      {plans.length === 0 ? (
        <Text inline size="h2" weight="bold">
          No plans available
        </Text>
      ) : (
        <Text inline size="h2" weight="bold">
          {plans.length} plans starting at{' '}
          {/** standardize the display of "was this much, is now this much" info */}
          {aptcEligible && lowestOriginalPremium > lowestPremium && (
            <Text size="h2" color="subtle" weight="regular" strike>
              {$(lowestOriginalPremium, { whole: 0 })}{' '}
            </Text>
          )}
          {$(Math.max(0, lowestPremium), { whole: 0 })}
        </Text>
      )}
    </Stack>
  );
};

/**
 * @params {stack} one of CHIP, MEDICARE
 * @params {applicants} one of SPOUSE, CHILD
 */
const EligibleCard = ({ stack, applicants, count }) => {
  const { c } = useCopy('catch.healthExplorer.Savings');

  return (
    <Card padded shadow>
      <Asset icon={MedicaidMedicareChip} />
      <Text size="p" weight="bold" align="center">
        {c('eligibilityTitle', {
          eligibility: stack,
          applicants,
        })}
      </Text>
      <Text align="center" size="fp" gutter="top">
        {c('eligibilityp1', {
          eligibility: stack,
          link: (
            <Link
              small
              inline
              target="_blank"
              href="https://www.healthcare.gov/medicaid-chip/childrens-health-insurance-program/"
            >
              CHIP
            </Link>
          ),
        })}
      </Text>
      <Text gutter="top" align="center" size="fp">
        {c('eligibilityp2', {
          eligibility: stack,
          linkMedicaid: <Link inline>apply directly</Link>,
          linkMedicare: <Link inline>Learn more about Medicare</Link>,
          count,
        })}
      </Text>
    </Card>
  );
};

/**
 * @params {eligibility} one of MEDICAID, MEDICARE
 * @params {applicants} one of SELF, SPOUSE, CHILD, ALL
 */
const EligibilityText = ({ eligibility, applicants, count }) => {
  const { c } = useCopy('catch.healthExplorer.Savings');
  const { c: applyCopy } = useCopy('catch.healthExplorer.SEPIneligible');

  const [startEnrollment] = useDeprecatedMutation('upsertEnrollmentFromExplorer', {
    onCompleted: (enrollment) => {
      open(routes.EDE_INTRO, {
        enrollId: enrollment.id,
        pathwayType: enrollment.pathwayType,
      });
    },
  });

  return (
    <>
      <Layout.Header
        title={c('eligibilityTitle', {
          eligibility,
          applicants,
        })}
        subtitles={[
          c('eligibilityp1', {
            eligibility,
            link: <Link inline>CHIP</Link>,
          }),
          c('eligibilityp2', {
            eligibility,
            linkMedicaid: (
              <Link inline onPress={startEnrollment}>
                apply through Catch.
              </Link>
            ),
            linkMedicare: <Link inline>Learn more about Medicare</Link>,
            count,
          }),
        ]}
      />

      {applicants === 'ALL' && eligibility === 'MEDICARE' && (
        <Text size="fp" align="center">
          {applyCopy('apply', {
            link: (
              <Link inline small onPress={startEnrollment}>
                apply for coverage
              </Link>
            ),
          })}
        </Text>
      )}
    </>
  );
};

/**
 * Shows intiial eligibility
 * @todo visuals
 */
const ExplorerSavings = ({ explorerID, pathwayType, handleNext }) => {
  const {
    loading,
    applicants: members,
    scoredPlans,
    eligibilityByMember,
  } = useDeprecatedQuery('explorer', { skip: !pathwayType || pathwayType !== 'EDE' });

  /*
   * note: the CA schema is used for all non-FFM states
   */
  const { scoredPlans: caPlans, loading: caLoading } = useDeprecatedQuery('california', {
    variables: { eid: explorerID },
    skip: pathwayType === 'EDE',
  });

  const childrenCount = members?.filter((d) => d.relation === 'CHILD')?.length;

  const { eligibility, stack, applicants, aptcEligible, csrEligible, amount, level } = useMemo(
    () => determineSavingsEligibility(eligibilityByMember),
    [eligibilityByMember],
  );

  /*
   * this is a temporary stand in
   * in PX, we use viewerTwo.publicHealthEligibility
   * but there is no in-app equivalent for non-FFM states it seems?
   */
  const altAptc = (caPlans?.plans || [])?.reduce((prev, curr) => {
    const discount = curr.healthPlan.originalPremium - curr.healthPlan.premium;
    return discount > prev ? discount : prev;
  }, 0);

  // handles medicare/medicaid handoff eligibility
  const handoff = (type) => {
    exit(routes.HOME);
  };

  const plans = pathwayType === 'EDE' ? scoredPlans : caPlans;

  return (
    <ExplorerLayout
      onNext={handleNext}
      customToolbar={
        /MEDICAID|MEDICARE/.test(eligibility) && (
          <Button onPress={() => handoff(eligibility)}>Apply now</Button>
        )
      }
    >
      {loading || caLoading || !pathwayType ? (
        <Loading accentColor="coverage" />
      ) : /MEDICAID|MEDICARE/.test(eligibility) ? (
        <MEDEligibilityPage eligibility={eligibility} applicants={applicants} />
      ) : (
        <Stack spacing="1">
          <AvailablePlans
            plans={plans?.plans}
            aptcEligible={aptcEligible || altAptc > 0}
            lowestPremium={plans?.lowestPremium}
            lowestOriginalPremium={plans?.lowestOriginalPremium}
          />
          <APTCEligibility
            aptcEligible={aptcEligible || altAptc > 0}
            csrEligible={csrEligible}
            amount={amount || altAptc}
            level={level}
          />
          {!!stack && !!applicants && (
            <EligibleCard stack={stack} applicants={applicants} count={childrenCount} />
          )}
        </Stack>
      )}
    </ExplorerLayout>
  );
};

export const ExplorerSavingsView: ScreenDefinition = {
  name: routes.EXPLORER_SAVINGS,
  component: ExplorerSavings,
  options: {
    title: 'Savings',
  },
};
