export type Route =
  | 'LOBBY'
  | 'LOGIN'
  | 'REGISTER'
  | 'CONFIRM_CODE'
  | 'DEV'
  | 'FORGOT_PASSWORD'
  | 'CONFIRM_PASSWORD'
  | 'WELCOME'
  // | 'SETUP_INCOME_SOURCES'
  | 'ADD_BANK_LINK'
  | 'ONBOARDING_BIOMETRICS'
  | 'SET_NOTIFICATIONS'
  | 'HOME'
  | 'ACTIVITY'
  | 'ANNUAL_REVIEW'
  | 'INCOME'
  | 'INCOME_STATS'
  | 'INCOME_SKIPPED'
  | 'INCOME_SOURCE_DETAIL'
  | 'PLAN'
  | 'PLAN_DETAIL'
  | 'PLAN_EDIT'
  | 'COVERAGE'
  | 'COVERAGE_DETAIL'
  | 'WALLET_ITEM'
  | 'ACCOUNT'
  | 'ACCOUNT_INFO'
  | 'LINKED_BANKS'
  | 'ACCOUNT_SETTINGS'
  | 'CHANGE_ALIAS'
  | 'CHANGE_PASSWORD'
  | 'EDIT_USER_DETAILS'
  | 'REFERRAL'
  | 'STATEMENTS'
  | 'TRANSFER_AMOUNT'
  | 'TRANSFER_CONFIRM'
  | 'PAYCHECK_SKIPPED'
  | 'PAYCHECK_INCOME'
  | 'PAYCHECK_BREAKDOWN'
  | 'EVENT_RECEIPT'
  | 'TAX_PAYMENT'
  | 'TAX_PAYMENT_HISTORY'
  | 'MANAGE_INCOME_SOURCES'
  // | 'ADD_INCOME_SOURCE'
  | 'EFTPS_AUTH'
  | 'TAX_PAYMENT_INTRO'
  | 'TAX_PAYMENT_AMOUNT'
  | 'TAX_PAYMENT_CONFIRM'
  | 'AUTO_TAX_PAYMENT_INTRO'
  | 'GOAL_INTRO'
  | 'GOAL_ESTIMATOR'
  | 'GOAL_CONFIRM'
  | 'GOAL_EMPLOYMENT_TYPE'
  | 'GOAL_ESTIMATED_INCOME'
  | 'GOAL_WORK_STATE'
  | 'GOAL_TAX_FILING_STATUS'
  | 'GOAL_SPOUSE_INCOME'
  | 'GOAL_DEPENDENTS'
  | 'RETIREMENT_CURRENT_SAVINGS'
  | 'RETIREMENT_RISK_LEVEL'
  | 'RETIREMENT_RISK_COMFORT'
  | 'RETIREMENT_PORTFOLIO_SELECTION'
  | 'RETIREMENT_PORTFOLIO_TYPE'
  | 'RETIREMENT_ACCOUNT_SELECTION'
  | 'RETIREMENT_ACCOUNT_TYPE'
  | 'ACCOUNT_SETUP_INTRO'
  | 'ACCOUNT_SETUP_NAME'
  | 'ACCOUNT_SETUP_PHONE'
  | 'ACCOUNT_SETUP_EMAIL'
  | 'ACCOUNT_SETUP_CODE'
  | 'ACCOUNT_SETUP_DOB'
  | 'ACCOUNT_SETUP_ADDRESS'
  | 'ACCOUNT_SETUP_SSN'
  | 'ACCOUNT_SETUP_CONFIRM'
  | 'DOC_UPLOAD'
  | 'ACCOUNT_SETUP_DENIED'
  | 'FINANCIAL_SCREENING'
  | 'FINANCIAL_SCREENING_DENIED'
  | 'INVESTMENT_AGREEMENT'
  | 'GOAL_EDIT'
  | 'GOAL_CUSTOMIZE'
  | 'RETIREMENT_CHANGE_PORTFOLIO'
  | 'HEALTH_RESUME'
  | 'EDE_INTRO'
  | 'EDE_COVERAGE_INFO'
  | 'EDE_PRIVACY'
  | 'EDE_HOUSEHOLD'
  | 'EDE_SCREENING'
  | 'EDE_PRO_ASSISTANCE'
  | 'EDE_ID_INFO'
  | 'EDE_IDENTITY'
  | 'EDE_APPLICATION_IMPORT'
  | 'EDE_CONFIRM_APPLICANTS'
  | 'EDE_WINDOW_SHOPPING'
  | 'EDE_MEMBER_INFO'
  | 'EDE_MEMBER_QUESTIONS'
  | 'EDE_IMMIGRATION_DETAILS'
  | 'EDE_MEDICAID_DENIAL'
  | 'EDE_MEMBER_INCOME_LIST'
  | 'EDE_MEMBER_DEDUCTIONS_LIST'
  | 'EDE_MEMBER_INCOME_DETAILS'
  | 'EDE_MEMBER_INCOME_CONFIRM'
  | 'EDE_MEMBER_INCOME_DISCREPANCY'
  | 'EDE_SEP'
  | 'EDE_MEMBER_MEDICAL_BILLS'
  | 'EDE_DEPENDENTS_CURRENT_COVERAGE'
  | 'EDE_OUTSIDE_HOUSEHOLD'
  | 'EDE_MEMBER_WORK_HOURS'
  | 'EDE_OTHER_COVERAGE'
  | 'EDE_REVIEW'
  | 'EDE_AGREEMENTS'
  | 'EDE_RESULTS'
  | 'EDE_DMI'
  | 'EDE_SPLIT_ELIGIBILITY'
  | 'EDE_PLANS'
  | 'EDE_APTC_AGREEMENT'
  | 'EDE_ENROLL'
  | 'EDE_SUCCESS'
  | 'EDE_HELP'
  | 'EDE_SEP_OUT_OF_DATE'
  | 'HEALTH_LINK_INTRO'
  | 'HEALTH_LINK_AGREEMENTS'
  | 'HEALTH_LINK_IDENTITY'
  | 'HEALTH_LINK_IMPORT'
  | 'CIC_MANAGE_COVERAGE_INTRO'
  | 'CIC_CHANGE_SELECTION'
  | 'CIC_BASICS'
  | 'CIC_ADDRESS_ENTRY'
  | 'CIC_NO_CHANGE'
  | 'CIC_STATE_HANDOFF'
  | 'CIC_STATE_PICKUP'
  | 'CIC_CANCEL_POLICY'
  | 'CIC_GOV_HANDOFF'
  | 'CIC_GOV_PICKUP'
  | 'CIC_APP_REVIEW_INTRO'
  | 'CIC_MEMBER_MARRIAGE'
  | 'CIC_MEMBER_CUT_INTRO'
  | 'CIC_MOVE_DATE_ENTRY'
  | 'CIC_MEMBER_DIVORCE'
  | 'CIC_MEMBER_DEATH'
  | 'CIC_MEMBER_CUSTODY'
  | 'CIC_MEMBER_JOB_COVERAGE'
  | 'CIC_MEMBER_STATUS_CHANGE'
  | 'CIC_REMOVING_MEMBERS'
  | 'CIC_POLICY_INVALID'
  | 'CIC_NEW_PLANS_AVAILABLE'
  | 'CIC_NEW_PLAN_REQUIRED'
  | 'CIC_PRELIM_ELIG'
  | 'CIC_MEMBER_CUT'
  | 'CIC_SEP_MIXED'
  | 'CIC_SSN_ENTRY'
  | 'CIC_INCOME_ENTRY'
  | 'EXPLORER_INTRO'
  | 'EXPLORER_ZIPCODE'
  | 'EXPLORER_STATE_HANDOFF'
  | 'EXPLORER_APPLICANTS'
  | 'EXPLORER_HOUSEHOLD'
  | 'EXPLORER_SAVINGS'
  | 'EXPLORER_RISK_TOLERANCE'
  | 'EXPLORER_USAGE'
  | 'EXPLORER_PRESCRIPTIONS'
  | 'EXPLORER_DOCTORS'
  | 'EXPLORER_SEP_INELIGIBLE'
  | 'EXPLORER_CHOOSE_PLAN'
  | 'EXPLORER_SEP'
  | 'EXPLORER_QLE'
  | 'STATE_RETURN'
  | 'TAXES'
  | 'SETUP_PAYROLL'
  | 'EMPLOYMENT_TYPE'
  | 'ESTIMATED_INCOME'
  | 'WORK_STATE'
  | 'TAX_FILING_STATUS'
  | 'DEPENDENTS'
  | 'SPOUSE_INCOME'
  | 'OTHER_WITHHOLDING'
  | 'SETUP_ESTIMATOR'
  | 'PAYROLL_SUMMARY'
  | 'HEALTH_SLASHER_INTRO'
  | 'HEALTH_SLASHER_TRIAGE'
  | 'HEALTH_SLASHER_INCOME_ENTRY'
  | 'HEALTH_SLASHER_INCOME_LIST'
  | 'HEALTH_SLASHER_INCOME_DETAILS'
  | 'HEALTH_SLASHER_INCOME_CONFIRM'
  | 'HEALTH_SLASHER_AGREEMENTS'
  | 'HEALTH_SLASHER_HICCUP'
  | 'HEALTH_SLASHER_RESULTS'
  | 'HEALTH_SLASHER_SUCCESS'
  | 'HEALTH_RENEW_INTRO'
  | 'HEALTH_RENEW_AGREEMENTS'
  | 'HEALTH_RENEW_IDENTITY'
  | 'HEALTH_RENEW_IMPORT'
  | 'HEALTH_RENEW_DETAILS'
  | 'HEALTH_RENEW_PLAN'
  | 'HEALTH_RENEW_RESUBMIT'
  | 'HEALTH_RENEW_SUCCESS'
  | 'UPSERT_BENEFICIARY';
/**
 * Navigatable places
 * for example, we can navigate to all of these as
 */
export const routes: Record<Route, Route> = {
  LOBBY: 'LOBBY',
  LOGIN: 'LOGIN',
  REGISTER: 'REGISTER',
  CONFIRM_CODE: 'CONFIRM_CODE',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  CONFIRM_PASSWORD: 'CONFIRM_PASSWORD',
  WELCOME: 'WELCOME',
  ADD_BANK_LINK: 'ADD_BANK_LINK',
  ONBOARDING_BIOMETRICS: 'ONBOARDING_BIOMETRICS',
  ANNUAL_REVIEW: 'ANNUAL_REVIEW',

  // personal payroll
  SETUP_PAYROLL: 'SETUP_PAYROLL',
  // SETUP_INCOME_SOURCES: 'SETUP_INCOME_SOURCES',
  EMPLOYMENT_TYPE: 'EMPLOYMENT_TYPE',
  ESTIMATED_INCOME: 'ESTIMATED_INCOME',
  WORK_STATE: 'WORK_STATE',
  TAX_FILING_STATUS: 'TAX_FILING_STATUS',
  DEPENDENTS: 'DEPENDENTS',
  SPOUSE_INCOME: 'SPOUSE_INCOME',
  OTHER_WITHHOLDING: 'OTHER_WITHHOLDING',
  SETUP_ESTIMATOR: 'SETUP_ESTIMATOR',
  PAYROLL_SUMMARY: 'PAYROLL_SUMMARY',

  /** */
  SET_NOTIFICATIONS: 'SET_NOTIFICATIONS',
  HOME: 'HOME',
  ACTIVITY: 'ACTIVITY',
  DEV: 'DEV',
  INCOME: 'INCOME',
  INCOME_SOURCE_DETAIL: 'INCOME_SOURCE_DETAIL',
  INCOME_STATS: 'INCOME_STATS',
  INCOME_SKIPPED: 'INCOME_SKIPPED',

  PLAN: 'PLAN',
  PLAN_EDIT: 'PLAN_EDIT',
  PLAN_DETAIL: 'PLAN_DETAIL',
  COVERAGE: 'COVERAGE',
  COVERAGE_DETAIL: 'COVERAGE_DETAIL',
  WALLET_ITEM: 'WALLET_ITEM',
  ACCOUNT: 'ACCOUNT',
  ACCOUNT_INFO: 'ACCOUNT_INFO',
  EDIT_USER_DETAILS: 'EDIT_USER_DETAILS',
  LINKED_BANKS: 'LINKED_BANKS',

  ACCOUNT_SETTINGS: 'ACCOUNT_SETTINGS',
  CHANGE_ALIAS: 'CHANGE_ALIAS',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  REFERRAL: 'REFERRAL',
  STATEMENTS: 'STATEMENTS',
  TRANSFER_AMOUNT: 'TRANSFER_AMOUNT',
  TRANSFER_CONFIRM: 'TRANSFER_CONFIRM',
  PAYCHECK_SKIPPED: 'PAYCHECK_SKIPPED',
  PAYCHECK_INCOME: 'PAYCHECK_INCOME',
  PAYCHECK_BREAKDOWN: 'PAYCHECK_BREAKDOWN',
  EVENT_RECEIPT: 'EVENT_RECEIPT',
  TAXES: 'TAXES',
  TAX_PAYMENT: 'TAX_PAYMENT',
  TAX_PAYMENT_HISTORY: 'TAX_PAYMENT_HISTORY',
  MANAGE_INCOME_SOURCES: 'MANAGE_INCOME_SOURCES',
  // ADD_INCOME_SOURCE: 'ADD_INCOME_SOURCE',
  UPSERT_BENEFICIARY: 'UPSERT_BENEFICIARY',

  // goal standalone setup
  GOAL_INTRO: 'GOAL_INTRO',
  GOAL_EMPLOYMENT_TYPE: 'GOAL_EMPLOYMENT_TYPE',
  GOAL_ESTIMATED_INCOME: 'GOAL_ESTIMATED_INCOME',
  GOAL_WORK_STATE: 'GOAL_WORK_STATE',
  GOAL_TAX_FILING_STATUS: 'GOAL_TAX_FILING_STATUS',
  GOAL_SPOUSE_INCOME: 'GOAL_SPOUSE_INCOME',
  GOAL_DEPENDENTS: 'GOAL_DEPENDENTS',
  GOAL_ESTIMATOR: 'GOAL_ESTIMATOR',
  GOAL_CONFIRM: 'GOAL_CONFIRM',

  // tax payment
  TAX_PAYMENT_INTRO: 'TAX_PAYMENT_INTRO',
  TAX_PAYMENT_AMOUNT: 'TAX_PAYMENT_AMOUNT',
  TAX_PAYMENT_CONFIRM: 'TAX_PAYMENT_CONFIRM',
  AUTO_TAX_PAYMENT_INTRO: 'AUTO_TAX_PAYMENT_INTRO',

  // retirement
  RETIREMENT_CURRENT_SAVINGS: 'RETIREMENT_CURRENT_SAVINGS',
  RETIREMENT_RISK_LEVEL: 'RETIREMENT_RISK_LEVEL',
  RETIREMENT_RISK_COMFORT: 'RETIREMENT_RISK_COMFORT',
  RETIREMENT_PORTFOLIO_SELECTION: 'RETIREMENT_PORTFOLIO_SELECTION',
  RETIREMENT_ACCOUNT_SELECTION: 'RETIREMENT_ACCOUNT_SELECTION',
  RETIREMENT_ACCOUNT_TYPE: 'RETIREMENT_ACCOUNT_TYPE',
  RETIREMENT_PORTFOLIO_TYPE: 'RETIREMENT_PORTFOLIO_TYPE',
  FINANCIAL_SCREENING: 'FINANCIAL_SCREENING',
  FINANCIAL_SCREENING_DENIED: 'FINANCIAL_SCREENING_DENIED',

  // account setup
  ACCOUNT_SETUP_INTRO: 'ACCOUNT_SETUP_INTRO',
  ACCOUNT_SETUP_NAME: 'ACCOUNT_SETUP_NAME',
  ACCOUNT_SETUP_PHONE: 'ACCOUNT_SETUP_PHONE',
  ACCOUNT_SETUP_EMAIL: 'ACCOUNT_SETUP_EMAIL',
  ACCOUNT_SETUP_CODE: 'ACCOUNT_SETUP_CODE',
  ACCOUNT_SETUP_DOB: 'ACCOUNT_SETUP_DOB',
  ACCOUNT_SETUP_ADDRESS: 'ACCOUNT_SETUP_ADDRESS',
  ACCOUNT_SETUP_SSN: 'ACCOUNT_SETUP_SSN',
  ACCOUNT_SETUP_CONFIRM: 'ACCOUNT_SETUP_CONFIRM',

  DOC_UPLOAD: 'DOC_UPLOAD',
  ACCOUNT_SETUP_DENIED: 'ACCOUNT_SETUP_DENIED',

  INVESTMENT_AGREEMENT: 'INVESTMENT_AGREEMENT',
  GOAL_EDIT: 'GOAL_EDIT',
  GOAL_CUSTOMIZE: 'GOAL_CUSTOMIZE',
  RETIREMENT_CHANGE_PORTFOLIO: 'RETIREMENT_CHANGE_PORTFOLIO',

  HEALTH_RESUME: 'HEALTH_RESUME',
  EDE_INTRO: 'EDE_INTRO',
  EDE_COVERAGE_INFO: 'EDE_COVERAGE_INFO',
  EDE_PRIVACY: 'EDE_PRIVACY',
  EDE_HOUSEHOLD: 'EDE_HOUSEHOLD',
  EDE_SCREENING: 'EDE_SCREENING',
  EDE_PRO_ASSISTANCE: 'EDE_PRO_ASSISTANCE',
  EDE_ID_INFO: 'EDE_ID_INFO',
  EDE_IDENTITY: 'EDE_IDENTITY',
  EDE_APPLICATION_IMPORT: 'EDE_APPLICATION_IMPORT',
  EDE_CONFIRM_APPLICANTS: 'EDE_CONFIRM_APPLICANTS',
  EDE_WINDOW_SHOPPING: 'EDE_WINDOW_SHOPPING',
  EDE_MEMBER_INFO: 'EDE_MEMBER_INFO',
  EDE_MEMBER_QUESTIONS: 'EDE_MEMBER_QUESTIONS',
  EDE_IMMIGRATION_DETAILS: 'EDE_IMMIGRATION_DETAILS',
  EDE_MEDICAID_DENIAL: 'EDE_MEDICAID_DENIAL',
  EDE_MEMBER_INCOME_LIST: 'EDE_MEMBER_INCOME_LIST',
  EDE_MEMBER_DEDUCTIONS_LIST: 'EDE_MEMBER_DEDUCTIONS_LIST',
  EDE_MEMBER_INCOME_DETAILS: 'EDE_MEMBER_INCOME_DETAILS',
  EDE_MEMBER_INCOME_CONFIRM: 'EDE_MEMBER_INCOME_CONFIRM',
  EDE_MEMBER_INCOME_DISCREPANCY: 'EDE_MEMBER_INCOME_DISCREPANCY',
  EDE_SEP: 'EDE_SEP',
  EDE_OUTSIDE_HOUSEHOLD: 'EDE_OUTSIDE_HOUSEHOLD',
  EDE_DEPENDENTS_CURRENT_COVERAGE: 'EDE_DEPENDENTS_CURRENT_COVERAGE',
  EDE_MEMBER_MEDICAL_BILLS: 'EDE_MEMBER_MEDICAL_BILLS',
  EDE_MEMBER_WORK_HOURS: 'EDE_MEMBER_WORK_HOURS',
  EDE_OTHER_COVERAGE: 'EDE_OTHER_COVERAGE',
  EDE_REVIEW: 'EDE_REVIEW',
  EDE_AGREEMENTS: 'EDE_AGREEMENTS',
  EDE_RESULTS: 'EDE_RESULTS',
  EDE_DMI: 'EDE_DMI',
  EDE_SPLIT_ELIGIBILITY: 'EDE_SPLIT_ELIGIBILITY',
  EDE_PLANS: 'EDE_PLANS',
  EDE_APTC_AGREEMENT: 'EDE_APTC_AGREEMENT',
  EDE_ENROLL: 'EDE_ENROLL',
  EDE_SUCCESS: 'EDE_SUCCESS',
  EDE_SEP_OUT_OF_DATE: 'EDE_SEP_OUT_OF_DATE',
  EDE_HELP: 'EDE_HELP',

  CIC_MANAGE_COVERAGE_INTRO: 'CIC_MANAGE_COVERAGE_INTRO',
  CIC_CHANGE_SELECTION: 'CIC_CHANGE_SELECTION',
  CIC_BASICS: 'CIC_BASICS',
  CIC_ADDRESS_ENTRY: 'CIC_ADDRESS_ENTRY',
  CIC_NO_CHANGE: 'CIC_NO_CHANGE',
  CIC_STATE_HANDOFF: 'CIC_STATE_HANDOFF',
  CIC_STATE_PICKUP: 'CIC_STATE_PICKUP',
  CIC_CANCEL_POLICY: 'CIC_CANCEL_POLICY',
  CIC_GOV_HANDOFF: 'CIC_GOV_HANDOFF',
  CIC_GOV_PICKUP: 'CIC_GOV_PICKUP',
  CIC_APP_REVIEW_INTRO: 'CIC_APP_REVIEW_INTRO',
  CIC_MEMBER_MARRIAGE: 'CIC_MEMBER_MARRIAGE',
  CIC_MEMBER_CUT_INTRO: 'CIC_MEMBER_CUT_INTRO',
  CIC_MOVE_DATE_ENTRY: 'CIC_MOVE_DATE_ENTRY',
  CIC_MEMBER_DIVORCE: 'CIC_MEMBER_DIVORCE',
  CIC_MEMBER_DEATH: 'CIC_MEMBER_DEATH',
  CIC_MEMBER_CUSTODY: 'CIC_MEMBER_CUSTODY',
  CIC_MEMBER_JOB_COVERAGE: 'CIC_MEMBER_JOB_COVERAGE',
  CIC_MEMBER_STATUS_CHANGE: 'CIC_MEMBER_STATUS_CHANGE',
  CIC_REMOVING_MEMBERS: 'CIC_REMOVING_MEMBERS',
  CIC_POLICY_INVALID: 'CIC_POLICY_INVALID',
  CIC_NEW_PLANS_AVAILABLE: 'CIC_NEW_PLANS_AVAILABLE',
  CIC_NEW_PLAN_REQUIRED: 'CIC_NEW_PLAN_REQUIRED',
  CIC_PRELIM_ELIG: 'CIC_PRELIM_ELIG',
  CIC_MEMBER_CUT: 'CIC_MEMBER_CUT',
  CIC_SEP_MIXED: 'CIC_SEP_MIXED',
  CIC_SSN_ENTRY: 'CIC_SSN_ENTRY',
  CIC_INCOME_ENTRY: 'CIC_INCOME_ENTRY',
  HEALTH_LINK_INTRO: 'HEALTH_LINK_INTRO',
  HEALTH_LINK_AGREEMENTS: 'HEALTH_LINK_AGREEMENTS',
  HEALTH_LINK_IDENTITY: 'HEALTH_LINK_IDENTITY',
  HEALTH_LINK_IMPORT: 'HEALTH_LINK_IMPORT',
  EXPLORER_INTRO: 'EXPLORER_INTRO',
  EXPLORER_ZIPCODE: 'EXPLORER_ZIPCODE',
  EXPLORER_STATE_HANDOFF: 'EXPLORER_STATE_HANDOFF',
  EXPLORER_APPLICANTS: 'EXPLORER_APPLICANTS',
  EXPLORER_HOUSEHOLD: 'EXPLORER_HOUSEHOLD',
  EXPLORER_SAVINGS: 'EXPLORER_SAVINGS',
  EXPLORER_RISK_TOLERANCE: 'EXPLORER_RISK_TOLERANCE',
  EXPLORER_USAGE: 'EXPLORER_USAGE',
  EXPLORER_PRESCRIPTIONS: 'EXPLORER_PRESCRIPTIONS',
  EXPLORER_DOCTORS: 'EXPLORER_DOCTORS',
  EXPLORER_CHOOSE_PLAN: 'EXPLORER_CHOOSE_PLAN',
  EXPLORER_SEP: 'EXPLORER_SEP',
  EXPLORER_SEP_INELIGIBLE: 'EXPLORER_SEP_INELIGIBLE',
  EXPLORER_QLE: 'EXPLORER_QLE',
  STATE_RETURN: 'STATE_RETURN',

  //eftps auth
  EFTPS_AUTH: 'EFTPS_AUTH',

  // premium slasher
  HEALTH_SLASHER_INTRO: 'HEALTH_SLASHER_INTRO',
  HEALTH_SLASHER_TRIAGE: 'HEALTH_SLASHER_TRIAGE',
  HEALTH_SLASHER_INCOME_ENTRY: 'HEALTH_SLASHER_INCOME_ENTRY',
  HEALTH_SLASHER_INCOME_LIST: 'HEALTH_SLASHER_INCOME_LIST',
  HEALTH_SLASHER_INCOME_DETAILS: 'HEALTH_SLASHER_INCOME_DETAILS',
  HEALTH_SLASHER_INCOME_CONFIRM: 'HEALTH_SLASHER_INCOME_CONFIRM',
  HEALTH_SLASHER_AGREEMENTS: 'HEALTH_SLASHER_AGREEMENTS',
  HEALTH_SLASHER_HICCUP: 'HEALTH_SLASHER_HICCUP',
  HEALTH_SLASHER_RESULTS: 'HEALTH_SLASHER_RESULTS',
  HEALTH_SLASHER_SUCCESS: 'HEALTH_SLASHER_SUCCESS',

  // quick renew
  HEALTH_RENEW_INTRO: 'HEALTH_RENEW_INTRO',
  HEALTH_RENEW_AGREEMENTS: 'HEALTH_RENEW_AGREEMENTS',
  HEALTH_RENEW_IDENTITY: 'HEALTH_RENEW_IDENTITY',
  HEALTH_RENEW_IMPORT: 'HEALTH_RENEW_IMPORT',
  HEALTH_RENEW_DETAILS: 'HEALTH_RENEW_DETAILS',
  HEALTH_RENEW_PLAN: 'HEALTH_RENEW_PLAN',
  HEALTH_RENEW_RESUBMIT: 'HEALTH_RENEW_RESUBMIT',
  HEALTH_RENEW_SUCCESS: 'HEALTH_RENEW_SUCCESS',
};

/**
 * Mapping for internal navigation
 * - Keys are internal paths like PLAN
 * - Values are url endpoints like /plan
 * - The endpoint is used for urls on web and for screen IDs with RNN
 */
export const paths: Record<Route, string> = {
  //login
  LOBBY: '/auth',
  LOGIN: '/auth/sign-in',
  REGISTER: '/auth/sign-up',
  CONFIRM_CODE: '/auth/confirm',
  FORGOT_PASSWORD: '/auth/forgot-password',
  CONFIRM_PASSWORD: '/auth/forgot-password/confirm',

  ONBOARDING_BIOMETRICS: '/onboarding/biometrics',
  SET_NOTIFICATIONS: '/onboarding/set-notifications',

  /**@todo rename new onboarding routes*/
  //onboarding - initial
  WELCOME: '/onboarding/welcome',

  // personal payroll
  SETUP_PAYROLL: '/personal-payroll/setup-payroll',
  EMPLOYMENT_TYPE: '/personal-payroll/employment-type',
  ESTIMATED_INCOME: '/personal-payroll/estimated-income',
  // SETUP_INCOME_SOURCES: '/personal-payroll/income',
  WORK_STATE: '/personal-payroll/work-state',
  TAX_FILING_STATUS: '/personal-payroll/filing-status',
  SPOUSE_INCOME: '/personal-payroll/spouse-income',
  DEPENDENTS: '/personal-payroll/dependents',
  OTHER_WITHHOLDING: '/personal-payroll/other-withholding',
  SETUP_ESTIMATOR: '/personal-payroll/estimator',
  PAYROLL_SUMMARY: '/personal-payroll/summary',
  ADD_BANK_LINK: '/personal-payroll/bank-link',

  // home
  HOME: '/',
  DEV: '/dev',

  ACTIVITY: '/activity',
  EVENT_RECEIPT: '/activity/receipt',
  ANNUAL_REVIEW: '/review',

  // income
  INCOME: '/income',
  INCOME_STATS: '/income/stats',
  INCOME_SKIPPED: '/income/skipped',

  // tax
  TAXES: '/taxes',
  TAX_PAYMENT: '/tax-history/payments/:paymentId',
  TAX_PAYMENT_HISTORY: '/tax-history/history',

  // plan
  PLAN: '/plan',
  PLAN_DETAIL: '/plan/:slug/overview',
  PLAN_EDIT: '/plan/edit',

  // coverage
  COVERAGE: '/coverage',
  COVERAGE_DETAIL: '/coverage/:type/overview',
  WALLET_ITEM: '/wallet',

  // profile
  ACCOUNT: '/me',
  ACCOUNT_INFO: '/me/info',
  ACCOUNT_SETTINGS: '/me/settings',
  LINKED_BANKS: '/me/accounts',
  STATEMENTS: '/me/statements',
  REFERRAL: '/me/referral',

  CHANGE_ALIAS: '/update-email',
  CHANGE_PASSWORD: '/update-password',
  EDIT_USER_DETAILS: '/edit-details',
  UPSERT_BENEFICIARY: '/beneficiary',

  //transfer
  TRANSFER_AMOUNT: '/transfer/amount',
  TRANSFER_CONFIRM: '/transfer/confirm',

  // paycheck approval
  PAYCHECK_SKIPPED: '/paycheck/skipped',
  PAYCHECK_INCOME: '/paycheck/income',
  PAYCHECK_BREAKDOWN: '/paycheck/breakdown',

  //events

  // autopilot
  MANAGE_INCOME_SOURCES: '/income-sources/rules',
  // ADD_INCOME_SOURCE: '/income-sources/create',
  INCOME_SOURCE_DETAIL: '/income-sources/:sourceID/details',

  // tax payment
  TAX_PAYMENT_INTRO: '/tax-payment/intro',
  TAX_PAYMENT_AMOUNT: '/tax-payment/amount',
  TAX_PAYMENT_CONFIRM: '/tax-payment/confirm',
  EFTPS_AUTH: '/tax-payment/eftps',
  AUTO_TAX_PAYMENT_INTRO: '/tax-payment/auto/intro',

  // goal setup
  GOAL_INTRO: '/setup/:slug/intro',
  GOAL_EMPLOYMENT_TYPE: '/setup/:slug/employment-type',
  GOAL_ESTIMATED_INCOME: '/setup/:slug/estimated-income',
  GOAL_WORK_STATE: '/setup/:slug/work-state',
  GOAL_TAX_FILING_STATUS: '/setup/:slug/filing-status',
  GOAL_SPOUSE_INCOME: '/setup/:slug/spouse-income',
  GOAL_DEPENDENTS: '/setup/:slug/dependents',
  GOAL_ESTIMATOR: '/setup/:slug/estimator',
  GOAL_CONFIRM: '/setup/:slug/confirm',

  // misc
  GOAL_EDIT: '/setup/:slug/edit',
  GOAL_CUSTOMIZE: '/setup/:slug/customize',
  RETIREMENT_CHANGE_PORTFOLIO: '/setup/:slug/change-portfolio',

  // account setup
  ACCOUNT_SETUP_INTRO: '/account-setup/intro',
  ACCOUNT_SETUP_NAME: '/account-setup/name',
  ACCOUNT_SETUP_PHONE: '/account-setup/phone',
  ACCOUNT_SETUP_EMAIL: '/account-setup/email',
  ACCOUNT_SETUP_CODE: '/account-setup/code',
  ACCOUNT_SETUP_DOB: '/account-setup/dob',
  ACCOUNT_SETUP_ADDRESS: '/account-setup/address',
  ACCOUNT_SETUP_SSN: '/account-setup/ssn',
  ACCOUNT_SETUP_CONFIRM: '/account-setup/agreement',
  ACCOUNT_SETUP_DENIED: '/account-setup/access-denied',

  // retirement
  RETIREMENT_CURRENT_SAVINGS: '/retirement-setup/current-savings',
  RETIREMENT_RISK_LEVEL: '/retirement-setup/risk-level',
  RETIREMENT_RISK_COMFORT: '/retirement-setup/risk-comfort',
  RETIREMENT_PORTFOLIO_SELECTION: '/retirement-setup/portfolio',
  RETIREMENT_PORTFOLIO_TYPE: '/retirement-setup/portfolio/selection',
  RETIREMENT_ACCOUNT_SELECTION: '/retirement-setup/account',
  RETIREMENT_ACCOUNT_TYPE: '/retirement-setup/account/selection',
  FINANCIAL_SCREENING: '/retirement-setup/regulatory',
  FINANCIAL_SCREENING_DENIED: '/retirement-setup/ineligible',
  INVESTMENT_AGREEMENT: '/retirement-setup/investment-agreement', // could make retirement specific? or just guard the page itself?

  // doc uploads
  DOC_UPLOAD: '/upload-doc',

  // standalone health pages
  HEALTH_RESUME: '/health/resume',

  // health application flow
  EDE_INTRO: '/health/application/intro',
  EDE_COVERAGE_INFO: '/health/application/coverage-info',
  EDE_PRIVACY: '/health/application/privacy',
  EDE_HOUSEHOLD: '/health/application/household',
  EDE_SCREENING: '/health/application/screening',
  EDE_PRO_ASSISTANCE: '/health/application/pro-assistance',
  EDE_ID_INFO: '/health/application/id-info',
  EDE_IDENTITY: '/health/application/identity',
  EDE_APPLICATION_IMPORT: '/health/application/application-import',
  EDE_CONFIRM_APPLICANTS: '/health/application/confirm-applicants',
  EDE_WINDOW_SHOPPING: '/health/application/window-shopping',
  EDE_MEMBER_INFO: '/health/application/member-info',
  EDE_MEMBER_QUESTIONS: '/health/application/member-questions',
  EDE_IMMIGRATION_DETAILS: '/health/application/immigration-details',
  EDE_MEDICAID_DENIAL: '/health/application/medicaid-denial',
  EDE_MEMBER_INCOME_LIST: '/health/application/member-income-list',
  EDE_MEMBER_DEDUCTIONS_LIST: '/health/application/member-deductions-list',
  EDE_MEMBER_INCOME_DETAILS: '/health/application/member-income-details',
  EDE_MEMBER_INCOME_CONFIRM: '/health/application/member-income-confirm',
  EDE_MEMBER_INCOME_DISCREPANCY: '/health/application/member-income-discrepancy',
  EDE_SEP: '/health/application/sep',
  EDE_OUTSIDE_HOUSEHOLD: '/health/application/outside-household',
  EDE_DEPENDENTS_CURRENT_COVERAGE: '/health/application/dependents-current-coverage',
  EDE_MEMBER_MEDICAL_BILLS: '/health/application/member-medical-bills',
  EDE_MEMBER_WORK_HOURS: '/health/application/member-work-hours',
  EDE_OTHER_COVERAGE: '/health/application/other-coverage',
  EDE_REVIEW: '/health/application/review',
  EDE_AGREEMENTS: '/health/application/agreements',
  EDE_RESULTS: '/health/application/results',
  EDE_DMI: '/health/application/dmi',
  EDE_SPLIT_ELIGIBILITY: '/health/application/split-eligibility',
  EDE_PLANS: '/health/application/plans',
  EDE_APTC_AGREEMENT: '/health/application/aptc-agreement',
  EDE_ENROLL: '/health/application/enroll',
  EDE_SUCCESS: '/health/application/success',
  EDE_SEP_OUT_OF_DATE: '/health/application/sep-out-of-date',
  EDE_HELP: '/health/application/contact-support',

  // CiC: Change in Circumstances
  CIC_MANAGE_COVERAGE_INTRO: '/coverage-change/:type/cic-manage-coverage',
  CIC_CHANGE_SELECTION: '/coverage-change/:type/cic-change-selection',
  CIC_BASICS: '/coverage-change/:type/cic-basics',
  CIC_ADDRESS_ENTRY: '/coverage-change/:type/cic-address-entry',
  CIC_NO_CHANGE: '/coverage-change/:type/cic-no-change',
  CIC_STATE_HANDOFF: '/coverage-change/:type/cic-state-handoff',
  CIC_STATE_PICKUP: '/coverage-change/:type/cic-state-pickup',
  CIC_CANCEL_POLICY: '/coverage-change/:type/cic-cancel-policy',
  CIC_GOV_HANDOFF: '/coverage-change/:type/cic-gov-handoff',
  CIC_GOV_PICKUP: '/coverage-change/:type/cic-gov-pickup',
  CIC_APP_REVIEW_INTRO: '/coverage-change/:type/cic-app-review-intro',
  CIC_MEMBER_MARRIAGE: '/coverage-change/:type/cic-member-marriage',
  CIC_MEMBER_CUT_INTRO: '/coverage-change/:type/cic-member-cut-intro',
  CIC_MOVE_DATE_ENTRY: '/coverage-change/:type/cic-move-date-entry',
  CIC_MEMBER_DIVORCE: '/coverage-change/:type/cic-member-divorce',
  CIC_MEMBER_DEATH: '/coverage-change/:type/cic-member-death',
  CIC_MEMBER_CUSTODY: '/coverage-change/:type/cic-member-custody',
  CIC_MEMBER_JOB_COVERAGE: '/coverage-change/:type/cic-member-job-coverage',
  CIC_MEMBER_STATUS_CHANGE: '/coverage-change/:type/cic-member-status-change',
  CIC_REMOVING_MEMBERS: '/coverage-change/:type/cic-removing-members',
  CIC_POLICY_INVALID: '/coverage-change/:type/cic-policy-invalid',
  CIC_NEW_PLANS_AVAILABLE: '/coverage-change/:type/cic-new-plans-available',
  CIC_NEW_PLAN_REQUIRED: '/coverage-change/:type/cic-new-plan-required',
  CIC_PRELIM_ELIG: '/coverage-change/:type/cic-prelim-eligible',
  CIC_MEMBER_CUT: '/coverage-change/:type/cic-member-cut',
  CIC_SEP_MIXED: '/coverage-change/:type/cic-sep-mixed',
  CIC_SSN_ENTRY: '/coverage-change/:type/cic-ssn-entry',
  CIC_INCOME_ENTRY: '/coverage-change/:type/cic-income-entry',

  // health link
  HEALTH_LINK_INTRO: '/health/link/intro',
  HEALTH_LINK_AGREEMENTS: '/health/link/agreements',
  HEALTH_LINK_IDENTITY: '/health/link/identity',
  HEALTH_LINK_IMPORT: '/health/link/import',

  // premium slasher
  HEALTH_SLASHER_INTRO: '/health/slash/intro',
  HEALTH_SLASHER_TRIAGE: '/health/slash/triage',
  HEALTH_SLASHER_INCOME_ENTRY: '/health/slash/income-entry',
  HEALTH_SLASHER_INCOME_LIST: '/health/slash/income-list',
  HEALTH_SLASHER_INCOME_DETAILS: '/health/slash/income-details',
  HEALTH_SLASHER_INCOME_CONFIRM: '/health/slash/income-confirm',
  HEALTH_SLASHER_AGREEMENTS: '/health/slash/agreements',
  HEALTH_SLASHER_HICCUP: '/health/slash/hiccup',
  HEALTH_SLASHER_RESULTS: '/health/slash/results',
  HEALTH_SLASHER_SUCCESS: '/health/slash/success',

  // explorer
  EXPLORER_INTRO: '/health/explorer/intro',
  EXPLORER_ZIPCODE: '/health/explorer/zip',
  EXPLORER_APPLICANTS: '/health/explorer/applicants',
  EXPLORER_STATE_HANDOFF: '/health/explorer/state',
  EXPLORER_HOUSEHOLD: '/health/explorer/household',
  EXPLORER_SAVINGS: '/health/explorer/savings',
  EXPLORER_RISK_TOLERANCE: '/health/explorer/risk',
  EXPLORER_USAGE: '/health/explorer/usage',
  EXPLORER_PRESCRIPTIONS: '/health/explorer/prescriptions',
  EXPLORER_DOCTORS: '/health/explorer/doctors',
  EXPLORER_CHOOSE_PLAN: '/health/explorer/plan',
  EXPLORER_SEP: '/health/explorer/sep',
  EXPLORER_SEP_INELIGIBLE: '/health/explorer/sep-ineligible',
  EXPLORER_QLE: '/health/explorer/qle',
  STATE_RETURN: '/health/explorer/state-return',

  // quick renew
  HEALTH_RENEW_INTRO: '/health/renew/intro',
  HEALTH_RENEW_AGREEMENTS: '/health/renew/agreements',
  HEALTH_RENEW_IDENTITY: '/health/renew/identity',
  HEALTH_RENEW_IMPORT: '/health/renew/import',
  HEALTH_RENEW_DETAILS: '/health/renew/details',
  HEALTH_RENEW_PLAN: '/health/renew/plan',
  HEALTH_RENEW_RESUBMIT: '/health/renew/resubmit',
  HEALTH_RENEW_SUCCESS: '/health/renew/success',
};
