import React from 'react';

import { Copy, RenderedIcon } from '@types';
import { Layout, Narrow, Page, Toolbar, HeaderGradient } from '@layouts';
import { Button, Loading, Explainer, ExplainerBullet, Render } from '@uikit';
import { useCopy } from '@app/utils';
import { pop } from '@navigate';

interface IntroBlueprintProps {
  title: Copy;
  titleSize?: string;
  subtitles?: Array<Copy>;
  align?: 'left' | 'center';
  loading: boolean;
  disabled: boolean;
  toolbar?: React.Component; //custom toolbar
  type?: RenderedIcon; //custom asset above header
  bullets?: Array<ExplainerBullet>;
  onNext?: () => void;
}

//used for onboarding screens without forms
const IntroBlueprint = ({
  title,
  titleSize = 'form',
  subtitles,
  align = 'left',
  loading,
  disabled,
  toolbar,
  type,
  bullets,
  onNext,
}: IntroBlueprintProps) => {
  const { c } = useCopy('catch.basics');

  return (
    <Page color="page">
      <Layout.Scroll fullWidth bottomSpace topSpace>
        {!!type ? (
          <HeaderGradient
            inherit
            autoHeight
            centered
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Layout center bottomSpace>
              <Render name={type} size="humongous" />
            </Layout>
          </HeaderGradient>
        ) : (
          <></>
        )}
        <Layout topSpace>
          <Narrow>
            <Layout.Header
              title={title}
              subtitles={subtitles}
              rightSpace={false}
              titleSize={titleSize}
              bottomSpace
              align={align}
            />
            {loading ? <Loading /> : <Explainer bullets={bullets} />}
          </Narrow>
        </Layout>
      </Layout.Scroll>
      {!!toolbar ? (
        toolbar
      ) : (
        <Toolbar narrow onBack={pop}>
          <Button testID="next-btn" onPress={onNext} accentColor="brand" disabled={disabled}>
            {c('next')}
          </Button>
        </Toolbar>
      )}
    </Page>
  );
};

IntroBlueprint.options = {};

export default IntroBlueprint;
