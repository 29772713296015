import React from 'react';
import shallow from 'zustand/shallow';

import { useAuth, useAuthEvents } from '@client/Auth';
import { routes, start } from '@navigate';
import { mutations, useDeprecatedMutation, useMutation } from '@data';
import { createLogger, Segment, useCopy } from '@app/utils';
import { HealthPathway, OnboardingBenefit } from '@app/types';
import { getExitOptions } from './util/context';
import { getSignupContextOptions } from '@app/pages/auth/util/context';
import { AuthBlueprint } from '@app/blueprints';
import { Platform } from '@app/layouts';
import { Button } from '@uikit';

const Log = createLogger('signup:view');

// needs to be shared with signin
interface RegisterComponentParams {
  initialVals?: any;
  benefit?: OnboardingBenefit;
  vertical?: OnboardingBenefit;
  r?: string;
  hr?: boolean;
  hid?: string;
  pathway?: HealthPathway;
  phone?: string;
  email?: string;
  zip?: string;
  partner?: string;
  next?: string;
  income?: string;
}

const ConfirmCode = ({
  initialVals,
  r: signupCode,
  hr: hideSignupCode,
  hid: healthID, // the PX session
  pathway,
  phone: initialPhone,
  email: initialEmail,
  zip,
  vertical,
  benefit,
  partner,
  income,
  next, // otherwise, this navigation prop gets sent on signup params and fails
  ...additionalSignupParams
}: RegisterComponentParams) => {
  // const { c } = useCopy('catch.benefits');
  const { c } = useCopy('catch');

  const {
    confirmedExpressBenefit,
    usedPublicExplorer,
    requestedBenefit,
    signupContext,
    onboardingType,
  } = getSignupContextOptions({ benefit, healthID, pathway });

  /** mutations */
  const [updateUser] = useMutation(mutations.UPDATE_USER);
  // const [_upsertExplorer, { loading: upserting }] = useMutation(mutations.UPSERT_EXPLORER);
  const [_importHealth] = useDeprecatedMutation('importPublicExplorer', {});

  // needs to be shared with signin
  const importHealth = async ({ onCompleted }) => {
    if (!!usedPublicExplorer) {
      await _importHealth({
        variables: { id: healthID },
        onCompleted,
      });
    } else onCompleted();
  };

  /**
   * continue onboarding/express path
   */
  const handleExit = async () => {
    Log.debug('Choosing onboard path...');

    updateUser({
      variables: {
        user: {
          signupContext,
        },
      },
    });

    const exitOptions = getExitOptions({
      confirmedExpressBenefit,
      onboardingType,
    });

    Log.debug(`Starting onboarding: ${onboardingType}`);

    let onCompleted = () => {};

    if (exitOptions) {
      onCompleted = () => start('MAIN_TABS', exitOptions);
    } else {
      onCompleted = () => start('ONBOARDING_STACK', {});
    }

    if (
      onboardingType === 'HealthApplication' ||
      onboardingType === 'PremiumSlasher' ||
      onboardingType === 'HealthExplorer'
    ) {
      await importHealth({ onCompleted });
    } else {
      onCompleted();
    }
  };

  const { confirmCode, temporaryAlias, error, loading } = useAuth(
    (state) => ({
      temporaryAlias: state.temporaryCredentials.alias,
      loading: state.loading,
      step: state.authState,
      steps: state.states,
      signUp: state.signUp,
      confirmCode: state.confirmCode,
      error: state.error,
    }),
    shallow,
  );

  useAuthEvents({
    onPendingConfirmation: ({ temporaryAlias }) => {
      Segment.identifyUser(null, {
        alias: temporaryAlias,
        referral_link: Platform.OS === 'web' ? document.referrer : null,
        initial_user: true,
      });
      Segment.pageView();
      Segment.submitRegistration({ alias: temporaryAlias });
    },
    onSignedIn: ({ user }) => {
      if (!!user) {
        Segment.identifyUser(user.id, {
          email: user.email,
          phoneNumber: user.phoneNumber,
          firstname: user.givenName,
          coupon_code: user.referralCode || signupCode,
          partner,
        });
        Segment.userCreated(user.email || user.phoneNumber, signupContext);
      }
      handleExit();
    },
  });

  Log.debug({
    signupContext: {
      signupCode,
      hideSignupCode,
      healthID,
      pathway,
      initialPhone,
      initialEmail,
      zip,
      vertical,
      benefit,
      requestedBenefit,
      confirmedExpressBenefit,
      onboardingType,
      partner,
      ...additionalSignupParams,
    },
  });

  return (
    <AuthBlueprint<{}>
      loading={false}
      submitting={loading}
      title={`Enter the code we sent to ${temporaryAlias}`}
      error={error}
      formConfig={{
        initialValues: {},
        fields: [{ type: 'code', name: 'code', alias: temporaryAlias, required: true }],
        onSubmit: confirmCode,
      }}
      actions={({ handleSubmit, disabled }) => (
        <Button testID="confirm-code" onPress={handleSubmit} disabled={disabled}>
          Next
        </Button>
      )}
    />
  );
};

export const ConfirmCodeView = {
  name: routes.CONFIRM_CODE,
  component: ConfirmCode,
  guest: true,
  options: {
    title: 'Enter the code',
    largeTitle: true,
    drawBehindNav: true,
  },
};
