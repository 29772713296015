import React from 'react';
import { Link } from '@uikit';
import { useCopy, openIntercom } from '@app/utils';
import { useQuery, queries, UserDetailsQueryData } from '@data';
import { pop, exit, routes } from '@navigate';
import { HiccupBlueprint } from '@blueprints';

/**
 * This view is concerned with handling cases where a user sets
 * isControlPerson, isFirmAffiliated, or isPoliticallyExposed to true
 * the route is /plan/{module}/ineligible
 */
const FinancialScreeningDenied = () => {
  const { c } = useCopy('catch.plans.PlanIneligibleUserView');
  const { c: basics } = useCopy('catch.basics');

  const { loading, data } = useQuery<UserDetailsQueryData>(queries.USER_DETAILS, {
    fetchPolicy: 'cache-first',
  });

  return (
    //   @ts-ignore
    <HiccupBlueprint
      loading={loading}
      title={c('title', { name: data?.viewer?.user?.nickname || data?.viewer?.user?.givenName })}
      subtitles={[
        c('p1'),
        c('p2', {
          contact: (
            // @ts-ignore
            <Link key="contact-support" onPress={openIntercom} inline>
              {basics('contactCatchSupport')}
            </Link>
          ),
        }),
      ]}
      buttonText={basics('exit')}
      onPress={() => exit()}
      cancelButtonText={basics('review')}
      cancelButtonOnPress={pop}
    />
  );
};

export const FinancialScreeningDeniedView = {
  name: routes.FINANCIAL_SCREENING_DENIED,
  component: FinancialScreeningDenied,
  options: {},
};
