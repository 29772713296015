import React, { useEffect } from 'react';

import { useQuery, PlanDetailQueryData, queries, useDeprecatedQuery } from '@data';
import { PlanDetailLayout, PromptCard } from '@common';
import { open, routes, useSheet, sheets, ScreenDefinition, useNavigation } from '@navigate';
import { useSnooze, useGoal, useTier } from '@hooks';
import { ButtonGroup, Layout } from '@app/layouts';
import { Banner, Button, GoalMeter } from '@uikit';
import NotFoundView from '@app/pages/misc/NotFoundView';
import DetailsTab from '../shared/DetailsTab';
import RetirementLineChart from '@app/charts/RetirementLineChart';
import { benefitColorLookup } from '@app/utils/color';
import {
  BENEFITS,
  formatBenefit,
  useCopy,
  deslug,
  calculateRetirementIncome,
  Env,
} from '@app/utils';
import { Prompts } from '@app/config';

const GoalDetail = ({ slug }) => {
  useQuery<PlanDetailQueryData>(queries.PLAN_DETAIL); // @todo use this instead

  const { c: basics, p, $ } = useCopy('catch.basics');
  const { c: c2 } = useCopy('catch.PlanSummary');
  const { c: c3 } = useCopy('catch.module.retirement.ProjectedValueCard');

  const { goal, loading, waiting: waitingGoal } = useGoal({ slug });
  const { setOptions } = useNavigation();
  const { open: openSheet } = useSheet();

  const { resume } = useSnooze(goal);

  const { age, estimatedIncome } = useDeprecatedQuery('user');
  const { locked } = useTier();

  const {
    status,
    icon,
    name,
    goalType,
    paycheckPercentage,
    totalBalance,
    pendingBalance,
    pausedEndDate,
    type,
    wealthAccountID,
    externalSavings = 0,
  } = { ...goal };

  const calcArgs = {
    currentAge: age,
    estimatedIncome,
    retirementAge: 65,
    initialAmount: externalSavings + totalBalance,
    paycheckPercentage,
  };

  const {
    monthlyIncome,
    totalSaved,
    inputs: { retirementAge },
  } = calculateRetirementIncome(calcArgs);

  const { isAccountReady, waiting: waitingStatus } = useDeprecatedQuery('status');
  const isPaused = status && /PAUSED|GOAL_MET/.test(goal.status);
  const isWaiting = waitingGoal || waitingStatus;

  const editPercentage = () => open(routes.GOAL_EDIT, { slug });

  const color = benefitColorLookup({ slug });
  const title = formatBenefit.name({ type, name, slug });
  const isUndefined = !loading && !goal?.id;

  useEffect(() => {
    if (!isUndefined) setOptions({ title });
  }, [title, isUndefined]);

  if (isUndefined) return <NotFoundView />;

  return (
    <PlanDetailLayout
      color={color + 'Light'}
      loading={isWaiting}
      type={BENEFITS[goalType]}
      title={title}
      balance={totalBalance}
      paycheckPercentage={paycheckPercentage}
      sheetConfig={{ name: sheets.PLAN_DETAIL_MENU, data: { slug } }}
      pendingBalance={pendingBalance}
      pausedEndDate={pausedEndDate}
      goalStatus={status}
      isAccountReady={isAccountReady}
      customIcon={icon}
      goal={goal}
      details={<DetailsTab goal={goal} />}
      onEditPercentage={editPercentage}
      content={wealthAccountID && <RetirementLineChart wealthAccountID={wealthAccountID} />}
      topRightComponent={
        false && goal?.goalType === 'RETIREMENT' ? (
          <Banner
            asset={{ containerSize: 'xl', size: 'lg', render: 'retirement' }}
            onPress={() => openSheet('RETIREMENT_PROJECTION_GUIDE', calcArgs)}
            title={`${c3('label')}: ${$(totalSaved, { whole: true })}`}
            subtitle={c3('description', {
              retirementAge,
              monthlyIncome: $(monthlyIncome || 0, { whole: true }),
            })}
          />
        ) : goal?.slug === 'hsa' ? (
          <Banner
            asset={{ containerSize: 'xl', size: 'lg', render: 'coverage' }}
            onPress={() => alert('Nice try!')}
            title="Nice find!"
            subtitle="We'll have more to share soon. You'll be among the first to get access."
          />
        ) : undefined
      }
      planSummary={
        !!goal?.target
          ? {
              banners: [
                <GoalMeter key="goal-meter" balance={totalBalance} target={goal?.target} />,
              ],
            }
          : null
      }
      controls={
        <ButtonGroup
          loading={isWaiting}
          textRight={$(totalBalance)}
          textLeft={
            c2('goalStatusLabel', {
              status: goal?.status,
              rate: p(paycheckPercentage || 0),
            }) || '0%'
          }
        >
          <Button
            disabled={true || locked}
            inherit
            testID="plan-summary-flag-button"
            full
            onPress={isPaused ? resume : editPercentage}
            alt
          >
            {basics('edit')}
          </Button>
          <Button
            disabled={true || locked || goal?.goalType === 'RETIREMENT'}
            inherit
            testID="plan-summary-transfer-button"
            full
            haptics="LIGHT"
            onPress={() =>
              openSheet(sheets.TRANSFER_OPTIONS, {
                goal,
                goalType,
              })
            }
          >
            Withdraw
          </Button>
        </ButtonGroup>
      }
    />
  );
};

/**
 * @todo E-3287
 */
export const GoalView: ScreenDefinition = {
  name: routes.PLAN_DETAIL,
  component: GoalDetail,
  options: {
    bg: ({ slug }) => benefitColorLookup({ slug }) + 'Light',
    title: ({ slug }) => deslug(slug),
    accentColor: ({ slug }) => benefitColorLookup({ slug }),
    drawBehindNav: true,
    buttons: {
      sheetMenu: sheets.PLAN_DETAIL_MENU,
    },
  },
};
