import gql from 'graphql-tag';

export interface UpsertExplorerApplicantsVars {
  applicants: Array<{
    id: string;
    age: number;
    relation: string; // @todo;
    aptcEligible: boolean;
    hasMec: boolean;
    isApplying: boolean;
    isParent: boolean;
    isPregnant: boolean;
    isSmoker: boolean;
  }>;
}

export interface UpsertExplorerApplicantsResponse {
  upsertHealthExplorerDependents: Array<{
    id: string;
    age: number;
    relation: string; // @todo;
    aptcEligible: boolean;
    hasMec: boolean;
    isApplying: boolean;
    isParent: boolean;
    isPregnant: boolean;
    isSmoker: boolean;
  }>;
}

export const UpsertExplorerApplicants = gql`
  mutation UpsertExplorerApplicants($applicants: [HealthExplorerDependentInput!]!) {
    upsertHealthExplorerDependents(input: { healthExplorerDependentInputs: $applicants }) {
      id
      age
      relation
      aptcEligible
      hasMec
      isApplying
      isParent
      isPregnant
      isSmoker
    }
  }
`;
