import React from 'react';

import { OnboardingFormBlueprint } from '@blueprints';
import { useQuery, queries, RetirementQueryData } from '@data';
import { RiskLevel } from '@types';
import { FormConfig } from '@app/forms';
import { fields } from '@app/config';
import { useCopy } from '@app/utils';
import { routes } from '@navigate';

interface RetirementRiskValues {
  riskLevel: RiskLevel;
}

/**
 * screen series required for retirement compliance,
 * specifically determination of objectives and sophistication.
 * ask Samuel Thayer for more
 * also @see RetirementRiskComfortView
 */
const RetirementRiskLevel = ({ handleNext, updateRetirement }) => {
  const { c } = useCopy('catch.module.retirement.RiskLevelView');

  const { loading, data } = useQuery<RetirementQueryData>(queries.RETIREMENT, {
    fetchPolicy: 'cache-first',
  });

  const formConfig: FormConfig<RetirementRiskValues> = {
    initialValues: { riskLevel: data?.viewer?.goal?.riskLevel },
    fields: [fields.RISK_LEVEL],
    onSubmit: (values) => {
      updateRetirement({ riskLevel: values.riskLevel });
      handleNext();
    },
  };

  return (
    <OnboardingFormBlueprint
      loading={loading}
      disabled={false}
      title={c('title')}
      formConfig={formConfig}
    />
  );
};

export const RetirementRiskLevelView = {
  name: routes.RETIREMENT_RISK_LEVEL,
  component: RetirementRiskLevel,
  options: OnboardingFormBlueprint.options,
};
