import React from 'react';
import { Linking, StyleSheet } from 'react-native';

import { Text } from './Text';
import { useTheme } from '../hooks/useTheme';
import { colors } from '../theme/colors';
import { useLayoutContext } from '@layouts/LayoutContext';

export const Link = ({
  style,
  children,
  type,
  containerStyle,
  small,
  href,
  gutter,
  subtle,
  call,
  disabled,
  size,
  color,
  align,
  onPress,
  weight,
  ...other
}) => {
  const { theme } = useTheme();
  const context = useLayoutContext();

  /**
   * Handle link presses
   * - if onPress is defined, use that.
   * - if link is internal, use goTo
   * - if link is external, use linking when href/call is set on native
   *
   * there's no need to pass href props down to Pressable level
   * as it causes flashing/re-loading of the link, as goTo takes care of directing
   *
   * Preferably: do not use onPress if it's simply an internal or external url
   * path but simply pass down href
   */
  const handlePress = !!onPress
    ? onPress
    : href || call
    ? () => Linking.openURL(`${call ? 'tel:' : ''}${href}`).catch((e) => e)
    : other.onPress;

  const textStyles = [
    disabled && theme.disabled,
    subtle && theme.subtleText,
    weight && theme[`${weight}Text`],
    style,
  ];
  if (type === 'delete') {
    textStyles.push(styles.deleteLink);
  }

  return (
    // <Pressable
    //   handleOnPress={handlePress}
    //   disabled={disabled}
    //   accessibilityRole={'link'}
    //   target="_blank"
    // >
    //   {({ intrx }) => (
    <Text
      onPress={handlePress}
      color={color || context?.accentColor || 'link'}
      style={[textStyles]}
      align={align}
      size={size ? size : small ? 'fp' : false}
      weight="medium"
    >
      {children}
    </Text>
    //   )}
    // </Pressable>
  );
};

const styles = StyleSheet.create({
  deleteLink: {
    color: colors['coral-1'],
  },
});

export default Link;
