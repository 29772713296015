import { Route } from '../routes';

type Tab = 'HOME' | 'INCOME' | 'TAXES' | 'PLAN' | 'COVERAGE' | 'ACCOUNT';
type TabRoute = Tab & Route;

// Pages that are the native bottom tabs
export const TAB_NAMES: Array<TabRoute> = ['HOME', 'INCOME', 'TAXES', 'PLAN', 'COVERAGE'];

// Pages that are web tabs
export const WEB_TABS: Array<TabRoute> = ['HOME', 'INCOME', 'TAXES', 'PLAN', 'COVERAGE'];

export const NEW_TAB_NAMES = {
  HOME: 'Home',
  INCOME: 'Income',
  TAXES: 'Taxes',
  PLAN: 'Saved',
  COVERAGE: 'Coverage',
};
