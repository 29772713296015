import React from 'react';
import { ApplicantAgeWarning } from '@app/partial';
import { FieldAdditionID } from '@types';
import { Text, ComplexRow, Banner } from '@uikit';
import { useCopy } from '@app/utils';
import { Layout } from '@app/layouts';

const Warning: React.FC<{ copy: { id: string; data?: object; prefix?: string } }> = ({ copy }) => {
  const { c } = useCopy(copy.prefix || 'catch');

  return (
    <Text size="fp" color="credit" weight="bold" gutter="top">
      {c(copy.id, copy.data || {})}
    </Text>
  );
};

const BasicText: React.FC<{ copy: { id: string; data?: object; prefix?: string } }> = ({
  copy,
}) => {
  const { c } = useCopy(copy.prefix || 'catch');

  return (
    <Text size="fp" gutter="top">
      {c(copy.id, copy.data || {})}
    </Text>
  );
};

const BasicBanner = (props) => {
  return <Banner {...props} style={{ marginTop: 16 }} />;
};

const FinancialAssistanceWarning = () => {
  return (
    <Layout margins topSpace>
      <ComplexRow
        bg="coverageLight"
        label="Catch recommends applying for savings"
        sublabel="If you don’t, you may end up paying more for coverage. 9 in 10 qualify to save."
      />
    </Layout>
  );
};

const components: Record<FieldAdditionID, React.FC> = {
  APPLICANT_AGE_WARNING: ApplicantAgeWarning,
  FINANCIAL_ASSISTANCE_WARNING: FinancialAssistanceWarning,
  WARNING: Warning,
  TEXT: BasicText,
  BANNER: BasicBanner,
};

const FieldAddition = ({ component, props }) => {
  if (!component) return null;
  const Component = components[component];
  return <Component {...props} />;
};

export default FieldAddition;
