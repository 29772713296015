import React, { useMemo } from 'react';
import shallow from 'zustand/shallow';

import {
  Route,
  MenuButton,
  HelpButton,
  history,
  store,
  translateScreenOptions,
  WebNavigationConfig,
} from '@navigate';
import SheetNav from '@navigate/components/SheetNav';
import { useTheme } from '@uikit';
import { Stack } from '@layouts';
import PageNav from './PageNav';

interface Props {
  layout: 'sheet' | 'page';
  route: Route;
  data?: {
    canGoBack: boolean;
  };
}

/**
 * Magic nav is very magical
 * - It takes the current route and handles displaying data
 * - Can be used on full page or nav modals
 * - Is really awesome generally
 */
const MagicNav = ({ layout, route }: Props) => {
  const { themeColors } = useTheme();

  // joins together stack and screen options (with screen overwriting any stack defaults)
  const { getOptions, lastUpdated } = store(
    (state) => ({
      getOptions: state.getOptions,
      lastUpdated: state.lastUpdated,
    }),
    shallow,
  );

  const options = useMemo(() => {
    return getOptions(route);
  }, [lastUpdated, getOptions, route]);

  const data = {
    // @ts-ignore
    ...store.getState().matchParams,
    // @ts-ignore
    ...history.location.state,
  };

  // @ts-ignore
  const config: WebNavigationConfig = translateScreenOptions(options, themeColors, data, layout);
  const LayoutSpecificNav = layout === 'sheet' ? SheetNav : PageNav;

  return (
    <>
      {layout === 'page' && <meta name="theme-color" content={config.themeColor} />}

      <LayoutSpecificNav
        title={config.title}
        subtitle={config.subtitle}
        navHex={config.navHex}
        navMode={config.navMode}
        isTransparent={config.isTransparent}
        style={config.bgStyle}
        textColor="text" // @computed???
        right={
          <Stack horizontal>
            {options.buttons?.help && <HelpButton />}
            {options.buttons?.sheetMenu && (
              <MenuButton sheet={options.buttons?.sheetMenu} data={data} />
            )}
          </Stack>
        }
      />
    </>
  );
};

export default MagicNav;
