import { matchPath } from 'react-router-dom';
import { paths } from '../routes';

/**
 * Logic for checking whether tab is currently selected
 *
 * Note the special logic:
 * only / matches HOME, but plan/* matches PLAN
 */
export const checkActiveTab = (tab, pathname) => {
  if (!tab) return false;
  return !!matchPath(pathname, paths[tab]);
};
