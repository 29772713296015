import React, { useMemo } from 'react';
import { StyleSheet, View } from 'react-native';

import { useDebug } from '@app/hooks/useDebug';
import { useResponsive } from '@uikit/hooks/useResponsive';
import { Stack } from '@layouts';
import { useTheme, ContextMenu, Button, Text } from '@uikit';
import Layout from './Layout';
import { Render } from '@uikit/components/Asset';

import More from '@svg/dots-horizontal.svg';

/**
 * @todo full refactor probably
 */
const FlowHeader = ({
  title,
  titleSpan,
  subtitle,
  subtitles,
  pretitle,
  question,
  center,
  loading,
  style,
  modal,
  children,
  menuItems,
  onMore,
  root,
  narrow,
  render,
  rightSpace = true,
}) => {
  const { theme, themeColors } = useTheme();
  const { showLayout } = useDebug();
  const { isMobile, isWeb } = useResponsive();
  const showMore = useMemo(
    () => Boolean((menuItems || onMore) && isWeb),
    [isWeb, menuItems, onMore, isMobile],
  );

  return (
    (!!root && !isWeb) || (
      <Layout
        center
        style={[
          showLayout && theme._overlay,
          style,
          !!root ? theme.topSpace4 : theme.topSpace4,
          theme.bottomSpace4,
          theme.fadeIn,
        ]}
      >
        <Layout
          margins
          style={[narrow ? theme.miniPage : theme.page, showLayout && theme._overlay]}
        >
          <View align="center">
            <>
              {React.isValidElement(pretitle) ? (
                pretitle
              ) : (
                <Text size="fp" weight="medium">
                  {pretitle}
                </Text>
              )}
              {!!render && (
                <View style={styles.renderWrapper}>
                  <Render blend={true} size="humongous" name={render} />
                </View>
              )}
              <Layout.Header
                loading={loading}
                staticTitle
                titleSize={question || modal || !root ? 'form' : 'page'}
                title={titleSpan || title}
                subtitles={subtitles}
                subtitle={subtitle}
                align={center ? 'center' : 'left'}
                light={question}
                rightSpace={rightSpace}
              />
              {children}
            </>
            {showMore && (
              <View style={{ position: 'absolute', right: 0 }}>
                {!!menuItems ? (
                  <Stack horizontal spacing="0b" style={styles.flexEnd}>
                    <ContextMenu options={menuItems}>
                      {/* @ts-ignore */}
                      <Button small alt svg={More} title="More">
                        More
                      </Button>
                    </ContextMenu>
                  </Stack>
                ) : onMore ? (
                  <Stack horizontal spacing="0b" style={styles.flexEnd}>
                    <Button alt small svg={More} title="More" onPress={onMore}>
                      More
                    </Button>
                  </Stack>
                ) : null}
              </View>
            )}
          </View>
        </Layout>
      </Layout>
    )
  );
};

const styles = StyleSheet.create({
  renderWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    marginBottom: 40,
    flexDirection: 'row',
  },
  flexEnd: {
    justifyContent: 'flex-end',
  },
});

export default FlowHeader;
