import gql from 'graphql-tag';

export interface UpsertIncomePreferencesVars {
  isDelayAutoIncome: boolean;
}

export interface UpsertIncomePreferencesResponse {
  upsertIncomePreferences: {
    isDelayAutoIncome: boolean;
    __typename: 'IncomePreferences';
  };
}

export const UpsertIncomePreferences = gql`
  mutation UpsertIncomePreferences($isDelayAutoIncome: Boolean!) {
    upsertIncomePreferences(input: { isDelayAutoIncome: $isDelayAutoIncome }) {
      isDelayAutoIncome
    }
  }
`;
