import { date } from 'redux-form-validators';

import { COPY } from './copy';

const validation = date({
  format: 'mm/dd/yyyy',
  msg: COPY['date'],
});

export default validation;
