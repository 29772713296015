import create from 'zustand';
import { persist } from 'zustand/middleware';

interface EnrollIdState {
  enrollId: string;
  setId: (enrollId: string) => void;
  getId: () => string;
  reset: () => void;
}

export const useStore = create<EnrollIdState>(
  persist(
    (set, get) => ({
      enrollId: '',
      setId: (enrollId: string) => set({ enrollId }),
      getId: () => get().enrollId,
      reset: () => ({ enrollId: '' }),
    }),
    {
      name: 'enrollId',
    },
  ),
);

//simple hook to keep track of enrollId
export const useEnrollId = (initialId: string) => {
  const { enrollId: storedId, setId, getId } = useStore();
  if (initialId && initialId !== storedId) {
    setId(initialId);
  }

  return {
    enrollId: getId(),
  };
};
