// @ts-ignore
import React, { useMemo, useState } from 'react';
import { handleHealthErrors, useCopy } from '@app/utils';
import {
  useDeprecatedMutation,
  useQuery,
  HealthApplicationQueryData,
  queries,
  getMembers,
  useMutation,
  mutations,
} from '@data';
import { navigate, routes, sheets, useSheet } from '@navigate';
import { MultiMemberSplitFormBlueprint } from '@app/blueprints';
import { fields, followUpFields } from './immigrationDetailsFields';
import { formatPayload, getInitialValues } from './immigrationDetailsUtils';
import shallow from 'zustand/shallow';
import { loaders } from '@app/config';
import { popToast } from '@app/_ui-kit';

const ImmigrationDetails = ({ isGA, applicationID }) => {
  const [followUp, setFollowUp] = useState(false);
  const [asked75, setAsked75] = useState(false);

  const { loading, data } = useQuery<HealthApplicationQueryData>(queries.HEALTH_APPLICATION, {
    variables: { applicationID },
    skip: !applicationID,
  });

  const { members, isRequestingFinancialAssistance, self, spouse } = useMemo(() => {
    const { screening, uiQuestionsToDisplay } = data?.viewerTwo.health.application || {};

    const allMembers = getMembers(data);

    const self = allMembers.find((m) => m?.relation === 'SELF');
    const spouse = allMembers.find((m) => m?.relation === 'SPOUSE');

    // if we've already asked question 75 on the initial load of this page,
    // then we don't need to ensure and follow up
    if (!followUp && uiQuestionsToDisplay?.['75']) {
      setAsked75(true);
    }

    return {
      members: allMembers.filter((m) =>
        followUp
          ? m?.uiQuestionsToDisplay?.['75']
          : m?.uiQuestionsToDisplay?.['45'] || m?.uiQuestionsToDisplay?.['40'],
      ),
      isRequestingFinancialAssistance: screening?.isRequestingFinancialAssistance,
      self,
      spouse,
    };
  }, [data?.viewerTwo.health.application, followUp]);

  const handleNext = () => {
    if (isRequestingFinancialAssistance) {
      navigate(routes.EDE_MEDICAID_DENIAL);
    } else {
      navigate(routes.EDE_SEP);
    }
  };

  const { c } = useCopy(`catch.ede.ImmigrationDetails`);

  const { openLoader, close } = useSheet(
    (state) => ({
      openLoader: () => state.open(sheets.LOADER, loaders.HEALTH_LONG_WAIT),
      close: state.closeAll,
    }),
    shallow,
  );

  const [upsert] = useMutation(mutations.UPSERT_APPLICATION_MEMBERS);

  // @ts-ignore
  const [ensure, { loading: ensuring }] = useDeprecatedMutation('ensureHealthApplication', {
    ...handleHealthErrors,
    onCompleted: (data) => {
      // check for whether we need to show question 75 as follow up afterwards
      if (data?.uiQuestionsToDisplay?.['75']) {
        setFollowUp(true);
        close();
        popToast({
          type: 'important',
          msg: "We've added a question based on your answers",
          title: "We've added another question",
        });
      } else {
        handleNext();
      }
    },
  });

  return (
    <MultiMemberSplitFormBlueprint
      loading={loading}
      submitting={ensuring}
      title={(values) => (values?.givenName ? c('nameTitle', { name: values?.givenName }) : '')}
      data={{ isGA, self, spouse }}
      members={members}
      fields={followUp ? followUpFields : fields}
      getInitialValues={getInitialValues}
      onNext={(values) => upsert(formatPayload({ applicationID, values, followUp }))}
      onComplete={async ({ reset }) => {
        if (!followUp && !asked75) {
          openLoader();
          await ensure({ variables: { applicationID } });
          reset();
        } else {
          handleNext();
        }
      }}
    />
  );
};

export const ImmigrationDetailsView = {
  name: 'EDE_IMMIGRATION_DETAILS',
  component: ImmigrationDetails,
  options: {
    ...MultiMemberSplitFormBlueprint.options,
    title: 'Immigration details',
  },
};
