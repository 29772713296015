import { StyleSheet, Platform } from 'react-native';
import { s } from '@app/_ui-kit/theme/static';
import { useTheme } from '@uikit/hooks/useTheme';

export function useSharedStyles() {
  const { theme, themeColors } = useTheme();
  return {
    colorStyles: {
      container: [theme.largeCorners, { backgroundColor: themeColors.bgGrayColor }],
      containerHovered: theme.pageLightBg,
      containerFocused: [theme.focusBorderColor, {}],
      containerUploading: [theme.focusBorderColor, {}],
      containerActive: {},
    },
  };
}

export const getGutters = (gutter) => gutter.split('|').map((g) => styles[`${g}Gutter`]);

export const styles = StyleSheet.create({
  container: {
    height: 200,
    ...Platform.select({
      web: {
        cursor: 'pointer',
      },
    }),
    overflow: 'hidden',
  },
  squareAvatar: {
    height: 260,
    width: 260,
  },
  content: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  progress: {
    paddingLeft: 96,
    paddingRight: 96,
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  preview: {
    height: '100%',
    width: '100%',
    ...Platform.select({
      web: {
        backgroundImage:
          'linear-gradient(180deg, rgba(31, 37, 51, 0.3) 60.98%, rgba(31, 37, 51, 0.85) 100%)',
        backgroundSize: '100%',
        backgroundPosition: '50% 50%',
        backgroundRepeat: 'no-repeat',
      },
    }),
  },
  nativePreview: {
    width: '100%',
    height: 260,
  },
  fullWidth: {
    width: '100%',
  },
  delete: {
    position: 'absolute',
    right: 20,
    bottom: 20,
  },
  topGutter: s?.topGutter3,
  leftGutter: s?.leftGutter3,
  rightGutter: s?.rightGutter3,
  bottomGutter: s?.bottomGutter3,
});
