import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Copy } from '@types';
import Card from '@layouts/Card';
import { Stack } from '@layouts/Stack';
import { Text } from './Text';
import { useTheme } from '../hooks/useTheme';

export interface AnnotationItem {
  id?: string;
  title: Copy;
  scheme: 'positive' | 'negative' | 'info';
}

export const Annotation: React.FC<{ note: AnnotationItem }> = ({ note }) => {
  const { themeColors } = useTheme();

  return (
    <View style={[{ backgroundColor: themeColors[`${note.scheme}LightColor`] }, styles.banner]}>
      <Text size="sm" color={note.scheme}>
        {note.title}
      </Text>
    </View>
  );
};

export const AnnotationGroup: React.FC<{ notes: Array<AnnotationItem> }> = ({ notes }) => {
  return (
    <Card padded={false} style={styles.container}>
      <Stack spacing="+gap">
        {notes.map((note, index) => (
          <Annotation key={note.id || index} note={note} />
        ))}
      </Stack>
    </Card>
  );
};

const styles = StyleSheet.create({
  container: {
    borderRadius: 8,
  },
  banner: {
    paddingVertical: 4,
    paddingHorizontal: 6,
  },
});

export default Annotation;
