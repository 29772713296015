import React, { useCallback, useMemo, useState } from 'react';
import { KeyboardAvoidingView, View } from 'react-native';
import { close, replace, routes, ScreenDefinition } from '@navigate';
import { CustomGoalIcons, GoalSlug } from '@types';
import { TextInput } from '@app/forms';
import { deslug, useCopy } from '@app/utils';
import { Button } from '@uikit';
import { useGoal } from '@app/hooks';
import { BasicLayout, Stack, Toolbar } from '@layouts';
import { Asset } from '@components/Asset';

interface Props {
  slug?: GoalSlug;
  creation?: boolean;
}

export const CustomGoalIconChoices: Array<CustomGoalIcons> = [
  'TARGET',
  'HOUSE',
  'LUGGAGE',
  'CAKE',
  'TOOLS',
  'CAR',
  'LAPTOP',
  'PRICE_TAG',
  'PET',
  'GIFT',
  'BRIEFCASE',
  'SAFE',
];

const GoalCustomizeIconSelection = ({ icon, setIcon }) => {
  return (
    <View style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row' }}>
      {CustomGoalIconChoices.map((value) => {
        const params = Asset.configureBenefit('custom-goal', value, 'md');
        return (
          <Asset
            key={value}
            {...params}
            containerSize="xl"
            containerStyle={{
              display: 'flex',
              alignItems: 'center',
              marginLeft: 10,
              marginRight: 10,
            }}
            style={{ margin: 10, cursor: 'pointer' }}
            bg={icon === value ? params.bg : 'transparent'}
            onPress={() => setIcon(value)}
          />
        );
      })}
    </View>
  );
};

/**
 * Renders an edit screen for changing name/icon for custom goal
 */
export const GoalCustomize = ({ slug = 'custom-goal', creation = false }: Props) => {
  const { c: basics } = useCopy('catch.basics');

  const { goal, upsert, loading } = useGoal({
    slug,
    refetchQueries: ['Goal'],
    onCompleted: ({ upsertGoal }) => {
      close();
      if (upsertGoal.status === 'NOT_STARTED' || creation) {
        replace(routes.GOAL_ESTIMATOR, { slug: upsertGoal.slug, creation: true });
      } else {
        replace(routes.PLAN_DETAIL, { slug: upsertGoal.slug });
      }
    },
  });

  const [name, setName] = useState(goal?.name);
  const [icon, setIcon] = useState(goal?.icon || 'TARGET');

  const isCustomGoal = useMemo(() => {
    if (goal) {
      return goal.goalType === 'CUSTOM_SAVINGS';
    }
    return slug === 'custom-goal';
  }, [slug, goal]);

  const saveGoal = useCallback(() => {
    return upsert({ name, slug, icon });
  }, [name, slug, icon]);

  return (
    <BasicLayout
      loading={loading}
      toolbar={
        <Toolbar type="update" narrow>
          <Button
            inherit
            loading={loading}
            onPress={saveGoal}
            testID="update-btn"
            disabled={(!isCustomGoal && !goal?.name) || goal?.name === ''}
          >
            {creation ? basics('continue') : basics('save')}
          </Button>
        </Toolbar>
      }
    >
      <Stack spacing="2">
        <KeyboardAvoidingView>
          <TextInput
            large
            label="Name"
            placeholder="Goal Name"
            value={name}
            onChange={(e) => setName(e)}
          />
        </KeyboardAvoidingView>
        {isCustomGoal && <GoalCustomizeIconSelection icon={icon} setIcon={setIcon} />}
      </Stack>
    </BasicLayout>
  );
};

/**
 * @todo E-3287
 */
export const GoalCustomizeView: ScreenDefinition = {
  name: routes.GOAL_CUSTOMIZE,
  component: GoalCustomize,
  options: {
    title: ({ slug, creation }) => {
      if (creation) {
        return 'Create Custom Goal';
      }
      return `Edit ${deslug(slug)}`;
    },
    layout: 'sheet',
  },
};
