import { useDeprecatedQuery } from '@data';
import {
  safeFormatDate,
  goalHasMinBalance,
  checkTaxPaymentPeriod,
  TAX_PAYMENT_PROMPT_MIN,
} from '@app/utils';
import { useNudge } from './useNudge';

/**
 * useTaxPaymentPeriod should return the current quarter + year
 * expects a location for the nudge to handle dismissal
 *
 * Note: the locations are dismissed after making a tax payment,
 * so if you create a new location, make sure to dismiss them
 */
export const useTaxPaymentPeriod = (location) => {
  const { taxGoal } = useDeprecatedQuery('taxGoal', { returnPartialData: true });

  const { shouldPromptTaxPeriod, quarterNum, taxYear, quarterDueDate, quarterProgress } =
    checkTaxPaymentPeriod();

  // Possible locations: 'action-item' and 'overview'
  const { isDismissed, dismissNudge } = useNudge(
    `Q${quarterNum}-${taxYear}-estimated-taxes-${location}`,
  );

  const allowTaxPayment = goalHasMinBalance(taxGoal, 0);
  const hasPromptBalance = goalHasMinBalance(taxGoal, TAX_PAYMENT_PROMPT_MIN);

  return {
    allowTaxPayment,
    showReminder: shouldPromptTaxPeriod && hasPromptBalance && !isDismissed,
    quarter: `Q${quarterNum}`,
    dueDate: quarterDueDate ? safeFormatDate(quarterDueDate, 'MMM dd') : null,
    quarterProgress,
    quarterNum,
    taxYear,
    handleDismiss: dismissNudge,
  };
};
