import React from 'react';

import { useCopy } from '@app/utils';
import { Link } from '@uikit';
import { Section } from '@app/layouts';

const voterRegistrationUrl = 'https://www.eac.gov/voters/national-mail-voter-registration-form/';

/**
 * required by CMS
 */
const VoterRegistration = () => {
  const { c } = useCopy('catch.ede.EligibilityResults');

  return (
    <Section title={c('voterTitle')} subtitle={c('voterText')}>
      <Link testID="voter-registration" small target="_blank" href={voterRegistrationUrl}>
        {c('voterButtonText')}
      </Link>
    </Section>
  );
};

export default VoterRegistration;
