import React from 'react';
import { FileInput, useResponsive } from '@uikit';
import { useCopy } from '@app/utils';
import { useUpload } from '@client';

import UploadIcon from '@svg/upload-cloud.svg';

const PREFIX = 'catch.FileDropper';

const FileDropper = ({
  file,
  handleDelete,
  handleDrop,
  filePrefix,
  copyPrefix,
  disabled,
  renderPreview,
  name,
  squareAvatar = false,
  icon,
}) => {
  const { c } = useCopy(copyPrefix || PREFIX);

  const { upload, deleteFile, files } = useUpload({
    filePrefix,
    onSuccess: handleDrop,
    onDelete: handleDelete,
  });

  const { isDesktop } = useResponsive();

  return (
    <FileInput
      value={files[name] || file}
      onUpload={upload}
      onDelete={deleteFile}
      name={name}
      label={isDesktop ? c('plhTitle.desktop') : c('plhTitle.mobile')}
      sublabel={isDesktop ? c('plhCaption.desktop') : undefined}
      disabled={disabled}
      renderPreview={renderPreview}
      squareAvatar={squareAvatar}
      svg={icon || UploadIcon}
    />
  );
};
export default FileDropper;
