import gql from 'graphql-tag';

export interface IncomeQueryData {
  viewer: {
    id: string;
  };
}

export const IncomeQuery = gql`
  query IncomeQuery {
    viewer {
      id
    }
  }
`;
