import React from 'react';
import { useCopy } from '@app/utils';
import { pop } from '@navigate';
import { Button } from '@uikit';
import { Toolbar } from '@layouts';
import FlowLayout from '@layouts/FlowLayout';

/**
 * @deprecated
 * this should use combination of new layout + blueprints
 * there are 11 instances left of this one.
 * most should be able to be replaced pretty easily w/new onboarding
 */
const ExplorerLayout = ({
  title,
  subtitle,
  disabled,
  canSkip,
  onSkip,
  onNext,
  toolbar = true,
  customToolbar,
  children,
  loading,
  hideBottomBar,
  render,
  titleAlign,
}) => {
  const { c } = useCopy('catch.basics');

  const nextButton = (
    <Button inherit loading={loading} alt disabled={disabled || loading} onPress={onNext}>
      {c('next')}
    </Button>
  );

  const skipButton = (
    <Button inherit loading={loading} onPress={onSkip} disabled={loading}>
      {c('next')}
    </Button>
  );

  return (
    <FlowLayout
      narrow
      align={titleAlign}
      render={render}
      loading={loading}
      title={title}
      subtitle={subtitle}
      hideBottomBar={hideBottomBar}
      customBottomBar={
        toolbar &&
        !hideBottomBar && (
          <Toolbar
            narrow
            type={customToolbar ? 'dialog' : 'flow'}
            onBack={!customToolbar ? pop : undefined}
          >
            {customToolbar ? customToolbar : disabled && canSkip ? skipButton : nextButton}
          </Toolbar>
        )
      }
    >
      {children}
    </FlowLayout>
  );
};

export default ExplorerLayout;
