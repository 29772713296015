import React, { useMemo } from 'react';
import {
  useQuery,
  queries,
  HealthApplicationQueryData,
  useMutation,
  mutations,
  updates,
} from '@data';
import { navigate, routes } from '@navigate';
import { SplitFormBlueprint } from '@app/blueprints';
import { fields } from './confirmApplicantsFields';
import {
  formatMembersPayload,
  formatApplicationPayload,
  getInitialValues,
  checkForRescreening,
} from './confirmApplicantsUtils';
import { useCopy } from '@app/utils';
import { popToast } from '@app/_ui-kit';

const ConfirmApplicants = ({ applicationID }) => {
  const { c } = useCopy('catch.ede.ConfirmApplicants');

  const [upsertApp, { loading: upsertingApplication }] = useMutation(
    mutations.UPSERT_HEALTH_APPLICATION,
  );

  const [upsertMembers, { loading: upsertingMembers }] = useMutation(
    mutations.UPSERT_APPLICATION_MEMBERS,
    {
      update: updates.UPDATE_APPLICATION_MEMBERS,
    },
  );

  const { data } = useQuery<HealthApplicationQueryData>(queries.HEALTH_APPLICATION, {
    variables: { applicationID },
    skip: !applicationID,
  });

  const initialValues = useMemo(() => getInitialValues(data), [data]);

  const handleSubmit = async (values) => {
    // determines if there are any diffs
    const rescreen = checkForRescreening({ values });

    // handles updating app + members
    await Promise.all([
      upsertApp({ variables: formatApplicationPayload({ values, applicationID }) }),
      upsertMembers({ variables: formatMembersPayload({ values, applicationID }) }),
    ]);

    // handles proper navigation
    if (rescreen) {
      navigate(routes.EDE_SCREENING, { householdRevisit: true });

      popToast({
        type: 'success',
        title: 'Your household has changed',
        msg: "You'll have to answer some questions again",
      });
    } else {
      navigate(routes.EDE_MEMBER_INFO);
    }
  };

  return (
    <SplitFormBlueprint
      loading={!data}
      submitting={upsertingApplication || upsertingMembers}
      dependencies={['members']}
      title={c('title')}
      subtitle={({ members }) => {
        const household = members?.length;
        const numApplicants = members?.filter((m) => m.isRequestingCoverage)?.length;
        return c('subtitle', { numApplicants, household });
      }}
      formConfig={{
        initialValues,
        fields,
        onSubmit: handleSubmit,
      }}
    />
  );
};

export const ConfirmApplicantsView = {
  name: routes.EDE_CONFIRM_APPLICANTS,
  component: ConfirmApplicants,
  options: {
    ...SplitFormBlueprint.options,
    title: 'Applicants',
  },
};
