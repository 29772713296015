import gql from 'graphql-tag';

export const UNIT_LEGAL = gql`
  query UnitLegalQuery {
    viewerTwo {
      id
      agreements {
        id
        acknowledgedUnitAccountDisclosures
      }
    }
    viewer {
      id
      user {
        id

        kycSavings {
          status
          savingsProviderType
        }
      }
      goals {
        id
        paycheckPercentage
        slug
        totalBalance
        status
        name
        type: goalType
      }
      bankLinks {
        id
        providerType
        accounts {
          id
          accountNumber
          isWatched
        }
      }
    }
  }
`;

const formatter = (data) => {
  const v = data?.viewer;
  const u = v?.user;
  const goals = v?.goals?.filter((g) => g.status === 'ACTIVE' || g.status === 'PAUSED');

  return {
    isUnit: true || u?.kycSavings?.savingsProviderType === 'UNIT',
    bankProvider: u?.kycSavings?.savingsProviderType,
    hasAgreed: data?.viewerTwo?.acknowledgedUnitAccountDisclosures,
    kycStatus: u?.kycSavings?.status,
    goals,
    totalPercentage:
      goals?.reduce((total, goal) => parseFloat(total) + parseFloat(goal?.paycheckPercentage), 0) ||
      0,
    accountNumbers:
      v?.bankLinks?.flatMap((link) => link?.accounts?.map((acct) => acct?.accountNumber)) || [],
  };
};

const query = {
  document: UNIT_LEGAL,
  formatter,
};

export default query;
