import gql from 'graphql-tag';
import { DocumentUploadType } from '@app/types';

export interface DocumentUploadsQueryData {
  viewerTwo: {
    id: string;
    documentUploads: Array<{
      id: string;
      documentType: DocumentUploadType;
      createdOn: Date;
    }>;
  };
}

export const DocumentUploadsQuery = gql`
  query DocumentUploads {
    viewerTwo {
      id
      documentUploads {
        id
        documentType
        name @client
        createdOn
      }
    }
  }
`;
