import React from 'react';

import { Currency, useCopy, formatBenefit, formatBank } from '@app/utils';
import { useDeprecatedMutation } from '@data';
import { ConfirmationHeader } from '@common';
import { close, routes } from '@navigate';
import { Layout, Toolbar, Stack, Page } from '@layouts';
import { Button, LineItem, useTheme } from '@uikit';
import ConfirmationBlueprint from '@app/blueprints/ConfirmationBlueprint';
import { benefitColorLookup } from '@app/utils/color';

const isGoal = (item) => {
  return item.__typename !== 'Account';
};

const format = (item) => {
  if (isGoal(item)) {
    return formatBenefit.name(item);
  } else {
    return formatBank.detail(item);
  }
};

/**
 * View for showing transfer funds confirmation
 * - this page acts as confirmation before user decides to move money
 * - handles for either direction of movement + any goal type
 *
 * - NOTE: by the time we get to this page, transferType, transferAmount, and
 *   goal should all be specified
 */
const TransferConfirm = ({ transferType, transferAmount, goal, source, destination }) => {
  const { theme } = useTheme();
  const { c, $ } = useCopy('catch');

  /**
   * uses the mutation for depositing and withdrawing funds
   * NOTE: this is where we set the direction of deposits
   */
  const [transferFunds, transfer1] = useDeprecatedMutation(
    transferType === 'deposit' ? 'depositToGoal' : 'withdrawFromGoal',
    {
      goalID: goal.id,
    },
  );

  const [transferBetweenGoals, transfer2] = useDeprecatedMutation('transferBetweenGoals', {
    goalID: goal.id,
  });

  const handleTransfer = () => {
    if (isGoal(source) && isGoal(destination)) {
      transferBetweenGoals({
        variables: {
          input: {
            fromGoalID: source?.id,
            toGoalID: destination?.id,
            amount: transferAmount,
          },
        },
      });
    } else {
      const goal = transferType === 'deposit' ? destination : source;
      const account = transferType === 'deposit' ? source : destination;

      transferFunds({
        variables: {
          input: {
            goalID: goal.id,
            goalType: goal.goalType,
            amount: transferAmount,
            accountID: account.id,
          },
        },
      });
    }
  };

  return (
    <ConfirmationBlueprint
      called={transfer1.called || transfer2.called}
      loading={transfer1.loading || transfer2.loading}
      error={transfer1.error || transfer2.error}
      accentColor={benefitColorLookup(goal || {})}
      onSuccess={() => close()}
      titles={{
        error: 'Unable to complete transfer',
        loading: 'Loading',
        done: c(`TransferFunds.confirmation.${transferType}.title`, {
          amount: $(transferAmount),
        }),
      }}
      subtitles={{
        error: 'Contact support for more information',
        done: c(`TransferFunds.confirmation.${transferType}.description`),
      }}
    >
      <Page color="sheet">
        <Layout.Scroll>
          <ConfirmationHeader
            amount={transferAmount}
            subtitle={c(`sequences.Transfer.confirm.subtitle.${transferType}`, {
              catchAccount: format(transferType === 'deposit' ? destination : source),
            })}
          />

          <Layout margins style={[theme.yGutter4]}>
            <Stack separatorComponent spacing="1">
              <LineItem left={c('basics.from')} right={format(source)} />
              <LineItem left={c('basics.to')} right={format(destination)} />
            </Stack>
          </Layout>
        </Layout.Scroll>
        <Toolbar>
          <Button inherit onPress={handleTransfer}>
            {c(`sequences.Transfer.confirm.${transferType}Button`, {
              amount: <Currency key="amount">{transferAmount}</Currency>,
            })}
          </Button>
        </Toolbar>
      </Page>
    </ConfirmationBlueprint>
  );
};

export const TransferConfirmView = {
  name: routes.TRANSFER_CONFIRM,
  component: TransferConfirm,
  options: {
    title: 'Confirm',
  },
};
