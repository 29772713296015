import gql from 'graphql-tag';
import access from 'safe-access';
import { source } from '../fragments';

const INCOME_AUTOMATION_RULE = gql`
  query _IncomeAutomationRule($sourceId: ID!) {
    viewer {
      id
      incomeAutomationRule(id: $sourceId) {
        id
        automationType
        workType
        ...RuleSource
      }
    }
  }
  ${source.fragments.sourceFromRule}
`;
const formatter = (data) => {
  const get = access(data);
  const rule = get('viewer.incomeAutomationRule') || [];
  const automationType = get('viewer.incomeAutomationRule.automationType');
  const ruleExists = ['ALWAYS', 'SOMETIMES', 'NEVER'].includes(automationType);
  const ruleActive = ['ALWAYS', 'NEVER'].includes(automationType);

  return {
    ruleExists,
    ruleActive,
    rule: {
      ...rule,
      source: source.formatSource(rule),
    },
  };
};

export default {
  document: INCOME_AUTOMATION_RULE,
  formatter,
};
