import { ScreenDefinition, StackDefinition } from './types';
import { Route } from './routes';
import { useSheet } from './sheets';
import * as NavigationActions from './actions';

/**
 * Handles screen registration when starting app
 */
interface Init {
  screens: Array<ScreenDefinition>;
  stacks: Array<StackDefinition>;
}

export const init = ({ screens, stacks }: Init) => {
  NavigationActions.init({ screens, stacks });
};

/**
 * Handles the crux of navigation for the app
 */
export const navigate = (screenName: Route, data?: object) => {
  useSheet.getState().closeAll();

  // @ts-ignore
  NavigationActions.push(screenName, data);
};

/**
 * Handles opening a new flow
 * On native, this will either open a full height/modal height page
 * On web, this will either direct via the url or open a web modal
 */
export const open = (screenName: Route, data: object = {}) => {
  useSheet.getState().closeAll();
  return NavigationActions.open(screenName, data);
};

/**
 * Pops the top screen
 */
export const pop = ({ toRoot } = { toRoot: false }) => {
  NavigationActions.pop(toRoot);
};

/**
 * Handles replacing a screen
 * This is helpful when we have loading state or similar
 * where we don't want a full push navigation
 */
export const replace = (screenName: Route, data: object = {}) => {
  useSheet.getState().closeAll();
  NavigationActions.replace(screenName, data);
};

/**
 * Handles closing a flow/modal
 * On native, this closes the highest level modal
 * On web, this will either direct via the url or open a web modal
 */
interface CloseOptions {
  closeSheets?: boolean;
}

export const close = (options?: CloseOptions) => {
  if (options?.closeSheets) {
    useSheet.getState().closeAll();
  }

  return NavigationActions.close();
};

/**
 * Handles exiting all flows/modals
 * On native, this will close any open modals
 * On web, this will close any modals + remove user from flow
 */
export const exit = (route?: Route) => {
  useSheet.getState().closeAll();
  NavigationActions.exit(route);
};
