import { navigate, routes, sheets, useSheet } from '@navigate';
import { PromptName, Prompt, PromptContext } from '@types';
import { getCarrierAsset, launchPlaid, safeFormatDate } from '@app/utils';

import Lock from '@svg/lock.svg';
import Clock from '@svg/clock.svg';
import Document from '@svg/file-important.svg';
import Statement from '@svg/file.svg';
import Denied from '@svg/minus-circle.svg';
import ShieldWarning from '@svg/shield-warning.svg';
import Link from '@svg/linked.svg';
import Sad from '@svg/face-sad.svg';
import Arrow from '@svg/arrow-up-right.svg';

const DATE_FORMAT = 'MMM d';
const EOL = 'April 6';
const EOL2 = 'April 19';

export const promptOrder: Record<PromptContext, number> = {
  ACCOUNT: 0,
  BLOCKING_CLOCK: 1,
  BLOCKING: 2,
  STATUS: 3,
  INCOME: 4,
  TODO: 5,
};

/**
 *
 * @todo add:
 * - health manual idp in progress
 * - rate the app
 * - rollovers
 * - health effectuation (and rest of OE prompts)
 * - all set
 *
 */
export const Prompts: Record<PromptName, Prompt | ((data?: object) => Prompt)> = {
  /**
   *
   *
   * ACCOUNT
   *
   *
   */
  ACCOUNT_LOCKED: {
    name: 'ACCOUNT_LOCKED',
    context: 'ACCOUNT',
    title: 'Your account is locked',
    subtitle: 'Contact support for assistance',
    icon: Lock,
    // gradient: 'negativeLight',
    dependencies: ['LOCKED'],
    actions: [
      {
        label: 'Support',
        type: 'HELP',
      },
    ],
  },
  KYC_DENIED: {
    name: 'KYC_DENIED',
    context: 'ACCOUNT',
    title: `We weren't able to open your account`,
    subtitle: 'Contact support to learn more',
    article: 'https://help.catch.co/en/articles/3753628-why-was-i-denied',
    // gradient: 'negativeLight',
    dependencies: ['DENIED'],
    icon: Denied,
    actions: [
      {
        label: 'Support',
        type: 'HELP',
      },
    ],
  },
  KYC_REVIEW: {
    name: 'KYC_REVIEW',
    context: 'ACCOUNT',
    icon: Clock,
    title: 'Your account is almost ready',
    subtitle: "We're just reviewing a few final details",
    dependencies: ['KYC_PENDING'],
    article: 'https://help.catch.co/en/articles/2130140-what-is-kyc',
  },
  MANUAL_ID_REVIEW: {
    name: 'MANUAL_ID_REVIEW',
    context: 'ACCOUNT',
    icon: Clock,
    title: 'Documents in review',
    subtitle: "We're reviewing your uploaded identity documents",
    dependencies: ['MANUAL_ID_REVIEW'],
  },

  /**
   *
   *
   * BLOCKING
   *
   *
   */
  BANK_LINK_ERROR: (data) => ({
    name: 'BANK_LINK_ERROR',
    context: 'BLOCKING',
    title: `Reconnect ${data?.bankName || 'your bank'}`,
    subtitle: 'Your account is disconnected',
    dependencies: ['BANK_ERROR'],
    // gradient: 'mediumLight',

    icon: ShieldWarning,
    asset: {
      base64: data?.logo,
    },
    actions: [
      {
        label: 'Fix',
        type: 'CUSTOM',
        onAction: () => {
          launchPlaid({
            mode: data?.mode,
            bankLinkID: data?.bankLinkID,
          });
        },
      },
    ],
  }),
  // LINK_BANK: {
  //   name: 'LINK_BANK',
  //   context: 'BLOCKING',
  //   title: 'Connect your bank account',
  //   subtitle: 'Connect your account to set aside for taxes, retirement, health, and more',
  //   icon: Link,
  //   gradient: 'brandLight',
  //   dependencies: ['NO_BANK'],
  //   article: 'https://help.catch.co/en/articles/2034414-why-do-i-need-to-link-my-bank-account',
  //   actions: [
  //     {
  //       label: 'Link',
  //       type: 'CUSTOM',
  //       onAction: (props) => launchPlaid({ mode: 'create', setLoading: props.setLoading }),
  //     },
  //   ],
  // },
  // KYC_DOCS: {
  //   name: 'KYC_DOCS',
  //   context: 'BLOCKING',
  //   icon: Document,
  //   title: 'Documents required',
  //   gradient: 'mediumLight',
  //   subtitle: 'Before activating your account, we need some additional documentation',
  //   dependencies: ['KYC_NEEDS_DOCS'],
  //   article: 'https://help.catch.co/en/articles/2130140-what-is-kyc',
  //   actions: [
  //     {
  //       label: 'Upload',
  //       type: 'ROUTE',
  //       route: routes.DOC_UPLOAD,
  //     },
  //   ],
  // },
  // CIP_DOCS: {
  //   name: 'CIP_DOCS',
  //   context: 'BLOCKING',
  //   icon: Document,
  //   title: 'Documents required',
  //   gradient: 'mediumLight',
  //   subtitle: 'To set up your retirement account, we need some additional documentation',
  //   dependencies: ['CIP_NEEDS_DOCS'],
  //   article: 'https://help.catch.co/en/articles/2130140-what-is-kyc',
  //   actions: [
  //     {
  //       label: 'Upload',
  //       type: 'ROUTE',
  //       route: routes.DOC_UPLOAD,
  //     },
  //   ],
  // },

  /**
   *
   *
   * INCOME
   *
   *
   */
  PAYCHECK: (data) => ({
    forEach: true,
    name: 'PAYCHECK',
    context: 'INCOME',
    title: '(paychecks)',
    dependencies: ['INCOME'],
    data,
    actions: [
      {
        label: 'Confirm',
        type: 'ROUTE',
        route: routes.PAYCHECK_INCOME,
      },
    ],
  }),

  NEEDS_HEALTH_DOCS: (data) => ({
    forEach: true,
    name: 'NEEDS_HEALTH_DOCS',
    context: 'BLOCKING',
    title: data?.actionNeeded ? 'Finalize your coverage' : 'Your docs are pending',
    subtitle: data?.actionNeeded
      ? `Upload required documentation by ${safeFormatDate(data?.deadline, DATE_FORMAT)}`
      : 'Your docs are awaiting review',
    accentColor: 'coverage',
    gradient: 'coverageLight',
    icon: Document,
    dependencies: [],
    actions: [
      {
        label: data?.actionNeeded ? 'Upload' : 'View',
        type: 'CUSTOM',
        onAction: () => navigate(routes.EDE_DMI, { applicationID: data?.applicationID }),
      },
    ],
  }),
  PAYMENT_REDIRECT: (data) => ({
    forEach: true,
    name: 'PAYMENT_REDIRECT',
    context: 'BLOCKING',
    title: `Make your first payment`,
    article: 'https://help.catch.co/en/articles/4543944-how-do-i-pay-my-health-premium',
    subtitle: `Make a payment by ${safeFormatDate(
      data?.policy?.dueDate,
      DATE_FORMAT,
    )} to confirm and activate your ${
      data?.policy?.productType?.includes('DENTAL') ? 'dental' : 'health'
    } coverage`,
    accentColor: 'coverage',
    gradient: 'coverageLight',
    asset: getCarrierAsset(
      data?.policy?.carrier || data?.policy?.providerPlan?.issuer?.name,
      data?.policy?.productType,
      'md',
    ),
    dependencies: [],
    data,
    actions: [
      {
        label: 'Pay premium',
        type: 'CUSTOM',
        onAction: () =>
          useSheet
            .getState()
            .open(sheets.PAYMENT_REDIRECT, { policy: data?.policy, enrollment: data?.enrollment }),
      },
    ],
  }),

  DOCS_AVAILABLE_2: {
    name: 'DOCS_AVAILABLE_2',
    context: 'BLOCKING',
    title: '2022 Documents',
    subtitle: 'Annual tax documents are ready to download',
    dependencies: ['DOCS_AVAILABLE'],
    // dismissable: true,
    // momentary: true,
    icon: Statement,
    actions: [
      {
        label: 'View',
        type: 'ROUTE',
        route: routes.STATEMENTS,
      },
    ],
  },
  REVIEW_AVAILABLE: {
    // gradient: 'brandLight',
    name: 'REVIEW_AVAILABLE',
    context: 'BLOCKING',
    title: '2022 in Review',
    subtitle: `Take a look, download your tax documents, and get ready for what's next`,
    dependencies: ['DOCS_AVAILABLE'],
    dismissable: true,
    render: 'brand',
    actions: [
      {
        label: 'View',
        type: 'ROUTE',
        route: routes.ANNUAL_REVIEW,
      },
    ],
  },
};

export const PromptList: Prompt[] = Object.values(Prompts);
