// @ts-nocheck
import React from 'react';
import { useCopy } from '@app/utils/language';
import { useBiometrics } from '@app/client/utils';
import { navigate, routes, ScreenDefinition } from '@navigate';
import { Button } from '@uikit';
import { Toolbar, CalloutLayout } from '@layouts';

const OnboardingBiometrics = (props) => {
  const { c: basics } = useCopy('catch.basics');
  const { c } = useCopy('catch.biometrics');

  // @ts-ignore
  const { supported, enable, prompt } = useBiometrics();

  const activate = async () => {
    enable();
    await prompt();
    navigate(routes.SET_NOTIFICATIONS);
  };

  const skip = () => {
    navigate(routes.SET_NOTIFICATIONS);
  };

  return (
    <CalloutLayout
      render="faceid"
      title={c('addBiometrics', {
        medium: supported || 'Biometrics',
      })}
      subtitles={['Log in to your Catch account without entering a password']}
      toolbar={
        <Toolbar>
          <Button testID="activate-biometrics" accentColor="brand" onPress={activate}>
            Enable
          </Button>
          <Button testID="skip-biometrics" accentColor="brandLight" onPress={skip}>
            {basics('maybeLater')}
          </Button>
        </Toolbar>
      }
    />
  );
};

export const OnboardingBiometricsView: ScreenDefinition = {
  name: routes.ONBOARDING_BIOMETRICS,
  component: OnboardingBiometrics,
  options: {},
};
