import React from 'react';
import { useHealthHandoff } from '@hooks';
import { Env, Segment, useCopy } from '@app/utils';
import { SheetBlueprint } from '@app/blueprints';
import { exit, routes } from '@navigate';

const phases = {
  // phase 2 screening questions
  isCanProvideSsn: 2,
  isNaturalizedOrDerivedCitizenship: 2,
  areCitizens: 2,
  isFormerFosterCareAndUnder25: 2,
  isAnyoneFullTimeStudent1822: 2,
  isPregnantOrHadChildLast60Days: 2,
  isIncarcerated: 2,
  isStepchildOrGrandchild: 2,

  // phase 3 screening questions
  areAllSameAddress: 3,
  isPlanningToFileTax: 3,
  isResponsibleForChildNotOnTaxReturn: 3,
  isIndianOrAlaskan: 3,
  isDependentSingleChild25OrYounger: 3,
  isClaimingAllDependentsOnTax: 3,
  isCoveredThroughJobOrCobra: 3,
  isOfferedIchra: 3,
  areDependentsLivingWithParentNotOnTaxReturn: 3,
  areDependentsChildOrStepchildSingleUnder26: 3, // screens out grandchildren, other dependent issues
};

const HealthScreenedOutSheet = ({ failedQuestion, coverageYear }) => {
  const { c } = useCopy('catch');

  const { handoff } = useHealthHandoff({
    handoffType: Env.isDevLike ? 'HEALTHCARE_GOV' : 'HEALTHSHERPA',
    options: {
      coverageYear,
      onHandoff: () => {
        Segment.track('EDE Screened Out', {
          question: failedQuestion,
          phase: phases[failedQuestion],
        });

        exit(routes.COVERAGE);
      },
    },
  });

  return (
    <SheetBlueprint
      title={c('health.screeningHandoff.title')}
      // subtitle={c('health.screeningHandoff.description')}
      actions={[{ type: 'CUSTOM', onAction: handoff, label: c('healthBasics.applyNow') }]}
    />
  );
};

export default HealthScreenedOutSheet;
