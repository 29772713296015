import React from 'react';
import { Platform, View, StyleSheet } from 'react-native';
import { useToasts } from './useToasts';
import Toast from './Toast';
import { Portal } from '../Portal';
import { useTheme } from '@uikit/hooks/useTheme';

export const ToastContainer = () => {
  const { bp } = useTheme();
  const { active, clearToast } = useToasts((state) => ({
    active: state.active,
    clearToast: state.clearToast,
  }));
  if (active.length === 0) {
    return null;
  }
  const slice = Platform.select({ web: [0, 3], native: [0, 1] });
  return (
    <Portal>
      <View
        style={[
          Platform.select({
            web: styles.container,
            native: styles.nativeContainer,
          }),
          bp < 1 && styles.smallSpace,
        ]}
      >
        {active.slice(...slice).map((t, i) => (
          <Toast
            key={t.id}
            {...t}
            isLast={i === active.length - 1}
            onDismiss={() => clearToast({ id: t.id })}
          />
        ))}
      </View>
    </Portal>
  );
};

const styles = StyleSheet.create({
  nativeContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    padding: 24,
    paddingTop: 32,
  },
  container: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    left: 0,
    width: '100%',
    alignItems: 'flex-end',
    padding: 24,
    zIndex: 999999999,
  },
  smallSpace: {
    padding: 16,
  },
  toastContainer: {
    backgroundColor: '#00746F',
    padding: 16,
    borderRadius: 8,
    width: 375,
    marginBottom: 12,
  },
});
