import React from 'react';
import { useCopy, calculateRetirementIncome } from '@app/utils';
import { formatCurrency } from '@app/utils/format/currency';
import { Bullet, Text } from '@uikit';
import { Section, Stack } from '@layouts';
import { GuideBlueprint } from '@blueprints';

/**
 *
 */
export const RetirementProjectionGuideSheet = ({
  currentAge,
  retirementAge,
  initialAmount,
  estimatedIncome,
  paycheckPercentage,
}) => {
  const { c, p, $ } = useCopy('catch.retirement.ProjectedValueInfo');
  const { c: c2 } = useCopy('catch.module.retirement.ProjectedValueCard');

  const {
    monthlyIncome,
    inputs: { deathAge, interestRate, inflation },
  } = calculateRetirementIncome({
    currentAge,
    estimatedIncome,
    paycheckPercentage,
    retirementAge,
    initialAmount,
  });

  const yearsBeforeRetirement = retirementAge - currentAge;

  const bulletStyles = {
    textSize: 'sm',
    textColor: 'subtler',
    bulletSize: 'sm',
    bulletColor: 'subtler',
  };

  return (
    <GuideBlueprint
      name="RETIREMENT_PROJECTION_GUIDE"
      asset={{
        containerSize: 'xl',
        size: 'md',
        render: 'retirement',
        bg: 'retirementLight',
        shape: 'circle',
      }}
      title={c2('label')}
      subtitle={c2('description', {
        retirementAge,
        monthlyIncome: formatCurrency((monthlyIncome || 0).toFixed(0)),
      })}
    >
      <Section>
        <Stack spacing="+gap">
          <Text size="fp" color="subtle" gutter="bottom">
            Assumptions
          </Text>
          <Bullet {...bulletStyles} text={c('l1', { age: currentAge, retirementAge })} />
          <Bullet
            {...bulletStyles}
            text={c('l2', {
              currentSavings: $(initialAmount),
              growthRate: p(interestRate),
            })}
          />
          <Bullet
            {...bulletStyles}
            text={c('l3', {
              estimatedIncome: $(estimatedIncome),
              yearsBeforeRetirement,
              rate: p(paycheckPercentage),
            })}
          />
          <Bullet {...bulletStyles} text={c('l4', { growthRate: p(interestRate) })} />
          <Bullet {...bulletStyles} text={c('l5')} />
          <Bullet {...bulletStyles} text={c('l6', { deathAge })} />
          <Bullet {...bulletStyles} text={c('l7', { inflationRate: p(inflation, { whole: 1 }) })} />
          <Bullet {...bulletStyles} text={c('disclaimer')} />
        </Stack>
      </Section>
    </GuideBlueprint>
  );
};

export default RetirementProjectionGuideSheet;
