import React, { useMemo } from 'react';
import {
  useMutation,
  mutations,
  updates,
  VerifyAliasError,
  VerifyPhoneResponse,
  VerifyPhoneVars,
  VerifyEmailResponse,
  VerifyEmailVars,
} from '@data';
import { OnboardingFormBlueprint } from '@blueprints';
import { routes } from '@navigate';
import { FormConfig } from '@app/forms';
import { fields } from '@app/config';

interface AccountSetupCodeProps {
  loading: boolean;
  aliasType: 'phone' | 'email';
  isPhoneVerified: boolean;
  requestedPhone: string;
  phoneNumber: string;
  isEmailVerified: boolean;
  requestedEmail: string;
  email: string;
  handleNext: () => void;
}

const messages: Record<VerifyAliasError, string> = {
  VERIFY_ALIAS_ERROR_INVALID_MFA: 'The code is incorrect',
  VERIFY_ALIAS_ERROR_NO_ACTIVE_CHANGE: 'Try resending the code',
  VERIFY_ALIAS_ERROR_REQUEST_EXPIRED: 'Code has expired, try resending',
  VERIFY_ALIAS_ERROR_REQUEST_NO_MORE_RETRIES: "You've tried too many times",
  VERIFY_ALIAS_ERROR_COGNITO: 'Something went wrong',
};

const AccountSetupCode: React.FC<AccountSetupCodeProps> = ({
  loading,
  aliasType,
  isPhoneVerified,
  requestedPhone = '',
  phoneNumber,
  isEmailVerified,
  requestedEmail = '',
  email,
  slug,
  handleNext,
}) => {
  const [verifyPhone, { loading: submittingPhone }] = useMutation<
    VerifyPhoneResponse,
    VerifyPhoneVars
  >(mutations.VERIFY_PHONE, { update: updates.VERIFY_PHONE });

  const [verifyEmail, { loading: submittingEmail }] = useMutation<
    VerifyEmailResponse,
    VerifyEmailVars
  >(mutations.VERIFY_EMAIL, { update: updates.VERIFY_EMAIL });

  const alreadyVerifiedAlias = useMemo(() => {
    if (aliasType === 'phone') {
      return isPhoneVerified && requestedPhone === phoneNumber;
    }

    if (aliasType === 'email') {
      return isEmailVerified && requestedEmail === email;
    }
    return false;
  }, [
    aliasType,
    isPhoneVerified,
    requestedPhone,
    phoneNumber,
    isEmailVerified,
    requestedEmail,
    email,
  ]);

  const requested = useMemo(() => {
    if (aliasType === 'phone') {
      return `******${requestedPhone.slice(-4)}`;
    }

    if (aliasType === 'email') {
      return requestedEmail;
    }

    return '';
  }, [aliasType, requestedPhone, requestedEmail]);

  const formConfig: FormConfig<{ confirmationCode: string }> = {
    initialValues: { confirmationCode: '' },
    fields: [
      {
        ...fields.CONFIRMATION_CODE,
        type: 'code',
        alias: requestedPhone || requestedEmail,
      },
    ],
    onSubmit: async (values, methods) => {
      if (alreadyVerifiedAlias) {
        handleNext({ isHealth: slug === 'health' });
      }

      let err: VerifyAliasError | undefined;

      if (aliasType === 'phone') {
        const result = await verifyPhone({
          variables: { confirmationCode: values.confirmationCode },
        });

        err = result.data?.verifyPhone?.error;
      }

      if (aliasType === 'email') {
        const result = await verifyEmail({
          variables: { confirmationCode: values.confirmationCode },
        });

        err = result.data?.verifyEmail?.error;
      }

      if (err) {
        methods.setError('confirmationCode', {
          type: 'custom',
          message: messages[err],
        });
      } else {
        handleNext({ isHealth: slug === 'health' });
      }
    },
  };

  return (
    <OnboardingFormBlueprint
      loading={loading}
      disabled={submittingPhone || submittingEmail}
      title={`Enter the code we sent to ${requested}`}
      formConfig={formConfig}
    />
  );
};

export const AccountSetupCodeView = {
  name: routes.ACCOUNT_SETUP_CODE,
  component: AccountSetupCode,
  options: {},
};
