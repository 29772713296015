import React from 'react';
import { ComplexRow, Asset, SimpleRow } from '@uikit';
import { formatBank, formatBenefit, useCopy } from '@app/utils';
import { GoalType } from '@app/types';

interface Goal {
  id: string;
  slug: string;
  goalType: GoalType;
  availableBalance: number;
}

interface Account {
  id: string;
  logo: string;
  bankName: string;
  accountName: string;
  accountNumber: string;
}

interface TransferRowProps {
  transferType: 'withdraw' | 'deposit';
  item: Goal | Account;
  onPress: () => void;
  display: boolean;
  selected: boolean;
  disabled: boolean;
}

const TransferRow: React.FC<TransferRowProps> = ({
  transferType,
  item,
  onPress,
  display,
  disabled,
}) => {
  const { $ } = useCopy('catch');
  if (!item) {
    return (
      <SimpleRow
        testID="select"
        inset
        asset={Asset.configureBank()}
        label={transferType === 'deposit' ? 'Choose a source' : 'Choose a destination'}
        bg="skeleton"
        onPress={onPress}
        accessory="arrow"
        disabled={disabled}
      />
    );
  }

  const configs = {
    bank: {
      label: formatBank.detail(item),
      sublabel: formatBank.name(item),
      asset: Asset.configureBank(item?.logo),
    },
    goal: {
      label: formatBenefit.name(item),
      sublabel: `${$(item?.availableBalance)} available`,
      asset: Asset.configureBenefit(item?.goalType),
    },
  };

  const config = item.__typename === 'Account' ? configs.bank : configs.goal;

  return (
    <ComplexRow
      testID={item.id}
      inset
      bg={display && 'skeleton'}
      onPress={onPress}
      accessory="arrow"
      disabled={disabled}
      label={config.label}
      sublabel={config.sublabel}
      asset={config.asset}
    />
  );
};

export default TransferRow;
