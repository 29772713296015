import React, { useCallback, useMemo } from 'react';
import { Field, Form } from 'react-final-form';

import { close, navigate, routes, ScreenDefinition } from '@navigate';
import {
  mutations,
  PayrollQueryData,
  queries,
  UpsertGoalResponse,
  UpsertGoalVars,
  useDeprecatedQuery,
  useMutation,
  useQuery,
} from '@data';
import { GoalSlug } from '@types';
import { deslug, getContributionsFromPercentage, precisionRound, useCopy } from '@app/utils';
import { Button } from '@uikit';
import { Layout, Toolbar } from '@layouts';
import { ResultCard } from '@common';

interface Props {
  slug: GoalSlug;
  creation?: boolean;
}

/**
 * Renders an edit screen for changing paycheck percentage
 *
 * If you're looking for the Tax witholding view (the one with federal & state percentages) use the Estimator component
 */
const GoalEdit = ({ slug, creation = false }: Props) => {
  const { goalType, estimatedIncome, workType, goal, waiting } = useDeprecatedQuery('goalSetup', {
    variables: { slug },
  });
  const { c } = useCopy('catch.plans.EstimatorView');
  const { c: basics } = useCopy('catch.basics');

  const { monthly } = getContributionsFromPercentage({
    percentage: goal?.paycheckPercentage,
    income: estimatedIncome,
  });

  const [upsertGoal, { loading: upserting }] = useMutation<UpsertGoalResponse, UpsertGoalVars>(
    mutations.UPSERT_GOAL,
    {
      refetchQueries: ['Goal'],
    },
  );

  const onSubmit = useCallback(
    async (values) => {
      const { data } = await upsertGoal({
        variables: {
          input: {
            slug: slug,
            paycheckPercentage: values?.paycheckPercentage,
          },
        },
      });
      close({ closeSheets: true });
      if (creation && data) {
        navigate(
          data.upsertGoal.status === 'NOT_STARTED' ? routes.GOAL_CONFIRM : routes.PLAN_DETAIL,
          {
            slug: data.upsertGoal.slug,
          },
        );
      }
    },
    [goal, slug],
  );

  const { data: payrollData } = useQuery<PayrollQueryData>(queries.PAYROLL, {
    variables: { slug },
    fetchPolicy: 'cache-first', // @todo: this should really be the default regardless
  });

  //keep track of total percentage across goals so we don't upsert over 100%
  //ideally we'd keep track of this as a local field in the cache
  const totalPaycheckPercentage = useMemo(
    () =>
      !!payrollData?.viewer?.goals
        ? precisionRound(
            payrollData?.viewer?.goals?.reduce((acc, goal) => {
              // only include ready goals, dont include the one we're adjusting atm
              return /ACTIVE|PAUSED|GOAL_MET/.test(goal.status) && goal.slug !== slug
                ? acc + goal?.paycheckPercentage
                : acc;
            }, 0),
            2,
          )
        : 0,
    [payrollData?.viewer.goals],
  );

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        paycheckPercentage: goal?.paycheckPercentage,
        totalPaycheckPercentage,
      }}
    >
      {({ handleSubmit, valid }) => (
        <>
          {/* @ts-ignore */}
          <Layout.Scroll margins topSpace color="sheet">
            <Field
              name="paycheckPercentage"
              // @ts-ignore
              component={ResultCard}
              isLoading={waiting}
              percent={goal?.paycheckPercentage}
              percentLegend={c('ResultCard.percentLegend')}
              estimatedIncome={estimatedIncome}
              monthlyAmount={monthly}
              description={c(
                workType === 'WORK_TYPE_DIVERSIFIED' || goalType === 'TAXES'
                  ? 'ResultCard.description.1099'
                  : 'ResultCard.description',
                {
                  estimatedIncome: estimatedIncome,
                  monthlyPayment: monthly,
                  annualIncome: estimatedIncome,
                },
              )}
              isEditing
              goalID={goal?.id}
              noOverflow
              noBorder
            />
          </Layout.Scroll>
          {/* @ts-ignore */}
          <Toolbar type="update">
            <Button
              inherit
              onPress={handleSubmit}
              testID="update-btn"
              loading={upserting}
              disabled={upserting}
            >
              {basics('save')}
            </Button>
          </Toolbar>
        </>
      )}
    </Form>
  );
};

/**
 * @todo E-3287
 */
export const GoalEditView: ScreenDefinition = {
  name: routes.GOAL_EDIT,
  component: GoalEdit,
  options: {
    title: ({ slug, creation }) => {
      if (creation) {
        return `Withholding for ${deslug(slug)}`;
      }
      return `Edit ${deslug(slug)}`;
    },
    layout: 'sheet',
  },
};
