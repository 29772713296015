import React, { useState, useMemo } from 'react';
import { View } from 'react-native';
import { SheetBlueprint } from '@app/blueprints';
import { Stack, useLayoutContext } from '@app/layouts';
import { useHeaderHeight } from '@app/layouts/useHeaderHeight';
import { Link } from './Link';
import { Asset } from './Asset';
import { Text } from './Text';
import { ActionSheet } from './ActionSheet';
import Information from '../icons/svg/information.svg';
import { useTheme } from '../hooks/useTheme';

interface HelpTextProps {
  content: string | React.ReactNode[];
  offset?: number;
}

export const HelpTextInline: React.FC<HelpTextProps> = ({ content, offset }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { themeColors } = useTheme();
  const { headerHeight } = useHeaderHeight();
  const { accentColor } = useLayoutContext();

  // this was originally going to be a way to split the content
  // however, when we pass Copy, it's difficult to do string calculations on it :/
  const { initialContent, hasMore } = useMemo(() => {
    return {
      initialContent: content,
      hasMore: false,
    };
  }, [content]);

  if (!initialContent) return null;

  return (
    <>
      <View
        style={{
          borderLeftColor: themeColors[`${accentColor}Color`],
          borderLeftWidth: 2,
          paddingLeft: 24,
          marginTop: offset ? offset - headerHeight : 0,
          marginBottom: offset ? offset - headerHeight : 0,
        }}
      >
        <Stack spacing="1">
          <Text size="fp" color="subtle">
            {initialContent}
          </Text>
          {hasMore && (
            <Link style={{ textDecoration: 'underline' }} onPress={() => setIsOpen(true)}>
              More info
            </Link>
          )}
        </Stack>
      </View>
      <ActionSheet
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        accentColor={accentColor}
      >
        <SheetBlueprint>
          <Text>{content}</Text>
        </SheetBlueprint>
      </ActionSheet>
    </>
  );
};

export const HelpText: React.FC<HelpTextProps> = ({ content }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { accentColor } = useLayoutContext();

  return (
    <>
      <Asset svg={Information} onPress={() => setIsOpen(true)} color={accentColor} size="xs" />
      <ActionSheet
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        accentColor={accentColor}
      >
        <SheetBlueprint>
          <Text>{content}</Text>
        </SheetBlueprint>
      </ActionSheet>
    </>
  );
};

export default HelpText;
