import gql from 'graphql-tag';

export interface DeleteBankLinkVars {
  bankLinkID: string;
}

export interface DeleteBankLinkResponse {
  // returns the id of the deleted bank link
  deleteBankLink: string;
}

export const DeleteBankLink = gql`
  mutation DeleteBankLink($bankLinkID: ID!) {
    deleteBankLink(input: { id: $bankLinkID })
  }
`;
