import { open } from '@navigate';
import { useTaxPaymentPeriod } from './useTaxPaymentPeriod';

export const useLaunchTaxPayments = (type) => {
  const { quarterNum, taxYear, dueDate, showReminder, handleDismiss, allowTaxPayment } =
    useTaxPaymentPeriod(type);

  const handleOpen = () => {
    open('TAX_PAYMENT_INTRO', {
      quarterNum,
      taxYear,
    });
  };

  return {
    isEnabled: true,
    allowTaxPayment,
    quarterNum,
    dueDate,
    showReminder: showReminder,
    handleDismiss,
    open: handleOpen,
  };
};
