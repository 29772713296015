import gql from 'graphql-tag';
import { ExistingApplicationInfo, ExistingApplicationsResponse } from '@types';

export const EdeApplicationSearch = gql`
  mutation EdeApplicationSearch {
    edeApplicationSearch {
      hasActivePolicy
      hasPreviousActivePolicy
      hasSubmittedApplication
      canIDProof
      idProofOk
      importOk
      existingApplications {
        id
        familyName
        givenName
        coverageYearNumber
        state
        applicationIdentifier
        applicationVersion
        providerLastUpdatedDate
        applicationStatus
        hasExistingPersonTrackingNumbersTiedToApplication
      }
    }
  }
`;
