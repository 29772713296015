import { isBefore } from 'date-fns';
import { optionPresets } from '@app/forms';
import { Env, getFullName, sixtyFiveYearsAgo, toDate } from '@app/utils';

const isValidDate = (date) => {
  const d = new Date(date);
  return /^\d{4}-\d{2}-\d{2}$/.test(date) && !isNaN(d.getTime());
};

export const fields = [
  {
    testID: '10a',
    copy: 'catch.ede.HealthEnrollment',
    name: 'writtenLanguageType',
    dependencies: ['isGA'],
    type: 'dropdown',
    options: optionPresets.LANGUAGES,
    required: true,
    label: { id: '10a_Q' },
    help: ({ isGA }) => ({ id: `10a_T${isGA ? '_GA' : ''}` }),
  },
  {
    testID: '10b',
    copy: 'catch.ede.HealthEnrollment',
    name: 'spokenLanguageType',
    type: 'dropdown',
    options: optionPresets.LANGUAGES,
    required: true,
    label: { id: '10b_Q' },
  },
  {
    testID: '27',
    copy: 'catch.ede',
    name: 'isRequestingCoverage',
    type: 'option',
    options: optionPresets.YES_NO_TRINARY,
    required: true,
    label: { id: 'HealthEnrollment.27_Q' },
    help: { id: 'HealthEnrollment.27_T' },
  },
  {
    copy: 'catch.ede.HealthEnrollment',
    name: 'name',
    type: 'legalName',
    required: true,
    label: { id: '4_Q' },
    subfields: {
      givenName: true,
      middleName: true,
      familyName: true,
      nameSuffix: true,
    },
  },
  {
    testID: '14',
    copy: 'catch.ede.HealthEnrollment',
    name: 'phone',
    type: 'phone',
    required: true,
    prefix: true,
    label: { id: '14_Q' },
    subfields: {
      number: true,
      ext: true,
      type: true,
    },
  },
  {
    testID: '14a',
    copy: 'catch.ede.HealthEnrollment',
    name: 'secondaryPhone',
    type: 'phone',
    required: false,
    prefix: true,
    label: { id: '14a_Q' },
    subfields: {
      number: true,
      ext: true,
      type: true,
    },
  },
  {
    copy: 'catch.fields.textOptIn',
    name: 'textOptIn',
    type: 'checkbox',
    title: { id: 'label' },
    label: { id: 'disclaimer' },
  },
  {
    testID: '9',
    copy: 'catch.ede.HealthEnrollment',
    name: 'email',
    type: 'email',
    required: true,
    label: { id: '9_L' },
    validate: (value) => {
      if (
        !/^(?![._-])(?!.*[._-]{2,})([a-zA-Z0-9]+[._-]?)*[a-zA-Z0-9]+@[a-zA-Z0-9]+([.-]?[a-zA-Z0-9]+)*\.[a-zA-Z]{2,}$/.test(
          value,
        )
      ) {
        return Env.isProd
          ? 'Emails can only contain numbers, letters, and . _ or -'
          : 'Emails can only contain numbers, letters, and . _ or -. (dev only msg: this was a rule implemented by CMS in SES R37.1 which ignored guidance on best practices)';
      }
    },
  },
  {
    testID: '11',
    copy: 'catch.ede.HealthEnrollment',
    name: 'contactMethod',
    type: 'option',
    options: [
      { label: 'Digital', value: 'EMAIL' },
      { label: 'Paper', value: 'MAIL' },
    ],
    required: true,
    label: { id: '11_Q' },
    help: { id: '11_T' },
  },
  {
    testID: '12',
    copy: 'catch.ede.HealthEnrollment',
    name: 'digitalContactMethod',
    type: 'option',
    dependencies: ['isGA'],
    options: ({ isGA }) => isGA ? [
      { label: 'Email', value: 'EMAIL' },
    ] : [
      { label: 'Email', value: 'EMAIL' },
      { label: 'Text', value: 'TEXT' },
    ],
    required: true,
    label: { id: '12_Q' },
    help: { id: '12_T' },
  },
  {
    testID: '5',
    copy: 'catch.ede.HealthEnrollment',
    name: 'homeAddress',
    type: 'address',
    required: true,
    label: { id: '5_Q' },
    help: { id: '5_T' },
    dependencies: ['coverageState', 'homeAddress'],
    disabledFields: ({ coverageState, homeAddress }) => {
      return {
        state: coverageState && coverageState === homeAddress.state,
      };
    },
    fieldAddition: ({ coverageState, homeAddress }) => {
      if (coverageState && coverageState === homeAddress.state) {
        return {
          component: 'TEXT',
          props: { copy: { id: 'ede.HealthEnrollment.5_warning', data: { state: coverageState } } },
        };
      }
    },
  },
  {
    testID: '7',
    copy: 'catch.ede',
    name: 'mailingAddressOption',
    type: 'option',
    required: true,
    allowFalseValues: true,
    options: [
      { label: 'Same as home address', value: true },
      { label: 'Different address', value: false },
    ],
    label: { id: 'HealthEnrollment.7_Q' },
    help: { id: 'HealthEnrollment.7_T' },
  },
  {
    testID: '8',
    copy: 'catch.ede.HealthEnrollment',
    name: 'mailingAddress',
    type: 'address',
    required: true, // required when showing
    dependencies: ['mailingAddressOption'],
    display: (values) => values.mailingAddressOption === false,
    allowPOBox: true,
    label: { id: '8_Q' },
    help: { id: '8_T' },
  },
  {
    copy: 'catch.ede.HealthEnrollment',
    name: 'dob',
    type: 'date',
    dob: true,
    required: true,
    label: { id: '33_L' },
    format: 'YYYY-MM-DD',
    dependencies: ['dob', 'name'],
    fieldAddition: ({ dob, name }) => {
      if (isValidDate(dob) && isBefore(toDate(dob), sixtyFiveYearsAgo())) {
        return { component: 'APPLICANT_AGE_WARNING', props: name };
      }
    },
  },
  {
    testID: '31',
    copy: 'catch.ede.HealthEnrollment',
    name: 'sex',
    type: 'dropdown',
    required: true,
    label: { id: '31_Q' },
    options: [
      { label: 'Female', value: 'FEMALE' },
      { label: 'Male', value: 'MALE' },
    ],
  },
  {
    testID: '32',
    copy: 'catch.ede.HealthEnrollment',
    name: 'ssn',
    type: 'ssn',
    dependencies: ['isRequestingCoverage', 'applicationPhase'],
    display: (values) => values.isRequestingCoverage === 'YES',
    required: ({ applicationPhase }) => (applicationPhase === 'TWO' ? false : true),
    label: { id: '32_L' },
    help: { id: '32_T' },
    editable: true,
  },
  {
    testID: '32_SKIP',
    copy: 'catch.ede.HealthEnrollment',
    name: 'confirmMissingSSN',
    type: 'checkbox',
    dependencies: ['isRequestingCoverage', 'applicationPhase', 'ssn', 'name'],
    display: ({ ssn, applicationPhase, isRequestingCoverage }) =>
      applicationPhase === 'TWO' && !ssn && isRequestingCoverage === 'YES',
    label: (values) => ({
      id: '32_CONFIRM',
      data: { fullName: getFullName(values?.name, 'This person') },
    }),
    required: ({ ssn }) => !ssn,
  },
];
