import React from 'react';

import { useCopy, handleHealthResponse } from '@app/utils';
import { HealthApplicationQueryData, mutations, queries, useMutation, useQuery } from '@data';
import { useModal, useTextOptIn } from '@hooks';
import { navigate, routes } from '@navigate';
import { DetailConfirmationModal } from '@common';
import { SplitFormBlueprint } from '@app/blueprints';
import { fields } from './idInfoFields';
import { getInitialValues, formatPayload } from './idInfoUtils';
import { useExistingApps } from '@app/hooks/useExistingApps';
import { logGQLError } from '@app/utils/logger';

const IdInfo = ({ isGA, applicationID, applicantID, enrollId }) => {
  const { isOpen, openModal, closeModal } = useModal();
  const { c } = useCopy('catch.ede');

  const { loading, data } = useQuery<HealthApplicationQueryData>(queries.HEALTH_APPLICATION, {
    variables: { applicationID },
    skip: !applicationID,
  });

  const { checking, checkForExistingApplications } = useExistingApps(applicationID);

  const [upsert, { loading: upserting }] = useMutation(
    mutations.UPSERT_APPLICATION_MEMBERS,
    handleHealthResponse({}),
  );

  const { update } = useTextOptIn();

  const handleContinue = () => {
    if (data?.viewerTwo?.identity?.status !== 'VERIFIED') {
      navigate(routes.EDE_IDENTITY);
    } else {
      // check if applications exist
      checkForExistingApplications();
    }
  };

  const onSubmit = async (values) => {
    if (values?.textOptIn === true) {
      update();
    }

    if (values?.id) {
      const payload = formatPayload({ applicationID, values });
      const hasSSN = !!values?.ssn;

      if (!hasSSN) {
        openModal();
        upsert(payload); // upsert, but don't wait to open the modal
        return;
      } else {
        await upsert(payload);
        handleContinue();
      }
    } else {
      logGQLError(
        { message: 'No ID for Health User' },
        { operationName: 'UpsertApplication' },
        {
          tags: { product: 'health', feature: 'enrollment' },
        },
      );
      alert('no id for this user is present, ask for technical assistance');
    }
  };

  return (
    <>
      <SplitFormBlueprint
        loading={!data}
        submitting={upserting || checking}
        title={c('IdInfo.title')}
        subtitle={c('IdInfo.subtitle')}
        formConfig={{
          initialValues: getInitialValues(data, isGA),
          fields,
          onSubmit: (values) => onSubmit(values),
        }}
      />
      <DetailConfirmationModal
        isOpen={isOpen}
        closeModal={closeModal}
        loading={loading || upserting}
        handleContinue={handleContinue}
        title={c('noSsnWarning.title')}
        subtitle={c('noSsnWarning.subtitle')}
      />
    </>
  );
};

export const IdInfoView = {
  name: routes.EDE_ID_INFO,
  component: IdInfo,
  options: {
    ...SplitFormBlueprint.options,
    title: 'Contact info',
  },
};
