import React, { useState, useEffect, useMemo, useCallback } from 'react';

import { routes } from '@navigate';
import { OnboardingFormBlueprint } from '@blueprints';
import { useQuery, queries, RetirementQueryData } from '@data';
import { FormConfig } from '@app/forms';
import { fields } from '@app/config';
import { useCopy, calculatePortfolioLevel, findRecommendedPortfolio } from '@app/utils';
import { RetirementPortfolioSheet } from '@sheets';

interface RetirementPortfolioValues {
  name: string;
  portfolioID: string;
}

export const RetirementPortfolioSelection = ({
  handleNext,
  updateRetirement,
  submitting,
  minimal,
}) => {
  const { c } = useCopy('catch.module.retirement.PortfolioSelectionView');

  const [selectedPortfolio, setSelectedPortfolio] = useState<any>();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { loading, data } = useQuery<RetirementQueryData>(queries.RETIREMENT, {
    fetchPolicy: 'cache-first',
  });

  const portfolioName = useMemo(() => data?.viewer?.goal?.portfolio?.name, [data]);
  const getPortfolio = useCallback(
    (name: string) => data?.reference?.wealth?.portfolios?.find((p) => p.name === name),
    [data],
  );

  useEffect(() => {
    if (!loading && !!portfolioName) setSelectedPortfolio(portfolioName);
  }, [loading, portfolioName]);

  const handleSelect = useCallback(
    (portfolioName: string) => {
      if (minimal) {
        const portfolio = getPortfolio(portfolioName);
        updateRetirement({ portfolioID: portfolio.id });
        handleNext();
      } else {
        const portfolio = getPortfolio(portfolioName);
        setSelectedPortfolio(portfolio);
        setIsOpen(true);
      }
    },
    [getPortfolio],
  );

  const handleSubmit = useCallback(
    (portfolioName: string) => {
      const portfolio = getPortfolio(portfolioName);
      updateRetirement({ portfolioID: portfolio.id });
      handleNext();

      setTimeout(function () {
        setIsOpen(false);
      }, 200);
    },
    [handleNext],
  );

  const preselectedPortfolio = useMemo(
    () =>
      findRecommendedPortfolio({
        type: calculatePortfolioLevel({
          age: data?.viewer?.user?.age,
          riskComfort: data?.viewer?.goal?.riskComfort,
          riskLevel: data?.viewer?.goal?.riskLevel,
        }),
        portfolios: data?.reference?.wealth?.portfolios,
      }),
    [data],
  );

  const formConfig: FormConfig<RetirementPortfolioValues> = {
    initialValues: { name: portfolioName || preselectedPortfolio.name || '' },
    autoSubmit: false,
    fields: [
      {
        ...fields.RETIREMENT_PORTFOLIOS,
        onPress: (name) => {
          handleSelect(name);
        },
      },
    ],
    onSubmit: () => {},
  };

  return (
    <OnboardingFormBlueprint
      loading={loading}
      disabled={submitting}
      title={c('selectionTitle')}
      subtitles={[c('selectionSubtitle'), c('esgCallout.description')]}
      formConfig={formConfig}
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
      hideBack={minimal}
      modalContent={
        <RetirementPortfolioSheet
          portfolio={selectedPortfolio}
          onSubmit={() => handleSubmit(selectedPortfolio?.name)}
        />
      }
    />
  );
};

export const RetirementPortfolioSelectionView = {
  name: routes.RETIREMENT_PORTFOLIO_SELECTION,
  component: RetirementPortfolioSelection,
  options: {},
};
