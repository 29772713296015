import React, { useMemo } from 'react';
import {
  useQuery,
  queries,
  HealthExplorerQueryData,
  useMutation,
  mutations,
  UpsertExplorerApplicantsResponse,
  UpsertExplorerApplicantsVars,
  updates,
} from '@data';
import { pop, routes, ScreenDefinition } from '@navigate';
import { BasicFormBlueprint } from '@app/blueprints';
import { useCopy } from '@app/utils';
import { fields } from './explorerApplicantsFields';

const ExplorerApplicants = ({ handleNext }) => {
  const { c } = useCopy('catch.healthExplorer.explorerApplicants');

  const { data } = useQuery<HealthExplorerQueryData>(queries.HEALTH_EXPLORER);

  const initialValues = useMemo(() => {
    const dependents = data?.viewerTwo?.healthExplorerData?.dependents || [];
    const self = { relation: 'SELF', age: data?.viewer?.user?.age || undefined };
    return { applicants: dependents.length > 0 ? dependents : [self] };
  }, [data]);

  const [upsert, { loading: upserting }] = useMutation<
    UpsertExplorerApplicantsResponse,
    UpsertExplorerApplicantsVars
  >(mutations.UPSERT_EXPLORER_APPLICANTS, {
    update: updates.UPSERT_EXPLORER_APPLICANTS,
  });

  const onSubmit = (values) => {
    upsert({
      variables: {
        applicants: values.applicants.map((applicant) => ({
          id: applicant.id,
          relation: applicant.relation,
          age: applicant.age,
          isSmoker: !!applicant.isSmoker,
          isApplying: true,
        })),
      },
    });

    handleNext();
  };

  return (
    <BasicFormBlueprint
      loading={!data}
      submitting={upserting}
      title={c('title')}
      onBack={pop}
      formConfig={{
        initialValues,
        fields,
        onSubmit,
      }}
    />
  );
};

export const ExplorerApplicantsView: ScreenDefinition = {
  name: routes.EXPLORER_APPLICANTS,
  component: ExplorerApplicants,
  options: {},
};
