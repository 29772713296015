export const defaults = {
  percents: {
    TAX: 0.2,
    PTO: 0.03,
    RETIREMENT: 0.02,
    FAMILY_LEAVE: 0.01,
  },
  limits: {
    TAX_PAYMENT_MIN: 10,
  },
};
