import { prefixes } from '@navigate';
import { Route, paths } from '../routes';
import { StackName } from '../stacks';

/**
 * Given a route, gets the path name format we prefer
 * @todo: we can provide a stack as well and we'll strip out the prefix too!
 */
export const normalizePath = ({ route, stack }: { route?: Route; stack?: StackName }) => {
  const path = route ? paths[route] : stack ? prefixes[stack] : '';

  // home is a special case -- should match the empty string
  return path === '/' ? '' : `${path.slice(1, path.length)}/*`;
};

/**
 * Helper to strip the stack prefix from a url so we can have relative paths
 */
export const stripPrefix = (stackName: StackName, route: Route) => {
  const prefix = prefixes[stackName];
  const url = paths[route];
  return url.replace(`${prefix}`, '');
};
