import React from 'react';

import { Text } from '@uikit';
import { useCopy } from '@app/utils';

const MonthlyContribution = ({
  yearly,
  align,
  yearlyContribution,
  monthlyContribution,
  onToggle,
  estimatedIncome,
  use1099Income = false,
  editIncomeEnabled = true,
}) => {
  const { c, $ } = useCopy('catch.context.MonthlyContribution');

  const size = 'fp';

  /** @todo maybe prompt the user to add it? */
  if (!estimatedIncome)
    return (
      <Text size={size} align={align} color="subtle">
        <Text tabular key="income" size={size} color="link" weight="medium" onPress={onToggle}>
          Add your estimated income
        </Text>{' '}
        for a more accurate&nbsp;breakdown
      </Text>
    );

  return (
    <Text size={size} align={align} color="subtle">
      {c(yearly ? 'descriptionYearly' : use1099Income ? 'description1099' : 'description', {
        yearlyContribution: (
          <Text key="yearly" qaName="yearlyContribution" size={size} tabular>
            {$(yearlyContribution, { whole: true })}
          </Text>
        ),
        monthlyContribution: (
          <Text key="monthly" qaName="monthlyPayment" size={size} tabular>
            {$(monthlyContribution, { whole: true })}
          </Text>
        ),
        estimatedIncome: editIncomeEnabled ? (
          <Text tabular key="income" size={size} weight="semibold" color="text" onPress={onToggle}>
            {$(estimatedIncome, { whole: true })}
          </Text>
        ) : (
          <Text key="income" size={size} tabular>
            {$(estimatedIncome, { whole: true })}
          </Text>
        ),
      })}
    </Text>
  );
};

export default MonthlyContribution;
