import gql from 'graphql-tag';

export const incomeStats = gql`
  fragment IncomeStats on PlanHistorySummary {
    grossIncome
    grossIncomeW2
    grossIncome1099
    totalSavings
    totalSavingsAll
  }
`;
