import { useDeprecatedMutation } from '@data';

export const useTaxPayment = ({
  amount,
  quarter,
  taxYear,
  onCompleted,
  goalID,
  jurisdiction = 'US',
}) => {
  const [makeTaxPayment, { loading, called, error, data }] = useDeprecatedMutation(
    'makeTaxPayment',
    {
      onCompleted,
      goalID,
    },
  );

  const sendPayment = ({ signatureText }) => {
    makeTaxPayment({
      variables: {
        payment: {
          jurisdiction,
          filingPeriod: quarter,
          isActual: false,
          taxYear,
          amount,
          signatureText,
        },
      },
    });
  };

  return {
    sendPayment,
    loading,
    called,
    error:
      error ||
      (data?.makeTaxPayment?.error && data?.makeTaxPayment?.error !== 'MAKE_TAX_PAYMENT_NO_ERROR'),
    data,
  };
};
