import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Env } from '@app/utils';
import { useTheme } from '@uikit/hooks/useTheme';
import { Text } from '@uikit/components/Text';
import { Tooltip } from '@uikit/components/Tooltip';
import Info from '@uikit/icons/Info';
import { useLayoutContext } from '@app/layouts/LayoutContext';
import { CatchTheme } from '@app/_ui-kit';

const Req = () => (
  <Text weight="bold" color="credit" gutter="left">
    •
  </Text>
);

/**
 * @todo this needs to be MUCH clearer
 */
const FieldLegend = ({
  label,
  sublabel,
  extralabel,
  labelID,
  sublabelID,
  required,
  fieldID,
  tooltip,
  fullWidth,
  centerLabel,
  startAlignTooltip,
}) => {
  const { theme, themeColors } = useTheme();
  const { accentColor } = useLayoutContext();

  const tooltipLength =
    typeof tooltip === 'string'
      ? tooltip.replace(/\s/g, '').length
      : Array.isArray(tooltip)
      ? tooltip
          .filter((char) => typeof char === 'string')
          .join()
          .replace(/\s/g, '').length
      : 301;

  // If tooltip is over 240 characters we render in an info sheet
  const isInfoSheet = tooltipLength > 300;

  // this currently allows the tooltip to render on components with no label such as legal checkbox components
  const showExtra = sublabel || (!label && tooltip);

  return (
    <View
      style={[
        theme.fieldLegend,
        theme.fullWidth,
        !theme.fullWidth && theme.pMax,
        theme.bottomGutter0b,
      ]}
    >
      <View
        style={[
          theme.row,
          fullWidth && theme.fullWidth,
          !startAlignTooltip && theme.spaceBetween,
          centerLabel && styles.centerRow,
        ]}
      >
        <View style={[theme.fullWidth, theme.row, theme.flex, theme.flexRow]}>
          <Text
            accessibilityRole={!Env.isNative && fieldID ? 'label' : undefined}
            nativeID={labelID}
            htmlFor={fieldID}
            weight="medium"
            size="fp"
            fluid
            inline
          >
            {label}
            {required && !sublabel && <Req />}
          </Text>
          {label && tooltip && (
            <Tooltip
              title={label}
              content={tooltip}
              direction="top"
              type={isInfoSheet ? 'sheet' : 'popover'}
            >
              <Info color={themeColors[`${accentColor}Color`]} />
            </Tooltip>
          )}
          <View>{extralabel && <View style={theme.extralabel}>{extralabel}</View>}</View>
        </View>
      </View>
      {showExtra && (
        <View style={[theme.fullWidth, theme.row, theme.flex, theme.flexRow, theme.bottomSpace2]}>
          <Text nativeID={sublabelID} fluid inline weight="medium" size="fp">
            {sublabel}
            {required && !!sublabel && <Req />}
          </Text>
          {!label && extralabel && <View>{extralabel}</View>}
          {!label && tooltip && (
            <Tooltip
              title={label}
              content={tooltip}
              direction="top"
              type={isInfoSheet ? 'sheet' : 'popover'}
            >
              <Info
                color={themeColors[`${accentColor}Color`]}
                style={[theme.bottomGutter0b, theme.leftGutter1]}
              />
            </Tooltip>
          )}
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  centerRow: {
    justifyContent: 'center',
  },
});

export default FieldLegend;
