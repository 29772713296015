import React, { useMemo } from 'react';
import shallow from 'zustand/shallow';
import { Linking } from 'react-native';
import { open, useSheet, sheets, routes, SheetComponentProps } from '@navigate';
import { useDeprecatedMutation, useQuery, queries, PolicyQueryData, getMostRecentEDN } from '@data';
import { SheetBlueprint } from '@blueprints';
import { Actions } from '@app/config';
import { capitalizeAll } from '@app/utils';

const CoverageDetailMenuSheet: React.FC<SheetComponentProps['COVERAGE_DETAIL_MENU']> = ({
  type,
  policyID,
}) => {
  const { openSheet } = useSheet((state) => ({ openSheet: state.open }), shallow);

  const { data } = useQuery<PolicyQueryData>(queries.COVERAGE_DETAIL, {
    variables: { policyID },
    skip: !policyID,
  });

  const { isPartner, pathwayType, dsrsIdentifier } = useMemo(() => {
    const app = data?.viewerTwo?.health?.policy?.application;

    return {
      applicationID: app?.id,
      pathwayType: app?.pathwayType,
      isPartner: /HEALTH_SHERPA|OSCAR/.test(app?.pathwayType || ''),
      dsrsIdentifier: getMostRecentEDN(app?.ednDocuments)?.dsrsIdentifier,
    };
  }, [data]);

  const [getDocument, { loading: gettingNotice }] = useDeprecatedMutation(
    'getGeneratedDocumentURL',
    {
      onCompleted: (data) => Linking.openURL(data.getGeneratedDocumentURL),
    },
  );

  const handleCoverageUpdate = () => {
    if (isPartner) {
      // either managed with hc.gov or healthsherpa
      openSheet(sheets.COVERAGE_MANAGED_OUTSIDE, {
        managedBy: pathwayType,
        reason: pathwayType,
      });
    } else {
      open(routes.CIC_MANAGE_COVERAGE_INTRO, { applicationID });
    }
  };

  return (
    <SheetBlueprint
      title={capitalizeAll(type)}
      actions={[
        {
          ...Actions.GET_EDN,
          onAction: () => getDocument({ variables: { input: { dsrsIdentifier } } }),
          disabled: gettingNotice,
        },
        {
          ...Actions.MANAGE_COVERAGE,
          onAction: handleCoverageUpdate,
        },
        Actions.REPORT_CHANGE,
        Actions.HELP,
      ]}
    />
  );
};

export default CoverageDetailMenuSheet;
