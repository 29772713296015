import React, { useMemo } from 'react';
import { useCopy } from '@app/utils';
import { getMembers, HealthApplicationQueryData, queries, useQuery } from '@data';
import { navigate, routes } from '@navigate';
import { BasicFormBlueprint } from '@app/blueprints';
import { fields } from './memberRemovalFields';

const PREFIX = 'catch.ede.CIC.memberCustody';

const CICMemberCustody = ({ applicationID }) => {
  const { c } = useCopy(PREFIX);

  const { loading, data } = useQuery<HealthApplicationQueryData>(queries.HEALTH_APPLICATION, {
    variables: { applicationID },
    skip: !applicationID,
  });

  const initialValues = useMemo(
    () => ({
      members: getMembers(data)
        ?.filter((m) => m?.relation === 'CHILD')
        ?.map((m) => ({
          id: m?.id,
          name: m?.legalName,
        })),
    }),
    [data],
  );

  const onSubmit = ({ members }) => {
    const keepAny = members.some((m) => m.removeMember === false);

    if (keepAny) {
      navigate(routes.CIC_GOV_HANDOFF, { members });
    } else {
      navigate(routes.CIC_REMOVING_MEMBERS, { members });
    }
  };

  return (
    <BasicFormBlueprint
      loading={loading}
      submitting={false}
      title={c('title')}
      formConfig={{
        initialValues,
        fields: fields(c('dateLabel')),
        onSubmit,
      }}
    />
  );
};

export const CICMemberCustodyView = {
  name: routes.CIC_MEMBER_CUSTODY,
  component: CICMemberCustody,
  options: {},
};
