import React, { useCallback, useState } from 'react';
import { ViewStyle } from 'react-native';
import { useLocation } from 'react-router-dom';

import { navigate, routes, WEB_TABS, checkActiveTab, open } from '@navigate';
import { Button, useResponsive, useTheme } from '@uikit';
import { useCopy, useLocale } from '@app/utils/language';
import { Segment, openIntercom } from '@app/utils';
import Env from '@app/utils/env';
import { NavBar, Stack } from '@layouts';
import { NavMode } from '@types';
import { AvatarMenu, NavMenu, DevMenu, NavLink, NavButton, Menu } from './components';
import { NEW_TAB_NAMES } from '@app/navigate/config/tabs';
import { useHealthStore } from '@app/client/useHealthStore';

import MenuIcon from '@svg/menu.svg';
import ActivityIcon from '@svg/clock.svg';
import ChatIcon from '@svg/annotation.svg';
import GlobeIcon from '@svg/globe.svg';

interface Props {
  title: string;
  navHex: string;
  isTransparent: boolean;
  navMode?: NavMode;
  canGoBack: boolean;
  style: ViewStyle;
}

/**
 * Renders the page nav
 * @todo make the nav and themeColor work from the native arguments
 */
const PageNav = ({ navHex, style, navMode }: Props) => {
  const { c } = useCopy('catch.basics');

  const { colorScheme } = useTheme();
  const [isOpen, setIsOpen] = useState();
  const { nextLanguage } = useLocale();
  const { pathname } = useLocation();
  const { shortRange } = useResponsive();
  const isMobile = shortRange !== 'l';
  const isHealthUrl = pathname?.includes('/health');
  const isHealthAppUrl = pathname?.includes('/health/application');
  const { context } = useHealthStore();

  const onSaveAndExit = useCallback(() => {
    Segment.track('EDE Save and Exit');
    navigate(routes.COVERAGE);
  }, [routes.COVERAGE]);

  return (
    <div className="nav">
      {/* @ts-ignore */}
      <NavBar
        style={[style]}
        // @ts-ignore
        onLogo={() => navigate(routes.HOME)}
        middle={
          isMobile
            ? null
            : WEB_TABS.map((tab) => (
                // @ts-ignore
                <NavLink
                  hide={!!navMode && navMode !== 'root'}
                  key={tab}
                  action={{ type: 'ROUTE', route: tab }}
                  active={checkActiveTab(tab, pathname)}
                  // @ts-ignore
                >
                  {NEW_TAB_NAMES[tab]}
                </NavLink>
              ))
        }
        right={
          <Stack horizontal spacing="1">
            <Stack horizontal spacing="0b">
              {/* Georgia Access logo */}
              {!!isHealthUrl && context === 'GA' && (
                <img
                  src={`https://s.catch.co/img/ga/ga${colorScheme === 'dark' ? '_invert' : ''}.png`}
                  style={styles.gaLogo}
                  alt="Georgia Access"
                />
              )}

              {!!isHealthAppUrl && !isMobile && (
                <Button testID="save-and-exit" onPress={onSaveAndExit} alt mini>
                  {c('saveAndExitButton')}
                </Button>
              )}

              {/* English/Spanish switch for health application */}
              {!!isHealthUrl && (
                <Menu icon={GlobeIcon}>
                  <NavLink action={{ type: 'TOGGLE_LANGUAGE' }} stacked>
                    {nextLanguage()}
                  </NavLink>
                </Menu>
              )}

              {/* @ts-ignore */}
              {Env.isDevLike && <DevMenu />}

              <NavButton icon={ActivityIcon} onPress={() => open('ACTIVITY')} />
              <NavButton icon={ChatIcon} onPress={() => openIntercom()} />
            </Stack>

            {!isMobile && <AvatarMenu />}
            {!!isMobile && (
              <NavButton icon={MenuIcon} onPress={() => setIsOpen(!isOpen)} isActive={isOpen} />
            )}
            {/* @ts-ignore */}
          </Stack>
        }
      />
      {isOpen && <NavMenu background={navHex} onClose={() => setIsOpen(false)} />}
    </div>
  );
};

const styles = {
  gaLogo: { height: 48, opacity: 0.5 },
};

export default PageNav;
