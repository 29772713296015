const PREFIX = 'catch.ede';

const qIsProfessionalAssistance = {
  copy: PREFIX,
  type: 'option',
  name: 'isProfessionalAssistance',
  // label: { id: 'HealthEnrollment.15_Q' },
  required: false,
  optional: true,
  options: [
    {
      label: { id: 'enums.PRO_ASSISTANCE_YES' },
      value: 'YES',
    },
    {
      label: { id: 'enums.PRO_ASSISTANCE_NO' },
      value: 'NO',
    },
  ],
};

const qApplicationAssistors = {
  copy: PREFIX,
  type: 'multiSelect',
  name: 'assistorTypes',
  dependencies: ['isProfessionalAssistance'],
  display: ({ isProfessionalAssistance }) => isProfessionalAssistance === 'YES',
  required: true,
  label: { id: 'HealthEnrollment.16_Q', data: { emphasis: null } },
  sublabel: { id: 'ProAssistance.brokerDetailedLabel' },
  help: { id: 'HealthEnrollment.15_T_GA' },
  options: [
    {
      label: { id: 'enums.NAVIGATOR' },
      value: 'NAVIGATOR',
      id: 'NAVIGATOR',
    },
    {
      label: { id: 'enums.CERTIFIED_APPLICATION_COUNSELOR' },
      value: 'CERTIFIED_APPLICATION_COUNSELOR',
      id: 'CERTIFIED_APPLICATION_COUNSELOR',
    },
    {
      label: { id: 'enums.AGENT_OR_BROKER' },
      value: 'AGENT_OR_BROKER',
      id: 'AGENT_OR_BROKER',
      disabled: true,
    },
    {
      label: { id: 'enums.OTHER_ASSISTOR' },
      value: 'OTHER_ASSISTOR',
      id: 'OTHER_ASSISTOR',
    },
  ],
};

const makeAssistorSection = (value) => ({
  copy: PREFIX,
  type: 'section',
  label: 'navigator',
  title: { id: 'ProAssistance.sectionTitle', data: { proType: { id: `enums.${value}` } } },
  subtitle: { id: 'ProAssistance.sectionSubtitle', data: { proType: { id: `enums.${value}` } } },
  dependencies: ['assistorTypes', 'isProfessionalAssistance'],
  display: ({ assistorTypes, isProfessionalAssistance }) =>
    isProfessionalAssistance === 'YES' && assistorTypes && assistorTypes.includes(value),
  required: true,
  fields: [
    {
      copy: PREFIX,
      type: 'legalName',
      name: `applicationAssistors.${value}.name`,
      subfields: {
        givenName: true,
        middleName: true,
        familyName: true,
        nameSuffix: true,
      },
    },
    {
      copy: PREFIX,
      type: 'text',
      label: { id: 'HealthEnrollment.17b_L' },
      name: `applicationAssistors.${value}.organizationName`,
    },
    {
      copy: PREFIX,
      type: 'text',
      label: { id: 'HealthEnrollment.17c_L' },
      name: `applicationAssistors.${value}.organizationID`,
    },
  ],
});

const qNavigation = makeAssistorSection('NAVIGATOR');
const qCertifiedApplicationCounselor = makeAssistorSection('CERTIFIED_APPLICATION_COUNSELOR');
const qOtherAssistor = makeAssistorSection('OTHER_ASSISTOR');

const proAssistanceFields = [
  qIsProfessionalAssistance,
  qApplicationAssistors,
  qNavigation,
  qCertifiedApplicationCounselor,
  qOtherAssistor,
];

export default proAssistanceFields;
