import { optionPresets } from '@app/forms';
import { ninetyDaysAgo, sixtyDaysFromNow } from '@app/utils';
import { parseISO, startOfDay } from 'date-fns';

export const fields = [
  {
    testID: '138',
    name: 'isMedicaidEnd',
    type: 'option',
    required: true,
    options: optionPresets.YES_NO_TRINARY,
    dependencies: ['relation', 'fullName', 'medicaid', 'chip'],
    copy: 'catch.ede.HealthEnrollment',
    label: ({ relation, fullName, medicaid, chip }) => ({
      id: '138_Q',
      data: { relation, fullName, medicaid, chip, endDate: sixtyDaysFromNow() },
    }),
  },
  {
    testID: '140',
    name: 'medicaidEndDate',
    type: 'date',
    required: true,
    format: 'YYYY-MM-DD',
    dependencies: ['isMedicaidEnd', 'relation', 'fullName', 'medicaid', 'chip'],
    display: (values) => values.isMedicaidEnd === 'YES',
    copy: 'catch.ede.HealthEnrollment',
    before: { date: sixtyDaysFromNow(), inclusive: true, message: "Must be within sixty days" },
    after: { date: startOfDay(new Date("3/31/23")), inclusive: true, message: "Must be after March 31, 2023" },
    label: ({ relation, fullName, medicaid, chip }) => ({
      id: '140_Q',
      data: { relation, fullName, medicaid, chip },
    }),
  },
  {
    testID: '139',
    name: 'isHouseholdChanged',
    type: 'option',
    required: true,
    options: optionPresets.YES_NO_TRINARY,
    dependencies: ['isMedicaidEnd', 'medicaidEndDate', 'relation', 'fullName'],
    display: (values) => values.isMedicaidEnd === 'YES' && !!values?.medicaidEndDate,
    copy: 'catch.ede.HealthEnrollment',
    label: ({ relation, fullName }) => ({ id: '139_Q', data: { relation, fullName: fullName } }),
  },

  {
    testID: '133',
    name: 'isMedicaidDenied',
    type: 'option',
    required: true,
    options: optionPresets.YES_NO_TRINARY,
    dependencies: [
      'isMedicaidEnd',
      'relation',
      'fullName',
      'medicaid',
      'chip',
      'state',
      'ninetyDaysAgo',
    ],
    display: (values) => values.isMedicaidEnd === 'NO',
    copy: 'catch.ede.HealthEnrollment',
    label: ({ relation, fullName, medicaid, chip, state, ninetyDaysAgo }) => ({
      id: '133_Q',
      data: { relation, fullName, medicaid, chip, state, date: ninetyDaysAgo },
    }),
    help: { id: '133_T' },
  },
  {
    testID: '134',
    name: 'medicaidDeniedDate',
    type: 'date',
    required: true,
    format: 'YYYY-MM-DD',
    dependencies: ['isMedicaidDenied', 'relation', 'fullName', 'chip', 'medicaid'],
    display: (values) => values.isMedicaidDenied === 'YES',
    copy: 'catch.ede.HealthEnrollment',
    label: ({ relation, fullName, chip }) => ({
      id: '134_Q',
      data: { relation, fullName, chip },
    }),
    help: ({ medicaid, chip }) => ({ id: '134_T', data: { medicaid, chip } }),
    before: { date: new Date(), message: 'Must be before today' },
    after: { date: ninetyDaysAgo(), message: 'Must be in the last 90 days' },
  },
  {
    testID: '136',
    name: 'isAppliedDuringOe',
    type: 'option',
    required: true,
    options: optionPresets.YES_NO_TRINARY,
    dependencies: [
      'isMedicaidDenied',
      'relation',
      'fullName',
      'oeStartDate',
      'oeEndDate',
      'oeYear',
    ],
    display: (values) => values.isMedicaidDenied === 'YES',
    copy: 'catch.ede.HealthEnrollment',
    label: ({ relation, fullName, oeStartDate, oeEndDate, oeYear }) => ({
      id: '136_Q',
      data: { relation, fullName, oeStartDate, oeEndDate, oeYear },
    }),
  },
  {
    testID: '137',
    name: 'isAppliedDuringLifeChange',
    type: 'option',
    required: true,
    options: optionPresets.YES_NO_TRINARY,
    dependencies: ['isAppliedDuringOe', 'relation', 'fullName'],
    display: (values) => values.isAppliedDuringOe === 'NO',
    copy: 'catch.ede.HealthEnrollment',
    label: ({ relation, fullName }) => ({ id: '137_Q', data: { relation, fullName: fullName } }),
    help: { id: '137_T' },
  },
  {
    testID: '141',
    name: 'isMedicaidDeniedDueToImmigration',
    type: 'option',
    required: true,
    options: optionPresets.YES_NO_TRINARY,
    dependencies: ['isLawfulPresenceStatus', 'relation', 'fullName', 'chip', 'fiveYearsAgo'],
    display: (values) => values.isLawfulPresenceStatus,
    copy: 'catch.ede.HealthEnrollment',
    label: ({ relation, fullName, chip, fiveYearsAgo }) => ({
      id: '141_Q',
      data: { relation, fullName, chip, date: fiveYearsAgo },
    }),
    help: ({ chip }) => ({ id: '141_T', data: { chip } }),
  },
  {
    testID: '142',
    name: 'hasLivedInUS5Years',
    type: 'option',
    required: true,
    options: optionPresets.YES_NO_TRINARY,
    dependencies: [
      'isLawfulPresenceStatus',
      'isMedicaidDeniedDueToImmigration',
      'relation',
      'fullName',
      'fiveYearsAgo',
    ],
    display: (values) =>
      values.isLawfulPresenceStatus && values.isMedicaidDeniedDueToImmigration === 'YES',
    copy: 'catch.ede.HealthEnrollment',
    label: ({ relation, fullName, fiveYearsAgo }) => ({
      id: '142_Q',
      data: { relation, fullName, date: fiveYearsAgo },
    }),
  },
  {
    testID: '143',
    name: 'isImmigrationStatusChangeSinceDenied',
    type: 'option',
    required: true,
    options: optionPresets.YES_NO_TRINARY,
    dependencies: [
      'isMedicaidDeniedDueToImmigration',
      'isLawfulPresenceStatus',
      'hasLivedInUS5Years',
      'relation',
      'fullName',
      'chip',
    ],
    display: (values) =>
      values.isLawfulPresenceStatus &&
      values.hasLivedInUS5Years === 'NO' &&
      values.isMedicaidDeniedDueToImmigration === 'YES',
    copy: 'catch.ede.HealthEnrollment',
    label: ({ relation, fullName, chip }) => ({
      id: '143_Q',
      data: { relation, fullName, chip },
    }),
  },
];
