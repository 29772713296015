import React, { useEffect } from 'react';

import { SetupIntro } from '@common';
import { routes, open } from '@navigate';
import { SlasherApplicationSummary } from '@data/queries/HealthSlasherQuery';
import { ComplexRow, Link } from '@uikit';
import { Segment } from '@app/utils';
import Warning from '@svg/minus.svg';
import { HealthSlasherStackScreenProps } from '../HealthSlasherStack';

const canSlash = (previousApplication?: SlasherApplicationSummary): boolean => {
  if (!previousApplication) return false;

  // has some active-ish coverage
  return previousApplication?.policies?.some((policy) =>
    ['ACTIVE', 'PENDING_START_DATE', 'PENDING', 'ACTIVE_PENDING_PAYMENT'].includes(
      policy.policyStatus,
    ),
  );
};

/**
 *
 * Premium Slasher Intro
 *
 * @todo
 * - check if CiC prefill and remove
 * - create CiC prefill
 * - handle intro errors (ugh)
 *
 */
const HealthSlasherIntro = ({
  handleNext,
  loading,
  previousApplication,
}: HealthSlasherStackScreenProps) => {
  const disable = !canSlash(previousApplication);

  useEffect(() => {
    Segment.track('_debug_slasher_flow_intro');
  }, []);

  return (
    <SetupIntro
      type="HEALTH_SLASHER"
      onNext={handleNext}
      context="sheet"
      disabled={disable}
      loading={loading}
    >
      {disable && !loading && (
        <ComplexRow
          testID="noApplication"
          loading={loading}
          label="No active coverage available"
          sublabel={<Link onPress={() => open(routes.EDE_INTRO)}>Start a new application</Link>}
          asset={{
            svg: Warning,
            bg: 'coverage',
            color: 'white',
            shape: 'circle',
          }}
        />
      )}
    </SetupIntro>
  );
};

export const HealthSlasherIntroView = {
  name: routes.HEALTH_SLASHER_INTRO,
  component: HealthSlasherIntro,
  options: {
    ...SetupIntro.options,
    bg: 'coverageLight',
    title: '',
  },
};
