import gql from 'graphql-tag';
import {
  CSRLevel,
  FilingStatus,
  HealthApplicationAssistorType,
  HealthApplicationContext,
  HealthPathwayType,
  IdentityProvider,
  IdentityStatus,
} from '@types';
import {
  ApolloApplicationMember,
  ApplicationMemberFragment,
  ApolloOpenEnrollmentDates,
  OpenEnrollmentFragment,
} from '../fragments';

export interface HealthApplicationQueryData {
  viewer: {
    user: {
      id: string;
      givenName?: string;
      middleName?: string;
      familyName?: string;
      email?: string;
      phoneNumber?: string;
      dob?: string;
      filingStatus?: FilingStatus;
      numTaxDependents?: number;
      legalAddress?: {
        street1: string;
        street2?: string;
        city?: string;
        state?: string;
        zip?: string;
      };
    };
  };
  viewerTwo: {
    agreements: {
      id: string;
      isPrivacyTruthfulAgree: boolean;
      isPrivacyAuthorizeAgree: boolean;
      isPrivacyDataUseAgree: boolean;
    };
    identity: {
      id: string;
      providerType: IdentityProvider;
      status: IdentityStatus;
    };
    health: {
      application: {
        id: string;
        applicationContext: HealthApplicationContext;
        isGeorgiaAccess: boolean;
        lastUsedRoute: string;
        pathwayType: HealthPathwayType;
        applicationPhase?: 'ONE' | 'TWO' | 'THREE';
        shouldRefetchEligibility?: boolean;
        uiQuestionsToDisplay?: { [key: string]: boolean };
        insuranceApplicationIdentifier?: string;

        // assistors
        applicationAssistors: Array<{
          applicationAssistorType?: HealthApplicationAssistorType;
          assistorFFMUserName?: string;
          assistorNPN?: number;
          firstName?: string;
          lastName?: string;
          middleName?: string;
          suffix?: string;
          organizationID?: string;
          organizationName?: string;
        }>;

        // household
        coverageState: string;
        coverageYearNumber: number;
        maritalStatus?: 'UNKNOWN' | 'SINGLE' | 'MARRIED';
        numTaxDependents?: number;
        isSelfCoverage?: boolean;
        isSpouseCoverage?: boolean;
        numDependentsRequestCoverage: number;
        numDependents?: number;
        householdIncome?: number;

        // for member checks
        hasSelf?: boolean;
        hasSpouse?: boolean;
        dependentsNum?: number;

        //agreements
        isRenewalAgreement: boolean;
        renewEligibilityYearQuantity: number;
        isMedicaidRequirementAgreement: boolean;
        isAbsentParentAgreement: boolean;
        isTerminateCoverageOtherMecFoundAgreement: boolean;
        isChangeInformationAgreement: boolean;

        enrollment?: {
          id: string;
          isOptOutRenewal?: boolean;
          enrollmentType?: any;
          hasAssociatedDentalPlan?: boolean;
        };

        policies?: {
          id: string;
        };

        eligibility: {
          totalAptc: number;
          csrLevel: CSRLevel;
          isMedicaid: boolean;
          isChip: boolean;
        };

        parent: {
          id: string;
          aptcHouseholdAmount: number;
          eligibility: {
            totalAptc: number;
            csrLevel: CSRLevel;
            isMedicaid: boolean;
            isChip: boolean;
          };
        };

        // screening
        screening: {
          areAllSameAddress?: boolean;
          areApplicationsCitizens?: boolean;
          areDependentsLivingWithParentNotOnTaxReturn?: boolean;
          areDependentsChildOrStepchildSingleUnder26?: boolean;
          isAnyoneFullTimeStudent1822?: boolean;
          isApplicationNameSameAsSsn?: boolean;
          isCanProvideSsn?: boolean;
          isClaimingAllDependentsOnTax?: boolean;
          isCoveredThroughJobOrCobra?: boolean;
          isDependentSingleChild25OrYounger?: boolean;
          isFormerFosterCareAndUnder25?: boolean;
          isIncarcerated?: boolean;
          isIndianOrAlaskan?: boolean;
          isNaturalizedOrDerivedCitizenship?: boolean;
          isOfferedIchra?: boolean;
          isPlanningToFileTax?: boolean;
          isPregnantOrHadChildLast60Days?: boolean;
          isRequestingFinancialAssistance?: boolean;
          isResponsibleForChildNotOnTaxReturn?: boolean;
          isStepchildOrGrandchild?: boolean;
        };

        // income
        incomeExplanationRequiredIndicator?: boolean;
        incomeExplanationRequiredReason?: 'HIGHER_THAN_SOURCE' | 'LOWER_THAN_SOURCE';

        // misc.
        stateReferenceData: {
          stateCHIPWaitingPeriod?: number;
          deprivationRequirementRetained?: boolean;
        };

        applicant?: ApolloApplicationMember;
        members?: Array<ApolloApplicationMember>;
      };
    };
  };
  reference: {
    health: {
      openEnrollmentDates: ApolloOpenEnrollmentDates;
    };
  };
}

export const HealthApplicationQuery = gql`
  query HealthApplicationQuery($applicationID: ID!) {
    viewer {
      id
      user {
        id
        givenName
        middleName
        familyName
        email
        phoneNumber
        dob
        filingStatus
        numTaxDependents
        legalAddress {
          street1
          street2
          city
          state
          zip
        }
      }
    }
    viewerTwo {
      id
      agreements {
        id
        isPrivacyTruthfulAgree
        isPrivacyAuthorizeAgree
        isPrivacyDataUseAgree
      }
      identity(providerType: CMS) {
        id
        providerType
        status
      }
      health {
        application(id: $applicationID) {
          id
          applicationContext
          lastUsedRoute
          pathwayType
          applicationPhase
          isGeorgiaAccess @client
          shouldRefetchEligibility
          uiQuestionsToDisplay
          insuranceApplicationIdentifier

          # assistors
          applicationAssistors {
            applicationAssistorType
            assistorFFMUserName
            assistorNPN
            firstName
            lastName
            middleName
            organizationID
            organizationName
            suffix
          }

          # household information
          coverageState
          coverageYearNumber
          maritalStatus
          numTaxDependents
          isSelfCoverage
          isSpouseCoverage
          numDependentsRequestCoverage
          householdIncome

          #agreements
          isRenewalAgreement
          renewEligibilityYearQuantity
          isMedicaidRequirementAgreement
          isAbsentParentAgreement
          isTerminateCoverageOtherMecFoundAgreement
          isChangeInformationAgreement

          eligibility {
            totalAptc
            csrLevel
            isMedicaid
            isChip
          }

          parent {
            id
            aptcHouseholdAmount
            eligibility {
              totalAptc
              csrLevel
              isMedicaid
              isChip
            }
          }

          screening {
            # phase 3
            areAllSameAddress
            areDependentsLivingWithParentNotOnTaxReturn
            isClaimingAllDependentsOnTax
            isCoveredThroughJobOrCobra
            isDependentSingleChild25OrYounger
            isIndianOrAlaskan
            isOfferedIchra
            isPlanningToFileTax
            isRequestingFinancialAssistance
            isResponsibleForChildNotOnTaxReturn
            areDependentsChildOrStepchildSingleUnder26

            # phase 2
            areApplicationsCitizens
            isAnyoneFullTimeStudent1822
            isApplicationNameSameAsSsn
            isCanProvideSsn
            isFormerFosterCareAndUnder25
            isIncarcerated
            isNaturalizedOrDerivedCitizenship
            isPregnantOrHadChildLast60Days
            isStepchildOrGrandchild
          }

          # income
          incomeExplanationRequiredIndicator
          incomeExplanationRequiredReason

          # misc.
          stateReferenceData {
            stateCHIPWaitingPeriod
            deprivationRequirementRetained
          }

          applicant {
            id
            ...ApplicationMember
          }

          members {
            id
            ...ApplicationMember
          }

          policies {
            id
            startDate
            endDate
            coverageYear @client
            planName
            carrier
            premium
            premiumEffective
            policyStatus
            enrollmentID
            productType
          }

          enrollment {
            id
            enrollmentType
            isOptOutRenewal
            hasAssociatedDentalPlan
          }
        }
      }
      healthExplorerData {
        id
        healthPlanDetails {
          planID: id
          name
          title @client
          premium
          premiumWithCredit
          issuer {
            id
            name
            title @client
          }
        }
      }
    }
    reference {
      health {
        openEnrollmentDates {
          ...OEDates
        }
      }
    }
  }
  ${ApplicationMemberFragment}
  ${OpenEnrollmentFragment}
`;
