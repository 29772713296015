import React from 'react';

import { routes } from '@navigate';
import { BasicLayout, Stack, Toolbar } from '@app/layouts';
import { Button, LineItem, Pressable, useTheme } from '@app/_ui-kit';
import { HealthSlasherStackScreenProps } from '../HealthSlasherStack';
import { capitalize } from '@app/utils';

// temp component ?
const CardRow = ({ left, right, onPress }) => {
  const { theme } = useTheme();
  return (
    <Pressable
      style={[theme.allSpace3, theme.borderLightBg, theme.mdCorners]}
      hoveredStyle={[theme.borderBg]}
      handleOnPress={onPress}
    >
      <LineItem left={left} right={right} />
    </Pressable>
  );
};

/**
 *
 * Premium Slasher Triage
 * (have any of these changed?)
 *
 * if the items here have changed, then we'll probably want the full application
 * (or alternatively, the vanilla CiC flow)
 *
 * @todo interstitial before the reroute
 *
 */
const HealthSlasherTriage = ({
  handleNext,
  handleComplexCase,
  loading,
  previousApplication,
}: HealthSlasherStackScreenProps) => {
  if (!previousApplication?.isRequestingFinancialAssistance) {
    return (
      <BasicLayout
        loading={loading}
        title="To qualify for savings, you must update your application"
        subtitles={['Some information is missing that you need to update first.']}
        toolbar={
          <Toolbar type="stack">
            <Button
              onPress={() =>
                handleComplexCase({
                  id: previousApplication?.id,
                  isRequestingFinancialAssistance: 'YES',
                })
              }
            >
              Update Application
            </Button>
          </Toolbar>
        }
      />
    );
  }

  return (
    <BasicLayout
      loading={loading}
      title="Has any of the information below changed?"
      subtitles={['The information below was pulled from your current health application.']}
      toolbar={
        <Toolbar type="stack">
          <Button onPress={handleNext}>Next</Button>
        </Toolbar>
      }
    >
      <Stack spacing="0b">
        <CardRow
          left="Tax Dependents"
          right={previousApplication?.numTaxDependents}
          onPress={handleComplexCase}
        />
        <CardRow
          left="Marital Status"
          right={capitalize(
            previousApplication?.maritalStatus || previousApplication?.applicant?.maritalStatus,
          )}
          onPress={handleComplexCase}
        />
      </Stack>
    </BasicLayout>
  );
};

export const HealthSlasherTriageView = {
  name: routes.HEALTH_SLASHER_TRIAGE,
  component: HealthSlasherTriage,
  options: {},
};
