import React, { useMemo } from 'react';
import { View } from 'react-native';

import { Button, Render, Text, useResponsive, useTheme } from '@uikit';
import { Layout, Narrow, Toolbar } from '@layouts';
import { navigate, routes, ScreenDefinition } from '@navigate';
import { useCopy } from '@app/utils';
import PageGradient from '@layouts/PageGradient';

const LobbyComponent = () => {
  const { c } = useCopy('catch.Lobby');
  const { theme } = useTheme();
  const { isMobile } = useResponsive();

  const narrowParams = useMemo(() => {
    const centered = !isMobile;
    return {
      style: {},
      center: centered,
    };
  }, [isMobile]);

  return (
    <PageGradient fixAndroidMarginBehindNav gradient="brandLight">
      <Narrow {...narrowParams}>
        <Layout.Scroll fullWidth>
          <View style={[theme.topSpace4]}>
            <Render name="brand" size={isMobile ? 'plus' : 'humongous'} />
            <Text
              align="left"
              size="xl"
              color="text"
              weight="medium"
              style={{ marginTop: 75, lineHeight: 48 }}
            >
              {c('title')}
            </Text>
          </View>
        </Layout.Scroll>

        <Toolbar type="stack" fullWidth hasGradient={false} noMargins>
          {/* <Button
            full
            wide={false}
            haptics="HEAVY"
            testID="register"
            accentColor="brand"
            onPress={() => navigate('REGISTER')}
          >
            {c('signUpBtn')}
          </Button> */}
          <Button
            full
            wide={false}
            haptics="LIGHT"
            testID="login"
            alt
            accentColor="brandLight"
            onPress={() => navigate('LOGIN')}
          >
            {c('signInBtn')}
          </Button>
        </Toolbar>
      </Narrow>
    </PageGradient>
  );
};

export const LobbyView: ScreenDefinition = {
  name: routes.LOBBY,
  component: LobbyComponent,
  guest: true,
  options: {
    drawBehindNav: true,
  },
};
