import React from 'react';

import { Copy } from '@types';
import { Layout } from '@layouts';
import { OptionGroup } from '@uikit/components/OptionGroup';
import { Asset } from '@uikit/components/Asset';
import { useCopy } from '@app/utils';
import { date as fDate } from '@app/utils/format/date';
import { paycheckSource } from '@app/utils/format/income';

interface Props {
  title?: Copy;
  paycheck?: any;
  question?: any;
  options?: any;
  onPress?: any;
}

/**
 * @description triages an item (paycheck, goal, etc.) with options
 * @todo genericize beyond paychecks
 */
export const ItemTriageBlueprint: React.FC<Props> = ({ paycheck, options, question, onPress }) => {
  const { c, $ } = useCopy('catch.sequences.Paycheck.breakdown.header');
  return (
    // @ts-ignore
    <Layout.Scroll topSpace={false} fullWidth color="sheet">
      {/* @ts-ignore */}
      <Layout margins>
        {!!paycheck && (
          // @ts-ignore
          <Layout.Header
            // @ts-ignore
            align="center"
            bottomSpace
            asset={{ ...Asset.configureSource(paycheck?.source), size: 'md' }}
            title={c('title', {
              // @ts-ignore
              amount: $(paycheck.amount),
              source: paycheckSource(paycheck.source),
            })}
            // @ts-ignore
            subtitle={fDate(paycheck.date, 'RELATIVE')}
          />
        )}
        {/* @ts-ignore */}
        <Layout.Header
          // @ts-ignore
          light
          sheet
          bottomSpace
          align="center"
          title={question}
        />
        {/* @ts-ignore */}
        <OptionGroup
          options={options}
          onPress={(val) => {
            if (onPress) onPress(val);
          }}
        />
      </Layout>
    </Layout.Scroll>
  );
};

export default ItemTriageBlueprint;
