import gql from 'graphql-tag';
import { lowercase } from '@app/utils';

export const PortfolioFragments = {
  portfolio: gql`
    fragment Portfolio on Portfolio {
      id
      name
      contents {
        name
        ticker
        weight
      }
    }
  `,
};

export const formatPortfolio = (portfolio) => {
  const type = lowercase(portfolio?.name?.replace('ESG', ''));
  const isESG = /ESG/.test(portfolio?.name);
  return { ...portfolio, type, isESG };
};

export default {
  ...PortfolioFragments,
  formatPortfolio,
};
