import React from 'react';

import { Link, FinePrint } from '@uikit';
import { useCopy } from '@app/utils';

const RegistrationDisclosures = () => {
  const { c } = useCopy('catch.module.disclosures');

  return (
    <FinePrint
      items={[
        c('register', {
          agreement: (
            <Link color="subtle" key="l-1" href="https://catch.co/legal/electronic" target="_blank">
              {c('platform.esign')}
            </Link>
          ),
          terms: (
            <Link color="subtle" key="l-2" href="https://catch.co/legal/terms" target="_blank">
              {c('platform.terms')}
            </Link>
          ),
          privacy: (
            <Link color="subtle" key="l-3" href="https://catch.co/legal/privacy" target="_blank">
              {c('platform.privacy')}
            </Link>
          ),
          smsPolicy: (
            <Link
              color="subtle"
              key="l-5"
              href="https://catch.co/legal/text-message"
              target="_blank"
            >
              {c('platform.sms')}
            </Link>
          ),
        }),
      ]}
    />
  );
};

export default RegistrationDisclosures;
