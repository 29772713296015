import gql from 'graphql-tag';
import access from 'safe-access';
import { banks } from '../fragments';

const BANK_ACCOUNTS = gql`
  query BankAccounts {
    viewer {
      id
      bankLinks(input: { includeNotWatched: true }) {
        id
        providerType
        ...BankLinkPartial
        plaidBank {
          ...Institution
        }
        accounts {
          id
          ...BankAccount
        }
      }
      pendingAccounts {
        id
        ...PendingAccount
      }
    }
  }
  ${banks.institution}
  ${banks.bankLink}
  ${banks.bankAccount}
  ${banks.pendingAccount}
`;

const formatter = (data) => {
  const get = access(data);
  const bankLinks = get('viewer.bankLinks') || [];
  const pending = get('viewer.pendingAccounts') || [];
  const ready = bankLinks.map((b) => banks.formatBank(b));
  const notReady = pending.map((p) => banks.formatPendingBank(p));
  const accountsById = {};

  // this can be consolidated, but didn't want to change it rn
  const accounts = ready.reduce((acc, b) => {
    (b?.accounts || []).forEach((account) => {
      accountsById[account?.id] = { ...account, bank: b };
    });
    return [...acc, ...b.accounts];
  }, []);

  let watchedAccounts = [];
  let unwatchedAccounts = [];
  let allAccounts = [];

  ready.forEach((bank) => {
    (bank?.accounts || []).forEach((acc) => {
      acc.bank = bank;

      if (acc?.isWatched) watchedAccounts.push(acc);
      if (!acc?.isWatched) unwatchedAccounts.push(acc);
      allAccounts.push(acc);
    });
  });

  return {
    banks: ready.concat(notReady),
    ready,

    accounts,
    accountsById,
    watchedAccounts,
    unwatchedAccounts,
    allAccounts,
  };
};

export default {
  document: BANK_ACCOUNTS,
  formatter,
  options: { fetchPolicy: 'cache-and-network' },
};
