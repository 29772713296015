import gql from 'graphql-tag';

export interface RelinkBankLinkVars {
  bankLinkID?: string;
}

export interface RelinkBankLinkResponse {
  setSyncStatus: {
    id: string;
    syncStatus: string;
  };
}

export const RelinkBankLink = gql`
  mutation RelinkBankLink($bankLinkID: ID!) {
    setSyncStatus(banklinkID: $bankLinkID, syncStatus: GOOD) {
      id
      syncStatus
      errorCode
      providerType
    }
  }
`;
