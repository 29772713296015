import { stateExchanges } from '../const/EXCHANGES';

/**
 * @deprecated
 * These constants have been moved to the @util health folder
 */
const binary = [
  {
    id: 'yes',
    value: 'YES',
  },
  {
    id: 'no',
    value: 'NO',
  },
];

const proAssistanceBinary = [
  {
    value: 'YES',
    id: 'PRO_ASSISTANCE_YES',
  },
  {
    value: 'NO',
    id: 'PRO_ASSISTANCE_NO',
  },
];

const immigrationDocumentTypes = {
  CERTIFICATE_OF_NATURALIZATION: 'CERTIFICATE_OF_NATURALIZATION',
  CERTIFICATE_OF_CITIZENSHIP: 'CERTIFICATE_OF_CITIZENSHIP',
  NONE_OF_THESE: 'NONE_OF_THESE',
};

const lawfulPresenceDocumentTypes = {
  PERMANENT_RESIDENCE_CARD: [
    'PERMANENT_RESIDENT_CARD_I_551',
    'TEMPORARY_I_551_STAMP_ON_PASSPORT_OR_I_94_I_94A',
    'REENTRY_PERMIT',
  ],
  ARRIVAL_DEPARTURE_RECORD: [
    'ARRIVAL_DEPARTURE_RECORD_IN_FOREIGN_PASSPORT_I_94',
    'ARRIVAL_DEPARTURE_RECORD_IN_UNEXPIRED_FOREIGN_PASSPORT_I_94',
  ],
  STUDENT_EXCHANGE_RECORD: [
    'CERTIFICATE_OF_ELIGIBILITY_FOR_EXCHANGE_VISITOR_STATUS_DS_2019',
    'CERTIFICATE_OF_ELIGIBILITY_FOR_NONIMMIGRANT_STUDENT_STATUS_I_20',
  ],
  MACHINE_READABLE_IMMIGRANT_VISA_WITH_TEMPORARY_I_551_LANGUAGE:
    'MACHINE_READABLE_IMMIGRANT_VISA_WITH_TEMPORARY_I_551_LANGUAGE',
  EMPLOYMENT_AUTHORIZATION_CARD_I_766: 'EMPLOYMENT_AUTHORIZATION_CARD_I_766',
  REFUGEE_TRAVEL_DOCUMENT_I_571: 'REFUGEE_TRAVEL_DOCUMENT_I_571',
  NOTICE_OF_ACTION_I_797: 'NOTICE_OF_ACTION_I_797',
  FOREIGN_PASSPORT: 'FOREIGN_PASSPORT',
  NONE_OF_THESE: 'NONE_OF_THESE',
};

const proAssistantTypes = [
  {
    value: 'NAVIGATOR',
  },
  {
    value: 'CERTIFIED_APPLICATION_COUNSELOR',
  },
  {
    value: 'AGENT_OR_BROKER',
  },
  {
    value: 'OTHER_ASSISTOR',
  },
];

const applicationAssistorTypes = {
  NAVIGATOR: 'ASSISTOR_TYPE_NAVIGATOR',
  CERTIFIED_APPLICATION_COUNSELOR: 'ASSISTOR_TYPE_CERTIFIED_APPLICATION_COUNSELOR',
  AGENT_OR_BROKER: 'ASSISTOR_TYPE_AGENT_BROKER',
  OTHER_ASSISTOR: 'ASSISTOR_TYPE_IN_PERSON_ASSISTANT_PERSONNEL',
};

const languages = [
  {
    value: 'ENGLISH',
    label: 'English',
  },
  {
    value: 'SPANISH',
    label: 'Espanol',
  },
  {
    value: 'VIETNAMESE',
    label: 'Vietnamese',
  },
  {
    value: 'TAGALOG',
    label: 'Tagalog',
  },
  {
    value: 'RUSSIAN',
    label: 'Russian',
  },
  {
    value: 'PORTUGUESE',
    label: 'Portuguese',
  },
  {
    value: 'ARABIC',
    label: 'Arabic',
  },
  {
    value: 'CHINESE',
    label: 'Chinese',
  },
  {
    value: 'FRENCH',
    label: 'French',
  },
  {
    value: 'FRENCH_CREOLE',
    label: 'French Creole',
  },
  {
    value: 'GERMAN',
    label: 'German',
  },
  {
    value: 'GUJARATI',
    label: 'Gujarati',
  },
  {
    value: 'HINDI',
    label: 'Hindi',
  },
  {
    value: 'KOREAN',
    label: 'Korean',
  },
  {
    value: 'POLISH',
    label: 'Polish',
  },
  {
    value: 'URDU',
    label: 'Urdu',
  },
  {
    value: 'OTHER',
    label: 'Other',
  },
];
const races = [
  {
    label: 'American Indian or Alaska Native',
    value: 'AMERICAN_INDIAN_OR_ALASKAN_NATIVE',
  },
  { label: 'Asian Indian', value: 'ASIAN_INDIAN' },
  { label: 'Black or African American', value: 'BLACK_OR_AFRICAN_AMERICAN' },
  { label: 'Chinese', value: 'CHINESE' },
  { label: 'Filipino', value: 'FILIPINO' },
  { label: 'Guamanian or Chamorro', value: 'GUAMANIAN_OR_CHAMORRO' },
  { label: 'Japanese ', value: 'JAPANESE' },
  { label: 'Korean', value: 'KOREAN' },
  { label: 'Native Hawaiian', value: 'NATIVE_HAWAIIAN' },
  { label: 'Samoan', value: 'SAMOAN' },
  { label: 'Vietnamese ', value: 'VIETNAMESE' },
  { label: 'Caucasian ', value: 'WHITE' },
  { label: 'An Asian race not listed above', value: 'OTHER_ASIAN' },
  {
    label: 'A Pacific Islander race not listed above',
    value: 'OTHER_PACIFIC_ISLANDER',
  },
  { label: 'Another race not listed here', value: 'OTHER' },
];
const specificOrigins = [
  { label: 'Cuban', value: 'CUBAN' },
  { label: 'Mexican, Mexican American, or Chicano/a', value: 'MEXICAN' },
  { label: 'Puerto Rican', value: 'PUERTO_RICAN' },
  { label: 'Other:', value: 'OTHER' },
];
const sex = [
  { label: 'Female', value: 'FEMALE' },
  { label: 'Male', value: 'MALE' },
];
const maritalStatus = [
  { label: 'Single', value: 'SINGLE' },
  { label: 'Married', value: 'MARRIED' },
];

const incomeTypes = [
  { label: 'Job (like salary, wages, commissions, or tips)', value: 'JOB' },
  {
    label: 'Self-employment (like your own business, consulting, or freelance work)',
    value: 'SELF_EMPLOYMENT',
  },
  { label: 'Farming or fishing', value: 'FARMING_OR_FISHING_INCOME' },
  { label: 'Unemployment', value: 'UNEMPLOYMENT' },
  {
    label: 'Social Security benefits (retirement and disability)',
    value: 'SOCIAL_SECURITY_BENEFIT',
  },
  {
    label: 'Retirement (like IRA and 401(k) withdrawals)',
    value: 'RETIREMENT',
  },
  { label: 'Pension benefits', value: 'PENSION' },
  {
    label: 'Investment (including interest and dividend income)',
    value: 'INVESTMENT_INCOME',
  },
  { label: 'Capital gains', value: 'CAPITAL_GAINS' },
  { label: 'Rental or royalty', value: 'RENTAL_OR_ROYALTY_INCOME' },
  { label: 'Alimony received', value: 'ALIMONY_RECEIVED' },
  { label: 'Scholarship', value: 'SCHOLARSHIP' },
  { label: 'Canceled debt', value: 'CANCELED_DEBT' },
  { label: 'Court awards', value: 'COURT_AWARDS' },
  {
    label: 'Gambling, prizes, or awards',
    value: 'PRIZES_AWARDS_GAMBLING_WINNINGS',
  },
  { label: 'Jury duty pay', value: 'JURY_DUTY_PAY' },
  { label: 'Other income', value: 'OTHER_INCOME' },
];

const deductionTypes = [
  { label: 'Alimony', value: 'ALIMONY_PAYMENT', id: 'ALIMONY_PAYMENT' },
  {
    label: 'Student loan interest',
    value: 'STUDENT_LOAN_INTEREST',
    id: 'STUDENT_LOAN_INTEREST',
  },
  {
    label: 'Other deduction',
    value: 'OTHER_DEDUCTION',
    id: 'OTHER_DEDUCTION_4',
  },
];

const frequencyTypes = [
  { value: 'WEEKLY', id: 'WEEKLY' },
  { value: 'BI_WEEKLY', id: 'BI_WEEKLY' },
  { value: 'MONTHLY', id: 'MONTHLY' },
  { value: 'QUARTERLY', id: 'QUARTERLY' },
  { value: 'ANNUALLY', id: 'ANNUALLY' },
  { value: 'SEMI_MONTHLY', id: 'SEMI_MONTHLY' },
  { value: 'ONE_TIME', id: 'ONE_TIME' },
];

// only hourly/daily frequency options are for JOB income type
const jobFrequencyTypes = [
  ...frequencyTypes,
  { value: 'DAILY', id: 'DAILY' },
  { value: 'HOURLY', id: 'HOURLY' },
];

const medicaidCHIPNames = {
  AL: {
    state: 'Alabama',
    medicaid: 'Medicaid',
    chip: 'Alabama ALL Kids',
  },
  AK: {
    state: 'Alaska',
    medicaid: 'Medicaid',
    chip: 'Denali KidCare',
  },
  AZ: {
    state: 'Arizona',
    medicaid: 'Arizona Health Care Cost Containment System (AHCCS)',
    chip: 'AHCCCS-KidsCare',
  },
  AR: {
    state: 'Arkansas',
    medicaid: 'Arkansas Medicaid',
    chip: 'ARKids B and Unborn Child',
  },
  CA: {
    state: 'California',
    medicaid: 'Medi-Cal',
    chip: 'Medi-Cal',
  },
  CO: {
    state: 'Colorado',
    medicaid: 'Health First Colorado',
    chip: 'Health First Colorado or CHP+',
  },
  CT: {
    state: 'Connecticut',
    medicaid: 'Connecticut Medicaid',
    chip: 'HUSKY Program',
  },
  DE: {
    state: 'Delaware',
    medicaid: 'Medicaid',
    chip: 'Delaware Healthy Children Program',
  },
  DC: {
    state: 'District Of Columbia',
    medicaid: 'DC Medicaid',
    chip: 'Healthy Families',
  },
  FL: {
    state: 'Florida',
    medicaid: 'Florida Medicaid',
    chip: 'Florida KidCare',
  },
  GA: {
    state: 'Georgia',
    medicaid: 'Georgia Medical Assistance',
    chip: 'PeachCare for Kids',
  },
  HI: {
    state: 'Hawaii',
    medicaid: 'Med Quest',
    chip: 'Med Quest',
  },
  ID: {
    state: 'Idaho',
    medicaid: 'Medicaid',
    chip: 'Idaho CHIP',
  },
  IL: {
    state: 'Illinois',
    medicaid: 'Illinois Medicaid',
    chip: 'Illinois All Kids',
  },
  IN: {
    state: 'Indiana',
    medicaid: 'Indiana Medicaid',
    chip: 'Indiana Children’s Health Insurance Plan',
  },
  IA: {
    state: 'Iowa',
    medicaid: 'Iowa Medicaid',
    chip: 'Hawk-I',
  },
  KS: {
    state: 'Kansas',
    medicaid: 'KanCare program',
    chip: 'KanCare program',
  },
  KY: {
    state: 'Kentucky',
    medicaid: 'Kentucky Medicaid',
    chip: 'Kentucky Children’s Health Insurance Program (KCHIP)',
  },
  LA: {
    state: 'Louisiana',
    medicaid: 'Healthy Louisiana',
    chip: 'Louisiana Children’s Health Insurance Program (LaCHIP)',
  },
  ME: {
    state: 'Maine',
    medicaid: 'MaineCare',
    chip: 'CubCare',
  },
  MD: {
    state: 'Maryland',
    medicaid: 'Maryland Medicaid',
    chip: 'Maryland Children’s Health Program (MCHIP)',
  },
  MA: {
    state: 'Massachusetts',
    medicaid: 'MassHealth',
    chip: '	MassHealth',
  },
  MI: {
    state: 'Michigan',
    medicaid: 'Michigan Department of Health and Human Service',
    chip: 'Michigan MIChild',
  },
  MN: {
    state: 'Minnesota',
    medicaid: 'Minnesota Medical Assistance',
    chip: 'Minnesota Medical Assistance',
  },
  MS: {
    state: 'Mississippi',
    medicaid: 'Mississippi Medicaid',
    chip: 'Mississippi Children’s Health Insurance Program (CHIP)',
  },
  MO: {
    state: 'Missouri',
    medicaid: 'MO HealthNet',
    chip: 'Missouri Children’s Health Insurance Program (CHIP)',
  },
  MT: {
    state: 'Montana',
    medicaid: 'Montana Medicaid',
    chip: 'Healthy Montana Kids',
  },
  NE: {
    state: 'Nebraska',
    medicaid: 'Medicaid',
    chip: 'Nebraska CHIP',
  },
  NV: {
    state: 'Nevada',
    medicaid: 'Medicaid',
    chip: 'Nevada Check Up',
  },
  NH: {
    state: 'New Hampshire',
    medicaid: 'New Hampshire Medicaid',
    chip: 'New Hampshire CHIP',
  },
  NJ: {
    state: 'New Jersey',
    medicaid: 'New Jersey FamilyCare',
    chip: '	New Jersey FamilyCare',
  },
  NM: {
    state: 'New Mexico',
    medicaid: 'Medicaid',
    chip: 'New Mexi-Kids',
  },
  NY: {
    state: 'New York',
    medicaid: 'New York Medicaid',
    chip: 'Child Health Plus',
  },
  NC: {
    state: 'North Carolina',
    medicaid: 'North Carolina Medicaid',
    chip: 'North Carolina Health Choice',
  },
  ND: {
    state: 'North Dakota',
    medicaid: 'Medicaid',
    chip: '	Healthy Steps',
  },
  OH: {
    state: 'Ohio',
    medicaid: 'Ohio Medicaid',
    chip: 'Healthy Start',
  },
  OK: {
    state: 'Oklahoma',
    medicaid: 'SoonerCare',
    chip: 'SoonerCare',
  },
  OR: {
    state: 'Oregon',
    medicaid: 'Oregon Health Plan',
    chip: 'Oregon Health Plan',
  },
  PA: {
    state: 'Pennsylvania',
    medicaid: 'Pennsylvania Medical Assistance (MA) (Medicaid)',
    chip: 'Pennsylvania CHIP',
  },
  RI: {
    state: 'Rhode Island',
    medicaid: 'Rhode Island Medical Assistance',
    chip: 'Rite Care',
  },
  SC: {
    state: 'South Carolina',
    medicaid: 'Healthy Connections',
    chip: 'Healthy Connections Children',
  },
  SD: {
    state: 'South Dakota',
    medicaid: 'Medicaid',
    chip: 'South Dakota CHIP Program',
  },
  TN: {
    state: 'Tennessee',
    medicaid: 'TennCare',
    chip: 'CoverKids',
  },
  TX: {
    state: 'Texas',
    medicaid: 'Medicaid',
    chip: 'CHIP',
  },
  UT: {
    state: 'Utah',
    medicaid: 'Medicaid',
    chip: 'CHIP',
  },
  VT: {
    state: 'Vermont',
    medicaid: 'Vermont Medicaid',
    chip: 'Dr Dynasaur',
  },
  VA: {
    state: 'Virginia',
    medicaid: 'Medicaid',
    chip: 'Family Access to Medical Insurance Security (FAMIS)',
  },
  WA: {
    state: 'Washington',
    medicaid: 'Apple Health (Medicaid)',
    chip: 'Apple Health for Kids',
  },
  WV: {
    state: 'West Virginia',
    medicaid: 'West Virginia Medicaid',
    chip: 'West Virginia Children’s Health Insurance Program (WVCHIP)',
  },
  WI: {
    state: 'Wisconsin',
    medicaid: 'BadgerCare Plus',
    chip: 'BadgerCare Plus',
  },
  WY: {
    state: 'Wyoming',
    medicaid: 'Medicaid',
    chip: 'Kid Care CHIP',
  },
};

// The state medicaid and chips will be merged dynamically
const coverageTypes = [
  {
    label: 'Individual insurance (including Marketplace or other non-group coverage)',
    value: 'MARKETPLACE',
  },
  { label: 'Medicaid', value: 'STATE_MEDICAID' },
  { label: 'Children’s Health Insurance Program (CHIP)', value: 'STATE_CHIP' },
  { label: 'Medicare', value: 'MEDICARE' },
  {
    label: 'TRICARE (Don’t check if this person has Direct Care or Line of Duty)',
    value: 'TRICARE',
  },
  { label: 'Veterans Affairs (VA) Health Care Program', value: 'VA' },
  { label: 'Peace Corps', value: 'PEACE_CORPS' },
  { label: 'Other full benefit coverage', value: 'OTHER_FULL_BENEFIT' },
  {
    label: 'Other limited benefit coverage (like a school accident policy)',
    value: 'OTHER_LIMITED_BENEFIT',
  },
];

const roles = [
  { label: 'I am applying for coverage', value: 'YES', id: 'IS_APPLYING' },
  {
    label: 'I am preparing this application for someone else',
    value: 'NO',
    id: 'IS_NOT_APPLYING',
  },
];

const phoneTypes = [
  { label: 'Mobile', value: 'MOBILE' },
  { label: 'Home', value: 'HOME' },
  { label: 'Office', value: 'OFFICE' },
  // { label: 'Other', value: 'OTHER' }, = other is not allowed on cms and breaks shit
];

const contactMethods = [
  { id: 'EMAIL', label: 'Digital', value: 'EMAIL' },
  { id: 'E_TEXT', label: 'Paper', value: 'MAIL' },
];

const mailingAddressOptions = [
  { label: 'Same as home address', value: true, id: 'SAME_AS_HOME' },
  { label: 'Different address', value: false, id: 'DIFFERENT_ADDRESS' },
];

const renewalYears = [
  { value: 0, id: 'RENEW_YEAR' },
  { value: 1, id: 'RENEW_YEAR' },
  { value: 2, id: 'RENEW_YEAR' },
  { value: 3, id: 'RENEW_YEAR' },
  { value: 4, id: 'RENEW_YEAR' },
  { value: 5, id: 'RENEW_YEAR' },
];

const sviDocumentTypes = [
  { value: 'ADOPTION_LETTER_OR_RECORD' },
  { value: 'AFFIDAVIT' },
  { value: 'ARRIVAL_DEPARTURE_RECORD_I_94_I_94A' },
  { value: 'BILLS_FINANCIAL_STATEMENT_AT_NEW_ADDRESS' },
  { value: 'COURT_ORDER' },
  { value: 'DENIAL_LETTER_FROM_YOUR_STATE' },
  { value: 'DHS_IMMIGRATION_DOCUMENT' },
  { value: 'DOCUMENT_FOR_COVERAGE_LOSS_DUE_TO_DEATH' },
  { value: 'DOCUMENT_FOR_COVERAGE_LOSS_DUE_TO_DIVORCE_SEPARATION' },
  { value: 'DOCUMENT_FROM_AN_EMPLOYER' },
  { value: 'FOSTER_CARE_PAPERS' },
  { value: 'HEALTH_CARE_PROGRAM_DOCUMENT' },
  { value: 'LEGAL_GUARDIANSHIP_DOCUMENT' },
  { value: 'LETTER_ABOUT_COBRA_COVERAGE' },
  { value: 'LETTER_FOR_LOSS_OF_STUDENT_COVERAGE' },
  { value: 'LETTER_FROM_A_GOVERNMENT_ORGANIZATION' },
  { value: 'LETTER_FROM_AN_EMPLOYER' },
  { value: 'LETTER_FROM_AN_INSURANCE_COMPANY' },
  { value: 'LETTER_FROM_GOVERNMENT_ORGANIZATION_SHOWING_CHANGE_OF_ADDRESS' },
  { value: 'LETTER_OF_EXPLANATION' },
  { value: 'LETTER_STATING_LOST_COVERAGE' },
  { value: 'MARKETPLACE_LETTER' },
  { value: 'MARRIAGE_CERT' },
  { value: 'MARRIAGE_LICENSE' },
  { value: 'MEDICAL_SUPPORT_ORDER' },
  { value: 'MORTGAGE_RENTAL_DOCUMENT_AT_NEW_ADDRESS' },
  { value: 'OFFICIAL_PUBLIC_RECORD' },
  { value: 'OTHER_DOCUMENT' },
  { value: 'PASSPORT' },
  { value: 'PAY_STUB' },
  { value: 'RELIGIOUS_DOCUMENT' },
  { value: 'SCREENSHOT_OF_RESULTS' },
  { value: 'U_S_POSTAL_SERVICE_CHANGE_OF_ADDRESS_LETTER' },
];

const dmiDocumentTypes = [
  'A_COMPLETED_EMPLOYER_COVERAGE_TOOL_WITH_NEW_EMPLOYER_COVERAGE_INFORMATION_AND_A_COVER_LETTER_SIGNED_BY_THE_EMPLOYER',
  'AGRICULTURAL_INCOME_CERTIFICATE',
  'AMERICAN_INDIAN_CARD_I_872',
  'ANNUITIES',
  'ARRIVAL_DEPARTURE_RECORD_IN_FOREIGN_PASSPORT_I_94',
  'AUTHENTIC_DOCUMENT_FROM_A_TRIBE_DECLARING_MEMBERSHIP_FOR_AN_INDIVIDUAL',
  'AUTHENTIC_DOCUMENT_FROM_A_TRIBE_DECLARING_MEMBERSHIP_OF_AN_INDIVIDUAL',
  'BANK_OR_INVESTMENT_FUND_STATEMENT',
  'BANK_STATEMENTS_PERSONAL_BUSINESS_AND_CANCELLED_CHECKS',
  'BONUS_INCENTIVE_PAYMENTS',
  'BOOKKEEPING_RECORDS_RECEIPTS_FOR_ALL_ALLOWABLE_EXPENSES',
  'CDM_XML',
  'CERTIFICATE_OF_CITIZENSHIP',
  'CERTIFICATE_OF_DECREE_OF_HALF_INDIAN_BLOOD',
  'CERTIFICATE_OF_ELIGIBILITY_FOR_EXCHANGE_VISITOR_STATUS_DS_2019',
  'CERTIFICATE_OF_ELIGIBILITY_FOR_NONIMMIGRANT_STUDENT_STATUS_I_20',
  'CERTIFICATE_OF_INDIAN_STATUS_CARD',
  'CERTIFICATE_OF_NATURALIZATION',
  'CERTIFICATE_STATEMENT_FROM_A_U_S_CONSULAR_OFFICIAL',
  'CERTIFICATION_OF_BIRTH_ABROAD',
  'CERTIFICATION_OF_REPORT_OF_BIRTH',
  'CHECK_STUB_S',
  'CLINIC_DOCTOR_OR_HOSPITAL_RECORDS_FOR_SERVICES_PROVIDED_IN_THE_LAST_60_DAYS',
  'COMPLETED_EMPLOYER_COVERAGE_TOOL_AND_A_COVER_LETTER_SIGNED_BY_THE_EMPLOYER',
  'COMPLETED_EMPLOYER_COVERAGE_TOOL_LINK_AND_A_COVER_LETTER_SIGNED_BY_THE_EMPLOYER_PARTICIPATING_IN_THE_SHOP',
  'CONSULAR_REPORT_OF_BIRTH_ABROAD',
  'COPY_OF_A_CHECK_PAID_TO_THE_HOUSEHOLD_MEMBER',
  'COST_OF_LIVING_ADJUSTMENT_LETTER_AND_OTHER_BENEFIT_VERIFICATION_NOTICES',
  'COURT_OR_AGENCY_RECORDS_FOR_ALIMONY_PAYMENTS',
  'COURT_RECORDS_FOR_ALIMONY_AND_RECORDS_OF_AGENCY_THROUGH_WHICH_ALIMONY_IS_PAID',
  'CURRENT_ORDERS_SHOWING_ACTIVE_DUTY_IN_THE_ARMY_NAVY_AIR_FORCE_MARINE_CORPS_OR_COAST_GUARD',
  'DEFERRED_COMPENSATION_PAYMENTS',
  'DIVORCE_DECREE',
  'DOCUMENT_INDICATING_A_MEMBER_OF_A_FEDERALLY_RECOGNIZED_INDIAN_TRIBE_OR_AMERICAN_INDIAN_BORN_IN_CANADA',
  'DOCUMENT_ISSUED_BY_BIA_RECOGNIZING_INDIVIDUAL_AS_AMERICAN_INDIAN',
  'DOCUMENT_ISSUED_BY_HIS_INDICATING_INDIVIDUAL_IS_WAS_ELIGIBLE_FOR_HIS_SERVICES_AS_AN_AMERICAN_INDIAN',
  'DOCUMENT_LISTING_LOAN_PROCEEDS',
  'DOCUMENT_OR_LETTER_FROM_SOCIAL_SECURITY_ADMINISTRATION',
  'DOCUMENT_OR_LETTER_FROM_SOCIAL_SECURITY_ADMINISTRATION_SSA',
  'DOCUMENT_SHOWING_DEFERRED_COMPENSATION_PAYMENT_S',
  'DOCUMENTATION_OF_PRIZES_SETTLEMENTS_OR_AWARDS_INCLUDING_COURT_ORDERED_AWARDS',
  'DOCUMENTATION_SHOWING_INHERITANCE_OF_CASH_OR_PROPERTY',
  'DRIVER_S_LICENSE',
  'EARLY_U_S_SCHOOL_RECORD',
  'EDGE_SERVER_SUPPORTING_DOCUMENT',
  'EHB_TEMPLATE',
  'EMPLOYER_IDENTIFICATION_CARD',
  'EMPLOYMENT_AUTHORIZATION_CARD_I_766',
  'EVIDENCE_SHOWING_FOREIGN_BORN_IN_THE_CANAL_ZONE_OR_REPUBLIC_OF_PANAMA',
  'EVIDENCE_SHOWING_FOREIGN_BORN_TO_U_S_CITIZEN_PARENT_S',
  'FEDERAL_DRIVERS_LICENSE',
  'FFE_XML',
  'FINAL_U_S_ADOPTION_DECREE',
  'FINANCIAL_AID_TRANSCRIPT_FORM_FOR_CURRENT_SEMESTER_SCHOOL_YEAR',
  'FOREIGN_PASSPORT',
  'FORM_1040_ES_FROM_LAST_90_DAYS',
  'FORM_1040_SCHEDULE_C_RENTS_OR_E_ROYALTIES',
  'FORM_1040_SE_WITH_SCHEDULE_C_F_OR_SE_FOR_SELF_EMPLOYMENT_INCOME',
  'FORM_1065_SCHEDULE_K1_WITH_SCHEDULE_E',
  'FORM_1099_G',
  'FORM_1099_MISC',
  'FORM_1099_R',
  'FORM_SSA_1099_SOCIAL_SECURITY_BENEFITS_STATEMENT',
  'G845_FORM',
  'G845_SUPPLEMENT_FORM',
  'GIFTS_AND_CONTRIBUTIONS',
  'GOVERNMENT_MAIL_SSA_STATEMENT_DMV_NOTICE',
  'HIGH_SCHOOL_OR_COLLEGE_DIPLOMA',
  'I_688_TEMPORARY_RESIDENT_CARD',
  'I_688A_EMPLOYMENT_AUTHORIZATION_CARD',
  'I_688B_EMPLOYMENT_AUTHORIZATION_DOCUMENT',
  'I_94_ARRIVAL_DEPARTURE_RECORD_IN_UNEXPIRED_FOREIGN_PASSPORT',
  'IDENTIFICATION_CARD_ISSUED_BY_THE_FEDERAL_STATE_OR_LOCAL_GOVERNMENT',
  'INDIAN_CENSUS_DOCUMENT',
  'INHERITANCES_IN_CASH_OR_PROPERTY',
  'INSURANCE_APPLICATION_ELIGIBILITY_NOTICE',
  'INTERESTS_AND_DIVIDENDS_INCOME',
  'LAST_YEAR_S_TAX_RETURN',
  'LEASE_AGREEMENT',
  'LEDGER_OR_OTHER_RECORDS_SHOWING_DATES_AND_TOTAL_AMOUNT_RECEIVED',
  'LEGAL_DOCUMENTS_THAT_ESTABLISH_AMOUNT_AND_FREQUENCY_OF_ALIMONY',
  'LETTER_FROM_AN_EMPLOYER_PARTICIPATING_IN_THE_SHOP_THAT_SAYS_THE_EMPLOYEE_ISN_T_OFFERED_COVERAGE',
  'LETTER_FROM_AN_EMPLOYER_THAT_SAYS_THE_EMPLOYEE_DOESN_T_QUALIFY_FOR_ANY_COVERAGE_THAT_MEETS_THE_MINIMUM_VALUE_STANDARD',
  'LETTER_FROM_EMPLOYER',
  'LETTER_FROM_EMPLOYER_OR_CONTRACT_WITH_EMPLOYER',
  'LETTER_FROM_GOVERNMENT_AGENCY_FOR_UNEMPLOYMENT_BENEFITS',
  'LETTER_FROM_HEALTH_INSURER_INCLUDING_COVERAGE_TERMINATION_DATE',
  'LETTER_FROM_PEACE_CORPS',
  'LETTER_FROM_THE_EMPLOYER_ABOUT_THE_EMPLOYER_S_COVERAGE',
  'LETTER_FROM_VETERANS_ADMINISTRATION',
  'LETTER_OR_STATEMENT_OF_CHILDREN_S_HEALTH_INSURANCE_PROGRAM_CHIP_BENEFITS',
  'LETTER_OR_STATEMENT_OF_MEDICAID_BENEFITS',
  'LETTER_OR_STATEMENT_OF_MEDICARE_BENEFITS',
  'MACHINE_READABLE_IMMIGRANT_VISA_WITH_IR3_OR_IH3_CODE',
  'MACHINE_READABLE_IMMIGRANT_VISA_WITH_TEMPORARY_I_551_LANGUAGE',
  'MANIFEST_FILE',
  'MARRIAGE_CERTIFICATE',
  'MILITARY_DEPENDENT_IDENTIFICATION_CARD',
  'MILITARY_LES',
  'MILITARY_RECORD',
  'MONEY_FROM_THE_SALE_EXCHANGE_OR_REPLACEMENT_OF_THINGS_YOU_OWN',
  'MORTGAGE_DEED_SHOWING_PRIMARY_RESIDENCY',
  'MORTGAGE_PAYMENT_RECEIPT',
  'MOST_RECENT_QUARTERLY_OR_YEAR_TO_DATE_PROFIT_AND_LOSS_STATEMENT',
  'NCQA_CDM_XML',
  'NCQA_FFE_XML',
  'NORTHERN_MARIANA_CARD_I_873',
  'NOTICE',
  'NOTICE_OF_ACTION_I_797',
  'NS1_MEMBERS_OF_A_FEDERALLY_RECOGNIZED_INDIAN_TRIBE',
  'NS2_NON_CITIZEN_WHO_IS_LAWFULLY_PRESENT_IN_AMERICAN_SAMOA_UNDER_THE_IMMIGRATION_LAWS_OF_AMERICAN_SAMOA',
  'NS4_NON_CITIZEN_WHO_IS_LAWFULLY_PRESENT_IN_AMERICAN_SAMOA',
  'OFFICE_OF_REFUGEE_RESETTLEMENT_ORR_ELIGIBILITY_LETTER_IF_UNDER_18',
  'OFFICIAL_RELEASE_PAPERS_FROM_INSTITUTION',
  'OT1_CERTIFICATION_FROM_HHS_ORR',
  'OT2_CUBAN_HAITIAN_ENTRANT',
  'OT3_DOCUMENT_INDICATING_WITHHOLDING_OF_REMOVAL',
  'OT4_ADMINISTRATIVE_STAY_BY_DHS',
  'OTHER_DOCUMENT',
  'OTHER_DOCUMENTATION_FROM_THE_EMPLOYER_PARTICIPATING_IN_THE_SHOP_THAT_SAYS_THAT_THE_EMPLOYEE_ISN_T_ELIGIBLE_FOR_HEALTH_COVERAGE',
  'OTHER_DOCUMENTATION_FROM_THE_EMPLOYER_THAT_SAYS_THE_EMPLOYEE_DOESN_T_QUALIFY_FOR_ANY_COVERAGE_THAT_MEETS_THE_MINIMUM_VALUE_STANDARD',
  'OTHER_DOCUMENTATION_FROM_THE_EMPLOYER_WITH_THE_NEW_PREMIUM_AMOUNT_AND_HOW_OFTEN_THE_EMPLOYEE_WOULD_PAY_THE_AMOUNT',
  'PAY_STUB',
  'PENSIONS_FROM_ANY_GOVERNMENT_OR_PRIVATE_SOURCE',
  'PERMANENT_RESIDENT_CARD_I_551',
  'PLAN_METADATA',
  'POLICY',
  'PRESCRIPTION_DRUG_TEMPLATE',
  'PRIZES_SETTLEMENTS_AND_AWARDS_INCLUDING_COURT_ORDERED_AWARDS',
  'PROCEEDS_OF_A_LOAN',
  'PROOF_OF_GAMBLING_WINNINGS',
  'PROOF_OF_TRIBAL_INCOME',
  'PROPERTY_DEED_OR_TITLE',
  'RECENT_CHECK_STUB_SHOWING_CURRENT_AMOUNT_AND_FREQUENCY_OF_AMOUNT',
  'RECENT_PAYSTUB_S',
  'REENTRY_PERMIT_I_327',
  'REFUGEE_TRAVEL_DOCUMENT',
  'RENT_RECEIPT_FROM_THE_LAST_60_DAYS',
  'RESIDENT_OF_AMERICAN_SAMOA_CARD',
  'RESIDUALS',
  'ROYALTIES',
  'SBC_SUMMARY_OF_BENEFIT_COVERAGE',
  'SCHOOL_ENROLLMENT_DOCUMENTATION',
  'SCHOOL_IDENTIFICATION_CARD',
  'SELF_EMPLOYED_LEDGER',
  'SEVERANCE_PAY',
  'SICK_PAY',
  'SIGNED_AFFIDAVIT_SAYING_THAT_THE_APPLICANT_LIVES_IN_THE_COMMUNITY',
  'SIGNED_TIME_SHEETS_AND_RECEIPT_OF_PAYROLL',
  'SIGNED_TIME_SHEETS_AND_RECEIPT_OF_PAYROLL_IF_YOU_HAVE_EMPLOYEES',
  'SOCIAL_SECURITY_CARD',
  'SPD_SUMMARY_PLAN_DESCRIPTION',
  'STATE_ID',
  'STATE_VOTER_IDENTIFICATION_CARD',
  'STATEMENT_FROM_ADOPTION_AGENCY',
  'STATEMENT_OF_HEALTH_BENEFITS',
  'STATEMENT_SHOWING_CURRENT_PENSION_ANNUITY_OR_RETIREMENT_AMOUNT_AND_FREQUENCY_OF_AMOUNT',
  'STRIKE_PAY_AND_OTHER_BENEFITS_FROM_UNIONS',
  'SUBSTITUTE_ASSISTANT_PAY',
  'TAX_FORM_S',
  'TAX_RETURN',
  'TAX_RETURN_FORMS',
  'TEMPORARY_I_551_STAMP_ON_PASSPORT_OR_I_94_I_94A',
  'TRAVEL_BUSINESS_REIMBURSEMENT_PAY',
  'TRIBAL_CARD',
  'TRIBAL_DOCUMENTATION_THAT_DEMONSTRATES_MEMBERSHIP_IN_A_FEDERALLY_RECOGNIZED_TRIBE',
  'U_S_AMERICAN_INDIAN_ALASKA_NATIVE_TRIBAL_ENROLLMENT_DOCUMENTATION_TRIBAL_CENSUS_RECORD_MUST_CONTAIN_BIOGRAPHICAL_INFORMATION',
  'U_S_CITIZEN_IDENTIFICATION_CARD',
  'U_S_CIVIL_SERVICE_EMPLOYMENT_RECORD',
  'U_S_COAST_GUARD_MERCHANT_MARINER_CARD',
  'U_S_MILITARY_DRAFT_CARD_OR_DRAFT_RECORD',
  'U_S_PASSPORT',
  'U_S_PUBLIC_BIRTH_RECORD',
  'U_S_RELIGIOUS_RECORD',
  'URAC_CDM_XML',
  'URAC_FFE_XML',
  'UTILITY_BILL',
  'VACATION_PAY',
  'VETERAN_S_DISCHARGE_CERTIFICATE_SHOWING_HONERABLE_DISCHARGE',
  'WAGES_AND_TAX_STATEMENT_W_2',
  'WORKER_S_COMPENSATION',
  'WORKER_S_COMPENSATION_DOCUMENT_OR_CHECK_STUB',
].map((e) => ({ value: e }));

/**
 * These document types are sorted based on
 * https://www.healthcare.gov/help/how-do-i-resolve-an-inconsistency/
 */
const dmiDocsByCategory = {
  CITIZENSHIP: [
    'U_S_PASSPORT',
    'CERTIFICATE_OF_NATURALIZATION',
    'CERTIFICATE_OF_CITIZENSHIP',
    'DRIVER_S_LICENSE',
    'DOCUMENT_FROM_A_FEDERALLY_RECOGNIZED_INDIAN_TRIBE',
    'OTHER_DOCUMENT',
  ],
  IMMIGRATION_STATUS: [
    'PERMANENT_RESIDENT_GREEN_CARD',
    'REENTRY_PERMIT',
    'REFUGEE_TRAVEL_DOCUMENT',
    'EMPLOYMENT_AUTHORIZATION_CARD',
    'MACHINE_READABLE_IMMIGRANT_VISA',
    'TEMPORARY_I_551_STAMP_ON_PASSPORT_OR_I_94_I_94A',
    'ARRIVAL_DEPARTURE_RECORD',
    'ARRIVAL_DEPARTURE_RECORD_IN_FOREIGN_PASSPORT',
    'OTHER_DOCUMENT',
  ],
  HOUSEHOLD_INCOME: [
    'FORM_1040_TAX_RETURN',
    'W2_AND_OR_1099S',
    'PAY_STUB_DOCUMENTATION',
    'SOCIAL_SECURITY_STATEMENTS',
    'UNEMPLOYMENT_BENEFITS_LETTER',
    'SELF_EMPLOYMENT_DOCUMENTATION',
    'OTHER_DOCUMENT',
  ],
  INCARCERATION: [
    'OFFICIAL_RELEASE_PAPERS',
    'DOCUMENT_SHOWING_EMPLOYMENT_PROOF_OF_RESIDENCE',
    'SIGNED_NOTARIZED_AFFIDAVIT',
    'PAROLE_PAPERS',
    'OTHER_DOCUMENT',
  ],
  NATIVE: [
    'TRIBAL_ENROLLMENT_MEMBERSHIP_CARD',
    'DOCUMENT_ISSUED_BY_BIA',
    'AUTHENTIC_DOCUMENT_FROM_A_TRIBE_DECLARING_MEMBERSHIP',
    'CERTIFICATE_OF_DEGREE_OF_INDIAN_BLOOD',
    'CERTIFICATE_OF_INDIAN_STATUS_CARD',
    'FORM_I_872_AMERICAN_INDIAN_CARD',
    'U_S_AMERICAN_INDIAN_ALASKA_NATIVE_TRIBAL_ENROLLMENT_OR_SHAREHOLDER_DOCUMENTATION',
    'LETTER_FROM_THE_MARKETPLACE_GRANTING_A_TRIBAL_EXEMPTION',
  ],
  MEC: ['LETTER_FROM_HEALTH_INSURER', 'STATEMENT_OF_HEALTH_BENEFITS', 'OTHER'],
  ESCMEC: ['LETTER_FROM_EMPLOYER', 'STATEMENT_OF_HEALTH_BENEFITS', 'OTHER'],
  MEDICAID_CHIP: ['LETTER_FROM_MEDICAID', 'LETTER_FROM_CHIP', 'OTHER'],
  TRICARE: ['LETTER_FROM_TRICARE', 'OTHER'],
  VA: ['LETTER_FROM_VA', 'OTHER'],
  MEDICARE: ['LETTER_FROM_MEDICARE', 'OTHER'],
  PEACE_CORPS: ['LETTER_FROM_PEACE_CORPS', 'OTHER'],
  SSN: ['SOCIAL_SECURITY_CARD', 'TAX_FORM_S', 'BENEFIT_OR_INCOME_STATEMENT', 'OTHER_DOCUMENT'],
};

const toObject = (k) => ({ value: k });

const subDocumentTypes = {
  CITIZENSHIP: {
    DOCUMENT_FROM_A_FEDERALLY_RECOGNIZED_INDIAN_TRIBE: [
      'TRIBAL_ENROLLMENT_MEMBERSHIP_CARD',
      'CERTIFICATE_OF_DEGREE_OF_INDIAN_BLOOD',
      'NATIVE_AMERICAN_TRIBAL_DOCUMENT',
      'LETTER_FROM_THE_MARKETPLACE_GRANTING_A_TRIBAL_EXEMPTION',
    ].map(toObject),
  },
  SSN: {
    // `-` suffixes are for reusing the same enum with different copy
    // we remove it before saving
    TAX_FORM_S: ['TAX_FORM_S-W4', 'TAX_FORM_S-1095', 'FORM_1040_TAX_RETURN', 'W2_AND_OR_1099S'].map(
      toObject,
    ),
    BENEFIT_OR_INCOME_STATEMENT: [
      'SOCIAL_SECURITY_STATEMENTS',
      'PAY_STUBS',
      'UNEMPLOYMENT_BENEFITS_LETTER',
    ].map(toObject),
    OTHER: [
      'U_S_MILITARY_CARD_OR_DRAFT_RECORD-RECORD',
      'U_S_MILITARY_CARD_OR_DRAFT_RECORD-CARD',
      'MILITARY_DEPENDENT_S_IDENTIFICATION_CARD',
      'COURT_ORDER',
      'DIVORCE_DECREE',
    ].map(toObject),
  },
};
/**
 * Here we map dmiTypes to document categories
 */
const dmiTypesToCategory = {
  INCARCERATION: dmiDocsByCategory.INCARCERATION,
  TRIBE_MEMBERSHIP: dmiDocsByCategory.NATIVE,
  NONESCMEC: dmiDocsByCategory.MEC,
  ANNUAL_INCOME: dmiDocsByCategory.HOUSEHOLD_INCOME,
  CITIZENSHIP: dmiDocsByCategory.CITIZENSHIP,
  QHP_LAWFUL_PRESENCE: dmiDocsByCategory.IMMIGRATION_STATUS,
  MEDICAID_LAWFUL_PRESENCE: dmiDocsByCategory.IMMIGRATION_STATUS,
  ESCMEC: dmiDocsByCategory.ESCMEC,
  SSN: dmiDocsByCategory.SSN,
  IMMIGRATION_STATUS: dmiDocsByCategory.IMMIGRATION_STATUS,
};

/**
 * DMI notice types
 */

const dmiNoticeTypes = {
  CLOCK_EXPIRATION_NOTICE: 'CLOCK_EXPIRATION_NOTICE',
};

/**
 * sviTypes are already cleanly mapped to categories
 */
const sviDocsByCategory = {
  MOVED_TO_NEW_SERVICE_AREA: [
    'BILLS_FINANCIAL_STATEMENT_AT_NEW_ADDRESS',
    'U_S_POSTAL_SERVICE_CHANGE_OF_ADDRESS_LETTER',
    'MORTGAGE_RENTAL_DOCUMENT_AT_NEW_ADDRESS',
    'LETTER_FROM_GOVERNMENT_ORGANIZATION_SHOWING_CHANGE_OF_ADDRESS',
    'DOCUMENT_FROM_AN_EMPLOYER',
    'HEALTH_CARE_PROGRAM_DOCUMENT',
    'LETTER_OF_EXPLANATION',
    'ARRIVAL_DEPARTURE_RECORD_I_94_I_94A',
    'PASSPORT',
    'LETTER_FROM_AN_INSURANCE_COMPANY',
    'OTHER_DOCUMENT',
  ],
  MEC_LOSS: [
    'LETTER_FROM_AN_INSURANCE_COMPANY',
    'LETTER_FROM_EMPLOYER',
    'LETTER_ABOUT_COBRA_COVERAGE',
    'HEALTH_CARE_PROGRAM_DOCUMENT',
    'PAY_STUB',
    'LETTER_FOR_LOSS_OF_STUDENT_COVERAGE',
    'DOCUMENT_FOR_COVERAGE_LOSS_DUE_TO_DIVORCE_SEPARATION',
    'DOCUMENT_FOR_COVERAGE_LOSS_DUE_TO_DEATH',
    'LETTER_OF_EXPLANATION',
    'OTHER_DOCUMENT',
  ],
  ADOPTION: [
    'ADOPTION_LETTER_OR_RECORD',
    'FOSTER_CARE_PAPERS',
    'COURT_ORDER',
    'LEGAL_GUARDIANSHIP_DOCUMENT',
    'MEDICAL_SUPPORT_ORDER',
    'DHS_IMMIGRATION_DOCUMENT',
    'LETTER_OF_EXPLANATION',
    'OTHER_DOCUMENT',
  ],
  MARRIAGE: [
    'MARRIAGE_CERT',
    'MARRIAGE_LICENSE',
    'OFFICIAL_PUBLIC_RECORD',
    'AFFIDAVIT',
    'RELIGIOUS_DOCUMENT',
    'LETTER_OF_EXPLANATION',
    'OTHER_DOCUMENT',
  ],
  SEP_GRANTED_FOR_MDCAID_DENIAL: [
    'DENIAL_LETTER_FROM_YOUR_STATE',
    'LETTER_STATING_LOST_COVERAGE',
    'MARKETPLACE_LETTER',
    'SCREENSHOT_OF_RESULTS',
    'LETTER_OF_EXPLANATION',
    'OTHER_DOCUMENT',
  ],
};

const taxIncomeDiffReasons = [
  {
    id: 'INCOME_DIFF_REASON_HOUSEHOLD_MEMBER_CHANGE',
    value: 'HOUSEHOLD_MEMBER_CHANGE',
  },
  {
    id: 'INCOME_DIFF_REASON_SELF_EMPLOYMENT',
    value: 'SELF_EMPLOYMENT_INCOME_FLUCTUATION',
  },
  { id: 'INCOME_DIFF_REASON_JOB_CHANGE', value: 'JOB_CHANGE' },
  {
    id: 'INCOME_DIFF_REASON_WORK_LOCATION_CHANGE',
    value: 'WORK_LOCATION_CHANGE',
  },
  { id: 'INCOME_DIFF_REASON_OTHER', value: 'OTHER' },
];

const taxIncomeLowerReasons = [
  {
    id: 'INCOME_DIFF_REASON_HOUSEHOLD_MEMBER_CHANGE',
    value: 'HOUSEHOLD_MEMBER_CHANGE',
  },
  {
    id: 'INCOME_DIFF_REASON_SELF_EMPLOYMENT',
    value: 'SELF_EMPLOYMENT_INCOME_FLUCTUATION',
  },
  { id: 'INCOME_DIFF_REASON_JOB_CHANGE_LOWER', value: 'JOB_CHANGE' },
  { id: 'INCOME_DIFF_REASON_OTHER', value: 'OTHER' },
];

const employmentIncomeDiffReasons = [
  { value: 'STOP_WORKING', id: 'EMPLOYER_INCOME_DIFF_REASON_STOP_WORKING' },
  { value: 'DECREASE_HOURS', id: 'EMPLOYER_INCOME_DIFF_REASON_DECREASE_HOURS' },
  { value: 'CHANGE_JOBS', id: 'EMPLOYER_INCOME_DIFF_REASON_CHANGE_JOBS' },
  { value: 'WAGE_CUT', id: 'EMPLOYER_INCOME_DIFF_REASON_WAGE_CUT' },
  { value: 'OTHER', id: 'EMPLOYER_INCOME_DIFF_REASON_OTHER' },
];

const coverageEndReasons = [
  { value: 'INSURANCE_TOO_EXPENSIVE' },
  { value: 'PARENT_NO_LONGER_WORKS_FOR_THE_COMPANY' },
  { value: 'COMPANY_STOPPED_OFFERING_INSURANCE' },
  { value: 'COMPANY_STOPPED_OFFERING_INSURANCE_TO_DEPENDENTS' },
  { value: 'MEDICAL_NEEDS_NOT_COVERED' },
  { value: 'OTHER' },
];

const helpUrls = {
  INCARCERATION: 'https://help.catch.co/en/articles/3383248-incarceration-status',
  TRIBE_MEMBERSHIP:
    'https://help.catch.co/en/articles/3383253-american-indian-or-alaska-native-status',
  NONESCMEC: 'https://help.catch.co/en/articles/3383261-no-minimal-essential-job-based-coverage',
  ANNUAL_INCOME: 'https://help.catch.co/en/articles/3383245-household-income',
  CITIZENSHIP: 'https://help.catch.co/en/articles/3383197-citizenship',
  QHP_LAWFUL_PRESENCE: 'https://help.catch.co/en/articles/3383237-immigration-status',
  MEDICAID_LAWFUL_PRESENCE:
    'https://help.catch.co/en/articles/3383264-no-coverage-through-medicaid-or-the-children-s-health-insurance-program-chip',
  ESCMEC: 'https://help.catch.co/en/articles/3383321-losing-qualifying-health-coverage',
  SSN: 'https://help.catch.co/en/articles/3383278-social-security-number-ssn',
  MOVED_TO_NEW_SERVICE_AREA:
    'https://help.catch.co/en/articles/3383313-permanently-moving-to-a-new-home-and-had-prior-coverage',
  MEC_LOSS: 'https://help.catch.co/en/articles/3383321-losing-qualifying-health-coverage',
  ADOPTION:
    'https://help.catch.co/en/articles/3383326-adopting-a-child-foster-care-placement-or-court-order',
  MARRIAGE: 'https://help.catch.co/en/articles/3383325-getting-married',
  SEP_GRANTED_FOR_MDCAID_DENIAL:
    'https://help.catch.co/en/articles/3383331-medicaid-or-chip-denial',
};

const constants = {
  languages,
  races,
  specificOrigins,
  sex,
  roles,
  binary,
  phoneTypes,
  maritalStatus,
  incomeTypes,
  deductionTypes,
  frequencyTypes,
  jobFrequencyTypes,
  medicaidCHIPNames,
  coverageTypes,
  contactMethods,
  mailingAddressOptions,
  sviDocumentTypes,
  dmiDocumentTypes,
  dmiDocsByCategory,
  dmiNoticeTypes,
  sviDocsByCategory,
  dmiTypesToCategory,
  subDocumentTypes,
  renewalYears,
  taxIncomeDiffReasons,
  taxIncomeLowerReasons,
  employmentIncomeDiffReasons,
  coverageEndReasons,
  proAssistanceBinary,
  proAssistantTypes,
  helpUrls,
  stateExchanges,
  applicationAssistorTypes,
  immigrationDocumentTypes,
  lawfulPresenceDocumentTypes,
};
export default constants;
