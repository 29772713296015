import gql from 'graphql-tag';
import { lastFour } from '@app/utils';

const institution = gql`
  fragment Institution on PlaidBank {
    id: bankID
    logo
    primaryColor
    url
  }
`;

const bankAccount = gql`
  fragment BankAccount on Account {
    id
    isPrimary
    isWatched
    accountName: name
    accountNumber
    balance
  }
`;

const bankLink = gql`
  fragment BankLinkPartial on BankLink {
    id
    bankName
    syncStatus
    errorCode
    lastUpdated
    providerType
  }
`;

const primaryAccount = gql`
  fragment PrimaryAccount on Account {
    id
    ...BankAccount
    bankLink {
      id
      providerType
      ...BankLinkPartial
      plaidBank {
        ...Institution
      }
    }
  }
  ${institution}
  ${bankLink}
  ${bankAccount}
`;

const pendingAccount = gql`
  fragment PendingAccount on PendingAccount {
    id
    isPrimary
    accountName: name
    accountNumber: mask
    bankName: institutionName
  }
`;

const formatPendingAccount = (account) => ({
  id: account.id,
  isPending: true,
  isPrimary: account.isPrimary,
  accountName: account.accountName,
  accountNumber: account.accountNumber,
  lastDigits: lastFour(account.accountNumber),
  bankName: account.bankName,

  // other fields like logo, primaryColor, institutionId are undefined
});

const formatPendingBank = (account) => ({
  id: account.id,
  isPending: true,
  numAccounts: 1,
  primaryAccount: account.isPrimary ? formatPendingAccount(account) : false,
  bankName: account.bankName,
  accounts: [formatPendingAccount(account)],
});

const formatInstitution = (bankLink) => ({
  bankName: bankLink.bankName,
  logo: bankLink.plaidBank.logo,
  primaryColor: bankLink.plaidBank.primaryColor,
  institutionId: bankLink.plaidBank.id,
  bankUrl: bankLink.plaidBank.url,
});

// wherever the bank link comes from  (either from parent or as child)
// use it to format the account
const formatAccount = (account, bankLink) => ({
  id: account?.id,
  isPending: false,
  isPrimary: account?.isPrimary,
  isWatched: account?.isWatched,
  accountName: account?.accountName,
  accountNumber: account?.accountNumber,
  lastDigits: lastFour(account?.accountNumber),
  bankLinkId: bankLink?.id,
  syncStatus: bankLink?.syncStatus,
  errorCode: bankLink?.errorCode,
  numAccounts: bankLink?.accounts?.length,
  balance: account?.balance,
  ...formatInstitution(bankLink),
});

const formatBank = (bankLink) => {
  const index = bankLink.accounts?.findIndex(({ isPrimary }) => isPrimary);

  return {
    id: bankLink.id,
    bankLinkId: bankLink.id,
    isPending: false,
    numAccounts: bankLink.accounts.length,
    hasPrimaryAccount: index > -1,
    primaryAccount: index > -1 ? bankLink.accounts[index] : false,
    syncStatus: bankLink.syncStatus,
    errorCode: bankLink.errorCode,
    plaidBank: bankLink.plaidBank,
    ...formatInstitution(bankLink),
    accounts: bankLink.accounts.map((account) => formatAccount(account, bankLink)),
  };
};

const formatPrimaryAccount = (primaryAccount) => {
  if (!primaryAccount) return false;
  return formatAccount(primaryAccount, primaryAccount.bankLink);
};

export default {
  institution,
  bankAccount,
  bankLink,
  pendingAccount,
  primaryAccount,
  formatPrimaryAccount,
  formatPendingAccount,
  formatPendingBank,
  formatAccount,
  formatBank,
};
