import { Amplify, Auth } from 'aws-amplify';

import { formatAliasForCognito } from '@app/forms_old/validators/alias';
import Env from '@app/utils/env';
import Log from '@app/utils/logger';

Amplify.configure({
  Auth: {
    userPoolId: Env.authCreds.userPoolId,
    userPoolWebClientId: Env.authCreds.userPoolWebClientId,
  },
});

export async function signIn({ alias, password }) {
  const formattedAlias = formatAliasForCognito(alias);
  try {
    return await Auth.signIn(formattedAlias, password);
  } catch (e) {
    console.error(e);
    Auth.configure({
      authenticationFlowType: 'USER_PASSWORD_AUTH',
    });

    return await Auth.signIn(formattedAlias, password);
  }
}

/**
 * This method is used to get the JWT token
 */
export async function getJWTToken() {
  return Auth.currentSession()
    .then((session) => session.getIdToken().getJwtToken())
    .catch((err) => console.log('No user in session'));
}

/**
 * This method is used for any platform-side Cognito calls
 */
export async function getCognitoAccessToken() {
  return Auth.currentSession()
    .then((session) => session.getAccessToken().getJwtToken())
    .catch((err) => console.log('No user in session'));
}

export async function signUp({ alias, password, ...attrs }) {
  const formattedAlias = formatAliasForCognito(alias);
  let attributes = {};

  Object.keys(attrs).map((attr) => (attributes['custom:' + attr] = attrs[attr]));

  return await Auth.signUp({
    username: formattedAlias,
    password,
    autoSignIn: false,
  });
}

export function confirmSignUp({ alias, code }) {
  const formattedAlias = formatAliasForCognito(alias);
  return Auth.confirmSignUp(formattedAlias, code, {
    forceAliasCreation: true,
  })
    .then((user) => true)
    .catch((err) => {
      Log.debug(err);

      // If the error indicates we're already registered, we should
      // resolve the result and be fine with it
      if (
        err.code === 'NotAuthorizedException' &&
        err.message.includes('Current status is CONFIRMED')
      ) {
        return true;
      }

      throw err;
    });
}

export function signOut() {
  return Auth.signOut();
}

export function forgotPassword(alias) {
  const formattedAlias = formatAliasForCognito(alias);
  return Auth.forgotPassword(formattedAlias);
}

export function confirmForgotPassword({ alias, code, newPassword }) {
  const formattedAlias = formatAliasForCognito(alias);
  return Auth.forgotPasswordSubmit(formattedAlias, code, newPassword);
}

export function resendAuthCode(alias) {
  const formattedAlias = formatAliasForCognito(alias);
  return Auth.resendSignUp(formattedAlias);
}

export function getUserAttributes(user) {
  return new Promise((resolve, reject) => {
    user.getUserAttributes((err, result) => {
      if (err) {
        reject(err);
      }
      Log.debug('attributes cb');
      if (result) {
        const toConvertToInt = ['annualIncome', 'spouseIncome'];
        const parsedResult = result.reduce((acc, attr) => {
          let name = attr.getName();
          if (/custom:/.test(name)) {
            name = name.split(':')[1];
          }

          if (name === 'phone_number') {
            acc['phoneNumber'] = attr.getValue();
          }

          if (toConvertToInt.includes(name)) {
            acc[name] = parseInt(attr.getValue(), 10);
          } else {
            acc[name] = attr.getValue();
          }
          return acc;
        }, {});
        resolve(parsedResult);
      }
    });
    /**
     * @BUG: we silently catch errors here as the cognito lib
     * seem to be calling this callback with an error randomly
     * even though the operation succeeded. We need to investigate
     * why exactly or whether this might cause any issue.
     */
  }).catch((err) => {});
}

export async function changePassword({ oldPassword, newPassword }) {
  const currentUser = await Auth.currentAuthenticatedUser();
  return await Auth.changePassword(currentUser, oldPassword, newPassword);
}
