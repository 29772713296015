import React from 'react';

import { ComplexRow } from '@uikit';
import { formatProvider } from '@app/utils';
import { Stack } from '@app/layouts';

const SelectedProvider = ({ doctor, remove }) => {
  return (
    <ComplexRow
      dismiss={remove}
      label={formatProvider(doctor?.name)}
      sublabel={doctor?.taxonomy}
      interaction="dismiss"
      onPress={remove}
      accessory="x"
    />
  );
};

/**
 * @todo should be merged with SelectedDrugs
 */
const SelectedProviders = ({ selected, values, deselect }) => {
  return (
    <Stack separatorComponent>
      {selected.map((npi, i) => (
        <SelectedProvider key={npi} remove={() => deselect(npi)} doctor={values[npi]?.data} />
      ))}
    </Stack>
  );
};

export default SelectedProviders;
