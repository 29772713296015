import React from 'react';
import { SheetComponentProps } from '@navigate';
import { SheetBlueprint } from '@blueprints';
import { ootIntl, useCopy } from '@app/utils';
import { useSnooze } from '@app/hooks';

const options = [
  { label: ootIntl.formatMessage({ id: 'catch.time.oneWeek' }), value: 7 },
  { label: ootIntl.formatMessage({ id: 'catch.time.twoWeeks' }), value: 14 },
  { label: ootIntl.formatMessage({ id: 'catch.time.indefinite' }), value: -1 },
];

const PauseGoalSheet: React.FC<SheetComponentProps['PAUSE_GOAL']> = ({ goal }) => {
  const { c } = useCopy('catch');
  const { snooze } = useSnooze(goal);

  return (
    <SheetBlueprint
      title={c('plan.pause.text')}
      actions={options.map((option) => ({
        testID: `snooze-${option.value}`,
        label: option.label,
        onAction: () => snooze({ numDaysPaused: option.value }),
      }))}
    />
  );
};

export default PauseGoalSheet;
