import React from 'react';
import { Copy, BackgroundColor, InteractionAccessoryType } from '@types';
import { Text } from '../Text';
import { Pressable } from '../Pressable';
import { s } from '@app/_ui-kit/theme/static';
import BaseRow from './BaseRow'; // parent
import { useDebug } from '@app/hooks/useDebug';
import { Spinner } from '@uikit';
import { useTheme } from '@app/_ui-kit/hooks/useTheme';
import { IconSvg } from '../Asset';

type TextColor = 'text' | 'link' | 'error';

export interface ActionRowProps {
  loading?: boolean;
  loadStyle?: 'spinner' | 'skeleton';
  testID: string;
  textColor?: TextColor;
  glyph?: any; // not sure how to type, but specifically a simple icon glyph
  label: Copy;
  errorLabel?: string;
  interaction?: InteractionAccessoryType;
  interactiveAppearance?: boolean;
  disabled?: boolean;
  disabledAppearance?: boolean;
  onLabelPress?: any;
  selectedBg?: BackgroundColor;
  selected?: boolean;
  selectedByDefault?: boolean;
  bold?: boolean;
  fp?: boolean;
  inset?: boolean;
  icon?: any;
  accentColor?: any;
}

/**
 * An always interactive row that usually lives in a Sheet as part of a Stack
 */
const ActionRow = ({
  loading,
  loadStyle = 'skeleton',
  testID,
  id,
  textColor,
  glyph,
  label,
  title,
  interaction,
  interactiveAppearance,
  disabled,
  disabledAppearance,
  onAction,
  onLabelPress,
  selectedBg,
  selectedByDefault,
  bold,
  inset,
  fp,
  icon,
  accentColor,
  hexColor,
}) => {
  const simple = !(interactiveAppearance || interaction || glyph || icon?.web);
  const { showTestIds } = useDebug();
  const { themeColors } = useTheme();
  return (
    <BaseRow
      inset={inset || !simple}
      loading={loading}
      testID={testID}
      selectedByDefault={selectedByDefault}
      selectedBg={selectedBg}
      interaction={interaction}
      disabled={disabled}
      onPress={onAction}
      squared
      asset={
        icon && icon?.web
          ? { icon: icon?.web, size: 'xs', color: 'subtle' }
          : glyph
          ? {
              element: (
                <IconSvg
                  svgComponent={glyph}
                  size="xs"
                  color={accentColor || textColor || 'subtle'}
                />
              ),
              size: 'xs',
            }
          : undefined
      }
      content={
        <Pressable
          testID={`${testID}-label`}
          handleOnPress={onLabelPress}
          style={[
            // ensures that the text only takes up as much space as it needs
            { alignSelf: !simple ? 'flex-start' : 'center' },
            // for when we want content to have opacity, but still be interactable
            disabledAppearance && s?.disabled,
          ]}
        >
          {loading && loadStyle === 'spinner' ? (
            <Spinner />
          ) : (
            <Text
              inline
              loading={loading}
              hex={themeColors[accentColor + 'Color'] || hexColor}
              color={accentColor || textColor || 'text'}
              size={fp ? 'fp' : simple ? 'h5' : 'btn'}
              weight={bold ? 'semibold' : simple ? 'medium' : 'medium'}
              underline={!!onLabelPress}
            >
              {showTestIds ? id || testID : title || label}
            </Text>
          )}
        </Pressable>
      }
    />
  );
};

export default ActionRow;
