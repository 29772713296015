import React from 'react';

import { routes } from '@navigate';
import { useLocale, useCopy } from '@app/utils';
import { Text, Asset } from '@uikit';
import { useAuth } from '@app/client/Auth';
import { Stack } from '@layouts';
import { useDeprecatedQuery } from '@data';

import Menu from './Menu';
import NavLink from './NavLink';

import User from '@svg/user.svg';

const AvatarMenu = ({ canToggleLanguage }) => {
  const { nextLanguage } = useLocale();
  const { signOut } = useAuth();
  const { loading, email, avatarImage, userID } = useDeprecatedQuery('user');
  const { c } = useCopy('catch.module.me');

  return (
    <Menu
      iconComponent={
        <Asset
          svg={!avatarImage && User}
          base64={avatarImage}
          id={userID}
          loading={loading}
          shape="circle"
          size={avatarImage ? 'md' : 'xs'}
          containerSize="md"
          bg="skeleton"
          bgHover="border"
        />
      }
    >
      <Stack spacing="0b">
        <Text size="l" weight="regular" color="subtle">
          {email}
        </Text>
        <>
          <NavLink action={{ type: 'ROUTE', route: routes.ACCOUNT_INFO }} stacked small>
            {c('MeView.infoLink')}
          </NavLink>
          <NavLink action={{ type: 'ROUTE', route: routes.LINKED_BANKS }} stacked small>
            {c('MeView.accountsLink')}
          </NavLink>
          <NavLink action={{ type: 'ROUTE', route: routes.ACCOUNT_SETTINGS }} stacked small>
            {c('MeView.settingsLink')}
          </NavLink>
          {/* <NavLink action={{ type: 'ROUTE', route: routes.REFERRAL }} stacked small>
            {c('MeView.referralLink')}
          </NavLink> */}
          <NavLink action={{ type: 'ROUTE', route: routes.STATEMENTS }} stacked small>
            {c('MeView.documentsLink')}
          </NavLink>
          {canToggleLanguage && (
            <NavLink action={{ type: 'TOGGLE_LANGUAGE' }} stacked small>
              {nextLanguage()}
            </NavLink>
          )}
          <NavLink action={{ type: 'HELP' }} stacked small>
            {c('MeView.helpLink')}
          </NavLink>
          <NavLink
            action={{ type: 'CUSTOM', onAction: signOut }}
            stacked
            small
            urgent
            color="credit"
          >
            {c('SignOutButton')}
          </NavLink>
        </>
      </Stack>
    </Menu>
  );
};

export default AvatarMenu;
