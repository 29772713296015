import React, { useCallback, useMemo, useState } from 'react';
import { AutomationType, IncomeWorkType } from '@types';
import { routes, ScreenDefinition } from '@navigate';
import { Layout, Section, Stack } from '@layouts';
import { SheetBlueprint } from '@app/blueprints';
import { Asset, Loading, Option, ActionSheet, OptionGroup } from '@uikit';
import { useIncomeSource } from '@hooks';
import { automationTypeOptions, workTypeOptions } from '@app/forms/config/options';
import {
  useQuery,
  queries,
  useMutation,
  mutations,
  UpsertIncomeAutomationRulesVars,
  UpsertIncomeAutomationRulesResponse,
  IncomeAutomationRuleQueryData,
  IncomeAutomationRuleQueryVars,
} from '@data';
import { IncomeSourceEvents } from './IncomeSourceEvents';

interface SourceDetailProps {
  sourceID: string;
}

const IncomeSourceDetailView: React.FC<SourceDetailProps> = ({ sourceID }) => {
  const [selectOption, setSelectOption] = useState<'WORK_TYPE' | 'AUTOMATION_TYPE' | undefined>();
  const { canFetchMore, fetchMore, querying, queryingTransactions, source, transactions } =
    useIncomeSource({
      sourceID,
    });

  const { loading, data, refetch } = useQuery<
    IncomeAutomationRuleQueryData,
    IncomeAutomationRuleQueryVars
  >(queries.INCOME_AUTOMATION_RULE, { fetchPolicy: 'cache-first', variables: { sourceID } });

  const rule = data?.viewer?.incomeAutomationRule;

  const onCompleted = useCallback(() => {
    refetch();
    setSelectOption(undefined);
  }, [refetch, setSelectOption]);

  const [upsertRules, { loading: updating }] = useMutation<
    UpsertIncomeAutomationRulesResponse,
    UpsertIncomeAutomationRulesVars
  >(mutations.UPSERT_INCOME_AUTOMATION_RULES, {
    onCompleted,
  });

  const options = useMemo(() => {
    return Object.values(selectOption === 'WORK_TYPE' ? workTypeOptions : automationTypeOptions);
  }, [selectOption]);

  const selectWorkType = (workType: IncomeWorkType) => {
    upsertRules({
      variables: {
        rules: [
          {
            incomeSourceID: sourceID,
            // for cases where rule hasn't been created yet
            automationType: rule?.automationType || 'SOMETIMES',
            workType,
            userHandlesActivation: true,
          },
        ],
      },
    });
  };

  const selectAutomationType = (automationType: AutomationType) => {
    upsertRules({
      variables: {
        rules: [
          {
            incomeSourceID: sourceID,
            automationType,
            userHandlesActivation: true,
          },
        ],
      },
    });
  };

  const asset = useMemo(() => Asset.configureSource(source), [source]);
  const title = useMemo(() => (source ? source.text || source.tag : 'Income Source'), [source]);

  return (
    <>
      <Layout.Scroll>
        <Layout margins topSpace>
          <Layout.Header
            title={title}
            asset={{ ...asset, size: 'lg' }}
            loading={querying}
            titleSize="page"
            align="center"
          />
          {loading ? (
            <Loading />
          ) : (
            <Stack>
              <Layout topSpace bottomSpace>
                <Stack spacing="+gap">
                  <Option
                    {...automationTypeOptions[rule?.automationType || 'SOMETIMES']}
                    accessory="chevron"
                    onPress={() => setSelectOption('AUTOMATION_TYPE')}
                    disabled
                  />
                  <Option
                    {...workTypeOptions[rule?.workType || 'WORK_TYPE_1099']}
                    accessory="chevron"
                    onPress={() => setSelectOption('WORK_TYPE')}
                    disabled
                  />
                </Stack>
              </Layout>
              <Layout>
                <Section title="Activity">
                  <IncomeSourceEvents
                    events={transactions}
                    querying={queryingTransactions}
                    hasMore={canFetchMore}
                    fetchMore={fetchMore}
                  />
                </Section>
              </Layout>
            </Stack>
          )}
        </Layout>
      </Layout.Scroll>
      <ActionSheet isOpen={!!selectOption} onRequestClose={() => setSelectOption(undefined)}>
        <SheetBlueprint>
          <OptionGroup
            onPress={selectOption === 'WORK_TYPE' ? selectWorkType : selectAutomationType}
            options={options}
            disabled={updating}
          />
        </SheetBlueprint>
      </ActionSheet>
    </>
  );
};

export const IncomeSourceDetail: ScreenDefinition = {
  name: routes.INCOME_SOURCE_DETAIL,
  component: IncomeSourceDetailView,
  options: {},
};
