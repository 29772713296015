import React from 'react';
import { View } from 'react-native';

import { Layout, Stack } from '@layouts';
import { Button, Link, useTheme } from '@uikit';
import { useCopy, formatPeople } from '@app/utils';
import { MembersAvatars } from '@app/partial';

/**
 * PlanSelectedSheet shows an action sheet after a plan has been selected for an enrollment group
 */
const PlanSelectedSheet = ({
  handleNextGroup,
  handleSkipGroup,
  handleDentalGroup,
  planName,
  currentMembers,
  orphanedMembers,
  nextGroup,
}) => {
  const { theme } = useTheme();
  const { c } = useCopy('catch.health');

  /**
   * show the option to continue if there are more members
   * (these can be either in another group or orphaned)
   */
  const showNextGroup =
    orphanedMembers?.length ||
    (nextGroup?.members?.length > 0 && nextGroup?.enrollmentType !== 'DENTAL_INSURANCE');

  /**
   * Prompts users with the full dental prompt
   * whenever we're at the end
   */
  const showDentalPrompt =
    nextGroup?.members?.length === 0 ||
    !nextGroup ||
    nextGroup?.enrollmentType === 'DENTAL_INSURANCE';

  const selected = (
    <View>
      <Layout.Header
        color="covered"
        title={c('planSelected')}
        subtitle={planName}
        graphic={<MembersAvatars members={currentMembers} currentIndex={currentMembers.length} />}
      />
    </View>
  );

  const next = (
    <View>
      <Layout.Header
        title={c('nextUp')}
        subtitle={c('chooseNext', {
          people: formatPeople({ people: nextGroup?.members || orphanedMembers }),
        })}
        graphic={
          <MembersAvatars members={nextGroup?.members || orphanedMembers} currentIndex={-1} />
        }
      />
      <View style={[theme.centered, theme.topGutter4]}>
        <Button wide onPress={handleNextGroup}>
          {c('viewPlans')}
        </Button>
        <Link
          color="text"
          style={theme.topGutter3}
          onPress={() => handleSkipGroup({ goToDental: false })}
        >
          {c('continueWithoutCoverage')}
        </Link>
        <Link
          color="text"
          style={theme.topGutter3}
          onPress={() => handleSkipGroup({ goToDental: true })}
        >
          {c('skipToDental')}
        </Link>
      </View>
    </View>
  );

  const dental = (
    <View>
      <Layout.Header title={c('dentalPromptTitle')} subtitle={c('dentalPromptSubtitle')} />
      <View style={[theme.centered, theme.topGutter4]}>
        <Button wide onPress={handleDentalGroup}>
          {c('viewDental')}
        </Button>
        <Link
          color="text"
          style={theme.topGutter3}
          onPress={() => handleSkipGroup({ goToDental: false })}
        >
          {c('noThanks')}
        </Link>
      </View>
    </View>
  );

  return (
    <Layout margins topSpace bottomSpace>
      <Stack separatorComponent spacing="4">
        {selected}
        {showNextGroup ? next : null}
        {showDentalPrompt ? dental : null}
      </Stack>
    </Layout>
  );
};

export default PlanSelectedSheet;
