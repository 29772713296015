import React from 'react';
import { View } from 'react-native';
import { Text } from '../Text';
import { Button } from '../Button';
import { useLayoutContext } from '@layouts';
import { useTheme } from '../../hooks/useTheme';
import Haptics from '../../tools/Haptics';
import Plus from '@svg/plus.svg';
import Minus from '@svg/minus.svg';

export interface StepperProps {
  value?: string;
  onChange: () => void;
  min?: number;
  max: number;
  step?: number;
  disabled?: boolean;
  disabledMax?: number;
  editable?: boolean;
  large?: boolean;
  style?: any;
  prefix?: any;
  suffix?: any;
  loading?: boolean;
  testID: string;
}

const Stepper = ({
  value = '0',
  onChange,
  min = 0,
  max,
  step = 1,
  disabled,
  disabledMax,
  editable = true,
  large,
  style,
  prefix,
  suffix,
  loading,
  testID,
}) => {
  const { theme } = useTheme();
  const context = useLayoutContext();

  const handleDecrease = () => {
    Haptics.impact('LIGHT');

    if (parseFloat(value) - step < 0) {
      return;
    }

    onChange(parseFloat(value) - step);
  };

  const handleIncrease = () => {
    Haptics.impact('LIGHT');
    onChange(parseFloat(value) + step);
  };

  const leftDisabled = parseFloat(value) === min || disabled;
  const rightDisabled = parseFloat(value) === max || disabled;

  return (
    <View style={[theme.row, !!large && { justifyContent: 'center' }, style]}>
      {editable ? (
        <Button
          testID="minusButton"
          svg={Minus}
          mini={!large}
          color="text"
          disabled={leftDisabled}
          accentColor={context?.accentColor ? context?.accentColor + 'Light' : 'brandLight'}
          onPress={leftDisabled ? undefined : handleDecrease}
        />
      ) : null}
      <View style={[theme.stepperGauge, large && theme.stepperGaugeLg]}>
        <Text
          loading={loading}
          size={large ? 'xl' : 'lg'}
          weight="bold"
          gutter={false}
          testID={testID}
          color="text"
          tabular
          inline
        >
          {!!prefix && prefix}
          {value}
          {!!suffix && suffix}
        </Text>
      </View>
      {editable ? (
        <Button
          testID="plusButton"
          svg={Plus}
          mini={!large}
          color="text"
          accentColor={context?.accentColor ? context?.accentColor + 'Light' : 'brandLight'}
          disabled={rightDisabled || disabledMax}
          onPress={rightDisabled ? undefined : handleIncrease}
        />
      ) : null}
    </View>
  );
};

export default Stepper;
