import { HealthAsyncStatus } from './../../types/HealthApplicationTypes';
import gql from 'graphql-tag';
import {
  ApolloApplicationIssues,
  ApolloEDNDocument,
  ApolloHealthPlanDetails,
  ApplicationIssuesFragment,
  EDNDocumentFragment,
  HealthPlanFragment,
} from '../fragments';
import { HealthPathwayType, PolicyStatusType } from '@types';

interface Application extends ApolloApplicationIssues {
  id: string;
  pathwayType?: HealthPathwayType;
  asyncStatus?: HealthAsyncStatus;
  enrollment?: {
    id?: string;
    hasAssociatedDentalPlan?: boolean;
  };
  ednDocuments?: ApolloEDNDocument;
}

export interface CoverageDetailQueryData {
  viewerTwo: {
    id: string;
    health?: {
      policy?: {
        id: string;
        startDate?: string; // YYYY-MM-DD
        endDate?: string; // YYYY-MM-DD
        dueDate?: string;
        coverageYear?: number;
        planName: string;
        carrier: string;
        premium?: number;
        premiumEffective?: number;
        policyStatus?: PolicyStatusType;
        productType?: 'PLANTYPE_HEALTH' | 'PLANTYPE_DENTAL';
        phoneNumber?: string;
        policyNumber?: string;
        exchangePolicyNumber?: string;
        subscriberID?: string;
        exchangeSubscriberID?: string;
        application?: Application;
        providerPlan?: ApolloHealthPlanDetails;
        enrolledDependents?: Array<{
          id: string;
          givenName?: string;
          middleName?: string;
          familyName?: string;
          relation?: 'SELF' | 'CHILD' | 'SPOUSE';
        }>;

        enrollmentID?: string;
      };
    };
  };
}

export const CoverageDetailQuery = gql`
  query CoverageDetailQuery($policyID: ID!) {
    viewerTwo {
      id
      health {
        policy(id: $policyID) {
          id
          startDate
          endDate
          dueDate @client
          coverageYear @client
          planName
          carrier
          premium
          premiumEffective
          policyStatus
          productType
          phoneNumber
          policyNumber
          exchangePolicyNumber
          subscriberID
          exchangeSubscriberID

          application {
            id
            pathwayType
            asyncStatus
            enrollment {
              id
              hasAssociatedDentalPlan
            }
            ednDocuments {
              id
              ...EDNDocument
            }
            ...ApplicationIssues
          }

          providerPlan {
            planID: id
            ...HealthPlanDetails
          }

          enrolledDependents {
            id
            givenName
            middleName
            familyName
            relation
          }

          enrollmentID
        }
      }
    }
  }
  ${HealthPlanFragment}
  ${ApplicationIssuesFragment}
  ${EDNDocumentFragment}
`;
