import React from 'react';

import { Toolbar, BasicLayout } from '@layouts';
import { Button, Link, Banner } from '@uikit';
import { useCopy, LongFormDate } from '@app/utils';
import { close, open, routes } from '@navigate';
import { HealthApplicationQueryData, queries, useDeprecatedMutation, useQuery } from '@data';

const PREFIX = 'catch.ede.CIC.sepMustApply';

const CICNewPlanRequired = ({ applicationID }) => {
  const { c } = useCopy(PREFIX);
  const [remove] = useDeprecatedMutation('removeCICPrefill');

  const { data } = useQuery<HealthApplicationQueryData>(queries.HEALTH_APPLICATION, {
    variables: { applicationID },
    skip: !applicationID,
  });

  function removePrefill() {
    remove({
      variables: {
        id: data?.viewerTwo?.health?.application?.enrollment?.id,
      },
    });
    close();
  }
  return (
    <BasicLayout
      title={c('title')}
      subtitles={[
        c('subtitle', {
          link: (
            <Link key="sepLink" inline>
              {c('sepLink')}
            </Link>
          ),
        }),
      ]}
      toolbar={
        <Toolbar>
          <Button alt onPress={() => removePrefill()}>
            {c('dismiss')}
          </Button>
          <Button wide onPress={() => open(routes.EDE_HOUSEHOLD)}>
            {c('apply')}
          </Button>
        </Toolbar>
      }
    >
      <Banner
        bg="importantCallout"
        title={c('caption', {
          date: <LongFormDate key="dueDate">{new Date()}</LongFormDate>,
        })}
      />
    </BasicLayout>
  );
};

export const CICNewPlanRequiredView = {
  name: 'CIC_NEW_PLAN_REQUIRED',
  component: CICNewPlanRequired,
  options: {
    bg: 'page',
  },
};
