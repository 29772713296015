import gql from 'graphql-tag';

export interface CreateLinkTokenVars {
  input: {
    bankLinkID?: string;
    androidPackageName?: string;
    redirectURI?: string;
  };
}

export interface CreateLinkTokenResponse {
  createLinkToken: {
    linkToken: string;
    expiration: Date;
  };
}

export const CreateLinkToken = gql`
  mutation CreateLinkToken($input: CreateLinkTokenInput) {
    createLinkToken(input: $input) {
      linkToken
      expiration
    }
  }
`;
