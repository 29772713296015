import gql from 'graphql-tag';
import { goals as goalsFragments } from '../fragments';

export const TRANSFER_GOALS = gql`
  query TransferGoals {
    viewer {
      id
      ...TransferGoals
    }
  }
  ${goalsFragments.transferGoals}
`;

const formatter = (data, options) => {
  return goalsFragments.formatTransferGoals(data, options);
};

export default {
  document: TRANSFER_GOALS,
  formatter,
};
