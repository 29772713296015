import React, { useEffect, useMemo } from 'react';
import { routes } from '@navigate';
import { CalloutLayout, Stack, Toolbar } from '@app/layouts';
import { Button } from '@uikit';
import { Segment, useCopy, currentYear } from '@app/utils';
import { useQuery, queries, CoverageQueryData } from '@app/data';
import { PolicyCard } from '@app/partial';
import { HealthSlasherStackScreenProps } from '../HealthSlasherStack';

/**
 *
 * Premium Slasher Success
 * @todo share, rate, etc
 * @todo use loading->success blueprint
 *
 */
const HealthSlasherSuccess = ({ handleNext, submittedApp }: HealthSlasherStackScreenProps) => {
  const { $ } = useCopy();

  const { loading, data } = useQuery<CoverageQueryData>(queries.COVERAGE, {
    variables: { coverageYears: [currentYear, currentYear + 1] },
    fetchPolicy: 'network-only',
  });

  const policies = useMemo(() => {
    return [...(data?.viewer?.health?.policies || [])]
      ?.filter((p) => p.policyStatus !== 'EXPIRED')
      ?.sort((a, b) => {
        return a.productType > b.productType ? -1 : 1;
      });
  }, [data?.viewer?.health?.policies]);

  const totalPremiums = useMemo(() => {
    return policies.reduce((total, policy) => {
      return total + policy.premiumEffective;
    }, 0);
  }, [policies]);

  useEffect(() => {
    Segment.track('Health Slasher Completed');
  }, []);

  return (
    <CalloutLayout
      showLoaderOnly
      size="page"
      loading={loading}
      render="coverage"
      title="You're all set!"
      subtitles={[`Your monthly premiums will be ${$(totalPremiums)}/mo starting next month.`]}
      toolbar={
        <Toolbar type="stack">
          <Button onPress={handleNext}>Go to my coverage</Button>
        </Toolbar>
      }
    >
      <Stack spacing="1">
        {policies.map((policy) => (
          <PolicyCard
            key={policy.id}
            type={policy.productType}
            carrier={policy.carrier || policy.providerPlan.issuer.name}
            plan={policy.planName}
            metalLevel={policy.providerPlan.metalLevel}
            premium={policy.premiumEffective}
            startDate={policy.startDate}
            endDate={policy.endDate}
          />
        ))}
      </Stack>
    </CalloutLayout>
  );
};

export const HealthSlasherSuccessView = {
  name: routes.HEALTH_SLASHER_SUCCESS,
  component: HealthSlasherSuccess,
  options: {},
};
