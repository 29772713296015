import React from 'react';

import { OnboardingFormBlueprint } from '@blueprints';
import { FormConfig } from '@app/forms';
import { fields } from '@app/config';
import { useCopy } from '@app/utils';
import { routes } from '@navigate';
import {
  useQuery,
  queries,
  RetirementQueryData,
  useMutation,
  mutations,
  UpdateUserVars,
  UpdateUserResponse,
} from '@data';

interface FinancialScreeningValues {
  isControlPerson: boolean;
  isFirmAffiliated: boolean;
  isPoliticallyExposed: boolean;
}

const FinancialScreening = ({ handleNext }) => {
  const { c } = useCopy('catch.plans.PlanRegulatoryView');

  const { loading, data } = useQuery<RetirementQueryData>(queries.RETIREMENT, {
    fetchPolicy: 'cache-first',
  });

  const [update, { loading: submitting }] = useMutation<UpdateUserResponse, UpdateUserVars>(
    mutations.UPDATE_USER,
  );

  const formConfig: FormConfig<FinancialScreeningValues> = {
    initialValues: {
      isControlPerson: data?.viewer?.user?.isControlPerson,
      isFirmAffiliated: data?.viewer?.user?.isFirmAffiliated,
      isPoliticallyExposed: data?.viewer?.user?.isPoliticallyExposed,
    },
    fields: [
      fields.FINANCIAL_SCREENING_PUBLICALLY_TRADED,
      fields.FINANCIAL_SCREENING_FIRM_AFFILIATED,
      fields.FINANCIAL_SCREENING_POLITICAL,
    ],
    onSubmit: (values) => {
      update({
        variables: {
          user: {
            isControlPerson: !!values.isControlPerson,
            isFirmAffiliated: !!values.isFirmAffiliated,
            isPoliticallyExposed: !!values.isPoliticallyExposed,
          },
        },
        optimisticResponse: {
          updateUserNew: {
            user: {
              ...data?.viewer?.user,
              id: data?.viewer?.user?.id || '',
              isControlPerson: values.isControlPerson,
              isFirmAffiliated: values.isFirmAffiliated,
              isPoliticallyExposed: values.isPoliticallyExposed,
              __typename: 'User',
            },
          },
        },
      });

      // all values must be false to pass regulatory screening
      const isEligible = [
        values.isControlPerson,
        values.isFirmAffiliated,
        values.isPoliticallyExposed,
      ].every((val) => !val);

      handleNext({ isEligible });
    },
  };

  return (
    <OnboardingFormBlueprint
      loading={loading}
      disabled={submitting}
      title={c('title')}
      subtitles={[c('subtitle'), c('formHeading')]}
      formConfig={formConfig}
      showLabels
    />
  );
};

export const FinancialScreeningView = {
  name: routes.FINANCIAL_SCREENING,
  component: FinancialScreening,
  options: OnboardingFormBlueprint.options,
};
