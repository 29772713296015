import React, { useState } from 'react';
import { Picker, View } from 'react-native';
import { Env, useCopy } from '@app/utils';
import { useTheme } from '@uikit/hooks/useTheme';
import { useID } from '@uikit/hooks/useID';
import { FieldWrapper } from '../FieldWrapper';
import { Asset } from '@app/_ui-kit';
import Down from '@svg/chevron-down.svg';

const PickerField = ({
  name,
  options,
  label,
  required,
  focused,
  sublabel,
  extraLabel,
  question,
  subtext,
  error,
  value,
  onChange: propOnChange,
  sideEffectOnChange,
  onFocus: propOnFocus,
  onBlur: propOnBlur,
  onKeyPress,
  placeholder,
  fullWidth,
  children,
  bare,
  gutter,
  style,
  row,
  grouped,
  tooltip,
  input: fieldInput,
  meta: fieldMeta,
  allowOther,
  otherReg,
  coLeft,
  coRight,
  coCenter,
  rounded,
  short,
  flex,
  testID,
  touchBeforeError = true, // Most pickers shouldn't show an error until touched and still invalid. However, for Pickers whose validity relies on another field (e.g. FinalPhoneField 'type' picker), we sometimes need to show
  ...rest
}) => {
  const [isFocused, setFocus] = useState(false);

  const { theme } = useTheme();
  const { c } = useCopy('catch.placeholders');
  const fieldID = useID();
  const sublabelID = useID();
  const input = fieldInput || { value, propOnChange, propOnFocus, propOnBlur };
  const meta = fieldMeta || { error, active: focused };
  const showOther = allowOther && (otherReg ? new RegExp(otherReg) : /OTHER/).test(input.value);
  const shouldShowError = meta.touched || !touchBeforeError || error;
  const hasError = (shouldShowError && meta.error) || error;

  /** These handlers are broken apart so that we handle cases where a custom onChange is passed but also exists on the input component */
  const handleChange = (value) => {
    fieldInput && fieldInput.onChange && fieldInput.onChange(value);
    propOnChange && propOnChange(value);
    sideEffectOnChange && sideEffectOnChange(value);
  };

  const handleFocus = () => {
    setFocus(true);
    fieldInput && fieldInput.onFocus && fieldInput.onFocus();
    propOnFocus && propOnFocus();
  };

  const handleBlur = () => {
    setFocus(false);
    fieldInput && fieldInput.onBlur && fieldInput.onBlur();
    propOnBlur && propOnBlur();
  };

  let items =
    placeholder && !value && value !== 0
      ? [<Picker.Item key="i-pl" label={placeholder || c('select')} value={''} />]
      : [];

  items = items.concat(
    options.map((opt, i) => (
      <Picker.Item
        key={`i-${i}`}
        label={opt.label}
        accessibilityLabel={`${testID}-${value}`}
        testID={`${testID}-${value}`}
        value={opt.value}
      />
    )),
  );

  const field = (
    <View
      style={[
        theme.row,
        fullWidth && theme.fullWidth,
        gutter && theme[`${gutter}Gutter${grouped ? '1' : ''}`],
        allowOther && showOther && theme.bottomGutter1,
        theme.pickerFieldContainer,
        flex && theme[`flex${flex}`],
        rounded && theme.textFieldRounded,
        style,
      ]}
    >
      <Picker
        style={[
          !Env.isTest && theme.textField,
          theme.textFieldBase,
          !Env.isTest && theme.pickerField,
          grouped && theme.groupedField,
          meta.error && shouldShowError && theme.textFieldError,
          (meta.active || isFocused) && theme.textFieldFocused,
          !input.value && theme.textSubtleColor,
          theme.textColor,
          fullWidth && theme.fullWidth,
          short && theme.shortField,
          style,
        ]}
        onKeyPress={!!onKeyPress ? ({ key }) => onKeyPress(key) : () => {}}
        onValueChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        aria-describedby={sublabel && sublabelID}
        accessibilityLabel={`${testID}-dropdown`}
        testID={`${testID}-dropdown`}
        selectedValue={input.value || ''}
        nativeID={fieldID}
        {...rest}
      >
        {items}
      </Picker>
      <Asset svg={Down} size="xs" containerStyle={theme.pickerFieldIcon} />
    </View>
  );
  if (bare) {
    return field;
  }
  return (
    <FieldWrapper
      label={label}
      required={required}
      fieldID={fieldID}
      sublabel={sublabel || question}
      sublabelID={sublabelID}
      extralabel={extraLabel}
      fullWidth={fullWidth}
      subtext={subtext}
      grouped={grouped}
      tooltip={tooltip}
      error={error || (hasError && meta.error)}
      row={row}
    >
      {field}
      {allowOther ? showOther && children : children}
    </FieldWrapper>
  );
};

PickerField.defaultProps = {
  placeholder: 'Select one',
};

export default PickerField;
