import React, { useState } from 'react';
import { useMutation, mutations } from '@data';
import { Asset } from '@uikit';
import { SheetBlueprint } from '@blueprints';
import { useSheet, sheets } from '@navigate';
import shallow from 'zustand/shallow';
import { BankAccount } from '@format/formatBanks';
import { launchPlaid } from '@app/utils';

interface Props {
  closeSheet: () => void;
  account?: BankAccount;
}

const ManageBankAccountSheet: React.FC<Props> = ({ account, closeSheet }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false); //show error if unable to create link token
  const { open } = useSheet((state) => ({ open: state.open }), shallow);

  const [watchAccount, watching] = useMutation(mutations.WATCH_ACCOUNT, {
    onCompleted: closeSheet,
  });

  const [unwatchAccount, unwatching] = useMutation(mutations.UNWATCH_ACCOUNT, {
    onCompleted: closeSheet,
  });

  const toggle = (selected: boolean) => {
    const toggleAccount = selected ? watchAccount : unwatchAccount;
    toggleAccount({ variables: { accountID: account.id } });
  };

  return (
    <SheetBlueprint
      asset={Asset.configureBank(account?.logo)}
      title={account.detail}
      actions={[
        {
          id: 'watch-account',
          type: 'CUSTOM',
          label: 'Watch for income',
          onAction: (selected) => toggle(selected),
          disabled: watching.loading || unwatching.loading || loading,
          interaction: 'switch',
          selectedByDefault: account?.isWatched,
          hidden: account.isPending,
        },
        {
          id: 'relink-account',
          button: true,
          type: 'CUSTOM',
          color: 'brand',
          errorLabel: showError
            ? 'Bank linking currently unavailable. Please try again later.'
            : '',
          label: `Relink ${account.bankName}`,
          loading,
          onAction: () =>
            launchPlaid({
              mode: account.relinkMode,
              bankLinkID: account.bankLinkID,
              setLoading,
              setShowError,
            }),
          disabled: watching.loading || unwatching.loading || loading,
          selectedByDefault: account?.isWatched,
          hidden: !account.isDisconnected,
        },
        {
          id: 'delete-rule',
          label: 'Delete account',
          type: 'CUSTOM',
          onAction: () => open(sheets.DELETE_BANK_ACCOUNT, { account }),
          disabled: watching.loading || unwatching.loading || loading,
          destructive: true,
        },
      ]}
    />
  );
};

export default ManageBankAccountSheet;
