import gql from 'graphql-tag';

export interface IncomeSkippedQueryData {
  viewer: {
    id: string;
    ignored: {
      edges: [
        {
          id: string;
          amount: number;
          memo: string;
          date: string;
          account: any;
          source: {
            id: string;
            text: string;
            taggedSource: {
              id: string;
              name: string;
              logo: any;
            };
          };
        },
      ];
    };
  };
}

export const IncomeSkippedQuery = gql`
  query IncomeSkippedQuery {
    viewer {
      id
      ignored: incomeTransactionsPaged(
        pagination: { pageNumber: 1, pageSize: 50 }
        incomeAction: [NOT_INCOME, SKIPPED]
      ) {
        edges {
          id
          ... on IncomeTransaction {
            id
            amount
            memo
            date
            account {
              id
              accountName: name
              accountNumber
            }
            source {
              id
              text
              taggedSource {
                id
                name
                logo {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;
