import gql from 'graphql-tag';
import { GoalStatus, GoalSlug } from '@types';

export interface ConfirmGoalsVars {
  input: {
    goalIds: Array<string>;
    authorizedAboundEftpsEnrollment?: boolean;
    acknowledgedUnitAccountDisclosures?: boolean;
  };
}

export interface ConfirmGoalsResponse {
  confirmGoals: {
    goals: {
      id: string;
      slug?: GoalSlug;
      status?: GoalStatus;
      paycheckPercentage?: number;
    };
    accountStatus: {
      id: string;
      isLocked: boolean;
      isBankingReady: boolean;
      isWealthReady: boolean;
      isBankingKYCDenied: boolean;
    };
    agreements: {
      id: string;
      authorizedAboundEftpsEnrollment?: boolean;
      acknowledgedUnitAccountDisclosures?: boolean;
    };
  };
}

export const ConfirmGoals = gql`
  mutation ConfirmGoals($input: ConfirmGoalsInput!) {
    confirmGoals(input: $input) {
      goals {
        id
        slug
        status
        paycheckPercentage
      }
      accountStatus {
        id
        isLocked
        isBankingReady
        isWealthReady
        isBankingKYCDenied
      }
      agreements {
        id
        acknowledgedUnitAccountDisclosures
        authorizedAboundEftpsEnrollment
      }
    }
  }
`;
