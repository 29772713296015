const planCostsWeights = {
  'Combined Medical and Drug EHB Deductible': 3,
  'Medical EHB Deductible': 2,
  'Drug EHB Deductible': 1,
  // anything else can be 0
};

/**
 * Comparison fn for comparing costs: based on the weights above
 * We want the ones we are going to use at the end of the array
 * because we use a reduce (so last match will be the one that is returned)
 */
export const compareCosts = (a, b) => {
  const weightA = planCostsWeights[a?.type] || 0;
  const weightB = planCostsWeights[b?.type] || 0;

  return weightA > weightB ? 1 : -1;
};

/**
 * Given a list of cost items (either moops or deductibles)
 * Finds the individual + family costs
 * This is done by matching the "family cost" + returning the associated amount
 */
export const findCosts = items => {
  const initialCosts = { individual: null, family: null };

  return items?.sort(compareCosts)?.reduce((acc, { familyCost, amount }) => {
    if (/^Family Per Person$|Individual/.test(familyCost)) {
      return { ...acc, individual: amount };
    }

    if (/^Family$/.test(familyCost)) {
      return { ...acc, family: amount };
    }

    return acc;
  }, initialCosts);
};

/**
 * Given a health plan,
 * calculates the max out of pocket + deductibles
 * for both the family level and person level
 */
export const calculatePlanCosts = ({ healthPlan }) => {
  const moopCosts = findCosts(healthPlan?.moops);
  const deductibleCosts = findCosts(healthPlan?.deductibles);

  return {
    outOfPocketFamily: moopCosts?.family,
    outOfPocketIndividual: moopCosts?.individual,
    deductibleFamily: deductibleCosts?.family,
    deductibleIndividual: deductibleCosts?.individual,
  };
};
