import gql from 'graphql-tag';
import access from 'safe-access';
import { STATES } from '@app/utils';

export const PREVIOUS_APPLICATIONS = gql`
  query PreviousApplications($input: PreviousApplicationsInput) {
    viewerTwo {
      id
      health {
        previousApplications(input: $input) {
          existingApplicationsInformation {
            givenName
            familyName
            applicationIdentifier
            coverageYearNumber
            state
            applicationVersion
            lastUpdated
            # applicationStatus
            hasExistingPersonTrackingNumbersTiedToApplication
            isPriorYear
          }
        }
      }
    }
  }
`;

const statusTypes = {
  IN_PROGRESS: 'IN_PROGRESS',
  SUBMITTED_NO_ENROLLMENT: 'SUBMITTED', // submitted app
  SUBMITTED_ENROLLMENT_TERM_CANCEL: 'SUBMITTED', // submitted app
  COMPLETE_LIMITED_PLAN_AVAILABILITY: 'NO_COVERAGE', // submitted app
  COMPLETE_NO_QHP_ELIGIBILITY: 'NO_COVERAGE', // submitted app
  SUBMITTED_ENROLLMENT_PENDING: 'ENROLLED',
  COMPLETE_ENROLLED: 'ENROLLED',
};

/**
 * Formatter for a single prev. appliaction
 *
 */
const formatPreviousApplication = (app) => ({
  id: app?.applicationIdentifier,
  lastUpdated: app?.lastUpdated,
  coverageYear: app?.coverageYearNumber,
  edeStatus: app?.applicationStatus,
  status: statusTypes[app?.applicationStatus],
  tenant: STATES[app?.state],
  givenName: app?.givenName,
  familyName: app?.familyName,
  isPriorYear: app?.isPriorYear,
});

/**
 * Reduces all the apps into prior year or current year
 * This simultaneously formats the applications using the formatter
 */
const handleApplications = ({ applications, coverageYear }) => {
  return applications?.reduce(
    (acc, app) => {
      const formattedApp = formatPreviousApplication(app);

      // If current year application, only append if
      // its completed or enrolled
      if (app?.coverageYearNumber === coverageYear) {
        if (formattedApp?.status === 'ENROLLED') {
          return { ...acc, enrolled: formattedApp };
        } else if (formattedApp?.status === 'SUBMITTED') {
          return { ...acc, submitted: formattedApp };
        } else {
          if (formattedApp?.status === 'IN_PROGRESS') {
            return { ...acc, inProgress: formattedApp };
          }
        }
      }

      // Only use the prior year application if its active enrollment
      // This will allow us to bring the coverage into Catch for viewing
      if (app?.coverageYearNumber === coverageYear - 1 && /ENROLLED/.test(formattedApp?.status)) {
        return { ...acc, prior: formattedApp };
      }

      // Otherwise, don't append
      return acc;
    },
    { prior: null, enrolled: null, submitted: null, inProgress: null },
  );
};

/**
 * Given a list of previous applications,
 * finds what the import/renewal status is
 * and simultaneously formats the relevent applications
 *
 * @param {[PreviousApplication]} applications
 * @param {{ coverageYear }} options
 *
 * @return
 * renewalStatus: one of IMPORT_ONLY, RENEWAL, or APPLICATION
 * currentApplication: the current year enrollment to go from
 * priorApplication: the active prior year enrollment to import
 *
 * unclear what to do with the following ExistingApplicationStatus:
 * COMPLETE_NO_QHP_ELIGIBILITY
 * COMPLETE_LIMITED_PLAN_AVAILABILITY
 */
const checkRenewalApplications = ({ prior, enrolled, submitted, inProgress }) => {
  // 1. 2021 already enrolled [COMPLETE_ENROLLED, SUBMITTED_ENROLLMENT_PENDING]
  // when users are in this state, we should just import and call it a day
  if (!!enrolled) {
    return {
      linkStatus: 'ACTIVE_COVERAGE',
      renewalStatus: 'IMPORT_ONLY',
      currentApplication: enrolled,
      priorApplication: prior,
    };
  }

  // 2. 2021 application submitted
  // [SUBMITTED_NO_ENROLLMENT, SUBMITTED_ENROLLMENT_TERM_CANCEL]
  if (!!submitted) {
    return {
      linkStatus: 'SUBMITTED_APP',
      renewalStatus: 'FINISH_ENROLLING',
      currentApplication: submitted,
      priorApplication: prior,
    };
  }

  if (!!inProgress) {
    return {
      linkStatus: 'IN_PROGRESS',
      renewalStatus: 'CONTINUE_APPLICATION',
      currentApplication: inProgress,
      priorApplication: prior,
    };
  }

  // 3. otherwise, this user needs to continue via the application flow
  // so we should set the status of application and direct them there
  return {
    linkStatus: 'NO_COVERAGE',
    renewalStatus: 'START_APPLICATION',
    currentApplication: null,
    priorApplication: prior,
  };
};

const getLinkApplications = ({ enrolled, submitted, inProgress }) => {
  if (enrolled) {
    return { status: 'LINK', application: enrolled };
  }

  if (submitted) {
    return { status: 'LINK', application: submitted };
  }

  if (inProgress) {
    return { status: 'CONTINUE', application: inProgress };
  }

  return { status: 'START', application: null };
};

const formatter = (data, options) => {
  const get = access(data);
  const previousApplications =
    get('viewerTwo.health.previousApplications.existingApplicationsInformation') || [];

  const { prior, enrolled, submitted, inProgress } = handleApplications({
    applications: previousApplications,
    coverageYear: options?.variables?.input?.coverageYear,
  });

  const renewals = checkRenewalApplications({
    prior,
    enrolled,
    submitted,
    inProgress,
  });

  const link = getLinkApplications({ enrolled, submitted, inProgress });

  const { applications, appsByID } = previousApplications?.reduce(
    (acc, app) => {
      const formattedApp = formatPreviousApplication(app);

      return {
        applications: [...acc.applications, formattedApp],
        appsByID: { ...acc.appsByID, [formattedApp?.id]: formattedApp },
      };
    },
    { applications: [], appsByID: {} },
  );

  return {
    renewals,
    applications,
    appsByID,
    link,
  };
};

export default {
  document: PREVIOUS_APPLICATIONS,
  formatter,
};
