import gql from 'graphql-tag';
import access from 'safe-access';
import { portfolio as portfolioFragments } from '../fragments';

const weights = {
  aggressive: 2,
  moderate: 1,
  conservative: 0,
};

const WEALTH_PORTFOLIOS = gql`
  query Portfolios {
    reference {
      wealth {
        portfolios {
          id
          ...Portfolio
        }
      }
    }
  }
  ${portfolioFragments.portfolio}
`;

const ordered = (a, b) => (weights[a?.type] > weights[b?.type] ? -1 : 1);

const formatter = (data, options) => {
  const get = access(data);

  const { portfolios, standard, social } = get('reference.wealth.portfolios.reduce()', [
    (acc, portfolio) => {
      const p = portfolioFragments.formatPortfolio(portfolio);

      return {
        portfolios: { ...acc.portfolios, [p.id]: p },
        standard: p?.isESG ? acc.standard : [...acc.standard, p],
        social: p?.isESG ? [...acc.social, p] : acc.social,
      };
    },
    { standard: [], social: [], portfolios: {} },
  ]) || { standard: [], social: [], portfolios: {} };

  return {
    portfolios,
    portfolio: portfolios[options?.portfolioID] || null,
    options: [...social, ...standard],
    socialGoodOptions: social.sort(ordered),
    standardOptions: standard.sort(ordered),
  };
};

export default {
  document: WEALTH_PORTFOLIOS,
  formatter,
};
