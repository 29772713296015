import gql from 'graphql-tag';

export interface PlanDetailQueryData {
  viewer: {
    id: string;
  };
}

export const PlanDetailQuery = gql`
  query PlanDetailQuery {
    viewer {
      id
    }
  }
`;
