import React, { useEffect } from 'react';
import { FormConfig, Fields, useForm } from '@f';
import { Platform } from 'react-native';
import { BasicLayout, Toolbar, NavBar, Stack } from '@layouts';
import { Banner } from '@app/_ui-kit';
import { AuthError } from '@app/client/Auth';

// note: shares this with other form blueprints! how cool
interface FormBlueprintProps<TFormValues> {
  loading: boolean; // shows a loader during initial load
  submitting: boolean; // while submitting the form, disable all buttons and inputs
  title: string;
  subtitle?: string;
  error?: string; // auth specific error
  actions: React.ReactNode;
  postContent?: React.ReactNode;
  formConfig: FormConfig<TFormValues>;
}

const errorMessages: Record<AuthError, string> = {
  USER_NOT_CONFIRMED: 'Must confirm email or phone',
  USER_NOT_FOUND: 'User does not exist',
  USER_NOT_CREATED: 'Something went wrong',
  USER_LAMBDA_VALIDATION: 'Something went wrong',
  NOT_AUTHORIZED: 'Something went wrong',
  CODE_MISMATCH: 'Invalid code',
  INVALID_PARAMETER: 'Something went wrong',
  LIMIT_EXCEEDED: 'Something went wrong',
  USERNAME_EXISTS: 'Something went wrong',
};

const AuthBlueprint = <TFormValues extends Record<string, any> = Record<string, any>>({
  loading, // for initial load
  submitting, // for example when submitting the form
  title,
  subtitle,
  actions,
  error,
  postContent,
  formConfig,
}: FormBlueprintProps<TFormValues>) => {
  const form = useForm<TFormValues>({
    loading,
    disabled: submitting,
    fields: formConfig.fields,
    initialValues: formConfig.initialValues,
    onSubmit: formConfig.onSubmit,
  });

  // we display the action buttons in different spots for web vs native
  const footer = actions({
    handleSubmit: form.submitForm,
    disabled: form.disableSubmit,
    submitting,
  });

  return (
    <>
      {Platform.OS === 'web' && <NavBar useFullLogo />}
      <BasicLayout
        loading={loading}
        fixAndroidMarginBehindNav
        title={Platform.OS !== 'ios' && title}
        subtitles={Platform.OS !== 'ios' && [subtitle]}
        toolbar={Platform.OS !== 'web' && <Toolbar type="dialog">{footer}</Toolbar>}
      >
        <Fields form={form} fields={formConfig.fields} />

        <Stack spacing="2">
          {error && <Banner bg="negativeLight" title={errorMessages[error]} />}
          {Platform.OS === 'web' && <Stack spacing="+gap">{footer}</Stack>}
          {postContent}
        </Stack>
      </BasicLayout>
    </>
  );
};

export default AuthBlueprint;
