import React, { useCallback } from 'react';
import { SetupIntro } from '@common';

import { Link } from '@uikit';
import { close } from '@navigate';
import { useTaxGoal, useNudge } from '@hooks';
import { useCopy } from '@util';

const AutoTaxPaymentIntro = () => {
  const { c } = useCopy('catch.agreements.autoTax');

  const { dismissNudge: dismissAutoTaxNudge } = useNudge('ACTIVATE_AUTO_TAX');
  const { upserting, upsertTaxGoal } = useTaxGoal({
    onCompleted: () => {
      dismissAutoTaxNudge();
      close();
    },
  });

  const handleNext = useCallback(() => {
    upsertTaxGoal({
      variables: {
        input: {
          slug: 'taxes',
          autoTax: true,
        },
      },
    });
  }, [upsertTaxGoal]);

  return (
    <SetupIntro
      type="AUTO_TAX"
      loading={upserting}
      onNext={handleNext}
      onSkip={() => close()}
      fine={c('eftps', {
        link: (
          <Link href={'https://catch.co/legal/eftps'} target="_blank" inline color="taxes">
            {c('eftps.link')}
          </Link>
        ),
      })}
    />
  );
};

export const AutoTaxPaymentIntroView = {
  name: 'AUTO_TAX_PAYMENT_INTRO',
  component: AutoTaxPaymentIntro,
  options: { ...SetupIntro.options, presentation: 'largeSheet' },
};
