import create, { SetState, GetState } from 'zustand';
import { Copy } from '@catch/types';

interface Toast {
  id: string;
  title: Copy;
  type: 'success' | 'error' | 'neutral' | 'important';
  autoCloseIn?: number;
  msg?: Copy;
}

type ToastStore = {
  active: Array<Toast>;
  popToast: (tst: Omit<Toast, 'id'>) => void;
};

let uniqueId = 1;
function getNextId() {
  return `toast-${uniqueId++}`;
}

const useToasts = create<ToastStore>((set: SetState<ToastStore>, get: GetState<ToastStore>) => ({
  active: [],
  popToast: (tst) => {
    if (!!tst) {
      const { type, title, msg, autoCloseIn } = tst;

      const active = get().active;
      const toast: Toast = {
        id: getNextId(),
        title,
        type: type || 'success',
        autoCloseIn,
        msg,
      };
      set({
        active: [...active, toast],
      });
    }
  },
  clearToast: ({ id }) => {
    const active = get().active;
    set({ active: active.filter((t) => t.id !== id) });
  },
}));

const popToast = useToasts.getState().popToast;

export { useToasts, popToast };
