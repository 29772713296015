/**
 * Gets the user's device from the browser on web
 * - Returns either android/ios/desktop
 * - This helps us figure out what device user's on, even on web
 */
export const getDevice = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  let platform = 'desktop';

  if (/android/i.test(userAgent)) {
    platform = 'android';
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    platform = 'ios';
  }

  return {
    platform,
    canShare: navigator.share,
  };
};
