import React from 'react';
import { View } from 'react-native';

import { ComplexRow, Text, useTheme } from '@uikit';
import { safeFormatDate, toDate, useCopy } from '@app/utils';
import { PersonRow } from '@app/_common';
import { Stack } from '@app/layouts';

/**
 * remove all the manual spacing and combine with SuccessSVITodos
 */
const SuccessDMITodos = ({ dmiTodos }) => {
  const { theme } = useTheme();
  const { c } = useCopy('catch.ede.Success');
  const { c: c2 } = useCopy('catch.ede.enums');

  return (
    <Stack spacing="+gap">
      {dmiTodos.map((dmiTodo) =>
        dmiTodo.members.map((m, i) => (
          <View style={theme.flex1} key={i}>
            <ComplexRow 
              label={`${m?.givenName} ${m?.familyName}`}
              sublabel={`${c('proof') ? c('proof') + ' ' : ''}${c2(`${dmiTodo.subType}`)}`}
              accessory={
                <Text weight="medium">
                  {safeFormatDate(toDate(dmiTodo.resolveBy), 'MMMM d, yyyy')}
                </Text>
              }      
            />
          </View>
        )),
      )}
    </Stack>
  );
};

export default SuccessDMITodos;
