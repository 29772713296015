import { combineReducers } from 'redux';
import formReducer from 'redux-form/lib/reducer';

export default function createReducer(injectedReducers = {}) {
  return combineReducers({
    // We can also persist some of them in the cache
    // I like persisting forms in case page is refreshed before submit...
    // form: persistReducer(persistConfig, formReducer),
    form: formReducer,

    // Then we can inject more later
    ...injectedReducers,
  });
}
