import gql from 'graphql-tag';
import {
  IncomeTransactionSource,
  CatchTransactionTag,
  WorkType,
  GoalStatus,
  GoalSlug,
} from '@types';

export interface IncomeSourcesQueryData {
  viewer: {
    id: string;
    user: {
      id: string;
      workType?: WorkType;
    };
    incomeSources: Array<IncomeTransactionSource>;
    suggestedSources: {
      edges: Array<CatchTransactionTag>;
    };
    incomePreferences: {
      isDelayAutoIncome?: boolean;
    };
    bankLinks: Array<{
      id: string;
      accounts: Array<{ id: string; isWatched: boolean }>;
    }>;
    goals: Array<{
      id: string;
      name: string;
      slug: GoalSlug;
      paycheckPercentage: number;
      status: GoalStatus;
    }>;
  };
}

export interface IncomeSourcesQueryVars {
  query: { prefix: string };
  page: { pageSize: number; pageNumber: number };
}

export const IncomeSourcesQuery = gql`
  query IncomeSourcesQuery($page: Pagination!, $query: SuggestedSourcesQuery) {
    viewer {
      id
      user {
        id
        workType
      }
      bankLinks {
        id
        providerType
        accounts {
          id
          isWatched
        }
      }
      incomeSources {
        id
        ruleID
        text
        sourceDetail
        canBeRule
        incomeAutomationRule {
          id
          automationType
          workType
        }
        taggedSource {
          id
          name
          logo {
            url
          }
        }
      }
      incomePreferences {
        isDelayAutoIncome
      }
      goals {
        id
        status
        name
        slug
        goalType
        paycheckPercentage
      }
      suggestedSources(pagination: $page, query: $query) {
        edges {
          id
          name
          logo {
            url
          }
        }
      }
    }
  }
`;
