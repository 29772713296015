import { ScreenDefinition, paths, TabComponent, StackComponent } from '@navigate';

// stack imports
import AuthStack from './auth/AuthStack';
import OnboardingStack from './onboarding/OnboardingStack';
import HomeStack from './home/HomeStack';
import IncomeStack from './income/IncomeStack';
import TaxesStack from './taxes/TaxesStack';
import PlanStack from './plan/PlanStack';
import CoverageStack from './health/CoverageStack';
import ActivityStack from './activity/ActivityStack';
import MeStack from './me/MeStack';
import TransferStack from './transfers/TransferStack';
import TaxPaymentStack from './taxes/TaxPaymentStack';
import TaxHistoryStack from './taxes/TaxHistoryStack';
import PaycheckStack from './income/PaycheckStack';
import IncomeSourcesStack from './income/IncomeSourcesStack';
import GoalSetupStack from './plan/GoalSetupStack';
import PersonalPayrollStack from './personalPayroll/PersonalPayrollStack';
import AccountSetupStack from './plan/AccountSetupStack';
import RetirementStack from './plan/RetirementStack';
import HealthExplorerStack from './health/HealthExplorerStack';
import HealthLinkStack from './health/HealthLinkStack';
import HealthSlasherStack from './health/HealthSlasherStack';
import HealthApplicationStack from './health/HealthApplicationStack';
import HealthRenewStack from './health/HealthRenewStack';
import CICStack from './health/CICStack';

// view imports
import { GoalEditView } from './plan/plan/GoalEditView';
import { GoalCustomizeView } from './plan/plan/GoalCustomizeView';
import { RetirementChangePortfolioView } from './plan/plan/RetirementChangePortfolioView';
import { PlanEditView } from './plan/plan/PlanEditView';
import { DocumentUpload } from './misc/DocumentUpload';
import { IncomeStatsView } from './income/income/IncomeStatsView';
import { IncomeSkippedView } from './income/income/IncomeSkippedView';
import { HealthResumeView } from './health/application/HealthResumeView';
import { EditUserDetailsView } from './me/partials/EditUserDetailsView';
import { ChangeAliasView } from './me/partials/ChangeAliasView';
import { ChangePasswordView } from './me/partials/ChangePasswordView';
import DevView from '@app/dev/DevView';
import { UpsertBeneficiaryView } from './me/partials/UpsertBeneficiaryView';
import { AnnualReviewView } from './misc/AnnualReviewView';
import { WalletItemView } from './misc/WalletItemView';

// tab declarations
export const tabs: Array<TabComponent> = [
  HomeStack,
  IncomeStack,
  TaxesStack,
  PlanStack,
  CoverageStack,
];

// layers that open above main app
export const layers: Array<StackComponent> = [
  OnboardingStack,
  ActivityStack,
  MeStack,
  TransferStack,
  PaycheckStack,
  IncomeSourcesStack,
  TaxPaymentStack,
  TaxHistoryStack,
  HealthExplorerStack,
  HealthLinkStack,
  HealthSlasherStack,
  HealthRenewStack,
  HealthApplicationStack,
  CICStack,
  GoalSetupStack,
  PersonalPayrollStack,
  AccountSetupStack,
  RetirementStack,
];

// stack for unauthed users
export const guest: StackComponent = AuthStack;

// standalone pages that can be opened from anywhere
export const standalone: Array<ScreenDefinition> = [
  GoalEditView,
  GoalCustomizeView,
  RetirementChangePortfolioView,
  PlanEditView,
  DocumentUpload,
  WalletItemView,
  HealthResumeView, // @todo E-3445 typescript
  EditUserDetailsView,
  ChangeAliasView,
  ChangePasswordView,
  IncomeStatsView,
  IncomeSkippedView,
  DevView,
  UpsertBeneficiaryView,
  AnnualReviewView,
];

/**
 * Heads up!
 * -------------------------
 * Everything below is just a consolidated mapping of stack/screens
 * IF a screen/stack is missing, add it to the corresponding configs above instead
 * -------------------------
 */

// consolidated definition for all stacks
export const stacks: Array<StackComponent> = [guest, ...tabs, ...layers];

/**
 * Builds a single array of all screens
 * Note: do not remove sort -- it's in place to ensure that web routing happens in correct order
 * so that a url like /plan/:slug/intro gets matched before /plan
 */
export const screens: Array<ScreenDefinition> = stacks
  .reduce((acc: Array<ScreenDefinition>, stack: StackComponent) => {
    return [...acc, ...stack.config.screens];
  }, [])
  .concat(standalone)
  .sort(({ name: pageName }, { name: secondPageName }) => {
    return paths[pageName] > paths[secondPageName] ? -1 : 1;
  });
