import React, { useState } from 'react';
import { View } from 'react-native';
import { Text, useTheme, useHover, Pressable, ComplexRow } from '@uikit';
import { Layout } from '@layouts';
import { formatDrug } from '@app/utils';

const DrugResult = ({ drug, isLast, select, isSelected }) => {
  const [hovered, hoverHandlers] = useHover();
  const [showStrengths, set] = useState(false);
  const { theme } = useTheme();

  return (
    <View
      dataSet={{ private: 'redact' }}
      style={[
        !isLast && theme.dividerHorizontal,
        hovered && !showStrengths && theme.skeletonBg,
        theme.fullWidth,
      ]}
      {...hoverHandlers}
    >
      <ComplexRow
        sensitive
        label={formatDrug(drug?.name)}
        sublabel={drug?.route}
        accessoryStyle={theme.alignEnd}
        onPress={() => set(!showStrengths)}
        style={theme.fullWidth}
        accessory={
          <Text size="fp" color="subtle">
            {drug?.strengths?.length} strengths
          </Text>
        }
      />
      {showStrengths && (
        <Layout>
          <View
            style={[
              theme.flex1,
              theme.row,
              theme.flexWrap,
              { flexFlow: 'row wrap' },
              theme.bottomGutter1b,
            ]}
          >
            {drug?.strengths.map((drug) => (
              <Pressable
                key={drug?.rxcui}
                pressedStyle={{ opacity: 0.2 }}
                style={[
                  theme.flag,
                  theme.flowNavBg,
                  theme.circleCorners,
                  theme.bottomGutter1b,
                  theme.rightGutter1b,
                  { flexShrink: 0, width: 'auto' },
                  isSelected(drug?.rxcui) && theme.neutralStatusBg,
                ]}
                handleOnPress={() => select(drug)}
              >
                <Text
                  size="fp"
                  weight="medium"
                  color={isSelected(drug?.rxcui) ? 'snow' : 'text'}
                  style={{ flexShrink: 0, width: 'auto' }}
                >
                  {drug?.strength}
                </Text>
              </Pressable>
            ))}
          </View>
        </Layout>
      )}
    </View>
  );
};

export default DrugResult;
