import gql from 'graphql-tag';

export interface RemoveApplicationMemberVars {
  input: {
    id: string;
    removeDate: Date;
    removeReason: 'DEATH' | 'DIVORCE' | 'OTHER';
  };
}

export interface RemoveApplicationMemberResponse {
  removeApplicationMember: boolean;
}

export const RemoveApplicationMember = gql`
  mutation DeleteExplorerApplicant($applicantID: ID!) {
    deleteHealthExplorerDependents(ids: [$applicantID]) {
      id
    }
  }
`;
