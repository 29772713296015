import React from 'react';
import { useCopy } from '@app/utils';
import { Asset } from '@uikit';
import { SheetBlueprint } from '@blueprints';
import { useSheet } from '@navigate';
import shallow from 'zustand/shallow';
import { useMutation, mutations, updates } from '@data';
import { BankAccount } from '@format/formatBanks';

interface Props {
  closeSheet: () => void;
  account?: BankAccount;
}

const DeleteBankAccountSheet: React.FC<Props> = ({ account, closeSheet }) => {
  const { closeAll } = useSheet((state) => ({ closeAll: state.closeAll }), shallow);
  const { c } = useCopy('catch.bankLink');
  const { c: basics } = useCopy('catch.basics');

  const [deleteBankLink, deletingLink] = useMutation(mutations.DELETE_BANK_LINK, {
    update: updates.DELETE_BANK_LINK,
    onCompleted: closeAll,
  });

  const [deletePendingAccount, deletingPending] = useMutation(mutations.DELETE_PENDING_ACCOUNT, {
    update: updates.DELETE_PENDING_ACCOUNT,
    onCompleted: closeAll,
  });

  const handleDelete = () => {
    if (account.isPending) {
      deletePendingAccount({ variables: { accountID: account.id } });
    } else {
      deleteBankLink({ variables: { bankLinkID: account.bankLinkID } });
    }
  };

  return (
    <SheetBlueprint
      asset={{ ...Asset.configureBank(account.logo), size: 'lg', shape: 'circle' }}
      title={basics('youSure')}
      subtitle={c('deleteBankLink.tempView.subtitle', account)}
      actions={[
        {
          label: basics('neverMind') || '',
          type: 'CUSTOM',
          onAction: closeSheet,
          id: 'cancel-action-button',
          disabled: deletingLink.loading || deletingPending.loading,
        },
        {
          label: basics('remove') || '',
          type: 'CUSTOM',
          onAction: handleDelete,
          destructive: true,
          id: 'action-button',
          disabled: deletingLink.loading || deletingPending.loading,
        },
      ]}
    />
  );
};

export default DeleteBankAccountSheet;
