// @ts-nocheck
import React, { useMemo, useRef } from 'react';
import { debounce } from 'lodash';
import { useQuery, queries, HealthExplorerQueryData, useDeprecatedMutation } from '@data';
import { useMarketplace, useSelectable } from '@hooks';
import { useCopy } from '@app/utils';
import { navigate, routes, ScreenDefinition } from '@navigate';
import { ProviderResult, SelectedProviders } from '@common';
import { ExplorerLayout } from '@layouts';
import { TextField } from '@app/forms_old/inputs';
import Search from '@svg/search.svg';

const ExplorerDoctors = () => {
  const { c } = useCopy('catch.healthExplorer.explorerDoctors');
  const { select, deselect, isSelected, selected, values } = useSelectable();

  const { loading, data } = useQuery<HealthExplorerQueryData>(queries.HEALTH_EXPLORER, {
    fetchPolicy: 'cache-first',
  });

  const explorer = useMemo(() => {
    return data?.viewerTwo?.healthExplorerData;
  }, [data?.viewerTwo?.healthExplorerData]);

  const { setSearchTerm, results } = useMarketplace({
    type: 'providers',
    zip: explorer?.zipcode,
  });

  const [upsert] = useDeprecatedMutation('upsertHealthPreference');

  const doctorsPayload =
    Object.values(values)?.length > 0
      ? Object.values(values)?.map((v) => ({
          npi: v.data.npi,
          name: v.data.name,
        }))
      : null;

  const setSearchTermDebounced = useRef(
    debounce((nativeEvent) => setSearchTerm(nativeEvent?.text), 400),
  );

  return (
    <ExplorerLayout
      loading={loading}
      title={c('title')}
      disabled={selected?.length === 0}
      canSkip
      onSkip={() =>
        navigate(routes.EXPLORER_CHOOSE_PLAN, {
          pathwayType: explorer?.pathwayType,
          explorerID: explorer?.id,
        })
      }
      onNext={() => {
        upsert({
          variables: {
            input: {
              providersWanted: doctorsPayload,
            },
          },
        });
        navigate(routes.EXPLORER_CHOOSE_PLAN);
      }}
    >
      <SelectedProviders selected={selected} values={values} deselect={deselect} />

      <TextField
        sensitive
        fullWidth
        label="Doctor"
        placeholder="Search a doctor or specialist"
        svg={Search}
        onChange={({ nativeEvent }) => setSearchTermDebounced.current(nativeEvent)}
      />

      {results?.map((doctor, i) => (
        <ProviderResult
          key={doctor?.npi}
          doctor={doctor}
          isLast={i === results?.length - 1}
          isSelected={isSelected}
          select={(doctor) => {
            select(doctor?.npi, doctor);
            setSearchTerm('');
          }}
        />
      ))}
    </ExplorerLayout>
  );
};

export const ExplorerDoctorsView: ScreenDefinition = {
  name: routes.EXPLORER_DOCTORS,
  component: ExplorerDoctors,
  options: {
    title: 'Add doctor preference',
  },
};
