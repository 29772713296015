import React from 'react';
import {
  useQuery,
  queries,
  useMutation,
  mutations,
  OnboardingQueryData,
  UpdateUserResponse,
  UpdateUserVars,
} from '@data';
import { OnboardingFormBlueprint } from '@blueprints';
import { routes } from '@navigate';
import { FormConfig } from '@app/forms';
import { fields } from '@app/config';
import { WorkType } from '@types';
import { useCopy } from '@app/utils';

const copy: Record<WorkType, string> = {
  WORK_TYPE_W2: 'W2 income',
  WORK_TYPE_1099: '1099 income',
  WORK_TYPE_DIVERSIFIED: 'W2 and 1099 income',
};

interface EstimatedIncomeValues {
  workType: WorkType;
  estimated1099Income?: number;
  estimatedW2Income?: number;
}

const EstimatedIncome = ({ handleNext }) => {
  const { c } = useCopy('catch.onboarding.estimatedIncome');
  const { loading, data } = useQuery<OnboardingQueryData>(queries.ONBOARDING, {
    fetchPolicy: 'cache-first', // @todo: this should really be the default regardless
  });

  const [update, { loading: submitting }] = useMutation<UpdateUserResponse, UpdateUserVars>(
    mutations.UPDATE_USER,
  );

  const formConfig: FormConfig<EstimatedIncomeValues> = {
    initialValues: {
      workType: data?.viewer?.user?.workType,
      estimated1099Income: data?.viewer?.user?.estimated1099Income,
      estimatedW2Income: data?.viewer?.user?.estimatedW2Income,
    },
    fields: [fields.INCOME_1099, fields.INCOME_W2],
    onSubmit: (values) => {
      update({
        variables: {
          user: {
            estimated1099Income: values?.estimated1099Income,
            estimatedW2Income: values?.estimatedW2Income,
          },
        },
        optimisticResponse: {
          updateUserNew: {
            user: {
              ...data?.viewer?.user,
              id: data?.viewer?.user?.id || '',
              estimated1099Income: values?.estimated1099Income,
              estimatedW2Income: values?.estimatedW2Income,
              __typename: 'User',
            },
          },
        },
      });

      handleNext();
    },
  };
  const workType = data?.viewer.user?.workType;
  return (
    <OnboardingFormBlueprint
      loading={loading}
      disabled={submitting}
      title={c('title', { workType: copy[workType] })}
      formConfig={formConfig}
      showLabels
    />
  );
};

export const EstimatedIncomeView = {
  name: routes.ESTIMATED_INCOME,
  component: EstimatedIncome,
  options: OnboardingFormBlueprint.options,
};

export const GoalEstimatedIncomeView = {
  name: routes.GOAL_ESTIMATED_INCOME,
  component: EstimatedIncome,
  options: OnboardingFormBlueprint.options,
};
