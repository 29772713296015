import gql from 'graphql-tag';
import access from 'safe-access';

const CURRENT_USER = gql`
  query GetCurrentUser($platform: AppPlatform!) {
    viewer {
      id
      user {
        id
        givenName
        familyName
        email
        phoneNumber
      }
      intercomIdentityHash(appPlatform: $platform)
    }
  }
`;

const formatter = (data) => {
  const get = access(data);

  const userId = get('viewer.user.id');
  const givenName = get('viewer.user.givenName');
  const email = get('viewer.user.email');
  const phoneNumber = get('viewer.user.phoneNumber');
  const familyName = get('viewer.user.familyName');
  const intercomHash = get('viewer.intercomIdentityHash');

  return {
    userId,
    email,
    phoneNumber,
    givenName,
    familyName,
    intercomHash,
  };
};

export default {
  document: CURRENT_USER,
  formatter,
};
