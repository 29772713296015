import gql from 'graphql-tag';
import access from 'safe-access';
import { convertUnixTimeToAge, getAnnualContributionLimit } from '@app/utils';

const RETIREMENT_LIMIT = gql`
  query RetirementLimit {
    viewer {
      id
      user {
        id
        dob
      }
      retirementGoal: goal(slug: "retirement") {
        id
        slug
        ... on RetirementGoal {
          totalContributions
        }
      }
    }
  }
`;

const formatter = (data) => {
  const get = access(data);

  const age = convertUnixTimeToAge(get('viewer.user.dob'));
  const limit = getAnnualContributionLimit(age);
  const contributed = get('viewer.retirementGoal.totalContributions');

  return {
    age,
    limit,
    contributed,
    hasReachedLimit: contributed >= limit,
  };
};

export default {
  document: RETIREMENT_LIMIT,
  formatter,
};
