import React, { useState, useMemo, useEffect } from 'react';
import { View, StyleSheet } from 'react-native';
import { useSpring, animated } from 'react-spring/native';
import { RenderedIcon, Action, Copy, AssetProps } from '@types';
import { ButtonGroup, Page, useLayoutContext } from '@layouts';
import { Button, CatchTheme, Loading, UIAccentColor, useTheme } from '@app/_ui-kit';
import { PromptCard } from '@app/_common';

interface ConfirmationProps {
  called: boolean;
  loading: boolean;
  error: boolean;
  accentColor?: UIAccentColor;
  titles?: {
    loading?: Copy;
    done?: Copy;
    error?: Copy;
  };
  subtitles?: {
    loading?: Copy;
    done?: Copy;
    error?: Copy;
  };
  followUp?: {
    asset?: AssetProps;
    accentColor?: UIAccentColor;
    render?: RenderedIcon;
    title: string;
    subtitle?: string;
    action: Action;
    loading?: boolean;
  };
  onSuccess?: () => void;
}

// @ts-ignore
const AnimatedView = animated(View);

const PROMPT_DELAY = CatchTheme.animation.delayMedium;
const SUCCESS_DELAY = CatchTheme.animation.delayMedium;

/**
 * @see https://www.figma.com/file/GLQN40NukLsugDgoV7XrjS/%E2%9D%96-Design-System?node-id=4135%3A70464
 *
 * @todo embed useNudge into this template for the "not now" selections
 * the appropriate type for that would probably match Prompt.name so it's dismissed on Home as well
 *
 */
export const ConfirmationBlueprint: React.FC<ConfirmationProps> = ({
  called,
  loading,
  error,
  titles,
  subtitles,
  accentColor,
  followUp,
  onSuccess,
  children,
}) => {
  const [dismissed, setDismissed] = useState(false);
  const { layout } = useLayoutContext();
  const { theme } = useTheme();

  const invoked = useMemo(() => {
    return called;
  }, [called, loading, error]);

  const done = useMemo(() => {
    return called && !error && !loading;
  }, [called, loading, error]);

  const showPrompt = useMemo(() => {
    return done && !!followUp;
  }, [done, followUp]);

  // trigger on success when done
  useEffect(() => {
    if (done && onSuccess && !followUp) {
      setTimeout(onSuccess, SUCCESS_DELAY);
    }
  }, [done, onSuccess]);

  const loaderBox = useSpring({
    translate: showPrompt ? -150 : 0,
    delay: showPrompt ? PROMPT_DELAY : 0,
  });

  const promptBox = useSpring({
    translate: showPrompt ? 0 : 300,
    scale: dismissed ? 0.9 : 1,
    opacity: dismissed ? 0 : done ? 1 : 0,
    delay: showPrompt && !dismissed ? SUCCESS_DELAY : 0,
  });

  if (!invoked) {
    return <>{children}</>;
  }

  return (
    <Page color={layout}>
      <AnimatedView style={{ flex: 1, transform: [{ translateY: loaderBox.translate }] }}>
        <Loading<'done' | 'loading' | 'error'>
          full
          accentColor={accentColor}
          status={done ? 'done' : error ? 'error' : 'loading'}
          titles={{ loading: ' ', done: ' ', ...titles }}
          subtitles={{ error: 'Something went wrong', ...subtitles }}
        />
      </AnimatedView>
      <AnimatedView
        style={[
          styles.promptContainer,
          {
            opacity: promptBox.opacity,
            transform: [{ translateY: promptBox.translate }, { scale: promptBox.scale }],
          },
        ]}
      >
        {followUp && (
          <PromptCard
            title={followUp.title}
            subtitle={followUp.subtitle}
            render={followUp.render}
            asset={followUp.asset}
            gradient={followUp.accentColor + 'Light'}
            wide
          >
            <View style={[theme.xSpace2, theme.bottomSpace2]}>
              <ButtonGroup>
                <Button
                  testID={`dismiss-success-prompt`}
                  alt
                  accentColor={followUp.accentColor ? followUp.accentColor + 'Light' : 'grayBg'}
                  onPress={() => {
                    setDismissed(true);
                    onSuccess();
                  }}
                >
                  Not now
                </Button>
                <Button
                  testID={followUp.action?.testID || 'agree-success-prompt'}
                  accentColor={followUp.accentColor}
                  onPress={followUp.action?.onAction}
                  loading={followUp.loading}
                >
                  {followUp.action.label}
                </Button>
              </ButtonGroup>
            </View>
          </PromptCard>
        )}
      </AnimatedView>
    </Page>
  );
};

const styles = StyleSheet.create({
  promptContainer: {
    position: 'absolute',
    alignItems: 'center',
    bottom: CatchTheme.spacing.mobile.margins,
    left: CatchTheme.spacing.mobile.margins,
    right: CatchTheme.spacing.mobile.margins,
  },
});

export default ConfirmationBlueprint;
