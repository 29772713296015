import React from 'react';
import { useCopy } from '@app/utils';
import { routes } from '@navigate';
import { ItemTriageBlueprint } from '@blueprints';
import { useQuery, queries, IncomeTransactionQueryData } from '@data';

const PaycheckIncome = ({ paycheckID, handleNext }) => {
  const { c } = useCopy('catch.sequences.Paycheck.triage');

  const { data } = useQuery<IncomeTransactionQueryData>(queries.INCOME_TRANSACTION, {
    variables: { paycheckID },
    skip: !paycheckID,
    fetchPolicy: 'cache-and-network',
    returnPartialData: true,
  });

  return (
    <ItemTriageBlueprint
      title="Hello"
      question={c('title')}
      paycheck={data?.viewer.incomeTransaction}
      onPress={(approvalType) => handleNext({ approvalType })}
      options={[
        {
          key: '1099',
          label: c('ctTitle'),
          description: c('ctSubtitle'),
          value: '1099',
        },
        {
          key: 'W2',
          label: c('w2Title'),
          description: c('w2Subtitle'),
          value: 'W2',
        },
      ]}
    />
  );
};

export const PaycheckIncomeView = {
  name: routes.PAYCHECK_INCOME,
  component: PaycheckIncome,
  options: {},
};
