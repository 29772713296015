import { popToast } from '@uikit';
import { Platform } from 'react-native';
import { mutations, CreateLinkTokenResponse, CreateLinkTokenVars } from '@data';
import apolloClient from '@client/Apollo';
import Env from '../env';
import { createLogger } from '../logger';

const Log = createLogger('fetch-plaid-token');

export const fetchToken = async (bankLinkID?: string) => {
  try {
    const input = Platform.select({
      web: {
        redirectURI: `${window?.location?.origin}/plaid-oauth`,
      },
      ios: {
        redirectURI: `${Env.webUrl}/plaid-oauth`,
      },
      android: {
        androidPackageName: Env.androidPackageName,
      },
    });

    const response = await apolloClient.mutate<CreateLinkTokenResponse, CreateLinkTokenVars>({
      mutation: mutations.CREATE_LINK_TOKEN,
      variables: {
        input: {
          ...input,
          bankLinkID,
        },
      },
    });

    return response.data?.createLinkToken.linkToken;
  } catch (error) {
    if (error?.message?.includes('Unavailable')) {
      popToast({
        type: 'important',
        msg: 'Please try again later.',
        title: 'Bank link is currently unavailable',
      });
    } else {
      Log.error(error);
    }
  }
};
