import gql from 'graphql-tag';

export interface UploadSignatureVars {
  signature: string;
}

export interface UploadSignatureResponse {
  uploadSignature: boolean;
}

export const UploadSignature = gql`
  mutation UploadSignature($signature: String!) {
    uploadSignature(signature: $signature)
  }
`;
