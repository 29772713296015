import React from 'react';

import { GoalMeter, LineItem, Text } from '@uikit';
import { open, routes } from '@navigate';
import { Percentage, Currency, LongFormDate, toDate, useCopy } from '@app/utils';
import { Section, Stack } from '@app/layouts';

import accountTypes from './accountTypes';
import { IRA_DOLLAR_LIMIT } from './AccountLimits';

/**
 * why is this component?
 * end of comment
 */
const DetailsTab = ({ goal }) => {
  const { c: benefits, $ } = useCopy('catch.benefits');
  const { c: portfolios } = useCopy('catch.module.retirement.PortfolioSelectionView');

  const handleEdit = () => open(routes.GOAL_EDIT, { slug: goal.slug });
  const handlePortfolio = () => open(routes.RETIREMENT_CHANGE_PORTFOLIO, { slug: goal.slug });

  // retirement beneficiary
  const _benef = goal?.wealthAccount?.primaryBeneficiaries?.[0];
  const beneficiaryName = !!_benef
    ? `${_benef?.firstName || ''} ${_benef?.lastName || ''}`
    : undefined;

  return (
    <Stack spacing="1" mobileGap>
      <Section style={{ paddingTop: 0 }}>
        <Stack spacing="1">
          <LineItem left="Pending" right={$(goal?.pendingBalance)} />
          <LineItem
            left="Setting aside"
            right={
              <Text underline onPress={handleEdit} color="text">
                <Percentage whole>{goal?.paycheckPercentage}</Percentage>
              </Text>
            }
          />
        </Stack>
      </Section>

      {/** @todo change portfolio */}
      {goal?.goalType === 'RETIREMENT' && (
        <Section title="Account">
          <Stack spacing="1">
            <LineItem
              left="Portfolio"
              right={
                <Text underline onPress={handlePortfolio} color="text">
                  {portfolios(`${goal?.portfolio?.name}.title`)}
                </Text>
              }
            />
            <LineItem left="Account Type" right={accountTypes[goal?.accountType]?.label} />
            <LineItem
              left="Beneficiary"
              right={
                <Text
                  underline
                  onPress={() => open(routes.UPSERT_BENEFICIARY)}
                  color={beneficiaryName ? 'text' : 'subtle'}
                >
                  {beneficiaryName || 'Add'}
                </Text>
              }
            />
          </Stack>
        </Section>
      )}

      {!!goal?.totalContributions && (
        <Section title="Contributions">
          <Stack spacing="1">
            <GoalMeter
              target={IRA_DOLLAR_LIMIT}
              balance={goal?.totalContributions}
              hideText
              color="retirement"
            />
            <LineItem left="This year" right={$(goal?.totalContributions)} />
            <LineItem left="Remaining" right={$(IRA_DOLLAR_LIMIT - goal?.totalContributions)} />
          </Stack>
        </Section>
      )}

      {(!!goal?.goalType === 'CUSTOM_SAVINGS' || !!goal?.endDate || !!goal?.plannedTarget) && (
        <Section>
          <Stack spacing="1">
            {!!goal?.goalType === 'CUSTOM_SAVINGS' && <LineItem left="Name" right={goal?.name} />}
            {!!goal?.endDate && (
              <LineItem
                left={benefits(goal?.type)}
                right={benefits('endDate', {
                  target: !!goal?.target ? (
                    <Currency whole>{goal?.target}</Currency>
                  ) : (
                    <Text size="fp" color="link">
                      {Math.ceil(goal?.numDays / 5)} weeks
                    </Text>
                  ),
                  endDate: <LongFormDate>{toDate(goal?.endDate)}</LongFormDate>,
                })}
                link
                onPress={handleEdit}
                noBorder
              />
            )}
            {!!goal?.plannedTarget && (
              <LineItem
                link
                onPress={handleEdit}
                noBorder
                left={benefits('targetLabel', { type: 'planned' })}
                right={benefits('targetDescription', {
                  days: goal?.plannedTarget,
                })}
              />
            )}
            {!!goal?.unplannedTarget && (
              <LineItem
                link
                onPress={handleEdit}
                noBorder
                left={benefits('targetLabel', { type: 'unplanned' })}
                right={benefits('targetDescription', {
                  days: goal?.unplannedTarget,
                })}
              />
            )}
          </Stack>
        </Section>
      )}
    </Stack>
  );
};

export default DetailsTab;
