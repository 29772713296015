import gql from 'graphql-tag';

const HEALTH_INSURANCE_ELIGIBILITIES = gql`
  query HealthInsuranceEligibilities($enrollId: ID!) {
    viewer {
      id
      health {
        enrollment(id: $enrollId) {
          id
          ... on HealthInsuranceEnrollment {
            id
            application {
              id
              coverageState

              eligibility {
                totalAptc
                csrLevel
                isMedicaid
                isChip
              }
              parent {
                id
                aptcHouseholdAmount
                eligibility {
                  totalAptc
                  csrLevel
                  isMedicaid
                  isChip
                }
              }
            }
          }
        }
      }
    }
  }
`;

const formatter = (data) => {
  const application = data?.viewer?.health?.enrollment?.application;
  const cicEligibility = application?.eligibility || {};
  const parentEligibility = { totalAptc: application?.parent?.aptcHouseholdAmount };
  return {
    application,
    cicEligibility,
    parentEligibility,
    coverageState: application?.coverageState,
  };
};

export default {
  document: HEALTH_INSURANCE_ELIGIBILITIES,
  formatter,
};
