import React from 'react';
import { SafeAreaView, View } from 'react-native';
import { AssetProps, Copy, Action } from '@types';
import { Stack, Layout } from '@layouts';
import { BottomActions } from '@app/_common/layouts/BottomActions';
import type { SheetName } from '@navigate';

interface Props {
  name: SheetName;
  title: Copy;
  subtitle?: Copy;
  asset?: AssetProps;
  actions?: Action[];
  children: any;
  layout?: 'modal' | 'sheet';
  color?: string;
}

/**
 * @todo add sticky header, disclaimers, etc
 */
export const GuideBlueprint: React.FC<Props> = ({
  name,
  title,
  subtitle,
  asset,
  actions,
  children,
  color,
}) => {
  return (
    <SafeAreaView style={{ flex: 1 }}>
      <Layout.Scroll>
        <Stack spacing="2">
          <Layout margins color={color}>
            <Layout.Header
              titleSize="form"
              light
              spaced
              title={title}
              subtitle={subtitle}
              asset={asset}
            />
          </Layout>

          <Layout margins bottomSpace>
            <Stack>{children}</Stack>
          </Layout>
        </Stack>
      </Layout.Scroll>
      {!!actions && actions?.length > 0 && (
        <View>
          <BottomActions actions={actions} />
        </View>
      )}
    </SafeAreaView>
  );
};

export default GuideBlueprint;
