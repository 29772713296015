import create from 'zustand';
import { persist } from 'zustand/middleware';
import { localStorage } from '@app/utils/storage';

type HealthContext = 'FFM' | 'GA';

interface HealthStore {
  applicationID: string;
  context: HealthContext;
  setID: (applicationID: string) => void;
  setHealthContext: (context: HealthContext) => void;
  reset: () => void;
}

export const useHealthStore = create<HealthStore>(
  persist(
    (set, get) => ({
      // application id
      applicationID: '',
      setID: (applicationID: string) => {
        set({ applicationID });
      },

      // health context
      context: 'FFM',
      setHealthContext: (context: HealthContext) => {
        set({ context });
      },

      reset: () => {
        set({ applicationID: '', context: 'FFM' });
      },
    }),
    {
      name: 'health',
      getStorage: () => localStorage,
    },
  ),
);
