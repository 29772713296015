import React, { useMemo } from 'react';
import { deslug, getAnnualContributionLimit, useCopy } from '@app/utils';
import { navigate, routes } from '@navigate';
import { formatCurrency } from '@app/utils/format/currency';
import { BasicFormBlueprint } from '@app/blueprints';
import { useQuery, queries, getBankAccounts } from '@app/data';
import { GoalType } from '@app/types';
import { TransferRow } from '@app/partial';
import { TIER_RESTRICTIONS } from '@app/config';

interface TransferAmountProps {
  transferType: 'withdraw' | 'deposit';

  goal: {
    id: string;
    slug: string;
    goalType: GoalType;
    availableBalance: number;
  };
}

const TransferAmount: React.FC<TransferAmountProps> = ({ goal, transferType }) => {
  const { $ } = useCopy('catch');
  const { data } = useQuery(queries.TRANSFER);

  const { accounts, remainingLimit, remainingRetirement } = useMemo(() => {
    const banks = getBankAccounts(data);
    const goals = data?.viewer?.goals || [];

    const filteredGoals = (data?.viewer?.goals || [])?.filter((g) => {
      // can't transfer between same goal; cant deposit FROM retirement
      if (g.id === goal.id || (g.goalType === 'RETIREMENT' && transferType === 'deposit')) {
        return false;
      }

      return true;
    });

    // get remaining daily transfer amounts
    const transferLimit = TIER_RESTRICTIONS[data?.viewer?.user?.payrollProductTier]?.TRANSFER_LIMIT;
    const dailyContributions = 0; // @todo - could use this from platform
    const remainingLimit = transferLimit - dailyContributions;

    // get remaining retirement amounts
    const retirementLimit = getAnnualContributionLimit(data?.viewer?.user?.age);
    const retirementContributions =
      goals?.find((g) => g.goalType === 'RETIREMENT')?.totalContributions || 0;
    const remainingRetirement = retirementLimit - retirementContributions;

    return {
      accounts: [...banks, ...filteredGoals],
      remainingLimit,
      remainingRetirement,
    };
  }, [data]);

  const getSelectedAccount = (accountID) => {
    return accounts.find((a) => a.id === accountID);
  };

  const onSubmit = (values) => {
    const item = getSelectedAccount(values.account);

    navigate(routes.TRANSFER_CONFIRM, {
      goal,
      source: transferType === 'deposit' ? item : goal,
      destination: transferType === 'deposit' ? goal : item,
      transferAmount: values.transferAmount,
      transferType,
    });
  };

  return (
    <BasicFormBlueprint
      loading={!data}
      submitting={false}
      title=""
      formConfig={{
        onSubmit,
        initialValues: {
          account: accounts?.[0]?.id || undefined,
        },
        fields: [
          {
            name: 'transferAmount',
            type: 'amount',
            amountType: 'decimal',
            large: true,
            required: true,
            label: 'Amount',
            allowNegative: false,
            dependencies: ['account'],
            max: (values) => {
              const item = getSelectedAccount(values.account);

              const limits: Array<{ amount: number; message: string }> = [
                {
                  amount: remainingLimit,
                  message: `You can transfer up to ${$(remainingLimit)}`,
                },
              ];

              // when depositing from a goal, use that as max
              if (transferType === 'deposit' && item && item?.__typename !== 'Account') {
                limits.push({
                  amount: item.availableBalance,
                  message: `The goal only has ${$(item.availableBalance)} available`,
                });
              }

              if (transferType === 'withdraw') {
                // can only withdraw amount in current goal
                limits.push({
                  amount: goal.availableBalance,
                  message: `The goal only has ${$(goal.availableBalance)} available`,
                });
              }

              if (transferType === 'deposit' && goal.goalType === 'RETIREMENT') {
                limits.push({
                  amount: remainingRetirement,
                  message: `You can transfer ${$(
                    remainingRetirement,
                  )} before reaching the annual retirement contribution limit.`,
                });
              }

              limits.sort((a, b) => a.amount - b.amount);
              return limits?.[0];
            },
          },
          {
            name: 'account',
            type: 'sheet',
            label: transferType === 'deposit' ? 'Transfer from' : 'Transfer to',
            title: transferType === 'deposit' ? 'Transfer from' : 'Transfer to',
            required: true,
            data: accounts,
            renderOption: ({ item, onPress, display, disabled }) => (
              <TransferRow
                key={item?.id}
                item={item}
                onPress={onPress}
                display={display}
                disabled={disabled}
              />
            ),
          },
        ],
      }}
    />
  );
};

export const TransferAmountView = {
  name: routes.TRANSFER_AMOUNT,
  component: TransferAmount,
  options: {
    title: ({ goal, transferType }) =>
      `${transferType === 'withdraw' ? 'From' : 'To'} ${deslug(goal?.slug)}`,
    subtitle: ({ goal, transferType }) =>
      transferType === 'withdraw'
        ? `${formatCurrency(goal?.availableBalance)} available`
        : undefined,
  },
};
