import React, { useState } from 'react';
import { formatDistance } from 'date-fns';

import { Section, Stack } from '@layouts';
import { useCopy, toDate, goToHealthApp } from '@app/utils';
import { useDeprecatedQuery, useDeprecatedMutation } from '@data';
import { SheetBlueprint } from '@app/blueprints';
import { ComplexCard } from '@uikit/components/ComplexCard';
import { ActionSheet } from '@uikit/components/ActionSheet';

const InProgressApplicationUpdates = ({ year }) => {
  const { c } = useCopy('catch.health.InProgressApplicationUpdates');
  const { refetch, coverageYear, enrollmentID, enrollments, loading } = useDeprecatedQuery(
    'healthInsuranceEnrollments',
    {
      fetchPolicy: 'cache-first',
      variables: { status: ['PREFILL', 'INITIAL_SEP', 'INITIAL_OEP'] },
    },
  );
  const [remove] = useDeprecatedMutation('removeCICPrefill', {
    onCompleted: () => {
      setRemoving(false);
      refetch();
    },
  });

  const inProgressEnrollments = enrollments?.filter((enrollment, idx) => enrollment?.year === year);

  const [removing, setRemoving] = useState(false);

  function removePrefill() {
    remove({
      variables: {
        id: enrollmentID,
      },
    });
  }

  if (!coverageYear) {
    return null;
  }
  return (
    <>
      {inProgressEnrollments?.length > 0 && !loading && (
        <Section title={c('title')}>
          <Stack spacing="0b">
            {inProgressEnrollments.map((enr) => (
              <ComplexCard
                loading={loading || removing}
                key={enr.id}
                testID="RESUME"
                onPress={() => goToHealthApp(enr.application)}
                onDismiss={() => setRemoving(true)}
                status={c('draft')}
                title={c('coverageCaption', { coverageYear })}
                subtitle="In Progress"
                detail={c('updated', {
                  distance: formatDistance(toDate(enr.lastUpdated), new Date()),
                })}
              />
            ))}
          </Stack>
        </Section>
      )}

      <ActionSheet isOpen={removing} onRequestClose={() => setRemoving(false)}>
        <SheetBlueprint
          title={c('removingTitle')}
          subtitle={c('removingSubtitle')}
          actions={[
            {
              onAction: () => setRemoving(false),
              label: c('dismiss'),
            },
            {
              onAction: removePrefill,
              label: c('confirm'),
              destructive: true,
            },
          ]}
        />
      </ActionSheet>
    </>
  );
};

export default InProgressApplicationUpdates;
