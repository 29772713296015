import React, { useMemo } from 'react';
import { ViewStyle } from 'react-native';
import { IncomeTransactionSource } from '@types';
import { Asset } from '@components/Asset';
import { assets } from '@uikit/theme/_constants';
import { Carousel, Stack } from '@layouts';

type IncomeSource = {
  id: string;
  ruleID: string;
  tagID: string;
  tag: string;
  text: string;
  subtag: string;
  count: number;
  logoUrl: string;
  taggedSource?: {
    id: string;
  };
  incomeAutomationRule?: {
    id: string;
    automationType: 'NEVER' | 'ALWAYS' | 'SOMETIMES';
  };
};

const score = (source) => {
  const ruleType = source?.incomeAutomationRule?.automationType;
  if (ruleType === 'ALWAYS') return 2;
  if (ruleType === 'SOMETIMES') return 1;
  if (!ruleType) return -1;
};

const IncomeSourceRow: React.FC<{
  incomeSources: Array<IncomeSource> | Array<IncomeTransactionSource>;
  style?: ViewStyle;
  handleSourcePress?: (id: string) => void;
  loading: boolean;
}> = ({ incomeSources, loading, handleSourcePress, style }) => {
  const assetParams = (source: IncomeSource) =>
    source?.taggedSource
      ? Asset.configureSource(source?.taggedSource)
      : source?.logoUrl
      ? Asset.configureSource(source)
      : {};

  const _sources = useMemo(
    () =>
      incomeSources
        ?.map((is) => ({ ...is, score: score(is) }))
        ?.sort((a, b) => (a.text < b.text ? -1 : 1))
        ?.sort((a, b) => b.score - a.score),
    [incomeSources],
  );

  return (
    <Stack spacing="0b" style={[style]}>
      <Carousel spacing="1" itemWidth={assets?.md}>
        {_sources?.map((source, idx) => (
          <Asset
            loading={loading}
            key={source?.id || source?.taggedSource?.id || idx}
            bg="skeleton"
            {...assetParams(source)}
            badge={source?.score === 2 && 'auto'}
            size="md"
            shape="circle"
            disabled={!source?.id || source?.score < 0}
            onPress={source?.id && handleSourcePress ? () => handleSourcePress(source.id) : null}
            pressableProps={{ haptics: source ? 'MEDIUM' : 'ERROR' }}
          />
        ))}
      </Carousel>
    </Stack>
  );
};

export default IncomeSourceRow;
