import React from 'react';
import { routes } from '@navigate';
import { Layout } from '@app/layouts';

/**
 * Quick Renew Plan
 *
 * @todo only show this page if there are related plans to show (otherwise skip and let user select a plan)
 */
const HealthRenewPlan = ({ handleNext, loading }) => {
  return <Layout.Header title="Plan" />;
};

export const HealthRenewPlanView = {
  name: routes.HEALTH_RENEW_PLAN,
  component: HealthRenewPlan,
  options: {},
};
