import gql from 'graphql-tag';
import { GoalSlug, GoalStatus, GoalType, IncomeAction, IncomeTransactionSource } from '@types';

export interface IncomeTransactionQueryData {
  viewer: {
    id: string;
    savingsAccountMetadata?: {
      isAccountReady?: boolean;
    };
    incomeTransaction: {
      id: string;
      status: IncomeAction;
      date?: string;
      autoDelayBatchTime?: string;
      amount: number;
      transferAmount: number;
      source: IncomeTransactionSource;
      transfer?: {
        id: string;
        returnCode?: string;
      };
      goalBreakdowns: Array<{
        id: string;
        status: IncomeAction;
        amount: number;
        percentage: number;
        isLimitedByMax: boolean;
        goal: {
          id: string;
          goalType: GoalType;
          status: GoalStatus;
          name: string;
          slug: GoalSlug;
          title: string;
        };
      }>;
    };
  };
}

export interface IncomeTransactionQueryVars {
  paycheckID: string;
}

export const IncomeTransactionQuery = gql`
  query IncomeTransactionQuery($paycheckID: ID!) {
    viewer {
      id
      savingsAccountMetadata {
        isAccountReady
      }
      incomeTransaction(id: $paycheckID) {
        id
        status
        date
        amount
        transferAmount
        autoDelayBatchTime
        transfer {
          id
          returnCode
        }
        goalBreakdowns {
          id
          status
          amount
          percentage
          isLimitedByMax
          type
          goal {
            id
            goalType
            status
            name
            slug
            title @client
          }
        }
        source {
          id
          ruleID
          text
          sourceDetail
          canBeRule
          incomeAutomationRule {
            id
            automationType
            workType
          }
          taggedSource {
            id
            name
            logo {
              url
            }
          }
        }
      }
    }
  }
`;
