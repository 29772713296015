import React from 'react';
import { useQuery, queries, OnboardingQueryData } from '@data';
import { IntroBlueprint } from '@blueprints';
import { routes } from '@navigate';
import { useCopy } from '@app/utils';

const SetupPayroll = ({ handleNext }) => {
  const { c } = useCopy('catch.onboarding.setupPayroll');
  const { loading, data } = useQuery<OnboardingQueryData>(queries.ONBOARDING, {
    fetchPolicy: 'cache-first', // @todo: this should really be the default regardless
  });

  return (
    <IntroBlueprint
      loading={loading}
      disabled={false}
      title={c('title', { nickname: data?.viewer?.user?.nickname })}
      subtitles={[c('subtitle')]}
      onNext={handleNext}
    />
  );
};

export const SetupPayrollView = {
  name: routes.SETUP_PAYROLL,
  component: SetupPayroll,
  options: IntroBlueprint.options,
};
