import React from 'react';
import { View } from 'react-native';
import { Text, useTheme } from '@uikit';

// fieldName: string;
// label: string;
// error?: string;
// tooltip?: string;
// optional?: boolean;
// required?: boolean;

const BaseWrapper: React.FC = ({ label, error, children }) => {
  const { theme } = useTheme();

  return (
    <View style={theme.bottomGutter2}>
      <Text label>{label}</Text>
      {children}
      {!!error && (
        <Text size="sm" color="error" weight="medium" style={theme.topGutter1}>
          {error}
        </Text>
      )}
    </View>
  );
};

export default BaseWrapper;
