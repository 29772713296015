import { useState, useEffect } from 'react';

// This should help create IDs while avoiding collision
let id = 0;
const genID = () => ++id;

export const useID = (prefix = 'c') => {
  const [id, setID] = useState(null);
  useEffect(() => setID(genID()), []);
  return `${prefix}-${id}`;
};
