import React, { useMemo } from 'react';
import { FormLabel, ActionSheet } from '@uikit';
import { InputProps } from '../types';
import { useModal } from '@app/hooks/useModal';
import { SheetBlueprint } from '@app/blueprints';
import { Stack } from '@app/layouts';

interface SheetInputProps<T> extends InputProps<T> {
  renderOption: (props: {
    item: { id: string };
    onPress: () => void;
    disabled: boolean;
    selected: boolean;
    display: boolean;
  }) => React.ReactNode;
  title?: string;
  data?: Array<{ id: string }>;
  getValue?: (item) => string;
}

const SheetInput = <T extends any>({
  testID,
  name,
  label,
  sublabel,
  help,
  optional,
  value,
  onChange,
  onBlur,
  title,
  data,
  renderOption,
  getValue,
  disabled,
}: SheetInputProps<T>) => {
  const { isOpen, openModal, closeModal } = useModal();

  const val = (item) => {
    return getValue ? getValue(item) : item.id;
  };

  const selectedItem = useMemo(() => {
    return (data || []).find((item) => {
      return val(item) === value;
    });
  }, [data, value]);

  const selectItem = (item) => {
    onChange(val(item));
    closeModal();
    onBlur();
  };

  return (
    <>
      <FormLabel label={label} sublabel={sublabel} help={help} optional={optional} />
      {renderOption({
        item: selectedItem,
        onPress: openModal,
        disabled,
        display: true,
        selected: !!selectedItem,
      })}
      <ActionSheet isOpen={isOpen} onRequestClose={closeModal}>
        <SheetBlueprint title={title} hasBottomActions>
          <Stack separatorComponent>
            {(data || []).map((item) => {
              return renderOption({
                item,
                onPress: () => selectItem(item),
                disabled,
                display: false,
                selected: value === val(item),
              });
            })}
          </Stack>
        </SheetBlueprint>
      </ActionSheet>
    </>
  );
};

export default SheetInput;
