import React, { useCallback } from 'react';

import { BasicFormBlueprint, SplitFormBlueprint } from '@blueprints';
import {
  queries,
  mutations,
  useQuery,
  useMutation,
  HealthApplicationQueryData,
  SubmitHealthApplicationResponse,
  SubmitHealthApplicationVars,
  updates,
} from '@data';
import { goToHealthApp, handleHealthResponse } from '@util';
import { open, routes, sheets, useSheet } from '@navigate';
import { getInitialValues, formatPayload } from './agreementsUtils';
import { fields } from './agreementsFields';
import shallow from 'zustand/shallow';
import { loaders } from '@app/config';

const Agreements = ({ layout, isResubmit, applicationID, isGA, handleNext }) => {
  const { openLoader } = useSheet(
    (state) => ({ openLoader: () => state.open(sheets.LOADER, loaders.HEALTH_LONG_WAIT) }),
    shallow,
  );

  const { data } = useQuery<HealthApplicationQueryData>(queries.HEALTH_APPLICATION, {
    variables: { applicationID },
    skip: !applicationID,
  });

  const [upsert, { loading: submitting }] = useMutation<
    SubmitHealthApplicationResponse,
    SubmitHealthApplicationVars
  >(mutations.SUBMIT_HEALTH_APPLICATION, {
    ...handleHealthResponse({ onCompleted: handleNext }),
    update: (cache, result) =>
      updates.ELIG_REFETCHED(cache, {
        didRefetch: true,
        applicationID,
      }),
  });

  const onSubmit = useCallback(
    (values) => {
      openLoader();
      upsert(formatPayload({ applicationID, values, isResubmit }));
    },
    [applicationID, isResubmit],
  );

  // handles two different layouts
  const Blueprint = layout === 'split' ? SplitFormBlueprint : BasicFormBlueprint;

  return (
    <Blueprint
      title="Agreements"
      loading={!data}
      submitting={submitting}
      formConfig={{
        initialValues: getInitialValues(data?.viewerTwo?.health?.application, isResubmit, isGA),
        fields,
        onSubmit,
      }}
    />
  );
};

export const HealthRenewResubmitView = {
  name: routes.HEALTH_RENEW_RESUBMIT,
  component: (props) => (
    <Agreements
      applicationID={props?.continueApplication?.id}
      layout="basic"
      isResubmit
      handleNext={() =>
        goToHealthApp({ ...props?.continueApplication, lastUsedRoute: 'results' }, { goTo: open })
      }
    />
  ),
  options: {},
};

export const AgreementsView = {
  name: routes.EDE_AGREEMENTS,
  component: (props) => <Agreements {...props} layout="split" />,
  options: {
    ...SplitFormBlueprint.options,
    title: 'Agreements',
  },
};
