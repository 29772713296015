import React, { useState, useMemo } from 'react';
import { navigate, routes } from '@navigate';
import { Copy, FieldConfig, GoalSlug } from '@types';
import { OnboardingFormBlueprint } from '@app/blueprints';
import { fields } from '@app/config';
import { formatPayload } from '@app/forms';
import {
  useQuery,
  queries,
  useMutation,
  mutations,
  AccountSetupQueryData,
  UpdateUserResponse,
  UpdateUserVars,
} from '@data';
import { FamiliarUserModal } from '@app/_common';
import { ActionSheet } from '@app/_ui-kit';
import { hasIdentityData } from '@app/utils';

const getInitialValues = (user = {}, fields) => {
  let values = {};

  fields.forEach((field) => {
    if (field.type === 'legalName') {
      Object.keys(field.subfields).forEach((subfield) => {
        values[subfield] = user[subfield];
      });
    } else {
      values[field.name] = user[field.name];
    }
  });

  return values;
};

const AccountSetupInfo: React.FC<{
  slug: GoalSlug;
  title: string;
  subtitle?: Copy;
  field: FieldConfig;
  handleNext: (updates: object) => void;
  lookup: () => void;
  afterIdentity: () => void;
}> = ({ title, subtitle, slug, field, handleNext, afterIdentity, lookup }) => {
  const fields = [field];
  const [isFamiliar, setFamiliar] = useState<boolean>(false);

  const { loading, data } = useQuery<AccountSetupQueryData>(queries.ACCOUNT_SETUP, {
    fetchPolicy: 'cache-first',
  });

  const [updateUser, { loading: updating }] = useMutation<UpdateUserResponse, UpdateUserVars>(
    mutations.UPDATE_USER,
  );

  const initialValues = useMemo(() => {
    return getInitialValues(data?.viewer?.user, fields);
  }, [data?.viewer.user, fields]);

  const formConfig = {
    initialValues,
    fields,
    onSubmit: (values) => {
      const isSSN = field?.type === 'ssn';
      const isAddress = field?.type === 'address';
      const isHealthFlow = slug === 'health';

      // perform lookup when SSN was entered OR when address is entered in link flow
      const shouldLookup = isSSN || (isAddress && isHealthFlow);

      const payload = formatPayload(values, fields, {
        id: data?.viewer.user.id,
      });

      updateUser({
        variables: { user: payload },
        onCompleted: async (data) => {
          let canIDProof = false;

          const identityRedirect = !!afterIdentity && hasIdentityData(data?.updateUserNew?.user);

          if (data.updateUserNew.error || !data.updateUserNew.user) {
            setFamiliar(true);
          } else if (shouldLookup || identityRedirect) {
            if (!!lookup) {
              // force the lookup during health flow (async)
              if (isHealthFlow) {
                const results = await lookup(true);
                canIDProof = results?.data?.edeApplicationSearch?.canIDProof;
              } else {
                lookup();
              }
            }

            if (identityRedirect) {
              navigate(afterIdentity);
            } else {
              handleNext({
                isRetirement: slug === 'retirement',
                isHealth: slug === 'health',
                canIDProof,
                afterIdentity,
              });
            }
          } else {
            handleNext({
              isRetirement: slug === 'retirement',
              isHealth: slug === 'health',
              canIDProof,
              afterIdentity,
            });
          }
        },
      });

      // everything is immediate EXCEPT ssn/address (because we want to check the identity status after those!)
      if (!isSSN && !isAddress) {
        handleNext({ isRetirement: slug === 'retirement', isHealth: slug === 'health' });
      }
    },
  };

  return (
    <>
      <OnboardingFormBlueprint
        loading={loading}
        disabled={updating}
        title={title}
        subtitles={[subtitle]}
        formConfig={formConfig}
      />
      <ActionSheet isOpen={isFamiliar} onRequestClose={() => setFamiliar(false)}>
        <FamiliarUserModal close={() => setFamiliar(false)} />
      </ActionSheet>
    </>
  );
};

export const AccountSetupNameView = {
  name: routes.ACCOUNT_SETUP_NAME,
  component: (props) => (
    <AccountSetupInfo {...props} title="Legal name" field={fields.LEGAL_NAME} />
  ),
  options: {},
};

export const AccountSetupDOBView = {
  name: routes.ACCOUNT_SETUP_DOB,
  component: (props) => (
    <AccountSetupInfo
      {...props}
      title="Date of birth"
      subtitle={
        props.slug === 'health'
          ? 'We need this information to connect your marketplace plan'
          : undefined
      }
      field={fields.DOB}
    />
  ),
  options: {},
};

export const AccountSetupAddressView = {
  name: routes.ACCOUNT_SETUP_ADDRESS,
  component: (props) => (
    <AccountSetupInfo {...props} title="Current home address" field={fields.LEGAL_ADDRESS} />
  ),
  options: {},
};

export const AccountSetupSSNView = {
  name: routes.ACCOUNT_SETUP_SSN,
  component: (props) => (
    <AccountSetupInfo
      {...props}
      title="Social Security Number"
      subtitle={
        props.slug === 'health'
          ? 'We need this information to connect your marketplace plan'
          : undefined
      }
      field={fields.SSN}
    />
  ),
  options: {},
};
