import { useMemo, useEffect, useState } from 'react';

import {
  useDeprecatedMutation,
  useQuery,
  queries,
  EnrollmentGroupsQueryData,
  useMutation,
  mutations,
  updates,
} from '@data';
import { navigate, routes, useSheet, sheets } from '@navigate';
import shallow from 'zustand/shallow';
import { loaders } from '@app/config';

/**
 * useEnrollmentGroups gets the list of current enrollment groups
 * Allows for adjustments of group members
 */
export const useEnrollmentGroups = ({ applicationID }) => {
  const { loading, data } = useQuery<EnrollmentGroupsQueryData>(queries.ENROLLMENT_GROUPS, {
    variables: { applicationID },
    skip: !applicationID,
  });

  const { enrollmentGroups, members, previouslySelectedID } = useMemo(() => {
    return {
      enrollmentGroups: data?.viewerTwo?.health?.application?.enrollmentGroups || [],
      members: data?.viewerTwo?.health?.application?.exchangeEligibleMembers || [],
      previouslySelectedID: data?.viewerTwo?.healthExplorerData?.providerPlanID,
    };
  }, [data?.viewerTwo?.health?.application]);

  const { openLoader, replaceWithLoader, confirmSelection, close } = useSheet(
    (state) => ({
      openLoader: () => state.open(sheets.LOADER, loaders.HEALTH_UPDATING_PLANS),
      replaceWithLoader: () => state.replace(sheets.LOADER, loaders.HEALTH_UPDATING_PLANS),
      confirmSelection: (data) => state.open(sheets.NEXT_PLAN_SELECTION, data),
      close: state.closeAll,
    }),
    shallow,
  );

  const [groupIndex, setGroupIndex] = useState(0); // for keeping track of current group
  const [selectedPlanName, setSelectedPlanName] = useState(''); // for stashing plan name between groups

  /**
   * Current group
   * the enrollment group we are actively viewing
   */
  const currentGroup = enrollmentGroups?.[groupIndex];

  /**
   * Next group
   * the next enrollment group, if any
   */
  const nextGroup = enrollmentGroups?.[groupIndex + 1];

  /**
   * Checks if there are other selected plans
   * If there are other groups with selected plans, we should show them
   */
  const hasOtherPlans = enrollmentGroups?.reduce((val, g) => {
    return val || (g?.id !== currentGroup?.id && !!g?.selectedProviderPlanID);
  }, false);

  /**
   * Possible members:
   * the list of members that are options as "shopping for coverage"
   * includes members of current group AND orphaned members
   */
  const possibleShoppingMembers = members?.filter(
    (m) => m?.healthEnrollmentGroupID === currentGroup?.id || !m?.healthEnrollmentGroupID,
  );

  /**
   * Orphaned members:
   * the list of members that are not attached to any enrollment group
   */
  const orphanedMembers = members?.filter((m) => !m?.healthEnrollmentGroupID);
  const selectedMembers = members?.filter((m) => !!m?.healthEnrollmentGroupID);

  /**
   * Next members:
   * the list of members coming up next
   * either 1) next group OR 2) the orphaned members
   */
  const nextMembers = nextGroup?.members?.length > 0 ? nextGroup?.members : orphanedMembers;

  /**
   * Mutation to select a plan for enrollment group
   * note: this uses upsertEnrollmentGroups
   * but it does not return any data afterwards
   */

  const [selectEnrollmentGroupPlan, { loading: selectingPlan }] = useMutation(
    mutations.UPSERT_ENROLLMENT_GROUPS,
    { update: updates.UPDATE_ENROLLMENT_GROUPS },
  );

  /**
   * Mutation to change emmbers on enrollment group
   * note: this uses upsertEnrollmentGroups
   * but it can be used to refresh the plan results
   */
  const [upsertEnrollmentGroupMembers, { loading: updatingMembers }] = useMutation(
    mutations.UPSERT_ENROLLMENT_GROUPS,
    { update: updates.UPDATE_ENROLLMENT_GROUPS },
  );

  // Note: when enrollment groups changes, we want the show the overlay accordingly
  useEffect(() => {
    if (updatingMembers) {
      openLoader();
    } else {
      close();
    }
  }, [updatingMembers]);

  /**
   * Mutation to change emmbers on enrollment group
   * note: this uses upsertEnrollmentGroups
   * but it can be used to refresh the plan results
   */
  const [deleteEnrollmentGroup, { loading: deleting }] =
    useDeprecatedMutation('deleteEnrollmentGroup');

  /**
   * Given a plan selection, selects that plan
   * for the current enrollment group
   * @param {PlanSelection} planSelection
   */
  const selectPlan = async (planSelection, options) => {
    // selects the plan for the enrollment group
    await selectEnrollmentGroupPlan({
      variables: {
        input: {
          enrollmentGroupInputs: [
            {
              id: currentGroup?.id,
              ...planSelection,
            },
          ],
        },
      },
      onCompleted: options?.onCompleted,
    });

    // stashes the plan name
    setSelectedPlanName(planSelection.selectedPlanName);

    /**
     * opens the plan selection + next group sheet
     * open the modal unless its last EG and is dental
     */
    if (
      groupIndex === enrollmentGroups?.length - 1 &&
      currentGroup.enrollmentType === 'DENTAL_INSURANCE'
    ) {
      goNext();
    } else {
      confirmSelection({
        planName: planSelection.selectedPlanName,
        currentMembers: currentGroup?.members || [],
        orphanedMembers,
        nextGroup,
        handleNextGroup,
        handleSkipGroup,
        handleDentalGroup,
      });
    }
  };

  /**
   * Given the selected + deselected member ids,
   * updates the current group to reflect the changes in members
   * @param {[MemberID]} selected the members to add to group
   * @param {[MemberID]} deselected the members to remove from group
   */
  const updateMembers = ({ selected, deselected }) => {
    upsertEnrollmentGroupMembers({
      variables: {
        input: {
          enrollmentGroupInputs: [
            {
              id: currentGroup?.id,
              memberIDs: selected,
              removeMemberIDs: deselected,
            },
          ],
        },
      },
    });
  };

  /**
   * Create group
   * creates a new enrollment group with a list of members
   * @param {*} param0
   */
  const createGroup = ({ memberIDs, onCompleted, enrollmentType = 'HEALTH_INSURANCE' }) => {
    upsertEnrollmentGroupMembers({
      variables: {
        input: {
          enrollmentGroupInputs: [
            {
              applicationID,
              memberIDs,
              enrollmentType,
            },
          ],
        },
      },
    }).then(onCompleted);
  };

  /**
   * Delete the currently selected group
   * + move onto the next page
   */
  const deleteGroup = ({ id = currentGroup?.id }) => {
    deleteEnrollmentGroup({ variables: { id } }).then(() => {
      goNext();
    });
  };

  // Handles the navigation to nextPage
  const goNext = () => {
    close();

    // todo - should we check for SEP out of date here?
    navigate(routes.EDE_APTC_AGREEMENT);
  };

  /**
   * Switches the group
   * Adds an artificail timeout to denote to user
   * that the group is changing + plans are updating
   */
  const changeGroup = (index) => {
    replaceWithLoader();

    setTimeout(() => {
      setGroupIndex(index);
      close();
    }, 250);
  };

  /**
   * handles the next group
   * - if there is a next group, go to that
   * - if there are orphaned members, create a group for them
   * - otherwise, exit plan selection
   */
  const handleNextGroup = () => {
    if (enrollmentGroups?.length - 1 > groupIndex) {
      changeGroup(groupIndex + 1);
    } else if (orphanedMembers?.length > 0) {
      createGroup({
        memberIDs: orphanedMembers?.map((m) => m?.id),
        onCompleted: () => setGroupIndex(groupIndex + 1),
      });
    } else {
      goNext();
    }
  };

  /**
   * Handles skipping the next group
   * - if there is already another group, delete it so people are orphaned
   * - if user wants to go to dental, invoke the dental handler
   * - otherwise, go to the next page in flow
   */
  const handleSkipGroup = ({ goToDental, skippingCurrentGroup }) => {
    if (skippingCurrentGroup) {
      deleteGroup({ id: currentGroup?.id });
    } else if (enrollmentGroups?.length - 1 > groupIndex || !enrollmentGroups?.length) {
      deleteGroup({ id: nextGroup?.id });
    } else if (orphanedMembers?.length === 0) {
      goNext();
    } else {
      if (goToDental) {
        handleDentalGroup();
      } else {
        goNext();
      }
    }
  };

  /**
   * Creates a dental EG
   * - When handling dental group, either go to next dental group
   * or create a new one with everyone currently on plan
   */
  const handleDentalGroup = () => {
    if (currentGroup?.enrollmentType !== 'DENTAL_INSURANCE' || !nextGroup) {
      //only create new group if we don't already have an existing dental group
      if (!enrollmentGroups.some((g) => g.enrollmentType === 'DENTAL_INSURANCE')) {
        replaceWithLoader();
        createGroup({
          memberIDs: selectedMembers.map((m) => m.id),
          enrollmentType: 'DENTAL_INSURANCE',
          onCompleted: () => {
            setGroupIndex(groupIndex + 1);
            close();
          },
        });
      } else {
        changeGroup(groupIndex + 1);
      }
    } else if (currentGroup?.enrollmentType === 'DENTAL_INSURANCE') {
      goNext();
    } else {
      changeGroup(groupIndex + 1);
    }
  };

  return {
    loading,
    groupIndex,
    updatingMembers,
    selectingPlan,
    deleting,
    applicationID,
    selectedPlanName,
    enrollmentGroups,
    currentGroup,
    currentMembers: currentGroup?.members || [],
    possibleShoppingMembers,
    orphanedMembers,
    nextMembers,
    nextGroup,
    hasOtherPlans,
    previouslySelectedID,
    changeGroup,
    deleteGroup,
    updateMembers,
    selectPlan,
    handleNextGroup,
    handleSkipGroup,
    handleDentalGroup,
  };
};
