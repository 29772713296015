import React from 'react';
import { Platform } from 'react-native';

import { CalloutLayout, Toolbar } from '@layouts';
import { setupNotifications, useCopy } from '@app/utils';
import { routes, ScreenDefinition } from '@navigate';
import { Button } from '@uikit';
import { useDeprecatedMutation } from '@data';

const SetNotifications = ({ handleNext }) => {
  const { c } = useCopy('catch.basics.enableNotifications');
  const [add] = useDeprecatedMutation('addPushToken');

  const onSubmit = () => {
    setupNotifications({
      onRegister: (token) =>
        // @ts-ignore
        add({ variables: { token, deviceType: Platform.OS.toUpperCase() } }),
    });

    handleNext();
  };

  return (
    <CalloutLayout
      loading={false}
      render="bell"
      title={c('title')}
      subtitles={[c('subtitle')]}
      toolbar={
        <Toolbar>
          <Button testID="enable-notifications" accentColor="brand" onPress={onSubmit}>
            {c('button.yes')}
          </Button>
          <Button testID="skip-notifications" accentColor="brandLight" onPress={() => handleNext()}>
            {c('button.no')}
          </Button>
        </Toolbar>
      }
    />
  );
};

export const SetNotificationsView: ScreenDefinition = {
  name: routes.SET_NOTIFICATIONS,
  component: SetNotifications,
  options: {},
};
