import React from 'react';
import { View, Text } from 'react-native'; // eslint-disable-line
import { Field, formValues, Fields } from 'redux-form';
import { compose } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';

import {
  useCopy,
  ensureZero,
  formatDate,
  constants,
  getCopyValues,
  formatNumber,
} from '@app/utils';
import { useDebug } from '@app/hooks';
import { formatCurrency, normalizeCurrency } from '@app/utils/format/currency';
import { useTheme, Tooltip, Info, s } from '@uikit';
import { TextField, PickerField } from '@app/forms_old/inputs';

const PREFIX = 'catch.ede.enums';
const FIELDS = 'catch.ede.HealthEnrollment';

const COPY = {
  yes: <FormattedMessage id={`${PREFIX}.yes`} />,
  no: <FormattedMessage id={`${PREFIX}.no`} />,
  optional: <FormattedMessage id={`${PREFIX}.optional`} />,
};

const components = {
  text: TextField,
  select: PickerField,
};

const formaters = {
  currency: formatCurrency,
  date: formatDate,
  hours: formatNumber,
};
const parsers = {
  currency: compose(ensureZero, normalizeCurrency),
};
const placeholders = {
  currency: '$',
  date: 'MM/DD/YYYY',
  dropdown: ' ',
  phone: '(555) 555 - 5555',
  description: { id: `${FIELDS}.descriptionHelpText` },
  hours: '40',
};

const HealthField = ({
  legendHeading,
  format,
  divider,
  disabled,
  style,
  name,
  type,
  qID,
  lID,
  form,
  bare,
  short,
  large,
  binary,
  options,
  equals,
  renderIf,
  viewport,
  setName,
  subtext,
  component,
  signature,
  compound,
  labelType,
  fullWidth,
  column,
  optional,
  multiline,
  values,
  gutter,
  children,
  numberOfLines,
  allowOther,
  longOptions,
  hideSubLabel,
  componentProps,
  isWarning,
  labelOverride, // bullshit prop, don't ever use
  useOriginalOptions,
  intl: { formatMessage, formatNumber },
  zip4,
  required,
  isGA,
  ...rest
}) => {
  const { theme } = useTheme();
  const { showLayout } = useDebug();

  const { c } = useCopy(FIELDS);

  const number = (qID || lID) && (
    <View style={[theme.urgentCalloutBg, theme.smallCorners, theme.ySpace0b, theme.xSpace1]}>
      <Text mono size="sm" weight="medium" color="text">
        {qID || lID.split('_')[0]}
      </Text>
    </View>
  );

  const getCopy = (id) => {
    const vals = getCopyValues(id);
    return (
      c(id, {
        ...rest,
        ...vals,
        amount: rest.amount
          ? formatNumber(rest.amount, {
              maximumFractionDigits: 0,
              minimumFractionDigits: 0,
              style: 'currency',
              currency: 'USD',
            })
          : null,
      }) || '_NA'
    );
  };

  const qMsg = getCopy(`${qID}_Q`);
  const lMsg = getCopy(lID);
  const tMsg = getCopy(`${qID}_T${isGA ? '_GA' : ''}`);
  // If we provide a label id, the Q is relegated to sublabel
  const label =
    labelOverride || (lID && lMsg !== '_NA' ? lMsg : qID && qMsg !== '_NA' ? qMsg : undefined);
  const sublabel = !hideSubLabel && lID && qMsg !== '_NA' ? qMsg : undefined;
  const tooltip = qID && tMsg !== '_NA' ? tMsg : undefined;

  const opts = useOriginalOptions
    ? options
    : legendHeading
    ? [{ label: legendHeading, description: qMsg }]
    : (options || constants[setName]) &&
      (options || constants[setName]).map((opt) => ({
        ...opt,
        label: formatMessage(
          {
            id: `${PREFIX}.${opt.id || opt.value || opt}`,
            defaultMessage: opt.label,
          },
          {
            ...rest,
            ...(opt.amount
              ? {
                  ...opt,
                  amount: formatNumber(opt.amount, {
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0,
                    style: 'currency',
                    currency: 'USD',
                  }),
                }
              : opt),
          },
        ),
      }));
  const Component = component || components[type];
  const formater = formaters[format];
  const parser = parsers[format];
  const placeholder = placeholders[format]
    ? typeof placeholders[format] !== 'string'
      ? formatMessage(placeholders[format])
      : placeholders[format]
    : type === 'select' &&
      formatMessage({
        id: 'catch.placeholders.select',
        defaultMessage: '',
      });
  const optionalLabel = <Text style={[theme.fp, theme.subtleText]}> {COPY['optional']}</Text>;

  if (
    !!renderIf &&
    (Array.isArray(equals)
      ? !equals.some((item) => item === rest[renderIf])
      : rest[renderIf] !== equals)
  ) {
    return null;
  }

  if (component) {
    return (
      <Component
        {...rest}
        {...componentProps}
        name={name}
        form={form}
        label={
          optional ? (
            <React.Fragment>
              {label}
              {optionalLabel}
            </React.Fragment>
          ) : (
            label
          )
        }
        extraLabel={
          !!tooltip && (
            <Tooltip label={tooltip}>
              <Info />
            </Tooltip>
          )
        }
        subtext={showLayout ? number : subtext}
        placeholder={placeholder}
        destroyOnUnmount={false}
        options={opts}
        testID={qID}
        zip4={zip4}
      />
    );
  }

  if (Array.isArray(name)) {
    return (
      <View>
        <Text style={theme.p}>
          {label} {optional && optionalLabel}
        </Text>
        <Fields
          names={name}
          component={(fields) => (
            <View style={s.row}>
              {name.map((key, i) => (
                <View key={`f-${i}`} style={i !== 0 && s?.leftGutter}>
                  <Component {...fields[key]} label={options[i]} />
                </View>
              ))}
            </View>
          )}
        />
        {showLayout && number}
      </View>
    );
  }
  // A bit of an implicit interface but I'd like it to be the default
  const isBinary = binary || (type === 'radio' && !opts);
  const defaultBinary = [
    {
      value: 'YES',
      label: COPY['yes'],
    },
    {
      value: 'NO',
      label: COPY['no'],
    },
  ];
  return (
    <Field
      name={name}
      short={short}
      qaName={name}
      options={opts || defaultBinary}
      parse={parser}
      format={formater}
      viewport={viewport}
      sublabel={sublabel}
      component={Component}
      placeholder={placeholder}
      row={type === 'radio' && !column}
      tooltip={tooltip}
      required={required}
      disabled={disabled}
      label={
        legendHeading ? (
          showLayout && number
        ) : optional && labelType !== 'question' ? (
          <React.Fragment>
            {label}
            {optionalLabel}
          </React.Fragment>
        ) : (
          !isBinary && labelType !== 'question' && label
        )
      }
      question={
        labelType === 'question' || isBinary ? (
          <React.Fragment>
            {label}
            {optional && optionalLabel}
          </React.Fragment>
        ) : undefined
      }
      signature={signature}
      compound={compound}
      grouped={compound}
      subtext={showLayout ? number : subtext}
      fullWidth={fullWidth}
      divider={divider}
      large={large}
      style={style}
      bare={bare}
      gutter={gutter}
      values={values}
      allowOther={allowOther}
      testID={qID}
      numberOfLines={numberOfLines}
      multiline={multiline}
      longOptions={longOptions}
      // Show an error as a field
      error={isWarning ? label || tooltip : undefined}
    >
      {children}
    </Field>
  );
};

HealthField.defaultProps = {
  equals: true,
};

const mapValue = ({ renderIf }) => {
  return renderIf || '';
};

const enhance = compose(injectIntl, formValues(mapValue));

export default enhance(HealthField);
