import { DocumentNode } from 'graphql';

// keep these in alphabetical order
import { AddBankLinkItem } from './mutations/AddBankLinkItem';
import { AddPendingAccount } from './mutations/AddPendingAccount';
import { AnswerIdentityQuestions } from './mutations/AnswerIdentityQuestions';
import { ChangeEmail } from './mutations/ChangeEmail';
import { ChangePhone } from './mutations/ChangePhone';
import { ConfirmGoals } from './mutations/ConfirmGoals';
import { ConfirmIdentity } from './mutations/ConfirmIdentity';
import { ConfirmIdentityOffline } from './mutations/ConfirmIdentityOffline';
import { CreateLinkToken } from './mutations/CreateLinkToken';
import { DeleteBankLink } from './mutations/DeleteBankLink';
import { DeletePendingAccount } from './mutations/DeletePendingAccount';
import { EdeApplicationSearch } from './mutations/EdeApplicationSearch';
import { EnsureHealthApplication } from './mutations/EnsureHealthApplication';
import { FetchEligibility } from './mutations/FetchEligibility';
import { ImportAllExistingApplications } from './mutations/ImportAllExistingApplications';
import { ImportExplorerFromApp } from './mutations/ImportExplorerFromApplication';
import { RelinkBankLink } from './mutations/RelinkBankLink';
import { RemoveApplicationMember } from './mutations/RemoveApplicationMember';
import { RemoveExplorerApplicant } from './mutations/RemoveExplorerApplicant';
import { ReportHealthEnrollmentChanges } from './mutations/ReportHealthEnrollmentChanges';
import { RequestManualReview } from './mutations/RequestManualReview';
import { UnwatchAccount } from './mutations/UnwatchAccount';
import { UpdateIncomeTransaction } from './mutations/UpdateIncomeTransaction';
import { UpdateUser } from './mutations/UpdateUser';
import { UploadSignature } from './mutations/UploadSignature';
import { UpsertApplicationMembers } from './mutations/UpsertApplicationMembers';
import { UpsertExplorer } from './mutations/UpsertExplorer';
import { UpsertExplorerApplicants } from './mutations/UpsertExplorerApplicants';
import { UpsertGoal } from './mutations/UpsertGoal';
import { SubmitHealthApplication } from './mutations/SubmitHealthApplication';
import { UpsertEnrollmentGroups } from './mutations/UpsertEnrollmentGroups';
import { UpsertHealthApplication } from './mutations/UpsertHealthApplication';
import { UpsertHealthPrivacy } from './mutations/UpsertHealthPrivacy';
import { UpsertIncomeAutomationRules } from './mutations/UpsertIncomeAutomationRules';
import { UpsertIncomePreferences } from './mutations/UpsertIncomePreferences';
import { VerifyEmail } from './mutations/VerifyEmail';
import { VerifyPhone } from './mutations/VerifyPhone';
import { WatchAccount } from './mutations/WatchAccount';

// group these by section
type MutationName =
  | 'UPDATE_USER'
  | 'UPSERT_GOAL'
  | 'CONFIRM_GOALS'

  // bank links
  | 'CREATE_LINK_TOKEN'
  | 'ADD_BANK_LINK_ITEM'
  | 'RELINK_BANK_LINK'
  | 'ADD_PENDING_ACCOUNT'
  | 'WATCH_ACCOUNT'
  | 'UNWATCH_ACCOUNT'
  | 'DELETE_BANK_LINK'
  | 'DELETE_PENDING_ACCOUNT'

  // paychecks
  | 'UPDATE_INCOME_TRANSACTION'

  // income sources
  | 'UPSERT_INCOME_AUTOMATION_RULES'
  | 'UPSERT_INCOME_PREFERENCES'

  // alias
  | 'CHANGE_PHONE'
  | 'CHANGE_EMAIL'
  | 'VERIFY_PHONE'
  | 'VERIFY_EMAIL'

  // health link
  | 'HEALTH_LINK_LOOKUP'
  | 'HEALTH_LINK_IMPORT'
  | 'HEALTH_INITIAL_AGREE'
  | 'HEALTH_IDP'
  | 'HEALTH_IDP_FARS'
  | 'HEALTH_IDP_QUESTIONS'
  | 'REQUEST_MANUAL_REVIEW'

  // health explorer
  | 'UPSERT_EXPLORER'
  | 'UPSERT_EXPLORER_APPLICANTS'
  | 'REMOVE_EXPLORER_APPLICANT'
  | 'IMPORT_EXPLORER_FROM_APP'

  // health application
  | 'SUBMIT_HEALTH_APPLICATION'
  | 'UPSERT_HEALTH_APPLICATION'
  | 'UPSERT_APPLICATION_MEMBERS'
  | 'REMOVE_APPLICATION_MEMBER'
  | 'FETCH_ELIGIBILITY'
  | 'UPSERT_ENROLLMENT_GROUPS'

  // slasher
  | 'REPORT_HEALTH_ENROLLMENT_CHANGES'
  | 'ENSURE_HEALTH_APPLICATION'

  // retirement
  | 'UPLOAD_SIGNATURE';

// keep these in same order as MutationName definition
export const mutations: Record<MutationName, DocumentNode> = {
  UPDATE_USER: UpdateUser,
  UPSERT_GOAL: UpsertGoal,
  CONFIRM_GOALS: ConfirmGoals,

  // bank links
  CREATE_LINK_TOKEN: CreateLinkToken,
  ADD_BANK_LINK_ITEM: AddBankLinkItem,
  RELINK_BANK_LINK: RelinkBankLink,
  ADD_PENDING_ACCOUNT: AddPendingAccount,
  WATCH_ACCOUNT: WatchAccount,
  UNWATCH_ACCOUNT: UnwatchAccount,
  DELETE_BANK_LINK: DeleteBankLink,
  DELETE_PENDING_ACCOUNT: DeletePendingAccount,

  // paychecks
  UPDATE_INCOME_TRANSACTION: UpdateIncomeTransaction,

  // income sources
  UPSERT_INCOME_PREFERENCES: UpsertIncomePreferences,
  UPSERT_INCOME_AUTOMATION_RULES: UpsertIncomeAutomationRules,

  // alias
  CHANGE_PHONE: ChangePhone,
  CHANGE_EMAIL: ChangeEmail,
  VERIFY_PHONE: VerifyPhone,
  VERIFY_EMAIL: VerifyEmail,

  // health link
  HEALTH_LINK_LOOKUP: EdeApplicationSearch,
  HEALTH_LINK_IMPORT: ImportAllExistingApplications,
  HEALTH_INITIAL_AGREE: UpsertHealthPrivacy,
  HEALTH_IDP: ConfirmIdentity,
  HEALTH_IDP_FARS: ConfirmIdentityOffline,
  HEALTH_IDP_QUESTIONS: AnswerIdentityQuestions,
  REQUEST_MANUAL_REVIEW: RequestManualReview,

  // health explorer
  UPSERT_EXPLORER: UpsertExplorer,
  UPSERT_EXPLORER_APPLICANTS: UpsertExplorerApplicants,
  REMOVE_EXPLORER_APPLICANT: RemoveExplorerApplicant,
  IMPORT_EXPLORER_FROM_APP: ImportExplorerFromApp,

  // health application
  SUBMIT_HEALTH_APPLICATION: SubmitHealthApplication,
  UPSERT_HEALTH_APPLICATION: UpsertHealthApplication,
  UPSERT_APPLICATION_MEMBERS: UpsertApplicationMembers,
  REMOVE_APPLICATION_MEMBER: RemoveApplicationMember,
  FETCH_ELIGIBILITY: FetchEligibility,
  UPSERT_ENROLLMENT_GROUPS: UpsertEnrollmentGroups,

  // premium slasher
  REPORT_HEALTH_ENROLLMENT_CHANGES: ReportHealthEnrollmentChanges,
  ENSURE_HEALTH_APPLICATION: EnsureHealthApplication,

  // retirement
  UPLOAD_SIGNATURE: UploadSignature,
};
