import React, { useCallback, useMemo } from 'react';
import { useCopy, constants, ninetyDaysAgo, fiveYearsAgo, handleHealthResponse } from '@app/utils';
import { navigate, ScreenDefinition, routes } from '@navigate';
import {
  getMembers,
  HealthApplicationQueryData,
  mutations,
  queries,
  useMutation,
  useQuery,
} from '@data';
import { MultiMemberSplitFormBlueprint } from '@app/blueprints';
import { fields } from './medicaidDenialFields';
import { getInitialValues, formatPayload } from './medicaidDenialUtils';

/**
 * MedicaidDenialView
 * Page that shows questions for medicaid denial
 * Each member is prompted
 */
const MedicaidDenial = ({ applicationID }) => {
  const { c } = useCopy('catch.ede.MedicaidDenial');

  const { loading, data } = useQuery<HealthApplicationQueryData>(queries.HEALTH_APPLICATION, {
    variables: { applicationID },
    skip: !applicationID,
  });

  const { requestingCoverageMembers, coverageState, lastOEYear } = useMemo(() => {
    return {
      requestingCoverageMembers: getMembers(data).filter((m) => !!m.isRequestingCoverage),
      coverageState: data?.viewerTwo?.health?.application?.coverageState,
      lastOEYear: data?.reference?.health?.openEnrollmentDates?.lastOEYear,
    };
  }, [data]);

  // @ts-ignore
  const [upsert] = useMutation(mutations.UPSERT_APPLICATION_MEMBERS, handleHealthResponse({}));

  const handleUpsert = useCallback(
    (values) => upsert(formatPayload({ applicationID, values })),
    [applicationID],
  );

  return (
    <MultiMemberSplitFormBlueprint
      loading={loading}
      title={(m) => c('title', { loading, givenName: m?.givenName })}
      subtitle={(m) => c('subtitle', { relation: m?.relation })}
      members={requestingCoverageMembers}
      fields={fields}
      getInitialValues={getInitialValues}
      data={{
        medicaid: constants.medicaidCHIPNames[coverageState]?.medicaid,
        chip: constants.medicaidCHIPNames[coverageState]?.chip,
        state: constants.medicaidCHIPNames[coverageState]?.state,
        ninetyDaysAgo: ninetyDaysAgo(),
        fiveYearsAgo: fiveYearsAgo(),
        oeStartDate: 'November 1',
        oeEndDate: 'January 15',
        oeYear: lastOEYear,
      }}
      onNext={handleUpsert}
      onComplete={() => navigate(routes.EDE_MEMBER_INCOME_LIST)}
    />
  );
};

export const MedicaidDenialView: ScreenDefinition = {
  name: 'EDE_MEDICAID_DENIAL',
  component: MedicaidDenial,
  options: {
    ...MultiMemberSplitFormBlueprint.options,
    title: 'Medicaid info',
  },
};
