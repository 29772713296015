import React from 'react';
import { View } from 'react-native';

import { Text, Link, useTheme } from '@uikit';
import { Narrow, Page } from '@layouts';
import { useCopy } from '@app/utils';
import { navigate, routes } from '@navigate';

const NotFoundView = (props) => {
  const { theme } = useTheme();
  const { c } = useCopy('catch.web.notFound');
  return (
    <Page color="page">
      <Narrow>
        <View style={theme.alignCenter}>
          <Text size="h1"> {c('title')}</Text>
          <Link onPress={() => navigate(routes.HOME)}>{c('redirect')}</Link>
        </View>
      </Narrow>
    </Page>
  );
};

export default NotFoundView;
