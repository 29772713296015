import React from 'react';
import { stacks, Stack, StackComponent, StackDefinition } from '@navigate';
import { OnboardingBiometricsView } from './OnboardingBiometrics';
import { SetNotificationsView } from './SetNotifications';
import { WelcomeView } from './Welcome';

/**
 * @todo remove PreferredName and JobTitle files if not added back into onboarding by 2022-12-01
 */
const config: StackDefinition = {
  stackName: stacks.ONBOARDING_STACK,
  options: {
    layout: 'page',
    navMode: 'flowBorderless',
  },
  screens: [WelcomeView, OnboardingBiometricsView, SetNotificationsView],
};

/**
 *
 *
 * O N B O A R D I N G
 * Onboarding (Proper) Stack
 *
 */
const OnboardingStack: StackComponent = () => {
  return <Stack stackName={config.stackName} screens={config.screens} options={config.options} />;
};

OnboardingStack.config = config;
export default OnboardingStack;
