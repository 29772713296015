import gql from 'graphql-tag';
import { applicationIssues } from '../queries';

import { createLogger } from '@app/utils';

const Log = createLogger('upload-issue-documents');

export const UPLOAD_ISSUE_DOC = gql`
  mutation UploadHealthIssueDocument($input: [UploadHealthIssueDocumentInput!]!) {
    uploadHealthIssueDocument(input: $input) {
      dmi {
        id
        status
        lastReviewed
      }
      svi {
        id
        status
        lastReviewed
      }
    }
  }
`;

const updateCache = (cache, { data: { uploadHealthIssueDocument } }) => {
  try {
    const { viewer } = cache.readQuery({ query: applicationIssues.document });
    const dmis = viewer.insuranceEnrollments[0].application.dmis;
    const dmiIdx = dmis.findIndex((dmi) => dmi.id === uploadHealthIssueDocument.dmi.id);
    const svis = viewer.insuranceEnrollments[0].application.svis;
    const sviIdx = svis.findIndex((svi) => svi.id === uploadHealthIssueDocument.svi.id);

    let data;

    if (dmiIdx < 0 && sviIdx >= 0) {
      const svisCopy = [...svis];
      svisCopy[sviIdx] = {
        ...svisCopy[sviIdx],
        ...uploadHealthIssueDocument,
      };
      data = {
        viewer: {
          ...viewer,
          insuranceEnrollments: [
            {
              ...viewer.insuranceEnrollments[0],
              application: {
                ...viewer.insuranceEnrollments[0].application,
                dmis: [...viewer.insuranceEnrollments[0].application.dmis],
                svis: svisCopy,
              },
            },
          ],
        },
      };
    }
    if (dmiIdx >= 0 && sviIdx < 0) {
      const dmisCopy = [...dmis];
      dmisCopy[dmiIdx] = {
        ...dmisCopy[dmiIdx],
        ...uploadHealthIssueDocument,
      };
      data = {
        viewer: {
          ...viewer,
          insuranceEnrollments: [
            {
              ...viewer.insuranceEnrollments[0],
              application: {
                ...viewer.insuranceEnrollments[0].application,
                dmis: dmisCopy,
                svis: [...viewer.insuranceEnrollments[0].application.svis],
              },
            },
          ],
        },
      };
    }

    cache.writeQuery({
      query: applicationIssues.document,
      data,
    });
  } catch (e) {}
};

export default {
  document: UPLOAD_ISSUE_DOC,
  updateCache,
};
