import React from 'react';
import { stacks, Stack } from '@navigate';
import { PlanView } from './plan/PlanView';
import { GoalView } from './plan/PlanDetailView';
import { TabStackComponent, TabConfig } from '../types';

const config: TabConfig = {
  stackName: stacks.PLAN_STACK,
  options: {
    drawBehindNav: true,
    largeTitle: true,
    accentColor: 'saved',
  },
  screens: [PlanView, GoalView],
  tabIcon: 'PLAN',
  title: 'Saved',
};

const PlanStack: TabStackComponent = () => {
  return <Stack stackName={config.stackName} screens={config.screens} options={config.options} />;
};

PlanStack.config = config;
export default PlanStack;
