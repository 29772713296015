import React, { useCallback } from 'react';
import {
  useMutation,
  mutations,
  ChangeAliasError,
  ChangePhoneVars,
  ChangePhoneResponse,
} from '@data';
import { OnboardingFormBlueprint } from '@blueprints';
import { navigate, routes } from '@navigate';
import { FormConfig } from '@app/forms';
import { fields } from '@app/config';

const messages: Record<ChangeAliasError, string> = {
  CHANGE_ALIAS_ERROR_ALIAS_IN_USE: 'Already exists',
  CHANGE_ALIAS_ERROR_SAME_ALIAS: 'Phone number is unchanged',
  CHANGE_ALIAS_ERROR_MISSING_ALIAS: 'Must provide a phone number',
  CHANGE_ALIAS_ERROR_INVALID_ALIAS: 'The phone number was invalid',
};

const AccountSetupPhone = ({
  loading,
  phoneNumber,
  isPhoneVerified,
  requestedPhone,
  handleNext,
}) => {
  const [changePhone, { loading: submitting }] = useMutation<ChangePhoneResponse, ChangePhoneVars>(
    mutations.CHANGE_PHONE,
  );

  const onSubmit = useCallback(
    async (values, methods) => {
      // do this first, because the mutation might hit an error
      if (isPhoneVerified && phoneNumber === values.phoneNumber) {
        navigate(routes.ACCOUNT_SETUP_DOB);
      } else {
        const result = await changePhone({ variables: { phoneNumber: values.phoneNumber } });
        const err: ChangeAliasError | undefined = result?.data?.changePhone?.error;

        if (err) {
          methods.setError('phoneNumber', { type: 'custom', message: messages[err] });
        } else {
          handleNext({ newPhone: result?.data?.changePhone.newPhone });
        }
      }
    },
    [isPhoneVerified, phoneNumber],
  );

  const formConfig: FormConfig<{ phoneNumber: string }> = {
    initialValues: { phoneNumber: requestedPhone || phoneNumber },
    fields: [fields.PHONE_NUMBER],
    onSubmit,
  };

  return (
    <OnboardingFormBlueprint
      loading={loading}
      disabled={submitting}
      title="What's your phone number?"
      formConfig={formConfig}
    />
  );
};

export const AccountSetupPhoneView = {
  name: routes.ACCOUNT_SETUP_PHONE,
  component: AccountSetupPhone,
  options: {},
};
