import gql from 'graphql-tag';
import { WorkType, FilingStatus, KYCStatus, GoalSlug, GoalStatus } from '@types';

export interface UserDetailsQueryData {
  viewer: {
    id: string;
    user: {
      id: string;
      nickname?: string;
      legalName?: string;
      givenName?: string;
      familyName?: string;
      dob?: string;
      jobTitle?: string;
      workType?: WorkType;
      filingStatus?: FilingStatus;
      estimated1099Income?: number;
      estimatedW2Income?: number;
      estimatedIncome?: number;
      workState?: string;
      spouseIncome?: number;
      numTaxDependents?: number;
      dba?: string;
      ein?: string;
      ssn?: string;
      legalAddress?: {
        street1?: string;
        street2?: string;
        city?: string;
        state?: string;
        zip?: string;
      };
    };
    taxGoal?: {
      id: string;
      slug: GoalSlug;
      status: GoalStatus;
    };
    savingsAccountMetadata?: {
      kycStatus?: KYCStatus;
    };
    wealthAccount?: {
      id: string;
      primaryBeneficiaries?: {
        firstName?: string;
        lastName?: string;
      };
    };
  };
}

export const UserDetailsQuery = gql`
  query UserDetailsQuery {
    viewer {
      id
      user {
        id
        nickname
        legalName @client
        givenName
        familyName
        dob
        jobTitle
        workType
        filingStatus
        estimated1099Income
        estimatedW2Income
        estimatedIncome @client
        workState
        spouseIncome
        numTaxDependents
        signupContext
        dba
        ein
        ssn
        legalAddress {
          street1
          street2
          city
          state
          zip
        }
      }
      taxGoal: goal(slug: "taxes") {
        id
        slug
        status
      }
      savingsAccountMetadata {
        kycStatus
      }
      wealthAccount {
        id
        primaryBeneficiaries {
          firstName
          lastName
        }
      }
    }
  }
`;
