import React from 'react';

import { createLogger } from '@app/utils';
import ErrorModal from '../modals/ErrorModal';
import { PageError } from '@app/_ui-kit';
import { Layout, Narrow } from '@layouts';
const Log = createLogger('error-boundary: ');

class ErrorBoundary extends React.PureComponent {
  state = {
    hasError: false,
    showModal: true,
    error: null,
    info: null,
  };

  componentDidCatch(error) {
    // Update state so the next render will show the fallback UI.
    this.setState({
      hasError: true,
      error,
      //info,
    });
    Log.error(error, [], false);
    //Log.info(info);
    return { hasError: true };
  }

  toggleMessage = () => {
    this.setState(({ showModal }) => ({
      showModal: !showModal,
    }));
  };
  render() {
    return this.state.hasError ? (
      <React.Fragment>
        {this._renderErrorComponent()}
        {this.state.showModal && <ErrorModal onClose={this.toggleMessage} />}
      </React.Fragment>
    ) : (
      this.props.children
    );
  }
  _renderErrorComponent = (_) => {
    const { render } = this.props;
    return typeof render === 'function' ? (
      render({ ...this.state })
    ) : (
      <Layout color="page" fullHeight topSpace>
        <Narrow>
          <Layout.Header
            sheet
            title="Something went wrong"
            asset={{ icon: PageError }}
            onClose={this.toggleMessage}
          />
        </Narrow>
      </Layout>
    );
  };
}

export default ErrorBoundary;
