import { useState, useEffect } from 'react';
import { BIOMETRY_TYPE } from 'react-native-keychain';
import * as Biometrics from './biometrics';

interface UseBiometricsReturn {
  prompt: () => Promise<{ alias?: string; password?: string }>;
  enable: () => void;
  disable: () => void;
  toggle: () => void;
  loading: boolean;
  enabled: boolean;
  supported: BIOMETRY_TYPE | null;
  hasCredentials: boolean;
}

const useBiometrics = (): UseBiometricsReturn => {
  const [supported, setSupported] = useState<BIOMETRY_TYPE | null>(null);
  const [hasCredentials, setCredentials] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function checkSupported() {
      setSupported(await Biometrics.supported());
      setCredentials(!!(await Biometrics.hasCredentials()));
      setEnabled(!!(await Biometrics.status()));
      setLoading(false);
    }

    checkSupported();
  }, []);

  async function prompt() {
    try {
      const credentials = await Biometrics.getCredentials();

      return {
        alias: credentials ? credentials.username : undefined,
        password: credentials ? credentials.password : undefined,
      };
    } catch (e) {
      return { alias: undefined, password: undefined };
    }
  }

  const enable = () => {
    Biometrics.toggle(true);
  };

  const disable = () => {
    Biometrics.toggle(false);
  };

  const toggle = () => {
    Biometrics.toggle(!enabled);
  };

  return {
    prompt,
    enable,
    disable,
    toggle,
    loading,
    enabled,
    supported,
    hasCredentials,
  };
};

export default useBiometrics;
