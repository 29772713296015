import React, { useCallback, useState, useMemo } from 'react';

import {
  stacks,
  Stack,
  StackComponent,
  StackDefinition,
  useCurrentRoute,
  useCurrentParams,
  routes,
  navigate,
  close,
} from '@navigate';
import {
  useMutation,
  mutations,
  UpsertIncomeAutomationRulesResponse,
  UpsertIncomeAutomationRulesVars,
  UpdateIncomeTransactionResponse,
  UpdateIncomeTransactionVars,
  IncomeSourceInput,
  useQuery,
  queries,
  PaychecksQueryData,
} from '@data';
import { useAppReview } from '@util';
import { PaycheckBreakdownView } from './paycheck/PaycheckBreakdownView';
import { PaycheckSkippedView } from './paycheck/PaycheckSkippedView';
import { PaycheckIncomeView } from './paycheck/PaycheckIncomeView';

type ApprovalType = '1099' | 'W2';

const config: StackDefinition = {
  stackName: stacks.PAYCHECK_STACK,
  options: { layout: 'sheet' },
  screens: [PaycheckBreakdownView, PaycheckSkippedView, PaycheckIncomeView], // @todo E-3445 typescript
};

interface HandleNextProps {
  id?: string;
  paycheck?: any;
  approvalType?: ApprovalType;
}

const PaycheckStack: StackComponent = () => {
  const route = useCurrentRoute();
  const { paycheckID } = useCurrentParams();
  const { requestReview } = useAppReview({ location: 'paycheck' });
  const [approvalType, setApprovalType] = useState<ApprovalType | undefined>();

  const handleNext = useCallback(
    (updates?: HandleNextProps) => {
      if (updates?.approvalType) setApprovalType(updates?.approvalType);
      switch (route) {
        case routes.PAYCHECK_INCOME:
          navigate(routes.PAYCHECK_BREAKDOWN, { done: false });
          break;
        case routes.PAYCHECK_BREAKDOWN:
        case routes.PAYCHECK_SKIPPED:
          close();
          break;
        default:
          console.log({ route });
          break;
      }
    },
    [route, paycheckID],
  );

  const { data } = useQuery<PaychecksQueryData>(queries.PAYCHECKS, {
    fetchPolicy: 'cache-first',
  });

  const [processIncome, processing] = useMutation<
    UpdateIncomeTransactionResponse,
    UpdateIncomeTransactionVars
  >(mutations.UPDATE_INCOME_TRANSACTION, {
    refetchQueries: ['HomeQuery', 'IncomeTransactionsPaged', 'IncomeQuery'],
  });

  const [upsertRules, adding] = useMutation<
    UpsertIncomeAutomationRulesResponse,
    UpsertIncomeAutomationRulesVars
  >(mutations.UPSERT_INCOME_AUTOMATION_RULES);

  const addRule = (payload: IncomeSourceInput, onCompleted = () => {}) => {
    return upsertRules({ variables: { rules: [payload] }, onCompleted });
  };

  const stackData = useMemo(() => {
    return {
      paychecks: data?.viewer?.paychecks?.edges || [],
      paycheckID,
      handleNext,
      approvalType,
      addRule,
      adding,
      requestReview,
      processIncome,
      processing,
    };
  }, [
    paycheckID,
    handleNext,
    approvalType,
    requestReview,
    addRule,
    adding,
    processIncome,
    processing,
  ]);

  return (
    <Stack
      stackName={config.stackName}
      screens={config.screens}
      options={config.options}
      data={stackData}
    />
  );
};

PaycheckStack.config = config;
export default PaycheckStack;
