import React, { useMemo } from 'react';
import { useCopy } from '@app/utils';
import { getMembers, HealthApplicationQueryData, queries, useQuery } from '@data';
import { navigate, routes } from '@navigate';
import { BasicFormBlueprint } from '@app/blueprints';
import { fields } from './memberRemovalFields';

const PREFIX = 'catch.ede.CIC.memberDivorce';

const CICMemberDivorce = ({ applicationID }) => {
  const { c } = useCopy(PREFIX);

  const { loading, data } = useQuery<HealthApplicationQueryData>(queries.HEALTH_APPLICATION, {
    variables: { applicationID },
    skip: !applicationID,
  });

  const initialValues = useMemo(() => {
    return {
      members: getMembers(data)
        ?.filter((m) => m?.maritalStatus === 'MARRIED' || !/SELF|SPOUSE/.test(m.relation || ''))
        ?.map((m) => ({
          id: m.id,
          name: m.legalName,
        })),
    };
  }, [data]);

  const onSubmit = ({ members }) => {
    navigate(routes.CIC_REMOVING_MEMBERS, { members: members?.filter((m) => m.removeMember) });
  };

  return (
    <BasicFormBlueprint
      loading={loading}
      submitting={false}
      title={c('title')}
      formConfig={{
        initialValues,
        fields: fields(c('dateLabel')),
        onSubmit,
      }}
    />
  );
};

export const CICMemberDivorceView = {
  name: routes.CIC_MEMBER_DIVORCE,
  component: CICMemberDivorce,
  options: {},
};
