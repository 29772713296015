import React, { useState, useCallback } from 'react';
import {
  stacks,
  Stack,
  navigate,
  routes,
  StackComponent,
  StackDefinition,
  useCurrentRoute,
} from '@navigate';
import { useQuery, queries, PayrollQueryData, OnboardingQueryData } from '@data';
import { SetupPayrollView } from './SetupPayrollView';
import { EmploymentTypeView } from './EmploymentTypeView';
import { EstimatedIncomeView } from './EstimatedIncomeView';
import { WorkStateView } from './WorkStateView';
import { TaxFilingStatusView } from './TaxFilingStatusView';
import { SpouseIncomeView } from './SpouseIncomeView';
import { DependentsView } from './DependentsView';
import { PayrollEstimatorView } from '../shared/EstimatorView';
import { OtherWithholdingView } from './OtherWithholdingView';
import { PayrollSummaryView } from './PayrollSummaryView';
import { AddBankLinkView } from './AddBankLinkView';
import { SetupSlug } from './types';
import { FilingStatus, WorkType } from '@app/types';

const config: StackDefinition = {
  stackName: stacks.PERSONAL_PAYROLL_STACK,
  options: {
    navMode: 'flow',
    layout: 'page',
    accentColor: 'brand',
  },
  screens: [
    SetupPayrollView,
    EmploymentTypeView,
    EstimatedIncomeView,
    WorkStateView,
    TaxFilingStatusView,
    SpouseIncomeView,
    DependentsView,
    OtherWithholdingView,
    PayrollEstimatorView,
    PayrollSummaryView,
    AddBankLinkView,
  ],
};

type SetupStatus = 'TODO' | 'DONE' | undefined;
type GoalsStatus = Record<SetupSlug, SetupStatus>;

const defaultOptions: GoalsStatus = {
  taxes: undefined,
  'family-leave': undefined,
  timeoff: undefined,
  'health-expenses': undefined,
  'emergency-savings': undefined,
};

interface NavigationUpdates {
  slug?: SetupSlug; // f
  filingStatus?: FilingStatus;
  workType?: WorkType;
  updatedGoals?: GoalsStatus;
  paycheckPercentage?: number;
}

const PersonalPayrollStack: StackComponent = () => {
  const [goals, setGoals] = useState<GoalsStatus>(defaultOptions);

  const route = useCurrentRoute();

  useQuery<PayrollQueryData>(queries.PAYROLL, {
    fetchPolicy: 'cache-first',
  });

  const { data } = useQuery<OnboardingQueryData>(queries.ONBOARDING, {
    fetchPolicy: 'cache-first',
  });

  const handleNext = useCallback(
    (updates: NavigationUpdates) => {
      switch (route) {
        case routes.SETUP_PAYROLL:
          navigate(routes.EMPLOYMENT_TYPE);
          break;
        case routes.EMPLOYMENT_TYPE:
          navigate(routes.ESTIMATED_INCOME);
          break;
        case routes.ESTIMATED_INCOME:
          navigate(routes.WORK_STATE);
          break;
        case routes.WORK_STATE:
          const skipTaxes = data?.viewer?.user?.workType === 'WORK_TYPE_W2';
          navigate(skipTaxes ? routes.OTHER_WITHHOLDING : routes.TAX_FILING_STATUS);
          break;
        case routes.TAX_FILING_STATUS:
          const filingStatus = updates?.filingStatus || '';
          navigate(/MARRIED/.test(filingStatus) ? routes.SPOUSE_INCOME : routes.DEPENDENTS);
          break;
        case routes.SPOUSE_INCOME:
          navigate(routes.DEPENDENTS);
          break;
        case routes.DEPENDENTS:
          navigate(routes.SETUP_ESTIMATOR, { slug: 'taxes' });
          break;
        case routes.SETUP_ESTIMATOR:
        case routes.OTHER_WITHHOLDING:
          const slug = updates?.slug;

          if (slug === 'taxes') {
            navigate(routes.OTHER_WITHHOLDING);
          } else {
            const chosenGoals = updates?.updatedGoals
              ? updates?.updatedGoals
              : slug
              ? { ...goals, [slug]: 'DONE' }
              : undefined;

            if (chosenGoals) {
              setGoals(chosenGoals);

              const nextSlug = Object.keys(chosenGoals)
                .filter((slug) => slug !== 'taxes')
                .find((slug) => chosenGoals[slug] === 'TODO');

              if (nextSlug) {
                navigate(routes.SETUP_ESTIMATOR, { slug: nextSlug });
              } else {
                navigate(routes.PAYROLL_SUMMARY, { slug: undefined });
              }
            }
          }
          break;
        case routes.PAYROLL_SUMMARY:
          if (data?.viewer?.bankLinks?.[0]) {
            navigate(routes.ACCOUNT_SETUP_INTRO);
          } else {
            navigate(routes.ADD_BANK_LINK);
          }
          break;
        case routes.ADD_BANK_LINK:
          // note: handled separately because of bank link
          break;
        default:
          break;
      }
    },
    [route, data, setGoals, goals],
  );

  return (
    <Stack
      stackName={config.stackName}
      screens={config.screens}
      options={config.options}
      data={{ goals, handleNext }}
    />
  );
};

PersonalPayrollStack.config = config;
export default PersonalPayrollStack;
