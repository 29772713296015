import gql from 'graphql-tag';
import { WorkType, FilingStatus, GoalStatus, GoalSlug, GoalType, AgreementSet } from '@types';
import { GoalsFragments } from '@data/deprecated/fragments/goals';

export interface PayrollQueryData {
  viewer: {
    id: string;
    user: {
      id: string;
      workType?: WorkType;
      filingStatus?: FilingStatus;
      numTaxDependents?: number;
      estimated1099Income?: number;
      estimatedW2Income?: number;
      spouseIncome?: number;
      workState?: string;
    };
    goal: {
      id: string;
      slug?: GoalSlug;
      status?: GoalStatus;
      goalType?: GoalType;
      name?: string;
      paycheckPercentage?: number;
      statePercentage?: number;
      federalPercentage?: number;
      pausedEndDate?: Date;
      totalBalance?: number;
      pendingBalance?: number;
      availableBalance?: number;
    };
    goals: {
      id: string;
      slug?: GoalSlug;
      status?: GoalStatus;
      paycheckPercentage?: number;
      statePercentage?: number;
      federalPercentage?: number;
      type?: string;
    };
  };
  viewerTwo: {
    id;
    agreements: AgreementSet;
  };
}

export const PayrollQuery = gql`
  query PayrollQuery($slug: String) {
    viewerTwo {
      id
      agreements {
        id
        acknowledgedUnitAccountDisclosures
        authorizedAboundEftpsEnrollment
      }
    }
    viewer {
      id
      user {
        id
        filingStatus
        workType
        estimated1099Income
        estimatedW2Income
        workState
        numTaxDependents
        spouseIncome
      }
      goal(slug: $slug) {
        id
        slug
        status
        goalType
        name
        paycheckPercentage
        pausedEndDate
        totalBalance
        pendingBalance
        availableBalance
        ...TaxGoal
        ...CustomGoal
      }
      goals {
        id
        slug
        status
        paycheckPercentage
        type: goalType
        ...TaxGoal
        ...CustomGoal
      }
    }
  }

  ${GoalsFragments.taxGoal}
  ${GoalsFragments.customGoal}
`;
