import React from 'react';
import {
  useQuery,
  queries,
  useMutation,
  mutations,
  OnboardingQueryData,
  UpdateUserResponse,
  UpdateUserVars,
} from '@data';
import { OnboardingFormBlueprint } from '@blueprints';
import { routes } from '@navigate';
import { FormConfig } from '@app/forms';
import { fields } from '@app/config';
import { useCopy } from '@app/utils';
import { FilingStatus } from '@types';
import { Link } from '@uikit';

interface TaxFilingStatusValues {
  filingStatus: FilingStatus;
}

const TaxFilingStatus = ({ handleNext }) => {
  const { c } = useCopy('catch.onboarding.taxFilingStatus');
  const { c: basics } = useCopy('catch.basics');
  const { loading, data } = useQuery<OnboardingQueryData>(queries.ONBOARDING, {
    fetchPolicy: 'cache-first', // @todo: this should really be the default regardless
  });

  const [update, { loading: submitting }] = useMutation<UpdateUserResponse, UpdateUserVars>(
    mutations.UPDATE_USER,
  );

  const formConfig: FormConfig<TaxFilingStatusValues> = {
    initialValues: { filingStatus: data?.viewer?.user?.filingStatus },
    fields: [fields.FILING_STATUS],
    onSubmit: (values) => {
      const filingStatus = values.filingStatus;

      update({
        variables: { user: { filingStatus } },
        optimisticResponse: {
          updateUserNew: {
            user: {
              ...data?.viewer?.user,
              id: data?.viewer?.user?.id || '',
              filingStatus,
              __typename: 'User',
            },
          },
        },
      });

      handleNext({ filingStatus });
    },
  };

  return (
    <OnboardingFormBlueprint
      loading={loading}
      disabled={submitting}
      title={c('title')}
      subtitles={[
        c('subtitle', {
          link: (
            <Link
              inline
              href="https://help.catch.co/setting-up-tax-withholding/what-is-my-filing-status"
              target="blank"
            >
              {basics('thisArticle')}
            </Link>
          ),
        }),
      ]}
      formConfig={formConfig}
    />
  );
};

export const TaxFilingStatusView = {
  name: routes.TAX_FILING_STATUS,
  component: TaxFilingStatus,
  options: OnboardingFormBlueprint.options,
};

export const GoalTaxFilingStatusView = {
  name: routes.GOAL_TAX_FILING_STATUS,
  component: TaxFilingStatus,
  options: OnboardingFormBlueprint.options,
};
