import gql from 'graphql-tag';
import { CSRLevel, EligibilityChange, HealthAsyncStatus, SEPType } from '@app/types';
import {
  ApolloEDNDocument,
  ApolloOpenEnrollmentDates,
  EDNDocumentFragment,
  OpenEnrollmentFragment,
} from '../fragments';

export interface EligibilityMember {
  id: string;
  givenName: string;
  familyName: string;
  isRequestingCoverage: boolean;
  metalLevelOption: {
    currentPlanOnlyIndicator: boolean;
  };

  exchangeEligibility: {
    startDate: string | null;
    endDate: string | null;
  } | null;

  aptcEligibility: {
    startDate: string | null;
    endDate: string | null;
    amount: number;
    change: EligibilityChange | null;
  } | null;

  csrEligibility: {
    startDate: string | null;
    endDate: string | null;
    csrLevel: CSRLevel;
    change: EligibilityChange | null;
  } | null;

  sepEligibility: {
    startDate: string | null;
    endDate: string | null;
    // earliestQhpEffective: string | null;
    // latestQhpEffective: string | null;
    sepReason: SEPType;
  } | null;

  chipEligibility: {
    startDate: string | null;
    endDate: string | null;
  } | null;

  medicaidEligibility: {
    startDate: string | null;
    endDate: string | null;
  } | null;

  initialEnrollmentEligibility: {
    startDate: string | null;
    endDate: string | null;
    // earliestQhpEffective: string | null;
    // latestQhpEffective: string | null;
  } | null;
}

export interface EligibilityResultsQueryData {
  viewerTwo: {
    health: {
      application: {
        id: string;
        lastUsedRoute: string;
        coverageYearNumber: number;
        coverageState: string;
        isRequestingFinancialAssistance: boolean;
        asyncStatus: HealthAsyncStatus;
        allMembers: Array<EligibilityMember>;
        stateReferenceData: {
          stateDeterminesMedicaidEligibility: boolean;
        };
        enrollmentGroups: Array<{
          id: string;
          plans: {
            total: number;
          };
        }>;
        ednDocuments?: Array<ApolloEDNDocument>;
        dmis: Array<{
          id: string;
          dependentID: string;
          subType: string;
          status: 'ACTION_NEEDED' | 'PROCESSING' | 'EXPIRED' | 'COMPLETE';
          resolveBy: string; // date time
        }>;

        svis: Array<{
          id: string;
          dependentIDs: Array<string>;
          status: 'ACTION_NEEDED' | 'PROCESSING' | 'EXPIRED' | 'COMPLETE';
          statusCode:
            | 'MOVED_TO_NEW_SERVICE_AREA'
            | 'MEC_LOSS'
            | 'ADOPTION'
            | 'MARRIAGE'
            | 'SEP_GRANTED_FOR_MDCAID_DENIAL';
          resolveBy: string; //date time
        }>;
      };
    };
  };
  reference: {
    health: {
      openEnrollmentDates: ApolloOpenEnrollmentDates;
    };
  };
}

export const EligibilityResultsQuery = gql`
  query EligibilityResultsQuery($applicationID: ID!) {
    viewerTwo {
      id
      health {
        application(id: $applicationID) {
          id
          lastUsedRoute
          coverageYearNumber
          coverageState
          isRequestingFinancialAssistance
          asyncStatus

          stateReferenceData {
            stateDeterminesMedicaidEligibility
          }

          enrollmentGroups {
            id
            plans {
              total
            }
          }

          dmis {
            id
            dependentID
            status
            resolveBy
            subType
          }
          svis {
            id
            dependentIDs
            statusCode
            status
            resolveBy
          }
          ednDocuments {
            id
            ...EDNDocument
          }

          allMembers {
            id
            givenName
            familyName
            isRequestingCoverage
            metalLevelOption {
              currentPlanOnlyIndicator
            }
            exchangeEligibility {
              startDate
              endDate
            }
            aptcEligibility {
              startDate
              endDate
              amount
              change
            }
            csrEligibility {
              startDate
              endDate
              csrLevel
              change
            }
            sepEligibility {
              startDate
              endDate
              sepReason
            }
            chipEligibility {
              startDate
              endDate
            }
            medicaidEligibility {
              startDate
              endDate
            }
            initialEnrollmentEligibility {
              startDate
              endDate
            }
          }
        }
      }
    }
    reference {
      health {
        openEnrollmentDates {
          ...OEDates
        }
      }
    }
  }
  ${OpenEnrollmentFragment}
  ${EDNDocumentFragment}
`;
