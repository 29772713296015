import { useMemo } from 'react';
import { StyleProp, ViewStyle, StyleSheet, Platform, useWindowDimensions } from 'react-native';

type ShortName = 'xs' | 's' | 'm' | 'l';
type MediumName = 'phone' | 'tablet' | 'browser';
type LongName = 'mobile' | 'desktop';

const SHORT: { ranges: Array<number>; names: Array<ShortName> } = {
  ranges: [500, 600, 720],
  names: ['xs', 's', 'm', 'l'],
};
const MEDIUM: { ranges: Array<number>; names: Array<MediumName> } = {
  ranges: [600, 840],
  names: ['phone', 'tablet', 'browser'],
};
const LONG: { ranges: Array<number>; names: Array<LongName> } = {
  ranges: [600],
  names: ['mobile', 'desktop'],
};
const supported: Array<MediumName | LongName> = [...LONG.names, ...MEDIUM.names];

interface ResponsiveStyle {
  [name: MediumName | LongName | 'default']: StyleProp<ViewStyle>;
}

export type ResponsiveStyleShape<T extends string> = Record<T, ResponsiveStyle>;

interface UseResponsiveResponse<T extends string> {
  res: Record<T, ViewStyle>;
  width: Number;
  height: Number;
  shortRange: ShortName;
  mediumRange: MediumName;
  longRange: LongName;
  isNative: boolean;
  isWeb: boolean;
  isMobile: boolean;
  isDesktop: boolean;
  isAndroid: boolean;
  isIos: boolean;
  isLandscape: boolean;
  isPortrait: boolean;
}

export function useResponsive<T extends string>(
  styleMap: ResponsiveStyleShape<T> = {},
): UseResponsiveResponse<T> {
  const { height, width, scale } = useWindowDimensions();

  const calcBp = (res, cur, i) => {
    if (width < cur) {
      return res;
    }
    return res + 1;
  };

  const short = useMemo(() => SHORT.ranges.reduce(calcBp, 0), [width]);
  const medium = useMemo(() => MEDIUM.ranges.reduce(calcBp, 0), [width]);
  const long = useMemo(() => LONG.ranges.reduce(calcBp, 0), [width]);

  const res = useMemo(
    () => ({
      ...Object.keys(styleMap).reduce((layout, key) => {
        // Handles nested objects
        const styles = supported.includes(key) ? styleMap : styleMap[key];

        const defaultStyles = styles.default;
        const longStyles = styles[LONG.names[long]];
        const mediumStyles = styles[MEDIUM.names[medium]];
        const shortStyles = styles[SHORT.names[short]];
        const platformStyles = styles[Platform.OS];
        const nativeStyles = /ios|android/.test(Platform.OS) && styles.native;
        // We can combine specific rules when their breakpoints matches
        // We keep the rn logic where short more specific rules overide more
        // general ones
        layout[key] = StyleSheet.flatten([
          defaultStyles,
          longStyles,
          mediumStyles,
          shortStyles,
          nativeStyles,
          platformStyles,
        ]);
        return layout;
      }, {}),
      // ...Object.keys(extra).reduce((acc, key) => {
      //   const items = supported.includes(key) ? extra : extra[key];

      //   const defaultItem = items.default;
      //   const longItem = items[LONG.names[long]];
      //   const mediumItem = items[MEDIUM.names[medium]];
      //   const shortItem = items[SHORT.names[short]];
      //   const platformItem = items[Platform.OS];
      //   acc[key] = platformItem || shortItem || mediumItem || longItem || defaultItem;
      //   return acc;
      // }, {}),
    }),
    [short, medium, long],
  );

  return {
    res,
    width,
    height,
    scale,
    shortRange: SHORT.names[short],
    mediumRange: MEDIUM.names[medium],
    longRange: LONG.names[long],
    isNative: Platform.OS !== 'web',
    isWeb: Platform.OS === 'web',
    isMobile: LONG.names[long] === 'mobile',
    isDesktop: LONG.names[long] !== 'mobile',
    isAndroid: Platform.OS === 'android',
    isIos: Platform.OS === 'ios',
    isLandscape: width > height,
    isPortrait: width < height,
  };
}
