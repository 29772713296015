import { useTheme } from '@app/_ui-kit';
import React from 'react';
import { TextInput as RNInput } from 'react-native';
import { KeyboardType, KeyHandlers } from '../utils/keyboard';

export interface InputProps {
  name: string;
  value: string;
  onChange: (val: string) => void;
  onBlur?: () => void;
  error: boolean;
  autoFocus: boolean;
  disabled: boolean;
  loading: boolean;
  autoFocus?: boolean;
  fullWidth?: boolean;
  large?: boolean;
  rounded?: boolean;
  placeholder: string;
  secure?: boolean;
  keyboard?: KeyboardType;
  signature?: boolean;
  keyMap?: KeyHandlers;
}

/**
 * Standard input for text
 */
const Input: React.FC<InputProps> = ({
  name,
  placeholder,
  value,
  onChange,
  onBlur,
  disabled,
  autoFocus,
  secure,
  keyMap,
  keyboard,
}) => {
  /**
   * @todo
   * secure should handle show/hide toggle
   */
  const { theme } = useTheme();

  return (
    <RNInput
      testID={name}
      nativeID={name}
      value={value}
      placeholder={placeholder}
      onBlur={onBlur}
      onChangeText={onChange}
      onKeyPress={({ nativeEvent: { key: keyValue } }) => {
        const map = keyMap || {};
        if (keyValue in map) map[keyValue]();
      }}
      autoFocus={!!autoFocus}
      secureTextEntry={!!secure}
      editable={!disabled}
      // autoCorrect
      keyboardType={keyboard}
      // returnKeyType
      // textContentType
      // autoCompleteType
      style={[
        /** @todo: bulk of styles right now :/ */
        theme.textFieldBase,
        theme.textField,
        // large && theme.textFieldLarge,
        // rounded && theme.textFieldRounded,
        // signature && theme.signatureField,
        // error && theme.textFieldError,
        // fullWidth && theme.fullWidth,
        // disabled && theme.disabled,
        // styles.input,
        // loading && styles.loading,
        // large && styles.large,
        // rounded && styles.rounded,
        // signature && styles.signature,
        // fullWidth && styles.fullWidth,
        // disabled && styles.disabled,
      ]}
    />
  );
};

export default Input;
