import React, { useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { Text } from './Text';
import styled from 'styled-components';

import { useTheme } from '../hooks/useTheme';
import { theme } from '@app/_ui-kit/themes/catch';

const MARGIN = 4;
const HEIGHT = 40;

const StyledSegmentedControl = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  border-bottom-width: 1;
  background-color: ${(p) => p.background};
  border-radius: ${theme.corners.round}px;
  margin-left: -${MARGIN}px;
  padding: ${MARGIN}px;
  height: ${HEIGHT}px;
  &:hover {
    background-color: ${(p) => p.hoverBackground};
  }
`;

const StyledSegmentedControlButton = styled.div`
  flex: 1;
  border-width: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: ${(p) => (p.active ? p.activeColor : p.background || 'transparent')};
  cursor: pointer;
  border-radius: ${theme.corners.round}px;
  transition: ${theme.animation.duration}ms ${theme.animation.curve};
  z-index: 0;
  height: 100%;
`;

export const SegmentedControl = ({
  controls,
  children,
  style,
  fullWidth,
  index,
  setIndex,
  small,
}) => {
  const [activeIdx, setActiveIdx] = useState(0);
  const { themeColors } = useTheme();

  const handlePress = (idx) => {
    if (setIndex) {
      setIndex(idx);
    } else {
      setActiveIdx(idx);
    }
  };

  const thisIdx = index || activeIdx;

  return (
    <>
      <View style={[style, fullWidth && styles.fullWidth]}>
        <StyledSegmentedControl background={themeColors.borderLightColor}>
          <StyledSegmentedControlButton
            background={themeColors.cardColor}
            style={{
              position: 'absolute',
              left: -MARGIN,
              transform: `translateX(${thisIdx * 100}%)`,
              marginLeft: MARGIN,
              height: HEIGHT,
              width: `${100 / controls?.length}%`,
              boxShadow: '0px 1px 5px -1px rgba(0, 0, 0, 0.12)',
            }}
          />
          {controls.map((btn, i) => {
            return (
              <StyledSegmentedControlButton
                key={`ctrl-${i}`}
                onClick={() => handlePress(i)}
                useForeground={true}
                active={thisIdx === i}
              >
                <Text size={small ? 'ffp' : 'fp'} weight="medium" align="center">
                  {btn.title}
                </Text>
              </StyledSegmentedControlButton>
            );
          })}
        </StyledSegmentedControl>
      </View>
      {React.Children.map(children, (child, i) => {
        if (i === thisIdx) {
          return child;
        }
        return null;
      })}
    </>
  );
};

const styles = StyleSheet.create({
  fullWidth: {
    width: '100%',
  },
});

export default SegmentedControl;
