import React from 'react';
import { Card, Stack } from '@layouts';
import { AnnotationGroup, AnnotationItem, SimpleRow, Text } from '@uikit';

import { getCarrierAsset } from '@app/utils';
import { PolicyStatusType } from '@types';
import CoverageFooter from '../coverage/CoverageFooter';
import CoverageBadges from '../coverage/CoverageBadges';

interface Coverage {
  testID?: string;
  // display types
  type: 'HEALTH_INSURANCE' | 'DENTAL_INSURANCE' | 'PLANTYPE_DENTAL' | 'PLANTYPE_HEALTH';
  badgeType?: 'PLAN_DETAILS' | 'POLICY_STATUS' | 'NONE';
  footerType?: 'DEDUCTIBLE' | 'DATES';

  // plan details
  plan: string; // the health plan name
  carrier: string; // the name of the carrier
  metalLevel?: string;
  planType?: string; // HMO, PPO etc.
  premium?: number;
  strikeoutPremium?: number;
  familyDeductible?: number;
  individualDeductible?: number;
  policyStatus?: PolicyStatusType;
  startDate?: string;
  endDate?: string;
  hsaEligible?: boolean;

  onPress?: () => void;
  disabled?: boolean;
  checked?: boolean;
  annotations?: Array<AnnotationItem>; // any additional notes about this policy/plan
}

/**
 * Component to display plan details as a card
 *
 * Note: there are a few "flavors" of this card that can be called for
 * - Badges can either show metal level + type OR policy status information
 * - Footer can either show deductibles OR
 */
const PolicyCard: React.FC<Coverage> = ({
  testID,
  type,
  badgeType = 'PLAN_DETAILS',
  footerType = 'DEDUCTIBLE',
  plan,
  carrier,
  metalLevel,
  planType,
  policyStatus,
  premium,
  strikeoutPremium,
  familyDeductible,
  individualDeductible,
  startDate,
  endDate,
  hsaEligible,
  onPress,
  disabled,
  checked,
  annotations,
}) => {
  const inactive = /CANCELLED|TERMINATED/.test(policyStatus || '');

  return (
    <Card
      testID={testID}
      border
      clipTopSpace
      onPress={onPress}
      checked={checked}
      disabled={disabled || inactive}
      padded={false}
      majorShadow
    >
      <Stack separatorComponent spacing="2" style={{ padding: 16, paddingTop: 0 }}>
        <Stack>
          <SimpleRow
            asset={getCarrierAsset(carrier, type, 'md')}
            accessory={
              <CoverageBadges
                type={badgeType}
                policyStatus={policyStatus}
                metalLevel={metalLevel}
                planType={planType}
                hsaEligible={hsaEligible}
              />
            }
          />
          <Stack spacing="+gap">
            <Text size="p" weight="medium">
              {plan}
            </Text>
            <Text size="fp" color="subtle">
              {carrier}
            </Text>
          </Stack>
        </Stack>
        {!inactive && (
          <Stack spacing="0b">
            <CoverageFooter
              type={footerType}
              startDate={startDate}
              endDate={endDate}
              premiumWithCredit={premium}
              originalPremium={strikeoutPremium}
              deductible={familyDeductible || individualDeductible}
            />
            {annotations && annotations?.length > 0 && <AnnotationGroup notes={annotations} />}
          </Stack>
        )}
      </Stack>
    </Card>
  );
};

export default PolicyCard;
