import React, { useMemo } from 'react';
import { Platform, View } from 'react-native';
import { useQuery } from '@apollo/client';

import { Card, Container, Layout, Page, Section, Split, Stack } from '@layouts';
import { date } from '@app/utils/format/date';
import {
  Accordion,
  Asset,
  ComplexRow,
  LineItem,
  SimpleRow,
  Text,
  Link,
  useResponsive,
} from '@app/_ui-kit';
import { taxPaymentJurisdiction } from '@app/utils/format/status';
import IncomeSourceRow from '@app/_ui-kit/components/IncomeSourceRow';
import IncomeBar from '@app/charts/IncomeBar';
import annualReviewQuery from './annualReviewQuery';
import { useDocument } from '@hooks/useDocument';
import { get_labels } from '@app/partial/documents/DocumentRow';
import {
  formatBenefit,
  formatTaxPayment,
  getCarrierAsset,
  openIntercom,
  precisionRound,
  useCopy,
} from '@app/utils';

import Doc from '@svg/file.svg';

const CARD_PROPS = {
  clipTopSpace: true,
  clipBottomSpace: true,
  border: true,
};

const PRICE_PROPS = {
  size: 'fp',
  technical: true,
  weight: 'medium',
};

const DocRow = ({ doc, detail, value }) => {
  const { downloadDocument } = useDocument(doc);

  const { label, _sublabel } = useMemo(
    () =>
      get_labels({
        type: doc?.type,
        detail: detail || doc?.detail,
      }),
    [doc],
  );

  return (
    <SimpleRow
      asset={{ svg: Doc, size: 'glyph', color: 'subtle', containerSize: 'glyph' }}
      noPadding
      onPress={downloadDocument}
      label={
        <Text size="sm" color="subtle">
          {label}
        </Text>
      }
      accessory={
        <Text size="sm" color="subtler" weight="light">
          {value || _sublabel}
        </Text>
      }
    />
  );
};

/**
 *
 * This is basically a prototype and was built in a few hours
 *
 */
const AnnualReviewComponent = () => {
  const { $, p } = useCopy('catch.income.report');
  const { isMobile } = useResponsive();
  const { data, loading } = useQuery(annualReviewQuery, {
    returnPartialData: true,
    variables: {
      fromDate: '2022-01-01',
      toDate: '2022-12-31',
      year: 2022,
    },
  });

  const waiting = loading && !data;
  const v = data?.viewer;
  const v2 = data?.viewerTwo;
  const totalIncome = v?.planHistorySummary;

  const allContrib = useMemo(
    () =>
      v2?.goalContributionsForYear?.reduce(
        (acc, curr) => acc + curr?.data?.reduce((a, c) => a + c.totalContributionsValue, 0),
        0,
      ),
    [v2?.goalContributionsForYear],
  );

  const taxPmts = v2?.taxPayments
    ?.map((p) => formatTaxPayment(p))
    ?.filter((p) => p?.year === 2022 && p?.status !== 'CANCELED');
  const taxPmtsAmt = taxPmts?.reduce((acc, curr) => acc + curr?.amount, 0 || 0);

  const ttlIncome = totalIncome?.grossIncome1099 + totalIncome?.grossIncomeW2;
  const estIncome = v?.income?.estimated1099Income + v?.income?.estimatedW2Income;
  const deviation = ttlIncome / estIncome;

  const taxAcct = v2?.accounts?.filter((acct) => acct?.goalType === 'TAX')?.[0];
  const retAccts =
    v?.wealthAccounts && v?.wealthAccounts?.length > 0 ? v?.wealthAccounts : [{ id: 'retirement' }];

  const Comp = isMobile ? View : Card;

  return (
    <Page>
      <Layout.Scroll>
        <Layout topSpace bottomSpace>
          <Container>
            {Platform.OS === 'web' && (
              <Layout.Header
                topSpace
                bottomSpace
                title="2022 in Review"
                subtitle="Yearly report"
                titleSize="page"
              />
            )}
            <Split sticky>
              <Stack spacing="1">
                <Section title="Income & Withholding">
                  <Stack spacing="3" separatorComponent mobileGap onSheet>
                    <Stack spacing="2">
                      <Stack spacing="1" topSpace>
                        <IncomeBar incomeByMonth={v2?.incomeForYear} isPreviousYear />
                        <IncomeSourceRow
                          loading={loading}
                          incomeSources={v?.incomeSources?.filter(
                            (is) => is?.incomeAutomationRule?.automationType !== 'NEVER',
                          )}
                        />
                      </Stack>
                      <Stack spacing="+gap">
                        <LineItem
                          loading={waiting}
                          prominent
                          mono
                          left="Gross Income"
                          right={$(ttlIncome)}
                        />
                        <LineItem
                          loading={waiting}
                          mono
                          left="1099 (Pre-tax)"
                          right={$(totalIncome?.grossIncome1099)}
                        />
                        <LineItem
                          loading={waiting}
                          mono
                          left="W2 (Post-tax)"
                          right={$(totalIncome?.grossIncomeW2)}
                        />
                        <Accordion
                          title="Fun stats"
                          small
                          style={{ marginBottom: -24, paddingBottom: 0 }}
                          buttonStyle={{ opacity: 0.5, height: 48 }} // super gross
                        >
                          <Stack spacing="+gap">
                            <LineItem
                              loading={waiting}
                              mono
                              left="Your estimate"
                              right={$(
                                v?.income?.estimated1099Income + v?.income?.estimatedW2Income,
                              )}
                            />
                            <LineItem
                              loading={waiting}
                              mono
                              left="2022 observed"
                              right={$(totalIncome?.grossIncome1099 + totalIncome?.grossIncomeW2)}
                            />
                            <LineItem
                              loading={waiting}
                              prominent
                              mono
                              left="Actual vs. estimated"
                              right={`${precisionRound(deviation, 2)}x`}
                            />
                          </Stack>
                        </Accordion>
                      </Stack>
                    </Stack>
                    <Stack spacing="+gap">
                      <LineItem
                        loading={waiting}
                        prominent
                        mono
                        left="Total Saved in Catch"
                        right={$(allContrib)}
                      />
                      <LineItem
                        loading={waiting}
                        mono
                        left={`Withheld from income (${p(totalIncome?.totalSavings / ttlIncome)})`}
                        right={$(totalIncome?.totalSavings)}
                      />
                      <LineItem
                        loading={waiting}
                        mono
                        left="Individual deposits"
                        right={$(allContrib - totalIncome?.totalSavings)}
                      />
                    </Stack>
                  </Stack>
                </Section>
              </Stack>
              <Stack spacing="1" mobileGap={isMobile} onSheet>
                <Comp {...CARD_PROPS}>
                  <Accordion title="Taxes" initOpen>
                    <ComplexRow
                      loading={waiting}
                      label={taxAcct?.accountNumber ? 'Withholding Account' : 'No account'}
                      sublabel={taxAcct?.accountNumber}
                      asset={{
                        ...Asset.configureBenefit('TAX', null, 'xs'),
                        containerSize: 'md',
                      }}
                    />

                    <SimpleRow
                      loading={waiting}
                      label={'2022 tax payments'}
                      accessory={<Text {...PRICE_PROPS}>{$(taxPmtsAmt || 0)}</Text>}
                    />

                    {taxPmts?.map(
                      (pmt) =>
                        pmt?.document && (
                          <DocRow
                            key={pmt?.document?.id}
                            doc={pmt?.document}
                            detail={`${pmt?.timeframe} ${taxPaymentJurisdiction(
                              pmt?.jurisdiction,
                            )}`}
                            value={$(pmt?.amount)}
                          />
                        ),
                    )}
                  </Accordion>
                </Comp>
                <Comp {...CARD_PROPS}>
                  <Accordion title="Retirement" initOpen>
                    {retAccts.map((acct) => (
                      <ComplexRow
                        loading={waiting}
                        key={acct?.id}
                        sublabel={acct?.accountID}
                        labelColor={acct?.accountID ? 'text' : 'subtle'}
                        label={
                          acct?.accountType
                            ?.replace('_', ' ')
                            ?.replace('ROTH', 'Roth')
                            ?.replace('TRADITIONAL', 'Traditional') || 'No account'
                        }
                        asset={{
                          ...Asset.configureBenefit('RETIREMENT', null, 'xs'),
                          containerSize: 'md',
                        }}
                      />
                    ))}

                    <SimpleRow
                      label={'2022 contributions'}
                      accessory={
                        <Text {...PRICE_PROPS}>
                          {$(v?.retirementGoal?.totalContributions || 0)}
                        </Text>
                      }
                    />

                    {v2?.documents?.taxDocuments
                      ?.filter((doc) => doc?.planType === 'PLANTYPE_RETIREMENT')
                      ?.map((doc) => (
                        <DocRow key={doc?.id} doc={doc} />
                      ))}
                  </Accordion>
                </Comp>
                <Comp {...CARD_PROPS}>
                  <Accordion title="Health & Dental" initOpen>
                    <Stack spacing="1" topSpace>
                      {v2?.health?.policies?.length > 0 ? (
                        v2?.health?.policies.map((acct) => (
                          <ComplexRow
                            loading={waiting}
                            noPadding
                            key={acct?.id}
                            label={acct.providerPlan.name}
                            accessory={<Text {...PRICE_PROPS}>{$(acct?.premiumEffective)}</Text>}
                            sublabel={`${date(acct?.startDate, 'SHORT')} - ${date(
                              acct.endDate,
                              'SHORT',
                            )}`}
                            asset={{
                              ...getCarrierAsset(
                                acct?.providerPlan?.issuer?.name,
                                acct?.productType,
                                'md',
                              ),
                              containerSize: 'md',
                            }}
                          />
                        ))
                      ) : (
                        <ComplexRow
                          loading={waiting}
                          labelColor={'subtle'}
                          noPadding
                          label="No coverage through Catch"
                          asset={{
                            ...getCarrierAsset(null, null, 'md'),
                            containerSize: 'md',
                          }}
                        />
                      )}
                    </Stack>
                    {v2?.documents?.taxDocuments
                      ?.filter((doc) => doc?.planType === 'PLANTYPE_HEALTH')
                      ?.map((doc) => (
                        <DocRow key={doc?.id} doc={doc} />
                      ))}
                  </Accordion>
                </Comp>
                <Comp {...CARD_PROPS}>
                  <Accordion title="Other Account Info">
                    <Stack spacing="0b">
                      {v2?.accounts
                        ?.filter(
                          (acct) =>
                            !!acct?.goalType &&
                            acct?.goalType !== 'TAX' &&
                            acct?.goalType !== 'RETIREMENT',
                        )
                        ?.map((acct) => (
                          <SimpleRow
                            noPadding
                            fp
                            key={acct?.id}
                            label={formatBenefit.name({ goalType: acct.goalType })}
                            accessory={
                              <Text size="sm" technical>
                                {acct?.accountNumber || '–'}
                              </Text>
                            }
                            asset={{
                              ...Asset.configureBenefit(acct?.goalType, null, 'xs'),
                              containerSize: 'sm',
                            }}
                          />
                        ))}
                    </Stack>
                  </Accordion>
                </Comp>
              </Stack>
            </Split>
          </Container>
        </Layout>

        <Layout topSpace bottomSpace color={!isMobile && 'skeleton'}>
          <Container>
            <Stack spacing="3" separatorComponent>
              <Text size="sm" color="subtler">
                Did you know you can connect Catch to apps like Mint, Quickbooks, and Copilot?
              </Text>
              <View dataSet={{ 'print-hide': true }}>
                <Stack horizontal spacing="2">
                  <Link size="sm" color="subtler" onPress={openIntercom}>
                    Feedback
                  </Link>
                  {Platform.OS === 'web' && (
                    <Link
                      size="sm"
                      color="subtler"
                      onPress={() => {
                        if (window) {
                          // need delay to get colors right and match cmd+p behavior
                          setTimeout(() => window.print(), 100);
                        }
                      }}
                    >
                      Print or save
                    </Link>
                  )}
                </Stack>
              </View>
            </Stack>
          </Container>
        </Layout>
      </Layout.Scroll>
    </Page>
  );
};

export const AnnualReviewView = {
  name: 'ANNUAL_REVIEW',
  component: AnnualReviewComponent,

  options: {
    title: '2022 in Review',
    largeTitle: true,
  },
};
