import React, { useMemo, useCallback } from 'react';
import { useCopy } from '@app/utils';
import { routes, ScreenOptions, navigate } from '@navigate';
import { Button, Link } from '@uikit';
import { useQuery, queries } from '@data';
import { CalloutLayout, Toolbar } from '@layouts';

const options: ScreenOptions = {
  title: 'Welcome to Catch',
  buttons: {
    activity: false,
    help: false,
  },
};

const Welcome = () => {
  // handles a prefetch
  useQuery(queries.ONBOARDING);

  /* @ts-ignore */
  const { c } = useCopy('catch.onboarding.welcome');

  const onNext = useCallback(() => {
    navigate(routes.EMPLOYMENT_TYPE);
  }, []);

  const nextButton = useMemo(
    () => (
      <Button testID="welcome--get-started" accentColor="brand" onPress={onNext}>
        {c('next')}
      </Button>
    ),
    [onNext],
  );

  return (
    <CalloutLayout
      loading={false}
      title={c('title')}
      subtitles={[
        c('paragraph'),
        <Link key="link" onPress={() => navigate(routes.HEALTH_LINK_INTRO, { context: 'page' })}>
          {c('skip')}
        </Link>,
      ]}
      render="brand"
      toolbar={<Toolbar>{nextButton}</Toolbar>}
    />
  );
};

export const WelcomeView = {
  name: routes.WELCOME,
  component: Welcome,
  options,
};
