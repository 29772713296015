import React, { useMemo, useRef } from 'react';
import { Layout, FlowLayout } from '@layouts';
import { Text } from '@uikit/components/Text';
import { Loading } from '@uikit/components/Loading';
import Agreement from '@app/_ui-kit/components/Agreement';
import { Fields, useForm } from '@f';
import { CheckboxConfig, FieldsConfig, SignatureConfig } from '@types';

interface Disclosure {
  id: string;
  title: string;
  body: string;
}

interface AgreementLink {
  name: string;
  label: string;
  required: boolean;
}

interface Props {
  loading: boolean;
  submitting: boolean;
  title?: string;
  subtitle?: string;
  disclosure: Disclosure;
  agreements: Array<AgreementLink>;
  legalName: string;
  handleSubmit: (values: object) => void;
}

export const AgreementsBlueprint: React.FC<Props> = ({
  loading,
  submitting,
  title = 'Agreements',
  subtitle,
  disclosure,
  agreements,
  handleSubmit,
  legalName,
}) => {
  const scroll = useRef(null);

  // defaults all agreements to false
  const initialValues = useMemo(() => {
    return agreements.reduce(
      (vals, agreement) => {
        return { ...vals, [agreement.name]: false };
      },
      { signature: '' },
    );
  }, [agreements]);

  const fields: FieldsConfig = useMemo(() => {
    const checkboxes: Array<CheckboxConfig> = agreements.map((agreement) => ({
      type: 'checkbox',
      name: agreement.name,
      label: agreement.label,
      required: agreement.required,
    }));

    const signature: SignatureConfig = {
      name: 'signature',
      type: 'signature',
      required: true,
      legalName,
    };

    return [...checkboxes, signature];
  }, [agreements]);

  const form = useForm({
    loading,
    disabled: submitting,
    fields,
    initialValues,
    onSubmit: (values) => {
      handleSubmit(values);
      // @ts-ignore
      scroll.current?.scrollTo({ y: 0 });
    },
  });

  return (
    <FlowLayout
      title={title}
      subtitle={subtitle}
      narrow
      modal
      nextButtonText="Sign"
      canClickNext={!form.disableSubmit}
      onNext={form.submitForm}
    >
      {loading ? (
        <Loading />
      ) : (
        // @ts-ignore
        <Layout>
          {subtitle !== disclosure?.title && (
            <Text color="subtle" size="fp" weight="medium">
              {disclosure?.title}
            </Text>
          )}
          <Agreement markdown={disclosure.body} />

          <Fields stackProps={{ spacing: '1' }} form={form} fields={fields} />
        </Layout>
      )}
    </FlowLayout>
  );
};

export default AgreementsBlueprint;
