import gql from 'graphql-tag';
import { ApplicationFragment, MemberPreviewFragment } from './../queries/HealthSlasherQuery';

export interface ReportHealthEnrollmentChangesVars {}

export interface ReportHealthEnrollmentChangesResponse {
  uploadSignature: boolean;
}

export const ReportHealthEnrollmentChanges = gql`
  mutation ReportHealthEnrollmentChanges($input: ReportHealthEnrollmentChangesInput!) {
    reportHealthEnrollmentChanges(input: $input) {
      insuranceEnrollment {
        id
        status
        applicationID
        healthApplication {
          id
          ...SlashApplicationFragment
        }
      }
      error {
        field
        message
        code
      }
    }
  }
  ${ApplicationFragment}
  ${MemberPreviewFragment}
`;
