import React from 'react';
import { FormLabel } from '@uikit';
import { InputProps } from '../types';
import { useDeprecatedMutation, queries } from '@app/data';
import { DocumentUploadType } from '@app/types';
import FileDropper from '@app/_common/components/FileDropper';

interface FileInputProps<T> extends InputProps<T> {
  documentType?: DocumentUploadType;
}

const DateInput = React.forwardRef(
  <T extends any>(
    {
      testID,
      autoFocus,
      name,
      value,
      documentType,
      label,
      sublabel,
      help,
      optional,
      disabled,
      onChange,
      onBlur,
      onKeyPress,
      onFocus,
      error,
    }: FileInputProps<T>,
    ref,
  ) => {
    const [uploadDoc] = useDeprecatedMutation('uploadDocument', {
      onCompleted: (data) => onChange(data?.uploadDocument?.id),
      refetchQueries: [queries.DOCUMENT_UPLOADS],
    });

    if (!documentType) return null;

    return (
      <>
        <FormLabel
          testID={testID}
          label={label}
          sublabel={sublabel}
          help={help}
          optional={optional}
        />

        <FileDropper
          handleDelete={() => onChange(null)}
          handleDrop={async ({ fileKey, fileType }) => {
            onChange(undefined); // reset first...

            uploadDoc({
              variables: {
                input: {
                  key: fileKey,
                  fileType,
                  documentType,
                },
              },
            });
          }}
        />
      </>
    );
  },
);

DateInput.displayName = 'DateInput';
export default DateInput;
