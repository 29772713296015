import { useState, useEffect } from 'react';

/**
 * useSelectable handles selecting values
 * @return {[type]} [description]
 */
export function useSelectable(initial = [], data = {}, refresh = false) {
  const initialValues = {};
  initial.forEach(
    key => (initialValues[key] = { ...data[key], selected: true }),
  );

  const [values, setValues] = useState(initialValues);
  const reset = () => setValues(initialValues);
  const clear = () => setValues({});

  // Allows initial values to be reset when refresh
  useEffect(() => {
    if (refresh) reset();
  }, [initial]);

  /**
   * Updates a key in our
   * - Data is adjusted
   * - Selected is marked as true/false
   */
  const handleChange = (key, value, data = {}) => {
    const existing = values[key] || {};
    const updated = { ...existing.data, ...data };
    setValues({ ...values, [key]: { selected: value, data: updated } });
  };

  // updates the data for a given key
  const update = (key, data = {}) => {
    handleChange(key, !!values[key]?.selected, data);
  };

  // When selecting, given a key, mark as selected + store anything in data
  const select = (key, data = {}) => {
    handleChange(key, true, data);
  };

  // When deselecting, given a key, mark as unselected, and pass data
  const deselect = (key, data = {}) => {
    handleChange(key, false, data);
  };

  // either select or deselect based on current selection
  const toggle = (key, data = {}) => {
    handleChange(key, !values[key]?.selected, data);
  };

  const isSelected = key => values[key]?.selected || false;

  const { selected, deselected } = Object.keys(values).reduce(
    (acc, key) => {
      if (values[key]?.selected) {
        return { ...acc, selected: [...acc.selected, key] };
      } else {
        return { ...acc, deselected: [...acc.deselected, key] };
      }
    },
    { selected: [], deselected: [] },
  );

  return {
    select,
    deselect,
    update,
    toggle,
    reset,
    clear,
    isSelected,
    selected,
    deselected,
    values,
    count: selected.length || 0,
  };
}
