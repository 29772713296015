import React, { useState, useEffect } from 'react';
import { Layout } from '@layouts';
import { SheetComponentProps } from '@navigate';
import {
  useQuery,
  queries,
  BankLinksQueryData,
  useMutation,
  mutations,
  AddBankLinkItemVars,
  AddBankLinkItemResponse,
  RelinkBankLinkVars,
  RelinkBankLinkResponse,
  updates,
  AddPendingAccountResponse,
  AddPendingAccountVars,
} from '@data';
import ConfirmationBlueprint from '@app/blueprints/ConfirmationBlueprint';

const PostPlaidSheet: React.FC<SheetComponentProps['POST_PLAID']> = ({
  publicToken,
  mode,
  bankLinkID,
  pendingAccountID,
  institutionID,
  accounts,
  onCompleted,
  closeSheet,
}) => {
  const [loading, setLoading] = useState(false);
  const [called, setCalled] = useState(false);

  const { loading: fetching, data } = useQuery<BankLinksQueryData>(queries.BANK_LINKS, {
    fetchPolicy: 'cache-first',
  });

  const [addBankLinkItem, adding] = useMutation<AddBankLinkItemResponse, AddBankLinkItemVars>(
    mutations.ADD_BANK_LINK_ITEM,
    {
      update: updates.ADD_BANK_LINK,
    },
  );

  const [relinkBankLink, relinking] = useMutation<RelinkBankLinkResponse, RelinkBankLinkVars>(
    mutations.RELINK_BANK_LINK,
  );

  const [addPending, pending] = useMutation<AddPendingAccountResponse, AddPendingAccountVars>(
    mutations.ADD_PENDING_ACCOUNT,
    {
      update: updates.ADD_PENDING_ACCOUNT,
    },
  );

  useEffect(() => {
    if (!fetching) {
      if (mode === 'update') {
        relinkBankLink({ variables: { bankLinkID } });
      } else if (!!pendingAccountID) {
        addPending({ variables: { pendingAccountID, publicToken } });
      } else {
        const index = data?.viewer.bankLinks?.reduce((existingIndex, bankLink, currentIndex) => {
          if (bankLink?.plaidBank.id === institutionID) {
            const allAccountsFound = accounts?.every((account) =>
              bankLink?.accounts?.some((acc) => acc.accountNumber?.slice(-4) === account?.mask),
            );

            return allAccountsFound ? currentIndex : existingIndex;
          }

          return existingIndex;
        }, -1);

        if (index < 0 || mode === 'force') {
          addBankLinkItem({
            variables: {
              input: {
                publicToken,
                watchAll: true,
                forceBankLinkID: mode === 'force' ? bankLinkID : undefined,
              },
            },
          });
        } else {
          setCalled(true);
          setLoading(true);

          setTimeout(() => {
            setLoading(false);
          }, 1000);
        }
      }
    }
  }, [fetching, mode, publicToken, bankLinkID]);

  return (
    <Layout topSpace center margins>
      <ConfirmationBlueprint<'loading' | 'duplicate' | 'done'>
        called={called || adding.called || relinking.called || pending.called}
        loading={fetching || loading || adding.loading || relinking.loading || pending.loading}
        error={!!adding.error || !!relinking.error || !!pending.error}
        titles={{
          loading: 'Connecting...',
          done: called
            ? 'Already connected bank account'
            : 'Successfully connected your bank account',
        }}
        onSuccess={() => {
          if (onCompleted) onCompleted();
          closeSheet();
        }}
      />
    </Layout>
  );
};

export default PostPlaidSheet;
