import gql from 'graphql-tag';
import { source, incomeStats, PAYCHECKS, DELAYED_PAYCHECKS } from '../fragments';

/**
 * @todo 20231231 "lastYear"
 */
export const INCOME = gql`
  query Income($currentYear: Int!, $prevFrom: Date!, $prevTo: Date!) {
    viewerTwo {
      id
      incomeForYear(year: $currentYear) {
        month
        totalIncome
      }
    }
    viewer {
      id
      incomeState
      spouseIncome
      incomeSources(input: { period: ANY }) {
        id
        ...Source
        incomeAutomationRule {
          id
          automationType
          workType
        }
      }
      bankLinks(input: { includeNotWatched: true }) {
        id
        syncStatus
        errorCode
        providerType
        accounts {
          id
          isPrimary
        }
      }
      income {
        estimated1099Income
        estimatedW2Income
      }
      allTime: planHistorySummary(period: ANY) {
        ...IncomeStats
      }
      thisYear: planHistorySummary(period: YTD) {
        ...IncomeStats
      }
      lastYear: planHistorySummary(period: CUSTOM, toDate: $prevTo, fromDate: $prevFrom) {
        ...IncomeStats
      }
      ...Paychecks
      ...DelayedPaychecks
    }
  }
  ${source.fragments.source}
  ${incomeStats}
  ${PAYCHECKS}
  ${DELAYED_PAYCHECKS}
`;

const formatter = (data) => {
  return {
    autopilotActive: true,
    incomeSources: data?.viewer?.incomeSources,
    incomeAutomationRules: data?.viewer?.incomeAutomationRules,
    incomeByMonth: data?.viewerTwo?.incomeForYear,
    estimates: {
      untaxed: data?.viewer?.income?.estimated1099Income,
      taxed: data?.viewer?.income?.estimatedW2Income,
      spouse: data?.viewer?.spouseIncome,
    },
    stats: {
      thisYear: data?.viewer?.thisYear,
      lastYear: data?.viewer?.lastYear,
      allTime: data?.viewer?.allTime,
    },
    paychecks: data?.viewer?.paychecks?.edges,
    delayedPaychecks: data?.viewer?.delayedPaychecks?.edges,
    needsBank: !(data?.viewer?.bankLinks?.length > 0),
    ignored: data?.viewer?.testIgnored?.edges,
  };
};

export default {
  document: INCOME,
  formatter,
};
