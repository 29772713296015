import React from 'react';
import { View, StyleSheet } from 'react-native';
import Text from './Text';
import Link from './Link';
import { replaceAll } from '@app/utils';

interface AgreementProps {
  markdown: string;
}

const Agreement: React.FC<AgreementProps> = ({ markdown = '' }) => {
  const lines = markdown.split('\n');

  if (!lines || lines.length === 0) {
    return null;
  }

  return (
    <View>
      {lines.map((line, lineNumber) => {
        let content = line;

        // empty lines reflect the \n and should add some spacing
        if (line === '') {
          return <View key={`spacing-${lineNumber}`} style={styles.spacer} />;
        }

        // lines with ** are bold titles
        if (line.includes('**')) {
          const normalized = replaceAll(content, '**', '');

          return (
            <Text key={`title-${lineNumber}`} weight="semibold" color="subtle">
              {normalized}
            </Text>
          );
        }

        // lines with []() are external links
        if (line.includes('](')) {
          let hasLinks = line.split('[');

          return (
            <Text key={`url-${lineNumber}`} size="fp" color="subtle">
              {hasLinks.reduce((acc, element, index) => {
                // has a link somewhere?
                if (element.includes('](')) {
                  const [linkDetails, text] = element.split(')');
                  const [link, href] = linkDetails.split('](');
                  const showText = !!text.replace(' ', '');

                  return [
                    ...acc,
                    <Link key={`link-${lineNumber}-${index}`} small href={href}>
                      {link}
                    </Link>,
                    showText ? (
                      <Text key={`text-${lineNumber}-${index}`} size="fp" color="subtle">
                        {text}
                      </Text>
                    ) : undefined,
                  ];
                } else {
                  if (element.replace(' ', '') === '') {
                    return acc;
                  }

                  return [
                    ...acc,
                    <Text key={`text-${lineNumber}-${index}`} size="fp" color="subtle">
                      {element}
                    </Text>,
                  ];
                }
              }, [])}
            </Text>
          );
        }

        // if starts with a number, its a member of a list
        if (parseInt(line.charAt(0))) {
          return (
            <Text key={`list-${lineNumber}`} size="fp" color="subtle" style={styles.numberedList}>
              {line}
            </Text>
          );
        }

        return (
          <Text key={`line-${lineNumber}`} size="fp" color="subtle">
            {line}
          </Text>
        );
      })}
    </View>
  );
};

const styles = StyleSheet.create({
  numberedList: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingBottom: 8,
  },
  spacer: { paddingTop: 12 },
});

export default Agreement;
