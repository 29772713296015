import gql from 'graphql-tag';
import { health } from '../fragments';

const DELETE_ENROLLMENT_GROUP = gql`
  mutation DeleteEnrollmentGroup($id: ID!) {
    deleteEnrollmentGroups(ids: [$id]) {
      enrollmentGroups {
        id
        ..._EnrollmentGroup
      }
      error {
        code
        field
        message
      }
    }
  }
  ${health.EnrollmentGroup}
`;

export default {
  document: DELETE_ENROLLMENT_GROUP,
};
