import React, { useMemo, useCallback } from 'react';

import { HomeQueryData } from '@data';
import { Text, Widget, SimpleRow } from '@uikit';
import { useCopy, capitalize, getTotalBalances } from '@util';
import { navigate, routes } from '@navigate';
import { Stack } from '@app/layouts';

interface GoalWidgetProps {
  data?: HomeQueryData;
}

const shouldDisplay = (data?: HomeQueryData): boolean => {
  const availableBalances = Object.entries(getTotalBalances(data?.viewer?.goals));
  return availableBalances.some((goal) => goal[1] > 0);
};

const GoalWidget = ({ data }: GoalWidgetProps) => {
  const { $ } = useCopy();

  const availableBalances = useMemo(() => {
    return Object.entries(getTotalBalances(data?.viewer?.goals));
  }, [data?.viewer?.goals]);

  const totalBalance = availableBalances?.reduce((acc, goal) => acc + goal?.[1], 0);

  const onPress = useCallback((goalType) => {
    switch (goalType) {
      case 'taxes':
        navigate(routes.TAXES);
        break;
      case 'retirement':
        navigate(routes.PLAN_DETAIL, { slug: 'retirement' });
        break;
      case 'saved':
        navigate(routes.PLAN);
        break;
      default:
        break;
    }
  }, []);

  if (!shouldDisplay(data)) {
    return null;
  }

  return (
    <Widget
      title="Balances"
      subtitleValue={$(totalBalance)}
      subtitleSuffix="held in Catch"
      isListBased
      onPress={() => navigate(routes.PLAN)}
    >
      <Stack topSpace>
        {availableBalances.map((goal) => {
          const [goalType, availableBalance] = goal;

          return (
            availableBalance > 0 && (
              <SimpleRow
                key={goalType}
                onPress={() => onPress(goalType)}
                asset={{ render: goalType + 'Alt' }}
                label={goalType === 'saved' ? 'Savings' : capitalize(goalType)}
                accessory={<Text color="subtle">{$(availableBalance)}</Text>}
              />
            )
          );
        })}
      </Stack>
    </Widget>
  );
};

GoalWidget.shouldDisplay = shouldDisplay;
export default GoalWidget;
