import React from 'react';
import { View, StyleSheet } from 'react-native';

import { Portal } from './Portal';
import { useTheme } from '../hooks/useTheme';
import { useResponsive } from '../hooks/useResponsive';
import { ModalNav } from './ModalNav';
import { LayoutContext, useLayoutContext } from '@app/layouts';
import { shadows } from '../theme/_constants';

const noop = () => {};

export const ModalOverlay = ({
  isOpen,
  onRequestClose = noop,
  contentLayout,
  onKeyDown,
  ...props
}) => {
  return (
    <Portal>
      <View
        style={{
          position: 'fixed',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        }}
        onResponderRelease={() => onRequestClose()}
        {...props}
      />
    </Portal>
  );
};

export const ModalContent = ({
  title,
  titleComponent,
  close,
  closeSide,
  onRequestClose,
  navColor,
  textColor,
  navOverlay,
  ...props
}) => {
  const closeProps =
    closeSide === 'right'
      ? {
          right: { icon: 'close' },
          onRight: onRequestClose,
        }
      : {
          left: { icon: 'close' },
          onLeft: onRequestClose,
        };
  return (
    <View {...props}>
      {(!!title || !!close) && (
        <ModalNav
          title={title}
          textColor={textColor}
          color={navColor}
          titleComponent={titleComponent}
          overlay={navOverlay}
          {...closeProps}
        />
      )}
      {props.children}
    </View>
  );
};

export const Modal = ({
  isOpen = true,
  onRequestClose,
  preventOverlayDismiss,
  overlayStyle,
  contentStyle,
  size,
  rounded,
  presentation = 'formSheet',
  topColor,
  textColor,
  title, // title to show in modal nav
  titleComponent,
  close, // whether we should show the close button
  closeSide,
  diff = 0,
  ...props
}) => {
  const { theme } = useTheme();
  const { res } = useResponsive(responsiveModal);
  const layout = useLayoutContext(); // pass through other layout props

  const isElevated = /formSheet|pageSheet|largeSheet|basicSheet/.test(presentation);

  return !!isOpen ? (
    <ModalOverlay
      isOpen={isOpen}
      style={[res.overlay, overlayStyle, isElevated ? theme.modalOverlay : theme.promptOverlay]}
      onClick={(e) => {
        e.stopPropagation();
        if (!preventOverlayDismiss) {
          onRequestClose();
        }
      }}
      onRequestClose={preventOverlayDismiss ? undefined : onRequestClose}
    >
      <LayoutContext {...layout} layout="sheet">
        <ModalContent
          onClick={(e) => {
            e.stopPropagation();
            return false;
          }}
          navColor={topColor}
          textColor={textColor}
          testID="modal"
          title={title}
          close={close}
          closeSide={closeSide}
          titleComponent={titleComponent}
          onRequestClose={onRequestClose}
          style={[
            theme[presentation],
            theme.sheetBg,
            theme.popMenuWeb,
            shadows.screen,
            res.content,
          ]}
          {...props}
        />
      </LayoutContext>
    </ModalOverlay>
  ) : (
    <></>
  );
};

const responsiveModal = {
  overlay: StyleSheet.create({
    default: {
      position: 'fixed',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      alignItems: 'center',
      backgroundColor: '#00000022',
    },
    desktop: {
      justifyContent: 'center',
    },
    mobile: {
      justifyContent: 'flex-end',
      maxWidth: '100%',
    },
  }),
  content: StyleSheet.create({
    mobile: {
      maxHeight: '95%',
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      maxWidth: '100%',
    },
    default: { maxWidth: '100%' },
  }),
};

export default Modal;
