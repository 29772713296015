import gql from 'graphql-tag';

export const ALIAS = gql`
  query Alias {
    viewer {
      id
      user {
        id
        phoneNumber
        email
      }
      isAliasMatchAndVerified {
        emailMatchAndVerified
        phoneMatchAndVerified
      }
    }
  }
`;

const formatter = (data) => {
  const v = data?.viewer;

  return {
    id: v?.id,
    email: v?.user?.email,
    phone: v?.user?.phoneNumber,
    emailVerified: v?.isAliasMatchAndVerified?.emailMatchAndVerified,
    phoneVerified: v?.isAliasMatchAndVerified?.phoneMatchAndVerified,
  };
};

const query = {
  document: ALIAS,
  formatter,
};

export default query;
