import gql from 'graphql-tag';

const UPLOAD_AVATAR = gql`
  mutation UploadAvatarRequest($input: UploadAvatarRequest!) {
    uploadAvatar(input: $input)
  }
`;

export default {
  document: UPLOAD_AVATAR,
};
