import gql from 'graphql-tag';
import access from 'safe-access';
import { IncomeTransactionFragments, formatSource } from './source';
import banks from './banks';

// note: only fetching id/name/number on the account
// this is so that apollo doesn't try to cache everything
export const PAYCHECK = gql`
  fragment Paycheck on IncomeTransaction {
    id
    amount
    transferAmount
    memo
    date
    status
    bankName
    isAutopilot
    autoDelayBatchTime
    account {
      id
      accountName: name
      accountNumber
    }
    transfer {
      id
      status
      returnCode
    }
    ...PaycheckSource
  }
  ${IncomeTransactionFragments.transactionSource}
`;

export const PAYCHECKS = gql`
  fragment Paychecks on Viewer {
    id
    paychecks: incomeTransactionsPaged(
      pagination: { pageNumber: 1, pageSize: 10 }
      incomeAction: [USER_PENDING]
    ) {
      edges {
        id
        ... on IncomeTransaction {
          ...Paycheck
        }
      }
    }
  }
  ${PAYCHECK}
`;

export const DELAYED_PAYCHECKS = gql`
  fragment DelayedPaychecks on Viewer {
    id
    delayedPaychecks: incomeTransactionsPaged(
      pagination: { pageNumber: 1, pageSize: 10 }
      incomeAction: [DELAYED]
    ) {
      edges {
        id
        ... on IncomeTransaction {
          ...Paycheck
        }
      }
    }
  }
`;

const paycheckCard = gql`
  fragment PaycheckCard on IncomeTransaction {
    id
    amount
    memo
    date
    status
    bankName
    account {
      id
      ...PrimaryAccount
    }
    ...PaycheckSource
  }
  ${IncomeTransactionFragments.transactionSource}
  ${banks.primaryAccount}
`;

export const PaycheckFragments = {
  /**
   * fragment for rendering paychecks
   * - this can be used to figure out # of paychecks needed
   * - right now, it's used to determine whether we have paychecks on home
   */
  paycheckIds: gql`
    fragment PaycheckIds on Viewer {
      id
      incomeTransactionsPaged(
        pagination: { pageNumber: 1, pageSize: 10 }
        incomeAction: [USER_PENDING]
      ) {
        edges {
          id
          ... on IncomeTransaction {
            ...PaycheckCard
          }
        }
      }
      processedPaychecks: incomeTransactionsPaged(
        pagination: { pageNumber: 1, pageSize: 1 }
        incomeAction: [APPROVED, EXECUTED, SETTLED]
      ) {
        pageInfo {
          totalItems
        }
      }
    }
    ${paycheckCard}
  `,
};

/**
 * formats paychecks for display
 * - given the data object (from query)
 * - returns the list of income transaction paychecks
 */
export const formatPaychecks = (data) => {
  const get = access(data);

  const paychecks =
    get('viewer.incomeTransactionsPaged.edges') ||
    [].map((p) => ({ ...p, source: formatSource(p) }));

  const lastPaycheckDate = get('viewer.lastPaycheckDate.edges[0].date');
  const numProcessed = get('viewer.processedPaychecks.pageInfo.totalItems');

  return {
    paychecks,
    lastPaycheckDate,
    numProcessed,
  };
};

export default {
  ...PaycheckFragments,
  paycheckCard,
  formatPaychecks,
};
