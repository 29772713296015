import { RelinkMode } from '@types';
import { useSheet, sheets, navigate, Route } from '@navigate';
import { openLink } from './openLink';
import { fetchToken } from './plaidData';

interface PlaidLaunchOptions {
  mode: RelinkMode;
  bankLinkID?: string;
  oauthOriginalToken?: string; // when set, don't fetch
  receivedRedirectUri?: string;
  next?: {
    route: Route;
    data?: object;
  };
  setLoading?: (loading: boolean) => void;
  setShowError?: (showError: boolean) => void;
}

const PENDING = /pending_automatic_verification/;

export const launchPlaid = async (options: PlaidLaunchOptions) => {
  if (options.setLoading) options.setLoading(true);
  const token = options.oauthOriginalToken ||
    await fetchToken(options.mode === 'force' ? "" : options.bankLinkID);

  if (token) {
    openLink({
      token,
      receivedRedirectUri: options.receivedRedirectUri,
      next: options.next,
      onSuccess: (publicToken, { account, institutionID, accounts }) => {
        if (options.setLoading) {
          options.setLoading(false);
        }

        useSheet.getState().replace(sheets.POST_PLAID, {
          publicToken,
          mode: options.mode,
          bankLinkID: options.bankLinkID,
          pendingAccountID: PENDING.test(account.verificationStatus) ? account.id : undefined,
          institutionID,
          accounts,
          onCompleted: () => {
            if (options.next) {
              navigate(options.next.route, options.next.data);
            }
          },
        });
      },
      onEvent: (eventName, metadata) => {
        if (eventName === 'OPEN' && options.setLoading) {
          options.setLoading(false);
        }
      },
      onExit: (error, metadata) => {
        if (options.setLoading) {
          options.setLoading(false);
        }
      },
    });
  } else {
    //notify user to try again later since most likely issue with Plaid
    if (options.setShowError) {
      options.setShowError(true);
    }
    if (options.setLoading) {
      options.setLoading(false);
    }
  }
};
