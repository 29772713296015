import React from 'react';
import { close, routes } from '@navigate';
import { BasicFormBlueprint } from '@blueprints';
import { capitalize } from '@app/utils';
import { useDeprecatedMutation } from '@app/data';

interface WalletItemProps {
  item: {
    vertical: 'VISION' | 'LIFE' | 'DENTAL' | 'HEALTH';
    id?: string;
    carrier?: string;
    planName?: string;
    source?: string;
    groupNumber?: string;
    policyNumber?: string;
    notes?: string;
  };
}

const INSURANCE_SOURCES = {
  EMPLOYER: 'Employer',
  SPOUSE: 'Spouse',
  PARENT: 'Parent',
  MEDICAID: 'Medicaid',
  MEDICARE: 'Medicare',
  SELF_EXCHANGE: 'State or federal marketplace',
  VETERANS: 'VA or Tricare',
  OTHER: 'Other',
};

const WalletItem: React.FC<WalletItemProps> = ({ item }) => {
  // note: continue using the deprecated mutation until we have a
  // consolidated upsertWalletItem mutation
  const [upsertWalletItem, { loading: upserting }] = useDeprecatedMutation(
    `upsertWallet_${item.vertical}`,
    {
      refetchQueries: ['WalletQuery', 'CoverageQuery'],
      onCompleted: () => close({}),
    },
  );

  return (
    <BasicFormBlueprint
      loading={false}
      submitting={!!upserting}
      title=""
      button="Save"
      formConfig={{
        initialValues: {
          id: item?.id,
          carrier: item?.carrier,
          planName: item?.planName,
          source: item?.source,
          groupNumber: item?.groupNumber,
          policyNumber: item?.policyNumber,
          notes: item?.notes,
        },
        fields: [
          {
            name: 'carrier',
            type: 'text',
            label: 'Insurance carrier',
            placeholder: 'e.g. Blue Cross Blue Shield',
            required: true,
          },
          {
            name: 'planName',
            type: 'text',
            label: 'Plan name',
          },
          {
            name: 'source',
            type: 'dropdown',
            label: 'Source of coverage',
            options: Object.keys(INSURANCE_SOURCES).map((value) => ({
              label: INSURANCE_SOURCES[value],
              value,
            })),
          },
          {
            name: 'groupNumber',
            type: 'text',
            label: 'Group number',
          },
          {
            name: 'policyNumber',
            type: 'text',
            label: 'Member ID',
          },
          {
            name: 'notes',
            type: 'text',
            label: 'Notes',
          },
        ],
        onSubmit: (values) => {
          upsertWalletItem({ variables: { input: values } });
        },
      }}
    />
  );
};

export const WalletItemView = {
  name: routes.WALLET_ITEM,
  component: WalletItem,
  options: {
    layout: 'sheet',
    title: ({ item }) => `${capitalize(item.vertical)} Insurance`,
    accentColor: 'coverage',
  },
};
