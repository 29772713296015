import React from 'react';
import { Linking } from 'react-native';

import { useCopy } from '@app/utils';
import { date as fDate } from '@app/utils/format/date';
import { Card, Layout } from '@layouts';
import { Modal, Text, DocumentUploadSuccess, Button, Link, ComplexRow } from '@uikit';

export const DMIUploadedView = ({ isViewing, setIsViewing, isUploading, uploadMore }) => {
  const { c } = useCopy('catch.ede.DMI');
  const { c: c2 } = useCopy('catch.ede.enums');
  const { c: basics } = useCopy('catch.basics');
  const { issueType, statusCode, subType, uploadedDocs } = isViewing;

  return (
    <Modal isOpen={!!isViewing} onRequestClose={() => setIsViewing(null)} diff={!!isUploading}>
      <Layout.Scroll>
        <Layout margins topSpace bottomSpace>
          <Layout.Header
            title={c('uploadedDocsTitle', {
              issue: (
                <Text size="h3">
                  {issueType === 'svi' ? c(`${statusCode}.title`) : c2(`${subType}`)}
                </Text>
              ),
            })}
            subtitle={c('uploadedDocs.p1')}
          />

          <Card>
            <Text size="fp">{c2(`${uploadedDocs?.[0]?.type}`)}</Text>

            {uploadedDocs?.map((doc) => (
              <ComplexRow
                key={doc?.key}
                label={`${doc.key}`}
                sublabel={c('uploadedDocs.date', {
                  date: fDate(doc?.createdOn, 'RELATIVE'),
                })}
                asset={
                  !/PDF/.test(doc?.url)
                    ? {
                        uri: doc?.url,
                        size: 'xl',
                        shape: 'rounded',
                        shadow: 'major',
                      }
                    : {
                        icon: DocumentUploadSuccess,
                        size: 'xl',
                        shape: 'rounded',
                        shadow: 'major',
                      }
                }
                accessory={
                  <Button accentColor="brand" onPress={() => Linking.openURL(doc.url)}>
                    {basics('view')}
                  </Button>
                }
              />
            ))}
          </Card>

          <Link small onPress={() => uploadMore(isViewing)}>
            Add additional documentation
          </Link>
        </Layout>
      </Layout.Scroll>
    </Modal>
  );
};

export default DMIUploadedView;
