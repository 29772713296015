import gql from 'graphql-tag';

import { formatGoal } from '../fragments/goals';
import { formatTaxPayment } from '@app/utils';

export const TAX_GOAL = gql`
  query _TaxGoal {
    viewer {
      id
      taxGoal: goal(slug: "taxes") {
        id
        status
        slug
        goalType
        totalBalance
        availableBalance
        pendingBalance
        name
        paycheckPercentage
        ... on TaxGoal {
          autoTax
          federalPercentage
          statePercentage
        }
      }
    }
    viewerTwo {
      id
      agreements {
        id
        authorizedAboundEftpsEnrollment
      }
      taxPayments {
        id
        createdOn
        jurisdiction
        amount
        confirmationCode
        timeframe
        yearOfLastCompletedFiling
        confirmationEmailAddress
        signatureText
        filingName
        isAuto
        status
        providerStatus
      }
    }
  }
`;

const formatter = (data) => {
  return {
    taxGoal: formatGoal(data?.viewer?.taxGoal),
    needsEftps: !data?.viewerTwo?.agreements?.authorizedAboundEftpsEnrollment,
    taxPayments: data?.viewerTwo?.taxPayments?.map((pmt, idx) => {
      return formatTaxPayment(pmt);
    }),
  };
};

export default {
  document: TAX_GOAL,
  formatter,
};
