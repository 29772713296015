import React from 'react';
import { openIntercom, useCopy } from '@app/utils';
import { exit, routes } from '@navigate';
import { HiccupBlueprint } from '@app/blueprints';
import Warning from '@svg/warning-triangle.svg';
import Chat from '@svg/message-2.svg';

/**
 * This page gets shown when we have a CMS error
 * When this happens, typically the issue needs to be addressed by an engineer
 */
//props = skipHandoff = true
const HealthError = () => {
  const { c } = useCopy('catch');

  return (
    <HiccupBlueprint
      asset={{ svg: Warning, gradient: 'coverage' }}
      title={c('health.errors.title')}
      subtitles={[c('health.errors.subtitle')]}
      onPress={() => exit(routes.HOME)}
      actions={[
        {
          testID: 'support',
          label: c('basics.contactSupport'),
          onAction: openIntercom,
          svg: Chat,
        },
      ]}
    />
  );
};

export const HealthErrorView = {
  name: routes.EDE_HELP,
  component: HealthError,
  options: {},
};
