import React from 'react';

import { useCopy } from '@app/utils';
import { Copy, AssetProps, BaseColor } from '@types';
import { Button, Loading } from '@uikit';
import { ToolbarType, toolbarTypes } from './LayoutTypes';
import Layout from './Layout';
import Page from './Page';
import { Narrow } from './GridPreset';
import Toolbar from './Toolbar';
import Stack from './Stack';
import { pop } from '@navigate';

interface Props {
  title: Copy;
  titleSize?: 'block' | 'page' | 'form';
  subtitle?: Copy;
  onNext: () => any;
  onSkip?: () => any;
  asset?: AssetProps;
  children?: any;
  disabled?: boolean;
  align?: 'left' | 'center';
  toolbar: ToolbarType;
  loading?: boolean;
  containsFlatlist?: boolean;
  pageColor?: BaseColor;
  bg?: 'sheet' | 'page';
  onScroll?: () => {};
  inline?: boolean;
}

const SimpleFormToolbarDefaults = ({ toolbar, onNext, onSkip, disabled }) => {
  const { c: basics } = useCopy('catch.basics');

  if (toolbar === toolbarTypes.hidden) {
    return null;
  } else if (toolbar === toolbarTypes.continue) {
    return (
      // @ts-ignore
      <Toolbar>
        <Button testID="next-btn" onPress={onNext} disabled={disabled}>
          {basics('next')}
        </Button>
      </Toolbar>
    );
  } else if (toolbar === toolbarTypes.skipOrContinue) {
    return (
      // @ts-ignore
      <Toolbar>
        <Button testID="skip-btn" onPress={onSkip} alt>
          {basics('skip')}
        </Button>
        <Button testID="next-btn" onPress={onNext} disabled={disabled}>
          {basics('next')}
        </Button>
      </Toolbar>
    );
  } else if (toolbar === toolbarTypes.nextOrPrev) {
    return (
      // @ts-ignore
      <Toolbar onBack={pop}>
        <Button testID="next-btn" onPress={onNext} disabled={disabled} accentColor="brand">
          {basics('next')}
        </Button>
      </Toolbar>
    );
  } else if (Array.isArray(toolbar)) {
    return (
      // @ts-ignore
      <Toolbar type="stack">
        {toolbar.map(({ testID, onPress, title, ...rest }, i) => (
          <Button
            testID={testID}
            onPress={onPress}
            {...rest}
            alt={i === 0 && toolbar.length > 1}
            key={testID || i}
          >
            {title}
          </Button>
        ))}
      </Toolbar>
    );
  } else {
    return toolbar;
  }
};

/**
 * @deprecated
 * there are only 4 instances left
 * most of which can be removed after onboarding is updated
 */
const SimpleFormLayout = ({
  children,
  asset,
  title,
  titleSize = 'form',
  onNext,
  onSkip,
  disabled,
  align = 'left',
  toolbar,
  loading,
  subtitle,
  containsFlatlist,
  pageColor = 'page',
  onScroll,
  inline,
}: Props) => {
  return (
    // @ts-ignore
    <Page color={pageColor}>
      {containsFlatlist ? (
        // @ts-ignore
        <Layout topSpace>
          {/* @ts-ignore */}
          <Narrow>
            {/* @ts-ignore */}
            <Layout.Header
              // @ts-ignore
              asset={asset}
              title={title}
              titleSize={titleSize}
              subtitle={subtitle}
              align={align}
            />
            {loading ? <Loading /> : children}
          </Narrow>
        </Layout>
      ) : (
        // @ts-ignore

        <Layout.Scroll onScroll={onScroll}>
          <Layout topSpace={!inline}>
            {/* @ts-ignore */}
            <Narrow>
              <Layout.Header
                // @ts-ignore
                asset={asset}
                title={title}
                titleSize={titleSize}
                subtitle={subtitle}
                align={align}
                spaced
              />

              {loading ? <Loading /> : <Stack spacing="0">{children}</Stack>}
            </Narrow>
          </Layout>
        </Layout.Scroll>
      )}
      <SimpleFormToolbarDefaults
        toolbar={toolbar}
        disabled={disabled}
        onNext={onNext}
        onSkip={onSkip}
      />
    </Page>
  );
};

SimpleFormLayout.options = {
  layout: 'page',
  bg: 'page',
};

export default SimpleFormLayout;
