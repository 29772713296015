import React from 'react';
import { FormLabel } from '@uikit';
import { TextField } from '@app/forms_old/inputs';
import { KeyboardSet } from '@app/types';
import { InputProps } from '../types';
import { parseEmail } from './EmailInput';
import { parsePhone } from './PhoneInput';

type AliasType = 'phone' | 'email';

interface AliasInputProps<T> extends InputProps<T> {
  combo?: boolean;
}

interface InputPreset {
  label: string;
  keyboard: KeyboardSet;
  format: (value: string) => string;
  parse: (value: string) => string;
}

export const checkAliasType = (value?: string): AliasType => {
  if (/^(\+)?1?-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/i.test(value || '')) {
    return 'phone';
  } else {
    return 'email';
  }
};

const formatAlias = (value?: string): string => value || '';

const parseAlias = (value?: string): string => {
  if (checkAliasType(value) === 'phone') {
    return parsePhone(value);
  } else {
    return parseEmail(value);
  }
};

const AliasInput = React.forwardRef(
  <T extends any>(
    {
      testID,
      autoFocus,
      name,
      value,
      sublabel,
      help,
      optional,
      disabled,
      onChange,
      onBlur,
      onKeyPress,
      error,
      combo,
    }: AliasInputProps<T>,
    ref,
  ) => {
    const config: InputPreset = {
      label: 'Phone or email',
      format: formatAlias,
      parse: parseAlias,
      keyboard: 'alias',
    };

    return (
      <>
        <FormLabel
          testID={testID}
          label={config.label}
          sublabel={sublabel}
          help={help}
          optional={optional}
        />
        <TextField
          ref={ref}
          testID={testID}
          disabled={disabled}
          autoFocus={autoFocus}
          name={name}
          value={config.format(value)}
          onChangeText={(val) => onChange(config.parse(val))}
          onBlur={onBlur}
          onKeyPress={onKeyPress}
          keyboard={config.keyboard}
          error={error}
          fullWidth
          autoCapitalize="none"
        />
      </>
    );
  },
);

AliasInput.displayName = 'AliasInput';
export default AliasInput;
