import React from 'react';
import { View } from 'react-native';

import { Button, Flag, Text, useTheme } from '@uikit';
import { constants, isWithin30Days, LongFormDate, useCopy } from '@app/utils';
import { Card, Stack } from '@layouts';

const selectThemeType = (status, resolveBy) => {
  switch (status) {
    case 'ACTION_NEEDED':
      return isWithin30Days(resolveBy) ? 'negativeLight' : 'taxesLight';
    case 'PROCESSING':
      return 'border';
    case 'EXPIRED':
      return 'border';
    case 'COMPLETE':
      return 'positiveLight';
    default:
      return;
  }
};

/** @deprecated use standard patterns */
const ApplicationIssueCard = ({
  status,
  members,
  lastReviewed,
  resolveBy,
  statusCode,
  subType,
  issueType,
  onRequestUpload,
  onViewDocuments,
  notices,
  expired,
  verificationType,
  getDocument,
}) => {
  const { theme, themeColors } = useTheme();
  const themeType = selectThemeType(status, resolveBy);
  const { c: c1 } = useCopy('catch.ede.DMI');
  const { c: c2 } = useCopy('catch.ede.enums');

  const renderNotices = (notice, idx) => {
    if (!expired && notice?.name === constants.dmiNoticeTypes.CLOCK_EXPIRATION_NOTICE) {
      return;
    }

    const buttonNamePrefix = issueType === 'dmi' ? 'DMI' : 'SVI';

    return (
      <Button
        key={notice.id}
        small
        alt
        testID={`getNotices_${idx}`}
        onPress={() =>
          getDocument({
            variables: { input: { dsrsIdentifier: notice.dsrsIdentifier } },
          })
        }
      >
        {notice.name
          ? notice.name
              .replace(/_/g, ' ')
              .split(' ')
              .map((word) => word[0] + word.substring(1).toLowerCase())
              .join(' ')
          : `${buttonNamePrefix} notice`}
      </Button>
    );
  };

  return (
    <>
      <Card color="card" padded={false} border>
        <View style={theme.allSpace3}>
          <Stack spacing="1">
            <View style={[theme.row, theme.spaceBetween]}>
              <Flag type="none">
                {
                  // for Adoption, UI needs to show either the applicant's name
                  // or dependent's name unlike showing household in the Flag
                  //  for ORR compliance
                  members.length > 1 && statusCode !== 'ADOPTION'
                    ? c1('household')
                    : members[0]?.givenName
                }
              </Flag>
              <Flag level={themeType}>{c1(`${status}.title`)}</Flag>
            </View>

            <Stack spacing="+gap">
              <Text size="p" weight="medium">
                {issueType === 'svi'
                  ? c1(`${statusCode}.title`)
                  : c2(`${subType}`) || c2(`${verificationType}`)}
              </Text>

              {!!lastReviewed && (
                <Text size="fp" color="subtler">
                  {c1('reviewDate', {
                    date: <LongFormDate>{lastReviewed}</LongFormDate>,
                  })}
                </Text>
              )}
            </Stack>
            {!!notices && notices?.length > 0 && (
              <View>{notices?.map((n, idx) => renderNotices(n, idx))}</View>
            )}
          </Stack>
        </View>
        <View
          style={[
            theme.row,
            theme.mobileSnap,
            theme.xSpace3,
            theme.ySpace2,
            { backgroundColor: themeColors[themeType + 'Color'] },
          ]}
        >
          <View style={theme.flex1}>
            <Text size="h6" gutter={false}>
              {c1(`${status}.docCaption`)}
            </Text>
            <Text size="sm">
              {c1(`${status}.docStatus`, {
                date: (
                  <Text weight="semibold">
                    <LongFormDate>{resolveBy}</LongFormDate>
                  </Text>
                ),
              })}
            </Text>
          </View>
          {/ACTION_NEEDED/.test(status) && (
            <Button small onPress={onRequestUpload}>
              {c1('addButton')}
            </Button>
          )}
          {/PROCESSING|COMPLETE/.test(status) && (
            <View>
              <Text style={theme.fpl} onPress={onViewDocuments}>
                {c1('viewLink')}
              </Text>
            </View>
          )}
        </View>
      </Card>
    </>
  );
};

export default ApplicationIssueCard;
