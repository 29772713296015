import React, { useCallback } from 'react';
import {
  useMutation,
  mutations,
  ChangeAliasError,
  ChangeEmailResponse,
  ChangeEmailVars,
} from '@data';
import { OnboardingFormBlueprint } from '@blueprints';
import { navigate, routes } from '@navigate';
import { FormConfig } from '@app/forms';
import { fields } from '@app/config';

const messages: Record<ChangeAliasError, string> = {
  CHANGE_ALIAS_ERROR_ALIAS_IN_USE: 'Already exists',
  CHANGE_ALIAS_ERROR_SAME_ALIAS: 'Phone number is unchanged',
  CHANGE_ALIAS_ERROR_MISSING_ALIAS: 'Must provide a phone number',
  CHANGE_ALIAS_ERROR_INVALID_ALIAS: 'The phone number was invalid',
};

const AccountSetupEmail = ({ loading, email, isEmailVerified, requestedEmail, handleNext }) => {
  const [changeEmail, { loading: submitting }] = useMutation<ChangeEmailResponse, ChangeEmailVars>(
    mutations.CHANGE_EMAIL,
  );

  const onSubmit = useCallback(
    async (values, methods) => {
      // do this first, because the mutation might hit an error
      if (isEmailVerified && email === values.email) {
        navigate(routes.ACCOUNT_SETUP_DOB);
      } else {
        const result = await changeEmail({ variables: { email: values.email } });
        const err: ChangeAliasError | undefined = result?.data?.changeEmail?.error;

        if (err) {
          methods.setError('email', { type: 'custom', message: messages[err] });
        } else {
          handleNext({ newEmail: result?.data?.changeEmail.newEmail });
        }
      }
    },
    [isEmailVerified, email],
  );

  const formConfig: FormConfig<{ email: string }> = {
    initialValues: { email: requestedEmail || email },
    fields: [fields.EMAIL],
    onSubmit,
  };

  return (
    <OnboardingFormBlueprint
      loading={loading}
      disabled={submitting}
      title="What's your email address?"
      formConfig={formConfig}
    />
  );
};

export const AccountSetupEmailView = {
  name: routes.ACCOUNT_SETUP_EMAIL,
  component: AccountSetupEmail,
  options: {},
};
