import gql from 'graphql-tag';

export const RETIREMENT_TIME_SERIES = gql`
  query RetirementTimeSeries($range: TimeSeriesRange!, $id: ID!) {
    viewerTwo {
      id
      wealthAccountData(range: $range, wealthAccountID: $id) {
        date
        marketValue
        weekMoneyWeightedReturn
        monthMoneyWeightedReturn
        yearMoneyWeightedReturn
        weekTimeWeightedReturn
        monthTimeWeightedReturn
        yearTimeWeightedReturn
        allMoneyWeightedReturn
      }
    }
  }
`;

const formatter = (data) => {
  const v = data?.viewerTwo;

  return {
    retirementTimeSeries: v?.wealthAccountData,
  };
};

const query = {
  document: RETIREMENT_TIME_SERIES,
  formatter,
};

export default query;
