import React from 'react';

import { Page, Layout } from '@layouts';
import { useQuery, IncomeSkippedQueryData, queries } from '@app/data';
import { Asset, ComplexRow, Spinner, Text } from '@app/_ui-kit';
import { useCopy } from '@app/utils';

/**
 * For CX troubleshooting purposes
 */
const IncomeSkippedComponent = () => {
  const { $ } = useCopy();
  const { data, loading } = useQuery<IncomeSkippedQueryData>(queries.INCOME_SKIPPED);
  const ignored = data?.viewer?.ignored?.edges || [];

  return loading ? (
    <Spinner />
  ) : (
    <Page color="sheet">
      <Layout.Scroll margins>
        {ignored?.map((tran) => (
          <ComplexRow
            fp
            key={tran.id}
            sublabel={tran?.memo?.slice(0, 32)}
            labelColor={tran?.source ? 'text' : 'subtler'}
            label={tran?.source ? tran?.source?.text : 'Not tagged'}
            accessory={<Text>{$(tran?.amount)}</Text>}
            asset={{
              ...Asset.configureSource(tran.source),
              disabled: true,
              badge: 'skipped',
              style: { filter: 'saturate(0)' },
            }}
          />
        ))}
      </Layout.Scroll>
    </Page>
  );
};

export const IncomeSkippedView = {
  name: 'INCOME_SKIPPED',
  component: IncomeSkippedComponent,
  options: {
    layout: 'sheet',
    presentation: 'largeSheet',
  },
};
