import React, { useMemo } from 'react';
import gql from 'graphql-tag';
import { differenceInMonths } from 'date-fns';
import { useMutation } from '@data';
import { routes } from '@navigate';
import { BasicLayout, Stack, Toolbar } from '@app/layouts';
import { Button } from '@app/_ui-kit';
import { PolicyCard } from '@app/partial';
import { HealthSlasherStackScreenProps } from '../HealthSlasherStack';

import Sparkles from '@svg/sparkles.svg';
import Bank from '@svg/bank.svg';
import Check from '@svg/check.svg';
import { copy, currentYear, getToday, handleHealthResponse } from '@app/utils';

type SlasherResultOutcome = 'POSITIVE' | 'NEGATIVE' | 'NOCHANGE';

interface ComputedAmounts {
  estimatedTaxes: number;
  totalSavings: number;
}

const COPY: Record<SlasherResultOutcome, (ComputedAmounts) => any> = {
  POSITIVE: ({ totalSavings }) => ({
    title: `Slash your health premiums by ${copy.$(totalSavings, { whole: true })} per month`,
    subtitle: 'In savings with tax credits',
    icon: Sparkles,
    color: 'positive',
  }),
  NEGATIVE: ({ estimatedTaxes }) => ({
    title: `Reduce tax bill by ${copy.$(estimatedTaxes, { whole: true })}`,
    subtitle: 'We estimate we can reduce your tax bill by this amount by adjusting your premiums',
    icon: Bank,
    color: 'coverage',
  }),
  NOCHANGE: () => ({
    title: 'Looks like you’re getting all the credits you qualify for',
    subtitle: 'We’ll keep an eye out for new tax credits that become available.',
    icon: Check,
    color: 'coverage',
  }),
};

const SUBMIT = gql`
  mutation SubmitSlasherEnrollment($applicationID: ID!) {
    submitEnrollment(id: $applicationID) {
      currentAppStatus
      policyResponseCode
      policyResponseSubcode
      memberResponseCode
      memberResponseSubcode
      error {
        code
        field
        message
      }
      healthServiceError {
        code
        field
        message
      }
    }
  }
`;

/**
 *
 * Premium Slasher Results/Enroll
 *
 * @todo copy
 * @todo help text
 *
 */
const HealthSlasherResults = ({
  handleNext,
  handleExit,
  loading,
  outcome,
  submittedApp,
}: HealthSlasherStackScreenProps) => {
  const [submitEnrollment, { loading: submitting }] = useMutation(SUBMIT, {
    ...handleHealthResponse({ onCompleted: handleNext }),
    variables: { applicationID: submittedApp?.id },
  });

  const groups = useMemo(() => {
    return [...(submittedApp?.enrollmentGroups || [])].sort((a, b) =>
      a.enrollmentType > b.enrollmentType ? -1 : 1,
    );
  }, [submittedApp]);

  const computed: ComputedAmounts = useMemo(() => {
    const savingsPerMonth = groups.reduce((totalDiff, g) => {
      const originalPremium = g.planPremium - g.previousAppliedAPTCAmount;
      const diff = originalPremium - g.planPremiumEffective;
      return totalDiff + diff;
    }, 0);

    // defaulting to the policy ending 12/31
    const months = differenceInMonths(new Date(currentYear + 1, 0, 1), getToday());
    const estimatedTaxes = months * Math.abs(savingsPerMonth);

    return {
      estimatedTaxes,
      totalSavings: savingsPerMonth,
    };
  }, [groups]);

  const copy = COPY[outcome](computed);

  return (
    <BasicLayout
      loading={loading}
      icon={{ svg: copy.icon, gradient: copy.color }}
      title={copy.title}
      subtitles={[copy.subtitle]}
      toolbar={
        <Toolbar type="stack">
          <Button
            testID="complete-update"
            loading={submitting}
            disabled={loading}
            onPress={outcome === 'NOCHANGE' ? handleExit : submitEnrollment}
          >
            {outcome === 'NOCHANGE' ? 'Done' : 'Complete Update'}
          </Button>
        </Toolbar>
      }
    >
      <Stack spacing="2">
        {groups.map((eg) => (
          <PolicyCard
            key={eg.id}
            type={eg.enrollmentType}
            carrier={eg.plan.issuer.name}
            plan={eg.plan.name}
            metalLevel={eg.plan.metalLevel}
            premium={eg.planPremiumEffective}
            strikeoutPremium={eg.planPremium - eg.previousAppliedAPTCAmount}
          />
        ))}
      </Stack>
    </BasicLayout>
  );
};

export const HealthSlasherResultsView = {
  name: routes.HEALTH_SLASHER_RESULTS,
  component: HealthSlasherResults,
  options: {},
};
