import React from 'react';
import { OptionGroup } from '@uikit';
import { useDeprecatedMutation } from '@data';
import { useCopy } from '@app/utils';
import { routes, ScreenDefinition } from '@navigate';

// relative imports
import { ExplorerLayout } from '@layouts';

const ExplorerUsage = ({ handleNext }) => {
  const { c } = useCopy('catch.healthExplorer.explorerUsage');

  const [upsert] = useDeprecatedMutation('upsertHealthPreference');

  const options = [
    {
      primary: c('rarelyPrimary'),
      secondary: c('rarelySecondary'),
      payload: 'REC_LOW',
    },
    {
      primary: c('sometimesPrimary'),
      secondary: c('sometimesSecondary'),
      payload: 'REC_MEDIUM',
    },
    {
      primary: c('veryOftenPrimary'),
      secondary: c('veryOftenSecondary'),
      payload: 'REC_HIGH',
    },
  ];

  return (
    <ExplorerLayout
      title={c('title')}
      subtitle="This is just for recommendation purposes and won’t limit your actual usage in any way"
      canSkip
      disabled={true}
      onSkip={handleNext}
      onNext={handleNext}
    >
      <OptionGroup
        options={options?.map((o, i) => {
          return {
            label: o.primary,
            description: o.secondary,
            selected: false,
            value: o.payload,
          };
        })}
        onPress={(val) => {
          upsert({
            variables: {
              input: {
                plannedUsage: val,
              },
            },
          });
          handleNext();
        }}
      />
    </ExplorerLayout>
  );
};

export const ExplorerUsageView: ScreenDefinition = {
  name: routes.EXPLORER_USAGE,
  component: ExplorerUsage,
  options: {},
};
