import React from 'react';
import { View } from 'react-native';
import { useTheme } from '@uikit/hooks/useTheme';
import { Text } from '@uikit/components/Text';
import { Tooltip } from '@uikit/components/Tooltip';
import { Pressable } from '@uikit/components/Pressable';

const FieldLabel = ({ label, labelID, required, fieldID, tooltip }) => {
  const { theme } = useTheme();

  return (
    <View style={[theme.fieldLegend, theme.yGutter1, { flexShrink: 1 }]}>
      <View style={theme.row}>
        <Tooltip
          title={label}
          content={tooltip}
          direction="top"
          type="sheet"
          style={[{ flexShrink: 1 }]}
        >
          <Pressable
            haptics="LIGHT"
            testID={`${labelID}-label`}
            style={[
              // ensures that the text only takes up as much space as it needs
              { alignSelf: 'flex-start' },
            ]}
          >
            <Text
              // @ts-ignore
              nativeID={labelID}
              htmlFor={fieldID}
              size="p"
              weight="regular"
              underline={tooltip}
            >
              {label}
              {required && (
                <Text weight="bold" color="credit">
                  {' '}
                  *
                </Text>
              )}
            </Text>
          </Pressable>
        </Tooltip>
      </View>
    </View>
  );
};

export default FieldLabel;
