import React, { useCallback, useMemo } from 'react';
import { open, routes, useSheet } from '@navigate';
import { ComplexRow, Text } from '@uikit';
import { getEventProperties, translateIncomeTransaction } from '@app/utils';
import shallow from 'zustand/shallow';

interface EventRowProps {
  incomeEvent: any;
  isPlaceholder?: boolean;
}

/**
 * @todo looks like this is dealing with some of the incomeTransaction vs incomeEvent messiness without explanation
 */
export const IncomeSourceEventRow: React.FC<EventRowProps> = ({
  incomeEvent,
  isPlaceholder,
  ...rest
}) => {
  const { openSheet } = useSheet((state) => ({ openSheet: state.open }), shallow);

  const translated = useMemo(() => translateIncomeTransaction(incomeEvent), [incomeEvent]);
  const properties = useMemo(
    () =>
      getEventProperties(
        translated,
        {
          showIncomeDecision: (_event) =>
            open(routes.PAYCHECK_BREAKDOWN, {
              paycheckID: _event?.id,
              paycheck: _event,
              done: true,
            }),
          showReceipt: (_event) =>
            open(routes.PAYCHECK_BREAKDOWN, {
              paycheckID: _event?.id,
              paycheck: _event,
              done: true,
            }),
        },
        { showContributed: true, accentColor: 'brand' },
      ),
    [openSheet, incomeEvent],
  );

  const { onPress, title, asset, subtitle, status, time } = properties || {};

  const handleEventPress = useCallback(() => {
    onPress && typeof onPress === 'function' && onPress(translated);
  }, [onPress, incomeEvent, translated]);

  return (
    <ComplexRow
      loading={isPlaceholder}
      testID="source-detail-event"
      interaction={'navigation'}
      asset={asset}
      onPress={handleEventPress}
      label={title}
      sublabel={subtitle}
      sublabelColor={status === 'Failed' ? 'credit' : undefined}
      accessory={
        <Text size="sm" color="subtle" loading={isPlaceholder}>
          {time}
        </Text>
      }
      {...rest}
    />
  );
};

export default IncomeSourceEventRow;
