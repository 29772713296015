const isNodeEnv = (str) => process.env.NODE_ENV === str;
const isCoveredEnv = (str) => process.env.REACT_APP_COVERED_ENV === str;
const isNative = (_) => navigator && navigator.product === 'ReactNative';
const isDesktop = (_) => process.env.REACT_APP_PLATFORM === 'desktop';
const isSafari = (_) => /Apple/.test(navigator.vendor);

const environments = {
  // Prod/Stage/Dev are the primary environments we have our applications
  // deployed in.
  isProd: isNodeEnv('production') && isCoveredEnv('prod'),
  isStage: isNodeEnv('production') && isCoveredEnv('stage'),
  isDev: isNodeEnv('production') && isCoveredEnv('dev'),

  // isLocal is used for when the dev server hot reloading is happening
  isLocal: isNodeEnv('development'),

  // isProdLike is used for whenever we're building code in a
  // minified/production like way.
  isProdLike: isNodeEnv('production'),
  // isDevLike is used for whenever we're in an environment used for developers
  // to sandbox/experiment.
  isDevLike: isNodeEnv('development') || isCoveredEnv('dev'),
  isHealth: process.env.REACT_APP_PHASE_TWO === 'ON',
  isGeorgia: process.env.REACT_APP_GEORGIA_ACCESS === 'ON',

  // isTest is used when the test suite is running
  isTest: isNodeEnv('test'),

  // isNative is used when code is rendering in the React Native context
  isNative: isNative(),

  // Should be fairly reliable...
  isSafari: isSafari(),

  // ask Andrew
  isDesktop: isDesktop(),
  isInternal: !isCoveredEnv('prod'),
};

const authCreds = {
  identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
  userPoolId: process.env.REACT_APP_USER_POOL_ID || process.env.STORYBOOK_USER_POOL_ID,
  region: process.env.REACT_APP_AWS_REGION,
  userPoolWebClientId: process.env.REACT_APP_WEB_CLIENT_ID || process.env.STORYBOOK_WEB_CLIENT_ID,
};

const analytics = {
  segmentKey: process.env.REACT_APP_SEGMENT_KEY,
};

const graphql = {
  uri: process.env.REACT_APP_GRAPHQL_URL,
};

const graphcms = {
  uri: process.env.REACT_APP_GRAPHCMS_URL,
};

const intercom = {
  appId: process.env.INTERCOM_ID,
};

const serverEvents = {
  uri: process.env.SERVER_EVENTS_PATH,
};

const uploads = {
  bucket: process.env.REACT_APP_UPLOAD_BUCKET,
};

const plaid = {
  publicKey: process.env.REACT_APP_PLAID_PUBLIC_KEY,
  webhookUrl: process.env.REACT_APP_PLAID_WEBHOOK_URL,
};

const envNames = {
  local: 'Dev',
  dev: 'Dev',
  stage: 'Stage',
};

const plaidEnvs = {
  dev: 'sandbox',
  stage: 'sandbox',
  prod: 'production',
};

const buildConfig = {
  number: process.env.REACT_APP_BUILD_NUMBER,
  version: process.env.REACT_APP_VERSION_NUMBER,
  time: process.env.REACT_APP_BUILD_TIMESTAMP,
};

const adminUrls = {
  dev: 'https://at.dev.catch.co',
  stage: 'https://at.stage.catch.co',
  prod: 'https://at.catch.co',
};

const webUrls = {
  prod: 'https://app.catch.co',
  stage: 'https://stage.catch.co',
  dev: 'https://dev.catch.co',
  local: 'https://dev.catch.co',
};

const marketplaceKeys = {
  dev: 'd687412e7b53146b2631dc01974ad0a4',
  stage: 'd687412e7b53146b2631dc01974ad0a4',
  prod: 'uplvuvRrQRzAWlleHNXfPcN5qkdnVJBg',
};

const features = {
  healthEDE: true,
};

const key = process.env.REACT_APP_COVERED_ENV || 'dev';

export default {
  ...environments,
  serverEvents,
  graphql,
  graphcms,
  authCreds,
  intercom,
  uploads,
  analytics,
  envName: envNames[key],
  isSentryEnabled: environments.isProd,
  plaid,
  plaidEnv: plaidEnvs[key],
  buildConfig,
  features,
  adminUrl: adminUrls[key],
  webUrl: webUrls[key],
  marketplaceKey: marketplaceKeys[key],
  googleKey: process.env.REACT_APP_GMAP_KEY,
};
