import gql from 'graphql-tag';

export const DOCUMENT_UPLOADS = gql`
  query DocUploads($input: DocsRequiredInput) {
    viewerTwo {
      id
      identity(providerType: UNIT) {
        id
        providerType
        docsRequired(input: $input) {
          id
          providerID
          documentType
        }
      }
      folioIdentity: identity(providerType: FOLIO) {
        id
        providerType
        docsRequired(input: $input) {
          id
          providerID
          documentType
        }
      }
      documentUploads {
        id
        createdOn
        ext
        documentType
      }
    }
  }
`;

function formatter(data) {
  // We only ask for the same document once, Platform takes care of
  // sending the documents again if the same documents are required
  const docsNeeded = data?.viewerTwo?.identity?.docsRequired || [];
  const folioDocsRequired = data?.viewerTwo?.folioIdentity?.docsRequired || [];
  for (let new_d of folioDocsRequired) {
    if (!docsNeeded.some((d) => d.documentType == new_d.documentType)) {
      docsNeeded.push(new_d);
    }
  }

  return {
    userId: data?.viewerTwo?.id,
    docsNeeded,
    allUploads: data?.viewerTwo?.documentUploads,
  };
}

export default {
  document: DOCUMENT_UPLOADS,
  formatter,
};
