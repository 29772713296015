import React, { useCallback, useMemo } from 'react';

import { useTheme } from '@uikit';
import {
  GoalTimeSeriesContributionData,
  TimeSeriesContributionDataPoint,
} from '@data/deprecated/queries/goalContributionsForYear';
import { GoalType } from '@types';
import { useDeprecatedQuery } from '@data';
import { monthNames, useCopy } from '@util';
import BarChart from './base/Bar';

const emptyMonth = (year: number, month: number): TimeSeriesContributionDataPoint => ({
  year,
  month,
  totalContributionsValue: 0,
});

const ContributionBar: React.FC = ({ showAxisLabels }) => {
  const { themeColors } = useTheme();
  const { $ } = useCopy();

  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;

  const {
    goalContributionsForYear: _goalContributionsForYear,
  }: { goalContributionsForYear: GoalTimeSeriesContributionData[] } = useDeprecatedQuery(
    'goalContributionsForYear',
    {
      variables: { year: currentYear },
    },
  );

  const goalContributionsForYear = useMemo(
    () =>
      _goalContributionsForYear
        ? _goalContributionsForYear.filter(({ goalType }) => goalType !== 'TAX')
        : [],
    [_goalContributionsForYear],
  );

  // Combine all monthly contributions for give goal types.
  const sumContributionsForGoalTypes = useCallback(
    (goalTypes: Set<GoalType>): TimeSeriesContributionDataPoint[] => {
      const result: Record<number, TimeSeriesContributionDataPoint> = {};

      // Initialize with empty months
      for (let i = 1; i < 13; i++) {
        result[i] = emptyMonth(currentYear, i);
      }

      if (goalContributionsForYear && goalContributionsForYear.length > 0) {
        const retirementSeries = goalContributionsForYear.filter((goalContributions) =>
          goalTypes.has(goalContributions.goalType),
        );

        for (let goalContributions of retirementSeries) {
          let month = 1;
          for (let monthContribution of goalContributions.data) {
            const data = result[month];
            data.totalContributionsValue += monthContribution.totalContributionsValue;
            result[month] = data;
            month++;
          }
        }
      }
      return Object.values(result).sort((a, b) => a.month - b.month);
    },
    [goalContributionsForYear, currentYear],
  );

  const retirementContributionsForYear = useMemo(
    () => sumContributionsForGoalTypes(new Set<GoalType>(['RETIREMENT'])),
    [sumContributionsForGoalTypes],
  );

  const savingsContributionsForYear = useMemo(
    () =>
      sumContributionsForGoalTypes(
        new Set<GoalType>([
          'PTO',
          'HEALTH_EXPENSES',
          'FAMILY_LEAVE',
          'EMERGENCY_SAVINGS',
          'CUSTOM_SAVINGS',
        ]),
      ),
    [sumContributionsForGoalTypes],
  );

  const sliceByMonth = useCallback(
    (data) => {
      if (currentMonth < 6) {
        return data.slice(0, 6);
      } else {
        return data.slice(6);
      }
    },
    [currentMonth],
  );

  const { retirementArray, savingsArray } = useMemo(() => {
    return {
      retirementArray: sliceByMonth(
        retirementContributionsForYear.map((item) => ({
          x: monthNames[item.month - 1],
          y: item.totalContributionsValue,
          month: item.month,
          label: $(item.totalContributionsValue),
          xLabel: monthNames[item.month - 1],
          goalSlug: 'retirement',
          fill: themeColors.retirementColor,
        })),
      ),
      savingsArray: sliceByMonth(
        savingsContributionsForYear.map((item) => ({
          x: monthNames[item.month - 1],
          y: item.totalContributionsValue,
          month: item.month,
          label: $(item.totalContributionsValue),
          xLabel: monthNames[item.month - 1],
          goalSlug: 'savings',
          fill: themeColors.savedColor,
        })),
      ),
    };
  }, [retirementContributionsForYear, savingsContributionsForYear]);

  if (!goalContributionsForYear || !savingsArray || !retirementArray) {
    return null;
  }

  return (
    <BarChart
      data={savingsArray}
      data2={retirementArray}
      x="x"
      y="y"
      options={{ showAxisLabels: true }}
    />
  );
};

export default ContributionBar;
