import React, { useMemo } from 'react';
import { formatMedicalName } from '@catch-co/health-utils';

import { AnnotationItem } from '@uikit';
import PolicyCard from '../policies/PolicyCard';

interface PlanShoppingCardProps {
  disabled: boolean;
  selectedID: string;
  enrollmentType?: 'HEALTH_INSURANCE' | 'DENTAL_INSURANCE';
  plan: {
    isLowestPremium?: boolean;
    isLowestDeductible?: boolean;
    healthPlan: {
      planID: string;
      title: string;
      type: string;
      metal: string;
      issuer: {
        name: string;
        title: string;
      };
      familyDeductible: number;
      individualDeductible: number;
      premium: number;
      premiumWithCredit: number;
      hsaEligible: boolean;
    };
  };
  selectPlan: (selectedID: string) => void;
}

/**
 * PlanShoppingCard is a variation of the coverage card that can be
 * easily used when doing plan shopping. It includes:
 * - Adding plan annotations like lowest premium, etc.
 * - Showing selected plans and allowing plan selection on press
 */
const PlanShoppingCard: React.FC<PlanShoppingCardProps> = ({
  disabled,
  selectedID,
  selectPlan,
  enrollmentType,
  plan,
}) => {
  const annotations = useMemo(() => {
    const notes: Array<AnnotationItem> = [];

    if (plan?.isLowestPremium)
      notes.push({ id: 'lowest-premium', title: 'Lowest premium', scheme: 'positive' });
    if (plan?.isLowestDeductible)
      notes.push({ id: 'lowest-deductible', title: 'Lowest deductible', scheme: 'positive' });

    // handles provider notes
    const providers = plan?.providerCoverage || [];
    providers.forEach((provider) => {
      if (provider.isCovered) {
        notes.push({
          id: provider.npi,
          title: `Accepted by ${formatMedicalName(provider.name)}`,
          scheme: 'positive',
        });
      } else {
        notes.push({
          id: provider.npi,
          title: `Not accepted by ${formatMedicalName(provider.name)}`,
          scheme: 'negative',
        });
      }
    });

    // handles drug coverages
    const drugs = plan?.drugCoverage || [];
    drugs.forEach((drug) => {
      if (drug.isCovered) {
        notes.push({
          id: drug.rxcui,
          title: `Covers ${formatMedicalName(drug.name)}`,
          scheme: 'positive',
        });
      } else {
        notes.push({
          id: drug.rxcui,
          title: `Doesn't cover ${formatMedicalName(drug.name)}`,
          scheme: 'negative',
        });
      }
    });

    const weights = {
      positive: 3,
      info: 2,
      negative: 1,
    };

    // sort so that notes show up in positive - info - negative order
    return notes.sort((a, b) => weights[b.scheme] - weights[a.scheme]);
  }, [plan]);

  return (
    <PolicyCard
      disabled={disabled}
      checked={selectedID === plan.healthPlan.planID}
      onPress={selectPlan ? () => selectPlan(plan.healthPlan.planID) : undefined}
      type={enrollmentType || 'HEALTH_INSURANCE'}
      badgeType="PLAN_DETAILS"
      footerType="DEDUCTIBLE"
      plan={plan.healthPlan.title}
      carrier={plan.healthPlan.issuer.title}
      metalLevel={plan.healthPlan.metal}
      planType={plan.healthPlan.type}
      premium={plan.healthPlan.premiumWithCredit}
      strikeoutPremium={plan.healthPlan.premium}
      familyDeductible={plan.healthPlan.familyDeductible}
      individualDeductible={plan.healthPlan.individualDeductible}
      hsaEligible={plan.healthPlan.hsaEligible}
      annotations={annotations}
    />
  );
};

export default PlanShoppingCard;
