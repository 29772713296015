import React from 'react';
import { Linking } from 'react-native';
import { Button, useResponsive } from '@uikit';
import { Toolbar, CalloutLayout } from '@layouts';
import { useCopy, STATES, constants } from '@app/utils';
import { navigate, routes } from '@navigate';

const PREFIX = 'catch.ede.CIC.stateHandoff';

/**
 *
 * @todo support other assets in Callout
 */
const CICStateHandoff = ({ exchangeState: state }) => {
  const { c } = useCopy(PREFIX);
  const { isNative } = useResponsive();
  const url = constants.stateExchanges[state]?.url;
  const exchange = constants.stateExchanges[state]?.name;
  const handleHandoff = () => {
    if (isNative) {
      Linking.openURL(url);
    }

    navigate(routes.CIC_STATE_PICKUP);
  };

  return (
    <CalloutLayout
      title={c('title', { state: STATES[state] })}
      subtitles={[c('subtitle1', { exchange }), c('subtitle2')]}
      toolbar={
        <Toolbar>
          <Button testID="shop-state-exchange" href={url} onPress={handleHandoff}>
            {c('button')}
          </Button>
        </Toolbar>
      }
    />
  );
};

export const CICStateHandoffView = {
  name: 'CIC_STATE_HANDOFF',
  component: CICStateHandoff,
  options: {},
};
