import React, { useEffect, useMemo, useState, useRef } from 'react';
import { StyleSheet, View, Animated } from 'react-native';

import { GoalType } from '@types';
import { currentYear, formatBenefit, useCopy } from '@app/utils';
import { useDeprecatedQuery, useQuery, queries, PayrollQueryData } from '@data';
import { routes } from '@navigate';
import { Layout, Stack, FlowLayout, Split } from '@layouts';
import { IncomePromptCard } from '@app/_common';
import {
  Gradient,
  Text,
  UIGradientName,
  useResponsive,
  CatchTheme,
  useTheme,
  ComplexRow,
  Asset,
} from '@uikit';

const SAMPLE_PAYCHECK = 500;
const BAR_HEIGHT = 40;
const DATE = new Date()?.toISOString();

type SimpleGoalType = 'SAVINGS' | 'RETIREMENT' | 'TAX';
type SimpleGoalConfigs = Record<SimpleGoalType, SimpleGoalConfig>;

interface SimpleGoalConfig {
  type: SimpleGoalType;
  bgColor: UIGradientName;
  paycheckPercentage?: number;
  sampleNumber?: number;
}

const GoalTypeSimplified: Record<GoalType, SimpleGoalType> = {
  PTO: 'SAVINGS',
  EMERGENCY_SAVINGS: 'SAVINGS',
  CUSTOM_SAVINGS: 'SAVINGS',
  FAMILY_LEAVE: 'SAVINGS',
  HEALTH_EXPENSES: 'SAVINGS',
  RETIREMENT: 'RETIREMENT',
  TAX: 'TAX',
};

const GoalConfigs: SimpleGoalConfigs = {
  TAX: {
    type: 'TAX',
    bgColor: 'taxes',
  },
  SAVINGS: {
    type: 'SAVINGS',
    bgColor: 'saved',
  },
  RETIREMENT: {
    type: 'RETIREMENT',
    bgColor: 'retirement',
  },
};

const GoalBar: React.FC<{
  data: SimpleGoalConfig;
  name: string;
}> = ({ data, name }) => {
  const { $, p } = useCopy();

  const barWidth = (data?.paycheckPercentage || 0) * 150;
  const currentBarWidth = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    Animated.timing(currentBarWidth, {
      toValue: barWidth,
      duration: CatchTheme.animation.duration,
      delay: CatchTheme.animation.delay,
      useNativeDriver: false,
      easing: CatchTheme.animation.easingCurve,
    }).start();
  }, []);

  return (
    <View style={[styles.sampleBar]}>
      <Animated.View
        style={[
          styles.sampleBarBg,
          {
            width: currentBarWidth.interpolate({
              inputRange: [0, 100],
              outputRange: ['0%', '100%'],
            }),
          },
        ]}
      >
        <Gradient angle={150} gradient={data.bgColor} style={styles.gradient} />
      </Animated.View>
      <View style={styles.sampleBarContent}>
        <Text weight="medium" style={styles.sampleBarText}>
          {name}
          <Text color="subtle" weight="regular">
            {'  '}
            {p(data.paycheckPercentage, { whole: true })}
          </Text>
        </Text>
        <Text weight="medium" style={styles.sampleBarAmount}>
          {$(data.sampleNumber, { whole: true })}
        </Text>
      </View>
    </View>
  );
};

const PayrollSummary = ({ goals: selectedGoals, handleNext }) => {
  const { isMobile } = useResponsive();
  const { $, c: disclaimer } = useCopy('catch.goal.confirm');
  const [ready, setReady] = useState(false);
  const { theme, themeColors } = useTheme();

  const { loading, data } = useQuery<PayrollQueryData>(queries.PAYROLL, {
    fetchPolicy: 'cache-first',
  });

  const { incomeSources } = useDeprecatedQuery('income', {
    variables: {
      currentYear,
      prevFrom: `${currentYear - 1}-01-01`,
      prevTo: `${currentYear - 1}-12-31`,
    },
  });

  const incomeSourceOptions = useMemo(() => {
    if (incomeSources) {
      return incomeSources
        .filter(({ taggedSource }) => taggedSource)
        .map(({ taggedSource }) => taggedSource);
    }
  }, [incomeSources]);

  //match our selectedGoals in state with existing goals from the query
  const goals = useMemo(
    () =>
      data?.viewer?.goals
        ?.filter(
          (goal) =>
            (goal.status !== 'DELETED' && goal.paycheckPercentage > 0) ||
            goal.slug === 'taxes' ||
            !!selectedGoals[goal.slug],
        )
        ?.sort((a, b) => b.paycheckPercentage - a.paycheckPercentage),
    [data?.viewer?.goals, selectedGoals],
  );

  useEffect(() => {
    if (!ready) {
      setReady(!!SAMPLE_PAYCHECK && !!goals);
    }
  }, [ready, goals]);

  const incomeSource = useMemo(
    () => ({
      name: incomeSourceOptions?.[0]?.name || 'Income',
      asset: Asset.configureSource(incomeSourceOptions?.[0]),
    }),
    [incomeSourceOptions],
  );

  return (
    <FlowLayout
      loading={loading}
      narrow
      onNext={handleNext}
      title={disclaimer('disclaimer.title')}
      subtitle={disclaimer('disclaimer.text')}
    >
      <Layout>
        {ready && (
          <Split sticky spacing="2">
            {isMobile ? (
              <ComplexRow
                bg={themeColors.skeletonColor}
                asset={incomeSource?.asset}
                label={`${$(SAMPLE_PAYCHECK)} from ${incomeSource?.name}`}
                sublabel={'Today'}
              />
            ) : (
              <IncomePromptCard
                amount={SAMPLE_PAYCHECK}
                title={incomeSource?.name}
                asset={incomeSource?.asset}
                date={DATE}
              />
            )}

            <View style={theme.fullWidth}>
              <Stack spacing="+gap">
                {goals?.map((goal, idx) => (
                  <GoalBar
                    name={formatBenefit.name(goal)}
                    key={goal.slug || goal.id || idx}
                    data={{
                      ...GoalConfigs[GoalTypeSimplified[goal.type]],
                      paycheckPercentage: goal.paycheckPercentage,
                      sampleNumber: goal.paycheckPercentage * SAMPLE_PAYCHECK,
                    }}
                  />
                ))}
              </Stack>
            </View>
          </Split>
        )}
      </Layout>
    </FlowLayout>
  );
};

const styles = StyleSheet.create({
  sampleBar: {
    height: BAR_HEIGHT,
    width: '100%',
  },
  sampleBarBg: {
    position: 'absolute',
    zIndex: 0,
    top: 0,
    left: 0,
    height: BAR_HEIGHT,
    width: '100%',
    borderRadius: CatchTheme.corners.sm,
    overflow: 'hidden',
    opacity: 0.65,
  },
  sampleBarContent: {
    height: BAR_HEIGHT,
    width: '100%',
    display: 'flex',
    flexWrap: 'nowrap',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 12,
  },
  sampleBarText: {
    zIndex: 1,
    width: 'auto',
  },
  sampleBarAmount: {
    width: 'auto',
  },
  gradient: { width: '100%', height: '100%' },
});

export const PayrollSummaryView = {
  name: routes.PAYROLL_SUMMARY,
  component: PayrollSummary,
  options: {},
};
