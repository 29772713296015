import gql from 'graphql-tag';

import { routes } from '@navigate/routes';
import client from '@client/Apollo';

const memos = [
  'Deposit',
  'Cash App',
  'Doordash',
  'Upwork',
  'GrubHub',
  'Airbnb',
  'Poshmark',
  'Lyft',
  'Payroll',
  'Gusto',
  'Stripe',
  'Instacart',
  'Square',
  'Square Payroll',
  'Vagaro',
  'TriNet',
  'Justworks',
  'PayPal',
  'Venmo',
  'Zelle',
  'Uber',
  'Visa Direct',
  'Grubhub',
  'Etsy',
  'Shipt',
  'TheCut',
  'Intuit',
  'Shopify',
  'Turo',
  'GlossGenius',
  'Walmart',
  'Uber Eats',
  'Hyer',
  'Wethos',
];

const ADD_SAMPLE_PAYCHECKS = gql`
  mutation AddSamplePaycheck($input: CreateSampleIncomeTransactionInput!) {
    CreateSampleIncomeTransaction(input: $input) {
      id
      amount
      memo
      status
    }
  }
`;

const RTS = Object.values(routes);

export const routeMatch = (search) => [...RTS]?.filter((r) => r.includes(search?.toUpperCase()));

export const updateBankLinks = (errorCode) => {
  // If the relinked bank link matches the primary account, we need to update that query
};

export const showBankLinkError = () => {
  // If the relinked bank link matches the primary account, we need to update that query
};

export const makePaychecks = () => {
  memos?.map((m, idx) =>
    setTimeout(() => {
      var date = new Date();
      var isoDateTime = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();

      client.mutate({
        mutation: ADD_SAMPLE_PAYCHECKS,
        variables: {
          input: {
            amount: Math.random() * 1000,
            description:
              m === 'Justworks'
                ? 'Justworks'
                : `MM$32p4u32095 TR43aaa SSIF:${m} somethingelse junk memo`,
            transactionDate: isoDateTime,
          },
        },
      });
    }, 25 * idx),
  );
};
