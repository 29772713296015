import React from 'react';
import { Linking } from 'react-native';

import { Link } from '@uikit';
import { Env, useCopy } from '@app/utils';
import { useAuth } from '@client';
import { SheetBlueprint } from '@app/blueprints';

const PREFIX = 'catch.util.views.CheckSSNView';

/**
 * Familiar user modal
 * @todo use SheetBlueprint
 * This view is a pop up that shows up on the PlanLegalView if we
 * detect that a user is trying to use a SSN that's already in our system
 */
const FamiliarUserModal = ({ close }) => {
  const { c } = useCopy(PREFIX);

  const signOut = useAuth((state) => state.signOut);

  const handleHelp = () => {
    const URL = 'https://help.catch.co/getting-started/why-cant-i-have-multiple-accounts';

    if (Env.isNative) {
      Linking.openURL(URL);
    } else {
      window.open(URL);
    }
  };

  return (
    <SheetBlueprint
      title={c('title')}
      subtitles={[
        c('text1', {
          here: (
            <Link key="link" onPress={signOut} color="link" weight="medium">
              {c('here')}
            </Link>
          ),
        }),
        c('text2'),
      ]}
      actions={[
        { onAction: handleHelp, label: c('help') },
        { onAction: close, label: c('review') },
      ]}
    />
  );
};

export default FamiliarUserModal;
