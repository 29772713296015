import gql from 'graphql-tag';
import { WorkType, FilingStatus } from '@types';

export interface GoalSetupQueryData {
  viewer: {
    id: string;
    user: {
      id: string;
      email?: string;
      phoneNumber?: string;
      nickname?: string;
      givenName?: string;
      familyName?: string;
      jobTitle?: string;
      workType?: WorkType;
      filingStatus?: FilingStatus;
      estimated1099Income?: number;
      estimatedW2Income?: number;
      numTaxDependents?: number;
      workState?: string;
      spouseIncome?: number;
      ssn?: string;
      dob?: string;
      legalAddress?: {
        street1?: string;
        street2?: string;
        city?: string;
        state?: string;
        zip?: string;
      };
    };
  };
}

export const GoalSetupQuery = gql`
  query GoalSetupQuery {
    viewer {
      id
      user {
        id
        email
        phoneNumber
        nickname
        givenName
        familyName
        jobTitle
        workType
        filingStatus
        estimated1099Income
        estimatedW2Income
        numTaxDependents
        workState
        spouseIncome
        ssn
        dob
        legalAddress {
          street1
          street2
          city
          state
          zip
        }
      }
    }
  }
`;
