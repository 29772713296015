import { Easing, Platform } from 'react-native';
import { FontSize } from '@types';
import { position } from './spacing';
import { theme } from '@app/_ui-kit/themes/catch';
import { colors } from './colors';

export const fontFallback =
  '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif';

export const nativeFonts = {
  Inter: {
    300: 'Inter-Light',
    400: 'Inter-Regular',
    500: 'Inter-Medium',
    600: 'Inter-SemiBold',
    700: 'Inter-Bold',
  },
  InterDisplay: {
    300: 'InterDisplay-Light',
    400: 'InterDisplay-Regular',
    500: 'InterDisplay-Medium',
    600: 'InterDisplay-SemiBold',
    700: 'InterDisplay-Bold',
  },
  default: {
    300: 'Inter-Light',
    400: 'Inter-Regular',
    500: 'Inter-Medium',
    600: 'Inter-SemiBold',
    700: 'Inter-Bold',

    HW: 'Caveat-Regular',
  },
};

export const fontWeightMap = {
  300: 300,
  400: 400,
  500: 500,
  600: 600,
  700: 700,
  light: 300,
  regular: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
};

export const space = {
  standard: position.get(2),
};

/** @deprecated */
export const sheets = {
  default: theme.containers.formSheet,
  form: theme.containers.formSheet,
  formSheet: theme.containers.formSheet,
  page: theme.containers.pageSheet,
  pageSheet: theme.containers.pageSheet,
  action: theme.containers.formSheet,
  large: theme.containers.largeSheet,
  dialog: theme.containers.dialog,
};

/** @deprecated */
export const animation = {
  curve: theme.animation.curve,
  bounce: theme.animation.bounce,
  durationShort: theme.animation.durationShort,
  durationMedium: theme.animation.duration,
  durationLong: theme.animation.durationLong,
};

/** @deprecated */
export const easing = Easing.bezier(0.23, 1, 0.32, 1);

/** @deprecated */
export const effects = {
  activeOpacity: 0.65,
  modalBlur: theme.constants.modalBlur,
};

/** @deprecated */
export const assets = {
  mini: theme.assets.mini,
  glyph: theme.assets.glyph,
  xs: theme.assets.xs,
  sm: theme.assets.sm,
  md: theme.assets.md,
  lg: theme.assets.lg,
  xl: theme.assets.xl,
  plus: theme.assets.xxl,
  jumbo: theme.assets.xxl,
};

/** @deprecated */
export const typeSizeSmall = {
  h0: theme.font?.size.XL,
  h1: theme.font?.size.PageTitle,
  h2: theme.font?.size.FormTitle,
  h3: theme.font?.size.BlockTitle,
  h4: theme.font?.size.Heading,
  btn: theme.font?.size.Button,
  p: theme.font?.size.Body,
  fp: theme.font?.size.Small,
  l: theme.font?.size.Small,
  sm: theme.font?.size.Caption,
  xs: theme.font?.size.SmallCaps,
  xl: theme.font?.size.XL,
  h2s: theme.font?.size.FormTitle,
  h5: theme.font?.size.Button,
  h6: theme.font?.size.Caption,
  lg: theme.font?.size.Button,
};

/** @deprecated */
export const typeLineHeight: Record<FontSize, number> = {
  h0: 60,
  h1: 38,
  h2: 30,
  h2s: 33,
  h3: 28,
  h4: 26,
  h5: 26,
  h6: 16,
  btn: 24,
  p: 24,
  fp: 20,
  l: 18,
  lg: 26,
  xl: 60,
  sm: 18,
  xs: 15,
};

/** @deprecated */
export const mapSizes = {
  small: 64,
  default: 136,
};

/** @deprecated */
export const content = {
  page: theme.containers.page,
  card: theme.containers.dialog,
  buttonHeight: theme.constants.buttonHeight,
};

const S_COLOR = colors.ink;
const S_OPACITY = 0.12;
const S_OPACITY_HOVER = 0.2;
const S_RADIUS = 21;

const S_RADIUS_HOVER = 16;
const S_OFFSET = { width: 0, height: 3 };

const S_OFFSET_HOVER = { width: 0, height: 3 };

const S_RADIUS_NAV = 2;
const S_OFFSET_NAV = { width: 0, height: 0 };

export const shadows = {
  button: {
    shadowColor: '#17066D',
    shadowOpacity: 0.03,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowRadius: 6,
    elevation: 1,
  },

  card: Platform.select({
    web: {
      shadowColor: S_COLOR,
      shadowOpacity: S_OPACITY / 2,
      shadowOffset: S_OFFSET,
      shadowRadius: S_RADIUS,
    },
    ios: {
      shadowColor: S_COLOR,
      shadowOpacity: S_OPACITY / 2,
      shadowOffset: S_OFFSET,
      shadowRadius: S_RADIUS,
    },
    android: {
      //note: shadow behaves strangely with transparent bg in dark mode, probably want a more elegant way to handle this
      elevation: 3, // fixes card shadow when there are transparent bgs
    },
  }),

  cardHover: Platform.select({
    web: {
      shadowColor: S_COLOR,
      shadowOpacity: S_OPACITY_HOVER,
      shadowOffset: S_OFFSET_HOVER,
      shadowRadius: S_RADIUS_HOVER,
    },
    ios: {
      shadowColor: S_COLOR,
      shadowOpacity: S_OPACITY_HOVER / 2,
      shadowOffset: S_OFFSET_HOVER,
      shadowRadius: S_RADIUS_HOVER,
    },
    android: {
      elevation: 1,
    },
  }),
  major: Platform.select({
    web: {
      shadowColor: S_COLOR,
      shadowOpacity: S_OPACITY,
      shadowOffset: S_OFFSET,
      shadowRadius: S_RADIUS,
    },
    ios: {
      shadowColor: S_COLOR,
      shadowOpacity: S_OPACITY / 2,
      shadowOffset: S_OFFSET,
      shadowRadius: S_RADIUS,
    },
    android: {
      elevation: 2,
    },
  }),
  nav: {
    shadowOpacity: S_OPACITY,
    shadowOffset: S_OFFSET_NAV,
    shadowRadius: S_RADIUS_NAV,
  },
  deep: Platform.select({
    web: {
      shadowColor: colors.ink,
      shadowOpacity: 0.1,
      shadowOffset: { width: 0, height: 10 },
      shadowRadius: 25,
    },
    ios: {
      shadowColor: colors.ink,
      shadowOpacity: 0.1,
      shadowOffset: { width: 0, height: 10 },
      shadowRadius: 25,
    },
    android: {
      elevation: 3,
    },
  }),
  screen: Platform.select({
    web: {
      shadowColor: colors.ink,
      shadowOpacity: 0.15,
      shadowOffset: { width: 0, height: 10 },
      shadowRadius: 500,
    },
    ios: {
      shadowColor: colors.ink,
      shadowOpacity: 1,
      shadowOffset: { width: 0, height: 0 },
      shadowRadius: 200,
    },
    android: {
      elevation: 3,
    },
  }),
};
