import React from 'react';

import { Link, Bullet } from '@uikit';
import { useCopy } from '@app/utils';
import { Stack } from '@app/layouts';

export const IRA_DOLLAR_LIMIT = 6000;

const HELP_LINKS = {
  IRA: 'http://help.catch.co/building-for-retirement/am-i-eligible-for-a-traditional-ira',
  ROTH_IRA: 'https://help.catch.co/building-for-retirement/am-i-eligible-for-a-roth-ira',
  SEP: 'https://www.irs.gov/pub/irs-pdf/f5305sep.pdf',
};

const HELP_ARTICLE = (accountType) => (
  <Link href={HELP_LINKS[accountType]} target="_blank" inline>
    help article
  </Link>
);

const SEP_FORM = () => (
  <Link href={HELP_LINKS['SEP']} target="_blank" inline>
    IRS Form 5305-SEP
  </Link>
);

const MGAI_LINK = (
  <Link
    href="https://help.catch.co/building-for-retirement/what-is-modified-adjusted-gross-income"
    target="_blank"
    inline
  >
    Modified Gross Adjusted Income
  </Link>
);

const PREFIX = 'catch.module.retirement.AccountSelectionView';

const bulletProps = {
  bulletSize: 'sm',
  textSize: 'fp',
};

/** @todo ...make this data driven? */
const RothLimits = () => {
  const { c } = useCopy(PREFIX);
  return (
    <Stack spacing="1">
      <Bullet {...bulletProps} text={c('roth-ira.limit1')} />
      <Bullet {...bulletProps} text={c('roth-ira.limit2')} />
      <Bullet {...bulletProps} text={c('roth-ira.limit3')} />
      <Bullet {...bulletProps} text={c('roth-ira.limit4')} />
      <Bullet {...bulletProps} text={c('roth-ira.limit5', { link: MGAI_LINK })} />
      <Bullet {...bulletProps} text={c('roth-ira.limit6', { link: MGAI_LINK })} />
      <Bullet {...bulletProps} text={c('roth-ira.limit7')} />
      <Bullet {...bulletProps} text={c('roth-ira.limit8', { link: HELP_ARTICLE('ROTH_IRA') })} />
    </Stack>
  );
};

const TraditionalLimits = () => {
  const { c } = useCopy(PREFIX);
  return (
    <Stack spacing="1">
      <Bullet {...bulletProps} text={c('ira.limit1')} />
      <Bullet {...bulletProps} text={c('ira.limit2')} />
      <Bullet {...bulletProps} text={c('ira.limit3')} />
      <Bullet {...bulletProps} text={c('ira.limit4')} />
      <Bullet {...bulletProps} text={c('ira.limit5')} />
      <Bullet {...bulletProps} text={c('ira.limit6', { link: HELP_ARTICLE('IRA') })} />
    </Stack>
  );
};

const SepLimits = () => {
  const { c } = useCopy(PREFIX);
  return (
    <Stack spacing="1">
      <Bullet {...bulletProps} text={c('sep-ira.limit1')} />
      <Bullet {...bulletProps} text={c('sep-ira.limit2')} />
      <Bullet
        {...bulletProps}
        text={c('sep-ira.limit3', {
          link: SEP_FORM(),
        })}
      />
      <Bullet {...bulletProps} text={c('sep-ira.limit4')} />
      <Bullet {...bulletProps} text={c('sep-ira.limit5')} />
    </Stack>
  );
};

const MAP = {
  SEP_IRA: SepLimits,
  ROTH_IRA: RothLimits,
  IRA: TraditionalLimits,
};

const AccountLimits = ({ selectedAccountType }) => {
  const Component = MAP[selectedAccountType];
  return <Component />;
};

export default AccountLimits;
