import React from 'react';
import { default as BaseWrapper } from '../wrappers/BaseWrapper';
import { default as Input } from '../components/Input';
import { KeyboardType, KeyHandlers } from '../utils/keyboard';

interface TextInputProps {
  name: string;
  label: string;
  placeholder: string;
  value: string;
  onChange: (val: string) => void;
  onBlur?: () => void;
  error?: string;
  secure?: boolean;
  required?: boolean;
  optional?: boolean;
  disabled?: boolean;
  loading?: boolean;
  autoFocus?: boolean;
  fullWidth?: boolean;
  keyboard?: KeyboardType;
  keyMap?: KeyHandlers;
}

const TextInput: React.FC<TextInputProps> = ({
  name,
  value,
  onChange,
  onBlur,
  error,
  label,
  placeholder,
  required,
  optional,
  secure,
  disabled,
  loading,
  autoFocus,
  fullWidth,
  keyboard,
  keyMap,
}) => {
  return (
    <BaseWrapper
      fieldName={name}
      label={label}
      required={required}
      optional={optional}
      error={error}
    >
      <Input
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        error={!!error}
        disabled={!!disabled}
        loading={!!loading}
        autoFocus={autoFocus}
        fullWidth={fullWidth}
        secure={secure}
        keyboard={keyboard}
        keyMap={keyMap}
      />
    </BaseWrapper>
  );
};

export default TextInput;
