// the minimum balance for tax goal to suggest a tax payment
export const TAX_PAYMENT_PROMPT_MIN = 10;

//default goal contribution
export const DEFAULT_GOAL_CONTRIBUTION = {
  timeoff: 0.01,
  'family-leave': 0.01,
  'health-expenses': 0.01,
  'emergency-savings': 0.01,
  retirement: 0.05,
};

export const MIN_GOAL_CONTRIBUTION = 0.01;

export const MIN_AGE_SEP_IRA = 21; //min age to open SEP IRA is 21
