type Sorter = 'default' | 'percent' | 'balance' | 'status';

const sorters: Record<Sorter, (a: any, b: any) => any> = {
  default: (a, b) => weight[b.type] || 0 - weight[a.type] || 0,
  percent: (a, b) => b.paycheckPercentage - a.paycheckPercentage,
  balance: (a, b) => b.availableBalance - a.availableBalance,
  status: (a, b) => statusWeight[b.status] - statusWeight[a.status] || 0,
};

// @todo SORT CHAIN
export const sortGoals = (goals: Array<any>, sorter?: Sorter) => {
  const _goals = goals || [];

  // incremental sort; last sorter is final
  if (!sorter || sorter === 'default') {
    _goals
      ?.sort(sorters.balance)
      ?.sort(sorters.percent)
      ?.sort(sorters.status)
      ?.sort(sorters.default);
  }

  return _goals?.sort(sorters[sorter || 'default']);
};

const weight = {
  TAX: 10,
  RETIREMENT: 9,
};

const statusWeight = {
  DELETED: 0,
  NOT_STARTED: 1,
  DRAFT: 2,
  GOAL_MET: 3,
  PAUSED: 4,
  ACTIVE: 5,
};
