import React from 'react';

import { useCopy } from '@app/utils';
import { Bullet, Link, Text, Accordion, useTheme } from '@uikit';
import { Stack } from '@app/layouts';

const PREFIX = 'catch.ede.EligibilityResults';

const WrongEligibilityDetermination = () => {
  const { c } = useCopy(PREFIX);
  const { theme } = useTheme();

  return (
    <Accordion
      title={c('whatIfWrong')}
      small
      style={[theme.skeletonBg, theme.mdCorners, theme.xSpace2]}
    >
      <Stack spacing="1">
        <Text fluid>{c('wrong.p1')}</Text>
        <Text fluid>{c('wrong.p2')}</Text>
        <Text fluid>{c('wrong.p3')}</Text>
        <Text fluid>{c('wrong.p4')}</Text>
        <Bullet text={c('wrong.bullet1')} />
        <Bullet text={c('wrong.bullet2')} />
        <Bullet text={c('wrong.bullet3')} />
        <Bullet text={c('wrong.bullet4')} />
        <Link href="https://healthcare.gov/marketplace-appeals/">{c('learnMoreAppeal')}</Link>
        <Text fluid>{c('mailingInstructions')}</Text>
      </Stack>
    </Accordion>
  );
};

export default WrongEligibilityDetermination;
