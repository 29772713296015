import gql from 'graphql-tag';
import {
  EligibilityChange,
  HealthApplicationStatus,
  IncomeFrequencyType,
  IncomeSourceType,
  PolicyStatusType,
} from '@types';

interface EligibilityPreview {
  totalAptc?: number;
  csrLevel?: number;
  isMedicaid?: boolean;
  isChip?: boolean;
}

interface MemberEligibilityPreview {
  startDate?: any;
  endDate?: any;
  amount?: number;
  change?: EligibilityChange;
}

export interface MemberPreview {
  id: string;
  maritalStatus: 'SINGLE' | 'MARRIED';
  relation: 'SELF' | 'SPOUSE' | 'CHILD';
  givenName: string;
  middleName: string;
  familyName: string;
  aptcEligibility: MemberEligibilityPreview;
  csrEligibility: MemberEligibilityPreview;
  annualTaxIncome?: {
    incomeAmount: number;
  };
  incomeSources?: Array<{
    incomeID?: string;
    type?: IncomeSourceType;
    incomeAmount?: number;
    incomeFrequencyType?: IncomeFrequencyType;
    employerName?: string;
    employerPhoneNumber?: string;
    averageWeeklyWorkHours?: number;
    averageWeeklyWorkDays?: number;
    selfEmploymentIncomeDescription?: string;
    incomeDescription?: string;
  }>;
  metalLevelOption?: {
    allowedMetalLevels: any;
    currentMetalLevel: any;
    currentPlanOnlyIndicator: boolean;
  };
}

export const MemberPreviewFragment = gql`
  fragment MemberPreviewFragment on ApplicationMember {
    id
    relation
    maritalStatus
    givenName
    middleName
    familyName
    annualTaxIncome {
      incomeAmount
      isUnknownIncome
      isVariableIncome
      attestedAnnualizedAPTCIndividualIncomeAmount
      hasReceivedUnemploymentCompensation
    }
    incomeSources {
      incomeID
      type
      incomeAmount
      incomeFrequencyType
      employerName
      employerPhoneNumber
      averageWeeklyWorkHours
      averageWeeklyWorkDays
      selfEmploymentIncomeDescription
      incomeDescription
    }
    metalLevelOption {
      allowedMetalLevels
      currentMetalLevel
      currentPlanOnlyIndicator
    }
    aptcEligibility {
      startDate
      endDate
      amount
      change
    }
    csrEligibility {
      startDate
      endDate
      csrLevel
      change
    }
  }
`;

export const ApplicationFragment = gql`
  fragment SlashApplicationFragment on HealthApplication {
    id
    coverageState
    coverageYearNumber
    applicationContext
    status
    lastUserUpdate
    lastUsedRoute
    householdIncome
    numTaxDependents
    maritalStatus
    isRequestingFinancialAssistance
    applicant {
      id
      ...MemberPreviewFragment
    }
    members {
      id
      ...MemberPreviewFragment
    }
    enrollment {
      id
    }
    policies {
      id
      policyStatus
    }
    aptcHouseholdAmount
    previousAptcHouseholdAmount

    eligibility {
      totalAptc
      csrLevel
      isMedicaid
      isChip
    }

    parent {
      id
      aptcHouseholdAmount
      eligibility {
        totalAptc
        csrLevel
        isMedicaid
        isChip
      }
    }
  }
`;

export interface SlasherApplicationSummary {
  id: string;
  status: HealthApplicationStatus;
  coverageYearNumber: number;
  coverageState: string;
  lastUsedRoute: string;
  numTaxDependents: number;
  maritalStatus: string;
  householdIncome: number;
  applicant: MemberPreview;
  members: Array<MemberPreview>;
  isRequestingFinancialAssistance: boolean;
  enrollment: {
    id: string;
  };
  policies: Array<{
    id: string;
    policyStatus: PolicyStatusType;
  }>;
  aptcHouseholdAmount?: number;
  previousAptcHouseholdAmount?: number;
}

export interface SlasherSubmittedApplicationSummary extends SlasherApplicationSummary {
  enrollmentGroups: Array<{
    id: string;
    planPremium: number;
    planPremiumEffective: number;
    appliedAPTCAmount: number;
    previousAppliedAPTCAmount: number;
    enrollmentType: 'HEALTH_INSURANCE' | 'DENTAL_INSURANCE';
    plan: {
      planID: string;
      name: string;
      metalLevel: string;
      issuer: {
        id: string;
        name: string;
      };
    };
  }>;
}

export interface SlasherPolicySummary {
  id: string;
  coverageYear?: number;
  planName: string;
  carrier: string;
  premium: number;
  premiumEffective: number;
  policyStatus: PolicyStatusType;
  enrollmentID: string;
  productType: 'PLANTYPE_DENTAL' | 'PLANTYPE_HEALTH';
}

export interface HealthSlasherQueryData {
  viewerTwo: {
    id: string;
    health: {
      applications: Array<SlasherApplicationSummary>;
      prefills: Array<SlasherApplicationSummary>;
      submitted: Array<SlasherSubmittedApplicationSummary>;
      policies: Array<SlasherPolicySummary>;
    };
    healthExplorerData?: {
      id: string;
      income?: number;
    };
  };
}

export const HealthSlasherQuery = gql`
  query HealthSlasherQuery($coverageYears: [Int!]) {
    viewerTwo {
      id
      health {
        applications(
          input: { coverageYearNumber: $coverageYears, statuses: [ENROLLED, SUBMITTED] }
        ) {
          id
          ...SlashApplicationFragment
        }
        prefills: applications(
          input: { coverageYearNumber: $coverageYears, statuses: [IN_PROGRESS] }
        ) {
          id
          ...SlashApplicationFragment
        }
        submitted: applications(
          input: { coverageYearNumber: $coverageYears, statuses: [SUBMITTED] }
        ) {
          id
          ...SlashApplicationFragment
          enrollmentGroups {
            id
            planPremium
            planPremiumEffective
            appliedAPTCAmount
            previousAppliedAPTCAmount
            enrollmentType
            plan {
              planID: id
              name
              metalLevel
              issuer {
                id
                name
              }
            }
          }
        }
        policies {
          id
          endDate
          coverageYear @client
          planName
          carrier
          premium
          premiumEffective
          policyStatus
          enrollmentID
          productType
          providerPlan {
            id
            metalLevel
            issuer {
              id
              name
            }
          }
        }
      }
      healthExplorerData {
        id
        income
      }
    }
  }
  ${ApplicationFragment}
  ${MemberPreviewFragment}
`;
