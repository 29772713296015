import gql from 'graphql-tag';

type BankLinkErrorType = 'NO_ACCOUNTS_FOUND' | 'LOCKED_POSSIBLE_FRAUD';

export interface AddBankLinkItemVars {
  input: {
    publicToken: string;
    watchAll?: boolean;
    forceBankLinkID?: string;
  };
}

export interface AddBankLinkItemResponse {
  addBankLinkItem: {
    bankLink: {
      id: string;
    };
    errors?: Array<BankLinkErrorType>;
  };
}

export const AddBankLinkItem = gql`
  mutation AddBankLinkItem($input: AddItemInput!) {
    addBankLinkItem(input: $input) {
      bankLink {
        id
        providerType
      }
      errors
    }
  }
`;
