import React from 'react';
import { View } from 'react-native';
import { AssetSize } from '@types';
import { Lottie } from './lottie';

import { theme as catchTheme } from '../../themes/catch';
import { useTheme } from '../../hooks/useTheme';
import { UIAccentColor } from '../../types';

import Cerise from './resources/cerise-loader.json';
import Orange from './resources/orange-loader.json';
import Lime from './resources/lime-loader.json';
import Red from './resources/red-loader.json';
import Teal from './resources/teal-loader.json';
import White from './resources/white-loader.json';

import CeriseSuccess from './resources/cerise-success.json';
import OrangeSuccess from './resources/orange-success.json';
import LimeSuccess from './resources/lime-success.json';
import RedSuccess from './resources/red-success.json';
import TealSuccess from './resources/teal-success.json';

import Fail from '@svg/close.svg';
import { Asset, IconSvg } from '../Asset';

const colors: Record<UIAccentColor, any> = {
  brand: Cerise,
  income: Cerise,
  taxes: Orange,
  saved: Lime,
  retirement: Teal,
  coverage: Red,
  white: White, // doesn't fit accent colors...
};

const colorsDone: Record<UIAccentColor, any> = {
  brand: CeriseSuccess,
  income: CeriseSuccess,
  taxes: OrangeSuccess,
  saved: LimeSuccess,
  retirement: TealSuccess,
  coverage: RedSuccess,
};

interface SpinnerProps {
  color?: UIAccentColor;
  size?: AssetSize;
  large?: boolean;
  done?: boolean;
  error?: boolean;
}

export const Spinner: React.FC<SpinnerProps> = ({
  color = 'brand',
  size = 'sm',
  large,
  done /** @deprecated */,
  error,
}) => {
  const { theme, themeColors } = useTheme();
  const _size = catchTheme.assets[large ? 'md' : size];

  return (
    <View
      style={[
        theme.centerSelf,
        theme.alignCenter,
        { width: _size, height: _size, justifyContent: 'center', overflow: 'hidden' },
      ]}
    >
      {error ? (
        <Asset
          svg={Fail}
          size="sm"
          containerSize="md"
          shape="circle"
          color="negative"
          bg="negativeLight"
        />
      ) : (
        <Lottie siz e={_size} animation={done ? colorsDone[color] : colors[color]} repeat={!done} />
      )}
    </View>
  );
};

export default Spinner;
