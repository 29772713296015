import React from 'react';
import { stacks, Stack, StackComponent, StackDefinition } from '@navigate';
import { benefitColorLookup } from '@app/utils/color';
import { TransferAmountView } from './TransferAmountView';
import { TransferConfirmView } from './TransferConfirmView';

const config: StackDefinition = {
  stackName: stacks.TRANSFER_STACK,
  options: {
    layout: 'sheet',
    accentColor: ({ goal }) => benefitColorLookup(goal),
  },
  screens: [TransferAmountView, TransferConfirmView], // @todo E-3445 typescript
};

const TransferStack: StackComponent = () => {
  return <Stack stackName={config.stackName} screens={config.screens} options={config.options} />;
};

TransferStack.config = config;
export default TransferStack;
