import gql from 'graphql-tag';
import { IncomeTransactionSource, IncomeWorkType, AutomationType } from '@types';

export interface IncomeAutomationRuleQueryData {
  viewer: {
    id: string;
    incomeAutomationRule: {
      id: string;
      automationType?: AutomationType;
      workType?: IncomeWorkType;
      source: IncomeTransactionSource;
    };
  };
}

export interface IncomeAutomationRuleQueryVars {
  sourceID: string;
}

export const IncomeAutomationRuleQuery = gql`
  query IncomeAutomationRule($sourceID: ID!) {
    viewer {
      id
      incomeAutomationRule(id: $sourceID) {
        id
        automationType
        workType
        source {
          id
          ruleID
          text
          sourceDetail
          canBeRule
          incomeAutomationRule {
            id
            automationType
            workType
          }
          taggedSource {
            id
            name
            logo {
              url
            }
          }
        }
      }
    }
  }
`;
