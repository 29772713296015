import { GoalType } from '@app/types';
import gql from 'graphql-tag';

export interface TransferQueryData {
  viewer: {
    id: string;
    user: {
      id: string;
      payrollProductTier: 'TIER_0' | 'TIER_1' | 'TIER_2' | 'TIER_3';
      dob?: Date;
      age?: number;
    };
    bankLinks: Array<{
      id: string;
      logo: string;
      bankName: string;
      accounts: Array<{
        id: string;
        name: string;
        accountNumber: string;
      }>;
    }>;
    goals: Array<{
      id: string;
      slug: string;
      name: string;
      goalType: GoalType;
      availableBalance: number;
      pendingBalance: number;
      totalBalance: number;
      totalContributions?: number;
    }>;
  };
}

export const TransferQuery = gql`
  query TransferQuery {
    viewer {
      id
      user {
        id
        payrollProductTier
        dob
        age @client
      }
      bankLinks(input: { includeNotWatched: true }) {
        id
        providerType
        logo
        bankName
        accounts {
          id
          name
          accountNumber
        }
      }
      goals {
        id
        slug
        name
        goalType
        availableBalance
        pendingBalance
        totalBalance
        ... on RetirementGoal {
          totalContributions
        }
      }
    }
  }
`;
