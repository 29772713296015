import gql from 'graphql-tag';
import access from 'safe-access';

import { isWorkDetailsFilled } from '@app/forms_old/validators';
import { getTotalIncome, getHouseholdIncome, slugToGoalType } from '@app/utils';

import { GOAL } from '../fragments';

const GOAL_SETUP = gql`
  query GoalSetup($slug: String) {
    viewerTwo {
      id
      agreements {
        id
        acknowledgedUnitAccountDisclosures
      }
    }
    viewer {
      id
      goal(slug: $slug) {
        id
        slug
        ...Goal
      }
      bankLinks {
        id
        providerType
        accounts {
          id
          accountNumber
          isWatched
        }
      }
      user {
        id
        givenName
        familyName
        email
        dob
        workType
        filingStatus

        isControlPerson
        isFirmAffiliated
        isPoliticallyExposed
        kycSavings {
          status
          needed
          savingsProviderType
        }
      }
      incomeState
      income {
        estimatedW2Income
        estimated1099Income
      }
      spouseIncome
    }
  }
  ${GOAL}
`;

const formatter = (data, { variables }) => {
  const get = access(data);

  const goal = get('viewer.goal');
  const legalName = `${get('viewer.user.givenName')} ${get('viewer.user.familyName')}`;
  const estimatedW2Income = get('viewer.income.estimatedW2Income');
  const estimated1099Income = get('viewer.income.estimated1099Income');
  const workType = get('viewer.user.workType');
  const spouseIncome = get('viewer.spouseIncome');
  const filingStatus = get('viewer.user.filingStatus');
  const goalType = get('viewer.goal.goalType') || slugToGoalType(variables.slug);
  const bankLinks = get('viewer.bankLinks');
  const kycStatus = get('viewer.user.kycSavings.status');
  const kycNeeded = get('viewer.user.kycSavings.needed');
  const bankProvider = get('viewer.user.kycSavings.savingsProviderType');
  const isControlPerson = get('viewer.user.isControlPerson');
  const isFirmAffiliated = get('viewer.user.isFirmAffiliated');
  const isPoliticallyExposed = get('viewer.user.isPoliticallyExposed');
  const externalSavings = get('viewer.goal.externalSavings');
  const hasFolioSignature = get('viewer.goal.hasUploadedSignature');
  const acknowledgedUnitAccountDisclosures = get(
    'viewerTwo.agreements.acknowledgedUnitAccountDisclosures',
  );

  // computed total income
  const estimatedIncome = getTotalIncome({
    estimatedW2Income,
    estimated1099Income,
    workType,
  });

  const householdIncome = getHouseholdIncome({
    estimatedIncome,
    spouseIncome: get('spouseIncome'),
    filingStatus: get('user.filingStatus'),
  });

  // must have answered false to all regulatory screeners
  // only show regulatory questions when setting up retirement
  const regulatoryEligible =
    [isControlPerson, isFirmAffiliated, isPoliticallyExposed].every((val) => val === false) ||
    goalType !== 'RETIREMENT';

  const kycSavings = {
    status: kycStatus,
    needed: kycNeeded,
    providerType: bankProvider,
  };

  return {
    hasStartedGoal: !!goal && !/NOT_STARTED|DELETED/.test(goal.status),
    goal,
    goalType,
    legalName,
    isWorkDetailsFilled: isWorkDetailsFilled(data),
    dob: get('viewer.user.dob'),
    incomeState: get('viewer.incomeState'),
    workType,
    filingStatus,
    estimatedW2Income,
    estimated1099Income,
    spouseIncome,
    estimatedIncome,
    householdIncome,
    needsBankLink: !bankLinks || bankLinks?.length === 0,
    kycStatus,
    kycNeeded,
    kycSavings,
    isControlPerson,
    isFirmAffiliated,
    isPoliticallyExposed,
    regulatoryEligible,
    isW2: workType === 'WORK_TYPE_W2',
    needsExternalSavings: goalType === 'RETIREMENT' && !externalSavings,
    needsInvestmentAgreement: goalType === 'RETIREMENT' && !hasFolioSignature,
    needsCustomDetails: goalType === 'CUSTOM_SAVINGS',
    acknowledgedAccountDisclosures: acknowledgedUnitAccountDisclosures,
    bankProvider,
    needsSavingsAgreement: !acknowledgedUnitAccountDisclosures,
  };
};

export default {
  document: GOAL_SETUP,
  formatter,
};
