import React from 'react';
import { stacks, Stack, StackComponent, StackDefinition } from '@navigate';
import { ManageIncomeSourcesView } from './sources/ManageIncomeSourcesView';
import { IncomeSourceDetail } from './sources/IncomeSourceDetailView';

const config: StackDefinition = {
  stackName: stacks.INCOME_SOURCES_STACK,
  options: { layout: 'sheet' },
  screens: [ManageIncomeSourcesView, IncomeSourceDetail], // @todo E-3445 typescript
};

const IncomeSourcesStack: StackComponent = () => {
  return <Stack stackName={config.stackName} screens={config.screens} options={config.options} />;
};

IncomeSourcesStack.config = config;
export default IncomeSourcesStack;
