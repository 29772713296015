import React from 'react';
import { KeyboardAvoidingView } from 'react-native';
import { Copy } from '@types';
import { Loading, Asset } from '@uikit';
import { Render } from '@uikit/components/Asset';
import { Layout } from './Layout';
import { Narrow } from './GridPreset';
import { ToolbarProps } from './Toolbar';
import Page from './Page';

interface CalloutLayoutProps {
  showLoaderOnly?: boolean; // hides text until loading completes
  loading: boolean;
  size?: 'page' | 'form';
  title: Copy;
  subtitles?: Array<Copy>;
  render: any;
  toolbar?: React.ReactElement<ToolbarProps>;
}

const CalloutLayout: React.FC<CalloutLayoutProps> = ({
  showLoaderOnly,
  loading,
  size = 'form',
  title,
  subtitles,
  render,
  toolbar,
  children,
}) => {
  const hideTitle = showLoaderOnly && loading;

  return (
    <Page>
      <Layout.Scroll fullWidth bottomSpace topSpace>
        <Layout topSpace>
          <Narrow>
            <Layout center bottomSpace>
              {size === 'page' ? (
                <Asset
                  render={render}
                  size="xxl"
                  shape="circle"
                  bg="coverageLight"
                  containerSize="jumbo"
                />
              ) : (
                <Render blend={true} size="humongous" name={render} />
              )}
            </Layout>
            {!hideTitle && (
              <Layout.Header
                topSpace
                titleSize={size}
                title={title}
                subtitles={subtitles}
                center
                align="center"
                bottomSpace
              />
            )}
            {loading ? (
              <Loading />
            ) : (
              <KeyboardAvoidingView behavior="position" keyboardVerticalOffset={100}>
                {children}
              </KeyboardAvoidingView>
            )}
          </Narrow>
        </Layout>
      </Layout.Scroll>
      {React.cloneElement(toolbar, { type: 'stack' })}
    </Page>
  );
};

export default CalloutLayout;
