import React from 'react';
import { Form, Field } from 'react-final-form';
import { useCopy } from '@app/utils';
import { mutations, useMutation } from '@data';
import { Loading, Modal, Button, Text } from '@uikit';
import { Toolbar, Layout } from '@layouts';
import { TextField } from '../../forms_old/inputs';

/** @deprecated use sheetblueprint */
const EligibilitySummaryActionSheet = ({
  isOpen,
  onRequestClose,
  monthlySavings,
  applicationID,
}) => {
  const { c } = useCopy('catch.ede.EligibilityResults');
  const { c: basics } = useCopy('catch.basics');

  const [upsert, { loading }] = useMutation(mutations.UPSERT_HEALTH_APPLICATION, {
    onCompleted: () => onRequestClose(),
  });

  const onSubmit = (values) => {
    upsert({
      variables: {
        input: {
          id: applicationID,
          aptcHouseholdToApply: values?.monthlySavings,
        },
      },
    });
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} close>
      {loading ? (
        <Loading accentColor="coverage" />
      ) : (
        <Form onSubmit={onSubmit} initialValues={{ monthlySavings }}>
          {({ handleSubmit, values }) => (
            <>
              <Layout.Scroll margins>
                <Text size="h4">{c('monthlySavingsTitle')}</Text>
                <Text gutter="bottom">{c('monthlySavingsCTA')}</Text>
                <Field
                  component={TextField}
                  name="monthlySavings"
                  label={c('monthlySavingsFieldLabel')}
                  initialValue={monthlySavings}
                  short
                />
              </Layout.Scroll>
              <Toolbar>
                <Button alt onPress={onRequestClose}>
                  {basics('cancel')}
                </Button>
                <Button onPress={handleSubmit}>{basics('save')}</Button>
              </Toolbar>
            </>
          )}
        </Form>
      )}
    </Modal>
  );
};

export default EligibilitySummaryActionSheet;
