import gql from 'graphql-tag';
import { STATES } from '@app/utils';

const INSURANCE_ENROLLMENT = gql`
  query InsuranceEnrollment($enrollId: ID!) {
    viewer {
      id
      health {
        enrollment(id: $enrollId) {
          id
          ... on HealthInsuranceEnrollment {
            id
            pathwayType
            status
            isOptOutRenewal
            hasAssociatedDentalPlan
            enrollmentType
            policy {
              id
              carrier
              planName
              endDate
              providerPlan {
                id
                name
                issuer {
                  id
                  name
                }
              }
            }
            members {
              id
              relation
              givenName
              familyName
              maritalStatus
            }
            application {
              id
              numTaxDependents
              coverageYearNumber
              coverageState
              applicant {
                id
                ssn
                givenName
                familyName
                maritalStatus
                homeAddress {
                  street1
                  street2
                  state
                  city
                  zip
                  zip4
                  coordinates {
                    longitude
                    latitude
                  }
                }
                mailingAddress {
                  street1
                  street2
                  state
                  city
                  zip
                  zip4
                  coordinates {
                    longitude
                    latitude
                  }
                }
                annualTaxIncome {
                  incomeAmount
                }
              }
            }
          }
        }
      }
    }
  }
`;

const formatter = (data) => {
  const members = (data?.viewer?.health?.enrollment?.members || []).map((m) => ({
    ...m,
    name: `${m.givenName} ${m.familyName}`,
  }));
  const enrollment = data?.viewer?.health?.enrollment;
  const application = enrollment?.application;
  const policy = enrollment?.policy || {};
  const applicant = application?.applicant;
  const applicantName = `${applicant?.givenName} ${applicant?.familyName}`;
  const hasSSN = !!applicant?.ssn;

  return {
    applicantID: applicant?.id,
    enrollment,
    enrollmentID: enrollment?.id,
    applicationID: application?.id,
    applicantName,
    givenName: applicant?.givenName,
    familyName: applicant?.familyName,
    homeAddress: applicant?.homeAddress,
    mailingAddress: applicant?.mailingAddress,
    numTaxDependents: application?.numTaxDependents,
    maritalStatus: applicant?.maritalStatus,
    householdIncome: applicant?.annualTaxIncome?.incomeAmount,
    coverageYear: application
      ? application?.coverageYearNumber || new Date().getFullYear()
      : undefined,
    coverageState: application?.coverageState,
    tenant: STATES[application?.coverageState],
    members,
    policy,
    hasSSN,
    pathwayType: enrollment?.pathwayType,
    isOptOut: enrollment?.isOptOutRenewal,
  };
};

export default {
  document: INSURANCE_ENROLLMENT,
  formatter,
};
