import React from 'react';
import { Checkbox, Pressable, Text, useTheme } from '@uikit';
import { FieldWrapper } from '../FieldWrapper';

const CheckField = ({
  label,
  title,
  input: fieldInput,
  onChange,
  onHover,
  onFocus,
  divider,
  value,
  disabled,
  testID,
  tooltip,
  tldrLabel,
  ...rest
}) => {
  const { theme } = useTheme();
  const input = fieldInput || { value, onChange };

  const hasTitle = !!title;

  return (
    <Pressable
      accesible
      accessibilityLabel={testID}
      disabled={disabled}
      handleOnPress={() => input.onChange(!input.value)}
      onHover={onHover}
      onFocus={onFocus}
      style={[
        theme.xSpace1b,
        theme.ySpace1b,
        theme.justifyStart,
        theme.mediumCorners,
        theme[`checkbox${input.value ? 'Selected' : 'Deselected'}Bg`],
      ]}
    >
      <FieldWrapper style={theme.fullWidth} noGutter>
        <Checkbox
          data-name={input.name}
          inside
          label={hasTitle ? <Text weight="medium">{title}</Text> : label}
          tldrLabel={tldrLabel}
          value={!!input.value}
          checked={!!input.value}
          onValueChange={input.onChange}
          tooltip={tooltip}
          divider={divider}
          testID={testID}
          style={{ cursor: 'pointer' }}
          {...rest}
        />
        {hasTitle && (
          <Text size="fp" style={{ paddingLeft: 36 }}>
            {label}
          </Text>
        )}
      </FieldWrapper>
    </Pressable>
  );
};

export default CheckField;
