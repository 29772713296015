import React from 'react';
import { View } from 'react-native';

import { Text, useTheme } from '@app/_ui-kit';
import { ChartProps } from './types';

interface BarChartProps extends ChartProps {
  data: Array<any>;
  data2: Array<any>;
  style: any;
  x: string;
  y: string;
  domain: any;
  padding: number | { left?: number; right?: number; top?: number; bottom?: number };
  barRatio: number;
  alignment: 'start' | 'middle' | 'end';
  options?: {
    showAxisLabels?: boolean;
  };
}

const MINIMUM_MAX_Y = 500;
const PLACEHOLDER_Y = 2;
const LABEL_HEIGHT = 24;
const GROUP_GAP = 12;
const SERIES_GAP = 4;
const LABEL_MARGIN = 4;

/**
 *
 */
export const BarChart = ({
  data = [],
  data2 = [],
  style,
  x,
  y,
  height = 120,
  options,
}: BarChartProps) => {
  const { theme } = useTheme();

  if (!data || data === [] || data?.length === 0) return <View style={{ height: 200 }} />;
  const _data = data?.map((datum, idx) => ({
    [x]: datum?.[x],
    [y]: datum?.[y],
    y2: data2[idx]?.[y],
    fill: datum?.fill,
    fill2: data2[idx]?.fill,
    xLabel: options?.showAxisLabels ? datum?.xLabel || datum?.[x] : undefined,
  }));

  const maxY = Math.max(
    MINIMUM_MAX_Y,
    ..._data?.map((datum) => datum?.[y]),
    ..._data?.map((datum) => datum?.y2 || datum?.[y]),
  );

  const numSeries = !!data2 && data2?.length > 0 ? 2 : 1;
  const margin = numSeries === 2 ? GROUP_GAP : SERIES_GAP;

  return (
    <View
      style={{
        width: 'auto',
        display: 'flex',
        flexDirection: 'row',
        height: height + LABEL_HEIGHT + LABEL_MARGIN,
        alignItems: 'stretch',
      }}
    >
      {_data?.map((d, idx) => (
        <View
          key={idx}
          style={{
            width: 'auto',
            display: 'flex',
            height: height + LABEL_HEIGHT + LABEL_MARGIN,
            flexBasis: 1 / data?.length,
            flexShrink: 0,
            flexGrow: 1,
            marginRight: idx === _data?.length - 1 ? 0 : margin,
          }}
        >
          <View
            style={[
              {
                height: height,
                flexGrow: 1,
                flexBasis: 'auto',
                flexShrink: 0,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-end',
              },
            ]}
          >
            <View
              style={[
                theme.growUp,
                {
                  height: (height * d?.[y]) / maxY || PLACEHOLDER_Y,
                  marginRight: numSeries === 2 ? SERIES_GAP : 0,
                  flexGrow: 1,
                  borderRadius: 4,
                  animationDelay: `${idx * 0.025}s`,
                  flexBasis: 'auto',
                  flexShrink: 0,
                  backgroundColor: d?.fill || style?.data?.fill({ datum: d }) || 'white',
                },
              ]}
            />
            {numSeries === 2 && (
              <View
                style={[
                  theme.growUp,
                  {
                    height: (height * d?.y2) / maxY || PLACEHOLDER_Y,
                    flexGrow: 1,
                    borderRadius: 4,
                    animationDelay: `${idx * 0.025}s`,
                    flexBasis: 'auto',
                    flexShrink: 0,
                    backgroundColor: d?.fill2 || style?.data?.fill({ datum: d }) || 'white',
                  },
                ]}
              />
            )}
          </View>
          {d?.xLabel && (
            <View style={{ height: LABEL_HEIGHT, marginTop: LABEL_MARGIN }}>
              <Text caps size="xs" color="subtler" align="center" gutter="top">
                {d?.xLabel}
              </Text>
            </View>
          )}
        </View>
      ))}
    </View>
  );
};

export default BarChart;
