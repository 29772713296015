import create, { SetState, GetState } from 'zustand';
import { Copy, PolicyProps, RelinkMode } from '@types';
import { currentPage } from './history';

export type SheetName =
  | 'LOADER'
  | 'TRANSFER_OPTIONS'
  | 'PLAN_DETAIL_MENU'
  | 'COVERAGE_MENU'
  | 'COVERAGE_DETAIL_MENU'
  | 'COVERAGE_MANAGED_OUTSIDE'
  | 'TAXES_MENU'
  | 'TAX_BREAKDOWN_GUIDE'
  | 'TAX_PAYMENT_GUIDE'
  | 'RETIREMENT_PROJECTION_GUIDE'
  | 'MANAGE_BANK_ACCOUNT'
  | 'DELETE_BANK_ACCOUNT'
  | 'PAUSE_GOAL'
  | 'EVENT_RECEIPT'
  | 'HELP_TEXT'
  | 'NEXT_PLAN_SELECTION'
  | 'HEALTH_SCREENED_OUT'
  | 'GOAL_ESTIMATOR'
  | 'RETIREMENT_ACCOUNT_TYPE'
  | 'RETIREMENT_PORTFOLIO'
  | 'POST_PLAID'
  | 'QRCODE'
  | 'REFETCH_ELIGIBILITY'
  | 'PAYMENT_REDIRECT'
  | 'CANCEL_POLICY'
  | 'RETIREMENT_WITHDRAWAL'
  | 'ADD_TO_WALLET';

export const sheets: Record<SheetName, SheetName> = {
  TRANSFER_OPTIONS: 'TRANSFER_OPTIONS',
  PLAN_DETAIL_MENU: 'PLAN_DETAIL_MENU',
  COVERAGE_MENU: 'COVERAGE_MENU',
  COVERAGE_DETAIL_MENU: 'COVERAGE_DETAIL_MENU',
  COVERAGE_MANAGED_OUTSIDE: 'COVERAGE_MANAGED_OUTSIDE',
  TAXES_MENU: 'TAXES_MENU',
  MANAGE_BANK_ACCOUNT: 'MANAGE_BANK_ACCOUNT',
  DELETE_BANK_ACCOUNT: 'DELETE_BANK_ACCOUNT',
  PAUSE_GOAL: 'PAUSE_GOAL',
  TAX_BREAKDOWN_GUIDE: 'TAX_BREAKDOWN_GUIDE',
  TAX_PAYMENT_GUIDE: 'TAX_PAYMENT_GUIDE',
  RETIREMENT_PROJECTION_GUIDE: 'RETIREMENT_PROJECTION_GUIDE',
  EVENT_RECEIPT: 'EVENT_RECEIPT',
  HELP_TEXT: 'HELP_TEXT',
  LOADER: 'LOADER',
  NEXT_PLAN_SELECTION: 'NEXT_PLAN_SELECTION',
  HEALTH_SCREENED_OUT: 'HEALTH_SCREENED_OUT',
  GOAL_ESTIMATOR: 'GOAL_ESTIMATOR',
  RETIREMENT_ACCOUNT_TYPE: 'RETIREMENT_ACCOUNT_TYPE',
  RETIREMENT_PORTFOLIO: 'RETIREMENT_PORTFOLIO',
  POST_PLAID: 'POST_PLAID',
  QRCODE: 'QRCODE',
  REFETCH_ELIGIBILITY: 'REFETCH_ELIGIBILITY',
  PAYMENT_REDIRECT: 'PAYMENT_REDIRECT',
  CANCEL_POLICY: 'CANCEL_POLICY',
  RETIREMENT_WITHDRAWAL: 'RETIREMENT_WITHDRAWAL',
  ADD_TO_WALLET: 'ADD_TO_WALLET',
};

interface SheetProps {
  navBg?: string;
  closeSheet: () => void;
}

/**
 * Prop types for each screen;  these are the parasms to pass when opening
 */
interface TransferOptionsProps {
  goal: object; // should be more specific
  goalType: string; // should be more specific
}

interface PlanDetailMenuProps {
  slug: string;
}

interface CoverageDetailMenuProps {}

interface TaxesMenuProps {
  taxGoal: object;
}

interface ManageBankAccountProps {
  account: any;
}

interface PauseGoalProps {
  goal: any;
}

interface EventReceiptProps {
  title: string;
  asset: any;
  event: any;
}

interface TaxBreakdownGuideProps {
  calculation: any;
  shouldRunQuery: boolean;
}

interface HelpTextProps {
  title: Copy;
  subtitle: Copy;
}

interface HealthPlanBrochureProps {
  scoredHealthPlan: any;
  enrollmentType: any;
}

interface RetirementProjectionGuideProps {
  rate: number;
}

interface LoaderProps {
  title?: any;
}

interface NextPlanSelectionProps {}

interface CoverageManagedOutsideProps {
  managedBy: 'HEALTHCARE_GOV' | 'HEALTHSHERPA';
  reason: 'DENTAL' | 'HEALTHCARE_GOV' | 'HEALTHSHERPA';
}

interface QRCodeProps {
  url: string;
}

interface HealthScreenedOutProps {
  handleFailedScreening: () => void;
  pathwayType: any;
  enrollId: string;
  failedQuestion: string;
  phase: string;
  coverageYear: number;
}

interface GoalEstimatorProps {
  slug: string;
  defaultValue: number;
  recommendedPercentage: number;
}

interface PostPlaidProps extends SheetProps {
  publicToken: string;
  mode: RelinkMode;
  bankLinkID?: string;
  pendingAccountID?: string;
  institutionID?: string;
  accounts?: Array<{ id: string; mask: string }>;
  onCompleted?: () => void;
}

interface RefetchEligibilityProps {
  applicationID: string;
}

interface PaymentRedirectProps {
  policy: PolicyProps;
  enrollment: {
    id: string;
    year: number;
    enrollmentType: string;
    healthApplication: {
      id: string;
    };
  };
}

interface CancelPolicyProps extends SheetProps {
  enrollmentID: string;
  enrollmentType: 'HEALTH' | 'DENTAL';
  actionEffectiveDate: Date;
}

interface RetirementWithdrawalProps extends SheetProps {}
interface AddToWalletProps extends SheetProps {}

export type ContentComponentProps =
  | TransferOptionsProps
  | PlanDetailMenuProps
  | CoverageDetailMenuProps
  | TaxesMenuProps
  | ManageBankAccountProps
  | ManageBankAccountProps
  | PauseGoalProps
  | TaxBreakdownGuideProps
  | EventReceiptProps
  | HelpTextProps
  | HealthPlanBrochureProps
  | RetirementProjectionGuideProps
  | LoaderProps
  | CoverageManagedOutsideProps
  | GoalEstimatorProps
  | PostPlaidProps
  | QRCodeProps
  | RefetchEligibilityProps
  | PaymentRedirectProps
  | CancelPolicyProps
  | RetirementWithdrawalProps
  | AddToWalletProps;

export interface SheetConfig {
  name: SheetName;
  data?: ContentComponentProps;
}

export interface SheetComponentProps {
  TRANSFER_OPTIONS: TransferOptionsProps;
  PLAN_DETAIL_MENU: PlanDetailMenuProps;
  COVERAGE_MENU: SheetProps;
  COVERAGE_DETAIL_MENU: CoverageDetailMenuProps;
  COVERAGE_MANAGED_OUTSIDE: CoverageManagedOutsideProps;
  TAXES_MENU: TaxesMenuProps;
  MANAGE_BANK_ACCOUNT: ManageBankAccountProps;
  DELETE_BANK_ACCOUNT: ManageBankAccountProps;
  PAUSE_GOAL: PauseGoalProps;
  TAX_BREAKDOWN_GUIDE: TaxBreakdownGuideProps;
  TAX_PAYMENT_GUIDE: SheetProps;
  EVENT_RECEIPT: EventReceiptProps;
  HELP_TEXT: HelpTextProps;
  HEALTH_PLAN_BROCHURE: HealthPlanBrochureProps;
  RETIREMENT_PROJECTION_GUIDE: RetirementProjectionGuideProps;
  LOADER: LoaderProps;
  NEXT_PLAN_SELECTION: NextPlanSelectionProps;
  HEALTH_SCREENED_OUT: HealthScreenedOutProps;
  GOAL_ESTIMATOR: GoalEstimatorProps;
  POST_PLAID: PostPlaidProps;
  QRCODE: QRCodeProps;
  REFETCH_ELIGIBILITY: RefetchEligibilityProps;
  PAYMENT_REDIRECT: PaymentRedirectProps;
  CANCEL_POLICY: CancelPolicyProps;
  RETIREMENT_WITHDRAWAL: RetirementWithdrawalProps;
  ADD_TO_WALLET: AddToWalletProps;
}

interface ActiveSheet {
  // unique id: combination of name and referrer
  id: string; // e.g. TRANSFER_SHEET-aks8csjk43

  name: SheetName;
  referrer: string; // page that opened this
  data: { [key: string]: any };
}

type SheetStore = {
  active: Array<ActiveSheet>;
  open: (sheet: SheetName, data?: ActiveSheet['data']) => void;
  close: (sheet: SheetName) => void;
  replace: (sheet: SheetName, data?: ActiveSheet['data']) => void;
  closeAll: () => void;
};

export const useSheet = create<SheetStore>(
  (set: SetState<SheetStore>, get: GetState<SheetStore>) => ({
    active: [],

    open: (name: SheetName, data = {}) => {
      const referrer = currentPage();
      const id = `${name}-${referrer}`;

      // ensures that the sheet isn't already open
      const index = get().active.findIndex((s) => s.id === id);
      if (index < 0) {
        const updated = [...get().active, { id, name, referrer, data }];
        set({ active: updated });
      }
    },
    closeById: (id: string) => {
      const current = [...get().active];
      const index = current.findIndex((s) => s.id === id);
      if (index > -1) current.splice(index, 1);
      set({ active: current });
    },
    close: () => {
      // @todo
    },
    replace: (name: SheetName, data = {}) => {
      const [remove, ...current] = [...get().active];
      const referrer = currentPage();
      const id = `${name}-${referrer}`;

      // ensures that the sheet isn't already open
      const index = current.findIndex((s) => s.id === id);
      if (index < 0) {
        const updated = [...current, { id, name, referrer, data }];
        set({ active: updated });
      }
    },
    closeAll: () => {
      set({ active: [] });
    },
  }),
);
