import gql from 'graphql-tag';

export interface CountiesQueryData {
  reference: {
    counties: Array<{
      fips: string;
      name: string;
      state: string;
      zipcode: string;
    }>;
  };
}

export interface CountiesQueryVars {
  zipcode: string;
}

export const CountiesQuery = gql`
  query CountiesQuery($zipcode: ZipCode!) {
    reference {
      counties(zipcode: $zipcode) {
        fips
        name
        state
        zipcode
      }
    }
  }
`;
