import React from 'react';
import { useCopy } from '@app/utils';
import { ActionSheet } from '@uikit';
import { SheetBlueprint } from '@app/blueprints';

interface Props {
  closeModal: () => void;
  isOpen: boolean;
  loading: boolean;
  handleContinue: (arg0?: boolean) => void;
  title: string;
  subtitle: string;
}

/** @deprecated use standard patterns */
const DetailConfirmationModal = ({
  isOpen,
  closeModal,
  loading,
  handleContinue,
  title,
  subtitle,
}: Props) => {
  const { c: basics } = useCopy('catch.basics');

  return (
    <ActionSheet isOpen={isOpen} onRequestClose={closeModal}>
      <SheetBlueprint
        title={title}
        subtitle={subtitle}
        actions={[
          {
            testID: 'confirmation-add-info-button',
            onAction: closeModal,
            label: basics('addInfo'),
            disabled: loading,
          },
          {
            testID: 'confirmation-continue-button',
            onAction: () => handleContinue(),
            disabled: loading,
            label: basics('continue'),
            button: true,
          },
        ]}
      />
    </ActionSheet>
  );
};

export default DetailConfirmationModal;
