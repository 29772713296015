import gql from 'graphql-tag';
import { GOAL as GF } from '../fragments';

export const GOAL = gql`
  query Goal($id: ID, $type: GoalType, $slug: String) {
    viewer {
      id
      goal(id: $id, goalType: $type, slug: $slug) {
        id
        slug
        ...Goal
      }
    }
  }
  ${GF}
`;

const formatter = (data) => {
  return { goal: data?.viewer?.goal };
};
export default {
  document: GOAL,
  formatter,
};
