import { useDeprecatedQuery, useDeprecatedMutation } from '@data';
import { slugToGoalType } from '@app/utils';

export const useGoal = ({ type, slug, id, ...rest }) => {
  const { goal, loading, error } = useDeprecatedQuery('goal', {
    variables: { type, slug, id },
    skip: !type && !slug && !id,
  });

  const [update, { data: mutData, loading: upserting }] = useDeprecatedMutation('upsertGoal', {
    slug,
    id,
    refetchQueries: ['Goals', 'Goal'],
    ...rest,
  });

  const upsert = (props) => update({ variables: { input: { type, slug, id, ...props } } });

  return {
    goal,
    goalType: goal?.goalType || slugToGoalType(slug),
    upsert,
    loading: loading || upserting,
    waiting: loading && !goal,
    error,
  };
};
