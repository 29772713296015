import React from 'react';
import {
  useQuery,
  queries,
  useMutation,
  mutations,
  OnboardingQueryData,
  UpdateUserResponse,
  UpdateUserVars,
} from '@data';
import { OnboardingFormBlueprint } from '@blueprints';
import { routes } from '@navigate';
import { FormConfig } from '@app/forms';
import { fields } from '@app/config';
import { WorkType } from '@types';
import { useCopy } from '@app/utils';

export const EmploymentType = ({ handleNext }) => {
  const { c } = useCopy('catch.onboarding.employmentType');
  const { loading, data } = useQuery<OnboardingQueryData>(queries.ONBOARDING, {
    fetchPolicy: 'cache-first', // @todo: this should really be the default regardless
  });

  const [update, { loading: submitting }] = useMutation<UpdateUserResponse, UpdateUserVars>(
    mutations.UPDATE_USER,
  );

  const formConfig: FormConfig<{ workType: WorkType }> = {
    initialValues: { workType: data?.viewer?.user?.workType },
    fields: [fields.EMPLOYMENT_TYPE],
    onSubmit: (values) => {
      update({
        variables: { user: { workType: values.workType } },
        optimisticResponse: {
          updateUserNew: {
            user: {
              ...data?.viewer?.user,
              id: data?.viewer?.user?.id || '',
              workType: values.workType,
              __typename: 'User',
            },
          },
        },
      });

      handleNext();
    },
  };

  return (
    <OnboardingFormBlueprint
      loading={loading}
      disabled={submitting}
      title={c('title')}
      formConfig={formConfig}
    />
  );
};

export const EmploymentTypeView = {
  name: routes.EMPLOYMENT_TYPE,
  component: EmploymentType,
  options: OnboardingFormBlueprint.options,
};

export const GoalEmploymentTypeView = {
  name: routes.GOAL_EMPLOYMENT_TYPE,
  component: EmploymentType,
  options: OnboardingFormBlueprint.options,
};
