import React from 'react';
import { SheetComponentProps } from '@navigate';
import { SheetBlueprint } from '@blueprints';
import { useCopy } from '@app/utils';
import { Layout } from '@app/layouts';
import { ActionSheet, useTheme } from '@app/_ui-kit';
import QRCode from 'react-qr-code';
import { View } from 'react-native';

const QRCodeSheet: React.FC<SheetComponentProps['QRCODE']> = ({ url }) => {
  const { c } = useCopy('catch.module.me.ReferralView');
  const { themeColors, theme } = useTheme();

  return (
    <SheetBlueprint title={c('shareQRCodeTitle')} hasBottomActions>
      <Layout centerAll bottomSpace>
        <View style={[theme.borderColor, theme.plusCorners, theme.allSpace3]}>
          <QRCode size={160} value={url} fgColor={themeColors.textColor} bgColor="transparent" />
        </View>
      </Layout>
    </SheetBlueprint>
  );
};

export default QRCodeSheet;
