import React from 'react';
import { healthRouteMap } from '@navigate/healthRouteMap';
import { checkTaxPaymentPeriod, Env } from '@app/utils';
import { start, stacks, routes } from '@navigate';
import OAuthHandler from '@app/_common/utility/OAuthHandler';
import { Params, useParams } from 'react-router';

/**
 * Redirects take two shapes, generally
 * First, basic from -> to redirect between two paths
 * Second, a handler where we want to initialize the app with some state
 *
 * Note: these are both exported and sorted among screens to allow for
 * better comparative routing between screens
 */

interface RedirectDefinition {
  type: 'redirect';
  from: string;
  to: string;
}


export const redirects: Array<RedirectDefinition> = [
  { type: 'redirect', from: '/plan/:slug/intro', to: '/setup/:slug/intro' },
  {
    type: 'redirect',
    from: '/plan/:slug/work-type-selection',
    to: '/setup/:slug/work-type-selection',
  },
  { type: 'redirect', from: '/plan/:slug/work-details', to: '/setup/:slug/work-details' },
  { type: 'redirect', from: '/plan/:slug/work-mismatch', to: '/setup/:slug/work-mismatch' },
  { type: 'redirect', from: '/plan/:slug/current-savings', to: '/setup/:slug/current-savings' },
  { type: 'redirect', from: '/plan/:slug/estimator', to: '/setup/:slug/estimator' },
  { type: 'redirect', from: '/plan/:slug/risk-level', to: '/setup/:slug/risk-level' },
  { type: 'redirect', from: '/plan/:slug/risk-comfort', to: '/setup/:slug/risk-comfort' },
  { type: 'redirect', from: '/plan/:slug/portfolio', to: '/setup/:slug/portfolio' },
  { type: 'redirect', from: '/plan/:slug/account', to: '/setup/:slug/account' },
  { type: 'redirect', from: '/plan/:slug/bank-link', to: '/setup/:slug/bank-link' },
  { type: 'redirect', from: '/plan/:slug/legal', to: '/setup/:slug/legal' },
  { type: 'redirect', from: '/plan/:slug/access-denied', to: '/setup/:slug/access-denied' },
  { type: 'redirect', from: '/plan/:slug/regulatory', to: '/setup/:slug/regulatory' },
  { type: 'redirect', from: '/plan/:slug/ineligible', to: '/setup/:slug/ineligible' },
  {
    type: 'redirect',
    from: '/plan/:slug/investment-agreement',
    to: '/setup/:slug/investment-agreement',
  },
  { type: 'redirect', from: '/plan/:slug/confirm', to: '/setup/:slug/confirm' },
  { type: 'redirect', from: '/healthlink/*', to: '/health/link/*' },
  { type: 'redirect', from: '/state-return', to: '/health/explorer/state-return' },
  { type: 'redirect', from: '/index.html', to: '/' },
];

interface SpecialHandler {
  type: 'handler';
  path: string;
  element: React.ReactElement<{ onStart: (params: Params<string>) => void }>;
}

export const Handler: React.FC<{ onStart: (params: Params<string>) => void }> = ({ onStart }) => {
  const params = useParams();
  onStart(params);

  return null;
};

export const handlers: Array<SpecialHandler> = [
  { type: 'handler', path: '/plaid-oauth', element: <OAuthHandler /> },
  {
    /**
     * temporary so both logged in and logged out opens in a modal
     * @todo: make it so that if we visit a /___ path with a default sheet layout,
     * then open it as a sheet
     */
    type: 'handler',
    path: '/migration',
    element: (
      <Handler
        onStart={() => {
          start('MAIN_TABS', { open: undefined });
        }}
      />
    ),
  },
  {
    type: 'handler',
    path: '/health/:enrollId/*',
    element: (
      <Handler
        onStart={(params) => {
          start('MAIN_TABS', {
            initialTab: stacks.COVERAGE_STACK,
            open: params.enrollId ? healthRouteMap[params['*']] : undefined,
            data: { enrollId: params.enrollId },
          });
        }}
      />
    ),
  },
  {
    type: 'handler',
    path: '/taxes/:paymentId',
    element: (
      <Handler
        onStart={(params) => {
          start('MAIN_TABS', {
            initialTab: stacks.TAXES_STACK,
            open: params.paymentId ? routes.TAX_PAYMENT : undefined,
            data: { paymentId: params.paymentId },
          });
        }}
      />
    ),
  },
  {
    type: 'handler',
    path: '/paycheck/:id/:action',
    element: (
      <Handler
        onStart={(params) => {
          start('MAIN_TABS', {
            initialTab: stacks.HOME_STACK,
            open: !params.id
              ? undefined
              : params.action === 'income' || params.action === 'breakdown'
              ? routes.PAYCHECK_INCOME
              : routes.PAYCHECK_SKIPPED,
            data: {
              paycheckID: params.id,
              action: params.action,
            },
          });
        }}
      />
    ),
  },
  {
    type: 'handler',
    path: '/link-health-coverage',
    element: (
      <Handler
        onStart={(params) => {
          start('MAIN_TABS', { initialTab: stacks.COVERAGE_STACK, open: routes.HEALTH_LINK_INTRO });
        }}
      />
    ),
  },
  {
    type: 'handler',
    path: '/setup-autopilot',
    element: (
      <Handler
        onStart={(params) => {
          start('MAIN_TABS', {
            initialTab: stacks.INCOME_STACK,
            // open: routes.ADD_INCOME_SOURCE,
            // data: { type: 'always' },
          });
        }}
      />
    ),
  },
  {
    type: 'handler',
    path: '/manage-autopilot',
    element: (
      <Handler
        onStart={(params) => {
          start('MAIN_TABS', {
            initialTab: stacks.INCOME_STACK,
            open: routes.MANAGE_INCOME_SOURCES,
          });
        }}
      />
    ),
  },
  {
    type: 'handler',
    path: '/tax-payments',
    element: (
      <Handler
        onStart={(params) => {
          const period = checkTaxPaymentPeriod();

          start('MAIN_TABS', {
            initialTab: stacks.TAXES_STACK,
            open: routes.TAX_PAYMENT_INTRO,
            data: { quarterNum: period.quarterNum, taxYear: period.taxYear },
          });
        }}
      />
    ),
  },
  {
    type: 'handler',
    path: '/deposit',
    element: (
      <Handler
        onStart={(params) => {
          start('MAIN_TABS', {
            initialTab: stacks.PLAN_STACK,
            // @todo commented out because we need to default to a goal
            // open: routes.TRANSFER_AMOUNT,
            // data: { transferType: 'deposit' },
          });
        }}
      />
    ),
  },
];
