import React from 'react';

import { routes } from '@navigate';
import { BasicLayout, Toolbar } from '@app/layouts';
import { Button } from '@app/_ui-kit';
import { HealthSlasherStackScreenProps } from '../HealthSlasherStack';
import { FormConfig, Fields, useForm } from '@f';
import { FieldConfig } from '@app/types';
import { mutations, updates, useMutation } from '@app/data';
import { Segment } from '@app/utils';

/**
 *
 * Premium Slasher Income Entry
 *
 */
const HealthSlasherIncomeEntry = ({
  handleNext,
  loading,
  previousApplication,
  explorerIncome,
}: HealthSlasherStackScreenProps) => {
  const [upsert] = useMutation(mutations.UPSERT_HEALTH_APPLICATION);

  const [reportChange, { loading: reporting }] = useMutation(
    mutations.REPORT_HEALTH_ENROLLMENT_CHANGES,
    {
      onCompleted: (data) => {
        const slasherAppID =
          data?.reportHealthEnrollmentChanges?.insuranceEnrollment?.applicationID;

        upsert({
          variables: {
            input: {
              id: slasherAppID,
              applicationContext: 'SLASHER',
            },
          },
        });

        Segment.track('Health Slasher Change Started');
        handleNext({ slasherAppID });
      },
      update: updates.ADD_PREFILL,
    },
  );

  const explIncome = explorerIncome;
  const applIncome = previousApplication?.householdIncome;
  const coverageYear = previousApplication?.coverageYearNumber;

  const fields: Array<FieldConfig> = [
    {
      testID: 'S4',
      name: 'householdIncome',
      type: 'amount',
      label: 'Household income (per year)',
      required: true,
      amountType: 'integer',
      placeholder: 'Annual income',
      inputProps: {
        autoFocus: true,
      },
    },
  ];

  const formConfig: FormConfig = {
    initialValues: { householdIncome: explIncome || applIncome },
    fields,
    onSubmit: (values) =>
      reportChange({
        variables: {
          input: {
            applicationInput: { householdIncome: values?.householdIncome },
          },
        },
      }),
  };

  const form = useForm({
    loading: loading || reporting,
    disabled: loading || reporting,
    fields: formConfig.fields,
    initialValues: formConfig.initialValues,
    onError: console.log,
    onSubmit: formConfig.onSubmit,
  });

  return (
    <BasicLayout
      title="Confirm your estimated household income"
      subtitles={[`What do you expect you’ll earn in ${coverageYear}?`]}
      toolbar={
        <Toolbar type="stack">
          <Button
            loading={reporting}
            testID="next"
            onPress={form.submitForm}
            disabled={form.disableSubmit}
          >
            Next
          </Button>
        </Toolbar>
      }
    >
      <Fields form={form} fields={formConfig.fields} />
    </BasicLayout>
  );
};

export const HealthSlasherIncomeEntryView = {
  name: routes.HEALTH_SLASHER_INCOME_ENTRY,
  component: HealthSlasherIncomeEntry,
  options: {},
};
