import React from 'react';
import { View, Platform } from 'react-native';

import { Checkbox } from '@uikit/components/Checkbox';
import { FieldWrapper } from '../FieldWrapper';
import { useID } from '@uikit/hooks/useID';

const CheckboxField = ({
  label,
  sublabel,
  required,
  subtext,
  error,
  options,
  input: fieldInput,
  style,
  checked,
  onChange,
  children,
  question,
  otherReg,
  allowOther,
  groupDivider,
  divider,
  values,
  value,
  bare,
  row,
  gutter,
  testID,
  tooltip,
  extraLabel,
  returnValue,
}) => {
  const labelID = useID();
  const sublabelID = useID();
  const input = {
    onChange,
    ...(fieldInput || {}),
    value: (fieldInput || {}).value || value || [],
    checked,
  };
  const showOther = allowOther && (otherReg ? new RegExp(otherReg) : /OTHER/).test(input.value);
  const isMulti = options?.length > 1;
  const fields = options?.map((o, i) => {
    const isLast = i === options.length - 1;
    const checked = isMulti
      ? input.value.includes(o.value)
      : o.value
      ? input.value === o.value
      : values
      ? input.value === values.YES
      : input.value;
    const onChange = (val) =>
      input.onChange(
        returnValue
          ? o.value
          : isMulti
          ? checked
            ? input.value.filter((v) => o.value !== v)
            : [...input.value, o.value]
          : values
          ? input.value === values.YES
            ? values.NO
            : values.YES
          : !checked,
      );
    return (
      <View key={`c-${i}`} style={{ flexDirection: 'row' }}>
        <Checkbox
          data-name={input?.name}
          disabled={o.disabled}
          label={o.label}
          value={o.value}
          checked={checked || o.checked}
          divider={divider}
          alignTop={isMulti}
          onValueChange={onChange}
          description={o.description}
          gutter={(isLast && allowOther && showOther && 'bottom') || gutter}
          accessibilityLabel={testID || input?.name}
          testID={testID || input?.name}
          style={bare && style}
        />
        {extraLabel}
      </View>
    );
  });
  if (bare) {
    return fields;
  }
  return (
    <FieldWrapper
      label={label}
      required={required}
      role={Platform.select({ web: 'group' })}
      sublabel={sublabel || question}
      subtext={subtext}
      labelID={labelID}
      sublabelID={sublabelID}
      groupLabelID={label ? labelID : undefined}
      groupSublabelID={sublabel ? sublabelID : undefined}
      divider={groupDivider}
      tooltip={tooltip}
      error={error}
      style={style}
      row={row}
    >
      {fields}
      {allowOther ? showOther && children : children}
    </FieldWrapper>
  );
};

export default CheckboxField;
