export const calcPovertyLine = ({ maritalStatus, numTaxDependents, state }) => {
  const totalHousehold =
    (maritalStatus === 'MARRIED' ? 2 : 1) + numTaxDependents;

  /** @see https://www.federalregister.gov/documents/2020/01/17/2020-00858/annual-update-of-the-hhs-poverty-guidelines */
  /** @todo update annually  */
  // Index 0 of each list equals amount for each additional person after 8
  const guidelines = {
    HI: [5150, 14680, 19830, 24980, 30130, 35280, 40430, 45580, 50730],
    AK: [5600, 15950, 21550, 27150, 32750, 38350, 43950, 49550, 55150],
    default: [4480, 12760, 17240, 21720, 26200, 30680, 35160, 39640, 44120],
  };
  const guides = guidelines[state] || guidelines.default;
  const povertyLine =
    totalHousehold > 8
      ? guides[8] + (totalHousehold - 8) * guides[0]
      : guides[totalHousehold];
  const fpls420 = Math.round((povertyLine * 4.2) / 1000) * 1000;
  return {
    povertyLine,
    fpls420,
  };
};
