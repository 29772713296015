import React, { useMemo } from 'react';
import { routes } from '@navigate';
import { copy, getFullName } from '@app/utils';
import { HealthSlasherStackScreenProps } from '../HealthSlasherStack';
import { BasicFormBlueprint } from '@app/blueprints';

const fields = [
  {
    name: 'members',
    type: 'array',
    Component: ({ children }) => children,
    Footer: () => null,
    subfields: [
      {
        name: 'incomeChanged',
        type: 'person',
        dependencies: ['givenName', 'familyName', 'annualTaxIncome'],
        label: ({ givenName, familyName }) => {
          return getFullName({ givenName, familyName });
        },
        secondary: {
          unchecked: ({ annualTaxIncome }) =>
            annualTaxIncome
              ? `Earning ${copy.$(annualTaxIncome?.incomeAmount, { whole: true })}/year`
              : '',
          checked: ({ annualTaxIncome }) =>
            annualTaxIncome
              ? `Earning ${copy.$(annualTaxIncome?.incomeAmount, { whole: true })}/year`
              : '',
        },
      },
    ],
  },
];

/**
 *
 * Premium Slasher Income List
 * @todo integrate approach with Application Proper
 *
 */
const HealthSlasherIncomeList = ({
  handleNext,
  newApplication,
  loading,
}: HealthSlasherStackScreenProps) => {
  const allMembers = useMemo(() => {
    const mems = newApplication?.applicant
      ? [...(newApplication?.members || []), newApplication?.applicant]
      : [];

    return mems.sort(
      (a, b) => (b?.annualTaxIncome?.incomeAmount || 0) - (a?.annualTaxIncome?.incomeAmount || 0),
    );
  }, [newApplication]);

  return (
    <BasicFormBlueprint
      loading={loading}
      submitting={false}
      title="Whose income has changed?"
      subtitle="This was pulled from your current health application."
      fields={fields}
      formConfig={{
        fields,
        initialValues: { members: allMembers },
        onSubmit: (values) => {
          const incomeChangeMembers = values.members.reduce(
            (acc, m) => (m?.incomeChanged ? [...acc, m?.id] : acc),
            [],
          );

          handleNext({ incomeChangeMembers });
        },
      }}
    />
  );
};

export const HealthSlasherIncomeListView = {
  name: routes.HEALTH_SLASHER_INCOME_LIST,
  component: HealthSlasherIncomeList,
  options: {},
};
