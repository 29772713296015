import React from 'react';
import { Card, Stack } from '@app/layouts';
import { ComplexRow, Text } from '@uikit';
import { safeFormatDate } from '@app/utils';
import TaxQuarterDonut from '@app/charts/TaxQuarterDonut';

/**
 * @deprecated (can prob just use ComplexCard + a util?)
 */
const PreviousApplicationCard = ({ application, disabled }) => {
  return (
    <Card disabled={disabled}>
      <Stack separatorComponent>
        <ComplexRow
          label={`${application?.coverageYear} Application`}
          sublabel={application?.tenant}
          asset={{
            element: (
              <TaxQuarterDonut
                percent={application?.status === 'IN_PROGRESS' ? 0.35 : 0.95}
                color="coverage"
              />
            ),
            size: 'md',
          }}
        />
        <Text size="fp" color="subtle" style={{ paddingTop: 16 }}>
          Updated {safeFormatDate(application?.lastUpdated)}{' '}
        </Text>
      </Stack>
    </Card>
  );
};

export default PreviousApplicationCard;
