import React from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';

import { routes } from '@navigate';
import { BasicLayout, Section, Toolbar } from '@app/layouts';
import { Button, ComplexRow } from '@app/_ui-kit';
import { FieldsConfig } from '@app/types';
import { useForm, Fields } from '@app/forms';
import {
  getFullName,
  personFullName,
  personInitials,
  precisionRound,
  safeFormatDate,
  Segment,
  useCopy,
} from '@app/utils';

import { HealthSlasherStackScreenProps } from '../HealthSlasherStack';

const SUBMIT = gql`
  mutation SubmitSlasherApplication(
    $submitHealthApplicationNewId: ID!
    $input: SubmitHealthApplicationAgreements!
  ) {
    submitHealthApplicationNew(id: $submitHealthApplicationNewId, input: $input) {
      currApplication {
        id
        status
        aptcHouseholdAmount
        previousAptcHouseholdAmount
        enrollmentGroups {
          id
          appliedAPTCAmount
          previousAppliedAPTCAmount
        }
      }
      errors {
        code
        applicationMemberID
        path
      }
    }
  }
`;

export const getOutcome = (application) => {
  const totalDiff = application?.enrollmentGroups?.reduce((total, eg) => {
    return total + precisionRound(eg?.appliedAPTCAmount - eg?.previousAppliedAPTCAmount, 2);
  }, 0);

  return totalDiff > 0 ? 'POSITIVE' : totalDiff < 0 ? 'NEGATIVE' : 'NOCHANGE';
};

/**
 *
 * Premium Slasher Agreements
 * @todo figure out how to handle the rest of the agreements
 *
 */
const HealthSlasherAgreements = ({
  handleNext,
  newApplication,
  allMembers,
}: HealthSlasherStackScreenProps) => {
  const { c, $ } = useCopy('catch.ede.HealthEnrollment');

  const [submitApplication, { loading: submitting }] = useMutation(SUBMIT, {
    onCompleted: ({ submitHealthApplicationNew }) => {
      const application = submitHealthApplicationNew?.currApplication;
      Segment.track('Health Slasher Application Submitted');

      handleNext({
        slasherAppID: application.id,
        outcome: getOutcome(application),
      });
    },
    refetchQueries: ['HealthSlasherQuery'],
    awaitRefetchQueries: true,
  });

  const fields: FieldsConfig = [
    {
      type: 'checkbox',
      name: 'isPenaltyOfPerjuryAgreement',
      required: true,
      label: c('256_Q'),
    },
    {
      label: c('258_Q'),
      type: 'signature',
      name: 'signatureText',
      legalName: getFullName(newApplication?.applicant),
    },
  ];

  const form = useForm<{
    isPenaltyOfPerjuryAgreement: boolean;
    signatureText: string;
  }>({
    loading: submitting,
    disabled: false,
    initialValues: {},
    fields,
    onSubmit: (values) => {
      submitApplication({
        variables: {
          submitHealthApplicationNewId: newApplication?.id,
          input: {
            isRenewalAgreement: 'YES',
            renewEligibilityYearQuantity: null,
            isMedicaidRequirementAgreement: 'YES',
            isTaxCreditReconciled: 'YES',
            isAbsentParentAgreement: 'YES',
            isTerminateCoverageOtherMecFoundAgreement: 'YES',
            lastUsedRoute: 'slasher',
            isChangeInformationAgreement: 'YES',
            isPenaltyOfPerjuryAgreement: 'YES',
            signatureText: values?.signatureText,
            signatureDate: safeFormatDate(new Date(), 'yyyy-MM-dd'),
            signatureType: 'APPLICANT',
          },
        },
      });
    },
  });

  const members = [newApplication?.applicant, ...(newApplication?.members || [])];

  return (
    <BasicLayout
      title="Confirm changes"
      subtitles={['Verify the changes below to determine your final additional tax credits.']}
      // icon={{ svg: Signature, bg: 'coverage' }}
      toolbar={
        <Toolbar type="stack">
          <Button loading={submitting} onPress={form.submitForm} disabled={form.disableSubmit}>
            Submit updates
          </Button>
        </Toolbar>
      }
    >
      {members?.map((member) => (
        <ComplexRow
          key={member?.id}
          label={personFullName(member)}
          sublabel={$(member?.annualTaxIncome?.incomeAmount)}
          asset={{
            text: personInitials(member),
            size: 'xs',
            containerSize: 'md',
            color: 'secondary',
            gradient: 'coverageLight',
            shape: 'circle',
          }}
        />
      ))}

      <Section>
        <Fields form={form} fields={fields} />
      </Section>
    </BasicLayout>
  );
};

export const HealthSlasherAgreementsView = {
  name: routes.HEALTH_SLASHER_AGREEMENTS,
  component: HealthSlasherAgreements,
  options: {},
};
