import React from 'react';
import { navigate, routes } from '@navigate';
import { useDeprecatedQuery, useMutation, mutations } from '@data';
import { MultiQuestionSplitFormBlueprint } from '@app/blueprints';
import { questions } from './memberQuestionsFields';
import { formatPayload, getInitialValues } from './memberQuestionsUtils';

/**
 * MemberDetailsView
 * Page where users add information for Phase 2 questions
 */
const MemberQuestions = ({ isGA, applicationID, enrollId }) => {
  const {
    members,
    parsedQuestions,
    renderImmigrationQuestions,
    isRequestingFinancialAssistance,
    loading,
  } = useDeprecatedQuery('application', {
    variables: { enrollId },
    skip: !enrollId,
  });

  const [upsert] = useMutation(mutations.UPSERT_APPLICATION_MEMBERS);

  const handleNext = () => {
    if (renderImmigrationQuestions) {
      navigate(routes.EDE_IMMIGRATION_DETAILS);
    } else if (isRequestingFinancialAssistance) {
      navigate(routes.EDE_MEDICAID_DENIAL);
    } else {
      navigate(routes.EDE_SEP);
    }
  };

  return (
    <MultiQuestionSplitFormBlueprint
      loading={loading}
      submitting={false}
      questions={questions}
      members={members}
      data={{ uiQuestionsToDisplay: parsedQuestions }}
      getInitialValues={(val) => getInitialValues(val)}
      onNext={(values, question) => {
        upsert(
          formatPayload({
            applicationID,
            questionID: question?.testID,
            members: values.members,
          }),
        );
      }}
      onComplete={handleNext}
    />
  );
};

export const MemberQuestionsView = {
  name: routes.EDE_MEMBER_QUESTIONS,
  component: MemberQuestions,
  options: {
    ...MultiQuestionSplitFormBlueprint.options,
    title: 'Personal details',
  },
};
