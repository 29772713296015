import React from 'react';
import { routes } from '@navigate';
import { Layout } from '@app/layouts';

/**
 * Quick Renew Success
 *
 * - @todo make sure to track with Segment event
 */
const HealthRenewSuccess = ({ handleNext, loading }) => {
  return <Layout.Header title="Success" />;
};

export const HealthRenewSuccessView = {
  name: routes.HEALTH_RENEW_SUCCESS,
  component: HealthRenewSuccess,
  options: {},
};
