import React, { useCallback, useMemo } from 'react';

import {
  navigate,
  routes,
  useCurrentRoute,
  stacks,
  Stack,
  StackComponent,
  StackDefinition,
  close,
  exit,
} from '@navigate';
import { useQuery, queries, PayrollQueryData } from '@data';
import { useHealthLink } from '@app/hooks';
import { useAppReview } from '@util';
import { HealthLinkAgreementsView } from './shared/PrivacyAgreementsView';
import { HealthLinkIdentityView } from './link/HealthLinkIdentityView';
import { HealthLinkIntroView } from './link/HealthLinkIntroView';
import { HealthLinkImportView } from './link/HealthLinkImportView';

const config: StackDefinition = {
  stackName: stacks.HEALTH_LINK_STACK,
  options: {
    title: 'Health Link',
    layout: 'sheet',
    navMode: 'flow',
    accentColor: 'coverage',
    presentation: 'basicSheet',
  },
  screens: [
    HealthLinkIntroView,
    HealthLinkAgreementsView,
    HealthLinkIdentityView,
    HealthLinkImportView,
  ],
};

/**
 *
 *
 * F A S T L I N K ~~>
 * Health Link Stack
 *
 * @see https://www.figma.com/file/CJexcruPziVz3e8B219TSP/Health-Link
 * @see https://www.notion.so/catchco/Fastlink-Beta-133739dc638944599fe5721aefc76909
 *
 */
const HealthLinkStack: StackComponent = () => {
  const route = useCurrentRoute();
  const {
    agree,
    agreeing,
    needsAgreements,
    needsIdentity,
    importAll,
    importing,
    loading,
    confirmIdentity,
    canSearch,
    hasSearched,
    lookup,
    isSlasherFlow,
    explorerIncome,
    isOpenEnrollment,
    ...rest
  } = useHealthLink();

  // Handlers
  const handleExit = () => exit(routes.HOME);

  /**
   * request an app store review if a health plan was successfully linked and the user has already been
   * using Catch, in this case carries a balance
   */
  const { requestReview } = useAppReview({ location: 'healthlink' });
  const { data } = useQuery<PayrollQueryData>(queries.PAYROLL, { fetchPolicy: 'cache-first' });

  const hasAvailableBalance = useMemo(
    () =>
      !!data?.viewer?.goals
        ? data?.viewer?.goals?.some((goal) => goal?.availableBalance > 0)
        : false,
    [data?.viewer?.goals],
  );

  // Navigation
  const handleNext = useCallback(() => {
    // during OE, use the renewal import flow instead
    const importRoute = isOpenEnrollment ? routes.HEALTH_RENEW_IMPORT : routes.HEALTH_LINK_IMPORT;
    const identityRoute = isOpenEnrollment
      ? routes.HEALTH_RENEW_IDENTITY
      : routes.HEALTH_LINK_IDENTITY;

    switch (route) {
      case routes.HEALTH_LINK_INTRO:
        navigate(
          needsAgreements
            ? routes.HEALTH_LINK_AGREEMENTS
            : !canSearch
            ? routes.ACCOUNT_SETUP_DOB
            : needsIdentity
            ? identityRoute
            : importRoute,
          { slug: 'health' },
        );
        break;
      case routes.HEALTH_LINK_AGREEMENTS:
        navigate(
          !canSearch ? routes.ACCOUNT_SETUP_DOB : needsIdentity ? identityRoute : importRoute,
          { slug: 'health' },
        );
        break;
      case routes.HEALTH_LINK_IDENTITY:
        navigate(importRoute);
        break;
      case routes.HEALTH_LINK_IMPORT:
        if (hasAvailableBalance) {
          requestReview();
        }

        exit(routes.HOME);
        break;
      default:
        break;
    }
  }, [route, needsAgreements, needsIdentity, isSlasherFlow, isOpenEnrollment]);

  return (
    <Stack
      stackName={config.stackName}
      screens={config.screens}
      options={config.options}
      data={{
        // @todo shared type for health link screen props (like health slasher screen props)
        loading,
        agree,
        agreeing,
        handleNext,
        handleSkip: handleExit,
        needsAgreements,
        needsIdentity,
        confirmIdentity,
        importAll,
        importing,
        canSearch,
        hasSearched,
        lookup,
        isSlasherFlow,
        explorerIncome,
        ...rest,
      }}
    />
  );
};

HealthLinkStack.config = config;
export default HealthLinkStack;
