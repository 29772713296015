import React, { useState, useCallback } from 'react';
import { OnboardingFormBlueprint } from '@blueprints';
import {
  useQuery,
  queries,
  PayrollQueryData,
  useMutation,
  mutations,
  UpsertGoalResponse,
  UpsertGoalVars,
} from '@data';
import { routes } from '@navigate';
import { FormConfig } from '@app/forms';
import { SetupSlug } from './types';

interface FormValues {
  otherWithholding: Array<SetupSlug>;
}

const OtherWithholding = ({ goals, handleNext }) => {
  const [initialGoals] = useState(
    Object.keys(goals).reduce((acc, g) => {
      return !!goals[g] ? [...acc, g] : acc;
    }, []),
  );

  const { loading, data } = useQuery<PayrollQueryData>(queries.PAYROLL, {
    fetchPolicy: 'cache-first',
  });

  //delete any goals in draft mode that are not selected
  const [update, { loading: submitting }] = useMutation<UpsertGoalResponse, UpsertGoalVars>(
    mutations.UPSERT_GOAL,
  );

  //if there are already goals in draft status that were not selected, we set their status to deleted
  //that way they don't show up in payroll summary or confirm goals
  //note, removeGoal mutation exists but since we're only in the setup stage there aren't any analytics or balances to remove
  const deleteUnselectedDraftGoals = useCallback(
    (selectedGoals) => {
      const draftGoals = data?.viewer?.goals?.filter(
        //we don't filter out taxes because other withholding is for non tax goal setup
        (goal) => goal.slug !== 'taxes' && goal.status === 'DRAFT',
      );

      const unselectedDraftGoals = draftGoals?.filter((goal) => !selectedGoals.includes(goal.slug));
      unselectedDraftGoals?.forEach((goal) => {
        update({
          variables: {
            input: { slug: goal?.slug, status: 'DELETED' },
          },
        });
      });
    },
    [data],
  );

  const formConfig: FormConfig<FormValues> = {
    initialValues: { otherWithholding: initialGoals },
    fields: [
      {
        name: 'otherWithholding',
        type: 'multiSelect',
        required: false,
        options: [
          { label: 'Health Savings', value: 'health-expenses' },
          { label: 'Time Off', value: 'timeoff' },
          { label: 'Family Leave', value: 'family-leave' },
          { label: 'Emergency Savings', value: 'emergency-savings' },
        ],
      },
    ],
    onSubmit: (values) => {
      deleteUnselectedDraftGoals(values.otherWithholding);

      const updatedGoals = values.otherWithholding.reduce(
        (goals, slug) => ({ ...goals, [slug]: 'TODO' }),
        {},
      );
      handleNext({ updatedGoals });
    },
  };

  return (
    <OnboardingFormBlueprint<FormValues>
      loading={loading}
      disabled={submitting}
      title="Besides taxes, what else do you want to set aside for?"
      formConfig={formConfig}
    />
  );
};

export const OtherWithholdingView = {
  name: routes.OTHER_WITHHOLDING,
  component: OtherWithholding,
  options: {},
};
