import React from 'react';
import { CheckField } from '@app/forms_old/inputs';
import { Copy } from '@types';
import { InputProps } from '../types';

interface CheckboxInputProps<T> extends InputProps<T> {
  title?: Copy;
  label: Copy;
}

const CheckboxInput = <T extends any>({
  testID,
  name,
  label,
  title,
  value,
  help,
  disabled,
  onChange,
  onHover,
  onFocus,
  onBlur,
  error,
}: CheckboxInputProps<T>) => {
  return (
    <CheckField
      testID={testID}
      name={name}
      value={value}
      disabled={disabled}
      tooltip={help}
      onHover={onHover}
      onFocus={onFocus}
      onChange={(val) => {
        onChange(val);
        onBlur(); // should trigger validation when toggle changes
      }}
      label={label}
      title={title}
    />
  );
};

export default CheckboxInput;
