import gql from 'graphql-tag';

/** @deprecated */
const subscription = gql`
  fragment Subscription on CatchFeeSubscription {
    id
    type
    startDate
    endDate
    status
    delinquentWindowEndDate
    hasRetried
  }
`;

/** @deprecated */
const fee = gql`
  fragment Fee on CatchFee {
    id
    fee
    type
    frequency
  }
`;

export default {
  subscription,
  fee,
};
