import gql from 'graphql-tag';
import access from 'safe-access';

import { handleApplicationData } from './handleApplicationData';
import { getFullName } from '@app/utils';

/**
 * Health Application
 *
 * Queries the application in its entirety
 * This would be a good query to index for speed (currently ~1s on dev)
 */
export const HEALTH_APPLICATION = gql`
  query HealthApplicationA($enrollId: ID!) {
    viewer {
      id
      health {
        enrollment(id: $enrollId) {
          id
          ... on HealthInsuranceEnrollment {
            id
            status
            pathwayType
            healthApplication {
              id
              ... on HealthApplication {
                id
                status
                coverageState
                coverageYearNumber
                lastUsedRoute
                applicationPhase
                uiQuestionsToDisplay

                # Privacy Agreements
                isPrivacyDataUseAgree
                isPrivacyTruthfulAgree
                isPrivacyAuthorizeAgree

                # Household
                # todo: screening should have a marital status independent of members
                numTaxDependents
                isSelfCoverage
                isSpouseCoverage
                numDependentsRequestCoverage

                # Screening Questions
                isRequestingFinancialAssistance
                areAllSameAddress
                isPlanningToFileTax
                isResponsibleForChildNotOnTaxReturn
                isPregnantOrHadChildLast60Days
                isAnyoneFullTimeStudent1822
                areApplicationsCitizens
                isCanProvideSsn
                isApplicationNameSameAsSsn
                isNaturalizedOrDerivedCitizenship
                isIncarcerated
                isIndianOrAlaskan
                isCoveredThroughJobOrCobra
                isFormerFosterCareAndUnder25
                isClaimingAllDependentsOnTax
                isDependentSingleChild25OrYounger
                isStepchildOrGrandchild
                areDependentsChildOrStepchildSingleUnder26
                areDependentsLivingWithParentNotOnTaxReturn
                isOfferedIchra

                # Application Agreements
                isMedicaidRequirementAgreement
                isAbsentParentAgreement
                isChangeInformationAgreement
                isTerminateCoverageOtherMecFoundAgreement
                isTaxCreditReconciled
                isRenewalAgreement
                isReuseTaxDataPermission
                renewEligibilityYearQuantity
                isPenaltyOfPerjuryAgreement
                isNonIncarcerationAgreement
                signatureText
                signatureDate
                signatureType

                # APTC Agreements
                aptcAgreement
                aptcSignature

                # Application Assistors
                applicationAssistors {
                  applicationAssistorType
                  organizationID
                  organizationName
                  firstName
                  middleName
                  lastName
                  assistorNPN
                }

                # Application Income Indicators
                incomeExplanationRequiredIndicator
                incomeExplanationRequiredReason
                incomeExplanationDocumentedAmount

                # State CHIP indicators
                stateCHIPWaitingPeriod
                stateDeprivationRequirementRetained
              }
            }

            members {
              id
              maritalStatus # don't really need this for P1?
              isParent

              # Contact Info
              email
              writtenLanguageType
              spokenLanguageType
              contactMethod
              birthCountry

              mailingAddress {
                street1
                street2
                city
                state
                zip
                zip4
                country
                countyfips
                coordinates {
                  latitude
                  longitude
                }
              }

              homeAddress {
                street1
                street2
                city
                state
                zip
                zip4
                country
                coordinates {
                  latitude
                  longitude
                }
                countyfips
              }

              idProofAddress {
                street1
                street2
                city
                state
                zip
                zip4
                country
                coordinates {
                  latitude
                  longitude
                }
                countyfips
              }

              phone {
                number
                type
                ext
              }

              secondaryPhone {
                number
                type
                ext
              }

              # Member Info
              givenName
              middleName
              familyName
              nameSuffix
              relation
              filerRelation
              spouseRelation
              isRequestingCoverage
              age
              sex
              dob
              ssn
              isCitizen
              ssnAlternateName {
                givenName: firstName
                middleName
                familyName: lastName
                suffix
              }
              isBlindOrDisabled
              isLongTermCare
              isSmoker
              lastTobaccoUseDate
              planName

              # Race Questions
              race
              otherRaceText
              isHispanicOrigin
              ethnicity
              otherEthnicityText

              # Member Questions
              isFosterCare1825
              fosterCareState
              isMedicaidDuringFosterCare
              fosterCareEndAge
              isPregnant
              incarcerationType
              babyDueQuantity
              isFullTimeStudentStatus
              uiQuestionsToDisplay

              # SEP Events
              isNoChanges
              isQualifyingCoverageLast60Days
              isLostCoverageLast60Days
              isWillLoseCoverageNext60Days
              coverageLostDate
              coverageLostDateFuture
              isReleasedFromIncarceration
              whenReleasedFromIncarceration
              isMarriedLast60Days
              whenMarried
              hasCoverage60DaysBeforeMarriage
              isGainDependent
              whenBecomeADependent
              lawfulPresenceGrantDate
              isMovedFromOutsideUs
              hasLivedOutsideUSLast60DaysBeforeMarriage
              isLivedOutsideUsLast60Days
              isMoved
              whenRelocated
              hasCoverageLast60DaysBeforeRelocation
              previousCountyLivedName
              previousStateCode
              previousZipcode

              # Income Questions
              incomeThisMonth
              hasDeductions

              annualTaxIncome {
                isVariableIncome
                isUnknownIncome
                incomeAmount
                isIncomeLessExplained
                taxHouseholdIncomeDiscrepancyDescriptionText
                taxHouseholdIncomeDifferenceReasonType
                variableIncomeDescriptionText
                annualIncomeExplanationRequired
                attestedAnnualizedAPTCIndividualIncomeAmount
              }

              incomeSources {
                incomeID
                type
                incomeAmount
                incomeFrequencyType
                employerName
                employerPhoneNumber
                employerAddress {
                  street1
                  street2
                  city
                  state
                  zip
                  zip4
                  country
                  countyfips
                  coordinates {
                    latitude
                    longitude
                  }
                }
                employerIdentificationNumber
                averageWeeklyWorkHours
                averageWeeklyWorkDays
                selfEmploymentIncomeDescription
                incomeDescription
                expirationDate
                tribalIncomeAmount
                isEstimatedForAptc
                incomeDifferenceReason
                otherIncomeDifferenceReason
                otherIncomeDescription
                otherDeductionDescription
                jobIncomeExplanationRequired
                educationExpenses
                incomeStateExchangeFields {
                  isPaidBeforeJan1
                  dateIncomeBegan
                  isPresentlyEmployed
                  dateLastGotIncome
                  isExpectedToEndIn4Months
                  dateIncomeEnded
                  incomeName
                }
              }
              deductions {
                incomeID
                incomeSourceType
                type: incomeSourceType
                incomeAmount
                incomeFrequencyType
                otherDeductionDescription
              }

              # Medicaid Questions
              isDeniedMedicaidLast90Days
              isMedicaidEndingSoon
              isMedicaidDenied
              medicaidDeniedDate
              isMedicaidDeniedDueToImmigration
              hasLivedInUS5Years
              isImmigrationStatusChangeSinceDenied

              # Other Coverage
              coverageEndedReason
              coverageEndedReasonText
              isAppliedDuringOeOrLifeChange
              isEnrolledInHealthCoverage
              otherCoverages {
                insuranceMarketType
                insurancePolicyNumber
                insurancePolicyMemberID
                insurancePlanName
              }

              # Later Phases
              isAttestedHeadOfHousehold
              isTaxFiler
              taxReturnFilingStatusType
              isClaimsDependent
              isClaimingTaxFilerNotOnApplication
              isVeteran
              hasAbsentParent
              isNoHomeAddress
              isTemporaryOutOfState
              personTrackingNumber
              isTaxDependent
              isInfoChangeSinceMedicaidEnded
              isMedicaidEnd
              medicaidEndDate
              isEnrolledInIchra
              isOfferedIchra
              hraOffers {
                hraType
                startDate
                endDate
                noticeDate
                isEnrolledInOfferFromSameEmployer
                isEnrolledInOfferFromSameEmployerUntilStartDate
                primaryInsuredMemberIdentifier
                isPrimaryInsuredMemberNotInTaxHh
                employeeSelfOnlyOfferAmount
              }
              isPrefill
              parent1WeeklyWorkHourQuantity
              parent2WeeklyWorkHourQuantity
              isUnpaidBill
              isCoveredDependentChild
              isCoverageEnded
              isStateHealthBenefit

              # Eligibility Indicators
              isRequestingMedicaidDetermination
              aptcEligibility {
                startDate
                endDate
                amount
              }
              csrEligibility {
                startDate
                endDate
                csrLevel
              }
              exchangeEligibility {
                startDate
                endDate
              }
              initialEnrollmentEligibility {
                startDate
                endDate
                earliestQhpEffective
                latestQhpEffective
              }
              sepEligibility {
                startDate
                endDate
                earliestQhpEffective
                latestQhpEffective
                sepReason
                verificationPending
              }
              chipEligibility {
                startDate
                endDate
              }
              medicaidEligibility {
                startDate
                endDate
              }
              metalLevelOption {
                allowedMetalLevels
              }

              # Statuses
              preliminaryMedicaidStatus
              preliminaryEmergencyMedicaidStatus
              preliminaryAPTCStatus
              preliminaryCHIPStatus
              qhpResidencyStatus
              qhpLawfulPresenceStatus
              isLawfulPresenceStatus
              chipWaitingPeriodStatus
              chipWaitingPeriodStatusReason
              ssnStatus
              ssnStatusReason
              citizenshipStatus
              citizenshipStatusReason
              incarcerationStatus
              incarcerationStatusReason
              preliminaryQHPStatus
            }
          }
        }
      }
    }
  }
`;

const formatter = (data) => {
  const get = access(data);

  const {
    application,
    members,
    parsedQuestions,
    renderMemberQuestions,
    renderImmigrationQuestions,
  } = handleApplicationData(
    get('viewer.health.enrollment.healthApplication'),
    get('viewer.health.enrollment.members') || [],
  );

  const selfFullName = getFullName(members?.find((d) => d?.relation === 'SELF'));
  const pathwayType = get('viewer.health.enrollment.pathwayType');

  // are any dependents prelim aptc eligible
  const isPrelimAptcEligible = members?.some((dep) => dep?.preliminaryAPTCStatus === 'YES');

  // are any dependents prelim QHP eligible
  const isPrelimQHPEligible = members?.some((dep) => dep?.preliminaryQHPStatus === 'YES');

  // are any dependents Medicaid Eligible or Emergency Medicaid Eligible
  const isPrelimMedicaidOrEmergencyMedicaidEligible = members?.some(
    (dep) =>
      dep?.preliminaryMedicaidStatus === 'YES' ||
      (dep?.preliminaryEmergencyMedicaidStatus === 'YES' &&
        dep?.preliminaryMedicaidStatus === 'NO'),
  );

  // are there any children on this application who have a parent living outside the home
  const childDependents = members?.filter((d) => d?.relation === 'CHILD');
  const anyDependentsWithParentOutsideHome = childDependents?.some((dep) => !!dep?.hasAbsentParent);

  return {
    data,
    application,
    pathwayType,
    members,
    selfFullName,
    isPrelimAptcEligible,
    isPrelimMedicaidOrEmergencyMedicaidEligible,
    isPrelimQHPEligible,
    anyDependentsWithParentOutsideHome,
    isRequestingFinancialAssistance: application?.isRequestingFinancialAssistance,
    applicationID: application?.id,
    applicationPhase: application?.applicationPhase || 'ONE',
    stateDeprivationRequirementRetained: application?.stateDeprivationRequirementRetained,
    coverageYear: application?.coverageYearNumber,
    coverageState: application?.coverageState,
    stateCHIPWaitingPeriod: application?.stateCHIPWaitingPeriod,
    parsedQuestions,
    renderMemberQuestions,
    renderImmigrationQuestions,
  };
};

export default {
  document: HEALTH_APPLICATION,
  formatter,
};
