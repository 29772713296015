import React from 'react';
import { View } from 'react-native';

import { precisionRound } from '@util';
import { useDeprecatedQuery } from '@data';
import { open, routes } from '@navigate';
import { Text, useTheme, Stepper, Skeleton, Pressable } from '@uikit';

import MonthlyContribution from './MonthlyContribution';

const ResultCard = ({
  percent,
  percentLegend,
  headerText,
  isEditing,
  input = {},
  adjustmentError,
  baseStyleOverride,
  isLoading,
  disabled,
  monthlyContribution,
  estimatedIncome,
  use1099Income,
  editIncomeEnabled,
  openBreakdown,
  showContribution = true,
  yearly,
  goalID,
  inline,
}) => {
  const { theme } = useTheme();
  const { onChange, value } = input;
  const roundedValue = precisionRound(value, 2);

  const { below100Percent } = useDeprecatedQuery('validateGoal', {
    fetchPolicy: 'network-only',
    goalID,
    percentage: roundedValue,
  });

  const monthlyAmount = (estimatedIncome / 12) * value;
  const yearlyAmount = estimatedIncome * value;

  const toggleUpdate = () => {
    open(routes.EDIT_USER_DETAILS, {
      fieldName: 'UserIncomeField',
    });
  };

  const percentageBlock = (
    <View style={[theme.centerText, theme.justifyCenter, theme.ySpace3, theme.topSpace4]}>
      <Stepper
        large
        loading={isLoading}
        editable={!!isEditing}
        disabled={!!disabled || !!adjustmentError}
        disabledMax={!below100Percent}
        value={Math.round((isEditing ? roundedValue : percent) * 100)}
        max={99}
        min={1}
        suffix="%"
        onChange={(newValue) => onChange(newValue / 100)}
      />
      <Text align="center" weight="medium">
        {percentLegend}
      </Text>
      <Text align="center" size="small" color="error">
        {adjustmentError}
      </Text>
    </View>
  );

  return (
    <View
      style={[
        !inline && theme.cardBg,
        !inline && theme.cardShadow,
        theme.hideOverflow,
        theme.justifyCenter,
        baseStyleOverride,
        theme.largeCorners,
      ]}
    >
      {!!headerText && isEditing && (
        <Pressable handleOnPress={openBreakdown}>
          <View
            style={[theme.recommendedBg, theme.alignCenter, theme.centerText, theme.transition]}
          >
            <Text center size="h6" inline color="recommended">
              {headerText}
            </Text>
          </View>
        </Pressable>
      )}
      <View style={[theme.bottomSpace3, theme.xSpace4]}>
        <View>{percentageBlock}</View>
        {showContribution && (
          <Skeleton loading={isLoading}>
            <MonthlyContribution
              yearly={yearly}
              subtle={inline}
              align="center"
              monthlyContribution={monthlyAmount ?? monthlyContribution ?? 0}
              yearlyContribution={yearlyAmount}
              estimatedIncome={estimatedIncome}
              use1099Income={use1099Income}
              editIncomeEnabled={editIncomeEnabled}
              onToggle={toggleUpdate}
            />
          </Skeleton>
        )}
      </View>
    </View>
  );
};

export default ResultCard;
