// @ts-nocheck
import React, { useRef } from 'react';
import { debounce } from 'lodash';
import { useQuery, queries, HealthExplorerQueryData, useDeprecatedMutation } from '@data';
import { useMarketplace, useSelectable } from '@hooks';
import { useCopy } from '@app/utils';
import { routes, ScreenDefinition } from '@navigate';
import { TextField } from '@app/forms_old/inputs';
import Search from '@svg/search.svg';

// relative imports
import { DrugResult, SelectedDrugs } from '@common';
import { ExplorerLayout } from '@layouts';

/**
 * Explorer RX view
 */
const ExplorerRX = ({ handleNext }) => {
  const { select, deselect, isSelected, selected, values } = useSelectable();
  const { c } = useCopy('catch.healthExplorer.explorerRX');
  const [upsert] = useDeprecatedMutation('upsertHealthPreference');

  const { loading, data } = useQuery<HealthExplorerQueryData>(queries.HEALTH_EXPLORER, {
    fetchPolicy: 'cache-first',
  });

  const { setSearchTerm, results } = useMarketplace({
    type: 'drugs',
    zip: data?.viewerTwo?.healthExplorerData?.zipcode,
  });

  const drugsPayload =
    Object.values(values)?.length > 0
      ? Object.values(values)?.map((v) => ({
          rxcui: v.data.rxcui,
          name: v.data.name,
        }))
      : null;

  const setSearchTermDebounced = useRef(
    debounce((nativeEvent) => setSearchTerm(nativeEvent?.text), 400),
  );

  return (
    <ExplorerLayout
      title={c('title')}
      disabled={selected?.length === 0}
      loading={loading}
      onNext={() => {
        upsert({
          variables: {
            input: {
              drugsWanted: drugsPayload,
            },
          },
        });

        handleNext();
      }}
      canSkip
      onSkip={handleNext}
    >
      <SelectedDrugs selected={selected} values={values} deselect={deselect} />
      <TextField
        sensitive
        fullWidth
        label="Prescription"
        placeholder="Search a medication"
        svg={Search}
        onChange={({ nativeEvent }) => setSearchTermDebounced.current(nativeEvent)}
      />

      {results?.map((drug, i) => (
        <DrugResult
          key={drug?.groupID}
          isSelected={isSelected}
          select={(drug) => {
            select(drug.rxcui, drug);
            setSearchTerm('');
          }}
          drug={drug}
          isLast={i === results?.length - 1}
        />
      ))}
    </ExplorerLayout>
  );
};

export const ExplorerRXView: ScreenDefinition = {
  name: routes.EXPLORER_PRESCRIPTIONS,
  component: ExplorerRX,
  options: {},
};
