import { ApolloClient, InMemoryCache, HttpLink, from } from '@apollo/client';
import Env from '@app/utils/env';

const fetcher = (...args) => {
  return window.fetch(...args);
};

const httpLink = new HttpLink({
  uri: Env.graphcms.uri,
  fetch: fetcher,
});

const client = new ApolloClient({
  link: from([httpLink]),
  cache: new InMemoryCache(),
});

export default client;
