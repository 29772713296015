import React from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';

import { routes, close } from '@navigate';
import { FormConfig } from '@f';
import { FieldConfig } from '@app/types';
import { fields } from '@app/config';
import { BasicFormBlueprint } from '@app/blueprints';

export interface BeneficiaryValues {
  firstName: string;
  lastName: string;
  dob: string;
  ssn: string;
  sharePercentage: number;
  relationship: 'SPOUSE' | 'OTHER';
}

const QUERY = gql`
  query BeneficiariesQuery {
    viewer {
      id
      wealthAccount {
        id
        primaryBeneficiaries {
          firstName
          lastName
          relationship
          dob
          ssn
          sharePercentage
        }
      }
    }
  }
`;

const MUTATION = gql`
  mutation UpsertWealthAccount($input: UpsertWealthAccountInput!) {
    upsertWealthAccount(input: $input) {
      id
    }
  }
`;

/**
 *
 *
 * Upsert beneficiary
 * (gets propogated to the retirement account)
 */
const UpsertBeneficiary = () => {
  const { data, loading } = useQuery(QUERY);
  const [update, { loading: submitting }] = useMutation(MUTATION);

  const _fields: Array<FieldConfig> = [
    {
      name: 'firstName',
      type: 'text',
      label: 'First name',
      required: true,
      grouped: true,
      format: (name) => name,
    },
    {
      name: 'lastName',
      type: 'text',
      label: 'Last name',
      required: true,
      grouped: true,
      format: (name) => name,
    },
    { ...fields.DOB, grouped: true, required: true },
    { ...fields.SSN, grouped: true, label: 'SSN', required: true },
    {
      name: 'relationship',
      type: 'option',
      label: 'Relationship',
      required: true,
      horizontal: true,
      options: [
        {
          label: 'Spouse',
          value: 'SPOUSE',
        },
        {
          label: 'Other',
          value: 'OTHER',
        },
      ],
    },
  ];

  const formConfig: FormConfig<BeneficiaryValues> = {
    fields: _fields,
    initialValues: data?.viewer?.wealthAccount?.primaryBeneficiaries[0] || {},
    onSubmit: (values) => {
      update({
        refetchQueries: ['BeneficiariesQuery', 'UserDetailsQuery'],
        onCompleted: close,
        variables: {
          input: {
            id: data?.viewer?.wealthAccount?.id,
            contingentBeneficiaries: [],
            primaryBeneficiaries: [
              {
                ...values,
                sharePercentage: 100,
              },
            ],
          },
        },
      });
    },
  };

  return (
    <BasicFormBlueprint
      loading={loading}
      submitting={submitting}
      formConfig={formConfig}
      title="Beneficiary"
      subtitle="Retirement"
    />
  );
};

export const UpsertBeneficiaryView = {
  name: routes.UPSERT_BENEFICIARY,
  component: UpsertBeneficiary,
  options: { layout: 'sheet', accentColor: 'retirement' },
};
