import React from 'react';
import { useDeprecatedMutation } from '@data';
import { useCopy } from '@app/utils';
import { routes, ScreenDefinition } from '@navigate';
import { OptionGroup } from '@uikit';
import { ExplorerLayout } from '@layouts';

const ExplorerRisk = ({ handleNext }) => {
  const { c } = useCopy('catch.healthExplorer.explorerRisk');
  const [upsert] = useDeprecatedMutation('upsertHealthPreference');

  const options = [
    {
      primary: c('premiumPrimary'),
      secondary: c('premiumSecondary'),
      payload: 'LOW_PREMIUM',
    },
    {
      primary: c('balancedPrimary'),
      secondary: c('balancedSecondary'),
      payload: 'BALANCED',
    },
    {
      primary: c('deductiblePrimary'),
      secondary: c('deductibleSecondary'),
      payload: 'LOW_DEDUCTIBLE',
    },
  ];

  return (
    <ExplorerLayout
      title="What's your top priority?"
      subtitle="Spending more up front can protect you from sky-high medical bills in a worst case scenario"
      canSkip
      disabled={true}
      onSkip={handleNext}
      onNext={handleNext}
    >
      <OptionGroup
        options={options?.map((o, i) => {
          return {
            label: o.primary,
            description: o.secondary,
            selected: false,
            value: o.payload,
          };
        })}
        onPress={(val) => {
          upsert({
            variables: {
              input: {
                financialPreference: val,
              },
            },
          });

          handleNext();
        }}
      />
    </ExplorerLayout>
  );
};

export const ExplorerRiskView: ScreenDefinition = {
  name: routes.EXPLORER_RISK_TOLERANCE,
  component: ExplorerRisk,
  options: {},
};
