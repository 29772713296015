import React from 'react';
import { View } from 'react-native';

import { TextAlign } from '@types';
import { HR } from '@uikit/components/HR';
import { s } from '@app/_ui-kit/theme/static';
import { useResponsive, useTheme } from '@app/_ui-kit';

interface Props {
  horizontal?: boolean;
  reverse?: boolean;
  spacing?: '+gap' | '0' | '0b' | '1' | '1b' | '2' | '3' | '4';
  separatorComponent?: boolean;
  mobileGap?: boolean;
  children: any;
  style?: any;
  itemStyle?: any;
  flexItem?: number;
  group?: boolean;
  align?: TextAlign;
  full?: boolean;
  wide?: boolean;
  sensitive?: boolean;
  debug?: boolean;
  topSpace?: boolean;
  onSheet?: boolean; // comment in progress; has to do with mobileGap in dark mode
}

export const Stack = ({
  horizontal,
  spacing = '0',
  group = false,
  separatorComponent,
  mobileGap,
  reverse,
  children,
  style,
  itemStyle,
  align,
  debug,
  sensitive,
  flexItem,
  topSpace,
  bottomSpace,
  onSheet,
  ...props
}: Props) => {
  const { theme, colorScheme } = useTheme();
  const { isMobile, isNative } = useResponsive();

  const stackElements = React.Children.toArray(children);
  const startDirection = horizontal ? 'left' : 'top';
  const endDirection = horizontal ? 'right' : 'bottom';

  return (
    <View
      dataSet={sensitive ? { private: 'redact' } : {}}
      style={[
        horizontal ? s?.row : s?.col,
        reverse && (horizontal ? s?.reverseRow : s?.reverseCol),
        !horizontal && { width: '100%' },
        align === 'center' ? { alignItems: 'center' } : {},
        topSpace && theme.topSpace2,
        bottomSpace && theme.bottomSpace2,
        style,
      ]}
    >
      {stackElements.map((element: any, i) => {
        const isFirst = reverse ? i === stackElements.length - 1 : i === 0;
        const isLast = reverse ? i === 0 : i === stackElements.length - 1;
        const styledElement = (
          <View
            key={element.key || element.testID || i}
            style={[
              !isFirst && s[`${startDirection}Gutter${spacing}`],
              !isLast && s[`${endDirection}Gutter${spacing}`],
              itemStyle,
              flexItem === i ? { flex: 1 } : {},
            ]}
          >
            {React.cloneElement(element, {
              ...props,
            })}
          </View>
        );

        // Show the divider in between when defined
        if (!isLast && (separatorComponent || mobileGap) && React.isValidElement(element)) {
          return [
            styledElement,
            <HR
              key={`${element.key}-divider`}
              height={mobileGap && isMobile ? 8 : 1}
              color={
                isMobile && isNative && mobileGap
                  ? onSheet && colorScheme === 'dark'
                    ? 'page'
                    : 'pageLight'
                  : 'border'
              }
              mobileSnap={isMobile}
            />,
          ];
        }

        // Otherwise, just return elements
        return styledElement;
      })}
    </View>
  );
};

export default Stack;
