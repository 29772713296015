import React, { useMemo } from 'react';
import { Prompt, PromptContext } from '@app/types';
import { Asset, Link, useResponsive } from '@uikit';
import { Carousel, Layout, Section, Stack } from '@layouts';
import { brandColors } from '@app/config'; //previously also imported prompts from GraphCMS
import { navigate, routes } from '@app/navigate';
import { CatchTheme } from '@app/_ui-kit/themes/catch';
import { paycheckSource } from '@app/utils/format/income';
import { PromptCard, IncomePromptCard, IncomeWidget, GoalWidget, HealthWidget } from '@common';
import { HomeQueryData } from '@data';

// icons
import SkipSvg from '@svg/catch-skip.svg';
import RollSvg from '@svg/catch-roll.svg';
import { Platform } from 'react-native';

interface PromptProps {
  prompts?: Array<Prompt>;
  data?: HomeQueryData;
}

const Outer = ({ stacked, children, isIncome, isMobile }) =>
  stacked ? (
    <Layout mobileSnap={isMobile}>
      <Stack spacing="+gap">{children}</Stack>
    </Layout>
  ) : (
    <Carousel
      itemWidth={isIncome ? CatchTheme.constants.promptWidthSm : CatchTheme.constants.promptWidthLg}
      spacing="1"
      fullWidth
    >
      {children}
    </Carousel>
  );

const PromptSection = ({
  title,
  prompts,
  stacked,
  style,
}: {
  title?: string;
  prompts: Array<Prompt>;
  stacked?: boolean;
  style?: any;
}) => {
  const { isMobile } = useResponsive();
  return (
    <Section color="incomeLight" title={title || ''} style={{ width: '100%', flex: 1, ...style }}>
      <Outer stacked={stacked} isIncome={prompts?.[0]?.name === 'PAYCHECK'} isMobile={isMobile}>
        {prompts?.map((cardType, idx) => {
          if (cardType.name === 'PAYCHECK') {
            const paycheck = cardType?.data?.paycheck;
            if (!paycheck) return null;

            const title = paycheckSource(paycheck.source);
            const color = brandColors[title]?.overlay;

            return (
              <IncomePromptCard
                idx={idx}
                name="PAYCHECK"
                hex={color}
                context="INCOME"
                treatment="INCOME"
                key={'PAYCHECK_' + paycheck.id || idx}
                title={title}
                amount={paycheck.amount}
                date={paycheck.date}
                disabled={cardType?.data?.disabled}
                asset={{
                  ...Asset.configureSource(paycheck?.source?.taggedSource),
                  size: 'lg',
                }}
                actions={[
                  {
                    label: 'Skip',
                    type: 'ROUTE',
                    route: routes.PAYCHECK_SKIPPED,
                    routeData: { paycheckID: paycheck?.id, paycheck },
                    icon: {
                      svg: SkipSvg,
                      color: 'text',
                    },
                  },
                  {
                    label: 'Roll',
                    type: 'ROUTE',
                    icon: {
                      svg: RollSvg,
                      color: 'text',
                    },
                    route: routes.PAYCHECK_INCOME,
                    routeData: {
                      paycheckID: paycheck?.id,
                      paycheck,
                      color: color,
                    },
                  },
                ]}
              />
            );
          }

          return (
            <PromptCard
              size={stacked ? 'ALERT' : 'CARD'}
              key={cardType.name + cardType?.forEach ? idx : undefined}
              wideMobile={prompts?.length === 1 && !stacked}
              {...cardType}
            />
          );
        })}
      </Outer>
    </Section>
  );
};
const HomePrompts = ({ prompts, data }: PromptProps) => {
  const grouped: { [context: PromptContext]: Array<Prompt> } = useMemo(() => {
    if (!prompts) return {};

    return prompts?.reduce((groups, prompt) => {
      const existing = groups[prompt.context] || [];

      return {
        ...groups,
        [prompt.context]: [...existing, prompt],
      };
    }, {});
  }, [prompts]);

  return (
    <Layout bottomSpace>
      <Stack spacing="1">
        {grouped.BLOCKING_CLOCK && (
          <PromptSection
            style={Platform.OS === 'web' && { paddingTop: 0 }}
            prompts={[...(grouped.BLOCKING_CLOCK || [])]}
          />
        )}
        {(grouped.ACCOUNT || grouped.BLOCKING) && (
          <PromptSection
            style={Platform.OS === 'web' && { paddingTop: 0 }}
            stacked
            prompts={[...(grouped.ACCOUNT || []), ...(grouped.BLOCKING || [])]}
          />
        )}

        {grouped.HEALTH && <PromptSection title="Open Enrollment" prompts={grouped.HEALTH} />}
        {grouped.STATUS && <PromptSection stacked prompts={grouped.STATUS} />}
        {/* {grouped.INCOME && <PromptSection title="New income" prompts={grouped.INCOME} />} */}

        <Section>
          <Stack spacing="1">
            {IncomeWidget.shouldDisplay(data) && <IncomeWidget data={data} />}
            {GoalWidget.shouldDisplay(data) && <GoalWidget data={data} />}
            {HealthWidget.shouldDisplay(data) && <HealthWidget data={data} />}
          </Stack>
        </Section>

        {grouped.TODO && <PromptSection title="Finish Setup" prompts={grouped.TODO} />}
        <Section>
          <Stack horizontal spacing="2">
            <Link size="sm" color="subtler" onPress={() => navigate(routes.ANNUAL_REVIEW)}>
              2022 in Review
            </Link>
            <Link size="sm" color="subtler" onPress={() => navigate(routes.STATEMENTS)}>
              Statements
            </Link>
          </Stack>
        </Section>
      </Stack>
    </Layout>
  );
};

export default HomePrompts;
