import React, { useState } from 'react';

import { ConfirmationHeader } from '@common';
import { Loading, Text, LineItem, Button, FinePrint } from '@uikit';
import { Layout, Toolbar, Stack } from '@layouts';
import { useCopy, copy, currentYear, useAppReview } from '@app/utils';
import { useTaxPayment } from '@hooks';
import { routes, close } from '@navigate';
import { useForm } from '@f';
import ConfirmationBlueprint from '@app/blueprints/ConfirmationBlueprint';
import { Prompts } from '@app/config';
import { useDeprecatedMutation } from '@app/data';
import { taxPaymentEntity } from '@app/utils/format/status';

/**
 * Tax Payments Confirmation
 */
const TaxPaymentConfirm = ({
  quarterNum,
  amount,
  taxYear,
  fullName,
  taxGoalID,
  jurisdiction,
  hasAuto,
}) => {
  const { c, $ } = useCopy('catch');
  const [isOpen, setOpen] = useState(false);
  const { requestReview } = useAppReview({ location: 'tax payment' });
  const { sendPayment, loading, called, data, error } = useTaxPayment({
    amount,
    quarter: `Q${quarterNum}`,
    taxYear: taxYear || currentYear,
    goalID: taxGoalID,
    jurisdiction,
  });

  const [enableAuto] = useDeprecatedMutation('upsertGoal', {
    variables: {
      input: { slug: 'taxes', autoTax: true, authorizedAboundEftpsEnrollment: true },
    },
    refetchQueries: ['TaxGoal'],
    onCompleted: () => requestReview(), //request an app store review upon toggling auto tax on after making tax payment
  });

  const form = useForm({
    loading: false,
    disabled: loading,
    initialValues: {},
    fields: [],
    onSubmit: (values) => {
      sendPayment({ signatureText: fullName });
    },
  });

  return (
    <ConfirmationBlueprint
      loading={loading}
      called={called}
      error={error}
      accentColor="taxes"
      onSuccess={() => close()}
      titles={{
        loading: 'Loading',
        done: c('payments.taxPayments.confirmationTitle', {
          amount: $(amount),
        }),
      }}
      subtitles={{
        loading: '',
        done: c('payments.taxPayments.confirmationSubtitle'),
      }}
      followUp={
        !hasAuto && {
          ...Prompts.ACTIVATE_AUTO_TAX,
          title: `Autopay taxes`,
          subtitle: `Send quarterly payments automatically`,
          action: {
            label: 'Autopay',
            type: 'CUSTOM',
            onAction: () => {
              enableAuto();
              close();
            },
          },
        }
      }
    >
      <Layout.Scroll fullHeight color="sheet">
        {loading ? (
          <Loading accentColor="taxes" />
        ) : (
          <>
            <ConfirmationHeader amount={amount} />
            <Layout margins topSpace>
              <Stack separatorComponent spacing="1">
                <LineItem left={c('payments.taxPayments.amountLabel')} right={$(amount)} />
                <LineItem left="To" right={taxPaymentEntity(jurisdiction)} />
              </Stack>
            </Layout>
          </>
        )}
      </Layout.Scroll>
      <Toolbar type="stacked">
        <FinePrint
          items={[
            c('payments.taxPayments.disclaimer.ssn'),
            c('payments.taxPayments.electronicallySign', {
              link: (
                <Text
                  size="fp"
                  weight="medium"
                  color={!isOpen && 'link'}
                  onPress={() => setOpen(!isOpen)}
                >
                  {!isOpen ? 'See more' : c('payments.taxPayments.disclaimer.taxBurden')}
                </Text>
              ),
            }),
          ]}
        />
        <Button
          accentColor="taxes"
          onPress={form.submitForm}
          disabled={form.disableSubmit || loading}
        >
          {c('MoneyMovement.toolbar.taxPayments')}
        </Button>
      </Toolbar>
    </ConfirmationBlueprint>
  );
};

/**
 * @todo E-3287
 */
export const TaxPaymentConfirmView = {
  name: routes.TAX_PAYMENT_CONFIRM,
  component: TaxPaymentConfirm,
  options: {
    title: ({ quarterNum, taxYear }) =>
      copy.format(`catch.payments.taxPayments.navTitle`, {
        quarterNum,
        taxYear,
      }),
  },
};
