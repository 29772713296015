import { AutomationType, IncomeWorkType } from '@app/types';
import gql from 'graphql-tag';

export interface IncomeSourceInput {
  incomeSourceID: string;
  automationType: AutomationType;
  workType?: IncomeWorkType;
  userHandlesActivation: true; // should be deprecated by platform
}

export interface TransactionTagInput {
  catchTransactionTagID: string;
  automationType: AutomationType;
  workType?: IncomeWorkType;
  userHandlesActivation: true; // should be deprecated by platform
}

export interface UpsertIncomeAutomationRulesVars {
  rules: Array<IncomeSourceInput | TransactionTagInput>;
}

export interface UpsertIncomeAutomationRulesResponse {
  upsertIncomeAutomationRules: Array<{
    id: string;
  }>;
}

export const UpsertIncomeAutomationRules = gql`
  mutation UpsertIncomeAutomationRules($rules: [IncomeAutomationRuleInput!]!) {
    upsertIncomeAutomationRules(input: { upsertIncomeAutomationRuleInputs: $rules }) {
      incomeAutomationRules {
        id
        automationType
        workType
        source {
          id
          ruleID
          text
          sourceDetail
          incomeAutomationRule {
            id
            automationType
            workType
          }
          taggedSource {
            id
            name
            logo {
              url
            }
          }
        }
      }
    }
  }
`;
