import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';

import { TIER_RESTRICTIONS } from '@app/config';
import { Tier, UseTierReturn } from '@app/types';

const QUERY = gql`
  query UserTier {
    viewer {
      id
      user(nocache: true) {
        id
        payrollProductTier
      }
    }
    viewerTwo {
      id
      accountStatus {
        id
        isLocked
      }
    }
  }
`;

/**
 *
 * @todo use for more things?
 *
 */
export const useTier = (): UseTierReturn => {
  const { data, loading } = useQuery(QUERY, {
    fetchPolicy: 'cache-and-network',
  });
  const tier: Tier = data?.viewer?.user?.payrollProductTier || 'TIER_1';

  return {
    tier,
    loading,
    restrictions: TIER_RESTRICTIONS[tier],
    maxTransfer: TIER_RESTRICTIONS[tier].TRANSFER_LIMIT,
    locked: data?.viewerTwo?.accountStatus?.isLocked,
  };
};
