import React, { useMemo, useState, useCallback, useRef } from 'react';
import { ApplicationMember, Copy } from '@types';
import { FormConfig, Fields, useForm } from '@f';
import { Loading, Button } from '@uikit';
import { pop } from '@navigate';
import { HelpTextInline } from '@app/_ui-kit/components/HelpText';
import { MembersAvatars } from '@app/partial';

import { default as SplitLayout } from '@app/layouts/SplitLayout';
import { default as Layout } from '@app/layouts/Layout';
import { default as Toolbar } from '@app/layouts/Toolbar';
import { default as Stack } from '@app/layouts/Stack';

export interface Member extends ApplicationMember {
  renderUnemploymentQuestion: boolean;
}

interface MultiMemberSplitFormBlueprintProps<TFormValues> {
  loading: boolean; // shows a loader during initial load
  submitting: boolean; // while submitting the form, disable all buttons and inputs
  title: Copy | ((member: TFormValues) => Copy);
  subtitle: Copy | ((member: TFormValues) => Copy);
  members: Array<Member>;
  fields: FormConfig<TFormValues>['fields'];
  data?: object; // data to share with all initial values

  getInitialValues: (member: Member) => TFormValues;
  onNext: (values: TFormValues) => void;
  onComplete: (options?: { reset: () => void }) => void;
  setCurrentMember?: (member: Member) => void;
  precontent?: React.ReactNode | (({ member }: { member: Member }) => React.ReactNode);
}

const relationWeights = {
  SELF: 3,
  SPOUSE: 2,
  CHILD: 1,
};

const MultiMemberSplitFormBlueprint = <
  TFormValues extends Record<string, any> = Record<string, any>,
>({
  loading, // for initial load
  submitting, // for example when submitting the form
  title,
  subtitle,
  members,
  fields,
  data = {},
  getInitialValues,
  onNext,
  onComplete,
  setCurrentMember,
  precontent,
}: MultiMemberSplitFormBlueprintProps<TFormValues>) => {
  const [inProgress, setLoading] = useState<boolean>(false);

  const sortedMembers = useMemo(() => {
    return members.sort((a, b) =>
      relationWeights[a?.relation] > relationWeights[b?.relation] ? -1 : 1,
    );
  }, [members]);

  // functionality for handling scroll
  const scrollWindow = useRef();
  const resetScroll = () => {
    if (!!scrollWindow && scrollWindow?.current) {
      scrollWindow.current.scrollTo({ y: 0 });
    }
  };

  // keeps track of current index/application member
  const [currentIndex, _setIndex] = useState(0);

  const setIndex = useCallback((index) => {
    setCurrentMember?.(sortedMembers[index]);
    _setIndex(index);
  }, []);

  const currentMember = useMemo(() => {
    return sortedMembers[currentIndex];
  }, [currentIndex, sortedMembers]);

  // computes the initial valuesx
  const initialValues = useMemo(() => {
    return { ...data, ...getInitialValues(currentMember) };
  }, [currentMember, getInitialValues]);

  const next = useCallback(
    async (values) => {
      if (currentIndex < sortedMembers.length - 1) {
        onNext(values);
        setIndex(currentIndex + 1);
        resetScroll();
      } else {
        setLoading(true);
        await onNext(values);
        onComplete({ reset: () => setIndex(0) });
        setLoading(false);
      }
    },
    [setIndex, currentIndex, sortedMembers],
  );

  const back = useCallback(() => {
    if (currentIndex > 0) {
      setIndex(currentIndex - 1);
      resetScroll();
    } else {
      pop();
    }
  }, [currentIndex]);

  const form = useForm<TFormValues>({
    loading,
    disabled: submitting || inProgress,
    fields,
    initialValues,
    onSubmit: next,
  });

  const computedTitle = useMemo(() => {
    if (typeof title === 'function') return title(currentMember);
    return title;
  }, [title, currentMember]);

  const computedSubtitle = useMemo(() => {
    if (typeof subtitle === 'function') return subtitle(currentMember);
    return subtitle;
  }, [subtitle, currentMember]);

  return (
    <SplitLayout
      scrollRef={scrollWindow}
      gradient="coverageLight"
      handleContent="HIDDEN"
      toolbar={
        <Toolbar onBack={back}>
          <Button inherit testID="next" onPress={form.submitForm} disabled={form.disableSubmit}>
            Next
          </Button>
        </Toolbar>
      }
    >
      <>
        <Stack spacing="2">
          <MembersAvatars setIndex={setIndex} currentIndex={currentIndex} members={sortedMembers} />
          <Layout.Header
            title={computedTitle}
            subtitle={computedSubtitle}
            titleSize="form"
            bottomSpace
          />
        </Stack>
        {loading ? (
          <Loading />
        ) : (
          <>
            {precontent && typeof precontent === 'function'
              ? precontent({ member: currentMember })
              : precontent}
            <Fields form={form} fields={fields} />
          </>
        )}
      </>

      {form.currentHelpText && (
        <HelpTextInline content={form.currentHelpText} offset={form?.layout?.y} />
      )}
    </SplitLayout>
  );
};

MultiMemberSplitFormBlueprint.options = SplitLayout.options;
export default MultiMemberSplitFormBlueprint;
