import React from 'react';

import { OnboardingFormBlueprint } from '@blueprints';
import { routes } from '@navigate';
import { useCopy } from '@app/utils';
import { FormConfig } from '@app/forms';
import { fields } from '@app/config';
import {
  useQuery,
  queries,
  RetirementQueryData,
  useMutation,
  mutations,
  UpdateUserVars,
  UpdateUserResponse,
} from '@data';

interface RetirementSavingsValues {
  externalSavings: number;
  dob: string;
}

const RetirementCurrentSavings = ({ handleNext, updateRetirement, submitting: upserting }) => {
  const { c } = useCopy('catch.module.retirement.SavingsView');

  const { loading, data } = useQuery<RetirementQueryData>(queries.RETIREMENT, {
    fetchPolicy: 'cache-first',
  });

  const [update, { loading: submitting }] = useMutation<UpdateUserResponse, UpdateUserVars>(
    mutations.UPDATE_USER,
  );

  const formConfig: FormConfig<RetirementSavingsValues> = {
    initialValues: { externalSavings: data?.viewer?.goal?.externalSavings },
    fields: !data?.viewer?.user?.dob
      ? [fields.EXTERNAL_SAVINGS, { ...fields.DOB, required: true }]
      : [fields.EXTERNAL_SAVINGS],
    onSubmit: (values) => {
      updateRetirement({ externalSavings: values.externalSavings });
      update({
        variables: { user: { dob: values?.dob } },
        optimisticResponse: {
          updateUserNew: {
            ...data?.viewer?.user,
            id: data?.viewer?.user?.id || '',
            dob: values?.dob,
            __typename: 'User',
          },
        },
      });

      handleNext();
    },
  };

  return (
    <OnboardingFormBlueprint
      loading={loading}
      disabled={upserting || submitting}
      title={c('title')}
      subtitles={[c('description')]}
      formConfig={formConfig}
      showLabels
    />
  );
};

export const RetirementCurrentSavingsView = {
  name: routes.RETIREMENT_CURRENT_SAVINGS,
  component: RetirementCurrentSavings,
  options: OnboardingFormBlueprint.options,
};
