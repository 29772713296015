import React from 'react';
import { Button, EmptyState, Loading } from '@uikit';
import { Layout, Stack, Toolbar } from '@layouts';
import { IncomeSourceEventRow } from './IncomeSourceEventRow';
import Clock from '@svg/clock.svg';

interface IncomeSourceEventsProps {
  events: any[];
  querying?: boolean;
  hasMore?: boolean;
  fetchMore?: () => void;
}

export const IncomeSourceEvents: React.FC<IncomeSourceEventsProps> = ({
  events = [],
  querying,
  hasMore,
  fetchMore,
}) => {
  if (querying && events.length === 0) {
    return (
      <Layout centerAll>
        <Loading />
      </Layout>
    );
  }

  if (events && events.length) {
    return (
      <Stack>
        {events.map((event) => (
          <IncomeSourceEventRow key={event?.id} incomeEvent={event} isPlaceholder={querying} />
        ))}
        {hasMore ? (
          <Toolbar type="dialog">
            <Button small alt testID="more-paychecks" onPress={fetchMore}>
              More
            </Button>
          </Toolbar>
        ) : null}
      </Stack>
    );
  }

  return <EmptyState color="transparent" title="Nothing yet" icon={Clock} />;
};

export default IncomeSourceEvents;
