import React, { useMemo, useState, useCallback } from 'react';
import { Button, ActionSheet, Divider } from '@uikit';
import { optionPresets } from '@app/forms';
import { useCopy } from '@app/utils';
import Plus from '@svg/plus.svg';
import { SheetBlueprint } from '@app/blueprints';

interface Props {
  type: 'income' | 'deduction';
  append: (item: any) => void;
}

const IncomeDetailsFooter: React.FC<Props> = ({ type, append, isLast }) => {
  const { c } = useCopy('catch.ede.enums');

  const [isOpen, setOpen] = useState(false);
  const open = useCallback(() => setOpen(true), []);
  const close = useCallback(() => setOpen(false), []);

  const options = useMemo(() => {
    return optionPresets[type === 'income' ? 'INCOME_TYPES' : 'DEDUCTION_TYPES'].map(
      ({ value }) => ({
        label: c(value),
        onAction: () => {
          append({ type: value });
          setOpen(false);
        },
      }),
    );
  }, [type]);

  return (
    <>
      <ActionSheet isOpen={isOpen} onRequestClose={close}>
        <SheetBlueprint actions={options} />
      </ActionSheet>
      <Button
        onPress={open}
        small
        testID={type === 'income' ? 'add-income-btn' : 'add-deductions-btn'}
        svg={Plus}
      >
        {type === 'income' ? 'Income Source' : 'Deduction'}
      </Button>
      {!isLast && <Divider style={{ marginTop: 16, marginBottom: 16 }} />}
    </>
  );
};

export default IncomeDetailsFooter;
