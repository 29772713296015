import { lowercase } from '@app/utils';

const DEFAULT = 120000;
const MARRIED = 199000;
const MARRIED_SEPARATELY = 10000;

export const INCOME_LEVELS = {
  default: DEFAULT,
  married: MARRIED,
  marriedSeparately: MARRIED_SEPARATELY,
  headOfHousehold: DEFAULT,
};

export const ACCOUNT_TYPES = {
  traditional: 'IRA',
  roth: 'ROTH_IRA',
};

export const calculateInitialPercentage = (args) => {
  if (args !== null && typeof args === 'object' && args.age !== undefined) {
    const { age } = args;
    if (age >= 50) {
      return 0.15;
    } else if (age >= 40) {
      return 0.12;
    } else if (age >= 30) {
      return 0.08;
    } else if (age >= 18) {
      return 0.05;
    } else {
      throw new Error('age must be over 18');
    }
  } else {
    throw new Error('age must be over 18');
  }
};

export const calculateAccountType = (args) => {
  const { filingStatus, income } = args;
  if (income > 0 && !!filingStatus) {
    if (filingStatus === 'HEAD' && income >= INCOME_LEVELS.headOfHousehold) {
      return ACCOUNT_TYPES.traditional;
    } else if (filingStatus === 'MARRIED_SEPARATELY' && income >= INCOME_LEVELS.marriedSeparately) {
      return ACCOUNT_TYPES.traditional;
    } else if (filingStatus === 'MARRIED' && income >= INCOME_LEVELS.married) {
      return ACCOUNT_TYPES.traditional;
    } else if (filingStatus === 'SINGLE' && income >= INCOME_LEVELS.default) {
      return ACCOUNT_TYPES.traditional;
    } else {
      return ACCOUNT_TYPES.roth;
    }
  } else {
    throw new Error(`${income} is not a valid income`);
  }
};

// RECURRING DEPOSITS
// rate: interest rate per period
// nper: number of payment periods
// pmt: the payment made each period
// pv: present value of future payments
// type: when payments are due. 0=end of period, 1=beginning of period
function fvWithDeposits({ rate, nper, pmt, pv, type = 0 }) {
  var pow = Math.pow(1 + rate, nper),
    fv;
  if (rate) {
    fv = (pmt * (1 + rate * type) * (1 - pow)) / rate - pv * pow;
  } else {
    fv = -1 * (pv + pmt * nper);
  }
  return fv.toFixed(2);
}

// ONE TIME DEPOSIT
// rate: interest rate per period
// nper: number of payment periods
// pmt: the payment made initially
function fv({ rate, nper, pmt }) {
  var pow = Math.pow(1 + rate, nper);
  return pmt * pow;
}

// WITHDRAWALS
// rate: interest rate per period
// nper: number of payment periods
// pv: present value of the account
// fv: future value of the account
// type: when payments are due. 0=end of period, 1=beginning of period
function pmt({ rate, nper, pv, fv, type = 0 }) {
  if (rate !== 0.0) {
    // Interest rate exists
    var q = Math.pow(1 + rate, nper);
    return -(rate * (fv + q * pv)) / ((-1 + q) * (1 + rate * type));
  } else if (nper !== 0.0) {
    // No interest rate, but number of payments exists
    return -(fv + pv) / nper;
  }

  return 0;
}

export function calculateRetirementIncome({
  currentAge,
  estimatedIncome,
  paycheckPercentage,
  initialAmount = 0,
  retirementAge = 65,
  deathAge = 85,
  interestRateRetirement = 0.05, // assuming users invest conservatively during retirement
  interestRate = 0.07, // moderate portfolio default
  inflation = 0.025, // projected rate of inflation
}) {
  const monthlyPayment = (estimatedIncome / 12) * paycheckPercentage;

  // explicitly account for inflation rate in the assumed interest rate earned on a portfolio during a user's working years
  const nominalRateWorking = interestRate - inflation;

  // explicitly account for inflation rate during users retirement years
  const nominalRateRetired = interestRateRetirement - inflation;

  const depositsFV = -fvWithDeposits({
    rate: nominalRateWorking / 12,
    nper: 12 * (retirementAge - currentAge),
    pmt: monthlyPayment,
    pv: monthlyPayment,
  });

  const initialSavingsFV = fv({
    rate: nominalRateWorking / 12,
    nper: 12 * (retirementAge - currentAge),
    pmt: initialAmount,
  });

  const totalSaved = initialSavingsFV + depositsFV;

  // at this point, the user will (hopefully) be saving conservatively, meaning a lower rate of return on their retirement account
  const monthlyIncome =
    -1 *
    pmt({
      rate: nominalRateRetired / 12,
      nper: 12 * (deathAge - retirementAge),
      pv: totalSaved,
      fv: 0,
    });

  return {
    monthlyIncome,
    totalSaved,
    initialSavingsFV,
    depositsFV,
    inputs: {
      currentAge,
      monthlyPayment,
      retirementAge,
      deathAge,
      interestRate,
      initialAmount,
      inflation,
    },
  };
}

export const PORTFOLIO_LEVELS = {
  conservative: 'conservative',
  moderate: 'moderate',
  aggressive: 'aggressive',
};

export const AGE_LEVELS = {
  HIGH: 'HIGH',
  MEDIUM: 'MEDIUM',
  LOW: 'LOW',
};

export const RECOMMENDATION_MAP = {
  HIGH: {
    MORE_RISKY: {
      AGGRESSIVE: PORTFOLIO_LEVELS.aggressive,
      MODERATE: PORTFOLIO_LEVELS.aggressive,
      CONSERVATIVE: PORTFOLIO_LEVELS.aggressive,
    },
    NEUTRAL: {
      AGGRESSIVE: PORTFOLIO_LEVELS.aggressive,
      MODERATE: PORTFOLIO_LEVELS.aggressive,
      CONSERVATIVE: PORTFOLIO_LEVELS.aggressive,
    },
    LESS_RISKY: {
      AGGRESSIVE: PORTFOLIO_LEVELS.aggressive,
      MODERATE: PORTFOLIO_LEVELS.moderate,
      CONSERVATIVE: PORTFOLIO_LEVELS.moderate,
    },
  },
  MEDIUM: {
    MORE_RISKY: {
      AGGRESSIVE: PORTFOLIO_LEVELS.aggressive,
      MODERATE: PORTFOLIO_LEVELS.moderate,
      CONSERVATIVE: PORTFOLIO_LEVELS.moderate,
    },
    NEUTRAL: {
      AGGRESSIVE: PORTFOLIO_LEVELS.moderate,
      MODERATE: PORTFOLIO_LEVELS.moderate,
      CONSERVATIVE: PORTFOLIO_LEVELS.moderate,
    },
    LESS_RISKY: {
      AGGRESSIVE: PORTFOLIO_LEVELS.moderate,
      MODERATE: PORTFOLIO_LEVELS.moderate,
      CONSERVATIVE: PORTFOLIO_LEVELS.conservative,
    },
  },
  LOW: {
    MORE_RISKY: {
      AGGRESSIVE: PORTFOLIO_LEVELS.moderate,
      MODERATE: PORTFOLIO_LEVELS.moderate,
      CONSERVATIVE: PORTFOLIO_LEVELS.conservative,
    },
    NEUTRAL: {
      AGGRESSIVE: PORTFOLIO_LEVELS.conservative,
      MODERATE: PORTFOLIO_LEVELS.conservative,
      CONSERVATIVE: PORTFOLIO_LEVELS.conservative,
    },
    LESS_RISKY: {
      AGGRESSIVE: PORTFOLIO_LEVELS.conservative,
      MODERATE: PORTFOLIO_LEVELS.conservative,
      CONSERVATIVE: PORTFOLIO_LEVELS.conservative,
    },
  },
};

export function calcAgeSuggestion({ age }) {
  if (age < 18) throw new Error('age must be 18 or over');
  if (age >= 18 && age <= 31) return 'HIGH';
  if (age >= 32 && age <= 49) return 'MEDIUM';
  if (age >= 50) return 'LOW';
}

export function calculatePortfolioLevel({ age, riskComfort, riskLevel }) {
  if (age && riskComfort && riskLevel) {
    return RECOMMENDATION_MAP[calcAgeSuggestion({ age })][riskComfort][riskLevel];
  } else {
    return RECOMMENDATION_MAP[calcAgeSuggestion({ age: 21 })]['MORE_RISKY']['CONSERVATIVE'];
  }
}

/**
 * Given a list of portfolios, finds the portfolio that
 * matches the type returned from calculatePortfolioLevel
 */
export const findRecommendedPortfolio = ({ type, portfolios }) => {
  return (
    portfolios?.find((p) => {
      const pType = lowercase(p?.name?.replace('ESG', ''));
      const isESG = /ESG/.test(p?.name);
      return isESG && pType === type;
    }) || {}
  );
};

export const breakdown = {
  AggressiveESG: {
    stocks: 80,
    bonds: 20,
  },
  ModerateESG: {
    stocks: 60,
    bonds: 40,
  },
  ConservativeESG: {
    stocks: 40,
    bonds: 60,
  },
};
