import { boolToTri, safeFormatDate } from '@util';

export const getInitialValues = (application, isResubmit, isGA) => {
  return {
    isResubmit,
    isRequestingFinancialAssistance: application?.screening?.isRequestingFinancialAssistance,
    members: [application?.applicant, ...(application?.members || [])],
    legalName: application?.applicant?.legalName,
    isRenewalAgreement: boolToTri(application?.isRenewalAgreement || (isGA ? true : undefined)),
    renewEligibilityYearQuantity: application?.renewEligibilityYearQuantity || (isGA ? 5 : undefined),
    isMedicaidRequirementAgreement: boolToTri(application?.isMedicaidRequirementAgreement),
    isAbsentParentAgreement: boolToTri(application?.isAbsentParentAgreement),
    isTerminateCoverageOtherMecFoundAgreement: boolToTri(
      application?.isTerminateCoverageOtherMecFoundAgreement,
    ),
    isChangeInformationAgreement: boolToTri(application?.isChangeInformationAgreement),
    isPenaltyOfPerjuryAgreement: application?.isPenaltyOfPerjuryAgreement,
  };
};

export const formatPayload = ({ applicationID, values, isResubmit }) => {
  return {
    variables: {
      applicationID,
      minimalEnsure: isResubmit,
      input: {
        id: values?.id,
        lastUsedRoute: 'agreements',
        isRenewalAgreement: values?.isRenewalAgreement,
        renewEligibilityYearQuantity: !values?.isRenewalAgreement
          ? null
          : values?.isRenewalAgreement === 'NO'
          ? parseInt(values?.renewEligibilityYearQuantity, 10)
          : 5,
        isMedicaidRequirementAgreement: values?.isMedicaidRequirementAgreement,
        isAbsentParentAgreement: values?.isAbsentParentAgreement,
        isTerminateCoverageOtherMecFoundAgreement:
          values?.isTerminateCoverageOtherMecFoundAgreement,
        isChangeInformationAgreement: values?.isChangeInformationAgreement,
        isPenaltyOfPerjuryAgreement: boolToTri(values?.isPenaltyOfPerjuryAgreement),
        signatureText: values?.signatureText,
        signatureDate: safeFormatDate(new Date(), 'yyyy-MM-dd'),
        signatureType: 'APPLICANT',
      },
    },
  };
};
