import { isBefore } from 'date-fns';
import { triToBool, boolToTri, toDate, twelveYearsAgo, normalize } from '@app/utils';

export const getInitialValues = (member = {}) => {
  return {
    id: member.id,
    givenName: member.givenName || '',
    middleName: member.middleName || '',
    familyName: member.familyName || '',
    nameSuffix: member.nameSuffix || '',
    relation: member.relation,
    filerRelation:
      member.relation === 'CHILD' ? member.filerRelation || member.relation : undefined,
    spouseRelation:
      member.relation === 'CHILD' ? member.spouseRelation || member.relation : undefined,
    sex: member.sex || '',
    ssn: member.ssn || '',
    age: member.age,
    lastTobaccoUseDate: member.lastTobaccoUseDate,
    dob: member.dob || '',
    isRequestingCoverage: boolToTri(member?.isRequestingCoverage),
    isSmoker: boolToTri(member?.isSmoker),
    isCitizen: boolToTri(member?.isCitizen),
    isSameNameApplicationAndDocument: !member.ssn
      ? null
      : !!member?.ssnAlternateName?.givenName
      ? 'NO'
      : null,
    ssnAlternateName: member?.ssnAlternateName,
  };
};

export const formatPayload = ({ applicationID, values }) => {
  const isOlderThan12 = isBefore(toDate(values?.dob), twelveYearsAgo());

  return {
    variables: {
      input: {
        applicationID,
        applicationMembersInput: [
          {
            id: values?.id,
            givenName: values?.givenName,
            middleName: values?.middleName,
            familyName: values?.familyName,
            nameSuffix: values?.nameSuffix,
            sex: values?.sex,
            ssn: values?.confirmMissingSSN ? null : values?.ssn,
            dob: values?.dob,
            filerRelation: values?.filerRelation,
            spouseRelation: values?.spouseRelation,
            isSmoker: isOlderThan12 ? triToBool(values?.isSmoker) : false,
            lastTobaccoUseDate:
              isOlderThan12 && values?.isSmoker === 'YES' && !!values?.lastTobaccoUseDate
                ? values?.lastTobaccoUseDate
                : null,
            isBlindOrDisabled: values?.isBlindOrDisabled,
            isLongTermCare: values?.isLongTermCare,
            isHispanicOrigin: values?.isHispanicOrigin,
            race: values?.race,
            otherRaceText: values?.otherRaceText,
            isCitizen: normalize(values?.isCitizen),
            ssnAlternateName:
              values?.isSameNameApplicationAndDocument === 'NO'
                ? {
                    firstName: normalize(values?.ssnAlternateName?.givenName),
                    middleName: normalize(values?.ssnAlternateName?.middleName),
                    lastName: normalize(values?.ssnAlternateName?.familyName),
                    nameSuffix: normalize(values?.ssnAlternateName?.nameSuffix),
                  }
                : null,
          },
        ],
      },
    },
  };
};
