// @ts-nocheck
import React, { useState } from 'react';
import {
  useMutation,
  mutations,
  UpsertExplorerVars,
  UpsertExplorerResponse,
  useDeprecatedMutation,
  useQuery,
  queries,
  ExplorerPlansQueryData,
  updates,
} from '@data';
import { navigate, routes, exit, ScreenDefinition } from '@navigate';
import { useCopy } from '@app/utils';
import { HealthPlansBlueprint } from '@app/blueprints';

const ExplorerPlanSelection = ({ pathwayType, explorerID, standalone }) => {
  const { c: c0 } = useCopy('catch.healthExplorer.plans');
  const [selecting, setSelecting] = useState(false);

  const [startEnrollment] = useDeprecatedMutation('upsertEnrollmentFromExplorer', {
    onCompleted: (enrollment) => {
      navigate(routes.EDE_INTRO, {
        enrollId: enrollment.id,
        applicationID: enrollment.healthApplication.id,
      });
    },
  });

  const { loading, data } = useQuery<ExplorerPlansQueryData>(queries.EXPLORER_PLANS, {
    skip: !explorerID || !pathwayType,
  });

  const [update] = useMutation<UpsertExplorerResponse, UpsertExplorerVars>(
    mutations.UPSERT_EXPLORER,
    {
      update: updates.UPDATE_EXPLORER,
    },
  );

  const planSearch = data?.viewerTwo?.healthExplorerData?.healthPlans;

  const selectPlan = async ({ selectedProviderPlanID, onCompleted }) => {
    setSelecting(true);
    const input = { providerPlanID: selectedProviderPlanID };

    if (pathwayType === 'EDE') {
      await update({ variables: { input } });

      if (standalone) {
        exit(routes.HOME);
      } else {
        await startEnrollment();
        onCompleted();
      }
    } else {
      update({ variables: { input } });
      onCompleted();
      navigate(routes.EXPLORER_STATE_HANDOFF);
    }
  };

  return (
    <HealthPlansBlueprint
      loading={loading}
      selecting={selecting}
      title={c0('title')}
      subtitle={c0('subtitle')}
      initialPlanID={
        data?.viewerTwo?.healthExplorerData?.providerPlanID ||
        planSearch?.topPlans?.catchRecommended?.healthPlan?.planID
      }
      explorerID={data?.viewerTwo?.healthExplorerData?.id}
      previouslySelectedID={data?.viewerTwo?.healthExplorerData?.providerPlanID}
      planSearch={planSearch}
      onSubmit={(plan, onCompleted) => {
        selectPlan({ selectedProviderPlanID: plan.healthPlan.planID, onCompleted });
      }}
    />
  );
};

export const ExplorerPlanSelectionView: ScreenDefinition = {
  name: routes.EXPLORER_CHOOSE_PLAN,
  component: ExplorerPlanSelection,
  options: {
    ...HealthPlansBlueprint.options,
    title: 'Plans',
  },
};
