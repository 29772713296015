import React, { useCallback } from 'react';
import shallow from 'zustand/shallow';
import { routes, sheets, useSheet } from '@navigate';
import { loaders } from '@app/config';
import { mutations, useMutation } from '@app/data';
import { MultiMemberBasicFormBlueprint } from '@app/blueprints';
import { useCopy } from '@app/utils';
import { HealthSlasherStackScreenProps } from '../HealthSlasherStack';
import { fields } from '../application/incomeDetails/incomeDetailsFields';
import { getInitialValues, formatPayload } from '../application/incomeDetails/incomeDetailsUtils';

/**
 *
 * Premium Slasher Income Details
 *
 * @todo we need a grouped/compact field array style for times like this
 */
const HealthSlasherIncomeDetails = ({
  handleNext,
  loading,
  applicationID,
  filteredMembers,
}: HealthSlasherStackScreenProps) => {
  const { c } = useCopy('catch.ede.IncomeDetails');

  const { openLoader } = useSheet(
    (state) => ({ openLoader: () => state.open(sheets.LOADER, loaders.HEALTH_LONG_WAIT) }),
    shallow,
  );

  const [ensure, { loading: ensuring }] = useMutation(mutations.ENSURE_HEALTH_APPLICATION, {
    onCompleted: handleNext,
    refetchQueries: ['HealthSlasherQuery'],
    awaitRefetchQueries: true,
  });

  // @ts-ignore
  const [upsert] = useMutation(mutations.UPSERT_APPLICATION_MEMBERS);

  const onNext = useCallback(
    // @ts-ignore
    (values) => upsert(formatPayload({ applicationID, values, context: 'SLASHER' })),
    [applicationID, upsert, formatPayload],
  );

  const onComplete = useCallback(() => {
    openLoader();
    ensure({ variables: { applicationID } });
  }, [applicationID]);

  return (
    <MultiMemberBasicFormBlueprint
      loading={loading}
      submitting={ensuring}
      getInitialValues={(m) => getInitialValues(m, { slasher: true })}
      fields={fields}
      title={(m) => c('loadingTitle', { loading, relation: m?.relation })}
      subtitle={(m) => c('subtitle', { givenName: m?.givenName })}
      members={filteredMembers}
      data={{}}
      onNext={onNext}
      onComplete={onComplete}
    />
  );
};

export const HealthSlasherIncomeDetailsView = {
  name: routes.HEALTH_SLASHER_INCOME_DETAILS,
  component: HealthSlasherIncomeDetails,
  options: {},
};
