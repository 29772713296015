import { useHealthStore } from '@app/client/useHealthStore';

type HealthContext = 'FFM' | 'GA';

const marketplaceNames: Record<HealthContext, { name: string; full: string; }> = {
  FFM: {
    name: "Marketplace",
    full: "the Health Insurance Marketplace",
  },
  GA: {
    name: "Georgia Access",
    full: "Georgia Access"
  },
};



const providers: Record<HealthContext, string> = {
  FFM: 'Marketplace',
  GA: 'Catch',
};

const chipNames: Record<HealthContext, string> = {
  FFM: 'CHIP',
  GA: 'PeachCare for Kids',
};

const callCenter: Record<HealthContext, string> = {
  FFM: 'Marketplace Call Center at 1-800-318-2596 (TTY: 1-855-889-4325)',
  GA: 'Catch at (833) 309-0500',
};

export const useUniversalCopy = () => {
  const { context } = useHealthStore();

  return {
    marketplaceName: marketplaceNames[context].name,
    theMarketplace: marketplaceNames[context].full,
    provider: providers[context],
    callCenter: callCenter[context],
    chipName: chipNames[context],
  };
};
