import React from 'react';

import { useDeprecatedMutation } from '@data';
import { open, routes, useSheet, sheets, SheetComponentProps } from '@navigate';
import { useSnooze, useGoal } from '@hooks';
import { SheetBlueprint } from '@blueprints';
import Pause from '@svg/pause.svg';
import { Actions } from '@app/config';
import { canDeleteBenefit, deslug } from '@app/utils';

const PlanDetailMenuSheet: React.FC<SheetComponentProps['PLAN_DETAIL_MENU']> = ({ slug }) => {
  const { goal } = useGoal({ slug });
  const { replace } = useSheet();

  const { canPause, pause, resume } = useSnooze(goal);
  const [removeGoal, { loading: deleting }] = useDeprecatedMutation('upsertGoal', {
    onCompleted: () => {},
  });

  const isCustomGoal = slug === 'custom-goal' || goal?.goalType === 'CUSTOM_SAVINGS';

  const editPercentage = () => open(routes.GOAL_EDIT, { slug });
  const editCustomGoal = () => open(routes.GOAL_CUSTOMIZE, { slug });
  const changePortfolio = () => open(routes.RETIREMENT_CHANGE_PORTFOLIO, { slug });

  return (
    <SheetBlueprint
      title={deslug(slug)}
      actions={[
        {
          ...Actions.CUSTOMIZE_GOAL,
          onAction: editCustomGoal,
          hidden: !isCustomGoal,
          disabled: true,
        },
        {
          ...Actions.EDIT_WITHHOLDING,
          onAction: editPercentage,
          disabled: true,
        },
        {
          ...Actions.TRANSFER,
          disabled: true || goal?.goalType === 'RETIREMENT',
          onAction: () =>
            replace(sheets.TRANSFER_OPTIONS, {
              goal,
              goalType: goal?.goalType,
            }),
        },
        {
          testID: 'pause-resume-goal',
          glyph: Pause,
          label: false ? 'Pause' : 'Resume',
          onAction: canPause ? pause : resume,
          disabled: true,
        },
        {
          ...Actions.REMOVE_GOAL,
          loading: deleting,
          hidden: goal?.totalBalance > 0 || !canDeleteBenefit(goal?.goalType),
          onAction: () =>
            // @ts-ignore
            removeGoal({
              variables: {
                input: {
                  id: goal?.id,
                  status: 'DELETED',
                },
              },
            }),
        },
        {
          ...Actions.CHANGE_PORTFOLIO,
          onAction: changePortfolio,
          loading: !goal?.portfolio?.id,
          hidden: goal?.goalType !== 'RETIREMENT',
          disabled: true,
        },
        {
          ...Actions.EDIT_BENEFICIARY,
          disabled: true,
          hidden: goal?.goalType !== 'RETIREMENT' || !goal?.portfolio?.id,
        },
      ]}
    />
  );
};

export default PlanDetailMenuSheet;
