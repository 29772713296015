import { boolToTri, copy, getFullName, normalize } from '@util';

export const getInitialValues = (member) => {
  const annualTaxIncome = member?.annualTaxIncome;

  const labelData = {
    id: member?.id,
    fullName: getFullName(member),
    amount: '',
    relation: member?.relation,
  };

  if (!annualTaxIncome) {
    return {
      ...labelData,
      renderUnemploymentQuestion: false,
    };
  }

  // the correct inverted value (because we ask "is this income correct?")
  const isVariableIncome =
    annualTaxIncome.isVariableIncome === true || annualTaxIncome.isVariableIncome === false
      ? !annualTaxIncome.isVariableIncome
      : null;

  return {
    ...labelData,
    amount: copy.$(annualTaxIncome.attestedAnnualizedAPTCIndividualIncomeAmount),
    renderUnemploymentQuestion: member?.renderUnemploymentQuestion,
    isVariableIncome: boolToTri(isVariableIncome),
    isUnknownIncome: boolToTri(annualTaxIncome.isUnknownIncome),
    incomeAmount: annualTaxIncome.incomeAmount,
    calculatedAmount: annualTaxIncome.attestedAnnualizedAPTCIndividualIncomeAmount,
    hasReceivedUnemploymentCompensation: boolToTri(
      annualTaxIncome.hasReceivedUnemploymentCompensation,
    ),
  };
};

/**
 * The amount that the user is asked to attest to
 * This should always reflected the calculated amount from income details,
 * and not the amount specified in the annualTaxIncome for variable income folks
 * (Otherwise, people would be attesting to the number they entered when returning)
 */
export const getTotalIncomeForMember = (member) =>
  member?.annualTaxIncome?.attestedAnnualizedAPTCIndividualIncomeAmount || 0;

export const formatPayload = ({ values }) => {
  return {
    id: values?.id,
    annualTaxIncome: {
      // because the question we ask is reverse of `isVariableIncome`,
      // we need to invert the upsert values
      isVariableIncome:
        values?.isVariableIncome === 'YES'
          ? 'NO'
          : values?.isVariableIncome === 'NO'
          ? 'YES'
          : null,

      incomeAmount:
        values?.isVariableIncome === 'YES' ? values?.calculatedAmount || 0 : values?.incomeAmount,

      hasReceivedUnemploymentCompensation: normalize(values.hasReceivedUnemploymentCompensation),

      // if the user answers YES to "is this amount correct?"
      // then we want to make sure that the unknown income is properly unset
      // otherwise, it may have a preexisting value
      isUnknownIncome:
        values?.isVariableIncome === 'YES'
          ? 'NIL'
          : values?.isUnknownIncome === 'YES'
          ? 'YES'
          : values?.isUnknownIncome === 'NO'
          ? 'NO'
          : 'NIL',
    },
  };
};
