import React, { useState, useCallback } from 'react';
import { View } from 'react-native';
import { useDropzone } from 'react-dropzone';
import { unstable_createElement } from 'react-native-web';
import { Layout } from '@layouts';
import { Progress } from '../Progress';
import { styles, getGutters, useSharedStyles } from './shared';
import { Text } from '../Text';
import { useHover } from '@uikit/hooks/useHover';
import Trash from '../../icons/Trash';
import Error from '../../icons/Error';
import UploadIcon from '@svg/upload.svg';

// @WARNING:
// If you make any changes here make sure to stay consistent with native version
const FileInput = ({
  value,
  onDelete,
  onChange,
  onUpload,
  label,
  sublabel,
  fileTypeLabel,
  fullWidth,
  squareAvatar,
  gutter,
  name,
  initialUrl,
  svg = UploadIcon,
  acceptedTypes,
  maxSize,
  // renderPreview not in use right now, don't bother
  renderPreview,
  disabled,
}) => {
  const { colorStyles } = useSharedStyles();
  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isHovered, hoverHandlers] = useHover();
  const [tooHeavy, setTooHeavy] = useState(false);
  const [wrongFormat, setWrongFormat] = useState(false);
  const [error, setError] = useState(false);
  const onDrop = useCallback(
    (files) => {
      // Allow a single file for now
      if (onUpload) {
        onUpload({
          name,
          file: files[0],
          onProgress: setProgress,
          onStart: () => {
            setError(false);
            setIsUploading(true);
          },
          onStop: () => setIsUploading(false),
          onCompleted: onChange,
          onTooHeavy: setTooHeavy,
          onWrongFormat: setWrongFormat,
          onError: setError,
        });
      } else {
        onChange(files[0]);
      }
    },
    [name],
  );
  const uri = typeof value === 'string' || !value ? value : window.URL.createObjectURL(value);

  const { getRootProps, getInputProps, isDragActive, isFocused } = useDropzone({
    onDrop,
    accept: '.jpg, .jpeg, .png, .pdf',
  });

  const outsideContainerStyle = [
    styles.container,
    colorStyles.container,
    squareAvatar && styles.squareAvatar,
  ];

  return unstable_createElement(
    'div',
    {
      ...hoverHandlers,
      ...getRootProps(),
      style: [
        !renderPreview && outsideContainerStyle,
        gutter && getGutters(gutter),
        fullWidth && styles.fullWidth,
        isDragActive && !disabled && colorStyles.containerActive,
        isFocused && !disabled && colorStyles.containerFocused,
        isUploading && colorStyles.containerUploading,
        isHovered && !disabled && colorStyles.containerHovered,
      ],
    },

    unstable_createElement('input', {
      ...getInputProps(),
      // Shouldn't be disabled when there's an error so user can retry
      disabled: ((isUploading || !!value || !!initialUrl) && !error) || disabled,
    }),
    error ? (
      <View style={outsideContainerStyle}>
        <View style={styles.content}>
          <Error />
          <Text size="fp" color="coral" gutter="top" align="center">
            Upload failed
          </Text>
        </View>
      </View>
    ) : isUploading ? (
      <View style={outsideContainerStyle}>
        <View style={styles.progress}>
          <Progress progress={progress} />
        </View>
      </View>
    ) : value || initialUrl ? (
      !!renderPreview ? (
        renderPreview({
          uri: uri || initialUrl,
          handleDelete: () => onDelete(name),
        })
      ) : (
        <View style={outsideContainerStyle}>
          <View
            key="image"
            style={[
              styles.preview,
              {
                backgroundImage: `linear-gradient(180deg, rgba(31, 37, 51, 0.3) 60.98%, rgba(31, 37, 51, 0.85) 100%), url('${
                  uri || initialUrl
                }')`,
              },
            ]}
          >
            <Trash color="snow" containerStyle={styles.delete} onPress={() => onDelete(name)} />
          </View>
        </View>
      )
    ) : (
      <View style={outsideContainerStyle}>
        <View style={styles.content}>
          <Layout.Header light align="center" svg={svg} title={label} subtitle={sublabel} />
          {!!fileTypeLabel && (
            <Text size="fp" align="center" color="subtle">
              {fileTypeLabel}
            </Text>
          )}
          {tooHeavy && (
            <Text size="fp" color="coral" align="center">
              File size can’t be larger than {maxSize}
            </Text>
          )}
          {wrongFormat && (
            <Text size="fp" color="coral" align="center">
              Please upload a {acceptedTypes}
            </Text>
          )}
        </View>
      </View>
    ),
  );
};

FileInput.defaultProps = {
  maxSize: '5MB',
  acceptedTypes: 'JPG, PNG, or PDF',
  iconProps: {},
};

export default FileInput;
