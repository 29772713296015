import React, { useEffect, useState } from 'react';

import { Modal, Divider, EditNav, Text, useTheme, ComplexRow, Switch } from '@uikit';
import { Card, Layout, Toolbar } from '@layouts';
import { useSelectable } from '@hooks';
import { getCarrierAsset, personInitials, useCopy } from '@app/utils';
import { EnrollmentGroupMember } from '@types';
import User from '@svg/user.svg';

import NoAdditionalMembersPrompt from '../actionsheets/NoAdditionalMembersPrompt';
import { EnrollingMembersRow, HealthPlanPremium } from '@common';
import { EnrollmentGroupsQueryData } from '@app/data';

interface Props {
  groupID: string;
  isOpen: boolean;
  deleting: boolean;
  hasOtherPlans: boolean;
  closeModal: () => void;
  updateMembers: (any) => void;
  changeGroup: (number) => void;
  deleteGroup: (any) => void;
  enrollmentGroups: EnrollmentGroupsQueryData['viewer']['health']['application']['enrollmentGroups'];
  currentMembers: Array<EnrollmentGroupMember>;
  possibleShoppingMembers: Array<EnrollmentGroupMember>;
  orphanedMembers: Array<EnrollmentGroupMember>;
}

/**
 * Modal for showing who is currently shopping for coverage
 * from the current enrollment group
 * @param {boolean} isOpen modal open/closed state
 * @param {function} closeModal fn to hide the modal
 * @param {[ApplicationMember]} members the remaining group members
 */
const EnrollmentGroupMemberModal = ({
  groupID,
  isOpen,
  changeGroup,
  closeModal,
  updateMembers,
  deleteGroup,
  deleting,
  hasOtherPlans,
  enrollmentGroups,
  currentMembers,
  possibleShoppingMembers,
  orphanedMembers,
}: Props) => {
  const { c } = useCopy('catch.health');
  const { theme } = useTheme();
  const [isEditing, edit] = useState(false);

  /* @ts-ignore */
  const { toggle, isSelected, selected, deselected, reset } = useSelectable(
    currentMembers.map((m) => m.id),
    {},
  );

  useEffect(() => {
    reset();
  }, [currentMembers]);

  /**
   * Counts the number of plans to tentatively enroll in
   * - counts the number of current enrollment groups
   * - plus, add 1 if there are currently orphaned members OR deselected (i.e. may become orphaned)
   */
  const hasOrphans = deselected?.length > 0 || orphanedMembers.length > 0 ? 1 : 0;
  const numPlans = enrollmentGroups?.length + hasOrphans;

  const onClose = () => {
    reset();
    closeModal();
    edit(false);
  };

  const handleSave = () => {
    /* @ts-ignore */
    updateMembers({ selected, deselected });
    closeModal();
  };

  const selectGroup = (index) => {
    changeGroup(index);
    closeModal();
  };

  return (
    <>
      {/* @ts-ignore */}
      <Modal isOpen={isOpen} onRequestClose={onClose}>
        <EditNav
          onClose={onClose}
          handleSave={handleSave}
          handleCancel={reset}
          disabled={selected?.length === 0}
          isEditing={isEditing}
          toggle={edit}
        />
        {/* @ts-ignore */}
        <Layout.Scroll fullHeight style={theme.topGutter3} bottomSpace>
          {/* @ts-ignore */}
          <Layout margins style={theme.bottomGutter4}>
            <Text size="h4" weight="bold">
              {c('shoppingForCoverage')}
            </Text>
            {possibleShoppingMembers.map((member) => (
              <ComplexRow
                key={member?.id}
                label={`${member?.givenName} ${member?.familyName}`}
                sublabel={c(isSelected(member?.id) ? 'enrolling' : 'notEnrolling')}
                disabled={!isSelected(member?.id)}
                asset={{
                  svg: User,
                  text: personInitials(member),
                  containerSize: 'md',
                  size: 'xs',
                  bg: 'coverage',
                  color: 'page',
                  shape: 'circle',
                }}
                accessory={
                  isEditing ? (
                    <Switch isOn={isSelected(member?.id)} onToggle={() => toggle(member?.id)} />
                  ) : undefined
                }
              />
            ))}
            <NoAdditionalMembersPrompt
              deleting={deleting}
              deleteGroup={deleteGroup}
              deselected={possibleShoppingMembers}
              show={selected?.length === 0}
              onClose={onClose}
            />
          </Layout>
          {hasOtherPlans && (
            <>
              {/* @ts-ignore */}
              <Divider />
              {/* @ts-ignore */}
              <Layout margins style={theme.topGutter4}>
                <Text style={theme.bottomGutter1b}>{c('readyToEnroll')}</Text>
                {/* @ts-ignore */}
                {enrollmentGroups?.map((EG, index) => {
                  // don't show if theres no selected plan or is the current group
                  if (EG?.id === groupID || !EG?.sortedPlans?.selectedPlan) {
                    return null;
                  }

                  return (
                    <Card
                      key={EG?.id}
                      onPress={() => selectGroup(index)}
                      shadow
                      style={theme.allSpace2}
                    >
                      {/* @ts-ignore */}
                      <ComplexRow
                        asset={getCarrierAsset(EG?.sortedPlans?.selectedPlan?.healthPlan?.provider)}
                        label={EG?.sortedPlans?.selectedPlan?.healthPlan?.name}
                        sublabel={
                          //  @ts-ignore
                          <HealthPlanPremium
                            premium={EG?.sortedPlans?.selectedPlan?.healthPlan?.premium}
                          />
                        }
                      />
                      <EnrollingMembersRow
                        members={EG?.members}
                        label={c('numApplying', {
                          num: EG?.members?.length,
                        })}
                      />
                    </Card>
                  );
                })}
              </Layout>
            </>
          )}
        </Layout.Scroll>
        {/* @ts-ignore */}
        <Toolbar type="stack">
          <Text color="subtle" align="center">
            {c('planEnrollmentCount', { numPlans })}
          </Text>
        </Toolbar>
      </Modal>
    </>
  );
};

export default EnrollmentGroupMemberModal;
