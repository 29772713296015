import React from 'react';
import { Layout } from '@layouts';
import Check from '@svg/check-circle.svg';
import { useCopy } from '@app/utils';

/**
 * The big green header for confirming things like transfers and payments
 */
const ConfirmationHeader = ({ amount, label = '', digits = 2, subtitle }) => {
  const { $ } = useCopy();
  return (
    <Layout margins topSpace bottomSpace>
      <Layout.Header
        align="center"
        svg={Check}
        title={`${$(amount)} ${label}`}
        subtitle={subtitle}
        titleSize="form"
      />
    </Layout>
  );
};

export default ConfirmationHeader;
