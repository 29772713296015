import { useDeprecatedQuery, useDeprecatedMutation } from '@data';

export const useTextOptIn = () => {
  const { textNotifications } = useDeprecatedQuery('textNotifications');
  const [upsert] = useDeprecatedMutation('updateNotificationSettings');

  function update() {
    upsert({
      variables: {
        input: {
          textNotificationSettings: {
            healthInsurance: true,
          },
        },
      },
    });
  }
  const showOptIn = !textNotifications;
  return { update, showOptIn };
};
