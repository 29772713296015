import React from 'react';
import { stacks, Stack } from '@navigate';
import { TaxesView } from './taxes/TaxesView';
import { TabStackComponent, TabConfig } from '../types';

const config: TabConfig = {
  stackName: stacks.TAXES_STACK,
  options: { accentColor: 'taxes' },
  screens: [TaxesView],
  tabIcon: 'TAXES',
  title: 'Taxes',
};

const TaxesStack: TabStackComponent = () => {
  return <Stack stackName={config.stackName} screens={config.screens} options={config.options} />;
};

TaxesStack.config = config;
export default TaxesStack;
