import gql from 'graphql-tag';

export const TransfersFragments = {
  // get all the PAID bonuses
  rewards: gql`
    fragment Rewards on Viewer {
      id
      pendingRewards(status: PAID) {
        code
        amount
        createdOn
        description
      }
    }
  `,
};

export const formatRewards = (data) => {
  const rewards = data?.viewer?.pendingRewards || [];
  return {
    rewards,
  };
};

export default {
  ...TransfersFragments,
  formatRewards,
};
