import React, { useState } from 'react';
import { SheetComponentProps } from '@navigate';
import { SheetBlueprint } from '@app/blueprints';
import { BottomActions } from '@common';
import { useDeprecatedMutation } from '@app/data';
import { StepperInput } from '@f';
import { Layout } from '@layouts';
import { Loading } from '@uikit';
import { deslug } from '@app/utils';

const GoalEstimatorSheet: React.FC<SheetComponentProps['GOAL_ESTIMATOR']> = ({
  slug,
  defaultValue,
  recommendedPercentage,
  closeSheet,
}) => {
  const [percentage, setPercentage] = useState<number>(defaultValue);

  const [upsertGoal, { loading }] = useDeprecatedMutation('upsertGoal', {
    onCompleted: () => closeSheet(),
  });

  const handleUpdate = () => {
    upsertGoal({
      variables: {
        input: {
          slug,
          paycheckPercentage: percentage,
        },
      },
    });
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <SheetBlueprint title={`Edit ${deslug(slug)}`}>
      <Layout bottomSpace>
        <StepperInput
          testID="goal-estimator-percentage"
          name="paycheckPercentage"
          label="per paycheck"
          stepperType="percentage"
          value={percentage}
          onChange={(val) => setPercentage(val)}
          onBlur={() => {}}
        />
      </Layout>
      <BottomActions
        accentColor="brand"
        actions={[
          {
            type: 'CUSTOM',
            label: 'Reset to recommended',
            color: 'text',
            onAction: () => setPercentage(recommendedPercentage),
          },
          {
            type: 'CUSTOM',
            label: 'Save',
            color: 'text',
            button: true,
            onAction: () => handleUpdate(),
          },
        ]}
      />
    </SheetBlueprint>
  );
};

export default GoalEstimatorSheet;
