import { createLogger } from '../logger';
import DeviceInfo from 'react-native-device-info';
import Env from '../env';

const Log = createLogger('analytics:');

/**
 * @note on Segment:
 *
 * By default, the dev and stage envs are disabled
 * so that they don't take up visitor #s
 *
 * If you are working on Segment tracking, you should
 * verify that events are being properly tracked by enabling
 * the web dev source in Segment + making sure your event is there
 */
export function initAnalytics(segmentKey, debug) {
  // Create a queue, but don't obliterate an existing one!
  const analytics = (window.analytics = window.analytics || []);

  // If the real analytics.js is already on the page return.
  if (analytics.initialize) return;

  // If the snippet was invoked already show an error.
  if (analytics.invoked) {
    if (window.console && console.error) {
      console.error('Segment snippet included twice.');
    }
    return;
  }

  // Invoked flag, to make sure the snippet
  // is never invoked twice.
  analytics.invoked = true;

  // A list of the methods in Analytics.js to stub.
  analytics.methods = [
    'trackSubmit',
    'trackClick',
    'trackLink',
    'trackForm',
    'pageview',
    'identify',
    'reset',
    'group',
    'track',
    'ready',
    'alias',
    'debug',
    'page',
    'once',
    'off',
    'on',
  ];

  // Define a factory to create stubs. These are placeholders
  // for methods in Analytics.js so that you never have to wait
  // for it to load to actually record data. The `method` is
  // stored as the first argument, so we can replay the data.
  analytics.factory = function (method) {
    return function () {
      const args = Array.prototype.slice.call(arguments);
      args.unshift(method);
      analytics.push(args);
      return analytics;
    };
  };

  // For each of our methods, generate a queueing stub.
  for (let i = 0; i < analytics.methods.length; i++) {
    const key = analytics.methods[i];
    analytics[key] = analytics.factory(key);
  }

  // Define a method to load Analytics.js from their CDN,
  // and that will be sure to only ever load it once.
  analytics.load = function (key, options) {
    // Create an async script element based on your key.
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = 'https://analytics.catch.co/analytics.js/v1/' + key + '/analytics.min.js';

    // Insert our script next to the first script element.
    const first = document.getElementsByTagName('script')[0];
    first.parentNode.insertBefore(script, first);
    analytics._loadOptions = options;
  };

  // Add a version to keep track of what's in the wild.
  analytics.SNIPPET_VERSION = '4.1.0';

  // Load Analytics.js with proper environment key, which will automatically
  // load the tools we've enabled.
  analytics.load(segmentKey);

  // Debug should display some useful messages
  if (debug) {
    analytics.debug();
  }

  // Make the first page call to load the integrations.
  analytics.page();
}

const handleValues = (vals = {}) => {
  return {
    ...vals,
    app_version: Env.buildConfig.version,
    app_build: Env.buildConfig.number,
    app_platform: 'Web',
    device_id: DeviceInfo.getUniqueId(),
    ip_address: DeviceInfo.getIpAddress(),
  };
};

// We can create custom analytics methods or getters here
// this makes sure the script is loaded and we get the most
// recent instance of it
// It may also be helpful to see what we are capturing in a centralized way
const lib = {
  get analytics() {
    if (Env.isLocal) {
      return {
        page: console.log,
        track: console.log,
        identify: console.log,
      };
    }

    return window.analytics;
  },
  identifyUser(userID, traits = {}) {
    Log.debug({ userID, ...traits });
    if (!this.analytics) return;
    this._userID = userID;
    this.analytics.identify(userID, {
      ...traits,
    });
  },
  pageView() {
    if (!this.analytics) return;
    this.analytics.page();
  },
  track(name, data) {
    if (!this.analytics) return;
    this.analytics.track(name, handleValues(data));
  },
  submitRegistration(alias) {
    if (!this.analytics) return;
    this.analytics.track('Submit Registration', handleValues({ alias }));
  },

  plaidEvent(name, metadata) {
    // Excludes the search because it fires on every keystroke
    if (!/SEARCH_INSTITUTION|TRANSITION_VIEW/.test(name)) {
      if (!window.analytics) return;
      let eventName = 'Plaid Event';
      if (name === 'OPEN') eventName = 'Plaid Opened';
      if (name === 'ERROR') eventName = 'Plaid Error';
      if (name === 'EXIT') eventName = 'Plaid Exit';
      if (name === 'HANDOFF') eventName = 'Plaid Handoff';
      window.analytics.track(eventName, { name, ...metadata });
    }
  },
  copiedRefLink(referralLink) {
    if (!this.analytics) return;
    const eName = 'User Copied Referral Link';
    this.analytics.track(eName, handleValues({ referral_link: referralLink }), {}, () =>
      Log.debug(eName),
    );
  },
  appStore(response, action) {
    // shouldnt be triggered from web
  },
  // Triggered in BankSuccessView.js
  bankLinked(bankName) {
    const eName = 'Bank Linked';
    if (!this.analytics) return;
    this.analytics.track(eName, handleValues({ bank_name: bankName }), {}, () =>
      Log.debug(eName, bankName),
    );
  },
  goalAdded(goalType) {
    const eName = 'Goal Added';
    if (!this.analytics) return;
    this.analytics.track(eName, handleValues({ goal_type: goalType }), {}, () =>
      Log.debug(eName, goalType),
    );
    if (this._userID) {
      this.analytics.identify(this._userID, {
        [`status_${goalType}`]: 'active',
      });
    } else {
      this.analytics.identify({
        [`status_${goalType}`]: 'active',
      });
    }
  },
  fundsWithheld(value) {
    const eName = 'Funds Withheld';
    if (!this.analytics) return;
    this.analytics.track(eName, handleValues({ value }), {}, () => Log.debug(eName, value));
  },
  fundsDeposited(value) {
    const eName = 'Funds Deposited';
    if (!this.analytics) return;
    if (this._kickstartStarted) {
      this.kickstartApproved(value);
    } else {
      this.analytics.track(eName, handleValues({ value }), {}, () => Log.debug(eName, value));
    }
  },
  userCreated(alias, signup_context) {
    const eName = 'User Created';
    if (!this.analytics) return;
    this.analytics.track(eName, handleValues({ alias, signup_context }), {}, () =>
      Log.debug(eName, alias),
    );
  },
  interestExpressed(benefit, cb) {
    const eName = 'Interest Expressed';
    if (!this.analytics) {
      if (cb) cb();
      return;
    }
    const lcName = benefit && benefit.toLowerCase();
    const type = lcName ? lcName.slice(9) : 'unknown';
    // > By default, traits are cached in the browser’s local storage and attached to each subsequent identify call.
    if (this._userID) {
      this.analytics.identify(this._userID, {
        [`interested_${type}`]: true,
      });
    } else {
      this.analytics.identify({
        [`interested_${type}`]: true,
      });
    }
    this.analytics.track(eName, handleValues({ benefit: type }), {}, cb);
  },
  checkupCompleted() {
    const eName = 'Checkup Completed';
    if (!this.analytics) return;
    if (this._userID) {
      this.analytics.identify(this._userID, {
        checkup_completed: true,
      });
    } else {
      this.analytics.identify({
        checkup_completed: true,
      });
    }
  },
  guideCardOpened(benefit) {
    const eName = 'Guide Card Opened';
    if (!this.analytics) return;
    const lcName = benefit && benefit.toLowerCase();
    const type = lcName ? lcName.slice(9) : 'unknown';
    this.analytics.track(eName, handleValues({ benefit: type }), {}, () => Log.debug(eName));
  },
  healthPlanViewed(healthplanID) {
    const eName = 'Health Plan Viewed';
    if (!this.analytics) return;
    this.analytics.track(eName, handleValues({ healthplan_id: healthplanID }), {}, () =>
      Log.debug(eName),
    );
  },
  healthQLEChecked(lifeEvent) {
    const eName = 'Health QLE Checked';
    if (!this.analytics) return;
    if (this._userID) {
      this.analytics.identify(this._userID, {
        life_event: lifeEvent || 'NA',
      });
    } else {
      this.analytics.identify({
        life_event: lifeEvent || 'NA',
      });
    }
    this.analytics.track(eName, handleValues());
  },
  personAdded(relationship, attachments, numContacts) {
    const eName = 'Person Added';
    if (!this.analytics) return;
    this.analytics.identify({
      num_contacts: numContacts,
    });

    this.analytics.track(eName, handleValues({ relationship, attachments }), {}, () =>
      Log.debug(eName),
    );
  },
  personRemoved(relationship, attachments, numContacts) {
    const eName = 'Person Removed';
    if (!this.analytics) return;

    this.analytics.identify(this._userID, { num_contacts: numContacts });

    this.analytics.track(eName, handleValues({ relationship, attachments }), {}, () =>
      Log.debug(eName),
    );
  },
  kickstartOpened(goalType) {
    const eName = 'Kickstart Opened';
    if (!this.analytics) return;
    this.analytics.track(eName, handleValues({ goal_type: goalType }));
  },
  kickstartClosed(goalType) {
    const eName = 'Kickstart Closed';
    if (!this.analytics) return;
    this.analytics.track(eName, handleValues({ goal_type: goalType }));
  },
  kickstartApproved(goalType, amount) {
    const eName = 'Kickstart Approved';
    if (!this.analytics) return;
    this.analytics.track(eName, handleValues({ goal_type: goalType, amount }));
  },
  goalBookmarked(goalType) {
    const eName = 'Goal Bookmarked';
    if (!this.analytics) return;
    this.analytics.track(eName, handleValues({ goal_type: goalType }));
  },
  healthHandoffApplied(handoffType) {
    const eName = 'Health Handoff Applied';
    if (!this.analytics) return;
    this.analytics.track(
      eName,
      handleValues({
        handoff_type: handoffType,
      }),
      {},
      () => Log.debug(eName, handoffType),
    );
  },
  healthHandoffInitiated(handoffType) {
    const eName = 'Health Handoff Initiated';
    if (!this.analytics) return;
    this.analytics.track(
      eName,
      handleValues({
        handoff_type: handoffType,
      }),
      {},
      () => Log.debug(eName, handoffType),
    );
  },
};
export default lib;
