import React, { useMemo, useCallback, useState } from 'react';
import { useQuery, HealthApplicationQueryData, queries, useMutation, mutations } from '@data';
import { navigate, routes } from '@navigate';
import { WorkHoursForm } from '@app/forms_old';
import { determineOtherCoverage } from '@app/navigate/EDENavigationGuard';
import { getMembers } from '@app/data/utils';

const WorkHours = ({ applicationID }) => {
  const [activeChild, setActiveChild] = useState(0);
  const [updateAll, setUpdateAll] = useState(true);

  const { loading, data } = useQuery<HealthApplicationQueryData>(queries.HEALTH_APPLICATION, {
    variables: { applicationID },
    skip: !applicationID,
  });

  const { members, children, parents } = useMemo(() => {
    const members = getMembers(data);

    return {
      members,
      children: members?.filter((m) => m.relation === 'CHILD'),
      parents: members?.filter((m) => m?.relation === 'SELF' || m?.relation === 'SPOUSE'),
    };
  }, [data?.viewerTwo?.health?.application]);

  const handleNext = useCallback(
    () => navigate(determineOtherCoverage(members) ? routes.EDE_OTHER_COVERAGE : routes.EDE_REVIEW),
    [members],
  );

  const [upsert, { loading: upserting }] = useMutation(mutations.UPSERT_APPLICATION_MEMBERS);

  const handleSubmit = (values) => {
    upsert({
      variables: {
        id: applicationID,
        lastUsedRoute: 'member-work-hours',
        input: values.members.map((m) => {
          return {
            id: m?.id,
            parent1WeeklyWorkHourQuantity: Number(
              values.members[activeChild].parent1WeeklyWorkHourQuantity,
            ),
            parent2WeeklyWorkHourQuantity: Number(
              values.members[activeChild].parent2WeeklyWorkHourQuantity || 0,
            ),
          };
        }),
      },
    }).then(() => handleNext());
  };

  const handleBack = () => {
    if (activeChild !== 0) {
      setActiveChild(activeChild - 1);
    } else {
      navigate(routes.EDE_SEP);
    }
  };

  return (
    <WorkHoursForm
      applicationID={applicationID}
      initialValues={{ members: children }}
      parents={parents}
      setActiveChild={setActiveChild}
      setUpdateAll={setUpdateAll}
      activeChild={activeChild}
      loading={upserting || loading}
      updateAll={updateAll}
      onSubmit={handleSubmit}
      onBack={handleBack}
    />
  );
};

export const WorkHoursView = {
  name: routes.EDE_MEMBER_WORK_HOURS,
  component: WorkHours,
  options: {
    title: 'Work hours',
  },
};
