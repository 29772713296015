import React, { useEffect } from 'react';
import Intercom from 'react-intercom';
import LogRocket from 'logrocket';

import { Env, Segment, setSentryUser, checkCMS } from '@app/utils';
import { useResponsive } from '@uikit';
import { useDeprecatedMutation, useDeprecatedQuery } from '@data';
import { Platform } from 'react-native';

/**
 * Centralizing all web specific support/analytics tools
 *
 * Segment sends to Heap, Mixpanel, Customer.io, and others
 * https://github.com/nhagen/react-intercom/blob/master/src/index.js
 * https://app.logrocket.com/6an89b/catch-production/settings/setup
 */
const AuthenticatedSupport = () => {
  const { longRange } = useResponsive();
  const [startSession] = useDeprecatedMutation('startSession', {
    variables: {
      input: {
        version: Env.buildConfig?.version,
        deviceType: Platform.OS,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    },
  });
  const { userId, givenName, familyName, intercomHash, email, phoneNumber } = useDeprecatedQuery(
    'analyticsContext',
    { variables: { platform: 'WEB' } },
  );

  const enable = Env.isProd || checkCMS(email) || email?.includes('jess+');

  /**
   * @todo make sure EBP is properly updating
   * @todo only keep this live until end of OE 2023-01-16
   * */
  useEffect(() => {
    startSession();
  }, []);

  useEffect(() => {
    if (enable && userId) {
      Segment.identifyUser(userId, {
        name: `${givenName} ${familyName}`,
        email,
        givenName,
        familyName,
        phoneNumber,
        phone: phoneNumber,
      });

      LogRocket.init('6an89b/catch-production', {
        network: {
          requestSanitizer: (request) => {
            if (request.url.toLowerCase() === 'https://cognito-idp.us-west-2.amazonaws.com/') {
              request.body = null;
            }

            if (request?.body?.variables?.user?.ssn) {
              request.body.variables.user.ssn = '';
            }

            return request;
          },
        },
      });

      LogRocket.identify(userId, {
        name: `${givenName} ${familyName}`,
        email,
        phoneNumber,
        phone: phoneNumber,
      });

      LogRocket.getSessionURL(function (sessionURL) {
        Segment.track('LogRocket', { sessionURL });
      });

      setSentryUser({ email, id: userId });
    }
  }, [userId, givenName, familyName, email, phoneNumber, enable]);

  return (
    <>
      {userId && (
        <Intercom
          appID={Env.isProd ? 'pniw40fg' : 'qn5cenup'}
          vertical_padding={longRange === 'mobile' ? 84 : 20}
          user_id={userId}
          name={`${givenName} ${familyName}`}
          email={email}
          user_hash={intercomHash}
          hide_default_launcher={true}
        />
      )}
    </>
  );
};

export default AuthenticatedSupport;
