import gql from 'graphql-tag';
import { GoalType } from '@types';

export type TimeSeriesContributionDataPoint = {
  month: number;
  year: number;
  totalContributionsValue: number;
};

export type GoalTimeSeriesContributionData = {
  goalType: GoalType;
  goalID: string;
  goalName: string;
  goalSlug: string;
  data: TimeSeriesContributionDataPoint[];
};

export const GOAL_CONTRIBUTIONS_FOR_YEAR = gql`
  query GoalContributionsForYear($year: Int!) {
    viewerTwo {
      id
      goalContributionsForYear(year: $year) {
        goalID
        goalType
        goalName
        goalSlug
        data {
          month
          year
          totalContributionsValue
        }
      }
    }
  }
`;

const formatter = (data: {
  viewerTwo: { goalContributionsForYear: GoalTimeSeriesContributionData[] };
}) => data && data.viewerTwo;

const query = {
  document: GOAL_CONTRIBUTIONS_FOR_YEAR,
  formatter,
};

export default query;
