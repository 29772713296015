import React, { useState } from 'react';
import { Copy, KeyboardSet } from '@types';
import { View, StyleSheet } from 'react-native';
import { TextInput } from 'react-native';
import { useTheme, useID, useResponsive, Text, IconSvg, Asset } from '@uikit';
import { FieldWrapper } from '../FieldWrapper';
import { handleAutoCompleteType, getKeyboardConfig } from '../utils';
import { fontFamilyFix } from '@app/_ui-kit/theme/themes';

interface TextFieldProps {
  label?: Copy;
  sublabel?: Copy;
  keyboard?: KeyboardSet;
}

const TextField: React.FC<TextFieldProps> = React.forwardRef(
  (
    {
      row,
      label,
      sublabel,
      extraLabel,
      onSubmit,
      onKeyPress,
      error,
      errorItem,
      focused,
      style,
      large,
      rounded,
      tooltip,
      subtext,
      signature,
      question,
      value,
      bare,
      short,
      onChange,
      onBlur,
      onFocus,
      grouped,
      fullWidth,
      input: fieldInput,
      meta: fieldMeta,
      gutter,
      type,
      flex,
      hideError,
      centerAlignError,
      containerStyle,
      siblings,
      center,
      centerLabel,
      alert,
      largeLabel,
      isLoading,
      placeholder,
      svg,
      testID,
      testSuffix,
      required,
      req,
      disabled,
      labelID,
      affirmValidity,
      autoCompleteType,
      noGutter,
      first,
      search,
      keyboard,
      xl,
      tabular,
      secureTextEntry,
      sensitive,
      format,
      ...rest
    },
    ref,
  ) => {
    const { theme, colorScheme } = useTheme();
    const { isAndroid } = useResponsive();

    const [isFocused, setFocused] = useState<boolean>(false);

    const handleFocus = () => {
      if (onFocus) onFocus();
      setFocused(true);
    };

    const handleBlur = () => {
      if (onBlur) onBlur();
      setFocused(false);
    };

    const fallbackLabelID = useID();
    labelID = labelID || fallbackLabelID;
    const fieldID = useID();
    const sublabelID = useID();
    const handleKeyPress = ({ nativeEvent: { key } }) => {
      onKeyPress && onKeyPress(key);
      key === 'Enter' && onSubmit && onSubmit();
    };
    const input = fieldInput || { value, onChange };
    const meta = fieldMeta || { error, active: focused };
    const hasError = (!hideError && meta.touched && meta.error) || error;
    const testLabel = testID ? [testID, '-', testSuffix].join('') : input?.name;

    const field = (
      <TextInput
        data-name={input.name}
        onKeyPress={handleKeyPress}
        placeholder={placeholder || undefined}
        placeholderTextColor={isAndroid && colorScheme === 'dark' ? 'grey' : undefined}
        autoCompleteType={handleAutoCompleteType(autoCompleteType)}
        onFocus={handleFocus}
        onBlur={handleBlur}
        returnKeyType="next"
        aria-labelledby={labelID}
        accessibilityLabel={testLabel}
        testID={testLabel}
        secureTextEntry={type === 'password' || input.type === 'password' || secureTextEntry}
        style={[
          theme.textFieldBase,
          theme.textField,
          search && theme.searchField,
          large && theme.textFieldLarge,
          xl && theme.textFieldXl,
          rounded && theme.textFieldRounded,
          ((meta.error && meta.touched) || (alert && meta.pristine) || error) &&
            theme.textFieldError,
          (isFocused || meta.active) && theme.textFieldFocused,
          isLoading && theme.disabled,
          fullWidth && theme.fullWidth,
          short && theme.shortField,
          gutter && theme[`${gutter}Gutter${grouped ? '1' : ''}`],
          flex && theme[`flex${flex}`],
          center && theme.centerText,
          svg && theme.hasIconRight,
          grouped && theme.groupedField,
          tabular && theme.tabular,
          grouped && first && theme.groupedFieldFirst,
          signature && theme.signatureField,
          style,
          fontFamilyFix(large ? 'page' : xl ? 'xl' : 'p', '500', signature),
        ]}
        disabled={disabled}
        nativeID={fieldID}
        ref={ref}
        dataSet={sensitive ? { private: 'redact' } : {}}
        format={format}
        {...getKeyboardConfig(keyboard)}
        {...input}
        value={format ? format(input.value) : input.value}
        {...rest}
      />
    );
    // if (bare) {
    //   return field;
    // }

    return (
      <FieldWrapper
        label={label}
        required={required}
        tooltip={tooltip}
        fieldID={fieldID}
        labelID={labelID}
        sublabelID={sublabelID}
        extralabel={extraLabel}
        fullWidth={fullWidth}
        sublabel={sublabel || question}
        grouped={grouped}
        subtext={subtext}
        error={!hideError && (error || (hasError && meta.error))}
        centerAlignError={centerAlignError}
        centerLabel={centerLabel}
        style={containerStyle}
        largeLabel={largeLabel}
        errorItem={errorItem}
        disabled={disabled}
        row={row}
        noGutter={noGutter}
      >
        {field}

        {svg && (
          <View style={theme.textFieldIcon}>
            <Asset size="xs" svg={svg} color="subtle" />
          </View>
        )}

        {!!siblings && <View style={[styles.sibling]}>{siblings}</View>}
      </FieldWrapper>
    );
  },
);

const styles = StyleSheet.create({
  sibling: {
    position: 'absolute',
    right: 0,
    minWidth: 75,
    flex: 1,
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

TextField.displayName = 'TextField';
export default TextField;
