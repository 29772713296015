import React, { useEffect, useState } from 'react';
import { routes } from '@navigate';
import { useCopy } from '@app/utils';
import {
  useQuery,
  useMutation,
  UserDetailsQueryData,
  queries,
  mutations,
  UpdateUserResponse,
  UpdateUserVars,
  UploadSignatureResponse,
  UploadSignatureVars,
} from '@data';
import { Loading, Text } from '@uikit';
import { AgreementsBlueprint } from '@app/blueprints';

const PREFIX = 'catch.module.retirement.FolioAgreementView';
const HEADER = 'catch.module.plan.AgreementHeaderView';

const InvestmentAgreement = ({ handleNext }) => {
  const { c } = useCopy(PREFIX);
  const { c: c2 } = useCopy(HEADER);
  const [disclosure1, setDisclosure1] = useState('');
  const [disclosure2, setDisclosure2] = useState('');

  useEffect(() => {
    fetch(`https://s.catch.co/legal/folio-account-opening-summary-part1.md`)
      .then((res) => res.text())
      .then((text) => setDisclosure1(text));

    fetch(`https://s.catch.co/legal/folio-account-opening-summary-part2.md`)
      .then((res) => res.text())
      .then((text) => setDisclosure2(text));
  }, []);

  const { loading, data } = useQuery<UserDetailsQueryData>(queries.USER_DETAILS, {
    fetchPolicy: 'cache-first',
  });

  const [update, { loading: updatingUser }] = useMutation<UpdateUserResponse, UpdateUserVars>(
    mutations.UPDATE_USER,
  );
  const [upload, { loading: uploadingSignature }] = useMutation<
    UploadSignatureResponse,
    UploadSignatureVars
  >(mutations.UPLOAD_SIGNATURE);

  const onSubmit = async ({ subjectBackupWithholding, signature }) => {
    await Promise.all([
      upload({ variables: { signature } }),
      update({ variables: { user: { subjectBackupWithholding } } }),
    ]);

    handleNext();
  };

  if (!disclosure1 || !disclosure2) {
    return <Loading accentColor="retirement" />;
  }

  return (
    <AgreementsBlueprint
      title={c2(`investment.title`)}
      loading={loading}
      submitting={updatingUser || uploadingSignature}
      disclosure={{ body: disclosure1 + '\n\n' + disclosure2 }}
      legalName={data?.viewer?.user?.legalName}
      handleSubmit={onSubmit}
      agreements={[
        {
          name: 'subjectBackupWithholding',
          required: false,
          label: c('backup', {
            only: (
              <Text size="fp" weight="bold" key="inline-text">
                {c('only')}
              </Text>
            ),
          }),
        },
      ]}
    />
  );
};

export const InvestmentAgreementView = {
  name: routes.INVESTMENT_AGREEMENT,
  component: InvestmentAgreement,
  options: {},
};
