import React from 'react';
import shallow from 'zustand/shallow';
import { useCopy } from '@app/utils';
import { useAuth, useAuthEvents } from '@client';
import { navigate, routes } from '@navigate';
import { AuthBlueprint } from '@blueprints';
import { Button } from '@uikit';

export const ForgotPassword = () => {
  const { c } = useCopy('catch.module.login');

  useAuthEvents({
    onPendingNewPassword: () => navigate(routes.CONFIRM_PASSWORD),
  });

  const { requestNewPassword, submitting, error } = useAuth(
    (state) => ({
      requestNewPassword: state.requestNewPassword,
      submitting: state.loading,
      error: state.error,
    }),
    shallow,
  );

  return (
    <AuthBlueprint
      loading={false}
      submitting={submitting}
      error={error}
      title={c('ForgotPasswordView.title')}
      subtitle={c('ForgotPasswordView.subtitle')}
      formConfig={{
        initialValues: {},
        fields: [
          {
            type: 'alias',
            name: 'alias',
            required: true,
            combo: true, // defaults to combo
          },
        ],
        onSubmit: requestNewPassword,
      }}
      actions={({ handleSubmit, disabled }) => (
        <Button testID="forgot-password" disabled={disabled} onPress={handleSubmit}>
          {c('PasswordResetForm.submitButton')}
        </Button>
      )}
    />
  );
};

export const ForgotPasswordView = {
  name: routes.FORGOT_PASSWORD,
  component: ForgotPassword,
  guest: true,
  options: {},
};
