import { BankErrorCode, BankSyncStatus } from '@app/types';
import gql from 'graphql-tag';

export interface ApolloBankAccount {
  id: string;
  isPrimary: boolean;
  isWatched: boolean;
  accountName: string;
  accountNumber: string;
}

export interface ApolloPendingAccount {
  id: string;
  isPrimary: boolean;
  accountName: string;
  accountNumber: string;
  bankName: string;
}

export interface ApolloBankLink {
  id: string;
  bankName: string;
  syncStatus: BankSyncStatus;
  errorCode: BankErrorCode;
  identityErrorCode?: string;
  lastUpdated: Date;
  plaidBank: {
    id: string;
    logo: string;
    primaryColor: string;
    url: string;
  };
  accounts: Array<ApolloBankAccount>;
}

export interface BankLinksQueryData {
  viewer: {
    id: string;
    bankLinks: Array<ApolloBankLink>;
    pendingAccounts: Array<ApolloPendingAccount>;
  };
}

export const BankLinksQuery = gql`
  query BankLinksQuery {
    viewer {
      id
      bankLinks(input: { includeNotWatched: true }) {
        id
        bankName
        syncStatus
        errorCode
        lastUpdated
        providerType
        identityErrorCode
        plaidBank {
          id: bankID
          logo
          primaryColor
          url
        }
        accounts {
          id
          isPrimary
          isWatched
          accountName: name
          accountNumber
        }
      }
      pendingAccounts {
        id
        isPrimary
        accountName: name
        accountNumber: mask
        bankName: institutionName
      }
    }
  }
`;
