import React, { useMemo } from 'react';
import { differenceInDays } from 'date-fns';
import shallow from 'zustand/shallow';
import { useQuery, CoverageDetailQueryData, queries, getApplicationIssues } from '@data';
import { Stack, Card } from '@layouts';
import { Button, LineItem, Banner, Text } from '@uikit';
import { ScreenDefinition, routes, sheets, useSheet, open, navigate } from '@navigate';
import { PeopleList, InProgressApplicationUpdates, PlanDetailLayout } from '@common';
import {
  BENEFITS,
  capitalizeAll,
  formatBenefit,
  LongFormDate,
  toDate,
  safeFormatDate,
  useCopy,
  capitalize,
  lowercase,
  formatList,
} from '@app/utils';
import { PolicyCard } from '@app/partial';
import HealthPlanCosts from '@app/partial/coverage/HealthPlanCosts';

const CoverageDetail = ({ type, policyID }) => {
  const { openSheet } = useSheet((state) => ({ openSheet: state.open }), shallow);

  const { c } = useCopy('catch');

  const { c: summary } = useCopy('catch.PlanSummary');
  const { c: asyncCopy } = useCopy('catch.ede.Confirmation');

  const { data } = useQuery<CoverageDetailQueryData>(queries.COVERAGE_DETAIL, {
    variables: { policyID },
    skip: !policyID,
  });

  const {
    applicationID,
    enrollId,
    policy,
    members,
    asyncStatus,
    startDate,
    endDate,
    pathwayType,
    issue,
    coverageYear,
    isPartner,
    hasAssociatedDentalPlan,
    coverageCancelled,
    isCoverageFuture,
    isPendingPayment,
  } = useMemo(() => {
    const policy = data?.viewerTwo?.health?.policy;
    const { issuesByStatus } = getApplicationIssues(policy?.application);

    return {
      policy,
      applicationID: policy?.application?.id,
      enrollId: policy?.application?.enrollment?.id,
      application: policy?.application,
      members: policy?.enrolledDependents,
      coverageYear: policy?.coverageYear,
      asyncStatus: policy?.application?.asyncStatus,
      startDate: policy?.startDate,
      endDate: policy?.endDate,
      pathwayType: policy?.application?.pathwayType,
      issue: issuesByStatus.action.length > 0 ? issuesByStatus.action[0] : undefined,

      // computed
      isPartner: /HEALTH_SHERPA|OSCAR/.test(policy?.application?.pathwayType || ''),
      hasAssociatedDentalPlan: policy?.application?.enrollment?.hasAssociatedDentalPlan,
      coverageCancelled: policy?.policyStatus === 'CANCELLED',
      isCoverageFuture: policy?.policyStatus === 'PENDING_START_DATE',
      isPendingPayment: policy?.policyStatus === 'ACTIVE_PENDING_PAYMENT',
    };
  }, [data]);

  const insuranceType = BENEFITS[type];

  // either managed with hc.gov or healthsherpa
  const handleManagedOutside = (managedBy) => {
    openSheet(sheets.COVERAGE_MANAGED_OUTSIDE, {
      managedBy,
      reason: hasAssociatedDentalPlan ? 'DENTAL' : managedBy,
    });
  };

  const handleCIC = () => {
    if (isPartner) {
      handleManagedOutside(pathwayType);
    } else {
      open(routes.CIC_CHANGE_SELECTION, { applicationID, step: 'changeSelection' });
    }
  };

  const handleCoverageUpdate = () => {
    if (isPartner) {
      handleManagedOutside(pathwayType);
    } else {
      open(routes.CIC_MANAGE_COVERAGE_INTRO, { applicationID });
    }
  };

  const is30DaysBeforeEnd = !!endDate ? differenceInDays(toDate(endDate), new Date()) < 30 : false;
  const isAsyncPending = !/NOT_APPLICABLE|COMPLETED_NO_DMI/.test(asyncStatus || '');
  const canMakeChange = !isAsyncPending && /ACTIVE|PENDING/.test(policy?.policyStatus || '');

  return (
    <PlanDetailLayout
      loading={!data}
      color="coverageLight"
      title={formatBenefit.name({ type: insuranceType })}
      subtitle={coverageYear}
      tabKey="health"
      sheetConfig={{
        name: sheets.COVERAGE_DETAIL_MENU,
        data: { type, policyID },
      }}
      planSummary={{
        title: !!isAsyncPending ? (
          summary('coverageStatus', { status: 'PENDING' })
        ) : isCoverageFuture ? (
          <LongFormDate shortMonth>{startDate}</LongFormDate>
        ) : is30DaysBeforeEnd && !isAsyncPending ? (
          summary('coverageEnding', {
            end: <LongFormDate shortMonth>{endDate}</LongFormDate>,
          })
        ) : (
          summary('coverageStatus', { status: policy?.policyStatus })
        ),
        subtitle: summary('coverageLabel', {
          status: isCoverageFuture ? 'PENDING_DATE' : policy?.policyStatus,
        }),
        banners: [
          // CMS CR52
          asyncStatus && asyncStatus !== 'NOT_APPLICABLE' ? (
            <Banner
              key="upload-docs"
              testID="upload-docs"
              title={asyncCopy(`async.${asyncStatus}.title`)}
              subtitle={asyncCopy(`async.${asyncStatus}`)}
            />
          ) : null,
          issue ? (
            <Card
              key="upload-docs"
              containerStyle={{ width: '100%' }}
              gradient="coverageLight"
              shadow
            >
              <Stack spacing="1">
                <>
                  <Text weight="medium" size="h3">
                    {c('health.docsRequired.title', {
                      date: safeFormatDate(issue?.resolveBy),
                    })}
                  </Text>
                  <Text color="subtle">
                    {c('health.docsRequired.subtitle', {
                      person: formatList(issue?.dependents, (m) => m?.givenName),
                      date: safeFormatDate(issue?.resolveBy, 'MMMM do'),
                      issue: lowercase(c(`ede.enums.${issue?.copyType}`)),
                    })}
                  </Text>
                </>
                <Button
                  testID="upload-documentation"
                  full
                  onPress={() => navigate(routes.EDE_DMI, { applicationID })}
                >
                  Upload documentation
                </Button>
              </Stack>
            </Card>
          ) : null,
          isPendingPayment ? (
            <Banner
              onPress={() => openSheet(sheets.PAYMENT_REDIRECT, { policy })}
              testID="payment-redirect-banner"
              title={summary('pendingPremiumPayment', {
                startDate: safeFormatDate(policy?.dueDate, 'MMM dd, yyyy'),
              })}
              accessory="navigation"
            />
          ) : null,
        ],
      }}
      topRightComponent={
        <>
          <PolicyCard
            type={policy?.productType}
            badgeType="PLAN_DETAILS"
            footerType="DEDUCTIBLE"
            plan={policy?.planName || ''}
            carrier={policy?.carrier || ''}
            metalLevel={policy?.providerPlan?.metalLevel}
            planType={policy?.providerPlan?.type}
            policyStatus={policy?.policyStatus}
            premium={policy?.premiumEffective}
            strikeoutPremium={policy?.premium}
            familyDeductible={policy?.providerPlan?.familyDeductible}
            individualDeductible={policy?.providerPlan?.individualDeductible}
            startDate={policy?.startDate}
            endDate={policy?.endDate}
          />
          <InProgressApplicationUpdates year={coverageYear} />
        </>
      }
      controls={
        !coverageCancelled ? (
          <Stack spacing="0b">
            <Button disabled={!canMakeChange} full alt onPress={handleCIC}>
              {c('health.HealthOverviewView.cicLink')}
            </Button>
            <Button disabled={!canMakeChange} full alt onPress={handleCoverageUpdate}>
              {c('health.HealthOverviewView.adjustCovered')}
            </Button>
          </Stack>
        ) : (
          <Stack spacing="0b">
            <Button alt gutter="right" onPress={() => open(routes.COVERAGE)}>
              {c('health.HealthOverviewView.removeHealthBtn')}
            </Button>
            <Button onPress={() => open(routes.EDE_INTRO)}>
              {c('health.HealthOverviewView.addHealthBtn')}
            </Button>
          </Stack>
        )
      }
      additionalDetailTab={
        policy?.providerPlan &&
        !coverageCancelled && (
          <HealthPlanCosts
            benefits={policy?.providerPlan?.benefits}
            isDental={insuranceType === BENEFITS['DENTAL']}
          />
        )
      }
      leftColumnComponent={
        <Stack spacing="1">
          {!coverageCancelled && (
            <PeopleList members={members} sublabel={(m) => capitalize(m?.relation)} />
          )}
        </Stack>
      }
      details={
        <Stack separatorComponent spacing="2">
          <LineItem
            left={c('health.HealthOverviewView.providerLabel')}
            right={policy?.providerPlan?.title}
          />
          <LineItem
            left={c('health.HealthOverviewView.planLabel')}
            right={policy?.providerPlan?.issuer?.title}
          />
          <LineItem
            left={c('health.HealthOverviewView.planTypeLabel')}
            right={policy?.providerPlan?.type}
          />
          <LineItem
            left={c('health.HealthOverviewView.policyNumberLabel')}
            right={policy?.policyNumber || policy?.exchangePolicyNumber}
          />
          <LineItem
            left={c('health.HealthOverviewView.subscriberIDLabel')}
            right={policy?.subscriberID || policy?.exchangeSubscriberID}
          />
          <LineItem
            left={c('health.HealthOverviewView.effectiveDatesLabel')}
            right={c('health.HealthOverviewView.effectiveDates', {
              startDate: safeFormatDate(startDate),
              endDate: safeFormatDate(endDate),
            })}
          />
        </Stack>
      }
    />
  );
};

/**
 * @todo E-3287
 */
export const CoverageDetailView: ScreenDefinition = {
  name: 'COVERAGE_DETAIL',
  component: CoverageDetail,
  options: {
    bg: 'coverageLight',
    title: ({ type }) => capitalizeAll(type),
    buttons: {
      sheetMenu: sheets.COVERAGE_DETAIL_MENU,
    },
  },
};
