import gql from 'graphql-tag';
import access from 'safe-access';

import { typeMap } from '@app/utils';
import { date } from '@app/utils/format/date';
import { formatCurrency } from '@app/utils/format/currency';

export const PLAN = gql`
  query Plan {
    viewer {
      id
      incomeState
      goals {
        id
        type: goalType
        status
        totalBalance
        availableBalance
        paycheckPercentage
        slug
        ... on TaxGoal {
          state
        }
        ... on PTOGoal {
          plannedTarget
          unplannedTarget
        }
        ... on RetirementGoal {
          accountType
        }
        ... on FamilyLeaveGoal {
          numDays
        }
        ... on CustomGoal {
          icon
          name
          target
          endDate
        }
      }
      benefits(input: { includeNotLive: false }) {
        id
        benefitReference {
          type
        }
      }
    }
  }
`;

/**
 * Helpers
 */

const setupGoal = (g) => /^ACTIVE|PAUSED|GOAL_MET/.test(g?.status);

const isActive = (g) => /^ACTIVE/.test(g?.status);

const formatter = (data) => {
  const get = access(data);

  if (!data) {
    return { data: false };
  }

  const benefitCoverage = {
    TAX: null,
    PTO: null,
    RETIREMENT: null,
    HEALTH_EXPENSES: null,
    FAMILY_LEAVE: null,
    EMERGENCY_SAVINGS: null,
    DENTAL: null,
    VISION: null,
    LIFE: null,
    HEALTH_BENEFIT: null,
    CUSTOM: null,
  };

  const other = [];
  const goals = [...(data?.viewer?.goals || [])];

  // creates list of setup goals, while marking the goal as setup

  const setup =
    get('viewer.goals.reduce()', [
      (acc, g) => {
        if (!setupGoal(g)) return acc;

        benefitCoverage[g?.type] = 'SETUP';

        // compute fields
        const computed = {};
        if (g?.type === 'PTO') {
          computed.numDays = g?.plannedTarget + g?.unplannedTarget;
        }

        if (g?.type === 'TAX') {
          computed.state = get('viewer.incomeState');
        }

        if (g?.type === 'FAMILY_LEAVE') {
          computed.numWeeks = Math.round(g?.numDays / 5);
        }

        if (g?.type === 'CUSTOM_SAVINGS') {
          computed.target = formatCurrency(g?.target);
          computed.end = date(g?.endDate, 'RELATIVE');
        }

        return [...acc, { ...g, ...computed }];
      },
      [],
    ]) || [];

  //goals in draft or active status (i.e. not deleted), we don't want to show duplicates on saved view
  const goalsToAdd = Object.keys(typeMap).reduce(
    (acc, type) =>
      type !== 'CUSTOM_SAVINGS' &&
      type !== 'TAX' &&
      !goals.some((g) => g.status !== 'DELETED' && g.type === type)
        ? [...acc, { type }]
        : acc,
    [],
  );

  return {
    data,
    setup,
    other,
    goalsToAdd,
    ...goals.reduce(
      (sum, g) => {
        return {
          totalBalance: sum.totalBalance + (g?.type === 'TAX' ? 0 : g?.totalBalance),
          totalActiveRate: sum.totalActiveRate + (isActive(g) ? g?.paycheckPercentage : 0),
          totalRate: sum.totalRate + g?.paycheckPercentage,
          setupGoals: setupGoal(g) || sum.setupGoals,
        };
      },
      {
        totalBalance: 0,
        totalActiveRate: 0,
        totalRate: 0,
        setupGoals: false,
      },
    ),
  };
};

export default {
  document: PLAN,
  formatter,
};
