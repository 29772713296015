import React, { useState, useEffect, useMemo } from 'react';
import { routes, open, exit, navigate } from '@navigate';
import { BasicLayout, Stack, Toolbar } from '@app/layouts';
import { Button } from '@uikit';
import { useMutation, mutations, updates, queries, useDeprecatedMutation } from '@data';
import { goToHealthApp, handleHealthErrorsWithProps, Segment } from '@app/utils';
import { PolicyCard } from '@app/partial';

/**
 * Health Renew
 *
 * Import view handles importing applications and allowing
 * users to decide whether to stick with their plan or choose a new one
 *
 */
const HealthRenewImport = ({
  refetch,
  renewalPolicies,
  continueApplication,
  oeCoverageYear,
  isOpenEnrollment,
}) => {
  const [loading, setLoading] = useState(true);

  const [importAll] = useMutation(mutations.HEALTH_LINK_IMPORT, {
    update: updates.UPDATE_EXISTING_APPLICATIONS,
  });

  const [fetchEligibility, { loading: fetching }] = useMutation(mutations.FETCH_ELIGIBILITY, {
    update: (cache) =>
      updates.ELIG_REFETCHED(cache, {
        didRefetch: true,
        applicationID: continueApplication?.id,
      }),
  });

  const [submitEnrollment, { loading: submitting }] = useDeprecatedMutation('submitEnrollment', {
    onCompleted: () => exit(routes.COVERAGE),
    ...handleHealthErrorsWithProps(),
    refetchQueries: [queries.COVERAGE, queries.HOME],
  });

  const [upsertExplorer, { loading: upserting }] = useMutation(mutations.UPSERT_EXPLORER);
  const [startExplorer, { loading: starting }] = useMutation(mutations.IMPORT_EXPLORER_FROM_APP);

  const policies = useMemo(() => {
    return (continueApplication?.policies || [])
      .filter((p) => /ACTIVE|PENDING/.test(p.policyStatus))
      .sort((a, b) => (a.type > b.type ? 1 : -1));
  }, [continueApplication, renewalPolicies]);

  const displayPolicies = useMemo(() => {
    return (continueApplication?.policies || renewalPolicies || [])
      .filter((p) => /ACTIVE|PENDING/.test(p.policyStatus))
      .sort((a, b) => (a.type > b.type ? 1 : -1));
  }, [continueApplication, renewalPolicies]);

  const handleExplorer = async ({ browse }) => {
    // updates the explorer to use 2023 info
    await startExplorer({
      variables: {
        applicationID: policies?.[0]?.application?.id,
        coverageYear: oeCoverageYear,
      },
    });

    Segment.track('Window Shopping Completed', {
      coverageYear: oeCoverageYear,
    });

    if (browse) {
      // navigate to explorer to choose new plan
      open(routes.EXPLORER_CHOOSE_PLAN, { standalone: true });
    } else {
      // sets the current plan
      const providerPlanID = policies?.[0]?.providerPlan.planID;
      await upsertExplorer({ variables: { input: { providerPlanID } } });
      exit();
    }
  };

  useEffect(async () => {
    await importAll();
    await refetch({ fetchPolicy: 'network-only' });
    setLoading(false);
  }, []);

  const handleApplication = async () => {
    // sets the current plan
    const providerPlanID = policies?.[0]?.providerPlan.planID;
    await upsertExplorer({ variables: { input: { providerPlanID } } });

    /**
     * Based on the status of the application, do one of the following:
     * 1) For BAR applications, we show agreements and trigger a resubmit
     * 2) For an already enrolled application, we fetch elig. and auto submit enrollment
     * 3) For submitted applications, bring user to post enrollment pages
     * 4) For any other app, continue the application flow
     */
    if (continueApplication?.status === 'ENROLLED') {
      if (policies.length === 0) {
        navigate(routes.HEALTH_RENEW_RESUBMIT); // handle BAR auto submit
      } else {
        await fetchEligibility({ variables: { applicationID: continueApplication?.id } });
        submitEnrollment({ variables: { applicationID: continueApplication?.id } });
      }
    } else if (continueApplication?.status === 'SUBMITTED') {
      // navigate into post enrollment flow
      goToHealthApp({ ...continueApplication, lastUsedRoute: 'results' }, { goTo: open });
    } else {
      goToHealthApp(continueApplication, { goTo: open });
    }
  };

  const goToPlans = async () => {
    await fetchEligibility({ variables: { applicationID: continueApplication?.id } });
    goToHealthApp({ ...continueApplication, lastUsedRoute: 'plans' }, { goTo: open });
  };

  // only allow viewing all plans if the user has a submitted app and everything
  const canViewPlans =
    /SUBMITTED|ENROLLED/.test(continueApplication?.status || '') && policies?.length > 0;

  const disabled = loading || upserting || starting || fetching || submitting;

  return (
    <BasicLayout
      showLoaderOnly
      loading={loading || fetching || submitting}
      title="Your current coverage"
      subtitles={[
        isOpenEnrollment
          ? ''
          : "Your 2022 coverage is below. We'll finalize your renewal on November 1. You can also browse and lock in new plans.",
      ]}
      toolbar={
        isOpenEnrollment ? (
          <Toolbar>
            {canViewPlans && (
              <Button accentColor="coverageLight" onPress={goToPlans} disabled={disabled}>
                View all plans
              </Button>
            )}
            <Button accentColor="coverage" onPress={handleApplication} disabled={disabled}>
              Keep this coverage
            </Button>
          </Toolbar>
        ) : (
          <Toolbar>
            <Button
              accentColor="coverageLight"
              onPress={() => handleExplorer({ browse: true })}
              disabled={disabled}
            >
              Browse all plans
            </Button>
            <Button
              accentColor="coverage"
              onPress={() => handleExplorer({ browse: false })}
              disabled={disabled}
            >
              Looks good
            </Button>
          </Toolbar>
        )
      }
    >
      <Stack spacing="2">
        {displayPolicies.map((policy) => (
          <PolicyCard
            key={policy.id}
            type={policy?.productType}
            plan={policy?.providerPlan?.title}
            carrier={policy?.providerPlan?.issuer?.title}
            metalLevel={policy?.providerPlan?.metal}
            planType={policy?.providerPlan?.type}
            policyStatus={policy?.policyStatus}
            premium={policy?.premiumEffective}
            strikeoutPremium={policy?.premium}
            familyDeductible={policy?.providerPlan?.familyDeductible}
            individualDeductible={policy?.providerPlan?.individualDeductible}
            startDate={policy?.startDate}
            endDate={policy?.endDate}
          />
        ))}
      </Stack>
    </BasicLayout>
  );
};

export const HealthRenewImportView = {
  name: routes.HEALTH_RENEW_IMPORT,
  component: HealthRenewImport,
  options: {},
};
