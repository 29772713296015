import { useMemo } from 'react';
import { useResponsive } from '@uikit/hooks/useResponsive';
import { useLayoutContext } from './LayoutContext';
import { navBar } from './NavBar.web';

export const useHeaderHeight = () => {
  const { layout } = useLayoutContext();
  const { res } = useResponsive(navBar);

  const height = useMemo(() => {
    // if page, use the computed styles from NavBar
    if (layout === 'page') {
      return res?.size?.height;
    } else {
      return 48;
    }
  }, [res]);

  return {
    headerHeight: height,
    platformOffset: 0,
  };
};
