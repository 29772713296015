import gql from 'graphql-tag';
import { explorer } from '../queries';
import { EXPLORER_DATA } from '../fragments';

export const UPSERT_HEALTH_PREFERENCE = gql`
  mutation UpsertHealthPreference($input: HealthPreferenceInput!) {
    upsertHealthPreference(input: $input) {
      financialPreference
      plannedUsage
      drugsWanted {
        name
        rxcui
      }
      providersWanted {
        name
        npi
      }
      healthExplorerData {
        id
        ...ExplorerData
      }
    }
  }
  ${EXPLORER_DATA}
`;

/*
const updateCache = (cache, { data }) => {
  try {
    const explorerCache = cache.readQuery({ query: explorer.document });

    cache.writeQuery({
      query: explorer.document,
      data: {
        viewer: {
          ...explorerCache?.viewer,
          health: {
            ...explorerCache?.viewer?.health,
            healthExplorerData: data.upsertHealthPreference.healthExplorerData,
          },
        },
      },
    });
  } catch (e) {
    console.log(e);
  }
};
*/

export default {
  document: UPSERT_HEALTH_PREFERENCE,
  //updateCache,
};
