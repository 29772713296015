import React, { useMemo, useState } from 'react';

import { Page, Layout, Stack, Toolbar } from '@layouts';
import { LineItem, Stepper, Button } from '@uikit';
import { useGoals } from '@hooks';
import { close, ScreenDefinition, pop } from '@navigate';
import { formatBenefit } from '@app/utils';
import { useDeprecatedMutation } from '@data';
import { EstimatorValues } from '@pages/shared/EstimatorView';
import { Goal } from '@types';

type PlanEditChanges = Partial<EstimatorValues>;

/**
 * this is a very hacky component from @aa-io
 */
const PlanEditComponent = ({ goBack }) => {
  const { goals } = useGoals();
  const [changes, setChanges] = useState<Record<string, PlanEditChanges>>({});
  const newPercentage = goals?.reduce(
    (acc, val: Goal) =>
      (acc +=
        typeof changes[val.id]?.paycheckPercentage !== 'undefined'
          ? changes[val.id].paycheckPercentage
          : val.paycheckPercentage),
    0,
  );

  const goalsToRender = useMemo(() => {
    return goals?.filter((g) => g.goalType !== 'TAX'); // Tax has it's own rendering
  }, [goals]);

  const isOver100 = newPercentage >= 1;
  const [update, { loading: upserting }] = useDeprecatedMutation('upsertGoal', {
    onCompleted: () => {
      if (goBack) {
        pop();
      } else {
        close();
      }
    },
  });

  const save = () => {
    Object.entries(changes)?.forEach(([k, v]) => {
      update({
        variables: {
          input: { id: k, ...v },
        },
      });
    });
  };

  return (
    <Page color="sheet">
      <Layout.Scroll margins>
        <Layout topSpace bottomSpace>
          <Stack spacing="2" separatorComponent>
            {goalsToRender?.map((item, idx) => (
              <LineItem
                prominent
                key={item?.id}
                left={formatBenefit.name(item)}
                right={
                  <Stepper
                    disabled={upserting}
                    disabledMax={isOver100}
                    value={Math.round(
                      (typeof changes[item?.id]?.paycheckPercentage !== 'undefined'
                        ? changes[item?.id].paycheckPercentage
                        : item.paycheckPercentage) * 100,
                    )}
                    suffix="%"
                    onChange={(v) =>
                      setChanges((changes) => ({
                        ...changes,
                        [item?.id]: {
                          paycheckPercentage: v / 100,
                        },
                      }))
                    }
                  />
                }
              />
            ))}
          </Stack>
        </Layout>
      </Layout.Scroll>
      <Toolbar>
        <Button inherit disabled={upserting || isOver100} onPress={save} loading={upserting}>
          Save
        </Button>
      </Toolbar>
    </Page>
  );
};

export const PlanEditView: ScreenDefinition = {
  name: 'PLAN_EDIT',
  component: PlanEditComponent,
  options: {
    title: 'Edit Withholding',
    layout: 'sheet',
    presentation: 'pageSheet',
  },
};
