import React, { useCallback, useMemo } from 'react';

import { useCopy } from '@app/utils';
import { Link, Loading } from '@uikit';
import {
  useQuery,
  queries,
  EligibilityResultsQueryData,
  useDeprecatedMutation,
  getMostRecentEDN,
} from '@data';
import { navigate, open, routes, exit } from '@navigate';
import { FlowLayout, Section, Split, Stack } from '@layouts';
import { WrongEligibilityDetermination, MedicaidDetermination, VoterRegistration, FileDropper } from '@common';
import { EligibilityGroupList, EligibilityIssuesList, EligibilityNextSteps } from '@app/partial';
import {
  getResultsForMembers,
  getEligibilityGroups,
  getApplicationIssues,
  getEligibilityNextSteps,
} from './eligibilityResultsUtils';
import { Linking } from 'react-native';

const PREFIX = 'catch.ede.EligibilityResults';

const EligibilityResults = ({ applicationID, isGA }) => {
  const { c } = useCopy(PREFIX);

  const { loading, data } = useQuery<EligibilityResultsQueryData>(queries.ELIGIBILITY_RESULTS, {
    variables: { applicationID },
    skip: !applicationID,
  });

  const {
    groups,
    nextSteps,
    issuesSummary,
    asyncStatus,
    coverageYear,
    coverageState,
    isRequestingFinancialAssistance,
    stateDeterminesMedicaidEligibility,
    ineligibleForMedicaid,
    hasAvailablePlans,
    dsrsIdentifier,
  } = useMemo(() => {
    // pull fields from data
    const application = data?.viewerTwo?.health?.application;
    const members = application?.allMembers || [];
    const coverageYear = application?.coverageYearNumber;
    const oe = data?.reference?.health?.openEnrollmentDates;

    // during OE, if applying for oe year
    const isOEApplication = oe?.isOpenEnrollment && coverageYear === oe?.oeCoverageYear;

    // computed
    const computed = getResultsForMembers(members, isOEApplication);
    const numPlans = application?.enrollmentGroups?.reduce(
      (sum, group) => sum + group.plans.total,
      0,
    );

    // gets the most recent EDN
    const mostRecentEDN = getMostRecentEDN(application?.ednDocuments);

    return {
      // computed results and next steps
      groups: getEligibilityGroups(computed),
      nextSteps: getEligibilityNextSteps(computed),
      issuesSummary: getApplicationIssues(application || { dmis: [], svis: [] }),

      //
      coverageYear,
      coverageState: application?.coverageState,
      isRequestingFinancialAssistance: application?.isRequestingFinancialAssistance,
      stateDeterminesMedicaidEligibility:
        application?.stateReferenceData?.stateDeterminesMedicaidEligibility,
      ineligibleForMedicaid: members.filter(
        (m) => m.isRequestingCoverage && !m.medicaidEligibility,
      ),
      hasAvailablePlans: numPlans > 0,

      // flags
      isOpenEnrollment: oe?.isOpenEnrollment,
      asyncStatus: application?.asyncStatus,

      // EDN
      dsrsIdentifier: mostRecentEDN?.dsrsIdentifier,
    };
  }, [data?.viewerTwo?.health?.application]);

  const [getDocument, { loading: gettingNotice }] = useDeprecatedMutation(
    'getGeneratedDocumentURL',
    {
      onCompleted: (data) => Linking.openURL(data.getGeneratedDocumentURL),
    },
  );

  const handleNext = useCallback(() => {
    nextSteps.allCanEnroll
      ? navigate(routes.EDE_PLANS)
      : nextSteps.someCanEnroll
      ? navigate(routes.EDE_SPLIT_ELIGIBILITY)
      : nextSteps.noneCanEnroll
      ? exit(routes.COVERAGE)
      : nextSteps.someNotEligibeForSEP
      ? navigate(routes.EDE_SEP_OUT_OF_DATE)
      : navigate(routes.EDE_PLANS);
  }, [nextSteps, loading]);

  const cta = useMemo(() => {
    if (nextSteps.allCanEnroll || nextSteps.someCanEnroll) {
      return 'Continue';
    }

    if (nextSteps.noneCanEnroll) {
      return 'Exit';
    }

    return 'Continue';
  }, [nextSteps, loading]);

  return (
    <FlowLayout
      nextButtonText={cta}
      onNext={handleNext}
      canClickNext={!loading}
      title={c('title')}
      subtitle={c(`async.${asyncStatus}`)}
    >
      {loading ? (
        <Loading accentColor="coverage" />
      ) : (
        <Stack separatorComponent spacing="8">
          <Split>
            <EligibilityGroupList
              applicationID={applicationID}
              coverageYear={coverageYear}
              groups={groups}
              determinationType={
                stateDeterminesMedicaidEligibility ? 'DETERMINATION' : 'ASSESSMENT'
              }
            />
            <Stack spacing="2">
              <EligibilityNextSteps
                numCanEnroll={nextSteps.numCanEnroll}
                hasAvailablePlans={hasAvailablePlans}
                allMedicaidOrChip={nextSteps.allMedicaidOrChip}
                makeChanges={() => open(routes.EDE_HOUSEHOLD, { applicationID })}
                getNotice={() => getDocument({ variables: { input: { dsrsIdentifier } } })}
                gettingNotice={gettingNotice}
                handleNext={handleNext}
              />
              <EligibilityIssuesList issues={issuesSummary} />
            </Stack>
          </Split>

          {isGA && (
             <Split>
               <Section
                 title={c('gaAppeal.title')}
                 subtitle={c('gaAppeal.text', {
                   link: (
                     <Link
                       inline
                       href="https://s.catch.co/pdf/georgia-access-appeal-form.pdf"
                       target="blank"
                     >
                       Georgia Access Appeal Form
                     </Link>
                   ),
                 })}
               />

               <FileDropper
                 name={'HEALTH_DMI'}
                 handleDrop={async ({ fileKey, fileType }) => {
                  // @TODO - determine how to handle georgia appeal form
                 }}
               />
             </Split>
           )}

          {/* medicaid determination - only show this block if applicant is requesting financial assistance */}
          {!!isRequestingFinancialAssistance && ineligibleForMedicaid.length > 0 && (
            <>
              <MedicaidDetermination
                stateDeterminesMedicaidEligibility={stateDeterminesMedicaidEligibility}
                ineligibleForMedicaid={ineligibleForMedicaid}
                state={coverageState}
                applicationID={applicationID}
              />

              <WrongEligibilityDetermination />
            </>
          )}

          <VoterRegistration />
        </Stack>
      )}
    </FlowLayout>
  );
};

export const EligibilityResultsView = {
  name: routes.EDE_RESULTS,
  component: EligibilityResults,
  options: {
    title: 'Results',
  },
};
