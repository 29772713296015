import { routes, Route } from '@navigate';

/**
 * this function returns next route for CIC for
 * CICSelection and CICSSNEntry views
 * @todo write test to check routing
 */
export const nextCICRoute = (cic: string, hasSSN: boolean): Route => {
  if (!hasSSN) {
    return routes.CIC_SSN_ENTRY;
  } else {
    switch (cic) {
      case 'INCOME':
        return routes.CIC_BASICS;
      case 'BIRTH':
        return routes.CIC_APP_REVIEW_INTRO;
      case 'ADOPTION':
        return routes.CIC_APP_REVIEW_INTRO;
      case 'ADDRESS':
        return routes.CIC_ADDRESS_ENTRY;
      case 'MARRIAGE':
        return routes.CIC_MEMBER_MARRIAGE;
      case 'PREGNANCY':
        return routes.CIC_GOV_HANDOFF;
      case 'DIVORCE':
        return routes.CIC_MEMBER_DIVORCE;
      case 'DEATH':
        return routes.CIC_MEMBER_CUT_INTRO;
      case 'CUSTODY':
        return routes.CIC_MEMBER_CUT_INTRO;
      case 'COVERAGE':
        return routes.CIC_MANAGE_COVERAGE_INTRO;
      //applies only to CICSelectionView
      case 'COVERAGE_GAIN_JOB':
        return routes.CIC_MEMBER_JOB_COVERAGE;
      case 'COVERAGE_GAIN':
        return routes.CIC_APP_REVIEW_INTRO;
      case 'COVERAGE_LOSS':
        return routes.CIC_MEMBER_STATUS_CHANGE;
      case 'FUTURE_COVERAGE_LOSS':
        return routes.CIC_MEMBER_STATUS_CHANGE;
      /**
       * @todo this is different between CICSelectionView vs.
       * add and remove member
       * views which also uses the same view
       * check whimsical doc
       */

      case 'DISABILITY':
        return routes.CIC_MEMBER_STATUS_CHANGE;
      case 'CITIZENSHIP':
        return routes.CIC_MEMBER_STATUS_CHANGE;
      case 'INCARCERATION':
        return routes.CIC_MEMBER_STATUS_CHANGE;
      case 'CHILD_26':
        return routes.CIC_MEMBER_CUT;
      case 'OTHER':
        return routes.CIC_MEMBER_CUT;
      case 'FILING_STATUS':
        return routes.CIC_MEMBER_STATUS_CHANGE;
      default:
        return routes.CIC_MEMBER_STATUS_CHANGE;
    }
  }
};
