import gql from 'graphql-tag';
import { WorkType, FilingStatus, Address } from '@types';

export interface OnboardingQueryData {
  viewer: {
    id: string;
    user: {
      id: string;
      email?: string;
      phoneNumber?: string;
      nickname?: string;
      givenName?: string;
      familyName?: string;
      dob?: string;
      jobTitle?: string;
      workType?: WorkType;
      filingStatus?: FilingStatus;
      estimated1099Income?: number;
      estimatedW2Income?: number;
      numTaxDependents?: number;
      workState?: string;
      spouseIncome?: number;
      legalAddress?: Address;
    };
    bankLinks: Array<{
      id: string;
    }>;
  };
}

export const OnboardingQuery = gql`
  query OnboardingQuery {
    viewer {
      id
      user {
        id
        email
        phoneNumber
        nickname
        givenName
        familyName
        dob
        jobTitle
        workType
        filingStatus
        estimated1099Income
        estimatedW2Income
        numTaxDependents
        workState
        spouseIncome
        legalAddress {
          street1
          street2
          city
          state
          zip
        }
      }
      bankLinks {
        id
        providerType
      }
    }
  }
`;
