import React, { useMemo } from 'react';
import { Text, Button, Link } from '@uikit';
import { Layout, Stack, Card, ButtonGroup } from '@layouts';
import { useCopy } from '@app/utils';

// has any

interface Props {
  hasAvailablePlans: boolean;
  numCanEnroll: number;
  allMedicaidOrChip: boolean;

  makeChanges: () => void;
  getNotice: () => void;
  gettingNotice: boolean;
  handleNext: () => void;
}

type NextStepsType =
  | 'NO_AVAILABLE_PLANS'
  | 'NOT_ELIGIBLE'
  | 'ALL_MEDICAID_OR_CHIP'
  | 'MARKETPLACE_ELIGIBLE';

const copy: Record<NextStepsType, { title: string; description: string }> = {
  MARKETPLACE_ELIGIBLE: {
    title: "You're not finished",
    description:
      'View your eligibility notice, then choose a plan to enroll in coverage. Your eligibility notice explains your options for coverage, costs, deadlines and next steps.',
  },

  NO_AVAILABLE_PLANS: {
    title: 'Next steps',
    description: 'View your eligibility notice. There are currently no plans in your area.',
  },
  NOT_ELIGIBLE: {
    title: 'Next steps',
    description: 'View your eligibility notice.  You are not eligible to enroll at this time.',
  },
  ALL_MEDICAID_OR_CHIP: {
    title: 'Next steps',
    description: `View your eligibility notice. You can expect to hear from your state Medicaid agency.`,
  },
};

/**
 * Handles next steps
 * Shows the following flavors:
 *   - No available plans
 *   - All medicaid/CHIP eligible
 *   - Eligible to enroll
 *   - No eligibility
 */
const EligibilityNextSteps: React.FC<Props> = ({
  hasAvailablePlans,
  numCanEnroll,
  allMedicaidOrChip,
  makeChanges,
  getNotice,
  gettingNotice,
  handleNext,
}) => {
  const { c } = useCopy('catch.ede.EligibilityResults');

  const type: NextStepsType = useMemo(() => {
    if (numCanEnroll > 0) {
      return hasAvailablePlans ? 'MARKETPLACE_ELIGIBLE' : 'NO_AVAILABLE_PLANS';
    }

    if (allMedicaidOrChip) {
      return 'ALL_MEDICAID_OR_CHIP';
    }

    return 'NOT_ELIGIBLE';
  }, [hasAvailablePlans, numCanEnroll]);

  return (
    <Stack spacing="1">
      {numCanEnroll > 0 && (
        <Text size="fp" align="right">
          {numCanEnroll} eligible to enroll in Marketplace coverage
        </Text>
      )}

      <Card gradient="coverageLight">
        <Stack spacing="1">
          <Layout.Header title={copy[type].title} subtitle={copy[type].description} />
          <Link small onPress={makeChanges}>
            {c('CICtrigger')}
          </Link>
          <ButtonGroup>
            <Button
              testID="view-notice"
              alt
              onPress={getNotice}
              wide
              loading={gettingNotice}
              disabled={gettingNotice}
            >
              {c('viewEligibleNoticeBtn')}
            </Button>
            {numCanEnroll > 0 && hasAvailablePlans && (
              <Button testID="enroll-now" onPress={handleNext} wide reverse>
                Enroll
              </Button>
            )}
          </ButtonGroup>
        </Stack>
      </Card>
    </Stack>
  );
};

export default EligibilityNextSteps;
