import { isBefore } from 'date-fns';
import { optionPresets } from '@app/forms';
import { toDate, sixtyFiveYearsAgo, eighteenYearsAgo, getFullName } from '@app/utils';

const isValidDate = (date) => {
  const d = new Date(date);
  return /^\d{4}-\d{2}-\d{2}$/.test(date) && !isNaN(d.getTime());
};

const copyGA = (id, isGA) => ({ id: `${id}${isGA ? '_GA' : ''}` });

export const fields = [
  {
    copy: 'catch.ede.HealthEnrollment',
    name: 'name',
    type: 'legalName',
    required: true,
    label: { id: '4_Q' },
    subfields: {
      givenName: true,
      middleName: true,
      familyName: true,
      nameSuffix: true,
    },
  },
  {
    copy: 'catch.ede.HealthEnrollment',
    name: 'dob',
    type: 'date',
    dob: true,
    required: true,
    label: { id: '33_L' },
    format: 'YYYY-MM-DD',
    dependencies: ['dob', 'name'],
    fieldAddition: ({ dob, name }) => {
      if (isValidDate(dob) && isBefore(toDate(dob), sixtyFiveYearsAgo())) {
        return { component: 'APPLICANT_AGE_WARNING', props: name };
      }
    },
  },
  {
    copy: 'catch.ede.HealthEnrollment',
    name: 'isSmoker',
    type: 'option',
    required: true,
    dependencies: ['relation', 'name', 'dob'],
    // show if valid dob and more than 12 years old
    display: ({ dob }) => isValidDate(dob) && isBefore(toDate(dob), eighteenYearsAgo()),
    options: optionPresets.YES_NO_TRINARY,
    label: (values) => ({
      id: '145_Q',
      data: { relation: values.relation, fullName: getFullName(values.name, 'this person') },
    }),
    help: { id: '145_T' },
  },
  {
    copy: 'catch.ede.HealthEnrollment',
    name: 'lastTobaccoUseDate',
    type: 'date',
    format: 'YYYY-MM-DD',
    required: true,
    dependencies: ['isSmoker'],
    display: (values) => values.isSmoker === 'YES',
    label: { id: '146_Q' },
    help: { id: '146_T' },
    before: { date: new Date(), message: 'Must be before today' },
  },
  {
    copy: 'catch.ede.HealthEnrollment',
    name: 'filerRelation',
    type: 'dropdown',
    required: true,
    dependencies: ['relation', 'filerName'],
    display: (values) => values.relation === 'CHILD',
    label: (values) => ({
      id: '28_Q',
      data: { fullName: values.filerName },
    }),
    options: optionPresets.CHILD_RELATION,
  },
  {
    copy: 'catch.ede.HealthEnrollment',
    name: 'spouseRelation',
    type: 'dropdown',
    required: true,
    dependencies: ['relation', 'spouseName'],
    display: (values) => values.relation === 'CHILD' && !!values.spouseName,
    label: (values) => ({ id: '28_Q', data: { fullName: values.spouseName } }),
    options: optionPresets.CHILD_RELATION,
  },
  {
    copy: 'catch.ede.HealthEnrollment',
    name: 'sex',
    type: 'dropdown',
    required: true,
    label: { id: '31_Q' },
    options: optionPresets.SEX,
  },
  {
    copy: 'catch.ede.HealthEnrollment',
    name: 'ssn',
    type: 'ssn',
    dependencies: ['isRequestingCoverage', 'applicationPhase', 'relation', 'name', 'id', 'SSNs'],
    required: (values) => values.applicationPhase !== 'TWO',
    label: (values) => ({ id: values.isRequestingCoverage === 'YES' ? '32_L' : '34_Q' }),
    help: (values) => ({ id: values.isRequestingCoverage === 'YES' ? '32_T' : '34_T' }),
    doesNotExistIn: ({ SSNs, id }) => ({
      array: SSNs.filter((member) => !!member.ssn && member.id !== id).map((member) => member.ssn),
      message: 'This SSN matches another member on the application',
    }),
    editable: true,
  },
  {
    testID: '32_SKIP',
    copy: 'catch.ede.HealthEnrollment',
    name: 'confirmMissingSSN',
    type: 'checkbox',
    dependencies: ['applicationPhase', 'isRequestingCoverage', 'applicationPhase', 'ssn', 'name'],
    display: ({ ssn, applicationPhase, isRequestingCoverage }) =>
      applicationPhase !== 'ONE' && !ssn && isRequestingCoverage === 'YES',
    label: (values) => ({
      id: '32_CONFIRM',
      data: { fullName: getFullName(values?.name, 'this person') },
    }),
    required: ({ ssn }) => !ssn,
  },
  {
    copy: 'catch.ede.HealthEnrollment',
    name: 'isCitizen',
    type: 'option',
    required: true,
    options: optionPresets.YES_NO_TRINARY,
    dependencies: ['applicationPhase', 'isRequestingCoverage', 'relation', 'name'],
    display: (values) => values.applicationPhase !== 'ONE' && values.isRequestingCoverage === 'YES',
    label: (values) => ({
      id: '38_Q',
      data: { relation: values.relation, fullName: getFullName(values.name, 'this person') },
    }),
    help: { id: '38_T' },
  },
  {
    copy: 'catch.ede.HealthEnrollment',
    name: 'isSameNameApplicationAndDocument',
    type: 'option',
    required: true,
    options: optionPresets.YES_NO_TRINARY,
    dependencies: ['applicationPhase', 'ssn', 'relation', 'name'],
    display: (values) => values.applicationPhase !== 'ONE' && !!values.ssn,
    label: (values) => ({
      id: '36_Q',
      data: { relation: values.relation, fullName: getFullName(values.name, 'this person') },
    }),
  },
  {
    copy: 'catch.ede.HealthEnrollment',
    name: 'ssnAlternateName',
    prefix: true,
    type: 'legalName',
    required: true,
    subfields: {
      givenName: true,
      middleName: true,
      familyName: true,
      suffix: true,
    },
    dependencies: ['isSameNameApplicationAndDocument'],
    display: (values) => values.isSameNameApplicationAndDocument === 'NO',
    label: { id: '37_Q' },
    help: { id: '37_T' },
  },
  {
    copy: 'catch.ede.HealthEnrollment',
    name: 'isBlindOrDisabled',
    type: 'option',
    options: optionPresets.YES_NO_TRINARY,
    dependencies: ['isRequestingCoverage', 'isRequestingFinancialAssistance', 'relation', 'name'],
    display: (values) =>
      values.isRequestingCoverage === 'YES' && values.isRequestingFinancialAssistance,
    required: false,
    label: (values) => ({
      id: '131_Q',
      data: { relation: values.relation, fullName: getFullName(values.name, 'this person') },
    }),
    help: { id: '131_T' },
  },
  {
    copy: 'catch.ede.HealthEnrollment',
    name: 'isLongTermCare',
    type: 'option',
    options: optionPresets.YES_NO_TRINARY,
    dependencies: ['isRequestingCoverage', 'isRequestingFinancialAssistance', 'relation', 'name'],
    display: (values) =>
      values.isRequestingCoverage === 'YES' && values.isRequestingFinancialAssistance,
    required: false,
    label: (values) => ({
      id: '132_Q',
      data: { relation: values.relation, fullName: getFullName(values.name, 'this person') },
    }),
    help: { id: '132_T' },
  },
  {
    copy: 'catch.ede.HealthEnrollment',
    name: 'isHispanicOrigin',
    type: 'option',
    dependencies: ['relation', 'name', 'isGA'],
    options: optionPresets.YES_NO_TRINARY,
    required: false,
    label: (values) => ({
      id: '128_L',
      data: { relation: values.relation, fullName: getFullName(values.name, 'this person') },
    }),
    help: ({ isGA }) => copyGA('128_T', isGA),
  },
  {
    copy: 'catch.ede.HealthEnrollment',
    name: 'ethnicity',
    type: 'dropdown',
    options: optionPresets.ETHNICITY,
    dependencies: ['isHispanicOrigin'],
    display: (values) => values.isHispanicOrigin === 'YES',
    label: { id: '129_Q' },
    help: { id: '129_T' },
  },
  {
    copy: 'catch.ede.HealthEnrollment',
    name: 'otherEthnicity',
    type: 'text',
    dependencies: ['ethnicity'],
    display: (values) => values.ethnicity === 'OTHER',
    placeholder: 'Other ethnicity',
  },
  {
    copy: 'catch.ede.HealthEnrollment',
    name: 'race',
    type: 'multiSelect',
    style: 'picker',
    label: { id: '130_Q' },
    required: false,
    options: optionPresets.RACE,
  },
  {
    name: 'otherRaceText',
    type: 'text',
    dependencies: ['race'],
    display: ({ race }) => race?.includes('OTHER'),
    placeholder: 'Race description',
  },
];
