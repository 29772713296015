import gql from 'graphql-tag';
import { ExistingApplicationsResponse } from '@types';

export const ImportAllExistingApplications = gql`
  mutation ImportAllExistingApplications {
    importAllExistingApplications {
      hasActivePolicy
      hasPreviousActivePolicy
      hasSubmittedApplication
      idProofOk
      importOk
      existingApplications {
        id
        familyName
        givenName
        coverageYearNumber
        state
        applicationIdentifier
        applicationVersion
        providerLastUpdatedDate
        applicationStatus
        hasExistingPersonTrackingNumbersTiedToApplication
      }
    }
  }
`;
