import { useDeprecatedQuery } from '@data';
import { hasActiveGoal, hasStartedGoal } from '@app/utils';

/**
 * @todo this actually makes the entire plan query
 * @deprecated
 */
export const useOnboarding = () => {
  const {
    goals,
    loading: goalsLoading,
    waiting: goalsWaiting,
    ...goalsInfo
  } = useDeprecatedQuery('goals', { context: { failQuietly: true } });

  const {
    enrollments,
    loading: planLoading,
    waiting: planWaiting,
    ...planInfo
  } = useDeprecatedQuery('plan', { context: { failQuietly: true } });

  const hasAnyActiveGoal = hasActiveGoal(goals);
  const hasAnyStartedGoal = hasStartedGoal(goals);
  const hasAnyActiveHealth = enrollments?.active?.length > 0;

  return {
    loading: planLoading || goalsLoading,
    waiting: planWaiting || goalsWaiting,
    hasAnyActiveGoal,
    hasAnyStartedGoal,
    hasAnyActiveHealth,
    hasAnyActive: hasAnyActiveGoal || hasAnyActiveHealth,
    healthOnly: !goals || goals?.length === 0,

    ...planInfo,
    ...goalsInfo,
  };
};
