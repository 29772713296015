import React, { useMemo } from 'react';
import { View, ViewStyle } from 'react-native';

import { Gradient, UIGradientName, UIColorName, CatchTheme } from '@uikit';
import { useLayoutContext } from './LayoutContext';
import { useHeaderHeight } from './useHeaderHeight';

interface HeaderGradientProps {
  inherit?: boolean; // whether to inherit the gradient from layout
  hasChildren?: boolean; // helps with bottom margin
  centered?: boolean; // if set, doesnt apply the platform offset
  gradient?: UIGradientName;
  color?: UIColorName;
  hidden?: boolean;
  style?: ViewStyle | Array<ViewStyle>;
}

const HeaderGradient: React.FC<HeaderGradientProps> = ({
  inherit,
  hasChildren,
  centered, // whether the content should look centered or not (with nav)
  color,
  gradient,
  style,
  hidden,
  children,
}) => {
  const { layout, accentColor } = useLayoutContext();
  const { headerHeight, platformOffset } = useHeaderHeight();

  const size = useMemo(() => {
    return {
      // minHeight: autoHeight ? heights[layout] + headerHeight / 2 : undefined,
      marginTop: -1 * headerHeight,
      paddingTop: headerHeight,
      paddingBottom: hasChildren ? CatchTheme.spacing.mobile.margins * 2 : 0,
    };
  }, [layout, headerHeight]);

  if (hidden) {
    return <View style={style}>{children}</View>;
  }

  return (
    <Gradient
      gradient={inherit ? `${accentColor}Light` : gradient}
      color={color}
      color2={layout}
      style={[size, style]}
    >
      {centered ? <View style={{ paddingTop: platformOffset }}>{children}</View> : children}
    </Gradient>
  );
};

export default HeaderGradient;
