import React, { useMemo } from 'react';
import gql from 'graphql-tag';
import { useCopy } from '@util';
import { SplitFormBlueprint } from '@blueprints';
import { routes } from '@navigate';
import { getMembers, HealthApplicationQueryData, queries, useMutation, useQuery } from '@data';
import { Trinary } from '@app/types';
import { fields } from './incomeListFields';
import { formatPayload, getInitialValues } from './incomeListUtils';

const PREFIX = 'catch.ede.IncomeList';

const UpsertIncomeListMembers = gql`
  mutation UpsertIncomeListMembers($input: UpsertApplicationMembersInput!) {
    upsertApplicationMembers(input: $input) {
      id
      incomeThisMonth
    }
  }
`;

const MemberIncomeList = ({ applicationID, handleNext }) => {
  const { c } = useCopy(PREFIX);

  const { data } = useQuery<HealthApplicationQueryData>(queries.HEALTH_APPLICATION, {
    variables: { applicationID },
    skip: !applicationID,
  });

  const members = useMemo(() => getMembers(data), [data]);

  const initialValues = useMemo(() => {
    return { members: getInitialValues(members) };
  }, [members]);

  const [upsert, { loading: upserting }] = useMutation<
    {
      upsertApplicationMembers: Array<{
        id: string;
        incomeThisMonth: boolean;
        __typename: 'ApplicationMember';
      }>;
    },
    {
      input: {
        applicationID: string;
        applicationMembersInput: Array<{ id: string; incomeThisMonth: Trinary }>;
      };
    }
  >(UpsertIncomeListMembers);

  const onSubmit = async (values) => {
    try {
      upsert({
        variables: {
          input: {
            applicationID,
            applicationMembersInput: formatPayload(values),
          },
        },
        optimisticResponse: {
          upsertApplicationMembers: values.members.map((m) => ({
            id: m.id,
            incomeThisMonth: m.incomeThisMonth,
            __typename: 'ApplicationMember',
          })),
        },
      });

      handleNext();
    } catch (e) {}
  };

  return (
    <SplitFormBlueprint
      loading={!data}
      submitting={upserting}
      title={c('title')}
      subtitle={c('subtitle')}
      formConfig={{
        initialValues,
        fields,
        onSubmit,
      }}
    />
  );
};

export const IncomeListView = {
  name: routes.EDE_MEMBER_INCOME_LIST,
  component: MemberIncomeList,
  options: {
    ...SplitFormBlueprint.options,
    title: 'Income',
  },
};
