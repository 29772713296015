import gql from 'graphql-tag';
import access from 'safe-access';
import { differenceInDays, parseISO } from 'date-fns';
import { convertUnixTimeToAge, personFullName, personInitials } from '@app/utils';

/** preload cache and keep it shallow */
const USER_PRELOAD = gql`
  query UserPreload {
    viewerTwo {
      id
      accountStatus {
        id
        isLocked
      }
      agreements {
        id
        isPrivacyDataUseAgree
        isPrivacyTruthfulAgree
        acknowledgedUnitAccountDisclosures
        authorizedAboundEftpsEnrollment
      }
    }
    viewer {
      id
      incomeState
      spouseIncome
      income {
        estimated1099Income
        estimatedW2Income
      }
      goals {
        id
        type: goalType
        status
        totalBalance
        paycheckPercentage
        slug
      }
      savingsAccountMetadata {
        isAccountReady
        status
        kycStatus
        savingsProviderType
      }
      user {
        id
        email
        phoneNumber
        avatarImage
        supportedPathways
        givenName
        middleName
        familyName
        workType
        workState
        nickname
        dob
        ssn
        isControlPerson
        isFirmAffiliated
        isPoliticallyExposed
        referralCode
        createdOn
        signupParams
      }
    }
  }
`;

const USER_PARTIAL = `
  income {
    estimated1099Income
    estimatedW2Income
  }
  user {
    id
    email
    givenName
    middleName
    familyName
    nickname
    dob
    ssn
    filingStatus
    phoneNumber
    workType
    createdOn
    jobTitle
    avatarImage
    workState
    signupCode
    signupParams
    legalAddress {
      street1
      street2
      city
      state
      zip
      country
    }
    isControlPerson
    isFirmAffiliated
    isPoliticallyExposed
    referralCode
    kycSavings {
      status
      needed
      savingsProviderType
    }
    residency {
      isUSCitizen
      isResidentAlien
      citizenshipCountry
    }
  }
`;

const USER_QUERY = gql`
  query User {
    viewer {
      id
      income {
        estimated1099Income
        estimatedW2Income
      }
      user {
        id
        email
        givenName
        middleName
        familyName
        nickname
        dob
        ssn
        filingStatus
        phoneNumber
        workType
        createdOn
        jobTitle
        avatarImage
        workState
        signupCode
        signupParams
        legalAddress {
          street1
          street2
          city
          state
          zip
          country
        }
        isControlPerson
        isFirmAffiliated
        isPoliticallyExposed
        referralCode
        kycSavings {
          status
          needed
          savingsProviderType
        }
        residency {
          isUSCitizen
          isResidentAlien
          citizenshipCountry
        }
      }
      savingsAccountMetadata {
        kycStatus
      }
    }
  }
`;

const getAttr = (attr) => `viewer.user.${attr}`;

const formatter = (data) => {
  const get = (attr) => access(data, getAttr(attr));
  const createdOn = get('createdOn');
  const TODAY = new Date();
  const daysOnPlatform = differenceInDays(TODAY, parseISO(createdOn));
  const user = data?.viewer?.user;

  const incomes = {
    WORK_TYPE_1099: data?.viewer?.income?.estimated1099Income,
    WORK_TYPE_W2: data?.viewer?.income?.estimatedW2Income,
    WORK_TYPE_DIVERSIFIED:
      data?.viewer?.income?.estimated1099Income + data?.viewer?.income?.estimatedW2Income,
  };
  const estimatedIncome = incomes[user?.workType] || 0;

  return {
    user,
    userID: get('id'),
    email: get('email'),
    name: get('nickname') || get('givenName'),
    nickname: get('nickname'),
    givenName: get('givenName'),
    middleName: get('middleName'),
    familyName: get('familyName'),
    initials: personInitials({
      givenName: get('nickname') || get('givenName'),
      familyName: get('familyName'),
    }),
    fullName: personFullName(data?.viewer?.user),
    legalName: `${get('givenName')} ${get('familyName')}`,
    dob: get('dob'),
    age: !!get('dob') ? convertUnixTimeToAge(get('dob')) : null,
    filingStatus: get('filingStatus'),
    phoneNumber: get('phoneNumber'),
    jobTitle: get('jobTitle'),
    legalAddress: get('legalAddress'),
    zipcode: get('legalAddress.zip'),
    workType: get('workType'),
    ssn: get('ssn'),
    acknowledgedAccountDisclosures: data?.viewerTwo?.agreements?.acknowledgedUnitAccountDisclosures,
    isControlPerson: get('isControlPerson'),
    isPoliticallyExposed: get('isPoliticallyExposed'),
    isFirmAffiliated: get('isFirmAffiliated'),
    kycStatus: get('kycSavings.status'),
    kycNeeded: get('kycSavings.needed'),
    hasSSN: !!get('ssn'),
    isUSCitizen: get('residency.isUSCitizen'),
    isResidentAlien: get('residency.isResidentAlien'),
    citizenshipCountry: get('residency.citizenshipCountry'),
    avatarImage: get('avatarImage'),
    referralCode: get('referralCode'),
    daysOnPlatform,
    locked: data?.viewerTwo?.accountStatus?.isLocked,
    denied: data?.viewer?.savingsAccountMetadata?.kycStatus === 'KYC_DENIED',
    acknowledgedUnitAccountDisclosures:
      data?.viewerTwo?.agreements?.acknowledgedUnitAccountDisclosures,
    joinDate: get('createdOn'),
    signupCode: get('signupCode'),
    signupParams: get('signupParams'),
    // flags based on signup code
    isDasher: /doordash/.test(get('signupCode')),
    estimatedIncome,
    workState: get('workState'),
    homeState: get('legalAddress.state'),
    bankPartner: get('kycSavings.savingsProviderType'),
  };
};

export { USER_QUERY, USER_PARTIAL };

export default {
  document: USER_QUERY,
  documentQuick: USER_PRELOAD,
  formatter,
};
