import React from 'react';

import { View } from 'react-native';
import { Card } from '@layouts';
import { useTheme, Person, SimpleRow } from '@uikit';

/** @deprecated use standard template */
const PersonCard = ({
  givenName,
  familyName,
  children,
  onPress,
  checked,
  style,
  row,
  containerStyle,
  shadow,
  majorShadow,
}) => {
  const { theme } = useTheme();
  return (
    <Card
      shadow={shadow}
      majorShadow={majorShadow}
      gutter="bottom"
      hover={!checked}
      hoverColor="gray"
      color={!!checked ? 'setupBase' : 'sheet'}
      containerStyle={containerStyle}
    >
      <SimpleRow
        style={[theme.row, theme.cardHeader, theme.cardBg]}
        selectedBg={!!checked ? 'setupBase' : 'sheet'}
        onPress={onPress}
        asset={{ icon: Person }}
        accessory={checked ? 'selected' : 'unselected'}
        label={`${givenName} ${familyName}`}
      />

      {!!checked && !!children && (
        <View style={[theme.cardPadding, row && theme.row, style]}>{children}</View>
      )}
    </Card>
  );
};

export default PersonCard;
