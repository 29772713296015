import { HealthPathwayType, HealthApplicationContext } from '@types';
import { useHealthStore } from '@app/client/useHealthStore';
import { navigate, routes, open, healthRouteMap, stackScreens } from '@navigate';

interface HealthAppNavigation {
  id: string;
  applicationContext: HealthApplicationContext;
  lastUsedRoute: string;
  coverageState: string;
  isGeorgiaAccess: boolean;
  pathwayType: HealthPathwayType;
  enrollment?: {
    id: string;
  };
}

export const goToHealthApp = (
  app?: HealthAppNavigation,
  options?: { hasAgreed?: boolean; goTo?: () => void },
) => {
  const goTo = options?.goTo || navigate;
  const hasAgreed = options?.hasAgreed || false;

  // set the app ID if defined
  if (app?.id) {
    useHealthStore.getState().setID(app.id);
  }

  if (app?.isGeorgiaAccess) {
    useHealthStore.getState().setHealthContext('GA');
  }

  const params = {
    applicationID: app?.id,
    enrollId: app?.enrollment?.id,
  };

  // @todo: check for non-EDE pathways

  if (app?.applicationContext === 'SLASHER') {
    // @ts-ignore
    const isSlasherRoute = stackScreens.HEALTH_SLASHER_STACK.includes(app?.lastUsedRoute);

    // @ts-ignore
    open(isSlasherRoute ? app?.lastUsedRoute : routes.HEALTH_SLASHER_INTRO);
  } else if (app?.lastUsedRoute && healthRouteMap[app.lastUsedRoute]) {
    goTo(healthRouteMap[app.lastUsedRoute], params);
  } else {
    if (!app?.coverageState) {
      goTo(routes.EDE_COVERAGE_INFO, params);
    } else if (hasAgreed) {
      goTo(routes.EDE_HOUSEHOLD, params);
    } else {
      goTo(routes.EDE_PRIVACY, params);
    }
  }
};
