import React, { useState } from 'react';
import { Platform, View, LayoutAnimation, UIManager } from 'react-native';
import { useTheme } from '../hooks/useTheme';

const CARD_REDUCTION_SIZE = 0.03;
const BACKDROP_BLUR = 25;

if (Platform.OS === 'android') {
  UIManager.setLayoutAnimationEnabledExperimental(true);
}

const style = {
  backdropFilter: `blur(${BACKDROP_BLUR}px)`,
  paddingHorizontal: 16,
  marginBottom: 8,
  transformOrigin: 'bottom',
  borderRadius: 16,
  overflow: 'hidden',
  transition: '.5s cubic-bezier(.17,.67,.11,1.1)',
};

export const ZStack = ({ children, maxItems = 10 }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { theme } = useTheme();

  return (
    <View
      style={{ cursor: 'pointer', pointerEvents: isOpen ? 'all' : 'box-only' }} // @todo calculate actual margin needs
      onStartShouldSetResponderCapture={(e) => !isOpen}
      onTouchEndCapture={(e) => {
        LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
        setIsOpen(true);
      }}
      onClick={(e) => {
        setIsOpen(true);
      }}
    >
      {!!children &&
        children?.map(
          (child, idx) =>
            idx < (isOpen ? maxItems : 4) && (
              <View
                key={idx}
                style={[
                  theme.androidCard,
                  theme.lightBorder,
                  theme.cardShadow,
                  theme.sheetBg,
                  {
                    zIndex: -idx,
                    opacity: isOpen ? 1 : 1 - idx * CARD_REDUCTION_SIZE * 3,
                    filter: isOpen ? '' : `blur(${idx * 0.5}px)`,
                    transform: isOpen ? [{ scale: 1 }] : [{ scale: 1 - idx * CARD_REDUCTION_SIZE }],
                    marginTop: isOpen || idx === 0 ? 0 : -72,
                    ...style,
                  },
                ]}
              >
                {child}
              </View>
            ),
        )}
    </View>
  );
};

export default ZStack;
