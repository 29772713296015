import React, { useRef, useMemo } from 'react';
import { MultipleFieldErrors } from 'react-hook-form';

import { NameSubfields } from '@types';
import { FormLabel } from '@uikit';
import { PickerField, TextField } from '@app/forms_old/inputs';
import { InputProps } from '../types';
import { optionPresets } from '../config/options';

interface LegalNameInputProps<T> extends InputProps<T> {
  errors?: MultipleFieldErrors;
  subfields: NameSubfields;
}

const LegalNameInput = React.forwardRef(
  <T extends any>(
    {
      testID,
      autoFocus,
      name,
      value, // to allow for easy on change
      label,
      sublabel,
      help,
      optional,
      subfields,
      disabled,
      onChange,
      onBlur,
      onKeyPress,
      onSubmitEditing,
      error,
      errors,
    }: LegalNameInputProps<T>,
    ref,
  ) => {
    const inputRefs = useRef([]);

    const fieldsOrder = useMemo(() => {
      return Object.keys(subfields);
    }, [subfields]);

    const focusNext = (name) => {
      const currentIndex = fieldsOrder.indexOf(name);

      // if last, then trigger the on submit editing
      if (currentIndex === fieldsOrder.length - 1) {
        onSubmitEditing();
      } else {
        // otherwise, focus to the next subfield
        const nextField = fieldsOrder[currentIndex + 1];
        inputRefs?.current[nextField]?.focus();
      }
    };

    return (
      <>
        <FormLabel
          testID={testID}
          label={label}
          sublabel={sublabel}
          help={help}
          optional={optional}
        />
        {subfields.givenName && (
          <TextField
            ref={ref}
            testID={`${testID}-givenName`}
            disabled={disabled}
            autoFocus={autoFocus}
            keyboard="firstName"
            value={value?.givenName}
            onChangeText={(givenName) => onChange({ ...value, givenName })}
            placeholder="First name"
            fullWidth
            error={errors?.givenName}
            onKeyPress={onKeyPress}
            onSubmitEditing={() => focusNext('givenName')}
            grouped
          />
        )}
        {subfields.middleName && (
          <TextField
            ref={(el) => (inputRefs.current['middleName'] = el)}
            testID={`${testID}-middleName`}
            disabled={disabled}
            keyboard="middleName"
            value={value?.middleName}
            onChangeText={(middleName) => onChange({ ...value, middleName })}
            placeholder="Middle name"
            fullWidth
            error={errors?.middleName}
            onKeyPress={onKeyPress}
            onSubmitEditing={() => focusNext('middleName')}
            grouped
          />
        )}
        {subfields.familyName && (
          <TextField
            ref={(el) => (inputRefs.current['familyName'] = el)}
            testID={`${testID}-familyName`}
            disabled={disabled}
            keyboard="lastName"
            value={value?.familyName}
            onChangeText={(familyName) => onChange({ ...value, familyName })}
            onBlur={onBlur} // this will trigger validation
            placeholder="Last name"
            fullWidth
            error={errors?.familyName}
            onKeyPress={onKeyPress}
            onSubmitEditing={() => focusNext('familyName')}
            grouped
          />
        )}
        {subfields.nameSuffix && (
          <PickerField
            ref={(el) => (inputRefs.current['nameSuffix'] = el)}
            testID={`${testID}-nameSuffix`}
            disabled={disabled}
            keyboard="suffix"
            placeholder="Suffix"
            name={name}
            value={value?.nameSuffix}
            options={optionPresets.SUFFIXES}
            onChange={(nameSuffix) => onChange({ ...value, nameSuffix })}
            onSubmitEditing={() => focusNext('nameSuffix')}
            fullWidth
            error={!!errors?.nameSuffix}
            grouped
          />
        )}
      </>
    );
  },
);

LegalNameInput.displayName = 'LegalNameInput';
export default LegalNameInput;
