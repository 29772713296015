import gql from 'graphql-tag';
import access from 'safe-access';

export const STATE_HEALTH_REFERENCE = gql`
  query StateHealthReference($state: String!) {
    reference {
      stateHealthReference(state: $state) {
        url
        name
        oeEndTime
      }
    }
  }
`;

const formatter = (data, { variables }) => {
  const get = access(data);
  const ref = get('reference.stateHealthReference');

  return {
    state: variables?.state,
    name: ref?.name,
    url: ref?.url,
    oeEndTime: ref?.oeEndTime,
  };
};

export default {
  document: STATE_HEALTH_REFERENCE,
  formatter,
};
