import { replace, navigate, routes } from '@navigate';

export const handleHealthResponse = ({ onError, onCompleted }) => ({
  onError: (error) => {
    console.log('There was a healthError', { error });
    if (onError) onError();
    navigate(routes.EDE_HELP);
  },
  onCompleted: (data) => {
    let error = false;

    Object.keys(data).forEach((mutation) => {
      const response = data[mutation];

      if (response?.error || response?.errors?.length > 0) {
        error = true;

        console.log('There was a healthError', {
          error: response?.error,
          errors: response?.errors,
        });

        if (onError) onError();
        navigate(routes.EDE_HELP);
      }
    });

    if (!error && onCompleted) {
      onCompleted(data);
    }
  },
});

export const handleHealthErrors = {
  onError: (error) => {
    console.log('There was a healthError', { error });
    replace(routes.EDE_HELP);
  },
  onCatchError: (error) => {
    console.log('There was a healthError', { error });

    if (error?.[0]?.message?.includes('Found valid applications to import')) {
      navigate(routes.EDE_APPLICATION_IMPORT);
    } else {
      replace(routes.EDE_HELP);
    }
  },
};

export const handleHealthErrorsWithProps = (props) => ({
  onError: (error) => {
    console.log('There was a healthError', { error });
    replace(routes.EDE_HELP, props);
  },
  onCatchError: (error) => {
    console.log('There was a healthError', { error });
    replace(routes.EDE_HELP, props);
  },
});

export const getHealthErrorHandlers = (onError) => ({
  onError: (error) => {
    console.log('There was a healthError', { error });
    if (onError) onError();
    replace(routes.EDE_HELP);
  },
  onCatchError: (error) => {
    console.log('There was a healthError', { error });
    if (onError) onError();
    replace(routes.EDE_HELP);
  },
});
