import React, { useCallback } from 'react';
import {
  exit,
  navigate,
  routes,
  Stack,
  StackComponent,
  StackDefinition,
  stacks,
  useCurrentRoute,
} from '@navigate';

import {
  useQuery,
  queries,
  RetirementQueryData,
  useMutation,
  mutations,
  UpsertGoalResponse,
  UpsertGoalVars,
} from '@data';

import { FinancialScreeningView } from './retirement/FinancialScreeningView';
import { FinancialScreeningDeniedView } from './retirement/FinancialScreeningDeniedView';
import { RetirementCurrentSavingsView } from './retirement/RetirementCurrentSavingsView';
import { RetirementRiskLevelView } from './retirement/RetirementRiskLevelView';
import { RetirementRiskComfortView } from './retirement/RetirementRiskComfortView';
import { RetirementPortfolioSelectionView } from './retirement/RetirementPortfolioSelectionView';
import { RetirementPortfolioTypeView } from '../../sheets/RetirementPortfolioSheet';
import { RetirementAccountSelectionView } from './retirement/RetirementAccountSelectionView';
import { RetirementAccountTypeView } from '../../sheets/RetirementAccountTypeSheet';
import { InvestmentAgreementView } from './retirement/InvestmentAgreementView';

const config: StackDefinition = {
  stackName: stacks.RETIREMENT_STACK,
  options: {
    layout: 'page',
    navMode: 'flow',
    buttons: { help: true },
    accentColor: 'retirement',
  },
  screens: [
    RetirementCurrentSavingsView,
    RetirementRiskLevelView,
    RetirementRiskComfortView,
    RetirementPortfolioSelectionView,
    RetirementPortfolioTypeView,
    RetirementAccountSelectionView,
    RetirementAccountTypeView,
    FinancialScreeningView,
    FinancialScreeningDeniedView,
    InvestmentAgreementView,
  ],
};

interface HandleNextUpdates {
  isEligible?: boolean;
}

const RetirementStack: StackComponent = () => {
  const route = useCurrentRoute();

  const { data } = useQuery<RetirementQueryData>(queries.RETIREMENT, {
    fetchPolicy: 'cache-first',
  });

  const [upsert, { loading: submitting }] = useMutation<UpsertGoalResponse, UpsertGoalVars>(
    mutations.UPSERT_GOAL,
  );
  const updateRetirement = useCallback(
    (input) =>
      upsert({
        variables: { input: { slug: 'retirement', ...input } },
        optimisticResponse: {
          upsertGoal: {
            ...data?.viewer?.goal,
            id: data?.viewer?.goal?.id || '',
            ...input,
            __typename: 'RetirementGoal',
          },
        },
      }),
    [data],
  );

  const handleSkip = useCallback(() => exit(routes.HOME), []);

  const handleNext = useCallback(
    (updates: HandleNextUpdates) => {
      switch (route) {
        case routes.RETIREMENT_CURRENT_SAVINGS:
          navigate(routes.RETIREMENT_RISK_LEVEL);
          break;
        case routes.RETIREMENT_RISK_LEVEL:
          navigate(routes.RETIREMENT_RISK_COMFORT);
          break;
        case routes.RETIREMENT_RISK_COMFORT:
          navigate(routes.RETIREMENT_PORTFOLIO_SELECTION);
          break;
        case routes.RETIREMENT_PORTFOLIO_SELECTION:
          navigate(routes.RETIREMENT_ACCOUNT_SELECTION);
          break;
        case routes.RETIREMENT_ACCOUNT_SELECTION:
          navigate(routes.FINANCIAL_SCREENING);
          break;
        case routes.FINANCIAL_SCREENING:
          if (updates?.isEligible) {
            navigate(routes.INVESTMENT_AGREEMENT);
          } else {
            navigate(routes.FINANCIAL_SCREENING_DENIED);
          }
          break;
        case routes.INVESTMENT_AGREEMENT:
          navigate(routes.GOAL_CONFIRM, { slug: 'retirement' });
          break;
        case routes.GOAL_CONFIRM:
          exit(routes.PLAN);
          break;
        default:
          console.log('route not handled', route);
          break;
      }
    },
    [route],
  );

  return (
    <Stack
      stackName={config.stackName}
      screens={config.screens}
      options={config.options}
      data={{ handleNext, updateRetirement, submitting, handleSkip, slug: 'retirement' }}
    />
  );
};

RetirementStack.config = config;
export default RetirementStack;
