import gql from 'graphql-tag';
import access from 'safe-access';
import { calculateTaxes } from '@catch-co/tax-calculator';
import { getTotalIncome } from '@app/utils';

export const TAXES = gql`
  query TaxGoalForCalc {
    viewer {
      id
      incomeState
      income {
        estimatedW2Income
        estimated1099Income
      }
      spouseIncome
      user {
        id
        filingStatus
        workType
      }
      taxGoal: goal(slug: "taxes") {
        id
        slug
        paycheckPercentage
        status
        availableBalance
        ... on TaxGoal {
          numDependents
          numExemptions
          estimatedJobExpenseRange
          taxDeferredContributions
        }
      }
      savingsAccountMetadata {
        isAccountReady
      }
    }
  }
`;

const formatter = (data) => {
  const get = access(data);
  const taxes = get('viewer.taxGoal');
  if (!taxes) {
    return {};
  }
  const workType = get('viewer.user.workType');
  const estimatedW2Income = get('viewer.income.estimatedW2Income');
  const estimated1099Income = get('viewer.income.estimated1099Income');
  const userIncome = getTotalIncome({
    estimatedW2Income,
    estimated1099Income,
    workType,
  });
  const result = calculateTaxes({
    state: get('viewer.incomeState'),
    grossIncome: userIncome,
    spouseIncome: get('viewer.spouseIncome'),
    filingStatus: get('viewer.user.filingStatus'),
    numExemptions: get('viewer.taxGoal.numExemptions'),
    numDependents: get('viewer.taxGoal.numDependents') || 0,
  });
  const currentRate = get('viewer.taxGoal.paycheckPercentage');
  const suggestedRate = result.roundedPaycheckPercentage;
  return {
    workType,
    estimated1099Income,
    estimatedW2Income,
    currentRate,
    currentEstimate: currentRate * (estimated1099Income / 12),
    suggestedRate,
    suggestedEstimate: suggestedRate * (estimated1099Income / 12),
    isSetToSuggestion: currentRate === suggestedRate,
    result,
  };
};

export default {
  document: TAXES,
  formatter,
};
