import React, { useMemo } from 'react';
import {
  useQuery,
  queries,
  useMutation,
  mutations,
  AccountSetupQueryData,
  ConfirmGoalsResponse,
  ConfirmGoalsVars,
  updates,
  useData,
} from '@data';
import { routes, navigate, exit } from '@navigate';
import { FieldsConfig, GoalSlug } from '@types';
import { Asset, ComplexRow, Text, Button, Link } from '@uikit';
import { Segment, createLogger, formatBenefit, useCopy } from '@util';
import { BasicLayout, Toolbar, Stack, Section } from '@layouts';
import { useForm, Fields } from '@app/forms';
import ConfirmationBlueprint from '@blueprints/ConfirmationBlueprint';
import IncomeSourceRow from '@components/IncomeSourceRow';

const Log = createLogger('unit-setup');

interface GoalConfirmProps {
  slug: GoalSlug;
  isInExchange: boolean;
  hasSSN: boolean;
  showIncome?: boolean;
  handleNext: (updates?: object) => void;
  agree: () => void;
  doneMessage?: string;
}

export const GoalConfirm: React.FC<GoalConfirmProps> = ({
  slug,
  handleNext,
  hasSSN,
  showIncome = true,
  doneMessage,
}) => {
  const { c, p } = useCopy('catch');

  const { loading, data } = useQuery<AccountSetupQueryData>(queries.ACCOUNT_SETUP, {
    fetchPolicy: 'no-cache', // This view is often visited directly after editing a goal, so don't use the cache
  });

  //show all goals or just the one we're setting up
  //depends on if we're in account setup (onboarding) or goal setup (post-onboarding)
  const goals = useMemo(() => {
    return (
      data?.viewer?.goals?.filter((g) => {
        if (slug) return g.slug === slug;
        return g.status !== 'DELETED';
      }) || []
    );
  }, [data]);

  const hasTaxes = useMemo(() => {
    return !!goals.find((g) => g.type === 'TAX');
  }, [goals]);

  const totalPercentage = useMemo(() => {
    const total = goals.reduce((sum, goal) => {
      return sum + goal.paycheckPercentage;
    }, 0);

    return Math.round(total * 100);
  }, [goals]);

  const incomeSources = useMemo(() => {
    return (
      data?.viewer?.incomeSources?.filter((source) => {
        return source.incomeAutomationRule?.automationType === 'ALWAYS';
      }) || []
    );
  }, [data]);

  // @E-3685 @E-3686
  // this is NOT the way we want to handle these;
  // this should be replaced once platform implements agreements edge
  const { fetch } = useData('forceUser', {});

  const [confirm, { loading: confirming, called, error }] = useMutation<
    ConfirmGoalsResponse,
    ConfirmGoalsVars
  >(mutations.CONFIRM_GOALS, {
    onCompleted: (data: ConfirmGoalsResponse) => {
      fetch();
      if (data.confirmGoals.accountStatus.isBankingKYCDenied) {
        navigate(routes.ACCOUNT_SETUP_DENIED);
      }
    },
  });

  const fields: FieldsConfig = [
    {
      type: 'checkbox',
      name: 'unitAgreement',
      required: true,
      display: !data?.viewerTwo?.agreements?.acknowledgedUnitAccountDisclosures,
      grouped: true,
      label: c('module.disclosures.unit', {
        electronic: (
          <Link key="l-1" href="https://catch.co/legal/electronic" target="_blank">
            Electronic Disclosure and Consent
          </Link>
        ),
        terms: (
          <Link key="l-2" href="https://catch.co/legal/terms" target="_blank">
            Deposit Account Terms and Conditions
          </Link>
        ),
      }),
    },
    {
      type: 'checkbox',
      name: 'eftpsAgreement',
      required: true,
      grouped: true,
      display: hasTaxes && !data?.viewerTwo?.agreements?.authorizedAboundEftpsEnrollment,
      label: c('agreements.eftps', {
        link: (
          <Link href={'https://catch.co/legal/eftps'} target="_blank" inline>
            the EFTPS and tax payments agreement
          </Link>
        ),
      }),
    },
  ];

  const form = useForm<{
    unitAgreement: boolean;
    eftpsAgreement: boolean;
  }>({
    loading,
    disabled: confirming,
    initialValues: {},
    fields,
    onSubmit: (values) => {
      Log.info('Performing KYC and opening account...');
      confirm({
        variables: {
          input: {
            goalIds: goals.map((g) => g.id),
            acknowledgedUnitAccountDisclosures:
              data?.viewerTwo?.agreements?.acknowledgedUnitAccountDisclosures ||
              values.unitAgreement,
            authorizedAboundEftpsEnrollment:
              data?.viewerTwo?.agreements?.authorizedAboundEftpsEnrollment || values.eftpsAgreement,
          },
        },
        onCompleted: () => Log.debug('Unit account opened'),
      });
    },
  });

  const [lookup] = useMutation(mutations.HEALTH_LINK_LOOKUP, {
    update: updates.UPDATE_EXISTING_APPLICATIONS,
    onCompleted: (res) => {
      Log.debug('Search complete.');
      Segment.track('Health Link Lookup');

      if (slug) {
        exit(slug === 'taxes' ? routes.TAXES : routes.PLAN);
      } else {
        const existingApplications = res?.edeApplicationSearch?.existingApplications || [];
        handleNext({ hasApplications: existingApplications.length > 0 });
      }
    },
  });

  return (
    <ConfirmationBlueprint
      loading={confirming}
      titles={!slug ? { done: doneMessage || 'Successfully created your account' } : undefined}
      called={called}
      error={!!error}
      onSuccess={lookup}
    >
      <BasicLayout
        loading={loading}
        title="Confirm"
        toolbar={
          <Toolbar type="stack">
            <Button
              disabled
              testID="confirm"
              loading={loading}
              inherit
              // disabled={form.disableSubmit || !hasSSN}
              onPress={form.submitForm}
            >
              Confirm
            </Button>
          </Toolbar>
        }
      >
        <Stack>
          {showIncome && incomeSources && incomeSources.length > 0 && (
            <IncomeSourceRow loading={loading} incomeSources={incomeSources} />
          )}
          {goals.length > 0 && (
            <Section>
              <Stack>
                {goals.map((goal) => (
                  <ComplexRow
                    noPadding
                    testID={goal.id}
                    key={goal.id}
                    label={formatBenefit.name(goal)}
                    accessory={<Text weight="medium">{p(goal.paycheckPercentage)}</Text>}
                    asset={{
                      ...Asset.configureBenefit(goal.type, 'default', 'xs'),
                      containerSize: 'md',
                    }}
                  />
                ))}
              </Stack>
            </Section>
          )}

          <Stack spacing="3">
            <Fields form={form} fields={fields} stackProps={{ spacing: '+gap' }} />
            <Text size="sm" color="subtle">
              {loading
                ? ''
                : c('module.disclosures.financialConfirm', {
                    percentage: totalPercentage,
                  })}
            </Text>
          </Stack>
        </Stack>
      </BasicLayout>
    </ConfirmationBlueprint>
  );
};

export const AccountSetupConfirmView = {
  name: routes.ACCOUNT_SETUP_CONFIRM,
  component: GoalConfirm,
  options: {},
};

export const GoalConfirmView = {
  name: routes.GOAL_CONFIRM,
  component: (props) => <GoalConfirm {...props} showIncome={false} />,
  options: {},
};
