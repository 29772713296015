import gql from 'graphql-tag';
import { ApolloWalletItem, WalletItemFragment } from '../fragments';

export interface WalletQueryData {
  viewer: {
    id: string;
    wallet?: Array<ApolloWalletItem>;
  };
}

export const WalletQuery = gql`
  query WalletQuery {
    viewer {
      id
      wallet {
        id
        ...WalletItem
      }
    }
  }
  ${WalletItemFragment}
`;
