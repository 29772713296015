import React from 'react';

import { ComplexRow } from '@uikit';
import { formatDrug } from '@app/utils';
import { Stack } from '@app/layouts';

const SelectedDrug = ({ drug, remove }) => {
  return (
    <ComplexRow
      dismiss={remove}
      label={formatDrug(drug?.name)}
      sublabel={drug?.route + ', ' + drug?.strength}
      interaction="dismiss"
      onPress={remove}
      accessory="x"
    />
  );
};

/**
 * @deprecated should be merged with selectedProviders
 */
const SelectedDrugs = ({ selected, values, deselect }) => {
  return (
    <Stack separatorComponent>
      {selected.map((drugId, i) => (
        <SelectedDrug key={drugId} remove={() => deselect(drugId)} drug={values[drugId]?.data} />
      ))}
    </Stack>
  );
};

export default SelectedDrugs;
