import React from 'react';
import { stacks, Stack, StackComponent, StackDefinition } from '@navigate';

import { CICSelectionView } from './cic/CICSelectionView';
import { CICCoverageOptionsIntroView } from './cic/CICCoverageOptionsIntro';
import { CICBasicsEntryView } from './cic/CICBasicsEntry';
import { CICIncomeEntryView } from './cic/CICIncomeEntry';
import { CICAddressEntryView } from './cic/CICAddressEntry';
import { CICNoChangeView } from './cic/CICNoChange';
import { CICStateHandoffView } from './cic/CICStateHandoff';
import { CICStatePickupView } from './cic/CICStatePickup';
import { CICCancelPolicyView } from './cic/CICCancelPolicy';
import { CICGovPickupView } from './cic/CICGovPickup';
import { CICGovHandoffView } from './cic/CICGovHandoff';
import { CICAppReviewIntroView } from './cic/CICAppReviewIntro';
import { CICMemberMarriageView } from './cic/CICMemberMarriage';
import { CICMemberCutIntroView } from './cic/CICMemberCutIntro';
import { CICMoveDateEntryView } from './cic/CICMoveDateEntry';
import { CICMemberDivorceView } from './cic/CICMemberDivorce';
import { CICMemberDeathView } from './cic/CICMemberDeath';
import { CICMemberCustodyView } from './cic/CICMemberCustody';
import { CICMemberJobCoverageView } from './cic/CICMemberJobCoverage';
import { CICMemberStatusChangeView } from './cic/CICMemberStatusChange';
import { CICRemovingMembersView } from './cic/CICRemovingMembers';
import { CICPolicyInvalidView } from './cic/CICPolicyInvalid';
import { CICNewPlansAvailableView } from './cic/CICNewPlansAvailable';
import { CICNewPlanRequiredView } from './cic/CICNewPlanRequired';
import { CICPrelimEligView } from './cic/CICPrelimElig';
import { CICMemberCutView } from './cic/CICMemberCut';
import { CICSEPMixedView } from './cic/CICSEPMixed';
import { CICSSNEntryView } from './cic/CICSSNEntry';

const config: StackDefinition = {
  stackName: stacks.CIC_STACK,
  options: {
    navMode: 'flow',
    layout: 'sheet',
    presentation: 'pageSheet',
    accentColor: 'coverage',
  },
  screens: [
    CICSelectionView,
    CICCoverageOptionsIntroView,
    CICBasicsEntryView,
    CICIncomeEntryView,
    CICAddressEntryView,
    CICNoChangeView,
    CICStateHandoffView,
    CICStatePickupView,
    CICCancelPolicyView,
    CICGovPickupView,
    CICGovHandoffView,
    CICAppReviewIntroView,
    CICMemberMarriageView,
    CICMemberCutIntroView,
    CICMoveDateEntryView,
    CICMemberDivorceView,
    CICMemberDeathView,
    CICMemberCustodyView,
    CICMemberJobCoverageView,
    CICMemberStatusChangeView,
    CICRemovingMembersView,
    CICPolicyInvalidView,
    CICNewPlansAvailableView,
    CICNewPlanRequiredView,
    CICPrelimEligView,
    CICMemberCutView,
    CICSEPMixedView,
    CICSSNEntryView,
  ], // @todo E-3445 typescript
};

/**
 *
 *
 * C H A N G E ~ I N ~ C I R C U M S T A N C E
 * Change in Circumstance Stack (EDE CiC)
 *
 * @see https://www.figma.com/file/pvN75n8evS8qi3sYQeS0TQ/Health-Change-in-Circumstance
 *
 */
const CICStack: StackComponent = () => {
  return <Stack stackName={config.stackName} screens={config.screens} options={config.options} />;
};

CICStack.config = config;
export default CICStack;
