import { Env } from '@app/utils';

// The string must be at least 8 letters long
export const testLength = (value = '') => {
  return value ? value.length >= 8 : false;
};

// The string must contain at least 1 lowercase alphabetical character
export const testLowerCase = (value = '') => {
  return /(?=.*[a-z])/.test(value);
};

// The string must contain at least 1 uppercase alphabetical character
export const testUpperCase = (value = '') => {
  return /(?=.*[A-Z])/.test(value);
};

// The string must contain at least 1 numeric character
export const testNumber = (value = '') => {
  return /(?=.*[0-9])/.test(value);
};

// The string must contain at least 1 special character
export const testSymbol = (value = '') => {
  if (!/[\s]/.test(value) && value) {
    return /(?=.*\W)/.test(value);
  }
};

export const isValidPassword = (value = '') => {
  //   if (Env.isProd || Env.isStage) {
  return (
    !!testSymbol(value) &&
    !!testLowerCase(value) &&
    !!testNumber(value) &&
    !!testUpperCase(value) &&
    !!testLength(value)
  );
  //   } else {
  //     return value.length >= 6;
  //   }
};
