import React from 'react';
import { View, StyleSheet } from 'react-native';

import { useTheme } from '@uikit/hooks/useTheme';
import { useResponsive } from '@uikit/hooks/useResponsive';
import { IconSvg, Pressable } from '@app/_ui-kit';
import { default as NavButton } from '@app/web/components/NavButton';
import { openIntercom } from '@app/utils';

import Help from '@svg/help-circle.svg';
import LogoJetpack from '@svg/jetpack.svg';
import LogoFull from '@svg/lockup.svg';

const NavBar = ({ onLogo, middle, right, style, children, useFullLogo }) => {
  const { res } = useResponsive(navBar);
  const { theme, themeColors } = useTheme();

  return (
    <View style={[res.size, res.container, style]}>
      <View style={[res.containerOverlay, theme.navbar, res.size, theme.margins, style]}>
        <View style={[res.size, res.bounds]}>
          <View style={[res.navSection, res.left]}>
            <Pressable handleOnPress={onLogo}>
              {useFullLogo ? (
                <LogoFull fill={themeColors.textColor} style={{ width: 120 }} />
              ) : (
                <IconSvg svgComponent={LogoJetpack} size="md" color="text" />
              )}
            </Pressable>
          </View>
          <>
            {middle}
            <View style={[res.navSection, res.right]}>
              {right || <NavButton icon={Help} onPress={() => openIntercom()} />}
            </View>
          </>
        </View>
        {children}
      </View>
    </View>
  );
};

export const navBar = {
  size: StyleSheet.create({
    default: {
      height: 64,
      width: '100%',
    },
    xs: {
      height: 56,
    },
  }),
  right: StyleSheet.create({
    default: {
      justifyContent: 'flex-end',
    },
  }),
  navSection: StyleSheet.create({
    default: {
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
    },
  }),
  containerOverlay: StyleSheet.create({
    default: {
      flex: 1,
      height: '100%',
      width: '100%',
      alignItems: 'center',
    },
  }),
  container: StyleSheet.create({
    default: {
      alignItems: 'center',
      zIndex: 1000,
    },
  }),
  bounds: StyleSheet.create({
    default: {
      flexDirection: 'row',
    },
  }),
};

export default NavBar;
