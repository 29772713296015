import React from 'react';
import { View, StyleSheet, KeyboardAvoidingView, Platform } from 'react-native';
import { Copy } from '@types';
import { ResponsiveStyleShape, useResponsive, CatchTheme, Loading, Modal, useTheme } from '@uikit';
import { Layout } from './Layout';
import Page from './Page';
import HeaderGradient from './HeaderGradient';
import { useHeaderHeight } from './useHeaderHeight';

interface SplitLayoutProps {
  scrollRef: any;
  loading: boolean;
  title: Copy;
  subtitle: Copy;

  /**
   * @TODO
   * since the split layout only works on larger screens,
   * this prop will decide how to handle secondary content on mobile screens
   */
  handleContent: 'HIDDEN' | 'SHEET' | 'STACK';

  // for handling a sheet case
  isOpen?: boolean;
  onRequestClose?: () => void;

  // either one or two children (if the second is defined, goes to left hand side)
  children?: React.ReactElement | [React.ReactElement, React.ReactElement];
  toolbar?: React.ReactElement;
  sideToolbar?: React.ReactElement;
}

const SplitLayout: React.FC<SplitLayoutProps> = ({
  scrollRef,
  loading,
  title,
  subtitle,
  handleContent,
  toolbar,
  sideToolbar,
  isOpen,
  onRequestClose,
  children,
}) => {
  const { theme } = useTheme();
  // splits into left and right based on children (max 2 children can be passed!)
  const { isMobile, res } = useResponsive(responsive);

  const [content, side] = React.Children.toArray(children);
  const header = <Layout.Header bottomSpace title={title} subtitle={subtitle} />;

  // draw behind nav behaves a bit differently - this makes sure android looks ok
  const { platformOffset } = useHeaderHeight();

  if (isMobile) {
    return (
      <>
        <Page>
          <KeyboardAvoidingView
            style={[theme.flex1, { paddingTop: platformOffset }]}
            behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
            enabled={true}
            keyboardVerticalOffset={Platform.OS === 'ios' ? -100 : 10}
          >
            <>
              <Layout.Scroll ref={scrollRef} margins>
                <Layout bottomSpace>
                  {header}
                  {loading ? (
                    <Loading />
                  ) : (
                    <>
                      {content}
                      {handleContent === 'STACK' ? side : null}
                    </>
                  )}
                </Layout>
              </Layout.Scroll>
              {toolbar}
            </>
          </KeyboardAvoidingView>
        </Page>
        {handleContent === 'SHEET' && (
          <Modal isOpen={!!isOpen} onRequestClose={onRequestClose || (() => {})} close>
            <Page>
              <Layout.Scroll margins>{side}</Layout.Scroll>
              {sideToolbar}
            </Page>
          </Modal>
        )}
      </>
    );
  }

  return (
    <Page>
      <View style={styles.split}>
        <View style={[styles.pane, styles.leftPane, res.gutters]}>
          <View style={styles.content}>
            <Layout.Scroll ref={scrollRef} fullWidth fullHeight>
              <Layout bottomSpace topSpace>
                {header}
                {loading ? <Loading /> : content}
              </Layout>
            </Layout.Scroll>
            {toolbar}
          </View>
        </View>

        <HeaderGradient
          inherit
          hidden={isMobile}
          style={[styles.pane, styles.rightPane, res.gutters]}
        >
          <View style={styles.content}>
            <Layout.Scroll fullWidth fullHeight>
              {loading ? null : side}
            </Layout.Scroll>
          </View>
        </HeaderGradient>
      </View>
    </Page>
  );
};

const responsive: ResponsiveStyleShape<'gutters'> = {
  gutters: {
    browser: {
      paddingHorizontal: 64, // @todo pull from theme?
    },
    default: {
      paddingHorizontal: CatchTheme.spacing.mobile.margins,
    },
  },
};

const styles = StyleSheet.create({
  split: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: '100%',
  },
  pane: {
    display: 'flex',
  },
  content: {
    maxWidth: CatchTheme.containers.formSheet,
    width: '100%',
    height: '100%',
  },
  leftPane: {
    flex: 7, // assuming grid of 12
    alignItems: 'flex-end',
  },
  rightPane: {
    flex: 5, // assuming grid of 12
    alignItems: 'flex-start',
    width: '100%',
  },
});

// @ts-ignore
SplitLayout.options = {
  drawBehindNav: true,
  navMode: 'split',
};

export default SplitLayout;
