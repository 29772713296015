import { Trinary } from '@app/types';
import gql from 'graphql-tag';

export interface UpsertHealthApplicationVars {
  input: {
    id: string;
    isStartedApplication?: boolean;
    lastUsedRoute?: string;
    maritalStatus?: 'UNKNOWN' | 'SINGLE' | 'MARRIED';
    isSelfCoverage?: Trinary;
    isSpouseCoverage?: Trinary;
    numTaxDependents?: number;
    numDependentsRequestCoverage?: number;
    householdIncome?: number;
    aptcAgreement?: Trinary;
    aptcSignature?: string;
  };
}

export interface UpsertHealthApplicationResponse {
  upsertHealthApplication: Array<{
    id: string;
    applicationPhase?: string;
    lastUsedRoute?: string;
    maritalStatus?: 'UNKNOWN' | 'SINGLE' | 'MARRIED';
    isSelfCoverage?: boolean;
    isSpouseCoverage?: boolean;
    numTaxDependents?: number;
    numDependentsRequestCoverage?: number;
    householdIncome?: number;
    aptcAgreement?: Trinary;
    aptcSignature?: string;
    aptcHouseholdToApply?: number;
  }>;
}

export const UpsertHealthApplication = gql`
  mutation UpsertHealthApplication($input: UpsertHealthApplicationInput!) {
    upsertHealthApplication(input: $input) {
      id
      applicationPhase
      lastUsedRoute
      maritalStatus
      isSelfCoverage
      isSpouseCoverage
      numTaxDependents
      numDependentsRequestCoverage
      householdIncome
      aptcAgreement
      aptcSignature
      aptcHouseholdToApply
    }
  }
`;
