import React, { useMemo } from 'react';
import { View, ViewStyle } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import { useTheme } from '@uikit';
import { CatchTheme } from '../themes/catch';
import { UIGradientName } from '../types';

type GradientType = 'toolbar' | 'icon' | 'button';

export interface GradientProps {
  type?: GradientType;
  angle?: number;
  color?: string;
  color2?: string;
  gradient?: UIGradientName;
  style?: ViewStyle;
  show?: boolean;
}

const locations: Record<GradientType, Array<number>> = {
  toolbar: [0, 0.35],
  icon: [0, 1],
  button: [0, 1],
};

const angles: Record<GradientType, number> = {
  toolbar: 180,
  button: 180,
  icon: 315, // top right to bottom left
};

export const Gradient: React.FC<GradientProps> = ({
  type,
  angle = 160,
  color,
  color2,
  gradient,
  style,
  children,
  show = true,
}) => {
  const { themeColors, colorScheme } = useTheme();
  const _color = themeColors[`${color}Color`];
  const _color2 = themeColors[`${color2}Color`];

  const _gradientColors = useMemo(() => {
    const gradients = CatchTheme.gradients[colorScheme];

    // prefers the icon gradient, if defined
    if ((type === 'icon' || type === 'button') && `${gradient}Small` in gradients) {
      return gradients[`${gradient}Small`];
    }

    // otherwise, lookup the gradient by name
    return gradients[gradient];
  }, [colorScheme, gradient, type]);

  const hasColor = !!_color;
  const hasGradient = !!_gradientColors;

  const showGradient = (hasColor || hasGradient) && show;

  return showGradient ? (
    <LinearGradient
      angle={type ? angles[type] : angle}
      useAngle={!!gradient}
      locations={type ? locations[type] : undefined}
      style={style}
      colors={!!gradient ? _gradientColors : [_color, _color2 || themeColors.pageColor]}
    >
      {children}
    </LinearGradient>
  ) : (
    <View style={style}>{children}</View>
  );
};

export default Gradient;
