import React, { useCallback, useMemo } from 'react';
import { Platform } from 'react-native';

import {
  routes,
  useCurrentRoute,
  stacks,
  Stack,
  StackComponent,
  StackDefinition,
  navigate,
  open,
} from '@navigate';
import { useQuery, queries, OpenEnrollmentQueryData, HealthRenewQueryData } from '@data';

import { HealthRenewIntroView } from './renew/HealthRenewIntroView';
import { HealthRenewDetailsView } from './renew/HealthRenewDetailsView';
import { HealthRenewPlanView } from './renew/HealthRenewPlanView';
import { HealthRenewImportView } from './renew/HealthRenewImportView';
import { HealthRenewIdentityView } from './shared/IdentityView';
import { HealthRenewAgreementsView } from './shared/PrivacyAgreementsView';
import { HealthRenewResubmitView } from './application/agreements/AgreementsView';
import { HealthRenewSuccessView } from './renew/HealthRenewSuccessView';
import { hasAgreedToPrivacy, hasIdentityData, createLogger } from '@app/utils';

const Log = createLogger('HealthRenewStack');

const config: StackDefinition = {
  stackName: stacks.HEALTH_RENEW_STACK,
  options: {
    title: 'Quick Renew',
    // @workaround: issue with pushing new stack onto a sheet
    layout: Platform.OS === 'web' ? 'sheet' : 'page',
    navMode: 'flow',
    accentColor: 'coverage',
    presentation: 'basicSheet',
  },
  screens: [
    // in order of appearance:
    HealthRenewIntroView,
    HealthRenewAgreementsView,
    HealthRenewIdentityView,
    HealthRenewImportView,
    HealthRenewResubmitView,

    // not implemented
    HealthRenewDetailsView,
    HealthRenewPlanView,

    HealthRenewSuccessView,
  ],
};

export interface HealthRenewStackScreenProps {
  loading: boolean;
  oeCoverageYear?: Number;
  handleNext: () => void;
}

/**
 *
 *
 * HEALTH RENEW
 */
const HealthRenewStack: StackComponent = () => {
  const route = useCurrentRoute();

  const OE = useQuery<OpenEnrollmentQueryData>(queries.OPEN_ENROLLMENT);

  const oeCoverageYear = useMemo(() => {
    return OE?.data?.reference?.health?.openEnrollmentDates?.oeCoverageYear;
  }, [OE]);

  const { loading, data, refetch } = useQuery<HealthRenewQueryData>(queries.HEALTH_RENEW, {
    variables: {
      coverageYear: oeCoverageYear,
      renewalYear: oeCoverageYear ? parseInt(oeCoverageYear) - 1 : undefined,
    },
    skip: !oeCoverageYear,
  });

  const computed = useMemo(() => {
    // has a selected plan AND it was chosen for the oe year (2023)
    const selectedPlan =
      data?.viewerTwo?.healthExplorerData?.healthPlans?.topPlans?.selectedPlan &&
      data?.viewerTwo?.healthExplorerData?.coverageYear === oeCoverageYear;

    const applications = data?.viewerTwo?.health?.applications || [];
    const submitted = data?.viewerTwo?.health?.submitted || [];
    const renewalPolicies = data?.viewerTwo?.health?.policies || [];
    const isWindowShopping = data?.reference?.health?.openEnrollmentDates?.isWindowShopping;
    const isOpenEnrollment = data?.reference?.health?.openEnrollmentDates?.isOpenEnrollment;

    const continueApplication =
      submitted.length > 0 ? submitted[0] : applications.length > 0 ? applications[0] : undefined;

    return {
      hasSelectedPlan: !!selectedPlan,
      planName: selectedPlan?.healthPlan?.name,
      hasAgreements: hasAgreedToPrivacy(data?.viewerTwo?.agreements),
      hasIDProof: data?.viewerTwo?.identity?.status === 'VERIFIED',
      hasIdentityData: hasIdentityData(data?.viewer?.user),
      hasActivePolicy: data?.viewerTwo?.existingApplicationInfo?.hasActivePolicy,
      hasStartedApplication: applications.length > 0,
      hasSubmittedApplication: submitted.length > 0,
      isWindowShopping,
      isOpenEnrollment,
      continueApplication,
      renewalPolicies,
    };
  }, [data]);

  // Navigation
  const handleNext = useCallback(() => {
    // during OE, if theres a 2023 application (but no prior year active policy), route the user to app
    if (computed.isOpenEnrollment && computed.hasStartedApplication && !computed.hasActivePolicy) {
      open(routes.HEALTH_RESUME);
      return;
    }

    const navigation = [
      { route: routes.HEALTH_RENEW_INTRO, display: true },
      { route: routes.HEALTH_RENEW_AGREEMENTS, display: !computed.hasAgreements },
      {
        route: routes.ACCOUNT_SETUP_DOB,
        data: { slug: 'health', afterIdentity: routes.HEALTH_RENEW_IDENTITY },
        display: !computed.hasIdentityData,
      },
      { route: routes.HEALTH_RENEW_IDENTITY, display: !computed.hasIDProof },
      { route: routes.HEALTH_RENEW_IMPORT, display: computed.hasActivePolicy },
      {
        action: open,
        route: routes.EXPLORER_ZIPCODE,
        data: { chooseOEYear: true },
        display: !computed.hasSelectedPlan,
      },
      { action: open, route: routes.EDE_INTRO, display: computed.hasSelectedPlan },
    ];

    // builds a list of routes to display, including the current route
    const orderedRoutes = navigation
      .filter((path) => path.route === route || path.display)
      .map((path) => path.route);

    // get the route AFTER the current one
    const index = orderedRoutes?.findIndex((path) => path === route);
    const nextRoute = orderedRoutes[index + 1];

    // use the next route to lookup the config
    const config = navigation.find((n) => n.route === nextRoute);

    if (config) {
      Log.debug(config);

      if (config.action) {
        config.action(config?.route, config?.data);
      } else {
        navigate(config?.route, config?.data);
      }
    }
  }, [route, computed]);

  const screenData: HealthRenewStackScreenProps = useMemo(() => {
    return {
      loading,
      refetch,
      oeCoverageYear,
      handleNext,
      isOpenEnrollment: computed.isOpenEnrollment,
      continueApplication: computed.continueApplication,
      renewalPolicies: computed.renewalPolicies,
    };
  }, [
    handleNext,
    loading,
    refetch,
    oeCoverageYear,
    computed.isOpenEnrollment,
    computed.continueApplication,
    computed.renewalPolicies,
  ]);

  return (
    <Stack
      stackName={config.stackName}
      screens={config.screens}
      options={config.options}
      data={screenData}
    />
  );
};

HealthRenewStack.config = config;
export default HealthRenewStack;
