import React, { useState } from 'react';
import { useDeprecatedMutation } from '@data';
import { close } from '@navigate';

import DMIUploadForm from '@app/forms_old/DMIUploadForm';
// import { getDmiDocSubTypes, getDmiDocTypes, getSviDocTypes } from '@app/utils';

const DMIUploadView = ({ id, refetch, issueType, onClose, ...other }) => {
  // leave for CMS testing/screenshots
  // issueType = 'dmi';
  // const verificationType = 'NATIVE';
  // const subType = 'INDIAN_STATUS';
  // const statusCode = 'MOVED_TO_NEW_SERVICE_AREA';
  // const a = getDmiDocTypes(verificationType, subType);
  // const b = getDmiDocSubTypes(verificationType);
  // const a = getSviDocTypes(statusCode);

  const [refetching, setRefetching] = useState(false);
  const handleSubmit = ({ documents, type, ...other }, dispatch, { save }) => {
    // sub type overrides the type if available
    // we cleanup any added suffix
    const docType = (other[`${type}DocumentSubType`] || other[`${type}DocumentType`]).split('-')[0];
    const input = documents.map((doc) => ({
      ...doc,
      ...other,
      [`${type}DocumentType`]: docType,
    }));

    save({
      variables: {
        input,
      },
    });
  };

  const handleClose = async () => {
    setRefetching(true);
    await refetch();
    setRefetching(false);

    if (onClose) {
      onClose();
    } else {
      close();
    }
  };

  const [uploadIssueDocuments, { loading: uploading }] = useDeprecatedMutation(
    'uploadIssueDocuments',
    {
      onCompleted: handleClose,
    },
  );

  return (
    <DMIUploadForm
      onClose={handleClose}
      initialValues={{
        issueID: id,
        type: issueType,
      }}
      onSubmit={handleSubmit}
      save={uploadIssueDocuments}
      issueType={issueType}
      {...other}
      // statusCode={statusCode}
      // subType={subType}
      // docOptions={a}
      // subDocOptions={b}
      uploading={uploading || refetching}
    />
  );
};

export default DMIUploadView;
