import React from 'react';
import { useTheme } from '@uikit';
import BarChart from './base/Bar';
import chroma from 'chroma-js';
import { monthNames } from '@app/utils';

interface IncomeBarProps {
  incomeByMonth: Array<{ month: number; totalIncome: number }>;
  widget?: boolean; //adds padding and dynamic bar widths for income widget
  isPreviousYear?: boolean; //removes highlighting logic
}

/**
 * @todo type inputs, extend Bar.tsx types
 */
const IncomeBar = ({ incomeByMonth = [], widget, isPreviousYear }: IncomeBarProps) => {
  const { themeColors, colorScheme } = useTheme();
  const d = new Date();
  let month = d.getMonth() + 1;

  const array = incomeByMonth?.map((item, idx) => ({
    month: idx + 1,
    xLabel: monthNames[idx]?.slice(0, 1),
    totalIncome: item?.totalIncome,
  }));

  return (
    <BarChart
      height={72}
      options={{ showAxisLabels: !widget }}
      data={array}
      x="month"
      y="totalIncome"
      style={{
        data: {
          fill: ({ datum }) =>
            isPreviousYear
              ? chroma.mix(themeColors.incomeColor, themeColors.textColor, datum?.month / 12).hex()
              : datum?.month === month
              ? themeColors.incomeColor
              : datum?.month < month
              ? widget
                ? colorScheme === 'dark'
                  ? themeColors.borderColor
                  : themeColors.incomeLightColor
                : themeColors.incomeLightColor
              : themeColors.borderColor,
        },
      }}
    />
  );
};

export default IncomeBar;
