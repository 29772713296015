import React from 'react';
import { date, required } from 'redux-form-validators';
import { addDays, subDays } from 'date-fns';
import { Link } from 'react-router-dom';

import createValidator from './createValidator';
import { common, ssnValidation } from './primitives';

const PREFIX = 'catch.ede.validation';
export const requiredMsg = {
  id: `${PREFIX}.required`,
};
const rangeBefore60DaysAgoMsg = {
  id: `${PREFIX}.rangeBefore60DaysAgo`,
};
const rangeAfterTodayMsg = {
  id: `${PREFIX}.rangeAfterToday`,
};
const rangeAfter60DaysFromNowMsg = {
  id: `${PREFIX}.rangeAfter60DaysFromNow`,
};
const rangeBeforeTodayMsg = {
  id: `${PREFIX}.rangeBeforeToday`,
};
export const dateFormatMsg = {
  id: `${PREFIX}.dateFormat`,
};

export const defaultHealthInfo = {
  givenName: common.name,
  familyName: common.name,
  ssn: [ssnValidation, required({ if: (values) => !!values.isRequestingCoverage })],
};

export const forFinancialAssistance = {
  if: (values) => values.isRequestingFinancialAssistance === 'YES',
};

export const allScreeningPhase1 = {
  areAllSameAddress: [required()],
  isPlanningToFileTax: [required(forFinancialAssistance)],
  isResponsibleForChildNotOnTaxReturn: [required(forFinancialAssistance)],
  isPregnantOrHadChildLast60Days: [required(forFinancialAssistance)],
  isAnyoneFullTimeStudent1822: [required(forFinancialAssistance)],
  isCoveredThroughJobOrCobra: [required(forFinancialAssistance)],
  areCitizens: [required()],
  isCanProvideSsn: [required()],
  isApplicationNameSameAsSsn: [required()],
  isNaturalizedOrDerivedCitizenship: [required()],
  isIncarcerated: [required()],
  isIndianOrAlaskan: [required()],
  isFormerFosterCareAndUnder25: [required()],
  isOfferedIchra: [required()],
};

export const allScreening = {
  isRequestingFinancialAssistance: [required()],
  areAllSameAddress: [required()],
  isPlanningToFileTax: [required(forFinancialAssistance)],
  isResponsibleForChildNotOnTaxReturn: [required(forFinancialAssistance)],
  isCoveredThroughJobOrCobra: [required(forFinancialAssistance)],
  isIndianOrAlaskan: [required()],
  isOfferedIchra: [required()],
};

export const memberInfoQuestions = {
  givenName: common.name,
  familyName: common.name,
  sex: [required()],
  dob: [
    required(),
    date({
      format: 'mm/dd/yyyy',
      '<': new Date(),
      msg: {
        format: dateFormatMsg,
        '<': rangeBeforeTodayMsg,
      },
    }),
  ],
  ssn: [required(), ssnValidation],
  lastTobaccoUseDate: [
    required({
      if: (values) => values?.isSmoker === 'YES',
      msg: requiredMsg,
    }),
    date({
      format: 'mm/dd/yyyy',
      if: (values) => values?.isSmoker === 'YES',
      msg: {
        format: dateFormatMsg,
      },
    }),
  ],
};

export const phaseTwoMemberInfo = {
  isCitizen: [
    required({
      if: (values) => values?.isRequestingCoverage,
    }),
  ],
  isSameNameApplicationAndDocument: [
    required({
      if: (values) => values.ssn !== '',
    }),
  ],
  filerRelation: [required({ if: (values) => values.relation === 'CHILD' })],
  spouseRelation: [
    required({
      if: (values) => values.relation === 'CHILD' && values.hasSpouse,
    }),
  ],
  'ssnAlternateName.givenName': [
    required({
      if: (values) => values.isSameNameApplicationAndDocument === 'NO',
    }),
  ],
  'ssnAlternateName.familyName': [
    required({
      if: (values) => values.isSameNameApplicationAndDocument === 'NO',
    }),
  ],
};

export const validateSEP = createValidator({
  coverageLostDate: [
    required({
      if: (values) => values.isLostCoverageLast60Days === 'YES',
      msg: requiredMsg,
    }),
    date({
      format: 'mm/dd/yyyy',
      if: (values) => values.isLostCoverageLast60Days === 'YES',
      '>': subDays(new Date(), 60),
      '<': new Date(),
      msg: {
        '>': rangeBefore60DaysAgoMsg,
        '<': rangeAfterTodayMsg,
        format: dateFormatMsg,
      },
    }),
  ],
  coverageLostDateFuture: [
    required({
      if: (values) => values.isWillLoseCoverageNext60Days === 'YES',
      msg: requiredMsg,
    }),
    date({
      format: 'mm/dd/yyyy',
      if: (values) => values.isWillLoseCoverageNext60Days === 'YES',
      '>': new Date(),
      '<': addDays(new Date(), 60),
      msg: {
        '>': rangeBeforeTodayMsg,
        '<': rangeAfter60DaysFromNowMsg,
        format: dateFormatMsg,
      },
    }),
  ],
  whenLawfulPresence: [
    required({
      if: (values) => values.isGainedLawfulPresence === 'YES',
      msg: requiredMsg,
    }),
    date({
      format: 'mm/dd/yyyy',
      if: (values) => values.isGainedLawfulPresence === 'YES',
      '>': subDays(new Date(), 60),
      '<': new Date(),
      msg: {
        '>': rangeBefore60DaysAgoMsg,
        '<': rangeAfterTodayMsg,
        format: dateFormatMsg,
      },
    }),
  ],
  whenMarried: [
    required({ if: (values) => values.isMarriedLast60Days === 'YES' }),
    date({
      format: 'mm/dd/yyyy',
      if: (values) => values.isMarriedLast60Days === 'YES',
      '>': subDays(new Date(), 60),
      '<': new Date(),
      msg: {
        '>': rangeBefore60DaysAgoMsg,
        '<': rangeAfterTodayMsg,
        format: dateFormatMsg,
      },
    }),
  ],
  hasCoverage60DaysBeforeMarriage: [
    required({ if: (values) => values.isMarriedLast60Days === 'YES' }),
  ],
  whenRelocated: [
    required({ if: (values) => values.isMoved === 'YES' }),
    date({
      format: 'mm/dd/yyyy',
      if: (values) => values.isMoved === 'YES',
      '>': subDays(new Date(), 60),
      '<': new Date(),
      msg: {
        '>': rangeBefore60DaysAgoMsg,
        '<': rangeAfterTodayMsg,
        format: dateFormatMsg,
      },
    }),
  ],
  // hasCoverageLast60DaysBeforeRelocation: [required({ if: (values) => values.isMoved === 'YES' })],
  previousZipcode: [
    required({
      if: (values) => values.isMoved === 'YES' && values.isMovedFromOutsideUs === 'NO',
    }),
  ],
  previousCounty: [
    required({
      if: (values) => values.isMoved === 'YES' && values.isMovedFromOutsideUs === 'NO',
    }),
  ],
  whenReleasedFromIncarceration: [
    required({ if: (values) => values.isReleasedFromIncarceration === 'YES' }),
    date({
      format: 'mm/dd/yyyy',
      if: (values) => values.isReleasedFromIncarceration === 'YES',
      '>': subDays(new Date(), 60),
      '<': new Date(),
      msg: {
        '>': rangeBefore60DaysAgoMsg,
        '<': rangeAfterTodayMsg,
        format: dateFormatMsg,
      },
    }),
  ],
  whenBecomeADependent: [
    required({ if: (values) => values.isGainDependent === 'YES' }),
    date({
      format: 'mm/dd/yyyy',
      if: (values) => values.isGainDependent === 'YES',
      '>': subDays(new Date(), 60),
      '<': new Date(),
      msg: {
        '>': rangeBefore60DaysAgoMsg,
        '<': rangeAfterTodayMsg,
        format: dateFormatMsg,
      },
    }),
  ],
});

export const validateApplicant = createValidator({
  age: [
    required(),
    (value, context) => {
      const healthcareLink = (
        <Link inline target="_blank" href="https://healthcare.gov">
          healthcare.gov
        </Link>
      );
      if (value < 0) {
        return 'Looks like one of the ages is not valid.';
      }
      if (context.relation === 'CHILD') {
        return value < 26
          ? undefined
          : `If a dependent over 25 needs coverage, visit ${healthcareLink}`;
      }
    },
  ],
});

export const partnerApplicationContact = {
  'homeAddress.street1': common.address1,
  'homeAddress.zip': common.zipcode,
  'homeAddress.city': [required()],
  'homeAddress.state': [required()],
  email: common.email,
  phoneNumber: common.phoneNumber,
};

export const partnerApplicant = {
  givenName: common.name,
  familyName: common.name,
  dob: common.date,
  ssn: common.ssn,
  sex: [required()],
};
