import React from 'react';
import { stacks, Stack, StackComponent } from '@navigate';
import { LobbyView } from './LobbyView';
import { LoginView } from './SignInView';
import { RegisterView } from './SignUpView';
import { ConfirmCodeView } from './ConfirmCodeView';
import { ConfirmPasswordView } from './ConfirmPasswordView';
import { ForgotPasswordView } from './ForgotPasswordView';

const config = {
  stackName: stacks.AUTH_STACK,
  options: {},
  screens: [
    LobbyView,
    RegisterView,
    LoginView,
    ConfirmCodeView,
    ConfirmPasswordView,
    ForgotPasswordView,
  ], // @todo E-3445 typescript
};

const AuthStack: StackComponent = () => {
  return <Stack stackName={config.stackName} screens={config.screens} options={config.options} />;
};

AuthStack.config = config;
export default AuthStack;
