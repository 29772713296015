import React, { useState } from 'react';
import { View } from 'react-native';
import { PreviousApplicationCard } from '@common';
import { Button, Link, useTheme } from '@uikit';
import { Toolbar } from '@layouts';
import { pop } from '@navigate';
import { useCopy } from '@util';
import { SplitFormBlueprint } from '@blueprints';
import { identity } from 'lodash/util';
import { FieldConfig } from '@types';

const PREFIX = 'catch.ede.ApplicationImport';

const ApplicationImportForm: React.FC = ({
  onSubmit,
  applications,
  isCurrent,
  loading,
  invalid,
  initialValues,
  submitting,
}) => {
  const { c } = useCopy(PREFIX);
  const { theme } = useTheme();
  const [manual, setManual] = useState(false);
  const pretitle = isCurrent ? '⚡️' : '👋';
  const headerProps = isCurrent
    ? {
        title: pretitle + ' ' + c('currentTitle'),
        subtitle: c('currentSubtitle'),
      }
    : {
        title: pretitle + ' ' + c('pastTitle'),
        subtitle: c('pastSubtitle'),
      };

  const fields: Array<FieldConfig> = [
    manual && {
      type: 'text',
      name: 'applicationID',
      required: true,
      label: c('manualLabel') || 'Application identifier',
      help:
        c('manualInfo') ||
        'After you apply for Marketplace coverage, you’ll get a notice with your eligibility results that contains your Application ID.',
    },
  ].filter(identity) as Array<FieldConfig>;

  return (
    <SplitFormBlueprint
      {...headerProps}
      loading={loading || submitting}
      submitting={submitting}
      toolbar={
        <Toolbar onBack={() => pop()}>
          <Button inherit testID="next" onPress={onSubmit} disabled={invalid || loading}>
            {isCurrent ? c('importButton') : c('continueButton')}
          </Button>
        </Toolbar>
      }
      formConfig={{
        fields,
        initialValues,
        onSubmit,
      }}
      precontent={
        <>
          {!manual && (
            <>
              <View style={[theme.bottomSpace2]}>
                {applications.map((app, i) => (
                  <PreviousApplicationCard key={app?.id} application={app} disabled={submitting} />
                ))}
              </View>
              <View style={[theme.alignCenter]}>
                <Link small onPress={() => setManual(true)} gutter="top" style={[]}>
                  {c('manualLink') || 'Not seeing your application?'}
                </Link>
              </View>
            </>
          )}
        </>
      }
    />
  );
};

export default ApplicationImportForm;
