import React, { useState } from 'react';

import { useDeprecatedMutation } from '@data';
import { SheetBlueprint } from '@app/blueprints';
import { Layout } from '@app/layouts';
import { FileDropper } from '@app/_common';

const UploadAvatar = ({ id, closeModal, hasImage }) => {
  const [input, setInput] = useState(null);

  const [removeAvatar] = useDeprecatedMutation('removeAvatar', {
    refetchQueries: ['UserPreload', 'User'],
    onCompleted: closeModal,
  });

  const [uploadAvatar] = useDeprecatedMutation('uploadAvatar', {
    refetchQueries: ['UserPreload', 'User'],
    onCompleted: closeModal,
  });

  return (
    <SheetBlueprint
      actions={[
        {
          label: 'Remove Image',
          hidden: !hasImage,
          destructive: true,
          onAction: () =>
            removeAvatar({
              variables: { input: { personID: id } },
            }),
        },
        {
          label: 'Save Image',
          // disabled: !hasImage,
          onAction: () => uploadAvatar({ variables: { input } }),
        },
      ]}
    >
      <Layout topSpace centerAll>
        <FileDropper
          name="AVATAR"
          squareAvatar
          handleDelete={() => setInput(null)}
          filePrefix={'ProfilePhoto'}
          copyPrefix={'catch.module.me.MeView'}
          handleDrop={({ fileKey, fileType }) => {
            setInput({
              imageFiletype: fileType,
              imageKey: fileKey,
              personID: id,
            });
          }}
        />
      </Layout>
    </SheetBlueprint>
  );
};

export default UploadAvatar;
