import { formatISO, addDays } from 'date-fns';
import { useDeprecatedMutation } from '@data';
import { popToast } from '@uikit';
import { useCopy, formatBenefit } from '@app/utils';
import { useSheet, sheets } from '@navigate';

const today = new Date();

const optimisticSnooze = ({ id, status, numDaysPaused, __typename }) => ({
  upsertGoal: {
    id,
    status,
    pausedEndDate:
      numDaysPaused && numDaysPaused > 0
        ? formatISO(addDays(today, numDaysPaused), { representation: 'date' })
        : null,
    __typename,
  },
});

export const useSnooze = (goal) => {
  const { replace: replaceSheet, closeAll } = useSheet();

  const canPause = /^ACTIVE/.test(goal?.status);
  const status = canPause ? 'PAUSED' : 'ACTIVE'; // the status to switch to

  const { c } = useCopy('catch.plan');

  const [toggleGoal, { loading }] = useDeprecatedMutation('upsertGoal', {
    onCompleted: (data) => {
      const isResumed = data.upsertGoal.status === 'ACTIVE';
      const type = isResumed ? 'resumed' : 'paused';

      popToast({
        type: isResumed ? 'success' : 'neutral',
        title: c(`${type}.toastTitle`, {
          benefit: formatBenefit.name(goal),
        }),
        msg: c(`${type}.toastMsg`, {
          benefit: formatBenefit.name(goal),
        }),
      });
    },
  });

  const pause = () => replaceSheet(sheets.PAUSE_GOAL, { goal });

  const snooze = ({ numDaysPaused }) => {
    if (!goal) return;

    toggleGoal({
      variables: { input: { id: goal.id, status, numDaysPaused } },
      optimisticResponse: optimisticSnooze({
        ...goal,
        status,
        numDaysPaused,
      }),
    });

    closeAll();
  };

  const resume = () => {
    if (!goal) return;

    toggleGoal({
      variables: { input: { id: goal.id, status, numDaysPaused: null } },
      optimisticResponse: optimisticSnooze({
        ...goal,
        status,
        numDaysPaused: -1,
      }),
    });

    closeAll();
  };

  return {
    loading,
    canPause,
    pause, // to open the pause menu
    snooze, // to trigger the snooze mutation
    resume,
  };
};
