import React from 'react';

import { Button, Link, Text, Banner } from '@uikit';
import { BasicLayout, Toolbar } from '@layouts';
import { useCopy } from '@app/utils';
import { close, open, routes } from '@navigate';

import Check from '@svg/check.svg';

const PREFIX = 'catch.ede.CIC.noImpact';

const CICNoChange = ({ screenStep }) => {
  const { c } = useCopy(PREFIX);

  const showMessage = /incomeNoChange|sepMoveOutdatedPolicyValid/.test(screenStep);

  const handleSubmit = () => {
    close();
  };
  return (
    <BasicLayout
      title={
        <Text size="h4" color="debit" align="center">
          {c('title')}
        </Text>
      }
      subtitles={[
        c(`${screenStep}.subtitle`, {
          link: (
            <Link key="link" inline>
              {c(`${screenStep}.link`)}
            </Link>
          ),
        }),
      ]}
      toolbar={
        <Toolbar>
          <Button onPress={handleSubmit}>{c('done')}</Button>
        </Toolbar>
      }
    >
      {showMessage && (
        <Banner
          asset={{ svg: Check }}
          onPress={() => open(routes.EDE_HOUSEHOLD)}
          title={c(`${screenStep}.message`)}
        />
      )}
    </BasicLayout>
  );
};

export const CICNoChangeView = {
  name: routes.CIC_NO_CHANGE,
  component: CICNoChange,
  options: {},
};
