import React from 'react';
import { FormattedMessage } from 'react-intl';

const PREFIX = 'catch.ede.HealthEnrollment';

const getCopy = (name, values) => {
  return (
    <FormattedMessage id={`${PREFIX}.${name}`} defaultMessage={'_none_'} values={values}>
      {(txt) => (txt === '_none_' ? null : txt)}
    </FormattedMessage>
  );
};

export default getCopy;
