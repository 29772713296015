import { BANK_ERROR_CODES } from '@app/utils';
import gql from 'graphql-tag';
import access from 'safe-access';

const SYNC_STATUS = gql`
  query SyncStatus {
    viewer {
      id
      primaryAccount {
        id
        bankLink {
          id
          providerType
          syncStatus
          errorCode
        }
      }
      bankLinks(input: { includeNotWatched: true }) {
        id
        providerType
        syncStatus
        accounts {
          id
        }
      }
    }
  }
`;

const formatter = (data) => {
  const get = access(data);
  const primaryBankLink = get('viewer.primaryAccount.bankLink');
  const allBankLinks = get('viewer.bankLinks');
  const hasAnyBankLink = allBankLinks?.length > 0;
  const errorCode = BANK_ERROR_CODES[primaryBankLink?.errorCode];

  return {
    hasPrimaryBankLink: !!primaryBankLink?.id,
    primarySyncStatus: primaryBankLink?.syncStatus,
    primaryErrorCode: errorCode,
    showFlag: primaryBankLink?.syncStatus !== 'GOOD' && !!errorCode,
    primaryBankLink,
    /* */
    hasAnyBankLink,
  };
};

export default {
  document: SYNC_STATUS,
  formatter,
};
