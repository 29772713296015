const config = {
  memberQuestions: [144, 147, 149, 250],
  immigrationQuestions: [40, 45],
  alternativeCitizenshipDocumentation: [45],
  unemploymentIncome: [300],
};

export const renderQuestions = ({ uiQuestionsToDisplay }) => {
  const parsedQuestions = uiQuestionsToDisplay || {};

  const shouldRender = (name) => {
    // if (applicationPhase !== 'TWO') return false;
    return config[name]?.some((Q) => parsedQuestions?.[Q]);
  };

  const renderMemberQuestions = shouldRender('memberQuestions');
  const renderImmigrationQuestions = shouldRender('immigrationQuestions');
  const renderUnemploymentQuestion = config['unemploymentIncome']?.some(
    (Q) => parsedQuestions?.[Q],
  );

  return {
    parsedQuestions,
    renderMemberQuestions,
    renderImmigrationQuestions,
    renderUnemploymentQuestion,
  };
};
