import gql from 'graphql-tag';

export const UPLOAD_DOCUMENT = gql`
  mutation UploadDocument($input: DocumentMetadataInput!) {
    uploadDocument(input: $input) {
      id
      createdOn
      ext
      documentType
    }
  }
`;

const uploadDocument = {
  document: UPLOAD_DOCUMENT,
};
export default uploadDocument;
