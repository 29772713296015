import React, { useMemo } from 'react';
import chroma from 'chroma-js';
import { StyleSheet, View } from 'react-native';
import { HapticStyle, href } from '@types';
import { useLayoutContext, Stack } from '@layouts';
import { useDebug } from '@hooks/useDebug';
import { useTheme } from '@app/_ui-kit/hooks/useTheme';
import { Pressable } from '../Pressable';
import { Asset } from '../Asset';
import { Text } from '../Text';
import { Spinner } from '../Spinner';
import Gradient from '../Gradient';

const MANUAL_CONTRAST_ADJUST = 40;

type ButtonFontSize = 'xxs' | 'xs' | 'fp' | 'p' | 'h5';
type ButtonGlyphSize = 'mini' | 'glyph' | 'xs' | 'sm';

interface Props {
  testID: string;
  svg?: any;
  reverse?: boolean;
  loading?: boolean;
  disabled?: boolean;
  onPress?: () => void;
  href?: href;
  children?: any;
  mini?: boolean;
  small?: boolean;
  large?: boolean;
  caps?: boolean;

  full?: boolean;
  wide?: boolean;
  gutter?: string;
  alt?: boolean;
  haptics?: HapticStyle;
  accentColor?: string;
  inherit?: boolean; // when set, inherits the layout accent color
}

const sizeLookup = ({
  mini,
  small,
  large,
}): { textSize: ButtonFontSize; iconSize: ButtonGlyphSize } => {
  if (mini) return { textSize: 'xs', iconSize: 'mini' };
  if (small) return { textSize: 'fp', iconSize: 'glyph' };
  if (large) return { textSize: 'h5', iconSize: 'sm' };
  return { textSize: 'p', iconSize: 'xs' };
};

const accentColorLookup = ({ accentColor, themeColors }) => {
  return themeColors[accentColor + 'Color'];
};

const Button = ({
  testID,
  children,
  onPress,
  href,
  haptics = 'LIGHT',
  disabled,
  loading,
  svg,
  reverse,
  mini,
  small,
  large,
  full,
  wide,
  caps,
  alt = false,
  accentColor: accentColorProp,
  inherit,
}: Props) => {
  const { theme, themeColors } = useTheme();
  const { showTestIds } = useDebug();
  const context = useLayoutContext();

  const accentColor = inherit
    ? alt
      ? context?.accentColor + 'Light'
      : context?.accentColor
    : accentColorProp;

  const iconOnly = useMemo(() => {
    return !!svg && !children;
  }, [children, svg]);

  const _accentColor = useMemo(() => {
    return accentColorLookup({ accentColor, themeColors });
  }, [accentColor, themeColors]);

  // only show gradient if accent color is
  const hideGradient = /Light/.test(accentColor);

  const shadow = useMemo(() => {
    if (disabled) return undefined;

    return alt || /Light/.test(accentColor || '') ? undefined : theme.cardShadow;
  }, [accentColor, theme, disabled, alt]);

  const buttonStyleName = alt ? 'secondaryButton' : 'primaryButton';

  /**
   * extract
   * memo for speed?
   * */
  const buttonTextColor = useMemo(() => {
    const black = !!_accentColor && chroma.distance(_accentColor, '#000000');
    const white =
      !!_accentColor && chroma.distance(_accentColor, '#FFFFFF') + MANUAL_CONTRAST_ADJUST;
    return !!_accentColor ? (black > white ? `ink` : `snow`) : `${buttonStyleName}Text`;
  }, [_accentColor]);

  const { iconSize, textSize } = sizeLookup({ mini, small, large });

  const iconComponent = svg && !loading && (
    <Asset testID={`${testID}-asset`} size={iconSize} svg={svg} color={`${buttonTextColor}Color`} />
  );

  return (
    <View style={[theme.buttonContainer, full && theme.fullWidth, wide && !full && theme.cardMax]}>
      <Pressable
        testID={testID}
        handleOnPress={onPress}
        href={href}
        haptics={haptics}
        disabled={disabled}
        corners="xl"
        pressedStyle={[theme.buttonActive]}
        style={[
          theme.button,
          shadow,
          theme.largeCorners,
          theme[buttonStyleName],
          !!accentColor && {
            backgroundColor: _accentColor,
          },
          !!mini && theme.buttonMini,
          !!small && theme.buttonSmall,
          reverse && theme.reverse,
          (wide || full) && theme.fullWidth,
          iconOnly && theme.buttonIconOnly,
          iconOnly && !!mini && theme.buttonMiniIconOnly,
          iconOnly && !!small && theme.buttonSmallIconOnly,
        ]}
      >
        {({ intrx }) => (
          <>
            {!!accentColor && !hideGradient && (
              <Gradient
                type="button"
                gradient={accentColor}
                style={[styles.gradient, theme.largeCorners]}
              />
            )}
            {loading && (
              <View style={styles.viewLoading}>
                <Spinner color={accentColor?.replace('Light', '')} />
              </View>
            )}
            {iconOnly ? (
              iconComponent
            ) : (
              <Stack
                horizontal
                reverse={reverse}
                spacing="0b"
                style={[intrx?.pressed && theme.pressedText]}
              >
                {iconComponent}
                {children && (
                  <Text
                    inline
                    align="center"
                    weight="medium"
                    color={buttonTextColor}
                    size={textSize}
                    mono={!!caps}
                    style={[loading && { opacity: 0 }, styles.center]}
                  >
                    {showTestIds ? testID : children}
                  </Text>
                )}
              </Stack>
            )}
          </>
        )}
      </Pressable>
    </View>
  );
};

const styles = StyleSheet.create({
  gradient: { position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 },
  center: { textAlign: 'center' },
  viewLoading: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default Button;
