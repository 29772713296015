import { currentYear } from '@app/utils';
import { useCallback, useMemo } from 'react';
import { useDeprecatedMutation, useDeprecatedQuery } from '@data';
import { useCopy } from '@util';
import { ActionRowProps } from '@uikit';
import { usePagination } from './usePagination';

type ChangeType = 'AUTO' | 'ASK' | 'IGNORE' | 'TOGGLE';
type AutomationType = 'ALWAYS' | 'NEVER' | 'SOMETIMES';
type WorkType = 'WORK_TYPE_1099' | 'WORK_TYPE_W2' | 'WORK_TYPE_DIVERSIFIED';

interface UseIncomeSourceOptions {
  sourceID: string;
}

interface RuleChangePayload {
  incomeSourceID: string;
  catchTransactionTagID?: string;
  automationType?: AutomationType;
  workType?: WorkType;
  isActive?: any;
  skipUpdate?: boolean;
}

const _COPY_PREFIX = 'catch.income.incomeSource';

const noop = () => {};
const joinResults = (prev, next) => ({
  ...prev,
  viewer: {
    ...prev.viewer,
    incomeTransactionsPaged: {
      ...prev.viewer.incomeTransactionsPaged,
      edges: [
        ...prev.viewer.incomeTransactionsPaged.edges,
        ...next.viewer.incomeTransactionsPaged.edges,
      ],
      pageInfo: next.viewer.incomeTransactionsPaged.pageInfo,
    },
  },
});

export const useIncomeSource = ({ sourceID }: UseIncomeSourceOptions) => {
  /* this is to grab the name/asset, which should already be cached */
  const { c } = useCopy(_COPY_PREFIX);
  const { incomeSources } = useDeprecatedQuery('income', {
    variables: {
      currentYear,
      prevFrom: `${currentYear - 1}-01-01`,
      prevTo: `${currentYear - 1}-12-31`,
    },
  });

  /* get autopilot rules for source, this is also the fallback resource for the asset */
  const { rule, loading: queryingRule } = useDeprecatedQuery('incomeAutomationRule', {
    variables: { sourceId: sourceID },
    skip: !sourceID,
  });

  const source = useMemo(() => {
    if (incomeSources && incomeSources.length) {
      const source = incomeSources.find((s) => s.id === sourceID);
      if (source) {
        return source;
      }
    }
    return rule?.source;
  }, [incomeSources, rule, sourceID]);

  const {
    transactions,
    initialLoading: queryingTransactions,
    fetchMore,
    canFetchMore,
  } = usePagination('incomeTransactionsPaged', {
    pageSize: 25,
    variables: { sourceID },
    joinResults,
    filter: undefined,
  });

  //@ts-ignore
  const [upsertRules, { loading: upserting }] = useDeprecatedMutation('setAutopilotRules', {
    refetchQueries: ['IncomeAutomationRule'],
  });

  const querying = queryingRule || queryingTransactions;
  const loading = upserting || querying;

  const upsertRuleChange = useCallback(
    (changeType: ChangeType, withhold?: boolean) => {
      let payload: RuleChangePayload = {
        incomeSourceID: sourceID,
      };

      switch (changeType) {
        case 'AUTO': {
          payload.automationType = 'ALWAYS';
          payload.workType = rule?.workType || 'WORK_TYPE_1099';
          payload.isActive = 'YES';
          payload.userHandlesActivation = true;
          break;
        }
        case 'ASK': {
          payload.automationType = 'SOMETIMES';
          break;
        }
        case 'IGNORE': {
          payload.automationType = 'NEVER';
          break;
        }
        case 'TOGGLE': {
          payload.automationType = rule?.automationType || 'ALWAYS';
          payload.workType = withhold ? 'WORK_TYPE_1099' : 'WORK_TYPE_W2';
          break;
        }
        default: {
          payload.skipUpdate = true;
          break;
        }
      }

      if (!payload.skipUpdate && sourceID) {
        //@ts-ignore
        return upsertRules({
          variables: {
            input: {
              upsertIncomeAutomationRuleInputs: [payload],
            },
          },
        });
      }
      return noop();
    },
    [rule, sourceID],
  );

  const toggleAction: ActionRowProps = useMemo(
    () => ({
      testID: 'change-worktype',
      label: c('workTypeToggle'),
      interaction: 'switch',
      selectedByDefault: rule?.workType === 'WORK_TYPE_1099',
      onAction: (withhold: boolean) => upsertRuleChange('TOGGLE', withhold),
      disabled: loading || !rule?.automationType || rule?.automationType === 'SOMETIMES',
    }),
    [loading, rule],
  );

  const availableActions = useMemo(
    () => (rule?.automationType === 'ALWAYS' ? [toggleAction] : null),
    [rule, toggleAction],
  );

  return {
    availableActions,
    canFetchMore,
    fetchMore,
    querying,
    queryingTransactions,
    rule,
    source,
    transactions,
    upsertRuleChange,
    loading: upserting,
  };
};

export default useIncomeSource;
