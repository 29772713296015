import React from 'react';

import { SimpleRow } from '@uikit';
import { Stack } from '@layouts';
import { useCopy } from '@app/utils';
import { useDeprecatedQuery, useDeprecatedMutation } from '@data';

const EmailNotifications = () => {
  const { c } = useCopy('catch.profile.AccountSettings');

  const { myBenefits, recommendedBenefits, featureAnnouncements } =
    useDeprecatedQuery('emailNotifications');
  const [update] = useDeprecatedMutation('updateNotificationSettings');

  function handleChange(name, value) {
    update({
      variables: {
        input: {
          emailNotificationSettings: {
            myBenefits,
            recommendedBenefits,
            featureAnnouncements,
            [name]: value,
          },
        },
      },
      optimisticResponse: {
        updateNotificationSettings: {
          email: {
            myBenefits,
            recommendedBenefits,
            featureAnnouncements,
            [name]: value,
            __typename: 'EmailNotificationSettings',
          },
          __typename: 'NotificationSettings',
        },
      },
    });
  }

  return (
    <Stack separatorComponent>
      <SimpleRow
        interaction="switch"
        label={c('myBenefits.title')}
        selected={!!myBenefits}
        selectedByDefault={true}
        onPress={(val) => handleChange('myBenefits', !myBenefits)}
      />
      <SimpleRow
        interaction="switch"
        label={c('recBenefits.title')}
        selected={!!recommendedBenefits}
        selectedByDefault={true}
        onPress={(val) => handleChange('recommendedBenefits', !recommendedBenefits)}
      />
      <SimpleRow
        interaction="switch"
        label={c('features.title')}
        selected={!!featureAnnouncements}
        selectedByDefault={true}
        onPress={(val) => handleChange('featureAnnouncements', !featureAnnouncements)}
      />
    </Stack>
  );
};

export default EmailNotifications;
