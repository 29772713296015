import React from 'react';
import { View } from 'react-native';

import { Stack } from '@app/layouts';
import { FieldsProps } from '../types';
import FieldElement from './FieldElement';

/**
 * Displays a group of fields in order
 */
const Fields = <TFormValues extends Record<string, any> = Record<string, any>>({
  form,
  fields,
  stackProps, // @todo handle
}: FieldsProps<TFormValues>) => {
  // fields should handle:
  // 1. flattening a list of all fields in order to index them
  // 2. creating a set of input refs
  // apply negative margin since the last element will always be padded

  return (
    <View onLayout={({ nativeEvent }) => form.setFormLocation(nativeEvent.layout)}>
      <Stack {...stackProps}>
        {fields.map((field, i) => (
          <FieldElement
            key={field.name || field.testID}
            grouped={field.grouped}
            form={form}
            element={field}
            isFirst={i === 0}
            isLast={i === fields.length - 1}
          />
        ))}
      </Stack>
    </View>
  );
};

export default Fields;
