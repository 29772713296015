import React, { useMemo, useCallback } from 'react';
import {
  queries,
  mutations,
  useQuery,
  useMutation,
  HealthApplicationQueryData,
  UpsertHealthApplicationVars,
  UpsertHealthApplicationResponse,
} from '@data';
import { SplitFormBlueprint } from '@blueprints';
import { routes } from '@navigate';
import { boolToTri, useCopy } from '@util';
import { Stack, Layout } from '@layouts';
import { Text } from '@uikit';

const APTCAgreements = ({ isGA, applicationID, handleNext }) => {
  const { c } = useCopy('catch.ede.APTCAgreement');
  const { c: signature } = useCopy('catch.ede.HealthEnrollment');

  const { loading, data } = useQuery<HealthApplicationQueryData>(queries.HEALTH_APPLICATION, {
    variables: { applicationID },
    skip: !applicationID,
  });

  const coverageYear = useMemo(
    () => data?.viewerTwo?.health?.application?.coverageYearNumber,
    [data?.viewerTwo?.health?.application],
  );

  const [upsert, { loading: submitting }] = useMutation<
    UpsertHealthApplicationResponse,
    UpsertHealthApplicationVars
  >(mutations.UPSERT_HEALTH_APPLICATION, {
    onCompleted: () => handleNext(),
  });

  const onSubmit = useCallback((values) => {
    upsert({
      variables: {
        input: {
          id: applicationID,
          aptcAgreement: boolToTri(values.aptcAgreement),
          aptcSignature: values.aptcSignature,
        },
      },
    });
  }, []);

  return (
    <SplitFormBlueprint
      title={c('title')}
      subtitle={c('subtitle')}
      loading={!data}
      submitting={submitting}
      precontent={
        <Layout bottomSpace>
          <Stack spacing="1">
            <Text size="fp" maxSize="p">
              {c('agreementL1')}
            </Text>
            <Text size="fp" maxSize="p">
              {c('agreementL2', {
                nextYear: coverageYear + 1,
                coverageYear,
              })}
            </Text>
            <Text size="fp" maxSize="p">
              {c('agreementL3', { coverageYear })}
            </Text>
            <Text size="fp" maxSize="p">
              {c('agreementL4', { coverageYear })}
            </Text>
            <Text size="fp" maxSize="p">
              {c('agreementL5', { coverageYear })}
            </Text>
            <Text size="fp" maxSize="p">
              {c('agreementL6', { coverageYear })}
            </Text>
          </Stack>
        </Layout>
      }
      formConfig={{
        initialValues: {
          legalName: data?.viewerTwo?.health?.application?.applicant?.legalName,
        },
        fields: [
          {
            name: 'aptcAgreement',
            type: 'checkbox',
            required: true,
            label: c('checkboxLabel'),
            testID: 'checkboxLabel',
          },
          {
            name: 'aptcSignature',
            type: 'signature',
            dependencies: ['legalName'],
            required: true,
            label: signature('258_Q'),
            testID: '258',
          },
        ],
        onSubmit,
      }}
    />
  );
};

export const APTCAgreementView = {
  name: routes.EDE_APTC_AGREEMENT,
  component: APTCAgreements,
  options: {
    ...SplitFormBlueprint.options,
    title: 'Financial agreements',
  },
};
