import React, { useState, useMemo } from 'react';
import { ComplexRow, LineItem, Button, Loading, ActionRow, Banner, Text } from '@uikit';
import { Asset } from '@uikit/components/Asset';
import { Toolbar } from '@layouts';
import { routes } from '@navigate';
import { useCopy, precisionRound, Env } from '@app/utils';
import { Layout, Stack, Page } from '@layouts';
import { date as fDate } from '@app/utils/format/date';
import { paycheckSource as fSource } from '@app/utils/format/income';
import { useForm, Fields, FormValues } from '@app/forms';
import ConfirmationBlueprint from '@app/blueprints/ConfirmationBlueprint';
import { useQuery, queries, IncomeTransactionQueryData } from '@data';

type PaycheckType = 'PAYCHECK_TYPE_1099' | 'PAYCHECK_TYPE_W2';

type ApprovalType = '1099' | 'W2';

const paycheckTypes: Record<ApprovalType, PaycheckType> = {
  1099: 'PAYCHECK_TYPE_1099',
  W2: 'PAYCHECK_TYPE_W2',
};

const shouldSkipGoal = (item, approvalType) => {
  return (
    item.goal?.status === 'PAUSED' ||
    item.isLimitedByMax ||
    (approvalType === 'W2' && item.goal?.goalType === 'TAX')
  );
};

const getBreakdownLabel = (item, approvalType) => {
  if (item.goal?.status === 'PAUSED') return 'Paused';
  if (item.isLimitedByMax) return 'Limit reached';
  if (approvalType === 'W2' && item.goal?.goalType === 'TAX') return 'Already withheld';
};

const calculatePaycheck = (paycheckAmount, formValues) => {
  const { expenses, ...breakdowns } = formValues;

  // dont let expenses go above paycheck amount
  const computedExpenses = expenses > paycheckAmount ? paycheckAmount : expenses;
  const totalPercentage: number = Object.values(breakdowns).reduce((t: number, value: number) => {
    return t + value;
  }, 0);

  const withholdFrom = precisionRound(paycheckAmount - computedExpenses, 2);

  const withholding = precisionRound(withholdFrom * totalPercentage, 2);

  return {
    percentage: totalPercentage,
    withholding,
    net: withholdFrom - withholding,
    expenses: computedExpenses || 0,
  };
};

/**
 * @todo:
 * - add goal inline
 * - nice visuals (dynamic color?)
 * - apply streak/counter logic to follow-up conditional
 */
const PaycheckDetails = ({
  paycheckID,
  addRule,
  requestReview,
  processIncome,
  processing,
  handleNext,
  approvalType,
  paychecks,
  done = false,
}) => {
  const { c: basics, $, p } = useCopy('catch.basics');
  const { c } = useCopy('catch.sequences.Paycheck.breakdown');
  const { c: c2 } = useCopy('catch.sequences.Paycheck.breakdown.header');
  const [expenses, setExpenses] = useState<boolean | number>(false);
  const [skipped, setSkipped] = useState(false);

  const { loading, data } = useQuery<IncomeTransactionQueryData>(queries.INCOME_TRANSACTION, {
    variables: { paycheckID },
    skip: !paycheckID,
    fetchPolicy: 'cache-first',
  });

  const handleAutoRule = async (source, approvalType) => {
    addRule(
      {
        incomeSourceID: source?.id,
        automationType: 'ALWAYS',
        workType: approvalType === 'W2' ? 'WORK_TYPE_W2' : 'WORK_TYPE_1099',
        userHandlesActivation: true,
      },
      () => requestReview(), //request an app store review if they toggle on autoroll
    );

    //update status for paychecks from same source
    await Promise.all(
      paychecks?.map((paycheck) => {
        // process for this source and don't try to process the one we just did
        if (paycheck?.source?.id === source?.id && paycheck?.id !== paycheckID) {
          return processIncome({
            variables: {
              input: {
                transactionID: paycheck.id,
                status: 'APPROVED',
                paycheckType: `PAYCHECK_TYPE_${approvalType}`,
              },
            },
          });
        }
      }),
    );

    handleNext();
  };

  const itx = data?.viewer.incomeTransaction;
  const source = itx?.source;
  const amount = itx?.amount;
  const scheduled = data?.viewer?.incomeTransaction.status === 'DELAYED';
  const date = itx?.date;
  const canTransfer = data?.viewer.savingsAccountMetadata?.isAccountReady;

  const goalBreakdowns = useMemo(() => {
    return (itx?.goalBreakdowns || []).filter((item) => {
      // when paycheck is already processed, only show the items that were withheld
      return done ? item.status !== 'SKIPPED' && item.amount > 0 : true;
    });
  }, [data]);

  const fields = useMemo(() => {
    return goalBreakdowns.map((item) => ({
      name: item.id,
      type: 'stepper',
      stepperType: 'percentage',
      inline: true,
      grouped: true,
      headerText: item.goal?.title,
      label: shouldSkipGoal(item, approvalType) ? getBreakdownLabel(item, approvalType) : undefined,
      max: item.isLimitedByMax ? 0 : undefined,
    }));
  }, [goalBreakdowns]);

  const expensesField = useMemo(
    () => ({
      name: 'expenses',
      type: 'amount',
      amountType: 'decimal',
      max: amount,
      label: 'Expenses',
    }),
    [amount],
  );

  const initialValues = useMemo(() => {
    return goalBreakdowns.reduce(
      (acc, breakdown) => {
        return {
          ...acc,
          [breakdown.id]: shouldSkipGoal(breakdown, approvalType) ? 0 : breakdown.percentage,
        };
      },
      { expenses: 0 },
    );
  }, [goalBreakdowns, approvalType]);

  const form = useForm({
    loading,
    disabled: false,
    initialValues,
    fields: [...fields, expensesField],
    onSubmit: ({ expenses, ...goalBreakdowns }) => {
      processIncome({
        variables: {
          input: {
            transactionID: paycheckID,
            expenses: expenses || 0,
            status: 'APPROVED',
            paycheckType: paycheckTypes[approvalType],
            goalBreakdownChanges: Object.keys(goalBreakdowns).map((itemID) => ({
              incomeTransactionGoalBreakdownID: itemID,
              percentageAmount: goalBreakdowns[itemID],
              isSkipped: false,
            })),
          },
        },
      });
    },
  });

  const skip = () => {
    setSkipped(true);
    processIncome({
      variables: {
        input: {
          transactionID: paycheckID,
          status: 'SKIPPED',
        },
      },
    });
  };

  if (loading) return <Loading />;

  return (
    <ConfirmationBlueprint
      loading={processing.loading}
      called={processing.called}
      error={!!processing.error}
      titles={{ done: skipped ? 'Paycheck skipped' : 'Paycheck approved' }}
      onSuccess={handleNext}
      // followUp={
      //   source?.canBeRule && {
      //     title: `Autoroll ${source?.text} next time`,
      //     subtitle: `Automatically set aside every time you're paid`,
      //     asset: { ...Asset.configureSource(source), size: 'md' },
      //     action: {
      //       label: 'Autoroll',
      //       type: 'CUSTOM',
      //       onAction: () => handleAutoRule(source, approvalType),
      //     },
      //   }
      // }
    >
      <Page>
        <Layout.Scroll>
          <Layout margins>
            <Layout.Header
              loading={loading}
              asset={{ ...Asset.configureSource(source), size: 'md' }}
              subtitle={itx?.status === 'CANCELED' ? 'Failed' : fDate(date, 'RELATIVE')}
              subtitleColor={itx?.status === 'CANCELED' ? 'credit' : undefined}
              align="center"
              title={c2('title', {
                amount: $(amount),
                source: fSource(source),
              })}
            />
          </Layout>

          <Layout margins bottomSpace>
            <Stack spacing="1">
              <>
                {!done && (
                  <>
                    {expenses !== false ? (
                      <Fields fields={[expensesField]} form={form} />
                    ) : (
                      <ActionRow
                        fp
                        label="Add expenses or deductions"
                        onAction={() => setExpenses(true)}
                      />
                    )}
                  </>
                )}

                {done ? (
                  <Stack separatorComponent>
                    {goalBreakdowns
                      ?.filter((b) => b.status !== 'SKIPPED' && b.amount > 0)
                      ?.map((breakdown) => (
                        <ComplexRow
                          key={breakdown.id}
                          label={breakdown.goal?.title}
                          accessory={<Text size="p">{$(breakdown.amount)}</Text>}
                        />
                      ))}
                  </Stack>
                ) : (
                  <Fields
                    fields={fields}
                    form={form}
                    stackProps={{ spacing: '0', separatorComponent: true }}
                  />
                )}
              </>
              <FormValues control={form.methods.control}>
                {(values) => {
                  const amounts = calculatePaycheck(amount, values);

                  return (
                    <Stack spacing="1b" topSpace>
                      <LineItem
                        loading={loading}
                        testID="total"
                        left={c('totalTitle')}
                        right={`(${p(amounts.percentage)}) ${$(amounts.withholding)}`}
                      />
                      <LineItem
                        loading={loading}
                        testID="total"
                        left={'Take home pay'}
                        right={$(amounts.net)}
                      />
                    </Stack>
                  );
                }}
              </FormValues>

              {!Env.isProd && !canTransfer && !loading && (
                <Layout bottomSpace>
                  <Banner
                    bg="skeleton"
                    title="Your account is almost ready"
                    subtitle="We're reviewing a few last details"
                  />
                </Layout>
              )}
            </Stack>
          </Layout>
        </Layout.Scroll>
        <FormValues control={form.methods.control}>
          {(values) => {
            const amounts = calculatePaycheck(amount, values);
            const willSkip = amounts.withholding === 0;
            const disabled = amounts.percentage > 1;

            return (
              <Toolbar type="stack">
                {(!!willSkip || !!scheduled) && canTransfer && !done && (
                  <Button
                    accentColor="incomeLight"
                    disabled={form.disableSubmit || !canTransfer}
                    alt
                    loading={processing.loading}
                    onPress={skip}
                  >
                    {basics('skip')}
                  </Button>
                )}
                {!willSkip && !done && (
                  <Button
                    accentColor="income"
                    disabled={form.disableSubmit || disabled || (!canTransfer && !Env.isProd)}
                    loading={processing.loading}
                    onPress={form.submitForm}
                  >
                    {c('ctaButton', {
                      amount: $(amounts.withholding),
                    })}
                  </Button>
                )}
              </Toolbar>
            );
          }}
        </FormValues>
      </Page>
    </ConfirmationBlueprint>
  );
};

export const PaycheckBreakdownView = {
  name: routes.PAYCHECK_BREAKDOWN,
  component: PaycheckDetails,
  options: {},
};
