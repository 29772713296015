import React from 'react';
import { FormattedNumber } from 'react-intl';

const Percentage = ({ children, whole, lessThan, ...other }) => {
  /**
   * Allows for showing <1% when not zero
   * @type {[type]}
   */
  const wholeNumber = children * 100;
  const showLessThan = lessThan && wholeNumber > 0 && wholeNumber < 1;

  return (
    <>
      {showLessThan && '<'}
      <FormattedNumber
        minimumFractionDigits={whole ? 0 : 2}
        maximumFractionDigits={whole ? 0 : 2}
        value={showLessThan ? 0.01 : children}
        /* eslint-disable-next-line react/style-prop-object */
        style="percent"
        {...other}
      />
    </>
  );
};

export default Percentage;
