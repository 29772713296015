import React from 'react';
import { Share } from 'react-native';

import { useCopy, device } from '@app/utils';
import { useDeprecatedQuery } from '@data';
import { exit, routes } from '@navigate';
import { Layout, FlowLayout, Section, Stack } from '@layouts';
import { Text, NumberedBullets, Bullet, Loading, Asset } from '@uikit';
import ShareIcon from '@svg/share.svg';
import SuccessDMITodos from './SuccessDMITodos';
import SuccessSVITodos from './SuccessSVITodos';

const Success = ({ applicationID, enrollId }) => {
  const { c } = useCopy('catch.ede.SuccessView');
  const { c: basics } = useCopy('catch.basics');
  const { c: fees } = useCopy('catch.fees');

  const { pathwayType, sviTodos, dmiTodos, monthlySavings, loading } = useDeprecatedQuery(
    'applicationIssues',
    {
      variables: { enrollId },
      skip: !enrollId,
    },
  );

  const aptcSuccessCopy = () =>
    c('aptcTitle', {
      amount: (
        <Text size="h3" color="algae">
          ${monthlySavings}
          {fees('MONTHLY')}
        </Text>
      ),
    });

  const { referralCode } = useDeprecatedQuery('user');
  const referralValue = `app.catch.co/?r=${referralCode}`;
  const referralUrl = `https://${referralValue}`;

  const handleShare = () => {
    if (device.canShare) {
      Share.share({ message: aptcSuccessCopy, referralUrl });
    }
  };

  const hasSvi = sviTodos?.length > 0;
  const hasDmi = dmiTodos?.length > 0;
  const isEffecutationReady = !hasSvi;
  const requiresAnyDocs = !!hasSvi || !!hasDmi;
  const requiresBothDocs = !!hasSvi && !!hasDmi;

  const HeaderTitle = () => {
    if (pathwayType === 'CALIFORNIA') {
      return `Congrats! You've submitted your application for CA insurance coverage through Catch.`;
    } else if (monthlySavings) {
      return aptcSuccessCopy();
    } else {
      c('title');
    }
  };

  return (
    <FlowLayout
      narrow
      bare
      hideProgress
      nextButtonText={basics('soundsGood')}
      onNext={() => exit(routes.COVERAGE)}
      canGoBack={false}
    >
      {loading ? (
        <Loading accentColor="coverage" />
      ) : (
        <>
          <Layout.Header asset={{ render: 'coverage' }} titleSize="form" title={HeaderTitle()} />

          {device.canShare && monthlySavings && (
            <Asset
              onPress={handleShare}
              svg={ShareIcon}
              shape="circle"
              bg="text"
              color="page"
              containerSize="md"
              size="xs"
            />
          )}

          {!!hasSvi && (
            <Section>
              <Text color="secondary" size="fp">
                {c('subtitle.svi')}
              </Text>
            </Section>
          )}
          <Stack spacing="2" separatorComponent>
            <Section title={c('now.title')}>
              {!!isEffecutationReady && (
                <NumberedBullets number={dmiTodos?.length > 0 && 1} text={c('bullet1')}>
                  <Text>{c('now.pay')}</Text>
                </NumberedBullets>
              )}

              {/* Upload documents */}
              {requiresAnyDocs && (
                <>
                  <NumberedBullets number={isEffecutationReady ? 2 : 1}>
                    <Stack spacing="2">
                      <Text>{c('now.docs.title')}</Text>
                      {!!hasSvi ? (
                        <SuccessSVITodos sviTodos={sviTodos} />
                      ) : (
                        <SuccessDMITodos dmiTodos={dmiTodos} />
                      )}
                      <Stack spacing="0b">
                        <Text color="subtle" size="fp">
                          {c('now.docs.how')}
                        </Text>
                        {!!hasDmi && !hasSvi && (
                          <Text color="subtle" size="fp">
                            {c('now.docs.dmi.warning')}
                          </Text>
                        )}
                      </Stack>
                    </Stack>
                  </NumberedBullets>

                  <NumberedBullets number={hasSvi > 0 ? 2 : 3}>
                    <Text size="fp">{c('now.notice')}</Text>
                  </NumberedBullets>

                  {hasSvi && (
                    <NumberedBullets number={3}>
                      <Text size="fp">{c('now.pay.after')}</Text>
                    </NumberedBullets>
                  )}
                </>
              )}
            </Section>

            {!!requiresBothDocs && (
              <Section title={c('dmi.title')} subtitle={c('now.docs.title')}>
                <Stack spacing="2">
                  <SuccessDMITodos dmiTodos={dmiTodos} />
                  <Stack spacing="0b">
                    <Text color="subtle" size="fp">
                      {c('now.docs.how')}
                    </Text>
                    <Text color="subtle" size="fp">
                      {c('dmi.warning')}
                    </Text>
                  </Stack>
                </Stack>
              </Section>
            )}

            <Section title={c('comms.title')}>
              <Stack spacing="1">
                <Bullet text={c('comms.read')} />
                <Bullet text={c('comms.notices')} />
                {!!requiresAnyDocs && <Bullet text={c('comms.docs')} />}
                <Bullet text={c('comms.taxForms')} />
              </Stack>
            </Section>

            <Section title={c('update.title')}>
              <Stack spacing="1">
                <Text size="fp">{c('update.change')}</Text>
                <Text size="fp" color="subtle">
                  {c('update.how')}
                </Text>
              </Stack>
            </Section>
          </Stack>
        </>
      )}
    </FlowLayout>
  );
};

export const SuccessView = {
  name: 'EDE_SUCCESS',
  component: Success,
  options: {},
};
