import React, { useState, useMemo, useEffect } from 'react';
import { Copy } from '@types';
import { ApolloScoredHealthPlan } from '@data';
import { default as Toolbar } from '@layouts/Toolbar';
import { default as SplitLayout } from '@layouts/SplitLayout';
import { Button } from '@uikit/components/Button';
import { pop } from '@navigate';
import HealthPlans from '@common/sections/HealthPlans';
import HealthPlanBrochure, { HealthPlanProvider } from '@common/sections/HealthPlanBrochure';

interface HealthPlansBlueprintProps {
  loading: boolean;
  selecting: boolean;
  title: Copy;
  subtitle: Copy;
  precontent?: React.ReactNode;
  initialPlanID: string;
  previouslySelectedID?: string;
  enrollmentType?: 'HEALTH_INSURANCE' | 'DENTAL_INSURANCE';
  planSearch: {
    plans: Array<ApolloScoredHealthPlan>;
    topPlans: {
      catchRecommended: ApolloScoredHealthPlan;
      budgetPlan: ApolloScoredHealthPlan;
      comprehensivePlan: ApolloScoredHealthPlan;
    };
  };
  onSubmit: (plan: ApolloScoredHealthPlan, onCompleted: () => void) => void;

  // one of the following needs to be defined to accurately get full plan benefits
  enrollmentGroupID?: string;
  explorerID?: string;
}

const HealthPlansBlueprint: React.FC<HealthPlansBlueprintProps> = ({
  loading,
  selecting,
  title,
  subtitle,
  precontent,
  initialPlanID,
  previouslySelectedID,
  enrollmentType,
  planSearch,
  onSubmit,
  enrollmentGroupID,
  explorerID,
}) => {
  const [selectedID, setSelectedID] = useState<string>(initialPlanID);
  const [isOpen, setIsOpen] = useState(false);

  const plans = useMemo(() => {
    const allPlans = planSearch?.plans ? [...planSearch?.plans] : [];

    const minimums = allPlans?.reduce(
      (mins, plan) => {
        const premium = plan.healthPlan.premiumWithCredit;
        const deductible = plan.healthPlan.familyDeductible || plan.healthPlan.individualDeductible;
        const moop = plan.healthPlan.familyMax || plan.healthPlan.individualMax;

        if (mins.lowestPremium < 0 || premium < mins.lowestPremium) {
          mins.lowestPremium = premium;
        }

        if (mins.lowestDeductible < 0 || deductible < mins.lowestDeductible) {
          mins.lowestDeductible = deductible;
        }

        if (mins.lowestOutOfPocketMax < 0 || moop < mins.lowestDeductible) {
          mins.lowestOutOfPocketMax = moop;
        }

        return mins;
      },
      {
        lowestPremium: -1,
        lowestDeductible: -1,
        lowestOutOfPocketMax: -1,
      },
    );

    return allPlans.map((plan) => {
      const premium = plan.healthPlan.premiumWithCredit;
      const deductible = plan.healthPlan.familyDeductible || plan.healthPlan.individualDeductible;
      const moop = plan.healthPlan.familyMax || plan.healthPlan.individualMax;

      return {
        ...plan,
        isLowestPremium: premium === minimums.lowestPremium,
        isLowestDeductible: deductible === minimums.lowestDeductible,
        isLowestOutOfPocketMax: moop === minimums.lowestOutOfPocketMax,
      };
    });
  }, [planSearch]);

  // creates an object to lookup by plan ID
  const planLookup = useMemo(() => {
    return (plans || []).reduce((acc, plan: ApolloScoredHealthPlan) => {
      return { ...acc, [plan.healthPlan.planID]: plan };
    }, {});
  }, [plans]);

  useEffect(() => {
    if (!loading) {
      // unselect if undefined OR plan is not in current options
      if (!initialPlanID || !(initialPlanID in planLookup)) {
        setSelectedID('');
      }

      // if defined, set the selected plan
      if (planLookup?.[initialPlanID]) {
        setSelectedID(initialPlanID);
      }
    }
  }, [loading, initialPlanID, planLookup]);

  const handleSelect = (id: string) => {
    setSelectedID(id);
    setIsOpen(true);
  };

  const cta = useMemo(() => {
    return previouslySelectedID === selectedID ? 'Continue' : 'Choose plan';
  }, [previouslySelectedID, selectedID]);

  return (
    <HealthPlanProvider enrollmentGroupID={enrollmentGroupID} explorerID={explorerID}>
      <SplitLayout
        loading={loading}
        title={title}
        subtitle={subtitle}
        handleContent="SHEET"
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        toolbar={
          <Toolbar onBack={() => pop()}>
            <Button
              disabled={loading || selecting || !selectedID}
              loading={selecting}
              inherit
              testID="enroll"
              onPress={() => onSubmit(planLookup[selectedID], () => setIsOpen(false))}
            >
              {cta}
            </Button>
          </Toolbar>
        }
        sideToolbar={
          <Toolbar type="stack">
            <Button
              disabled={loading || selecting || !selectedID}
              loading={selecting}
              inherit
              testID="enroll"
              onPress={() => onSubmit(planLookup[selectedID], () => setIsOpen(false))}
            >
              {cta}
            </Button>
          </Toolbar>
        }
      >
        <>
          {precontent}
          <HealthPlans
            disabled={selecting}
            selectedID={selectedID}
            previouslySelectedID={previouslySelectedID}
            selectPlan={handleSelect}
            planSearch={planSearch}
            plans={plans}
            enrollmentType={enrollmentType}
            planLookup={planLookup}
          />
        </>
        <HealthPlanBrochure plan={planLookup[selectedID]} enrollmentType={enrollmentType} />
      </SplitLayout>
    </HealthPlanProvider>
  );
};

HealthPlansBlueprint.options = SplitLayout.options;
export default HealthPlansBlueprint;
