import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useQuery } from '@apollo/client';

import { queries, useMutation, mutations } from '@app/data';
import {
  HealthSlasherQueryData,
  SlasherApplicationSummary,
  MemberPreview,
} from '@app/data/queries/HealthSlasherQuery';
import {
  routes,
  useCurrentRoute,
  stacks,
  Stack,
  StackComponent,
  StackDefinition,
  exit,
  navigate,
  stackScreens,
  Route,
} from '@navigate';

import { HealthSlasherIntroView } from './slash/HealthSlasherIntroView';
import { HealthSlasherTriageView } from './slash/HealthSlasherTriageView';
import { HealthSlasherIncomeEntryView } from './slash/HealthSlasherIncomeEntryView';
import { HealthSlasherIncomeListView } from './slash/HealthSlasherIncomeListView';
import { HealthSlasherIncomeDetailsView } from './slash/HealthSlasherIncomeDetailsView';
import { HealthSlasherIncomeConfirmView } from './slash/HealthSlasherIncomeConfirmView';
import { HealthSlasherAgreementsView, getOutcome } from './slash/HealthSlasherAgreementsView';
import { HealthSlasherHiccupView } from './slash/HealthSlasherHiccupView';
import { HealthSlasherResultsView } from './slash/HealthSlasherResultsView';
import { HealthSlasherSuccessView } from './slash/HealthSlasherSuccessView';

type SlasherOutcome = 'POSITIVE' | 'NEGATIVE' | 'NOCHANGE';

interface SlasherNavigationOptions {
  incomeChangeMembers?: Array<string>; // IDs of members who report income changes
  slasherAppID?: string;
  outcome?: SlasherOutcome;
}

export interface HealthSlasherStackScreenProps {
  handleNext: (options?: SlasherNavigationOptions) => void;
  handleComplexCase: () => void;
  handleExit: () => void;
  loading: boolean;
  previousApplication?: SlasherApplicationSummary;
  newApplication?: SlasherApplicationSummary;
  submittedApp?: SlasherApplicationSummary;
  explorerIncome?: number;
  allMembers: Array<MemberPreview>;
  filteredMembers: Array<MemberPreview>;
  applicationID?: string;
  coverageYear?: number;

  outcome: SlasherOutcome;

  /** @deprecated */
  data?: HealthSlasherQueryData;
}

const config: StackDefinition = {
  stackName: stacks.HEALTH_SLASHER_STACK,
  options: {
    title: 'Premium Slasher',
    layout: 'sheet',
    navMode: 'flow',
    accentColor: 'coverage',
    presentation: 'basicSheet',
  },
  screens: [
    // in order of appearance:
    HealthSlasherIntroView,
    HealthSlasherTriageView,
    HealthSlasherIncomeEntryView,
    HealthSlasherIncomeListView,
    HealthSlasherIncomeDetailsView,
    HealthSlasherIncomeConfirmView,
    HealthSlasherAgreementsView,
    HealthSlasherHiccupView,
    HealthSlasherResultsView,
    HealthSlasherSuccessView,
  ],
};

/**
 *
 *
 * P R E M I U M /// S L A S H E R
 * Health Premium Slasher Stack
 *
 * @see https://www.figma.com/file/jEvznwmJQRvZsWycyzWhyS/Premium-Slasher?node-id=119%3A28865
 * @see https://www.notion.so/catchco/Prem-Slasher-c846011c23f546d7bd1bb1f634d7eba7
 *
 *
 * @todo handle direct if unlinked
 *
 */
const HealthSlashStack: StackComponent = () => {
  const route = useCurrentRoute();

  const [slasherAppID, setSlasherAppID] = useState<string>();
  const [incomeChangeMembers, setIncomeChangeMembers] = useState([]);

  // Input Data
  const { data, loading } = useQuery<HealthSlasherQueryData>(queries.HEALTH_SLASHER, {
    variables: { coverageYears: [2023] },
  });

  const [update] = useMutation(mutations.UPSERT_HEALTH_APPLICATION);

  // if there is no specified slasher ID, check for possible existing ones
  useEffect(() => {
    if (!slasherAppID) {
      const submitted = data?.viewerTwo?.health?.submitted || [];
      const prefills = (data?.viewerTwo?.health?.prefills || []).filter(
        (a) => a?.applicationContext === 'SLASHER',
      );

      // prefer submitted apps first, then check for prefills
      if (submitted.length > 0) {
        setSlasherAppID(submitted[0]?.id);
      } else if (prefills.length > 0) {
        setSlasherAppID(prefills[0]?.id);
      }
    }
  }, [slasherAppID, data]);

  // returns the full slasher app from data, based on the ID
  const slasherApp = useMemo(() => {
    if (!slasherAppID) return undefined;

    // find the slasher app in data
    const submitted = data?.viewerTwo?.health?.submitted || [];
    const prefills = (data?.viewerTwo?.health?.prefills || []).filter(
      (a) => a?.applicationContext === 'SLASHER',
    );

    return [...submitted, ...prefills].find((app) => app.id === slasherAppID);
  }, [slasherAppID, data]);

  const allMembers = useMemo(() => {
    const { applicant, members } = slasherApp || {};
    return applicant ? [applicant, ...(members || [])] : [];
  }, [slasherApp]);

  const filteredMembers = useMemo(() => {
    return incomeChangeMembers.length === 0
      ? allMembers
      : allMembers.filter((m) => incomeChangeMembers.includes(m?.id));
  }, [allMembers, incomeChangeMembers]);

  const outcome = useMemo(() => {
    return getOutcome(slasherApp);
  }, [slasherApp]);

  // Handlers
  const handleExit = () => exit(routes.HOME);
  const handleComplexCase = async (input = {}) => {
    if (input?.isRequestingFinancialAssistance === 'YES') {
      await update({ variables: { input } });
    }

    exit(routes.EDE_INTRO);
  };

  const goTo = (route, applicationID) => {
    // stores the next route as last used route
    update({ variables: { input: { id: applicationID, lastUsedRoute: route } } });
    navigate(route);
  };

  // Navigation
  const handleNext = useCallback(
    (options) => {
      switch (route) {
        case routes.HEALTH_SLASHER_INTRO:
          if (!slasherApp) {
            navigate(routes.HEALTH_SLASHER_TRIAGE);
          } else {
            if (stackScreens.HEALTH_SLASHER_STACK.includes(slasherApp?.lastUsedRoute as Route)) {
              navigate(slasherApp?.lastUsedRoute as Route);
            } else {
              navigate(routes.HEALTH_SLASHER_INCOME_LIST);
            }
          }

          break;
        case routes.HEALTH_SLASHER_TRIAGE:
          navigate(routes.HEALTH_SLASHER_INCOME_ENTRY);
          break;
        case routes.HEALTH_SLASHER_INCOME_ENTRY:
          setSlasherAppID(options?.slasherAppID);
          goTo(routes.HEALTH_SLASHER_INCOME_LIST, slasherAppID);
          break;
        case routes.HEALTH_SLASHER_INCOME_LIST:
          setIncomeChangeMembers(options?.incomeChangeMembers);

          // todo handle for no income changes?
          // if (options?.incomeChangeMembers?.length === 0)

          goTo(routes.HEALTH_SLASHER_INCOME_DETAILS, slasherAppID);
          break;
        case routes.HEALTH_SLASHER_INCOME_DETAILS:
          goTo(routes.HEALTH_SLASHER_INCOME_CONFIRM, slasherAppID);
          break;
        case routes.HEALTH_SLASHER_INCOME_CONFIRM:
          goTo(routes.HEALTH_SLASHER_AGREEMENTS, slasherAppID);
          break;
        case routes.HEALTH_SLASHER_AGREEMENTS:
          setSlasherAppID(options?.slasherAppID);

          if (options?.outcome === 'POSITIVE') {
            goTo(routes.HEALTH_SLASHER_RESULTS, options?.slasherAppID);
          } else {
            goTo(routes.HEALTH_SLASHER_HICCUP, options?.slasherAppID);
          }

          break;
        case routes.HEALTH_SLASHER_HICCUP:
          goTo(routes.HEALTH_SLASHER_RESULTS, slasherAppID);
          break;
        case routes.HEALTH_SLASHER_RESULTS:
          goTo(routes.HEALTH_SLASHER_SUCCESS, slasherAppID);
          break;
        case routes.HEALTH_SLASHER_SUCCESS:
          exit(routes.COVERAGE);
          break;
        default:
          break;
      }
    },
    [route, slasherApp],
  );

  const screenProps: HealthSlasherStackScreenProps = {
    handleNext,
    handleComplexCase,
    handleExit,
    data,
    loading,
    previousApplication: data?.viewerTwo?.health?.applications?.[0],
    explorerIncome: data?.viewerTwo?.healthExplorerData?.income,
    allMembers,
    filteredMembers,
    applicationID: slasherAppID,
    newApplication: slasherApp,
    submittedApp: slasherApp,
    coverageYear: slasherApp?.coverageYearNumber,
    outcome: outcome || 'NOCHANGE',
  };

  return (
    <Stack
      stackName={config.stackName}
      screens={config.screens}
      options={config.options}
      data={screenProps}
    />
  );
};

HealthSlashStack.config = config;
export default HealthSlashStack;
