import React, { useEffect, useMemo, useState } from 'react';
import { FieldsConfig, IdentityStatus } from '@types';
import { BasicLayout, Layout, Stack, Toolbar } from '@layouts';
import { Button, OptionGroup, Text, Link, ComplexRow } from '@uikit';
import { createLogger, Env, safeFormatDate, Segment } from '@app/utils';
import { HiccupBlueprint } from '@app/blueprints';
import { useHealthIdentity } from '@app/hooks/useHealthIdentity';
import { exit, replace, routes } from '@app/navigate';
import { Fields, useForm } from '@app/forms';

import Phone from '@svg/phone.svg';
import Close from '@svg/close.svg';
import Clock from '@svg/clock.svg';
import Checkmark from '@svg/checkmark.svg';
import ShieldCross from '@svg/shield-cross.svg';
import Upload from '@svg/upload.svg';
import OfflineVerification from './OfflineVerification';
import { useHealthHandoff } from '@app/hooks/useHealthHandoff';

const Log = createLogger('identity-proofing');

interface ProofingProps {
  canSearch?: boolean;
  shouldHandoff?: boolean;
  applicantID?: string; // for cases where a specific health applicant should be used
  handleSuccess: () => void;
  coverageYear?: number;
}

type ProofingScenario =
  | 'LOADING'
  | 'ERROR'
  | 'QUESTIONS'
  | 'OFFLINE'
  | 'CALLED'
  | 'TIMEOUT'
  | 'UPLOAD'
  | 'UPLOADED'
  | 'DENIED'
  | 'VERIFIED';

interface CopyConfig {
  icon?: any; // svg component
  title: string;
  subtitle?: string;
}

const scenarios: Record<IdentityStatus, ProofingScenario> = {
  INITIAL: 'LOADING',
  PENDING_PROVIDER: 'LOADING',

  UNKNOWN: 'ERROR',
  STUCK: 'ERROR',
  SERVER_ISSUE: 'ERROR',
  NEEDS_DOCS: 'ERROR', // shouldn't be handled in this flow

  NEEDS_QUIZ: 'QUESTIONS',
  PENDING_OFFLINE_VERIFICATION: 'OFFLINE',
  LOCKED_OUT: 'UPLOAD',
  DENIED: 'UPLOAD',
  PENDING_MANUAL_REVIEW: 'UPLOADED',
  VERIFIED: 'VERIFIED',
};

const uploadFields = [
  {
    name: 'documentType',
    type: 'dropdown',
    label: 'Select document type',
    required: true,
    options: [
      { label: "Driver's license", value: 'PHOTO_ID_FRONT' },
      { label: 'Passport', value: 'PASSPORT' },
      { label: 'Social Security Card', value: 'SOCIAL_SECURITY_CARD' },
    ],
  },
  {
    name: 'upload',
    type: 'file',
    label: 'Upload your document',
    dependencies: ['documentType'],
    required: true,
    documentType: ({ documentType }) => documentType,
  },
];

/**
 * Handles the set of options for proofing
 *
 */
const Proofing: React.FC<ProofingProps> = ({
  applicantID,
  canSearch,
  shouldHandoff,
  handleSuccess,
  coverageYear,
}) => {
  const [hasCalled, setHasCalled] = useState(false);
  const [shouldUpload, setShouldUpload] = useState(false);
  const [hasUploads, setHasUploads] = useState(false);

  const {
    loading,
    status,
    questions = [],
    referenceNumber,
    retryAfter,
    shouldRetry,
    confirmIdentity,
    confirmVerifiedOffline,
    requestManualReview,
    answerQuestions,
    answering,
    hasIdentityUploads,
    identityUploads,
  } = useHealthIdentity(applicantID);

  /**
   * calls confirm identity for initial subsets
   */
  useEffect(() => {
    const needsIdentity =
      status === 'INITIAL' || status === undefined || status === 'PENDING_PROVIDER' || shouldRetry;

    // if status has not been set or is initial
    if (!loading && needsIdentity && !!canSearch) {
      confirmIdentity();
    }
  }, [status, canSearch, applicantID, shouldRetry, loading]);

  /**
   * Creates fields from the question and answer key
   */
  const fields: FieldsConfig = useMemo(() => {
    return questions?.map((question) => ({
      type: 'option',
      name: question?.key,
      label: question?.text,
      required: true,
      options: (question?.choices || question?.answers)?.map((answer) => ({
        label: answer.text,
        value: answer.key,
      })),
    }));
  }, [questions]);

  const form = useForm<{ '01': string; '02': string; '03': string }>({
    loading,
    disabled: answering,
    initialValues: {},
    fields,
    onSubmit: (values) => {
      answerQuestions(
        Object.entries(values)?.map((question) => ({
          questionKey: question[0],
          answerKey: question[1],
        })),
      );
    },
  });

  const uploadForm = useForm({
    loading,
    disabled: answering,
    initialValues: {},
    fields: uploadFields,
    onSubmit: () => {
      Segment.track('EDE Identity Docs Uploaded');
      requestManualReview();
      setHasUploads(true);
    },
  });

  const { handoff } = useHealthHandoff({
    handoffType: Env.isDevLike ? 'HEALTHCARE_GOV' : 'HEALTHSHERPA',
    options: {
      coverageYear,
      onHandoff: () => {
        replace(routes.COVERAGE);
      },
    },
  });

  const config: Record<ProofingScenario, CopyConfig> = {
    LOADING: { title: 'Verify identity' },
    ERROR: {
      icon: Close,
      title: 'Identity verification',
      subtitle: 'Something went wrong',
    },
    QUESTIONS: {
      title: 'Verify identity',
    },
    OFFLINE: {
      icon: Phone,
      title: "Couldn't verify your identity",
      subtitle:
        'Please call the Experian help desk at (866) 578-5409 and give them the code below to verify your identity.',
    },
    CALLED: {
      icon: Phone,
      title: 'What did they say?',
      subtitle: (
        <Text color="subtle">
          If you haven&apos;t spoken with Experian yet, see how to call the Experian help desk{' '}
          <Link onPress={() => setHasCalled(false)}>here</Link>.
        </Text>
      ),
    },
    UPLOAD: {
      icon: Upload,
      title: 'Identity documentation',
      subtitle:
        "Please provide a driver's license, passport, or Social Security card to verify your identity.",
    },
    UPLOADED: {
      icon: Checkmark,
      title: 'Your documents are in review',
      subtitle:
        "Catch Support is verifying your identity and will reach out with next steps. Once you're approved, you'll need to return to continue.",
    },
    TIMEOUT: {
      icon: Clock,
      title: "Couldn't verify your identity",
      subtitle: retryAfter
        ? `You will need to try again later. Please come back at ${safeFormatDate(
            new Date(retryAfter),
            'MMMM dd, yyyy H:mm a',
          )}.`
        : 'Please try again later.',
    },
    DENIED: {
      icon: ShieldCross,
      title: shouldHandoff
        ? 'Unfortunately, it doesn’t look like we’ll be able to verify your identity. You’ll need to continue your application using our preferred partner.'
        : "We're unable to verify your identity",
    },
    VERIFIED: {
      icon: Checkmark,
      title: 'Identity verified',
      subtitle: '',
    },
  };

  // maps the status to the scenario we want to handle for
  const scenario: ProofingScenario = useMemo(() => {
    if (!status) return 'LOADING';
    if (hasUploads) return 'UPLOADED';
    if (shouldUpload) return 'UPLOAD';
    if (hasCalled) return 'CALLED';
    Log.debug(`Handling status:${status}`);
    return scenarios[status];
  }, [status, hasCalled, shouldUpload, hasUploads]);

  const copy = useMemo(() => {
    return config[scenario];
  }, [scenario]);

  const content =
    scenario === 'CALLED' ? (
      <Layout>
        <OptionGroup
          onPress={(val) => {
            if (val === 'verified') {
              confirmVerifiedOffline();
            } else {
              if (hasIdentityUploads) {
                Segment.track('EDE Identity Docs Found');
                requestManualReview();
                setHasUploads(true);
              } else {
                setShouldUpload(true);
              }
            }
          }}
          options={[
            {
              key: 'verified',
              label: 'Verified',
              description: 'Experian successfully verified me',
              value: 'verified',
            },
            {
              key: 'not-verified',
              label: 'Unable to verify',
              description: 'Experian said to contact Catch support',
              value: 'not-verified',
            },
            {
              key: 'havent-called',
              label: 'Unable to call',
              description: "Haven't spoken with Experian",
              value: 'needs-to-call',
            },
          ]}
        />
      </Layout>
    ) : scenario === 'OFFLINE' ? (
      <OfflineVerification loading={loading} referenceNumber={referenceNumber || ''} />
    ) : scenario === 'QUESTIONS' ? (
      <Fields form={form} fields={fields} stackProps={{ spacing: 3 }} />
    ) : scenario === 'UPLOAD' ? (
      <Fields form={uploadForm} fields={uploadFields} />
    ) : scenario === 'UPLOADED' ? (
      <>
        <Stack separatorComponent>
          {identityUploads?.map((upload) => (
            <ComplexRow
              key={upload.id}
              label={upload?.name}
              sublabel={`Uploaded ${safeFormatDate(upload.createdOn)}`}
            />
          ))}
        </Stack>
        <Layout center>
          <Link
            onPress={() => {
              setShouldUpload(true);
              setHasUploads(false);
            }}
          >
            Upload another document
          </Link>
        </Layout>
      </>
    ) : null;

  const actions = {
    QUESTIONS: [
      {
        testID: 'answer',
        label: 'Next',
        disabled: form.disableSubmit,
        onAction: form.submitForm,
      },
    ],
    OFFLINE: [
      {
        testID: 'call',
        label: 'Call (866) 578-5409',
        color: 'coverageLight',
      },
      {
        testID: 'verify',
        label: 'Next',
        onAction: () => setHasCalled(true),
      },
    ],

    DENIED: [
      {
        testID: shouldHandoff ? 'apply-now' : 'exit',
        label: shouldHandoff ? 'Apply now' : 'Exit',
        onAction: shouldHandoff ? handoff : exit,
      },
    ],
    TIMEOUT: [
      {
        testID: 'exit',
        label: 'Exit',
        onAction: exit,
      },
    ],
    ERROR: [
      {
        testID: 'exit',
        label: 'Exit',
        onAction: exit,
      },
    ],
    UPLOAD: [
      {
        testID: 'answer',
        label: 'Upload',
        disabled: uploadForm.disableSubmit,
        onAction: uploadForm.submitForm,
      },
    ],

    VERIFIED: [
      {
        testID: 'success',
        label: 'Continue',
        onAction: handleSuccess,
      },
    ],
  };

  if (copy?.icon) {
    return (
      <HiccupBlueprint
        loading={loading}
        asset={{ svg: copy?.icon, gradient: 'coverage' }}
        title={copy?.title}
        subtitles={[copy?.subtitle]}
        actions={actions[scenario] || []}
      >
        {content}
      </HiccupBlueprint>
    );
  } else {
    return (
      <BasicLayout
        loading={loading || scenario === 'LOADING'}
        title={copy?.title}
        subtitles={[copy?.subtitle]}
        toolbar={
          <Toolbar>
            {(actions[scenario] || []).map((action) => (
              <Button
                inherit
                key={action.testID}
                testID={action.testID}
                disabled={action.disabled}
                onPress={action.onAction}
              >
                {action.label}
              </Button>
            ))}
          </Toolbar>
        }
      >
        {content}
      </BasicLayout>
    );
  }
};

export default Proofing;
