import { copy } from '@app/utils';
import { optionPresets } from '@f';
import { FieldConfig } from '@types';

const PREFIX = 'catch.ede.HealthEnrollment';

const labelDataDeps = ['id', 'fullName', 'coverageYear', 'relation'];

export const fields: Array<FieldConfig> = [
  {
    copy: PREFIX,
    name: 'isVariableIncome',
    type: 'option',
    required: true,
    dependencies: ['fullName', 'calculatedAmount'],
    label: ({ calculatedAmount, fullName }) => ({
      id: '181_Q',
      data: { amount: copy.$(calculatedAmount || 0, { whole: true }), fullName },
    }),
    help: { id: '181_T' },
    options: optionPresets.YES_NO_TRINARY,
  },
  {
    copy: PREFIX,
    type: 'option',
    name: 'isUnknownIncome',
    required: true,
    dependencies: ['isVariableIncome', ...labelDataDeps],
    display: ({ isVariableIncome }) => isVariableIncome === 'NO',
    label: (data) => ({ id: '182_Q', data }),
    options: optionPresets.YES_NO_TRINARY,
  },
  {
    copy: PREFIX,
    name: 'incomeAmount',
    type: 'amount',
    amountType: 'integer',
    required: true,
    min: -10000000,
    max: 10000000,
    dependencies: ['isVariableIncome', 'isUnknownIncome', ...labelDataDeps],
    display: ({ isVariableIncome }) => isVariableIncome === 'NO',
    label: (data) => {
      return {
        id: data?.isUnknownIncome === 'YES' ? '184_Q' : '183_Q',
        data: data,
      };
    },
    help: (data) => ({ id: '183_T', data }),
  },
  {
    copy: PREFIX,
    name: 'hasReceivedUnemploymentCompensation',
    display: ({ renderUnemploymentQuestion }) => !!renderUnemploymentQuestion,
    dependencies: ['renderUnemploymentQuestion', ...labelDataDeps],
    label: (data) => ({ id: '300_Q', data }),
    options: optionPresets.YES_NO_BOOLEAN,
    type: 'option',
    required: true,
  },
];
