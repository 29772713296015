import React, { useEffect, useMemo, useState, useContext } from 'react';
import { Platform } from 'react-native';
import { useNavigation } from '@navigate/utils/useNavigation';
import { Route } from '@navigate/routes';
import { UIAccentColor } from '@uikit/types';

export type Layout = 'page' | 'sheet';

interface LayoutContextProps {
  layout: Layout;
  needsScroll: boolean;
  name?: Route;
  accentColor?: UIAccentColor;
}

const LayoutContext = React.createContext({
  layout: 'page',
  accentColor: 'brand',
  scrolled: false,
  needsScroll: false,
  handleScroll: undefined,
  toolbarHeight: 0,
  setToolbarHeight: (height: number) => {},
});

const downThreshold = Platform.select({
  ios: 150,
  android: 150,
  default: 50,
});

const upThreshold = Platform.select({
  ios: 150,
  android: 150,
  default: 5,
});

/**
 * Provides a layout provider to wrap around each screen section
 * This allows us to compute values based on the layout value
 * and know whether we're in a modal or a full page
 */
const LayoutProvider: React.FC<LayoutContextProps> = ({
  name,
  layout,
  needsScroll,
  accentColor = 'brand',
  children,
}) => {
  const [scrolled, setScrolled] = useState<boolean>(false);
  const [toolbarHeight, setToolbarHeight] = useState<number>(0);
  const navigation = useNavigation();

  useEffect(() => {
    // we reset the scroll when a layout provider gets reinitialized
    // web always goes to top of page, so we want scrolled set to false
    if (navigation.setOptions) {
      navigation.setOptions(
        Platform.select({
          android: {},
          ios: {},
          default: { [name]: { scrolled: false } },
        }),
      );
    }
  }, []);

  const handleScroll = useMemo(() => {
    if (!needsScroll) return undefined;

    return ({ nativeEvent }) => {
      if (nativeEvent.contentOffset.y > downThreshold && !scrolled) {
        setScrolled(true);

        navigation.setOptions(
          Platform.select({
            android: { headerTransparent: false },
            ios: { headerBlurEffect: 'prominent' },
            default: { [name]: { scrolled: true } },
          }),
        );
      }
      if (nativeEvent.contentOffset.y < upThreshold && !!scrolled) {
        setScrolled(false);

        navigation.setOptions(
          Platform.select({
            android: { headerTransparent: true },
            ios: { headerBlurEffect: false },
            default: { [name]: { scrolled: false } },
          }),
        );
      }
    };
  }, [scrolled]);

  return (
    <LayoutContext.Provider
      value={{
        layout,
        scrolled,
        handleScroll,
        needsScroll,
        toolbarHeight,
        setToolbarHeight,
        accentColor,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};

export const useLayoutContext = () => {
  return useContext(LayoutContext);
};

export default LayoutProvider;
