import { Clipboard, Share, ShareContent, ShareOptions } from 'react-native';
import { device } from '@app/utils';

export interface ShareParams {
  message: string;
  url: string;
  options?: {
    copyLinkOnly?: boolean;
  };
}

const _share = (content: ShareContent, options?: ShareOptions) => {
  Share.share(content, options);
};

const _copy = (content: string) => {
  Clipboard.setString(content);
};

/**
 *
 * Share
 * @see https://reactnative.dev/docs/share
 *
 */
export const share = ({ message, url, options }: ShareParams) => {
  if (device.canShare) {
    const _msg =
      device.platform === 'android'
        ? {
            message: `${message} ${url}`,
          }
        : { message, url };
    _share(_msg);
  } else {
    _copy(options?.copyLinkOnly ? url : `${message}: ${url}`);
  }
};
