import React, { useState, useCallback } from 'react';
import { View } from 'react-native';
import { Text, Pressable, useResponsive } from '@uikit';
import { useTheme } from '@uikit/hooks/useTheme';
import { useID } from '@uikit/hooks/useID';

/**
 * Radio buttons are used when a list of two or more options are mutually exclusive,
 * meaning the user must select only one option. Don't forget to use unique ids for each button.
 * If you are looking to toggle options, use a Checkbox instead.
 */
const Radio = ({
  style,
  value,
  label,
  small,
  gutter,
  checked,
  disabled,
  onChange,
  containerStyle,
  accessibilityLabel,
  smallText,
  alignTop,
  isInRow,
  testID,
  textContainerStyle,
  textStyle,
}) => {
  const { theme } = useTheme();
  const { isAndroid } = useResponsive();
  const labelID = useID();
  const [isFocused, setIsFocused] = useState(false);
  const [isPressed, setIsPressed] = useState(false);
  const handleChange = () => onChange(value);
  const handleBlur = useCallback(() => setIsFocused(false), []);
  const handleFocus = useCallback(() => {
    if (!isPressed) {
      setIsFocused(true);
    }
  }, [isPressed]);

  const handlePressIn = useCallback(() => {
    handleFocus();
    setIsPressed(true);
  }, []);
  const handlePressOut = useCallback(() => {
    setIsPressed(false);
    handleBlur();
  }, []);

  return (
    <Pressable
      id={`${testID}-${value}`}
      testID={`${testID}-${value}`}
      resourceID={`${testID}-${value}`}
      accessible={true}
      contentDescription={`${testID}-${value}`}
      accessibilityLabel={accessibilityLabel || `${testID}-${value}`}
      role="radio"
      aria-labelledby={labelID}
      disabled={disabled}
      handleOnPress={handleChange}
      handlePressIn={handlePressIn}
      handlePressOut={handlePressOut}
      style={[
        theme.radioContainer,
        gutter && theme[`${gutter}Gutter`],
        alignTop && theme.startRow,
        !isInRow && theme.fullWidth,
        containerStyle,
      ]}
      aria-checked={checked}
    >
      <View style={[theme.radioOutline, isFocused && theme.elementFocused]}>
        <View style={[theme.radio, small && theme.smallRadio, disabled && theme.disabled, style]}>
          <View
            style={[
              theme.radioControl,
              theme.textBg,
              small && theme.smallRadioControl,
              checked && theme.radioControlChecked,
            ]}
          />
        </View>
      </View>
      <View style={[!isInRow && theme.flex1, !isAndroid && theme.leftGutter1, textContainerStyle]}>
        <Text
          nativeID={labelID}
          size={smallText ? 'fp' : 'p'}
          color="text"
          weight="medium"
          style={[alignTop && { paddingTop: 2 }, textStyle]}
        >
          {label}
        </Text>
      </View>
    </Pressable>
  );
};

Radio.defaultProps = {
  checked: true,
};

export default Radio;
