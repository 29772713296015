import React from 'react';
import { View, StyleSheet, Platform } from 'react-native';

import Stack from './Stack';
import { useTheme } from '@uikit/hooks/useTheme';
import { useResponsive } from '@uikit/hooks/useResponsive';
import { CatchTheme } from '@app/_ui-kit/themes/catch';

export const Split = ({ children, align, always, spacing, sticky }) => {
  const { theme } = useTheme();
  const { longRange, isMobile } = useResponsive();
  const small = longRange === 'mobile';
  const kids = !!children && children?.length > 0 ? React.Children.toArray(children) : [];
  return (
    <Stack
      horizontal={!small || !!always}
      spacing={spacing ? spacing : small ? 2 : 8}
      style={[
        align === 'center'
          ? theme.alignCenter
          : align === 'end'
          ? theme.alignEnd
          : theme.alignStart,
        { justifyContent: 'space-between', overflow: 'visible' },
      ]}
      itemStyle={[!isMobile && theme.flex1, theme.fullWidth, sticky && !isMobile && theme.sticky]}
    >
      {kids.map((item, i) => (
        <View key={item.key || item.testID || i}>{item}</View>
      ))}
      {kids?.length === 1 && !small ? <View key="XTRA"></View> : null}
    </Stack>
  );
};

export const Narrow = ({ children, noMargins, center = true, style }) => {
  const { theme } = useTheme();
  const { res } = useResponsive(spacing);
  return (
    <View
      style={[
        theme.miniPage,
        center && theme.centerSelf,
        !noMargins && res.margins,
        theme.fullWidth,
        theme.flex1,
        style,
      ]}
    >
      {children}
    </View>
  );
};

export const Container = ({ children, top = false, bottom = false, noMargins, fullNative }) => {
  const { theme } = useTheme();
  const { res } = useResponsive(spacing);
  return (
    <View
      style={[
        (Platform.OS === 'web' || !fullNative) && theme.page,
        theme.centerSelf,
        !noMargins && res.margins,
        theme.fullWidth,
        theme.flex1,
        { overflow: 'visible' },
      ]}
    >
      {children}
    </View>
  );
};

const spacing = {
  margins: StyleSheet.create({
    desktop: {
      paddingHorizontal: CatchTheme.spacing.desktop.margins,
    },
    mobile: {
      paddingHorizontal: CatchTheme.spacing.mobile.margins,
    },
    default: {
      paddingHorizontal: CatchTheme.spacing.mobile.margins,
    },
  }),
};
