import React, { useState } from 'react';

import { useDeprecatedQuery } from '@data';
import { Currency, currentYear, useCopy } from '@app/utils';
import { Page, Layout, Stack } from '@layouts';
import { SegmentedControl, LineItem, ActionRow } from '@uikit';
import { routes, open } from '@app/navigate';

const ROWS = ['grossIncome1099', 'grossIncomeW2'];
const TABS = [
  { title: currentYear, dataKey: 'thisYear' },
  { title: currentYear - 1, dataKey: 'lastYear' },
  { title: 'All', dataKey: 'allTime' },
];

const IncomeStatsComponent = () => {
  const [tab, setTab] = useState(0);
  const { c } = useCopy('catch.income.incomeStats');

  const { waiting, stats } = useDeprecatedQuery('income', {
    variables: {
      currentYear,
      prevFrom: `${currentYear - 1}-01-01`,
      prevTo: `${currentYear - 1}-12-31`,
    },
  });
  const selectedTimePeriod = stats?.[TABS[tab]?.dataKey];
  const actualIncome = selectedTimePeriod?.['grossIncome'] - selectedTimePeriod?.['totalSavings'];

  return (
    <Page color="sheet">
      <Layout.Scroll margins>
        <SegmentedControl index={tab} setIndex={setTab} controls={TABS} />
        <Layout topSpace bottomSpace>
          <Stack spacing="2">
            <Stack spacing="2" mobileGap>
              <Stack spacing="1">
                <LineItem
                  prominent
                  left={c('grossIncome')}
                  loading={waiting}
                  right={<Currency>{selectedTimePeriod?.['grossIncome']}</Currency>}
                />
                {ROWS.map((row, idx) => (
                  <LineItem
                    key={`incomeStats-${row}-${tab}`}
                    loading={waiting}
                    left={c(`${row}`)}
                    right={<Currency>{selectedTimePeriod?.[row]}</Currency>}
                  />
                ))}
              </Stack>
              <LineItem
                prominent
                loading={waiting}
                left={c('totalSavings')}
                right={<Currency>{selectedTimePeriod?.['totalSavings']}</Currency>}
              />
              <LineItem
                prominent
                loading={waiting}
                left={c('actualIncome')}
                right={<Currency>{actualIncome}</Currency>}
              />
            </Stack>
            <Stack>
              <ActionRow
                accentColor="income"
                fp
                title="Statements"
                onAction={() => open(routes.STATEMENTS)}
              />
              {tab === 1 && (
                <ActionRow
                  accentColor="income"
                  fp
                  title="2022 in Review"
                  onAction={() => open(routes.ANNUAL_REVIEW)}
                />
              )}
            </Stack>
          </Stack>
        </Layout>
      </Layout.Scroll>
    </Page>
  );
};

export const IncomeStatsView = {
  name: 'INCOME_STATS',
  component: IncomeStatsComponent,
  options: {
    title: 'Income Breakdown',
    layout: 'sheet',
  },
};
