import React from 'react';
import { View } from 'react-native';

import { useModal } from '@hooks';
import { formatPeople, useCopy } from '@app/utils';
import { ActionSheet, ActionSheetContent, Button, Text, Link, useTheme } from '@uikit';
import { MembersAvatars } from '@app/partial';

/** @deprecated use sheetblueprint */
const NoAdditionalMembersPrompt = ({ show, deselected, onClose, deleteGroup, deleting }) => {
  const { c } = useCopy('catch.health');
  const { theme } = useTheme();
  const { isOpen, openModal, closeModal } = useModal();

  if (!show) return null;

  /**
   * Close all:
   * closes action sheet + member modal
   * and goes back to viewing plans
   */
  const closeAll = () => {
    closeModal();
    onClose();
  };

  return (
    <>
      <Text size="fp">
        {c('enrollmentWarning.message', {
          continue: (
            <Link inline small onPress={openModal}>
              {c('enrollmentWarning.continue')}
            </Link>
          ),
        })}
      </Text>
      <ActionSheet isOpen={isOpen} onRequestClose={closeModal}>
        <ActionSheetContent
          component={<MembersAvatars members={deselected} />}
          title={c('enrollmentWarning.title', {
            people: formatPeople({ people: deselected }),
            numPeople: deselected?.length,
          })}
          subtitles={[
            c('enrollmentWarning.subtitle', {
              people: formatPeople({ people: deselected, enumerated: true }),
            }),
          ]}
        >
          <View style={[theme.alignCenter, theme.bottomGutter5]}>
            <Button wide onPress={closeAll} disabled={deleting}>
              {c('viewPlans')}
            </Button>
            <Link
              style={[theme.topGutter3, theme.errorColor]}
              onPress={deleteGroup}
              disabled={deleting}
            >
              {c('continueWithoutCoverage')}
            </Link>
          </View>
        </ActionSheetContent>
      </ActionSheet>
    </>
  );
};

export default NoAdditionalMembersPrompt;
