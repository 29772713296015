import React, { useCallback, useState } from 'react';
import { Linking } from 'react-native';

import { safeFormatDate, toDate, useCopy, constants } from '@app/utils';
import { useDeprecatedQuery, useDeprecatedMutation } from '@data';
import { navigate, exit, routes } from '@navigate';
import { Modal, Loading, Button } from '@uikit';

import { Layout, Split, Stack } from '@layouts';
import FlowLayout from '@layouts/FlowLayout';
import ApplicationIssueCard from '@app/_common/cards/ApplicationIssueCard';

import DMIUploadView from './DMIUploadView';
import DMIUploadedView from './DMIUploadedView';

const PREFIX = 'catch.ede.DMI';

const DMI = ({ enrollId }) => {
  const { c } = useCopy(PREFIX);

  const { application, loading: enrollmentLoading } = useDeprecatedQuery('application', {
    variables: { enrollId },
    skip: !enrollId,
  });
  const inProgressApplication = /SUBMITTED/.test(application?.status);

  const [isUploading, setIsUploading] = useState(null);
  const [isViewing, setIsViewing] = useState(null);
  const handleNext = useCallback(
    () => (inProgressApplication ? navigate(routes.EDE_PLANS) : exit(routes.COVERAGE)),
    [enrollId],
  );
  const handleOpen = (issue) => {
    setIsUploading(issue);
  };

  const handleClose = () => {
    setIsUploading(null);
    setIsViewing(null);
  };

  const {
    sviTodos,
    dmiTodos,
    uploaded,
    expired,
    loading: issuesLoading,
    refetch,
  } = useDeprecatedQuery('applicationIssues', {
    variables: { enrollId },
    skip: !enrollId,
  });

  const [getDocument] = useDeprecatedMutation('getGeneratedDocumentURL', {
    onCompleted: (data) => Linking.openURL(data.getGeneratedDocumentURL),
  });

  //this is at the application and not applicant level so we render it above the Cards
  const renderClockExpirationNotice = (notice) => {
    if (!expired && notice?.name !== constants.dmiNoticeTypes.CLOCK_EXPIRATION_NOTICE) {
      return;
    }
    return (
      <Button
        key={notice.id}
        small
        alt
        testID={`getNotices_0`}
        onPress={() =>
          getDocument({
            variables: { input: { dsrsIdentifier: notice.dsrsIdentifier } },
          })
        }
      >
        {constants.dmiNoticeTypes.CLOCK_EXPIRATION_NOTICE.replace(/_/g, ' ')
          .split(' ')
          .map((word) => word[0] + word.substring(1).toLowerCase())
          .join(' ')}
      </Button>
    );
  };

  return (
    <FlowLayout onNext={handleNext} title={c('title')} subtitle={c('subtitle')}>
      {enrollmentLoading || issuesLoading || !enrollId ? (
        <Loading accentColor="coverage" />
      ) : (
        <Stack separatorComponent spacing="5">
          {!!sviTodos?.length && (
            <Split>
              <Layout.Header
                title={c('todoTitle', {
                  date: safeFormatDate(toDate(sviTodos[0].resolveBy), 'MMMM d, yyyy'),
                })}
                subtitle={c('sviSubtitle', {
                  date: safeFormatDate(toDate(sviTodos[0].resolveBy), 'MMMM d, yyyy'),
                })}
              />
              <Stack spacing="1">
                {sviTodos.map((issue) => (
                  <ApplicationIssueCard
                    onRequestUpload={() => handleOpen(issue)}
                    onViewDocuments={() => setIsViewing(issue)}
                    key={issue.id}
                    getDocument={getDocument}
                    {...issue}
                  />
                ))}
              </Stack>
            </Split>
          )}

          {!!dmiTodos?.length && (
            <Split sticky>
              <Layout.Header
                title={c('todoTitle', {
                  date: safeFormatDate(toDate(dmiTodos[0].resolveBy), 'MMMM d, yyyy'),
                })}
                subtitle={c('dmiSubtitle', {
                  date: safeFormatDate(toDate(dmiTodos[0].resolveBy), 'MMMM d, yyyy'),
                })}
              />
              <Stack spacing="1">
                {!!dmiTodos[0]?.notices[0] && renderClockExpirationNotice(dmiTodos[0]?.notices[0])}
                {dmiTodos?.map((issue) => (
                  <ApplicationIssueCard
                    onRequestUpload={() => handleOpen(issue)}
                    onViewDocuments={() => setIsViewing(issue)}
                    key={issue.id}
                    getDocument={getDocument}
                    {...issue}
                  />
                ))}
              </Stack>
            </Split>
          )}

          {!!uploaded?.length && (
            <Split sticky>
              <Layout.Header title={c('uploadedTitle')} subtitle={c('uploadedSubtitle')} />
              <Stack spacing="1">
                {uploaded?.map((issue) => (
                  <ApplicationIssueCard
                    onRequestUpload={() => handleOpen(issue)}
                    onViewDocuments={() => setIsViewing(issue)}
                    key={issue.id}
                    getDocument={getDocument}
                    {...issue}
                  />
                ))}
              </Stack>
            </Split>
          )}

          {!!expired.length && (
            <Split sticky>
              <Layout.Header title={c('expiredTitle')} subtitle={c('expiredSubtitle')} />
              <Stack spacing="1">
                {expired?.map((issue) => (
                  <ApplicationIssueCard
                    onRequestUpload={() => handleOpen(issue)}
                    onViewDocuments={() => setIsViewing(issue)}
                    key={issue.id}
                    getDocument={getDocument}
                    expired
                    {...issue}
                  />
                ))}
              </Stack>
            </Split>
          )}
        </Stack>
      )}
      {!!isUploading && (
        <Modal isOpen={!!isUploading} onRequestClose={handleClose} presentation="largeSheet">
          <DMIUploadView {...isUploading} onClose={handleClose} refetch={refetch} />
        </Modal>
      )}
      {isViewing && (
        <DMIUploadedView
          isViewing={isViewing}
          setIsViewing={setIsViewing}
          isUploading={isUploading}
          uploadMore={handleOpen}
        />
      )}
    </FlowLayout>
  );
};

export const DMIView = {
  name: 'EDE_DMI',
  component: DMI,
  options: {
    title: 'Documents',
  },
};
