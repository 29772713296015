import {
  useQuery,
  useMutation,
  PayrollQueryData,
  UpsertGoalResponse,
  UpsertGoalVars,
  queries,
  mutations,
} from '@data';
import { calculateTaxes } from '@catch-co/tax-calculator';
import { useCallback, useMemo } from 'react';
import { FilingStatus, WorkTypeTypes } from '@types';

interface UseTaxGoalOptions {
  onCompleted?: (data?) => void;
}

interface UseTaxGoalReturns {
  recalculate: (numDependents: number) => ReturnType<typeof calculateTaxes> | undefined;
  existingTaxGoal: any;
  currentFilingStatus?: FilingStatus;
  estimated1099Income?: number;
  estimatedW2Income?: number;
  grossIncome?: number;
  workType?: WorkTypeTypes;
  numDependents?: number;
  incomeState?: string;
  spouseIncome?: number;
  needsEftps?: boolean;
  initialValues: { numDependents: number; paycheckPercentage: number };
  loading: boolean;
  upserting: boolean;
  waiting: boolean;
  upsertTaxGoal: any;
  refetch: () => void;
}

export const useTaxGoal = (options?: UseTaxGoalOptions): UseTaxGoalReturns => {
  const {
    loading: loadingSetup,
    data,
    refetch,
  } = useQuery<PayrollQueryData>(queries.PAYROLL, {
    fetchPolicy: 'cache-first',
    returnPartialData: true,
    context: { failQuietly: true },
  });

  const taxData = {
    existingTaxGoal: data?.viewer?.goals?.find((goal) => goal.slug === 'taxes'),
    currentFilingStatus: data?.viewer?.user?.filingStatus,
    estimated1099Income: data?.viewer?.user?.estimated1099Income,
    estimatedW2Income: data?.viewer?.user?.estimatedW2Income,
    grossIncome:
      (data?.viewer?.user?.estimated1099Income || 0) + (data?.viewer?.user?.estimatedW2Income || 0),
    workType: data?.viewer?.user?.workType,
    numDependents: data?.viewer?.user?.numTaxDependents,
    incomeState: data?.viewer?.user?.workState,
    spouseIncome: data?.viewer?.user?.spouseIncome,
    needsEftps: !data?.viewerTwo?.agreements?.authorizedAboundEftpsEnrollment,
  };
  const {
    existingTaxGoal,
    currentFilingStatus,
    estimated1099Income,
    estimatedW2Income,
    grossIncome,
    workType,
    numDependents,
    incomeState,
    spouseIncome,
    needsEftps,
  } = taxData;

  const [update, { loading: upserting }] = useMutation<UpsertGoalResponse, UpsertGoalVars>(
    mutations.UPSERT_GOAL,
    {
      onCompleted: options?.onCompleted,
    },
  );

  const recalculate = useCallback(() => {
    return !!incomeState &&
      typeof grossIncome !== undefined &&
      typeof spouseIncome !== undefined &&
      typeof numDependents !== undefined &&
      !!currentFilingStatus
      ? calculateTaxes({
          state: incomeState,
          grossIncome,
          spouseIncome,
          numDependents,
          filingStatus: currentFilingStatus,
        })
      : undefined;
  }, [loadingSetup, grossIncome, numDependents, spouseIncome, incomeState, currentFilingStatus]);

  const taxGoalInitialValues = useMemo(() => {
    if (loadingSetup) {
      return { numDependents: 0, paycheckPercentage: 0 }; // just for fallback
    }
    return {
      numDependents: numDependents ?? 0,
      paycheckPercentage: recalculate()?.roundedPaycheckPercentage ?? 0,
    };
  }, [loadingSetup, grossIncome]);

  return {
    existingTaxGoal,
    currentFilingStatus,
    estimated1099Income,
    estimatedW2Income,
    grossIncome,
    workType,
    numDependents,
    incomeState,
    spouseIncome,
    needsEftps,
    initialValues: taxGoalInitialValues,
    loading: loadingSetup || upserting,
    upserting,
    waiting: loadingSetup,
    upsertTaxGoal: update,
    recalculate,
    refetch,
  };
};
