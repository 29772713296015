import gql from 'graphql-tag';

export interface WatchAccountVars {
  accountID: string;
}

export interface WatchAccountResponse {
  setWatchAccounts: {
    accounts: Array<{
      id: string;
      isWatched: boolean;
    }>;
  };
}

export const WatchAccount = gql`
  mutation WatchAccount($accountID: ID!) {
    setWatchAccounts(ids: [$accountID]) {
      accounts {
        id
        isWatched
      }
    }
  }
`;
