import React from 'react';
import { View } from 'react-native';

// relative imports
import { Asset } from './Asset';
import { useTheme } from '../hooks/useTheme';
import { Profile } from '@uikit';

/**
 * size presets for grouping together assets
 * note: this handles asset size, container size, and overlap
 */
const SIZE_PRESETS = {
  sm: {
    size: 'xs',
    containerSize: 'sm',
    overlap: 12,
    fontWeight: 'medium',
  },
  md: {
    size: 'sm',
    containerSize: 'md',
    overlap: 6,
    fontWeight: 'medium',
  },
  lg: {
    size: 'md',
    containerSize: 'lg',
    overlap: 10,
    fontWeight: 'bold',
  },
};

interface Props {
  assets: Array<any>;
  containerSize: 'sm' | 'md' | 'lg';
  border?: boolean;
  rest: any;
  activeIdx?: number;
}

/**
 * Assets Group shows a set of assets together
 * Given a list of assets, renders those in an overlapped list
 * Also accepts size prop + any other asset props to be applied across all
 * note: we compute the size, container size + overlap based on the size prop
 * note: only sizes declared in the SIZE_PRESETS above are currently supported
 */
export const AssetsGroup = ({
  assets,
  border = true,
  containerSize,
  activeIdx,
  ...rest
}: Props) => {
  const { theme } = useTheme();

  //if we're showing active/inactive state then we use the following preset:
  const showActiveState = activeIdx || activeIdx === 0;
  const containerState = containerSize;

  const preset = SIZE_PRESETS[containerState];

  if (!preset) return null; // as safety check

  return (
    <View style={[theme.row, { paddingRight: preset.overlap }]}>
      {assets?.map((asset, i) => {
        //mark active/inactive/completed icons:
        let colorState;
        if (showActiveState) {
          if (i === activeIdx) {
            colorState = 'assetActive';
          } else if (i < activeIdx) {
            colorState = 'assetCompleted';
          } else {
            colorState = 'assetIncomplete';
          }
        }

        return (
          <View
            key={i}
            style={{
              marginRight: -1 * preset.overlap,
              zIndex: assets?.length - i,
            }}
          >
            <Asset
              shape="circle"
              {...asset}
              icon={asset?.text === '' && Profile}
              style={[border && theme.pageBorder, border && { borderWidth: 2 }]}
              size={preset.size}
              containerSize={preset.containerSize}
              fontWeight={preset.fontWeight}
              color={colorState}
              bg={colorState}
              borderColor={colorState}
              {...rest}
            />
          </View>
        );
      })}
    </View>
  );
};

export default AssetsGroup;
