import gql from 'graphql-tag';
import access from 'safe-access';

export const VERIFY_ALIAS = gql`
  query VerifyAlias {
    viewer {
      id
      isAliasMatchAndVerified {
        phoneMatchAndVerified
      }
      user {
        id
        email
        phoneNumber
      }
    }
  }
`;

const formatter = (data) => {
  const get = access(data?.viewer);
  return {
    email: get('user.email'),
    phoneNumber: get('user.phoneNumber'),
    phoneVerified: get('isAliasMatchAndVerified.phoneMatchAndVerified'),
  };
};

export default {
  document: VERIFY_ALIAS,
  formatter,
};
