import React from 'react';
import { CSRLevel } from '@types';
import { EligibilityMember } from '@data';
import { Stack } from '@layouts';
import { Link } from '@uikit';
import { getFullName, useCopy } from '@app/utils';
import { useModal } from '@app/hooks/useModal';
import PeopleList from '@common/components/PeopleList';
import EligibilityItem from './EligibilityItem';
import Dollar from '@svg/dollar-2.svg';
import Check from '@svg/checkmark.svg';
import X from '@svg/close.svg';
import Plus from '@svg/plus.svg';
import EligibilitySummaryActionSheet from '@app/_common/actionsheets/EligibilitySummaryActionSheet';

type EligibilityBucket =
  | 'MARKETPLACE_ELIGIBLE'
  | 'NOT_ELIGIBLE_SEP'
  | 'MEDICAID'
  | 'CHIP'
  | 'NOT_ELIGIBLE';

interface EligibilityResult extends EligibilityMember {
  results: {
    eligibility: EligibilityBucket;
    hasAPTC: boolean;
    aptcAmount?: number;
    hasCSR: boolean;
    csrLevel?: Omit<CSRLevel, 'NONE'>;
    hasPlanRestriction: boolean;
  };
}

interface EligibilityGroup {
  type: EligibilityBucket;
  members: Array<EligibilityResult>;
  totalAPTC?: number; // the total for this group
}

const EligibilityGroupList: React.FC<{
  applicationID: string;
  groups?: Array<EligibilityGroup>;
  determinationType?: 'ASSESSMENT' | 'DETERMINATION';
  coverageYear?: number;
}> = ({ applicationID, groups, determinationType, coverageYear }) => {
  const { c, $ } = useCopy('catch.ede.EligibilityResults');
  const { isOpen, openModal, closeModal } = useModal();

  return (
    <Stack spacing="3" separatorComponent>
      {groups?.map((group) => (
        <Stack key={group.type} spacing="1">
          <Stack>
            <PeopleList
              members={group?.members}
              sublabel={(m) =>
                m.results.hasPlanRestriction ? 'Must enroll in existing plan' : undefined
              }
            />
          </Stack>

          <Stack>
            <EligibilityItem
              icon={/NOT_ELIGIBLE/.test(group.type) ? X : Check}
              title={c(`${group.type}.banner`) || group.type}
              description={c(`${group.type}.description`, {
                determinationType,
                coverageYear,
                count: group.members.length,
              })}
            />

            {group.totalAPTC > 0 && (
              <>
                <EligibilityItem
                  icon={Dollar}
                  title={c('monthlySavingsTitle')}
                  description={c('monthlySavingsText', {
                    amount: <Link onPress={openModal}>{$(group.totalAPTC)}</Link>,
                  })}
                />
                <EligibilitySummaryActionSheet
                  isOpen={isOpen}
                  onRequestClose={closeModal}
                  monthlySavings={group.totalAPTC}
                  applicationID={applicationID}
                />
              </>
            )}

            {group.csr && (
              <EligibilityItem
                icon={Plus}
                title={c('addlSavingsTitle')}
                description={
                  group?.csr.isSubset
                    ? c('addlSavingsDescriptionGroup', {
                        people: getFullName(group?.csr?.members),
                        count: group?.csr?.members?.length,
                      })
                    : c('addlSavingsDescription')
                }
              />
            )}
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
};

export default EligibilityGroupList;
