import React from 'react';
import { mins, useCopy } from '@app/utils';
import { useAuth } from '@client/Auth';
import { Idle } from '@client/utils';
import { popToast } from '@uikit';

const TIMEOUT = mins(60); // Time after which we log the user out
const PRETIMEOUT = mins(1); // Time before which we warn them about it

export const AutoSignOut = () => {
  const { c } = useCopy('catch.module.login.AutoSignOut');
  const signOut = useAuth((state) => state.signOut);
  return (
    <Idle
      timeout={TIMEOUT}
      preTimeout={PRETIMEOUT}
      onChange={signOut}
      onWarn={() =>
        popToast({
          type: 'important',
          msg: c('toastBody'),
          title: c('toastTitle'),
          autoCloseIn: PRETIMEOUT,
        })
      }
    />
  );
};

export default AutoSignOut;
