import React, { useEffect } from 'react';
import { View } from 'react-native';

import { SetupIntro } from '@common';
import { routes } from '@navigate';
import { ComplexRow, CatchTheme } from '@app/_ui-kit';
import Checkmark from '@svg/checkmark.svg';
import { STATES } from '@app/utils';

/**
 * Health Link Intro
 */
const HealthLinkIntro = ({
  handleNext,
  handleSkip,
  loading,
  searching,
  hasActivePolicy,
  confirmIdentity,
  canSearch,
  isInExchange,
  lookup,
  context = 'sheet',
  applications,
  isSlasherFlow,
}) => {
  const _loading = loading || searching;

  useEffect(() => {
    lookup(true);
  }, []);

  useEffect(() => {
    if (!loading && !!canSearch) confirmIdentity();
  }, [loading, canSearch]);

  const _app = applications?.[0];

  return (
    <SetupIntro
      context={context}
      type="HEALTH_LINK"
      disabled
      onNext={handleNext}
      onSkip={isSlasherFlow ? undefined : handleSkip}
      loading={!isInExchange && _loading}
    >
      <View style={{ alignSelf: 'center', minWidth: CatchTheme.containers.dialog }}>
        {(hasActivePolicy === 'YES' || isInExchange) && (
          <ComplexRow
            testID="lookupResultLabel"
            loading={_loading}
            sublabel={_app ? `${STATES[_app?.state]} ${_app?.coverageYearNumber}` : undefined}
            asset={{
              svg: Checkmark,
              bg: _loading && !isInExchange ? 'page' : 'coverage',
              color: 'white',
              shape: 'circle',
            }}
            label={
              hasActivePolicy === 'YES'
                ? 'Found active coverage'
                : isInExchange
                ? 'Found coverage'
                : ''
            }
          />
        )}
      </View>
    </SetupIntro>
  );
};

export const HealthLinkIntroView = {
  name: routes.HEALTH_LINK_INTRO,
  component: HealthLinkIntro,
  options: {
    ...SetupIntro.options,
    bg: 'coverageLight',
    title: '',
  },
};
