import { date } from './date';
import { formatIcon } from '@app/utils/activity/formatActivity';
import { STATES } from '../const';

export const enrollmentStatus = {
  INITIAL_SEP: 'IN_PROGRESS',
  INITIAL_OEP: 'IN_PROGRESS',
  PREFILL: 'IN_PROGRESS',
  INACTIVE: 'CANCELLED',
  PENDING: 'PENDING',
  PENDING_IMPORT: 'PENDING',
  PENDING_SUPPORT_TASK: 'PENDING',
  ACTIVE: 'ACTIVE',
  PENDING_START_DATE: 'STARTS',
};

/** check providerStatus first */
export const taxPaymentStatus = (pmt) => {
  const _status = pmt?.providerStatus || pmt?.status?.replace('TAX_PAYMENT_ADMIN_', '');
  if (/CREATED|PENDING|INITIATED|PROCESSING/.test(_status)) return 'Pending';
  else if (/FAILED|CANCELLED|CANCELED/.test(_status)) return 'Failed';
  else return `Paid ${date(pmt?.createdOn, 'SHORT_WITH_YEAR')}`;
};

export const taxPaymentIcon = (pmt) => {
  const _status = pmt?.providerStatus || pmt?.status?.replace('TAX_PAYMENT_ADMIN_', '');

  const isFailed = /FAILED|CANCELLED|CANCELED/.test(_status);
  const isDone = /COMPLETE/.test(_status);

  const icon = isDone ? 'CHECK' : isFailed ? 'CLOSE' : 'PAYMENT';
  const color = isFailed ? 'coverage' : 'taxes';

  return {
    ...formatIcon(icon, {
      color: color,
      bold: isDone || isFailed,
    }),
  };
};

export const taxPaymentJurisdiction = (code) => {
  if (code === 'US') return 'Federal';
  else if (!!code && !!STATES[code]) return STATES[code];
  else return 'Federal';
};

export const taxPaymentEntity = (code) => {
  if (code === 'US') return 'The IRS';
  else if (!!code && !!STATES[code]) return STATES[code];
  else return 'The IRS';
};
