import { InitialLocation, StartOptions } from '../types';
import { history } from '../history';
import { paths } from '../routes';
import { open } from './open';
import { push } from './push';

export const start = (initialLocation: InitialLocation, options: StartOptions) => {
  if (initialLocation === 'GUEST') {
    history.push(paths.LOGIN);
  }

  if (initialLocation === 'SIGNED_OUT') {
    history.push(paths.LOGIN, {});
  }

  if (initialLocation === 'ONBOARDING_STACK') {
    history.push(paths.WELCOME); // beginning of onboarding
  }

  if (initialLocation === 'MAIN_TABS' && !options?.forcePush) {
    if (options?.initialTab === 'COVERAGE_STACK') {
      history.push(paths.COVERAGE);
    } else if (options?.initialTab === 'PLAN_STACK') {
      history.push(paths.PLAN);
    } else if (options?.initialTab === 'TAXES_STACK') {
      history.push(paths.TAXES);
    } else if (options?.initialTab === 'INCOME_STACK') {
      history.push(paths.INCOME);
    } else {
      history.push(paths.HOME);
    }
  }

  if (options?.open) {
    if (options?.forcePush) {
      push(options.open, options?.data || {});
    } else {
      open(options.open, options?.data || {});
    }
  }
};
