import React, { useEffect, useState } from 'react';
import { Text } from 'react-native'; // eslint-disable-line
import { RawIntlProvider, createIntl, createIntlCache } from 'react-intl';

import { useLocale } from './locale';
import { DEFAULT_LOCALE, translationMessages as messages } from './i18n';

const cache = createIntlCache();
const intlConfigs = {
  locale: DEFAULT_LOCALE,
  messages: messages[DEFAULT_LOCALE],
  textComponent: Text,
};

// Out of tree intl object
export let ootIntl = createIntl(intlConfigs, cache);

export let copy = {
  format: (id, data = {}) => ootIntl.formatMessage({ id }, data),
  num: (value, options) =>
    ootIntl.formatNumber(value, {
      style: 'decimal',
      minimumFractionDigits: options?.whole ? 0 : 2,
      maximumFractionDigits: options?.whole ? 0 : 2,
    }),
  $: (value, options) =>
    ootIntl.formatNumber(value, {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: options?.whole ? 0 : 2,
      maximumFractionDigits: options?.whole ? 0 : 2,
    }),
};

const LanguageProvider = ({ children }) => {
  const { locale } = useLocale();

  // sets the entire intl object
  const [intl, setIntl] = useState(ootIntl);

  // when locale changes, create and set the new intl
  useEffect(() => {
    if (locale !== intl.locale) {
      ootIntl = createIntl({ ...intlConfigs, locale, messages: messages[locale] }, cache);
      setIntl(ootIntl);
    }
  }, [locale]);

  return <RawIntlProvider value={intl}>{children}</RawIntlProvider>;
};

export default LanguageProvider;
