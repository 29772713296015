import React, { useEffect, useMemo } from 'react';
import { FormLabel, Text } from '@uikit';
import { TextField } from '@app/forms_old/inputs';
import { CountiesQueryData, CountiesQueryVars, queries, useQuery } from '@data';
import { InputProps } from '../types';
import OptionInput from './OptionInput';
import { useCopy, stateExchanges } from '@app/utils';

interface ZipInputProps<T> extends InputProps<T> {
  large?: boolean;
}

const parseZip = (val: string): string => {
  return val?.replace(/\D+/g, '').slice(0, 5);
};

// value gets stored as...
// { zip: ___, county: ___, fips: ___, state: ___ }
const ZipInput = React.forwardRef(
  <T extends any>(
    {
      testID,
      autoFocus,
      name,
      keyboard,
      value,
      label,
      sublabel,
      optional,
      placeholder,
      disabled,
      onChange,
      onFocus,
      onBlur,
      onKeyPress,
      onSubmitEditing,
      error,
      help,
      format,
      large,
    }: ZipInputProps<T>,
    ref,
  ) => {
    const { c } = useCopy('catch.basics');

    const validZip = useMemo(() => {
      return value && value?.zip?.length === 5;
    }, [value]);

    const { loading, data } = useQuery<CountiesQueryData, CountiesQueryVars>(queries.COUNTIES, {
      variables: { zipcode: value?.zip },
      skip: !validZip,
    });

    const { counties, countiesByFips, countiesByName, matchMultiple, county } = useMemo(() => {
      const counties = (data?.reference.counties || [])?.map((c, i) => ({
        ...c,
        pathway: c.state in stateExchanges ? 'STATE_EXCHANGE' : 'EDE',
      }));

      const countiesByFips = counties.reduce((acc, county, i) => {
        return { ...acc, [county.fips]: county };
      }, {});

      const countiesByName = counties.reduce((acc, county, i) => {
        return { ...acc, [county.name]: county };
      }, {});

      return {
        counties,
        countiesByFips,
        countiesByName,
        matchMultiple: counties?.length > 1,
        hasMatches: counties?.length > 0,
        county: counties?.length === 1 && validZip ? counties[0] : undefined,
      };
    }, [data]);

    // handles setting the county when there is only one
    useEffect(() => {
      onChange({
        zip: value?.zip,
        county: county?.name,
        fips: county?.fips,
        state: county?.state,
        pathway: county?.pathway,
      });
    }, [county]);

    //
    useEffect(() => {
      let county;

      // if fips is set, but no county name -> set it!
      if (value.fips && !value.county && countiesByFips) {
        county = countiesByFips[value?.fips];
      }

      // if county name is set, but no fips -> set it!
      if (value.county && !value.fips && countiesByName) {
        county = countiesByName[value.county];
      }

      if (county) {
        onChange({
          zip: value?.zip,
          county: county?.name,
          fips: county?.fips,
          state: county?.state,
          pathway: county?.pathway,
        });
      }
    }, [value, countiesByFips, countiesByName]);

    return (
      <>
        <FormLabel
          testID={testID}
          label={label}
          sublabel={sublabel}
          help={help}
          optional={optional}
        />
        <TextField
          ref={ref}
          testID={testID}
          autoFocus={autoFocus}
          keyboard="zip"
          large={large}
          value={value?.zip || ''}
          onChangeText={(val) =>
            onChange({
              zip: parseZip(val),
              county: undefined,
              fips: undefined,
              state: undefined,
            })
          }
          onFocus={onFocus}
          disabled={disabled}
          onBlur={onBlur}
          onKeyPress={onKeyPress}
          onSubmitEditing={onSubmitEditing}
          placeholder="00000"
          format={format}
          fullWidth
          error={error}
        />

        {!validZip ? null : !data ? (
          <Text size="fp">Checking...</Text>
        ) : matchMultiple ? (
          <OptionInput
            testID="county-lookup"
            label={c('multipleCounties')}
            name="county-lookup"
            value={value?.fips}
            onChange={(fips) => {
              const county = countiesByFips[fips];

              onChange({
                ...value,
                county: county?.name,
                fips: county?.fips,
                state: county?.state,
                pathway: county?.pathway,
              });
            }}
            options={counties.map((county) => ({
              label: county.name,
              value: county.fips,
            }))}
          />
        ) : (
          <Text size="fp" gutter="bottom">
            {county ? `${county?.name}, ${county?.state}` : 'Not a valid zip code'}
          </Text>
        )}
      </>
    );
  },
);

ZipInput.displayName = 'ZipInput';
export default ZipInput;
