import { useDeprecatedQuery } from '@data';
import { Goal, GoalType } from '@types';

interface UseGoalsInput {
  goalType?: GoalType;
}

interface UseGoalsResponse {
  loading: boolean;
  waiting: boolean;
  hasAnyGoal: boolean;
  numGoals: number;
  goal?: Goal;
  goals?: Array<Goal>;
  savingsGoals: Array<Goal>;
  investmentGoals: Array<Goal>;
  state: string;
}

interface FormattedGoalsResponse {
  goals: Array<Goal>;
  savingsGoals: Array<Goal>;
  investmentGoals: Array<Goal>;
}

/**
 * Filters out the list of goals
 * - Filters out DELETED goals
 * - Splits into savings + investment giaks
 */
export const filterGoals = (goals?: Array<Goal>): FormattedGoalsResponse => {
  // filter out any deleted goals so we don't display to user
  const filteredGoals = goals?.filter((g) => g?.status !== 'DELETED') || [];

  const savingsGoals = filteredGoals.filter(
    (g) => g.goalType !== 'TAX' && g.goalType !== 'RETIREMENT',
  );

  const investmentGoals = filteredGoals.filter(
    (g) => g.goalType !== 'TAX' && g.goalType === 'RETIREMENT',
  );

  return {
    goals: filteredGoals,
    savingsGoals,
    investmentGoals,
  };
};

/**
 * Helper function to find a goal
 * Given a list of goals and the goal type
 * Returns the goal with matching goal type (if found)
 */
export const findGoalByType = (goals: Array<Goal>, goalType?: GoalType): Goal | undefined => {
  if (!goals || !goalType) return undefined;
  return goals.find((g) => g.goalType === goalType);
};

/**
 * useGoals hook
 * Transforms data into usable lists, plus some indicators for goals
 */
export function useGoals(options?: UseGoalsInput): UseGoalsResponse {
  const { loading, goals, state, waiting } = useDeprecatedQuery('goals', {
    returnPartialData: true,
  });
  const filtered = filterGoals(goals);
  const goal = findGoalByType(filtered.goals, options?.goalType);

  return {
    loading,
    waiting,
    goal,
    hasAnyGoal: filtered.goals.length > 0,
    numGoals: filtered.goals.length || 0,
    goals: filtered.goals,
    savingsGoals: filtered.savingsGoals,
    investmentGoals: filtered.investmentGoals,
    state,
  };
}
