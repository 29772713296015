import React from 'react';
import { unstable_HistoryRouter as Router } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { ApolloProvider } from '@apollo/client';

// Internal Modules
import { LanguageProvider } from '@app/utils';
import { ThemeProvider } from '@uikit';
import { history } from '@navigate';

import App from './App.web';

// Do not remove this – @aa-io
/* eslint-disable */
import styles from './styles';
/* eslint-enable */

/**
 * AppProvider is our top level component that aggregates all necessary external
 * context providers and renders our App.  It is also in charge of setting up
 * any global style context that might be needed in various libraries.
 */
const AppProvider = ({ store, client, messages }) => (
  <ThemeProvider>
    <ApolloProvider client={client}>
      <ReduxProvider store={store}>
        <Router history={history}>
          <LanguageProvider messages={messages}>
            <App />
            <div id="portal" />
          </LanguageProvider>
        </Router>
      </ReduxProvider>
    </ApolloProvider>
  </ThemeProvider>
);

export default AppProvider;
