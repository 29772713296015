// import { chooseHealthApp } from '@app/utils';
import type { IdentityStatus, PromptName, Situation } from '@types';
import { BankLinkData, GoalsData, ApolloBankLink } from '@data';
import { Prompts } from '@app/config/prompts';
import { checkCMS } from '../health';
import { LINK_MODES, BANK_ERROR_CODES } from '../const';
import Env from '../env';

/**
 * wat dis for?
 */
export const promptNames: Record<PromptName, PromptName> = {
  ACCOUNT_LOCKED: 'ACCOUNT_LOCKED',
  KYC_DENIED: 'KYC_DENIED',
  // BANK_LINK_ERROR: 'BANK_LINK_ERROR',
  // LINK_BANK: 'LINK_BANK',
  // KYC_DOCS: 'KYC_DOCS',
  // CIP_DOCS: 'CIP_DOCS',
  // KYC_REVIEW: 'KYC_REVIEW',
  PAYCHECK: 'PAYCHECK',
  NEEDS_HEALTH_DOCS: 'NEEDS_HEALTH_DOCS',
  PAYMENT_REDIRECT: 'PAYMENT_REDIRECT',
  MANUAL_ID_REVIEW: 'MANUAL_ID_REVIEW',
  DOCS_AVAILABLE_2: 'DOCS_AVAILABLE_2',
  REVIEW_AVAILABLE: 'REVIEW_AVAILABLE',
};

interface Prompt {
  name: PromptName;
  data?: {};
}

/* goals flags do not include retirement */
const getNumGoals = (goals: Array<GoalsData>): number => {
  return goals?.filter((goal) => goal.__typename !== 'RetirementGoal')?.length || 0;
};

export const hasBankError = (bankLinks: BankLinkData[] | ApolloBankLink[]): boolean => {
  return bankLinks?.some((bankLink) => bankLink?.syncStatus !== 'GOOD');
};

// has some application with policies
// const hasApplicationWithPolicies = (data: HomeQueryData): boolean => {
//   return !!(data?.viewer?.health?.applications || []).find((a) => a?.policies?.length > 0);
// };

// has some application that has been slashed already
// const hasSlashed = (data: HomeQueryData): boolean => {
//   return !!(data?.viewer?.health?.applications || []).find(
//     (a) => a.applicationContext === 'SLASHER' && a.status === 'ENROLLED',
//   );
// };

const applyPrompt = (promptName, data) => {
  const config = Prompts[promptName];
  return typeof config === 'function' ? config(data) : config;
};

const shouldPromptLink = (data: {
  viewerTwo?: {
    existingApplicationInfo?: {
      existingApplications?: Array<{ id: string }>;
    };
    cmsIdentity?: {
      status?: IdentityStatus;
    };
    agreements?: {
      isPrivacyDataUseAgree?: boolean;
      isPrivacyTruthfulAgree?: boolean;
    };
  };
}): boolean => {
  const applications = data?.viewerTwo?.existingApplicationInfo?.existingApplications;
  const identity = data?.viewerTwo?.cmsIdentity;
  const agreements = data?.viewerTwo?.agreements;

  const hasSearched = !!applications;
  const hasApplications = applications && applications?.length > 0;
  const needsIdentity = !identity || identity?.status !== 'VERIFIED';
  const needsAgreements = !agreements?.isPrivacyDataUseAgree || !agreements?.isPrivacyTruthfulAgree;

  return (hasSearched && hasApplications && (needsIdentity || needsAgreements)) || !hasSearched;
};

const getOEApplications = (data) => {
  return (data?.viewerTwo?.health?.applications || data?.viewer?.health?.applications || []).filter(
    (app) =>
      app.coverageYearNumber === data?.reference?.health?.openEnrollmentDates?.oeCoverageYear,
  );
};

const getBestNextGoal = (data) => {
  return (
    data?.viewer?.goals?.filter((goal) => goal.status === 'DRAFT')?.[0]?.slug ||
    data?.viewer?.goals?.filter((goal) => goal.status === 'NOT_STARTED')?.[0]?.slug ||
    'taxes'
  );
};

/**
 * Given query data and a list of prompts to show,
 * we return computed set of prompts
 */
export const getPrompts = (
  data,
  { showPrompts = Object.keys(promptNames), isDebug },
): Array<Prompt> => {
  console.log({ showPrompts });

  const displayPrompts: Array<Prompt> = [];
  const oeApps = getOEApplications(data);
  const nextGoal = getBestNextGoal(data);
  const v = data?.viewer;
  const v2 = data?.viewerTwo;
  const u = v?.user;
  const bankingReady = v2?.accountStatus?.isBankingReady;
  const wealthReady = v2?.accountStatus?.isWealthReady;
  const kycStatus = v?.savingsAccountMetadata?.kycStatus;
  const idpStatus = v2?.cmsIdentity?.status;
  const agreements = v2?.agreements;
  const paychecks = v?.paychecks?.edges?.filter((paycheck) => paycheck?.status === 'USER_PENDING');
  const dismissedNudges = data?.viewer?.nudges?.filter((n) => n?.isDismissed)?.map((n) => n.id);

  // prefer whats in the explorer currently over any other fallback
  const preferredPathways = v2?.healthExplorerData?.pathwayType
    ? [v2?.healthExplorerData?.pathwayType]
    : u?.supportedPathways;

  // determines situations based on passed data
  const situations: Record<Situation, boolean> = {
    /** PENALTIES */
    LOCKED: !!v2?.accountStatus?.isLocked,
    DENIED: !!v2?.accountStatus?.isBankingKYCDenied,
    NOT_LOCKED: !v2?.accountStatus?.isLocked,
    NOT_DENIED: !v2?.accountStatus?.isBankingKYCDenied,

    /** BANK LINK */
    HAS_BANK: v?.bankLinks?.some((bl) => bl?.syncStatus === 'GOOD'),
    BANK_ERROR: hasBankError(v?.bankLinks),
    NO_BANK: v?.bankLinks?.length === 0 && !!u,

    /** DDA */
    KYC_NEEDS_DOCS: v2?.unitIdentity?.status === 'NEEDS_DOCS',
    CIP_NEEDS_DOCS:
      v2?.folioIdentity?.status === 'NEEDS_DOCS' && v2?.unitIdentity?.status !== 'NEEDS_DOCS',
    KYC_PENDING: kycStatus === 'KYC_PENDING',
    KYC: !!u?.kycSavings?.needed,
    KYC_GOOD_OR_PENDING: /KYC_GOOD|KYC_PENDING/.test(kycStatus),
    KYC_NOT_INITIATED: !kycStatus || kycStatus === 'KYC_INITIAL',
    NO_DDA: !bankingReady,
    DDA: bankingReady,
    NO_KYC_PENDING: !u?.kycSavings?.needed && kycStatus !== 'KYC_PENDING',

    /** HEALTH */
    HEALTH_SHOULD_LINK:
      shouldPromptLink(data) &&
      !(v2?.health?.applications?.length > 0) &&
      !preferredPathways?.includes('STATE_EXCHANGE'),
    // HEALTH_SHOULD_SLASH: hasApplicationWithPolicies(data) && !hasSlashed(data),
    HEALTH_SHOULD_SLASH: false,
    FFM_STATE: preferredPathways?.some((pathway) => /EDE/.test), //wtf?
    KNOWN_NON_FFM: preferredPathways?.includes('STATE_EXCHANGE'),
    ASSUMED_FFM: preferredPathways === null || preferredPathways?.includes('EDE'),

    HEALTH: v2?.health?.applications?.length > 0,
    HEALTH_STANDARD: !checkCMS(u?.email),
    HEALTH_SIMPLE: checkCMS(u?.email),

    /** OPEN ENROLLMENT */
    OPEN_ENROLLMENT: false,
    NOT_OPEN_ENROLLMENT: true,
    WINDOW_SHOPPING: false,
    NO_OE_APPS: oeApps?.length === 0,
    HAS_ENROLLED_IN_OE: oeApps?.some((app) => app.status === 'ENROLLED'),
    HAS_NOT_ENROLLED_IN_OE: oeApps?.every((app) => app.status !== 'ENROLLED'),

    /** GOALS */
    HAS_ANY_READY_GOAL: v?.goals?.some(
      (goal) => !!goal.status && /^ACTIVE|^GOAL_MET|^PAUSED/.test(goal.status),
    ),
    HAS_TAX_GOAL: v?.goals?.some((goal) => goal.__typename === 'TaxGoal'),
    NO_TAX_GOAL: !v?.goals?.some((goal) => goal.__typename === 'TaxGoal'),
    NO_RETIREMENT_GOAL: !v?.retirementGoal,
    HAS_RETIREMENT_GOAL: !!v?.retirementGoal,
    HAS_GOALS: getNumGoals(v?.goals) > 0,
    NO_GOALS: getNumGoals(v?.goals) === 0,

    /** TAX */
    HAS_AUTO_TAX: !!v?.taxGoal?.autoTax,
    NEED_AUTO_TAX: !!bankingReady && !v?.taxGoal?.autoTax,
    NEED_EFTPS_AUTH: !agreements?.authorizedAboundEftpsEnrollment,

    /** EMAIL */
    NEEDS_EMAIL: !u?.email && !!agreements?.acknowledgedUnitAccountDisclosures,

    /** INCOME */
    INCOME: paychecks?.length > 0,
    NO_INCOME: paychecks?.length === 0,

    /** IRA */
    IRA: !!wealthReady,
    NO_IRA: !wealthReady,

    /** currently unused/todo */
    MANUAL_ONLY: false,
    JOINED_VIA_PARTNER: false,
    NO_HEALTH: false,
    NEED_HEALTH_DOCS: false,
    HEALTH_IN_PROGRESS: false,
    MANUAL_ID_REVIEW: idpStatus === 'PENDING_MANUAL_REVIEW',
    DOCS_AVAILABLE: data?.viewerTwo?.documents?.taxDocuments?.length > 0,
  };

  /**
   * situational prompts
   * e.g. if situations apply, we show the prompt
   */
  showPrompts.forEach((name) => {
    const prompt = applyPrompt(name, { ...data, nextGoal });
    // for each singleton prompt
    if (
      !prompt.forEach &&
      prompt.name !== 'BANK_LINK_ERROR' &&
      !dismissedNudges?.includes(prompt.name)
    ) {
      if (isDebug || prompt.dependencies.every((dep) => situations[dep])) {
        displayPrompts.push(prompt);
      }
    }
  });

  if (situations.BANK_ERROR && showPrompts.includes(promptNames.BANK_LINK_ERROR)) {
    data?.viewer?.bankLinks?.forEach((bl) => {
      if (bl?.syncStatus !== 'GOOD' && situations.NOT_LOCKED && situations.NOT_DENIED) {
        const disconnectType = BANK_ERROR_CODES[bl?.errorCode];

        const prompt = applyPrompt(promptNames.BANK_LINK_ERROR, {
          bankName: bl?.bankName,
          logo: bl?.plaidBank?.logo,
          bankLinkID: bl?.id,
          mode: LINK_MODES[disconnectType],
        });
        displayPrompts.push(prompt);
      }
    });
  }

  /**
   * foreach prompts
   * e.g. for each of this data object, we show the prompt
   */
  if (situations.INCOME && showPrompts.includes(promptNames.PAYCHECK)) {
    v?.paychecks?.edges?.forEach((paycheck) => {
      if (paycheck?.status === 'USER_PENDING') {
        const prompt = applyPrompt(promptNames.PAYCHECK, {
          paycheck,
          disabled: situations.LOCKED || situations.DENIED,
        });
        displayPrompts.push(prompt);
      }
    });
  }

  if (showPrompts.includes(promptNames.NEEDS_HEALTH_DOCS)) {
    // health docs
    oeApps.forEach((app) => {
      const issues = [...(app?.dmis || []), ...(app?.svis || [])].filter(
        (issue) => issue.status === 'ACTION_NEEDED' || issue.status === 'PROCESSING',
      );

      const actionNeeded = issues.some((issue) => issue?.status === 'ACTION_NEEDED');

      if (issues.length > 0) {
        displayPrompts.push(
          applyPrompt(promptNames.NEEDS_HEALTH_DOCS, {
            actionNeeded,
            applicationID: app?.id,
            deadline: Date.parse(issues?.[0]?.resolveBy),
          }),
        );
      }
    });
  }

  // payment redirect
  if (showPrompts.includes(promptNames.PAYMENT_REDIRECT)) {
    oeApps.forEach((app) => {
      const policies = [...(app?.policies || [])]
        ?.filter((p) => p.dueDate >= Date.now())
        .sort((a, b) => (a?.policy?.productType > b?.policy?.productType ? 1 : -1));

      policies.forEach((policy) => {
        if (policy.policyStatus === 'ACTIVE_PENDING_PAYMENT') {
          displayPrompts.push(
            applyPrompt(promptNames.PAYMENT_REDIRECT, { policy, enrollment: app.enrollment }),
          );
        }
      });
    });
  }

  return displayPrompts;
};
