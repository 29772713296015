import React from 'react';
import { View, StyleSheet, ScrollView } from 'react-native';
import { Stack } from '@layouts';
import { CatchTheme } from '@uikit/themes/catch';
import { spacing as _oldSpacing } from '@uikit/theme/spacing';
import { useResponsive } from '@uikit/hooks/useResponsive';

type SnapMode = 'paged' | 'guided' | 'none';

interface CarouselProps {
  children?: any;
  itemWidth?: number;
  spacing?: string; // as defined here in theme/spacing.js, should change
  snapMode?: SnapMode;
  fullWidth?: boolean; //if you need this to be the full with on desktop web
}

const Carousel = ({
  children,
  itemWidth,
  spacing = '0',
  snapMode = 'guided',
  fullWidth,
}: CarouselProps) => {
  const { res, isMobile } = useResponsive(fullWidth ? carouselSpacing : normalSpacing);
  const key = isMobile ? 'mobile' : 'desktop';

  const canSnap = !!itemWidth && itemWidth > 0;
  const shouldSnap = !!canSnap && snapMode !== 'none';

  return (
    <View style={res.mobileSnap}>
      <ScrollView
        horizontal
        showsHorizontalScrollIndicator={false}
        snapToInterval={!shouldSnap ? undefined : itemWidth + _oldSpacing[spacing] * 2}
        decelerationRate={!!shouldSnap ? 'fast' : 'normal'}
        style={res.margins}
        contentContainerStyle={[{ padding: CatchTheme.spacing[key].margins }]}
        alwaysBounceVertical={false}
        disableIntervalMomentum={!!shouldSnap && snapMode === 'paged'}
      >
        <Stack horizontal spacing={spacing} style={stackStyle}>
          {children}
        </Stack>
      </ScrollView>
    </View>
  );
};

const stackStyle = { alignItems: 'flex-start' };

const normalSpacing = {
  margins: StyleSheet.create({
    web: {
      paddingHorizontal: 0,
    },
    default: {
      paddingHorizontal: 0,
    },
  }),
  mobileSnap: StyleSheet.create({
    web: {
      margin: -CatchTheme.spacing.mobile.margins,
      width: 'auto',
    },
    default: {
      margin: -CatchTheme.spacing.mobile.margins,
      width: 'auto',
    },
  }),
};

const carouselSpacing = {
  margins: StyleSheet.create({
    web: {
      paddingHorizontal: `max(0px, calc(0.5 * calc(100vw - ${CatchTheme.containers.page}px)))`,
    },
    default: {
      paddingHorizontal: 0,
    },
  }),
  mobileSnap: StyleSheet.create({
    // eslint-disable-next-line react-native/no-unused-styles
    web: {
      marginHorizontal: `min(${-CatchTheme.spacing.desktop.margins}px, calc(-0.5 * calc(100vw - ${
        CatchTheme.containers.page - CatchTheme.spacing.desktop.margins * 2
      }px) ))`,
      width: 'auto',
      marginTop: -CatchTheme.spacing.mobile.margins,
      marginBottom: -CatchTheme.spacing.mobile.margins * 1.5,
    },
    // eslint-disable-next-line react-native/no-unused-styles
    default: {
      marginHorizontal: -CatchTheme.spacing.mobile.margins,
      marginTop: -CatchTheme.spacing.mobile.margins,
      marginBottom: -CatchTheme.spacing.mobile.margins * 2,
      width: 'auto',
    },
  }),
};

export default Carousel;
