import React from 'react';

import { HealthFaqDrawer } from '@common';
import { Env, useCopy } from '@app/utils';
import { ExplainerIntroBlueprint } from '@blueprints';
import { Layout, Toolbar } from '@layouts';
import { Button } from '@uikit';
import Money from '@svg/dollar-2.svg';
import Plan from '@svg/gallery-view.svg';
import Shield from '@svg/shield-tick.svg';
import { useHealthStart } from '@app/hooks';
import { navigate, routes } from '@app/navigate';

/**
 *
 * Handles the health intro view
 *
 * Order of operations:
 * if an enroll id is set on params/route state, use that
 * if any explorer data exists, create enrollment from explorer
 * otherwise, continue to coverage info and upsert there
 *
 */
export const HealthIntro = ({ applicationID }) => {
  const { c } = useCopy('catch.plans.PlanIntroView');
  const { c: c2 } = useCopy('catch.healthExplorer.explorerIntro');

  const { loading, creating, handleNext, foundApp } = useHealthStart({
    defaultRoute: routes.EDE_COVERAGE_INFO,
  });

  const canCreateNew = foundApp && !loading && !Env.isProd;

  return (
    <ExplainerIntroBlueprint
      context="page"
      title={c('healthApplication')}
      color="coverage"
      render="coverage"
      gradient="coverageLight"
      bullets={[
        {
          id: 'bullet1',
          label: c2('estimate'),
          svg: Money,
        },
        {
          id: 'bullet2',
          label: c2('recommendation'),
          svg: Plan,
        },
        {
          id: 'bullet3',
          label: c2('enroll'),
          svg: Shield,
        },
      ]}
      toolbar={
        <Toolbar narrow type="stack">
          {canCreateNew && (
            <Button
              testID="continue"
              wide
              onPress={() => navigate(routes.EDE_COVERAGE_INFO)}
              disabled={loading || creating}
              loading={loading}
              alt
            >
              Create New
            </Button>
          )}
          <Button
            testID="continue"
            wide
            onPress={handleNext}
            disabled={loading || creating}
            loading={loading}
          >
            Continue
          </Button>
        </Toolbar>
      }
    >
      <Layout topSpace>
        <HealthFaqDrawer />
      </Layout>
    </ExplainerIntroBlueprint>
  );
};

export const HealthIntroView = {
  name: 'EDE_INTRO',
  component: HealthIntro,
  options: {
    drawBehindNav: true,
  },
};
