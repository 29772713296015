import { Storage } from '@app/utils';

/**
 * Stores the last used plaid token, uri that they left from Catch
 * This allows us to reinitialize when a user revisits when necessary
 */
export const oauthStorage = {
  setToken: (token) => Storage.setItem('initialLinkToken', token),
  getToken: () => Storage.getItem('initialLinkToken'),
  setUri: (uri) => Storage.setItem('initialUri', uri),
  getUri: () => Storage.getItem('initialUri'),
  setNext: (next) => Storage.setItem('plaidNext', JSON.stringify(next)),
  getNext: () => JSON.parse(Storage.getItem('plaidNext')),
  reset: () => {
    Storage.removeItem('initialLinkToken');
    Storage.removeItem('initialUri');
    Storage.removeItem('plaidNext');
  },
};
