import React, { useMemo } from 'react';
import { HealthApplicationQueryData, useQuery, queries, useMutation, mutations } from '@data';
import { navigate, routes, ScreenDefinition } from '@navigate';
import { useCopy } from '@util';
import { formatPayload, getInitialValues } from './proAssistanceUtils';
import { SplitFormBlueprint } from '@blueprints';
import fields from './proAssistanceFields';

const ProAssistance = ({ applicationID }) => {
  const { c } = useCopy('catch.ede.ProAssistance');

  const { data } = useQuery<HealthApplicationQueryData>(queries.HEALTH_APPLICATION, {
    variables: { applicationID },
    skip: !applicationID,
  });

  const applicationAssistors = useMemo(() => {
    return data?.viewerTwo?.health?.application?.applicationAssistors || [];
  }, [data?.viewerTwo?.health?.application]);

  const [upsert, { loading: upserting }] = useMutation(mutations.UPSERT_HEALTH_APPLICATION);

  const onSubmit = (values) => {
    upsert({ variables: { input: formatPayload({ applicationID, values }) } });
    navigate(routes.EDE_ID_INFO);
  };

  return (
    <SplitFormBlueprint
      loading={!data}
      submitting={upserting}
      title={c('title')}
      subtitle={c('subtitle')}
      formConfig={{
        fields,
        initialValues: getInitialValues({ applicationAssistors }),
        onSubmit,
      }}
    />
  );
};

export const ProAssistanceView: ScreenDefinition = {
  name: 'EDE_PRO_ASSISTANCE',
  component: ProAssistance,
  options: {
    ...SplitFormBlueprint.options,
    title: 'Professional assistance',
  },
};
