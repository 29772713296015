import React, { useEffect, useMemo } from 'react';

import {
  useCopy,
  constants,
  compareElig,
  NO_CHANGE,
  GAINED_CHIP,
  GAINED_MEDICAID,
  LOST_MEDICAID,
  LOST_CHIP,
} from '@app/utils';
import { useDeprecatedMutation, useQuery, HealthApplicationQueryData, queries } from '@data';
import { navigate, open, routes } from '@navigate';
import { Toolbar, Stack, BasicLayout, Layout } from '@layouts';
import { Button, Banner, Link } from '@uikit';

import Check from '@svg/sparkles-2.svg';

const PREFIX = 'catch.ede.CIC.financialAssistance';

/**
 *
 * @todo diff the APTC
 *
 */
const CICPrelimElig = ({ applicationID }) => {
  const { c, $ } = useCopy(PREFIX);

  const { loading, data } = useQuery<HealthApplicationQueryData>(queries.HEALTH_APPLICATION, {
    variables: { applicationID },
    skip: !applicationID,
  });

  const { application, coverageState, parentEligibility, cicEligibility } = useMemo(() => {
    const app = data?.viewerTwo?.health?.application;

    return {
      application: app,
      coverageState: app?.coverageState,
      cicEligibility: app?.eligibility || {},
      parentEligibility: app?.parent?.eligibility || {},
    };
  }, [data]);

  const [remove] = useDeprecatedMutation('removeCICPrefill');

  const state = compareElig(cicEligibility, parentEligibility);

  useEffect(() => {
    if (state === NO_CHANGE) {
      remove({
        variables: {
          id: data?.viewerTwo?.health?.application?.enrollment?.id,
        },
      });
      navigate(routes.CIC_NO_CHANGE);
    }
  }, [state]);

  // If the active policy has financial assistance
  const noPrevAssistance =
    parentEligibility?.totalAptc === 0 &&
    parentEligibility?.csrLevel === 'NONE' &&
    !parentEligibility?.isMedicaid;
  const amount = cicEligibility?.totalAptc;
  const prevAmount = parentEligibility?.totalAptc;
  const hasCsr = cicEligibility?.csrLevel !== 'NONE';

  const chipName = constants.medicaidCHIPNames[coverageState]?.chip;

  const handleSubmit = () => {
    open(routes.EDE_MEMBER_INCOME_LIST, { applicationID: application?.id });
  };

  return (
    <BasicLayout
      title={c(`${state}.title`, { chip: chipName })}
      loading={loading}
      toolbar={
        <Toolbar>
          <Button onPress={handleSubmit} disabled={loading}>
            {noPrevAssistance ? c('apply') : c('next')}
          </Button>
        </Toolbar>
      }
    >
      <Stack>
        {/APTC/.test(state) && (
          <Stack align="center" spacing="2">
            <Layout.Header
              light
              asset={{ svg: Check, size: 'xl', color: 'debit', strokeWidth: 1 }}
              align="center"
              titleSize="page"
              title={`${$(Math.ceil(amount))}/mo`}
              subtitles={[
                c('savingsLabel'),
                c('previousLabel', {
                  amount: $(prevAmount),
                }),
              ]}
            />

            {hasCsr && (
              <Banner
                title={c('csrLabel')}
                subtitle={
                  <>
                    {c('csrCaption')} {<Link>{c('infoLink')}</Link>}
                  </>
                }
              />
            )}
          </Stack>
        )}
        {[GAINED_CHIP, GAINED_MEDICAID].includes(state) && (
          <Banner
            title={c(`medicaidCaption`)}
            subtitle={c(`${state}.message`, { chip: chipName })}
          />
        )}
        {[LOST_CHIP, LOST_MEDICAID].includes(state) && (
          <Banner title={c(`${state}.caption`, { chip: chipName })} subtitle={c('infoLink')} />
        )}
      </Stack>
    </BasicLayout>
  );
};

export const CICPrelimEligView = {
  name: 'CIC_PRELIM_ELIG',
  component: CICPrelimElig,
  options: {},
};
