import React, { useState, useMemo } from 'react';
import { routes, navigate, useSheet, sheets } from '@navigate';
import { loaders } from '@app/config';
import shallow from 'zustand/shallow';
import { Loading } from '@uikit';
import {
  determineCoveredNonApplicantChild,
  determineDeprivationNavigation,
  determineOtherCoverage,
  determineOutsideHousehold,
} from '@navigate/EDENavigationGuard';
import { MultiQuestionSplitFormBlueprint } from '@blueprints';
import {
  queries,
  mutations,
  useQuery,
  useMutation,
  useDeprecatedMutation,
  HealthApplicationQueryData,
  UpsertApplicationMembersResponse,
  UpsertApplicationMembersVars,
} from '@data';
import { handleHealthErrors } from '@util';
import { getInitialValues, formatPayload } from './sepUtils';
import { questions } from './sepFields';
import { SEPIntro } from './SEPIntroView';

const SEP = ({ isGA, applicationID }) => {
  //intro view questions filter subsequent questions we ask
  const [intro, setIntro] = useState(true);
  const [shownQuestions, setShownQuestions] = useState({});

  const { openLoader } = useSheet(
    (state) => ({ openLoader: () => state.open(sheets.LOADER, loaders.HEALTH_LONG_WAIT) }),
    shallow,
  );

  const { data } = useQuery<HealthApplicationQueryData>(queries.HEALTH_APPLICATION, {
    variables: { applicationID },
    skip: !applicationID,
  });

  const [upsert, { loading: submitting }] = useMutation<
    UpsertApplicationMembersResponse,
    UpsertApplicationMembersVars
  >(mutations.UPSERT_APPLICATION_MEMBERS);

  const [ensure, { loading: ensuring }] = useDeprecatedMutation('ensureHealthApplication', {
    onCompleted: ({ members, stateDeprivationRequirementRetained }) => {
      // depending on ensure's response, navigate user to appropriate places
      const isMedicaidPrelim = members.some((d) => d.preliminaryMedicaidStatus === 'YES');
      const isRequestingFinancialAssistance =
        data?.viewerTwo?.health?.application?.screening?.isRequestingFinancialAssistance;
      if (isMedicaidPrelim) {
        navigate(routes.EDE_MEMBER_MEDICAL_BILLS);
      } else if (determineCoveredNonApplicantChild(members, isRequestingFinancialAssistance)) {
        navigate(routes.EDE_DEPENDENTS_CURRENT_COVERAGE);
      } else if (determineOutsideHousehold(members)) {
        navigate(routes.EDE_OUTSIDE_HOUSEHOLD);
      } else if (
        determineDeprivationNavigation(
          members,
          isRequestingFinancialAssistance,
          stateDeprivationRequirementRetained,
        )
      ) {
        navigate(routes.EDE_MEMBER_WORK_HOURS);
      } else if (determineOtherCoverage(members)) {
        navigate(routes.EDE_OTHER_COVERAGE);
      } else {
        navigate(routes.EDE_REVIEW);
      }
    },
    ...handleHealthErrors,
  });

  const handleScreener = async (values) => {
    setIntro(false);

    const hasSomeAnswer = Object.values(values).some((val) => val === 'YES');

    //only want to update all members in case of 'NO' values
    const payload = Object.fromEntries(Object.entries(values).filter((entry) => entry[1] === 'NO'));

    const input = {
      applicationID,
      applicationMembersInput: members.map((member) => ({
        id: member?.id,
        ...payload,
      })),
    };

    if (hasSomeAnswer) {
      setIntro(false);
      setShownQuestions(values);
      upsert({ variables: { input } });
    } else {
      await upsert({ variables: { input } });
      openLoader();
      ensure({ variables: { applicationID } });
    }
  };

  // for QLE questions, we're required to show NOT all household members; only those who are
  // requesting coverage and qualify for QHP and APTC
  const members = useMemo(() => {
    if (data?.viewerTwo?.health?.application) {
      const { applicant, members } = data?.viewerTwo?.health?.application || {};
      const allMembers = members ? [applicant, ...members] : [applicant];
      return allMembers?.filter(
        (m) => m?.preliminaryQHPStatus === 'YES' || m?.preliminaryAPTCStatus === 'YES',
      );
    } else {
      return [];
    }
  }, [data?.viewerTwo?.health?.application?.members]);

  const filteredQuestions = useMemo(() => {
    return questions.filter((question) => shownQuestions[question.name] === 'YES');
  }, [shownQuestions, questions]);

  return intro ? (
    <SEPIntro
      isGA={isGA}
      applicationID={applicationID}
      applicationPhase={data?.viewerTwo?.health?.application?.applicationPhase}
      members={members}
      handleScreener={handleScreener}
    />
  ) : filteredQuestions.length === 0 ? (
    <Loading />
  ) : (
    <MultiQuestionSplitFormBlueprint
      loading={!data}
      submitting={submitting || ensuring}
      questions={filteredQuestions}
      members={members}
      data={data}
      getInitialValues={getInitialValues}
      onBack={() => setIntro(true)}
      onNext={({ members }) => {
        return upsert(formatPayload({ applicationID, members }));
      }}
      onComplete={() => {
        openLoader();
        ensure({ variables: { applicationID } });
      }}
    />
  );
};

export const SEPView = {
  name: routes.EDE_SEP,
  component: SEP,
  options: {
    ...MultiQuestionSplitFormBlueprint.options,
    title: 'Special Enrollment',
  },
};
