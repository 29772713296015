import React from 'react';
import { StyleSheet, View } from 'react-native';

import { BackgroundColor, BorderColor } from '@types';
import { colors } from '../_ui-kit/theme/colors';
import { useTheme } from '../_ui-kit/hooks/useTheme';
import { Gradient, Pressable, UIGradientName } from '@uikit';

interface Props {
  testID?: string;
  children: any;
  gutter?: any;
  checked?: boolean;
  border?: boolean;
  width?: any;
  role?: any;
  style?: any;
  cornerSize?: string;
  shadow?: boolean;
  majorShadow?: boolean;
  color?: BackgroundColor;
  backgroundColor?: BackgroundColor;
  borderColor?: BorderColor;
  hoverColor?: BackgroundColor;
  gradient?: UIGradientName;
  containerStyle?: any;
  onPress?: () => void;
  onHover?: () => void;
  disabled?: boolean;
  clipTopSpace?: boolean; // mark true if the top item in the card is a row (which has its own top padding)
  clipBottomSpace?: boolean; // mark true if the top item in the card is a row (which has its own top padding)
}

const transparent = 'transparent';

const Card = ({
  testID,
  children,
  gutter,
  width,
  role,
  style,
  shadow = true,
  color,
  majorShadow,
  backgroundColor,
  borderColor,
  gradient,
  checked,
  border,
  padded = true,
  onPress,
  containerStyle,
  disabled,
  clipTopSpace,
  clipBottomSpace,
}: Props) => {
  const { theme, themeColors } = useTheme();

  const contentStyles = [
    theme.card,
    theme[width],
    theme.largeCorners,
    clipTopSpace && theme.topSpace1,
    clipBottomSpace && theme.bottomSpace1,
    color && (theme[`${color}Bg`] || { backgroundColor: themeColors[color] }),
    backgroundColor && { backgroundColor: colors[backgroundColor] },
    borderColor && { borderColor: colors[borderColor] },
    checked && { borderColor: themeColors.textColor },
    border && { borderWidth: 1 },
    !border && theme.noBorder,
    style,
  ];

  const innerStyle = padded ? [theme.allSpace2b] : [];

  return (
    <View
      dataSet={{ class: 'card' }}
      style={[
        shadow && theme.cardShadow,
        theme.transition,
        majorShadow && theme.majorShadow,
        theme[`${gutter}Gutter`],
        theme.largeCorners,
        { backgroundColor: transparent },
        containerStyle,
      ]}
    >
      {onPress ? (
        <Pressable
          testID={testID}
          role={role}
          disabled={disabled}
          handleOnPress={onPress}
          style={contentStyles}
        >
          <Gradient gradient={gradient} style={innerStyle}>
            {children}
          </Gradient>
        </Pressable>
      ) : (
        <Gradient gradient={gradient} style={[contentStyles, innerStyle]}>
          {children}
        </Gradient>
      )}
    </View>
  );
};

const _c = StyleSheet.create({ card: { borderColor: 'red' } });

export default Card;
