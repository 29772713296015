import gql from 'graphql-tag';
import access from 'safe-access';

export const MEMBERS_ELIGIBILITY = gql`
  query MembersEligibility($enrollId: ID!) {
    viewer {
      id
      health {
        enrollment(id: $enrollId) {
          id
          ... on HealthInsuranceEnrollment {
            id
            application {
              id
              exchangeEligibleMembers {
                id
                givenName
                familyName
                enrollmentGroupID
              }
              chipEligibleMembers {
                id
                givenName
                familyName
              }
              medicaidEligibleMembers {
                id
                givenName
                familyName
              }
            }
          }
        }
      }
    }
  }
`;

const formatter = (data) => {
  const get = access(data);

  const members = get('viewer.health.enrollment.application.exchangeEligibleMembers') || [];

  const chip = get('viewer.health.enrollment.application.chipEligibleMembers') || [];

  const medicaid = get('viewer.health.enrollment.application.medicaidEligibleMembers') || [];

  const { enrolling, notEnrolling } = members?.reduce(
    (acc, m) => {
      if (!!m?.enrollmentGroupID) {
        return { ...acc, enrolling: [...acc.enrolling, m] };
      } else {
        return { ...acc, notEnrolling: [...acc.notEnrolling, m] };
      }
    },
    { enrolling: [], notEnrolling: [] },
  );

  return {
    enrollingMembers: enrolling,
    notEnrollingMembers: notEnrolling,
    splitMembers: [...chip, ...medicaid],
  };
};

export default {
  document: MEMBERS_ELIGIBILITY,
  formatter,
};
