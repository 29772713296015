import React from 'react';

import { View, StyleSheet, Platform, SafeAreaView } from 'react-native';
import { Layout } from './Layout';
import { getStatusBarHeight } from 'react-native-status-bar-height';
import { useResponsive } from '@uikit/hooks/useResponsive';
import { useTheme } from '@uikit/hooks/useTheme';
import { Button } from '@uikit/components/Button';
import { useDebug } from '@app/hooks/useDebug';
import { Loading, Gradient } from '@uikit';
import { useLayoutContext } from './LayoutContext';
import Back from '@svg/chevron-left.svg';

/**
 * Inspired by iOS UIToolbar:
 *
 * A control that displays one or more buttons along the bottom edge of your interface
 * Handles four "types" of toolbars that correspond with design system
 * This component is in charge of the Buttons width, for that reason, it is
 * preferable to have buttons direct children to the Toolbar itself
 *
 * type="flow" (default):
 * on large devices, renders child buttons as right aligned and fluid width
 * on small devices, renders wide child buttons centered
 *
 * type="update":
 * renders the same as the default flow, but without a border
 * good for updates, like editing user info, etc.
 *
 * type="dialog":
 * on all devices, renders as wide butttons centered, without a border
 * this type is good for use on confirmation/delete action sheets
 *
 * type="stack":
 * renders wide buttons stacked on top of each other, without border
 * can also be used for non-buttons like stacked tocuhable rows
 */

type ToolbarTypes = 'flow' | 'update' | 'dialog' | 'stack';

export interface ToolbarProps {
  loading?: boolean; // sometimes, the actions depend on a result
  children: any;
  noMargins?: boolean;
  style?: any;
  type?: ToolbarTypes;
  narrow?: boolean;
  onBack?: () => void; // when set, we show the default back button
  hasGradient?: boolean;
  fullWidth?: boolean;
}

const Toolbar = ({
  loading,
  type = 'flow',
  onBack,
  noMargins,
  narrow,
  style,
  children,
  hasGradient = true,
  fullWidth = false,
}: ToolbarProps) => {
  const { theme } = useTheme();
  const { showLayout } = useDebug();
  const { isMobile } = useResponsive();
  const { layout, setToolbarHeight, accentColor } = useLayoutContext();
  const actions = React.Children.toArray(children);
  const noBorder = /dialog|update|stack|sheet/.test(type);
  const stack = /stack|sheet/.test(type);
  const renderLeft = !!onBack; //&& !isMobile;
  const showCentered = (isMobile && !renderLeft) || /dialog|stack/.test(type);

  return (
    <SafeAreaView>
      <Gradient
        show={hasGradient}
        type="toolbar"
        angle={180}
        color={`${layout}Transparent`}
        color2={layout}
        style={styles.position}
      >
        <Layout
          nativeID="tb"
          onHeight={(toolbarHeight) => setToolbarHeight(toolbarHeight)}
          style={[
            theme.toolbar,
            styles.container,
            theme.noBorder,
            type === 'sheet' && theme.sheetToolbar,
            showLayout && theme._overlay,
          ]}
        >
          <Layout
            margins
            behavior={Platform.select({ ios: 'position' })}
            keyboardVerticalOffset={95}
            style={[
              theme.row,
              narrow ? theme.miniPage : theme.page,
              theme.centerSelf,
              !!renderLeft && theme.spaceBetween,
              noMargins && styles.noMargins,
              noBorder && styles.noBorder,
              showLayout && theme._overlay,
              style,
            ]}
          >
            {renderLeft && (
              <Button
                onPress={onBack}
                testID="toolbar-back"
                accentColor={`${accentColor}Light`}
                svg={Back}
              />
            )}
            <View
              style={[
                showCentered ? styles.centerAlign : styles.rightAlign,
                !stack && theme.row,
                theme.flex1,
              ]}
            >
              {loading ? (
                <Loading />
              ) : (
                actions.map((action, i) => (
                  <View
                    key={`toolbar-button-${i}`}
                    testID={`toolbar-button-${i}`}
                    style={[
                      showCentered && !stack && theme.flex1,
                      showCentered && theme.alignCenter,
                      i !== 0 && !stack && theme.leftGutter0b,
                      i < actions.length - 1 && !stack && theme.rightGutter0b,
                      i < actions.length - 1 && stack && theme.bottomGutter1b,
                    ]}
                  >
                    {React.isValidElement(action)
                      ? React.cloneElement(action, {
                          full: fullWidth,
                          wide: showCentered,
                          align: 'center',
                        })
                      : action}
                  </View>
                ))
              )}
            </View>
          </Layout>
        </Layout>
      </Gradient>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  position: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    left: 0,
    right: 0,
    paddingTop: Platform.select({
      ios: 30,
      android: 60,
      web: 20,
    }),
  },

  container: {
    ...Platform.select({
      android: {
        paddingTop: 12,
        paddingBottom: getStatusBarHeight(),
      },
      ios: {
        paddingTop: 12,
        paddingBottom: 12 + getStatusBarHeight(),
        marginBottom: -getStatusBarHeight(),
      },
      web: {
        paddingTop: 16,
        paddingBottom: 16,
      },
    }),
  },
  rightAlign: {
    justifyContent: 'flex-end',
  },
  centerAlign: {
    justifyContent: 'center',
  },
  noMargins: {
    paddingHorizontal: 0,
    paddingTop: 0,
  },
});

export default Toolbar;
