import { parseISO, differenceInDays } from 'date-fns';

import { precisionRound } from './format_old/misc';

/**
 * Given the ideal percentage, a max percentage, and total of other plans,
 * computes a min and max percentage, along with suggested amount in that range
 */
export const getPercentageInRange = ({
  idealPercentage, // required: the percentage that would be ideal
  otherPlans = 0, // optional: current plan total, excluding this benefit
  suggestedMax = 100, // optional: a max that our suggestion should stay below
}) => {
  // these are hard ranges for validating percentages
  const range = {
    min: 1, // the minimum is always 1%
    max: 100 - otherPlans, // the plan total shouldn't go above 100%
  };

  // round percentage to nearest int in case passed as decimal
  const percentage = Math.round(idealPercentage);

  // suggested is the lowest of ideal, suggested max, and max range
  const lowest = Math.min(percentage, suggestedMax, range.max);

  // but should always be at or above the range min
  const suggested = lowest < range.min ? range.min : lowest;

  return {
    ...range,
    suggested,
    decimal: suggested / 100,
  };
};

/**
 * Given a percentage and the user's income,
 * calculates both the yearly and monthly contributions
 */
export const getContributionsFromPercentage = ({ percentage, income }) => ({
  yearly: precisionRound((percentage * income) / 100),
  monthly: precisionRound((percentage * income) / 100 / 12),
});

export const fixedAmountPercentage = ({ suggestedAmount, suggestedMax, income, otherPlans }) => {
  const { suggested, decimal, min, max } = getPercentageInRange({
    idealPercentage: (suggestedAmount / income) * 100,
    suggestedMax,
    otherPlans,
  });

  const { yearly, monthly } = getContributionsFromPercentage({
    percentage: decimal,
    income,
  });

  return {
    suggested,
    decimal,
    min,
    max,
    yearly,
    monthly,
  };
};

export const targetAmountPercentage = ({
  target = 0,
  endDate, // expects in iso format like YYYY-MM-DD
  income,
  otherPlans,
}) => {
  const daysUntilDate = differenceInDays(parseISO(endDate), new Date());

  const { suggested, decimal, min, max, outOfRange } = getPercentageInRange({
    idealPercentage: (target / income) * 100 * (365 / daysUntilDate),
    otherPlans,
  });

  const { yearly, monthly } = getContributionsFromPercentage({
    percentage: suggested,
    income,
  });

  return {
    suggested,
    decimal,
    min,
    max,
    yearly,
    monthly,
    outOfRange,
  };
};

const definitions = {
  EMERGENCY_SAVINGS: {
    calculator: fixedAmountPercentage,
    suggestedAmount: 1000,
    suggestedMax: 10,
  },
  HEALTH_EXPENSES: {
    calculator: fixedAmountPercentage,
    suggestedAmount: 1000,
    suggestedMax: 10,
  },
  CUSTOM_SAVINGS: {
    calculator: targetAmountPercentage,
  },
};

/**
 * Calculates the percentages
 * - given a type (benefit type)
 * - the user's income and other plans total
 * - along with any values for input
 *
 * uses the goal-specific calculator
 */
export const calculatePercentage = ({ type, ...rest }) => {
  if (!type) return {};
  const { calculator, ...constants } = definitions[type];
  return calculator({ ...constants, ...rest });
};
