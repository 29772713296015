import React from 'react';

import { Text, Tooltip, Info } from '@uikit';
import { Stack } from '@layouts';

/**
 *  @deprecated use standard patterns like Layout.Header (probably)
 */
const BigQuestion = ({ title, subtitle, tooltip }) => {
  return (
    <>
      <Stack horizontal>
        <Text size="h3">{title}</Text>
        {tooltip && (
          <Tooltip label={tooltip} type="sheet">
            <Info />
          </Tooltip>
        )}
      </Stack>
      {subtitle && (
        <Text size="h4" weight="regular">
          {subtitle}
        </Text>
      )}
    </>
  );
};

export default BigQuestion;
