import React from 'react';

import { Toolbar, BasicLayout } from '@layouts';
import { Button, NumberedBullets } from '@uikit';
import { useCopy } from '@app/utils';
import { navigate, routes } from '@navigate';

/**
 * This screen is reused by different member cut cases
 */
const CICMemberCutIntro = ({ change: cic }) => {
  const { c } = useCopy(`catch.ede.CIC.memberCutIntro`);

  const onSubmit = () => {
    switch (cic) {
      case 'DEATH':
        return navigate(routes.CIC_MEMBER_DEATH);
      case 'CUSTODY':
        return navigate(routes.CIC_MEMBER_CUSTODY);
    }
  };

  return (
    <BasicLayout
      title={c(`title`)}
      toolbar={
        <Toolbar>
          <Button onPress={onSubmit}>{c('next')}</Button>
        </Toolbar>
      }
    >
      <NumberedBullets number={1} text={c('l1')} />
      <NumberedBullets number={2} text={c('l2')} />
      <NumberedBullets number={3} text={c('l3')} />
    </BasicLayout>
  );
};

export const CICMemberCutIntroView = {
  name: routes.CIC_MEMBER_CUT_INTRO,
  component: CICMemberCutIntro,
  options: {},
};
