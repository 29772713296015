import React from 'react';

import { useCopy } from '@app/utils';
import { navigate, routes } from '@navigate';
import { ExplainerIntroBlueprint } from '@blueprints';
import { Toolbar } from '@layouts';
import { Button } from '@uikit';

import Money from '@svg/dollar-2.svg';
import Plan from '@svg/gallery-view.svg';
import Shield from '@svg/shield-tick.svg';

/**
 *
 */
export const HealthExplorerIntro = () => {
  const { c: c2 } = useCopy('catch.healthExplorer.explorerIntro');

  const handleNext = () => {
    navigate(routes.EXPLORER_ZIPCODE);
  };

  return (
    <ExplainerIntroBlueprint
      context="page"
      title={c2('title')}
      color="coverage"
      render="coverage"
      gradient="coverageLight"
      bullets={[
        {
          id: 'bullet1',
          label: c2('estimate'),
          svg: Money,
        },
        {
          id: 'bullet2',
          label: c2('recommendation'),
          svg: Plan,
        },
        {
          id: 'bullet3',
          label: c2('enroll'),
          svg: Shield,
        },
      ]}
      toolbar={
        <Toolbar narrow type="stack">
          <Button testID="continue" wide onPress={handleNext}>
            Continue
          </Button>
        </Toolbar>
      }
    />
  );
};

export const ExplorerIntroView: ScreenDefinition = {
  name: routes.EXPLORER_INTRO,
  component: HealthExplorerIntro,
  options: {
    drawBehindNav: true,
  },
};
