import React from 'react';
import { ViewStyle } from 'react-native';
import { AssetSize } from '@app/types/theme';
import { CatchTheme } from '@uikit/themes/catch';
import { useTheme } from '@uikit/hooks/useTheme';
import { Pressable } from '../../Pressable';

export interface IconSvgProps {
  svgComponent: React.Component;
  size: AssetSize;
  color: string;
  bg: string;
  onPress?: () => void;
  style?: ViewStyle;
  strokeWidth?: number;
  fill?: boolean;
}

export const IconSvg = ({
  svgComponent,
  onPress,
  size = 'sm',
  containerSize,
  color = 'text',
  bg,
  style,
  strokeWidth,
  fill,
}: IconSvgProps) => {
  const _size = CatchTheme.assets[size];
  const _cSize = CatchTheme.assets[containerSize || size];
  const { theme, themeColors } = useTheme();

  var options = {
    color: themeColors[color] || themeColors[color + 'Color'] || color,
    size: _size,
    height: _size,
    width: _size,
    viewBox: `0 0 24 24`,
    strokeWidth,
  };

  if (fill) {
    options.fill = themeColors[color] || themeColors[color + 'Color'] || color;
  }

  return !svgComponent ? (
    <></>
  ) : (
    <Pressable
      handleOnPress={onPress}
      style={[
        { width: _cSize, height: _cSize, alignItems: 'center', justifyContent: 'center', ...style },
        !!bg && [theme.allSpace1, { borderRadius: 99, backgroundColor: themeColors[bg + 'Color'] }],
      ]}
    >
      {React.createElement(svgComponent, options)}
    </Pressable>
  );
};
export default IconSvg;
