import React from 'react';

import { Toolbar, CalloutLayout } from '@layouts';
import { Button } from '@uikit';
import { useCopy } from '@app/utils';
import { close, open, routes } from '@navigate';
import { HealthApplicationQueryData, queries, useDeprecatedMutation, useQuery } from '@data';

const PREFIX = 'catch.ede.CIC.sepKeepOrApply';

const CICNewPlansAvailable = ({ applicationID }) => {
  const { c } = useCopy(PREFIX);

  const [remove] = useDeprecatedMutation('removeCICPrefill');

  const { data } = useQuery<HealthApplicationQueryData>(queries.HEALTH_APPLICATION, {
    variables: { applicationID },
    skip: !applicationID,
  });

  return (
    <CalloutLayout
      render="coverage"
      title={c('title')}
      subtitles={[c('subtitle1'), c('subtitle2')]}
      toolbar={
        <Toolbar type="stack">
          <Button
            testID="explore"
            wide
            alt
            disabled={!data}
            gutter="bottom"
            onPress={() => open(routes.EDE_HOUSEHOLD, { applicationID })}
          >
            {c('explore')}
          </Button>
          <Button
            testID="keep"
            wide
            disabled={!data}
            onPress={() => {
              remove({ variables: { id: data?.viewerTwo?.health?.application?.enrollment?.id } });
              close();
            }}
          >
            {c('keep')}
          </Button>
        </Toolbar>
      }
    />
  );
};

export const CICNewPlansAvailableView = {
  name: routes.CIC_NEW_PLANS_AVAILABLE,
  component: CICNewPlansAvailable,
  options: {
    bg: 'page',
  },
};
