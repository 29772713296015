import React from 'react';
import { FormattedMessage } from 'react-intl';
import Validators, { format, required, email, length } from 'redux-form-validators';

import Env from '@app/utils/env';

import dateValidation from './date';
import dob from './dob';
import { COPY } from './copy';

Validators.formatMessage = function (msg) {
  const props = msg.props || msg;
  return <FormattedMessage {...props} />;
};

Object.assign(Validators.messages, {
  presence: {
    id: 'catch.validations.baseRequire',
    defaultMessage: 'This field is required',
  },
});

export const signature =
  (otherKey, msg = 'Sign your name as it appears below') =>
  // eslint-disable-next-line
  (value) => {
    return value?.replace(/\s/g, '')?.toLowerCase() !== otherKey?.replace(/\s/g, '')?.toLowerCase()
      ? msg
      : null;
  };

export const baseRequire = required({ msg: COPY['baseRequire'] });

export const baseEmail = (value) => email({ msg: COPY['email'] })(value);

export const phoneNumber = (value) =>
  value && !/^[2-9]\d{2}-\d{3}-\d{4,10000}$/i.test(value) ? 'Must be 10 digits' : undefined;

export const zipcode = (value) =>
  value && !/^\d{5}?$/.test(value) ? 'Must be 5 digits' : undefined;

const poBox = (value) =>
  value &&
  /(?:P(?:ost(?:al)?)?[\.\-\s]*(?:(?:O(?:ffice)?[\.\-\s]*)?B(?:ox|in|\b|\d)|o(?:ffice|\b)(?:[-\s]*\d)|code)|box[-\s\b]*\d)/i.test(
    value,
  )
    ? 'PO boxes are invalid'
    : undefined;

const isPositiveNumber = (value) =>
  Math.sign(value) !== 1 ? 'The amount cannot be negative' : undefined;

const moneyValidation = (value) =>
  parseInt(value) >= 1000 ? undefined : 'Please enter your income';

//for testing purposes in dev/stage for Unit we want to be able to enter SSNs with many zeroes, e.g. 000-00-0002, see https://docs.unit.co/simulations/
export const ssnValidation = (value) => {
  const ssnRegex = Env.isProd
    ? !/^(?!(000|666|9))\d{3}-(?!00)\d{2}-(?!0000)\d{4}$/.test(value) ||
      /123-45-6789|219-09-9999|078-05-1120/.test(value)
    : !/^\d{3}-\d{2}-\d{4}$/.test(value);
  return value && !/^\d{3}-\d{2}-\d{4}$/.test(value)
    ? 'Social Security number must be 9 digits'
    : value && ssnRegex
    ? 'Invalid Social Security number'
    : undefined;
};

export const nameValidation = (value) =>
  value && !/^[a-zA-Z][a-zA-Z '-]*$/.test(value)
    ? 'Legal names must start with letters'
    : undefined;

// currently this validation only work on redux forms
export const signatureRedux = (opts) => (val, vals, props) => {
  if (
    val?.replace(/\s/g, '')?.toLowerCase() !==
    props?.[opts?.name]?.replace(/\s/g, '')?.toLowerCase()
  ) {
    return 'Sign your name as it appears below';
  }
};

export const hasOtherCoverages = () => (_, vals) => {
  if (vals?.isEnrolledInHealthCoverage === 'YES') {
    return !vals?.otherCoverages?.length
      ? `Input's with other coverage should have type.`
      : undefined;
  } else {
    return undefined;
  }
};

export const common = {
  // complex
  signature,
  signatureRedux,
  hasProp: hasOtherCoverages,
  required: [baseRequire],
  email: [baseRequire, email({ msg: COPY['email'] })],
  dob: [baseRequire, ...dob],
  date: [baseRequire, dateValidation],
  password: [
    baseRequire,
    format({
      with: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/,
      message: COPY['passwordRequirements'],
    }),
  ],
  address1: [baseRequire, poBox],
  address2: [poBox],
  phoneNumber: [baseRequire, phoneNumber],
  zipcode: [baseRequire, zipcode],
  ssn: [baseRequire, ssnValidation],
  name: [baseRequire, nameValidation],
  amount: [baseRequire, isPositiveNumber],
  moneyAmount: [baseRequire, moneyValidation],
};

export const passwordValidation =
  Env.isProd || Env.isStage
    ? [...common.password, length({ min: 8 })]
    : [...common.required, length({ min: 6 })];

export const noSpecialChars = (value) =>
  /[^A-Za-z0-9]/.test(value) ? 'no special characters and spaces are allowed' : undefined;
