import { BankErrorCode, BankDisconnectType, RelinkMode } from '@types';

export const LINK_MODES: Record<BankDisconnectType, RelinkMode | undefined> = {
  UPDATE_MODE: 'update',
  HARD_RELINK: 'force',
  FI_ACTION: undefined,
  CONTACT_SUPPORT: undefined,
  NOT_SUPPORTED: undefined,
  NONE: undefined,
};

export const BANK_ERROR_CODES: Record<BankErrorCode, BankDisconnectType> = {
  /**
   * Errors that should be handled in plaid update mode
   * - this happens mostly when a user needs to update their credentials
   * - we should open the Plaid link in update mode
   */
  ITEM_LOGIN_REQUIRED: 'UPDATE_MODE',
  INVALID_CREDENTIALS: 'UPDATE_MODE',
  INVALID_MFA: 'UPDATE_MODE',
  INVALID_SEND_METHOD: 'UPDATE_MODE',

  /**
   * Errors that should be handled with hard relinks
   * -in this case, if the user relinks, we delete the old one
   */
  INVALID_UPDATED_USERNAME: 'HARD_RELINK',
  NO_ACCOUNTS: 'HARD_RELINK',
  NO_AUTH_ACCOUNTS: 'HARD_RELINK',
  INSTITUTION_NOT_RESPONDING: 'HARD_RELINK',
  PRIMARY_ACCOUNT_MISSING: 'HARD_RELINK',
  ITEM_30_DAYS_PERSISTENT_ERROR: 'HARD_RELINK',

  /**
   * Some action is required from the user on financial institution side
   * - This can involve adjusting settings or similar with FI
   */
  ITEM_LOCKED: 'FI_ACTION',
  USER_SETUP_REQUIRED: 'FI_ACTION',

  /**
   * These action we're not too sure about..
   * Instead of taking action, we suggest users contact support
   */
  INCORRECT_DEPOSIT_AMOUNTS: 'CONTACT_SUPPORT',
  TOO_MANY_VERIFICATION_ATTEMPTS: 'CONTACT_SUPPORT',

  /**
   * If for some reason the bank/accounts are not supported, let the user know
   */
  ITEM_NOT_SUPPORTED: 'NOT_SUPPORTED',
  MFA_NOT_SUPPORTED: 'NOT_SUPPORTED',
  PRODUCTS_NOT_SUPPORTED: 'NOT_SUPPORTED',

  // These aren't actually disconnect errors
  ITEM_NO_ERROR: 'NONE',
  ITEM_NO_VERIFICATION: 'NONE',
  PRODUCT_NOT_READY: 'NONE',
};
