// createValidator takes in a validation map and returns the validate() function
// that can be used by redux-form to generate any errors
export default function createValidator(validationMap, customOverrides = {}) {
  const schema = {
    ...validationMap,
    ...customOverrides,
  };

  return (values, props) => {
    const errors = {};
    for (let field in schema) {
      const parts = field.split('.');
      let value =
        parts.length > 1 ? parts.reduce((obj, i) => (obj ? obj[i] : obj), values) : values[field];
      // validate recurses in order to create nested error objects for
      // dot notation nested fields
      function validate(name, errs = {}) {
        if (name && name.length) {
          const parts = name.split('.');
          return {
            ...errs,
            [parts[0]]: validate(parts.slice(1).join('.'), errs[parts[0]]),
          };
        }
        return schema[field]
          ?.map((validator) => {
            return validator(value, values, props, field);
          })
          .find((x) => x);
      }

      errors[parts[0]] = validate(parts.slice(1).join('.'), errors[parts[0]]);
    }
    return errors;
  };
}
