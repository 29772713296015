import gql from 'graphql-tag';

const IMPORT_APPLICATION = gql`
  mutation ImportApplication(
    $id: ID!
    $isPriorYear: Boolean
    $enrollmentId: ID
    $coverageYear: Int
  ) {
    importExistingApplication(
      id: $id
      isFromPriorYear: $isPriorYear
      enrollmentId: $enrollmentId
      coverageYear: $coverageYear
    ) {
      id
      status
      applicationContext
      lastUsedRoute
      coverageState
      pathwayType
      enrollment {
        id
      }
      members {
        id
        givenName
      }
    }
  }
`;

export default {
  document: IMPORT_APPLICATION,
};
