import React, { useCallback } from 'react';
import shallow from 'zustand/shallow';
import { routes, sheets, useSheet } from '@navigate';
import { MultiMemberBasicFormBlueprint } from '@app/blueprints';
import { loaders } from '@app/config';
import { HealthSlasherStackScreenProps } from '../HealthSlasherStack';
import { fields } from '../application/incomeConfirm/incomeConfirmFields';
import { getInitialValues, formatPayload } from '../application/incomeConfirm/incomeConfirmUtils';
import { mutations, useDeprecatedMutation, useMutation } from '@app/data';
import { handleHealthErrors } from '@app/utils';

/**
 *
 * Premium Slasher Income Confirm
 * @todo pull in the screen from Application Proper
 *
 */
const HealthSlasherIncomeConfirm = ({
  handleNext,
  loading,
  applicationID,
  allMembers,
  coverageYear,
}: HealthSlasherStackScreenProps) => {
  // @ts-ignore
  const [upsert] = useMutation(mutations.UPSERT_APPLICATION_MEMBERS, handleHealthResponse({}));

  // @ts-ignore
  const [ensure, { loading: ensuring }] = useDeprecatedMutation('ensureHealthApplication', {
    ...handleHealthErrors,
    onCompleted: handleNext,
  });

  const { openLoader } = useSheet(
    (state) => ({ openLoader: () => state.open(sheets.LOADER, loaders.HEALTH_LONG_WAIT) }),
    shallow,
  );

  const onNext = useCallback(
    (values) => {
      // @ts-ignore
      return upsert({
        variables: {
          input: {
            applicationID,
            applicationMembersInput: [formatPayload({ values })],
          },
        },
      });
    },
    [applicationID],
  );

  const onComplete = useCallback(async () => {
    openLoader();
    // @ts-ignore
    await ensure({ variables: { applicationID } });
  }, [ensure]);

  return (
    <MultiMemberBasicFormBlueprint
      loading={loading}
      submitting={ensuring}
      getInitialValues={getInitialValues}
      fields={fields}
      title="Confirm changes"
      subtitle={'Verify the changes below to secure your additional tax credits.'}
      members={allMembers}
      data={{ coverageYear }}
      onNext={onNext}
      onComplete={onComplete}
    />
  );
};

export const HealthSlasherIncomeConfirmView = {
  name: routes.HEALTH_SLASHER_INCOME_CONFIRM,
  component: HealthSlasherIncomeConfirm,
  options: {},
};
