import React from 'react';
import { Image, Platform } from 'react-native';
import { changeIcon } from 'react-native-change-icon';

import { useDeprecatedQuery } from '@data';
import { open, routes } from '@navigate';
import { useCopy } from '@app/utils';
import { TouchableField, Pressable, SimpleRow } from '@uikit';
import { Carousel, Section, Stack } from '@layouts';
import { SettingsLayout, LabelText } from '@common';
import { default as EmailNotifications } from './partials/EmailNotifications';
import { useBiometrics } from '@app/client/utils';

const APP_ICON_SIZE = 72;
const icons = {
  DefaultAppIcon: require('@uikit/icons/appicons/AppIcon.png'),
  DarkAppIcon: require('@uikit/icons/appicons/DarkAppIcon.png'),
  CeriseAppIcon: require('@uikit/icons/appicons/CeriseAppIcon.png'),
  TaxesAppIcon: require('@uikit/icons/appicons/TaxesAppIcon.png'),
  SavingsAppIcon: require('@uikit/icons/appicons/SavingsAppIcon.png'),
  RetirementAppIcon: require('@uikit/icons/appicons/RetirementAppIcon.png'),
  CoverageAppIcon: require('@uikit/icons/appicons/CoverageAppIcon.png'),
};

/**
 * @todo SMS prefs
 */
const AccountSettings = () => {
  const { c } = useCopy('catch.profile.AccountSettings');
  const { c: me } = useCopy('catch.module.me.UserDetailsView');
  const { email, phone, loading } = useDeprecatedQuery('alias');
  const { supported, enabled, toggle } = useBiometrics();

  return (
    <SettingsLayout title="Settings">
      <Stack mobileGap>
        <Section>
          <Stack separatorComponent>
            <TouchableField
              link={!email}
              value={email || me('addInfo')}
              loading={loading}
              label={<LabelText fieldName="EmailField" />}
              onPress={() => open(routes.CHANGE_ALIAS, { type: 'email', alias: email })}
            />
            <TouchableField
              link={!phone}
              value={phone || me('addInfo')}
              loading={loading}
              label={<LabelText fieldName="PhoneNumberField" />}
              onPress={() =>
                open(routes.CHANGE_ALIAS, {
                  type: 'phoneNumber',
                  alias: phone,
                })
              }
            />
          </Stack>

          <Stack separatorComponent>
            {!!supported && Platform.OS !== 'web' && (
              <SimpleRow
                interaction="switch"
                label={`Unlock with ${supported}`}
                selected={enabled}
                selectedByDefault={enabled}
                onPress={toggle}
              />
            )}

            <SimpleRow
              textColor="link"
              label={c('editPasswordBtn')}
              onPress={() => open(routes.CHANGE_PASSWORD)}
            />
          </Stack>
        </Section>
        <Section title={c('emailPrefTitle')}>
          <EmailNotifications />
        </Section>

        {/* {Platform.OS === 'ios' && (
          <Section title="App Icon">
            <Carousel itemWidth={APP_ICON_SIZE} spacing="1">
              {Object.entries(icons).map(([k, v]) => (
                <Pressable key={k} handleOnPress={() => changeIcon(k)}>
                  <Image
                    source={v}
                    resizeMethod="scale"
                    progressiveRenderingEnabled
                    style={{
                      width: APP_ICON_SIZE,
                      height: APP_ICON_SIZE,
                      borderRadius: APP_ICON_SIZE / 4,
                    }}
                  />
                </Pressable>
              ))}
            </Carousel>
          </Section>
        )} */}
      </Stack>
    </SettingsLayout>
  );
};

export const AccountSettingsView = {
  name: 'ACCOUNT_SETTINGS',
  component: AccountSettings,
  options: {
    title: 'Account Settings',
    largeTitle: true,
  },
};
