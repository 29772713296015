import React, { useMemo } from 'react';
import {
  useQuery,
  HealthApplicationQueryData,
  queries,
  getMembers,
  useMutation,
  mutations,
} from '@data';
import {
  determineDeprivationNavigation,
  determineOtherCoverage,
  determineOutsideHousehold,
} from '@navigate/EDENavigationGuard';
import { navigate, routes } from '@navigate';
import { questions } from './dependentsCurrentCoverageFields';
import { getInitialValues, formatPayload } from './dependentsCurrentCoverageUtils';
import { MultiQuestionSplitFormBlueprint } from '@app/blueprints';

const DependentsCurrentCoverage = ({ applicationID }) => {
  const { loading, data } = useQuery<HealthApplicationQueryData>(queries.HEALTH_APPLICATION, {
    variables: { applicationID },
    skip: !applicationID,
  });

  const [upsert, { loading: upserting }] = useMutation(mutations.UPSERT_APPLICATION_MEMBERS);

  const { members, parsedQuestions } = useMemo(() => {
    return {
      members: getMembers(data),
      parsedQuestions: data?.viewerTwo?.health?.application?.uiQuestionsToDisplay,
    };
  }, [data?.viewerTwo?.health?.application]);

  const handleNext = () => {
    if (determineOutsideHousehold(members)) {
      return navigate(routes.EDE_OUTSIDE_HOUSEHOLD);
    } else if (determineDeprivationNavigation(parsedQuestions)) {
      return navigate(routes.EDE_MEMBER_WORK_HOURS);
    } else if (determineOtherCoverage(members)) {
      return navigate(routes.EDE_OTHER_COVERAGE);
    } else {
      return navigate(routes.EDE_REVIEW);
    }
  };

  return (
    <MultiQuestionSplitFormBlueprint
      loading={loading}
      submitting={upserting}
      questions={questions}
      members={members}
      data={{ uiQuestionsToDisplay: parsedQuestions }}
      getInitialValues={(val) => getInitialValues(val)}
      onNext={(values) => upsert(formatPayload({ values, applicationID }))}
      onComplete={handleNext}
    />
  );
};

export const DependentsCurrentCoverageView = {
  name: routes.EDE_DEPENDENTS_CURRENT_COVERAGE,
  component: DependentsCurrentCoverage,
  options: {
    title: 'Dependents coverage',
  },
};
