import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { replace, getRouteFromURL } from '@navigate';
import { launchPlaid, oauthStorage } from '@app/utils';

const OAuthHandler = () => {
  // set these initially in state so that they don't
  // change by the rest of the plaid happenings
  const [oauthOriginalToken] = useState(oauthStorage.getToken());
  const [next] = useState(oauthStorage.getNext());
  const [receivedRedirectUri] = useState(window.location.href);

  /**
   * As soon as plaid is ready, open the link
   * Then, bring the user back to wherever they were before
   * and reset the oauth storage for clean slate on next try
   */
  useEffect(async () => {
    launchPlaid({
      mode: 'create',
      oauthOriginalToken,
      receivedRedirectUri,
      next,
    });

    const uri = oauthStorage.getUri();
    replace(getRouteFromURL(uri));
  }, []);

  // If we don't have an original token, just go home
  if (!oauthOriginalToken) {
    return <Navigate to="/" />;
  }

  return <></>;
};

export default OAuthHandler;
