import { Platform } from 'react-native';

/**
 * @author @aa-io
 * @since 2021-07-13
 * @summary RN supports a subset of HTML autocomplete options for the autoCompleteType prop– this filters out unsupported crash-causing options while keeping the correct props for HTML. Some native behavior is controlled by textContentType.
 * @todo probably a good TS candidate for WebAutocomplete and NativeAutocomplete
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete|HTML autocomplete}
 * @see {@link https://reactnative.dev/docs/textinput#autocompletetype|RN autoCompleteType}
 */

export const handleAutoCompleteType = (type) => {
  if (Platform.OS === 'web') return type;
  else return NativeAutoCompleteTypes.includes(type) ? type : undefined;
};

export const NativeAutoCompleteTypes = [
  'off',
  'username',
  'password',
  'email',
  'name',
  'tel',
  'street-address',
  'postal-code',
  'cc-number',
  'cc-csc',
  'cc-exp',
  'cc-exp-month',
  'cc-exp-year',
];

// for later reference
export const NativeUnsupportedWebTypes = [
  'honorific-prefix',
  'given-name',
  'additional-name',
  'additional-name-initial',
  'family-name',
  'honorific-suffix',
  'nickname',
  'address-line1',
  'address-line2',
  'address-line3',
  'locality',
  'city',
  'administrative-area',
  'state',
  'province',
  'region',
  'country-name',
  'tel-country-code',
  'tel-national',
  'tel-area-code',
  'tel-local',
  'tel-local-prefix',
  'tel-local-suffix',
  'tel-extension',
  'fax',
  'fax-country-code',
  'fax-national',
  'fax-area-code',
  'fax-local',
  'fax-local-prefix',
  'fax-local-suffix',
  'fax-extension',
  'cc-name',
  'cc-given-name',
  'cc-additional-name',
  'cc-family-name',
  'language',
  'bday',
  'bday-year',
  'bday-month',
  'bday-day',
  'org',
  'organization-title',
  'sex',
  'gender-identity',
  'url',
  'photo',
];
