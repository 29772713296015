import React, { useMemo } from 'react';
import { ActionSheetProps } from './types';
import { ScrollView } from 'react-native';
import { Modal } from '../Modal';
import { LayoutContext } from '@app/layouts';

/**
 * On web, action sheet should just use a modal
 */
const ActionSheet: React.FC<ActionSheetProps> = ({
  maxHeight,
  children,
  presentation = 'actionSheet',
  onRequestClose,
  preventClose,
  accentColor,
  ...props
}) => {
  const handleClose = useMemo(() => {
    return preventClose ? () => {} : onRequestClose;
  }, [onRequestClose, preventClose]);

  return (
    <Modal presentation={presentation} onRequestClose={handleClose} {...props}>
      <LayoutContext layout="sheet" accentColor={accentColor} needsScroll={false}>
        <ScrollView alwaysBounceVertical={false}>{children}</ScrollView>
      </LayoutContext>
    </Modal>
  );
};

export default ActionSheet;
