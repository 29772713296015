import gql from 'graphql-tag';
import { goals as goalsFragments } from '../fragments';

const UPSERT_GOAL = gql`
  mutation _UpsertGoal($input: UpsertGoalInput!) {
    upsertGoal(input: $input) {
      id
      slug
      ...Goal
    }
  }
  ${goalsFragments.fullGoal}
`;

export const upsertGoal = {
  document: UPSERT_GOAL,
  refetchQueries: ['Goal', 'Goals'],
};
