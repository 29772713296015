import React from 'react';
import { Text } from '@uikit';
import { TextField } from '@app/forms_old/inputs';
import { safeFormatDate } from '@app/utils';
import { InputProps } from '../types';

interface SignatureInputProps<T> extends InputProps<T> {
  legalName: string;
}

const SignatureInput = React.forwardRef(
  <T extends any>(
    {
      testID,
      name,
      value,
      label,
      placeholder,
      disabled,
      onChange,
      onBlur,
      onKeyPress,
      autoFocus,
      legalName,
      error,
    }: SignatureInputProps<T>,
    ref,
  ) => {
    return (
      <>
        <TextField
          ref={ref}
          testID={testID}
          autoFocus={autoFocus}
          value={value}
          onChangeText={onChange}
          onBlur={onBlur}
          onKeyPress={onKeyPress}
          label="Enter your full legal name to sign"
          placeholder="Signature"
          error={error}
          disabled={disabled}
          fullWidth
          signature
        />
        <>
          <Text weight="medium" size="fp">
            {legalName}
          </Text>
          <Text group color="subtle" size="l">
            {safeFormatDate(new Date())}
          </Text>
        </>
      </>
    );
  },
);

SignatureInput.displayName = 'SignatureInput';
export default SignatureInput;
