import { Route, routes } from './routes';

/**
 * Adding a new stack?
 *
 * Step 1: Add to the StackName type. Follow naming FEATURE_STACK
 * Step 2: Add to the stacks object, e.g. FEATURE_STACK: 'FEATURE_STACK'
 * Step 3: Add to prefixes object (this is implemented for prefixing on web)
 * Step 4: Add child routes to the stackScreens (this allows for nested navigation)
 */

// stack name type
export type StackName =
  | 'AUTH_STACK'
  | 'ONBOARDING_STACK'
  | 'HOME_STACK'
  | 'INCOME_STACK'
  | 'INCOME_SOURCES_STACK'
  | 'TAXES_STACK'
  | 'PLAN_STACK'
  | 'PAYCHECK_STACK'
  | 'COVERAGE_STACK'
  | 'GOAL_SETUP_STACK'
  | 'PERSONAL_PAYROLL_STACK'
  | 'ACCOUNT_SETUP_STACK'
  | 'RETIREMENT_STACK'
  | 'TRANSFER_STACK'
  | 'TAX_PAYMENT_STACK'
  | 'TAX_HISTORY_STACK'
  | 'ACTIVITY_STACK'
  | 'ME_STACK'
  | 'HEALTH_EXPLORER_STACK'
  | 'HEALTH_LINK_STACK'
  | 'HEALTH_APPLICATION_STACK'
  | 'HEALTH_SLASHER_STACK'
  | 'HEALTH_RENEW_STACK'
  | 'CIC_STACK';

// to allow for lookup like stacks.AUTH_STACK
export const stacks: Record<StackName, StackName> = {
  AUTH_STACK: 'AUTH_STACK',
  ONBOARDING_STACK: 'ONBOARDING_STACK',
  HOME_STACK: 'HOME_STACK',
  INCOME_STACK: 'INCOME_STACK',
  TAXES_STACK: 'TAXES_STACK',
  TAX_HISTORY_STACK: 'TAX_HISTORY_STACK',
  PLAN_STACK: 'PLAN_STACK',
  COVERAGE_STACK: 'COVERAGE_STACK',
  PAYCHECK_STACK: 'PAYCHECK_STACK',
  INCOME_SOURCES_STACK: 'INCOME_SOURCES_STACK',
  GOAL_SETUP_STACK: 'GOAL_SETUP_STACK',
  PERSONAL_PAYROLL_STACK: 'PERSONAL_PAYROLL_STACK',
  ACCOUNT_SETUP_STACK: 'ACCOUNT_SETUP_STACK',
  RETIREMENT_STACK: 'RETIREMENT_STACK',
  TRANSFER_STACK: 'TRANSFER_STACK',
  TAX_PAYMENT_STACK: 'TAX_PAYMENT_STACK',
  ACTIVITY_STACK: 'ACTIVITY_STACK',
  ME_STACK: 'ME_STACK',
  HEALTH_EXPLORER_STACK: 'HEALTH_EXPLORER_STACK',
  HEALTH_LINK_STACK: 'HEALTH_LINK_STACK',
  HEALTH_APPLICATION_STACK: 'HEALTH_APPLICATION_STACK',
  HEALTH_SLASHER_STACK: 'HEALTH_SLASHER_STACK',
  HEALTH_RENEW_STACK: 'HEALTH_RENEW_STACK',
  CIC_STACK: 'CIC_STACK',
};

// added to allow for prefixing in web
export const prefixes: Record<StackName, string> = {
  AUTH_STACK: '/auth',
  ONBOARDING_STACK: '/onboarding',
  HOME_STACK: '/',
  INCOME_STACK: '/income',
  TAXES_STACK: '/taxes',
  TAX_HISTORY_STACK: '/tax-history',
  PLAN_STACK: '/plan',
  COVERAGE_STACK: '/coverage',
  PAYCHECK_STACK: '/paycheck',
  INCOME_SOURCES_STACK: '/income-sources',
  GOAL_SETUP_STACK: '/setup/:slug',
  PERSONAL_PAYROLL_STACK: '/personal-payroll',
  ACCOUNT_SETUP_STACK: '/account-setup',
  RETIREMENT_STACK: '/retirement-setup',
  TRANSFER_STACK: '/transfer',
  TAX_PAYMENT_STACK: '/tax-payment',
  ACTIVITY_STACK: '/activity',
  ME_STACK: '/me',
  HEALTH_EXPLORER_STACK: '/health/explorer',
  HEALTH_LINK_STACK: '/health/link',
  HEALTH_APPLICATION_STACK: '/health/application',
  HEALTH_SLASHER_STACK: '/health/slash',
  HEALTH_RENEW_STACK: '/health/renew',
  CIC_STACK: '/coverage-change/:type',
};

/**
 * allows for nested navigation on native
 * note -- these should match the corresponding stack definitions, there is a
 * test to check for mismatches that is helpful
 */
export const stackScreens: Record<StackName, Array<Route>> = {
  HOME_STACK: [routes.HOME],
  INCOME_STACK: [routes.INCOME],
  TAXES_STACK: [routes.TAXES],
  TAX_HISTORY_STACK: [routes.TAX_PAYMENT_HISTORY, routes.TAX_PAYMENT],
  PLAN_STACK: [routes.PLAN, routes.PLAN_DETAIL],
  COVERAGE_STACK: [routes.COVERAGE, routes.COVERAGE_DETAIL],
  TRANSFER_STACK: [routes.TRANSFER_AMOUNT, routes.TRANSFER_CONFIRM],
  ACTIVITY_STACK: [routes.ACTIVITY, routes.EVENT_RECEIPT],
  AUTH_STACK: [
    routes.CONFIRM_PASSWORD,
    routes.FORGOT_PASSWORD,
    routes.LOBBY,
    routes.LOGIN,
    routes.CONFIRM_CODE,
    routes.REGISTER,
  ],
  ONBOARDING_STACK: [routes.WELCOME, routes.ONBOARDING_BIOMETRICS, routes.SET_NOTIFICATIONS],
  PAYCHECK_STACK: [routes.PAYCHECK_INCOME, routes.PAYCHECK_BREAKDOWN, routes.PAYCHECK_SKIPPED],
  INCOME_SOURCES_STACK: [routes.MANAGE_INCOME_SOURCES, routes.INCOME_SOURCE_DETAIL],
  GOAL_SETUP_STACK: [
    routes.GOAL_INTRO,
    routes.GOAL_EMPLOYMENT_TYPE,
    routes.GOAL_ESTIMATED_INCOME,
    routes.GOAL_WORK_STATE,
    routes.GOAL_TAX_FILING_STATUS,
    routes.GOAL_SPOUSE_INCOME,
    routes.GOAL_DEPENDENTS,
    routes.GOAL_ESTIMATOR,
    routes.GOAL_CONFIRM,
  ],
  PERSONAL_PAYROLL_STACK: [
    routes.SETUP_PAYROLL,
    routes.EMPLOYMENT_TYPE,
    routes.ESTIMATED_INCOME,
    routes.WORK_STATE,
    routes.TAX_FILING_STATUS,
    routes.SPOUSE_INCOME,
    routes.DEPENDENTS,
    routes.OTHER_WITHHOLDING,
    routes.SETUP_ESTIMATOR,
    routes.PAYROLL_SUMMARY,
    routes.ADD_BANK_LINK,
  ],
  ACCOUNT_SETUP_STACK: [
    routes.ACCOUNT_SETUP_INTRO,
    routes.ACCOUNT_SETUP_NAME,
    routes.ACCOUNT_SETUP_PHONE,
    routes.ACCOUNT_SETUP_EMAIL,
    routes.ACCOUNT_SETUP_CODE,
    routes.ACCOUNT_SETUP_DOB,
    routes.ACCOUNT_SETUP_ADDRESS,
    routes.ACCOUNT_SETUP_SSN,
    routes.ACCOUNT_SETUP_CONFIRM,
    routes.ACCOUNT_SETUP_DENIED,
  ],
  RETIREMENT_STACK: [
    routes.FINANCIAL_SCREENING,
    routes.FINANCIAL_SCREENING_DENIED,
    routes.INVESTMENT_AGREEMENT,
    routes.RETIREMENT_ACCOUNT_SELECTION,
    routes.RETIREMENT_ACCOUNT_TYPE,
    routes.RETIREMENT_CURRENT_SAVINGS,
    routes.RETIREMENT_PORTFOLIO_SELECTION,
    routes.RETIREMENT_PORTFOLIO_TYPE,
    routes.RETIREMENT_RISK_COMFORT,
    routes.RETIREMENT_RISK_LEVEL,
  ],
  TAX_PAYMENT_STACK: [
    routes.AUTO_TAX_PAYMENT_INTRO,
    routes.TAX_PAYMENT_INTRO,
    routes.TAX_PAYMENT_AMOUNT,
    routes.TAX_PAYMENT_CONFIRM,
    routes.EFTPS_AUTH,
  ],
  ME_STACK: [
    routes.ACCOUNT,
    routes.ACCOUNT_INFO,
    routes.ACCOUNT_SETTINGS,
    routes.LINKED_BANKS,
    routes.STATEMENTS,
    routes.REFERRAL,
  ],
  HEALTH_EXPLORER_STACK: [
    routes.EXPLORER_INTRO,
    routes.EXPLORER_ZIPCODE,
    routes.EXPLORER_APPLICANTS,
    routes.EXPLORER_STATE_HANDOFF,
    routes.STATE_RETURN,
    routes.EXPLORER_HOUSEHOLD,
    routes.EXPLORER_SAVINGS,
    routes.EXPLORER_RISK_TOLERANCE,
    routes.EXPLORER_USAGE,
    routes.EXPLORER_PRESCRIPTIONS,
    routes.EXPLORER_DOCTORS,
    routes.EXPLORER_CHOOSE_PLAN,
    routes.EXPLORER_SEP,
    routes.EXPLORER_SEP_INELIGIBLE,
    routes.EXPLORER_QLE,
  ],
  HEALTH_LINK_STACK: [
    routes.HEALTH_LINK_INTRO,
    routes.HEALTH_LINK_AGREEMENTS,
    routes.HEALTH_LINK_IDENTITY,
    routes.HEALTH_LINK_IMPORT,
  ],
  HEALTH_SLASHER_STACK: [
    routes.HEALTH_SLASHER_INTRO,
    routes.HEALTH_SLASHER_TRIAGE,
    routes.HEALTH_SLASHER_INCOME_ENTRY,
    routes.HEALTH_SLASHER_INCOME_LIST,
    routes.HEALTH_SLASHER_INCOME_DETAILS,
    routes.HEALTH_SLASHER_INCOME_CONFIRM,
    routes.HEALTH_SLASHER_AGREEMENTS,
    routes.HEALTH_SLASHER_HICCUP,
    routes.HEALTH_SLASHER_RESULTS,
    routes.HEALTH_SLASHER_SUCCESS,
  ],
  HEALTH_RENEW_STACK: [
    routes.HEALTH_RENEW_INTRO,
    routes.HEALTH_RENEW_AGREEMENTS,
    routes.HEALTH_RENEW_IDENTITY,
    routes.HEALTH_RENEW_IMPORT,
    routes.HEALTH_RENEW_RESUBMIT,
    routes.HEALTH_RENEW_DETAILS,
    routes.HEALTH_RENEW_PLAN,
    routes.HEALTH_RENEW_SUCCESS,
  ],
  HEALTH_APPLICATION_STACK: [
    routes.EDE_INTRO,
    routes.EDE_COVERAGE_INFO,
    routes.EDE_PRIVACY,
    routes.EDE_HOUSEHOLD,
    routes.EDE_SCREENING,
    routes.EDE_PRO_ASSISTANCE,
    routes.EDE_ID_INFO,
    routes.EDE_IDENTITY,
    routes.EDE_APPLICATION_IMPORT,
    routes.EDE_CONFIRM_APPLICANTS,
    routes.EDE_WINDOW_SHOPPING,
    routes.EDE_MEMBER_INFO,
    routes.EDE_MEMBER_QUESTIONS,
    routes.EDE_IMMIGRATION_DETAILS,
    routes.EDE_MEDICAID_DENIAL,
    routes.EDE_MEMBER_INCOME_LIST,
    routes.EDE_MEMBER_DEDUCTIONS_LIST,
    routes.EDE_MEMBER_INCOME_DETAILS,
    routes.EDE_MEMBER_INCOME_CONFIRM,
    routes.EDE_MEMBER_INCOME_DISCREPANCY,
    routes.EDE_SEP,
    routes.EDE_OUTSIDE_HOUSEHOLD,
    routes.EDE_DEPENDENTS_CURRENT_COVERAGE,
    routes.EDE_MEMBER_MEDICAL_BILLS,
    routes.EDE_MEMBER_WORK_HOURS,
    routes.EDE_OTHER_COVERAGE,
    routes.EDE_REVIEW,
    routes.EDE_AGREEMENTS,
    routes.EDE_RESULTS,
    routes.EDE_SEP_OUT_OF_DATE,
    routes.EDE_SPLIT_ELIGIBILITY,
    routes.EDE_PLANS,
    routes.EDE_APTC_AGREEMENT,
    routes.EDE_ENROLL,
    routes.EDE_SUCCESS,
    routes.EDE_HELP,
    routes.EDE_DMI,
  ],
  CIC_STACK: [
    routes.CIC_MANAGE_COVERAGE_INTRO,
    routes.CIC_CHANGE_SELECTION,
    routes.CIC_BASICS,
    routes.CIC_ADDRESS_ENTRY,
    routes.CIC_NO_CHANGE,
    routes.CIC_STATE_HANDOFF,
    routes.CIC_STATE_PICKUP,
    routes.CIC_CANCEL_POLICY,
    routes.CIC_GOV_HANDOFF,
    routes.CIC_GOV_PICKUP,
    routes.CIC_APP_REVIEW_INTRO,
    routes.CIC_MEMBER_MARRIAGE,
    routes.CIC_MEMBER_CUT_INTRO,
    routes.CIC_MOVE_DATE_ENTRY,
    routes.CIC_MEMBER_DIVORCE,
    routes.CIC_MEMBER_DEATH,
    routes.CIC_MEMBER_CUSTODY,
    routes.CIC_MEMBER_JOB_COVERAGE,
    routes.CIC_MEMBER_STATUS_CHANGE,
    routes.CIC_REMOVING_MEMBERS,
    routes.CIC_POLICY_INVALID,
    routes.CIC_NEW_PLANS_AVAILABLE,
    routes.CIC_NEW_PLAN_REQUIRED,
    routes.CIC_PRELIM_ELIG,
    routes.CIC_MEMBER_CUT,
    routes.CIC_SEP_MIXED,
    routes.CIC_SSN_ENTRY,
    routes.CIC_INCOME_ENTRY,
  ],
};

// looks up the stack based on the actual route (for nested navigation)
export const stackLookup = (route: Route): StackName | false => {
  let matchingStack: StackName | false = false;
  let stackName: StackName;

  for (stackName of Object.keys(stackScreens) as Array<StackName>) {
    if (stackScreens[stackName].includes(route)) {
      matchingStack = stackName;
      break;
    }
  }

  return matchingStack;
};
