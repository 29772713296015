import React, { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { exit, routes, ScreenDefinition } from '@app/navigate';
import { mutations, UpsertGoalResponse, UpsertGoalVars } from '@app/data';

import { RetirementPortfolioSelection } from '@app/pages/plan/retirement/RetirementPortfolioSelectionView';

const RetirementChangePortfolio = ({ slug }) => {
  const [upsert, { loading: submitting }] = useMutation<UpsertGoalResponse, UpsertGoalVars>(
    mutations.UPSERT_GOAL,
  );

  const updateRetirement = useCallback(
    (input) =>
      upsert({
        variables: { input: { slug: 'retirement', ...input } },
      }),
    [],
  );

  return (
    <RetirementPortfolioSelection
      handleNext={() => setTimeout(exit, 500)}
      updateRetirement={updateRetirement}
      submitting={submitting}
      minimal
    />
  );
};

export const RetirementChangePortfolioView: ScreenDefinition = {
  name: routes.RETIREMENT_CHANGE_PORTFOLIO,
  component: RetirementChangePortfolio,
  options: { title: 'Portfolio', layout: 'sheet' },
};
