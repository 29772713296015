import gql from 'graphql-tag';
import access from 'safe-access';
import { goalIsSetup } from '@app/utils';
import { PortfolioFragments } from './portfolio';

const BasicGoal = gql`
  fragment BasicGoal on Goal {
    __typename
    id
    slug
    status
    goalType
    type: goalType
    paycheckPercentage
    pausedEndDate
  }
`;

export const TAX_GOAL = gql`
  fragment TaxGoal on TaxGoal {
    id
    status
    slug
    goalType
    totalBalance
    availableBalance
    autoTax
    name
    paycheckPercentage
    federalPercentage
    statePercentage
  }
`;

export const GOAL = gql`
  fragment Goal on Goal {
    __typename
    id
    slug
    status
    goalType
    name
    type: goalType
    paycheckPercentage
    pausedEndDate
    totalBalance
    pendingBalance
    availableBalance
    ... on RetirementGoal {
      hasUploadedSignature
      externalSavings
      portfolio {
        id
        ...Portfolio
      }
      accountType
      riskLevel
      riskComfort
      wealthAccountID
      totalContributions
      wealthAccount {
        id
        primaryBeneficiaries {
          firstName
          lastName
        }
      }
    }
    ... on TaxGoal {
      state
      autoTax
      filingStatus
      FilingName
      YearOfLastCompletedFiling
      statePercentage
      stateLiability
      federalPercentage
      federalLiability
      AddressOfLastCompletedFiling {
        street1
        street2
        city
        state
        zip
        country
      }
    }
    ... on CustomGoal {
      id
      slug
      status
      goalType
      paycheckPercentage
      icon
      name
      target
      endDate
      pausedEndDate
    }
    ... on PTOGoal {
      plannedTarget
      unplannedTarget
    }
    ... on FamilyLeaveGoal {
      endDate
      numDays
    }
  }
  ${PortfolioFragments.portfolio}
`;

export const GoalsFragments = {
  goal: BasicGoal,
  fullGoal: GOAL,
  taxGoal: TAX_GOAL,
  /**
   * We shouldn't have to add this here, because we should be able to use
   * the full goal fragment instead. However, when upserting goals, we
   * are unable to use the generic Goal. The response must be on the
   * specific goal type. Therefore, this should be kept in sync
   * with the full goal to avoid any confusion
   */
  customGoal: gql`
    fragment CustomGoal on CustomGoal {
      id
      slug
      status
      goalType
      paycheckPercentage
      icon
      name
      target
      endDate
      pausedEndDate
    }
  `,

  transferGoals: gql`
    fragment TransferGoals on Viewer {
      id
      goals {
        id
        slug
        goalType
        type: goalType
        isAccountReady
        pendingBalance
        availableBalance
        totalBalance
        status
        ... on CustomGoal {
          icon
          name
        }
        ... on RetirementGoal {
          accountType
        }
      }
      savingsAccountMetadata {
        isAccountLocked
        isAccountReady
      }
      latestApprovedPaycheck {
        incomeTransaction {
          id
        }
      }
    }
  `,
};

/**
 * Helper function for determining which goals can be kickstarted
 * - the type is one of kickstart goal types
 * - they have ACTIVE status AND
 * - the total balance is 0
 */

export const formatGoal = (goal) => ({
  ...goal,
  isSetup: goalIsSetup(goal),
});

/**
 * formats goals for transfers
 * - creates a list of goals that we can deposit to/withdraw from
 * - used for showing options when transferring
 */
export const formatTransferGoals = (data, options) => {
  const { filteredGoal } = options;
  const get = access(data);
  const rawGoals = get('viewer.goals') || [];
  const savings = get('viewer.savingsAccountMetadata') || {};
  const goals = rawGoals.filter((goal) => goal.id !== filteredGoal?.goal?.id);
  const goalsByType = {};
  const goalsById = {};

  const canTransfer = {
    withdraw: [], // goals that user can withdraw from
    deposit: [], // goals that user can deposit to
  };

  const hasApprovedIncome = !!data?.viewer?.latestApprovedPaycheck?.incomeTransaction?.id;

  // only allow deposits if savings is ready + account not locked
  if (savings.isAccountReady && !savings.isAccountLocked) {
    // this logic should maybe be in useAllowTransfers rather than the formatter for the goal list
    goals.forEach((goal) => {
      const goalReady = goalIsSetup(goal);

      const transferGoal = {
        ...goal,
        canWithdraw:
          goal.goalType !== 'RETIREMENT' &&
          goal.isAccountReady &&
          goal.availableBalance > 0 &&
          goalReady,
        canDeposit: goalReady && hasApprovedIncome,
      };
      goalsByType[goal.goalType] = transferGoal;
      goalsById[goal.id] = transferGoal;
      // append to the can withdraw / deposit arrays
      if (transferGoal.canWithdraw) canTransfer.withdraw.push(transferGoal);
      if (transferGoal.canDeposit) canTransfer.deposit.push(transferGoal);
    });
  }

  return {
    goalsByType,
    goalsById,
    canTransfer,
    retirementGoal: goalsByType?.RETIREMENT,
    hasApprovedIncome,
  };
};
export default {
  ...GoalsFragments,
  formatTransferGoals,
  formatGoal,
};
