import gql from 'graphql-tag';
import {
  PolicyStatusType,
  EnrollmentType,
  KYCStatus,
  IdentityStatus,
  IdentityProvider,
  BankSyncStatus,
  BankErrorCode,
  SavingsProviderType,
  AgreementSet,
  GoalSlug,
  HealthPathwayType,
  KYCNeeded,
  HealthApplicationContext,
  HealthApplicationStatus,
} from '@types';

import { PlanHistorySummaryData, MonthlyIncomeData, GoalsData, PaycheckData } from '../types';
import { ApolloOpenEnrollmentDates, OpenEnrollmentFragment } from '../fragments';
export interface NudgeData {
  id: string;
  isDismissed: boolean;
}

export interface BankAccountData {
  id: string;
  isPrimary: boolean;
}

export interface BankLinkData {
  id: string;
  providerType: string;
  syncStatus: BankSyncStatus;
  errorCode: BankErrorCode;
  bankName: string;
  accounts: BankAccountData[];
  plaidBank: {
    id: string;
    logo: string;
    primaryColor: string;
    url: string;
  };
}

export interface EnrollmentData {
  id: string;
  status: PolicyStatusType;
  year: number;
  enrollmentType: EnrollmentType;
  policy: {
    id: string;
    startDate: string; // YYYY-MM-DD
    endDate: string; // YYYY-MM-DD
    dueDate: Date;
    providerPlan: {
      id: string;
      type: string;
      issuer: {
        id: string;
        name: string;
      };
    };
  };
}

export interface HomeQueryData {
  viewer: {
    id: string;
    goals: Array<GoalsData>;
    taxGoal: {
      id: string;
      slug: GoalSlug;
      autoTax: boolean;
    };
    retirementGoal: {
      id: string;
      slug: GoalSlug;
    };
    user: {
      id: string;
      joinDate: Date;
      email: string;
      supportedPathways: Array<HealthPathwayType>;
      kycSavings: {
        needed: KYCNeeded;
      };
    };
    planHistorySummary: PlanHistorySummaryData;

    paychecks: {
      edges: Array<PaycheckData>;
    };
    nudges: Array<NudgeData>;
    savingsAccountMetadata: {
      kycStatus: KYCStatus;
      isAccountReady: boolean;
      savingsProviderType: SavingsProviderType;
    };
    wealthAccount: {
      id: string;
      folioID: string;
    };
    bankLinks: Array<BankLinkData>;
  };
  viewerTwo: {
    id: string;
    incomeForYear: Array<MonthlyIncomeData>;
    accountStatus: {
      id: string;
      isLocked: boolean;
      isBankingKYCDenied: boolean;
      isBankingReady: boolean;
      isWealthReady: boolean;
    };
    healthExplorerData?: {
      id: string;
      state: string;
      pathwayType?: HealthPathwayType;
    };
    unitIdentity: {
      id: string;
      status: IdentityStatus;
      providerType: IdentityProvider;
    };
    folioIdentity: {
      id: string;
      status: IdentityStatus;
      providerType: IdentityProvider;
    };
    cmsIdentity: {
      id: string;
      status: IdentityStatus;
      providerType: IdentityProvider;
    };
    agreements: AgreementSet;
    health: {
      applications: Array<{
        id: string;

        applicationContext: HealthApplicationContext;
        status: HealthApplicationStatus;
        lastUsedRoute: string;
        coverageState: string;
        coverageYearNumber: number;
        policies: Array<{ id: string }>;
        enrollment: { id: string };
      }>;
    };
    documents: {
      taxDocuments: {
        id: string;
      };
    };
  };
  reference: {
    health: {
      openEnrollmentDates: ApolloOpenEnrollmentDates;
    };
  };
}

export const HomeQuery = gql`
  query HomeQuery($year: Int!, $taxYear: Int!, $prevTaxYear: Int!, $coverageYears: [Int!]) {
    viewer {
      id
      goals {
        id
        slug
        status
        type: goalType
        availableBalance
        totalBalance
      }
      taxGoal: goal(slug: "taxes") {
        id
        slug
        ... on TaxGoal {
          autoTax
        }
      }
      retirementGoal: goal(slug: "retirement") {
        id
        slug
        status
      }
      user {
        id
        joinDate: createdOn
        email
        supportedPathways
        kycSavings {
          needed
        }
      }
      planHistorySummary(period: YTD) {
        grossIncome
      }
      paychecks: incomeTransactionsPaged(
        pagination: { pageNumber: 1, pageSize: 10 }
        incomeAction: [USER_PENDING]
      ) {
        edges {
          id
          ... on IncomeTransaction {
            id
            amount
            date
            status
            source {
              id
              text
              taggedSource {
                id
                name
                logo {
                  url
                }
              }
            }
          }
        }
      }
      nudges {
        id: nudgeIdentifier
        isDismissed
      }
      savingsAccountMetadata {
        kycStatus
        isAccountReady
      }
      bankLinks(input: { includeNotWatched: true }) {
        id
        providerType
        syncStatus
        errorCode
        bankName
        accounts {
          id
          isPrimary
        }
        plaidBank {
          id: bankID
          logo
          primaryColor
          url
        }
      }
    }
    viewerTwo {
      id
      incomeForYear(year: $year) {
        month
        totalIncome
      }
      healthExplorerData {
        id
        state
        pathwayType
      }
      accountStatus {
        id
        isLocked
        isBankingKYCDenied
        isBankingReady
        isWealthReady
      }
      unitIdentity: identity(providerType: UNIT) {
        id
        status
        providerType
      }
      folioIdentity: identity(providerType: FOLIO) {
        id
        status
        providerType
      }
      cmsIdentity: identity(providerType: CMS) {
        id
        status
        providerType
      }
      existingApplicationInfo {
        existingApplications {
          id
        }
      }
      agreements {
        id
        acknowledgedUnitAccountDisclosures
        authorizedAboundEftpsEnrollment
        isPrivacyDataUseAgree
        isPrivacyTruthfulAgree
      }
      health {
        policies(input: { coverageYearNumber: $coverageYears }) {
          id
          policyStatus
          productType
          startDate
          endDate
          dueDate @client
          coverageYear @client
          carrier
          planName
          shorthand @client
          policyNumber
          subscriberID
          exchangeSubscriberID
          enrollmentID
          providerPlan {
            id
            name
            type
            issuer {
              id
              name
              tollFree
            }
          }
        }
        applications(input: { coverageYearNumber: $coverageYears }) {
          id
          status
          applicationContext
          lastUsedRoute
          coverageState
          coverageYearNumber
          pathwayType
          enrollment {
            id
          }
          policies {
            id
            policyStatus
            productType
            startDate
            endDate
            dueDate @client
            carrier
            planName
            policyNumber
            subscriberID
            exchangeSubscriberID
            providerPlan {
              id
              name
              issuer {
                id
                name
                tollFree
              }
            }
          }
          dmis {
            id
            status
            resolveBy
          }
          svis {
            id
            status
            resolveBy
          }
        }
      }
      # // @todo fix
      documents {
        taxDocuments(
          input: { endMonth: 3, endYear: $taxYear, startMonth: 3, startYear: $prevTaxYear }
        ) {
          id
        }
      }
    }
    reference {
      health {
        openEnrollmentDates {
          ...OEDates
        }
      }
    }
  }
  ${OpenEnrollmentFragment}
`;
