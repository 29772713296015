import React from 'react';
import { Image as RNImage } from 'react-native';

interface Props {
  uri?: string;
  img?: any;
  style?: any;
}

const Image = ({ uri, img, style, ...rest }: Props) => {
  const source = uri ? { uri } : img ? img : undefined;
  return <RNImage source={source} style={style} {...rest} />;
};

export default Image;
