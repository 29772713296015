import React, { useState } from 'react';
import { View, StyleSheet, Pressable } from 'react-native'; // eslint-disable-line
import { useSpring, animated } from 'react-spring/native';

import { Text } from './Text';
import { useTheme } from '@uikit/hooks/useTheme';
import { IconSvg } from './Asset';

import Arrow from '@svg/chevron-down.svg';
import { CatchTheme } from '../themes/catch';

const AView = animated(View);

export const Accordion = ({
  openCtrl,
  children,
  button,
  title,
  initOpen,
  small,
  color,
  buttonStyle,
  style,
}) => {
  const { theme } = useTheme();
  const [isOpen, setOpen] = useState(!!initOpen);
  const open = openCtrl ?? isOpen;
  const [height, setHeight] = useState(initOpen ? 1000 : 0);
  function handleLayout({ nativeEvent: { layout } }) {
    setHeight(layout.height);
  }
  const container = useSpring({
    height: open ? height + 61 : 61,
    config: CatchTheme.animation.springConfig,
  });

  const icon = useSpring({
    rotation: open ? '0deg' : '-90deg',
    config: CatchTheme.animation.springConfig,
  });

  const content = useSpring({
    opacity: open ? 1 : 0,
    config: CatchTheme.animation.springConfig,
  });

  return (
    <AView
      style={[
        !isOpen && styles.hidden,
        style,
        color && theme[`${color}Bg`],
        color && theme.xSpace2,
        color && theme.bottomGutter1,
        color && theme.mdCorners,
        { height: container.height },
      ]}
    >
      {button || (
        <Pressable style={[styles.button, buttonStyle]} onPress={() => setOpen(!open)}>
          <Text size={small ? 'fp' : 'lg'} weight={small ? 'semibold' : 'medium'}>
            {title}
          </Text>
          <AView
            style={{
              transform: [{ rotate: icon.rotation }],
              height: 24,
              width: 24,
            }}
          >
            <IconSvg svgComponent={Arrow} size={24} color="subtle" />
          </AView>
        </Pressable>
      )}
      <AView
        onLayout={handleLayout}
        style={[!button && styles.content, { opacity: content.opacity }]}
      >
        {children}
      </AView>
    </AView>
  );
};

const styles = StyleSheet.create({
  hidden: {
    overflow: 'hidden',
  },
  content: {
    paddingBottom: 12,
  },
  button: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 64,
    justifyContent: 'space-between',
  },
});

export default Accordion;
