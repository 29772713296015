import gql from 'graphql-tag';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { Log } from '@app/utils';

import * as QUERIES from './deprecated/queries';
import * as MUTATIONS from './deprecated/mutations';

export const DEFAULT_QUERY = gql`
  query DefaultQuery {
    viewer {
      id
      user {
        id
      }
    }
    viewerTwo {
      id
    }
  }
`;
export const DEFAULT_MUTATION = gql`
  mutation DefaultMutation($input: UpdateUserInput!) {
    updateUserNew(input: $input) {
      user {
        id
      }
    }
  }
`;

export function useData(name, options) {
  const query = QUERIES[name] || { document: DEFAULT_QUERY };
  const mutation = MUTATIONS[name] || { document: DEFAULT_MUTATION };
  const format = (obj) => (query.formatter ? query.formatter(obj) : obj);
  const { debug, skip, lazy, onCompleted, ...config } = options;
  // Query is skipped if it is a mutation or a lazy query
  const {
    data: queryResult,
    loading: fetching,
    error,
  } = useQuery(query.document, {
    ...config,
    skip: skip || lazy || !!MUTATIONS[name],
  });
  const [fetch, { data: lazyData, loading: lazyLoading }] = useLazyQuery(query.document, {
    ...config,
    onCompleted: (result) => {
      // Not sure why but it seems the callback may be triggered
      // at each rerender so we make sure not call if the operation is not lazy
      if (!onCompleted || !QUERIES[name] || !lazy) return;
      const formatted = format(result);
      onCompleted(formatted);
    },
  });
  const [mutate, { loading: mutating, data: mutationResult }] = useMutation(mutation.document, {
    ...config,
    update: mutation.updateCache,
    onCompleted: (result) => {
      if (!onCompleted) return;
      const formatted = format(result);
      onCompleted(formatted);
    },
  });

  if (mutating) Log.debug(`executing mutation: ${name}`);
  if (fetching || lazyLoading) {
    Log.debug(`fetching ${name} via network`);
  }

  const values = format(lazy ? lazyData : queryResult);

  if (debug) {
    Log.debug(values);
  }

  return {
    ...values,
    mutate,
    fetch,
    loading: fetching || mutating || lazyLoading,
    error,
  };
}
