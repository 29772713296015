import React from 'react';
import { View } from 'react-native';
import { Asset } from '../Asset';
import { Text } from '../Text';
import { Layout } from '@layouts';
import { useTheme } from '@uikit/hooks/useTheme';

/**
 * @deprecated
 * in favor of using atomic components directly in the ActionSheet container
 */
const ActionSheetContent = ({ asset, icon, component, title, subtitles, maxWidth, children }) => {
  const { theme } = useTheme();
  const hasComponent = component || icon || asset;
  return (
    <Layout margins>
      <View>
        {hasComponent && (
          <View style={[theme.alignCenter, theme.yGutter4, theme.bottomGutter3]}>
            {component || <Asset size="lg" icon={icon} {...asset} />}
          </View>
        )}
        <View
          style={
            maxWidth && {
              maxWidth,
              marginLeft: 'auto',
              marginRight: 'auto',
            }
          }
        >
          <Text
            align="center"
            size="h4"
            weight="bold"
            style={{ paddingTop: hasComponent ? 0 : 20 }}
          >
            {title}
          </Text>
        </View>
        <View style={theme.bottomGutter3}>
          {subtitles?.map((subtitle, i) => (
            <Text key={i} align="center" style={theme.bottomGutter1b}>
              {subtitle}
            </Text>
          ))}
        </View>
        {children}
      </View>
    </Layout>
  );
};

export default ActionSheetContent;
