import { GoalSlug, GoalType, GoalTypeName } from '@types';

const slugMap: Record<GoalSlug, GoalType> = {
  taxes: 'TAX',
  timeoff: 'PTO',
  retirement: 'RETIREMENT',
  'family-leave': 'FAMILY_LEAVE',
  'health-expenses': 'HEALTH_EXPENSES',
  'emergency-savings': 'EMERGENCY_SAVINGS',
  'custom-goal': 'CUSTOM_SAVINGS',
};

export const slugNameMap: Record<GoalSlug, GoalTypeName> = {
  taxes: 'TaxGoal',
  timeoff: 'PTOGoal',
  retirement: 'RetirementGoal',
  'family-leave': 'FamilyLeaveGoal',
  'health-expenses': 'HealthExpensesGoal',
  'emergency-savings': 'EmergencySavingsGoal',
  'custom-goal': 'CustomGoal',
};

export const goalTitles: Record<GoalSlug, string> = {
  'health-expenses': 'health expenses',
  timeoff: 'time off',
  'family-leave': 'family leave',
  'emergency-savings': 'emergency savings',
  retirement: 'retirement',
};

export const typeMap = {
  TAX: 'tax',
  PTO: 'timeoff',
  RETIREMENT: 'retirement',
  FAMILY_LEAVE: 'familyLeave',
  HEALTH_EXPENSES: 'healthExpenses',
  EMERGENCY_SAVINGS: 'emergencySavings',
  CUSTOM_SAVINGS: 'customGoal',
};

export const slugTypeMap = {
  taxes: 'tax',
  timeoff: 'timeoff',
  retirement: 'retirement',
  'family-leave': 'familyLeave',
  'health-expenses': 'healthExpenses',
  'emergency-savings': 'emergencySavings',
};

/**
 * Converts a slug to goal type
 * Useful for getting type from the wildcard slug
 * Will fallback to custom savings for any non-matches
 *
 * @param slug GoalSlug
 * @returns GoalType
 */
export const slugToGoalType = (slug: GoalSlug): GoalType => {
  // if the slug is defined above, return assocaited goal type
  if (slug in slugMap) {
    return slugMap[slug];
  }

  // otherwise, any wildcards are custom goals
  return 'CUSTOM_SAVINGS';
};

/**
 *
 * @param slug GoalSlug
 * @returns GoalTypeName
 */
export const slugToGoalTypeName = (slug: GoalSlug): GoalTypeName => {
  // if the slug is defined above, return assocaited goal type
  if (slug in slugNameMap) {
    return slugNameMap[slug];
  }
  return 'CustomGoal';
};

/**
 * The reverse of the above function
 * to get a default slug for navigation
 */
export const goalTypeToSlug = (goalType: GoalType): GoalSlug => {
  return Object.keys(slugMap).find((key) => slugMap[key] === goalType) || 'custom-goal';
};

/**
 * Handles what we would consider a goal to be "setup"
 * - Active, paused, and goal met goals have been setup
 */
export const goalIsSetup = (goal) => {
  return /^ACTIVE|PAUSED|GOAL_MET/.test(goal?.status);
};

/**
 * Handles what we would consider a goal to be "setup"
 * - Active, paused, and goal met goals have been setup
 */
export const goalIsStarted = (goal) => {
  return /^ACTIVE|PAUSED|GOAL_MET|DRAFT/.test(goal?.status);
};

/**
 * Given a goal and a minimum, ensures that
 * 1) the goal is setup and
 * 2) has at least a minimum available balance
 */
export const goalHasMinBalance = (goal, minimum) => {
  return goalIsSetup(goal) && goal?.availableBalance > minimum;
};

/**
 * Checks if any goal has been setup
 * - If any of tthe goals are active/paused/goal met,
 * then the user has at least one active goal
 */
export const hasActiveGoal = (goals) => (goals || []).some(goalIsSetup);

/**
 * Checks if any goal has been setup
 * - If any of tthe goals are active/paused/goal met,
 * then the user has at least one active goal
 */
export const hasStartedGoal = (goals) => (goals || []).some(goalIsStarted);

/**
 * Summarizes goal balances in 3 categories: taxes, retirement, and savings (which includes non-tax and non-retirement goals)
 */
export const getAvailableBalances = (goals) => {
  const balances = {
    taxes: 0,
    retirement: 0,
    saved: 0,
  };

  if (!!goals && goals.length > 0) {
    goals.forEach((goal, index) => {
      if (goal?.status === 'ACTIVE' || goal?.status === 'PAUSED' || goal?.status === 'GOAL_MET') {
        if (goal?.type === 'TAX' || goal?.slug === 'taxes') {
          balances.taxes = goal?.availableBalance || 0;
        } else if (goal?.type === 'RETIREMENT' || goal?.slug === 'retirement') {
          balances.retirement = goal?.availableBalance || 0;
        } else if (goal?.type in typeMap || goal?.slug in slugNameMap) {
          balances.saved += goal?.availableBalance || 0;
        }
      }
    });
  }

  return balances;
};

/**
 * Summarizes goal balances in 3 categories: taxes, retirement, and savings (which includes non-tax and non-retirement goals)
 */
export const getTotalBalances = (goals) => {
  const balances = {
    taxes: 0,
    retirement: 0,
    saved: 0,
  };

  if (!!goals && goals.length > 0) {
    goals.forEach((goal, index) => {
      if (goal?.status === 'ACTIVE' || goal?.status === 'PAUSED' || goal?.status === 'GOAL_MET') {
        if (goal?.type === 'TAX' || goal?.slug === 'taxes') {
          balances.taxes = goal?.totalBalance || 0;
        } else if (goal?.type === 'RETIREMENT' || goal?.slug === 'retirement') {
          balances.retirement = goal?.totalBalance || 0;
        } else if (goal?.type in typeMap || goal?.slug in slugNameMap) {
          balances.saved += goal?.totalBalance || 0;
        }
      }
    });
  }

  return balances;
};
