export { calcPovertyLine } from './calcPovertyLine';

export { default as getCopy } from './getCopy';
export { default as getCopyValues } from './getCopyValues';
export { determineSavingsEligibility } from './determineSavingsEligibility';
export { enumCopy } from './enumCopy';
export { formatHealthPlan, formatSortedPlans } from './formatHealthPlan';
export { formatPlanSelection } from './formatPlanSelection';
export { getSviDocTypes, getDmiDocTypes, getDmiDocSubTypes } from './docTypes';

export { renderQuestions } from './renderQuestions';
export { generateMembers } from './memberPayload';
export { formatCostCoverage } from './sortBenefits';

export {
  handleHealthErrors,
  handleHealthResponse,
  handleHealthErrorsWithProps,
  getHealthErrorHandlers,
} from './handleHealthErrors';

export {
  getDisplayEnrollment,
  getActiveEnrollments,
  getActiveOrPendingEnrollments,
  sortEnrollmentsByYear,
  getCurrentYearEnrollment,
  getNextYearEnrollment,
  sortEnrollmentsForOE,
  sortDisplayEnrollments,
} from './sortEnrollments';

/** @todo move these */
export { default as constants } from './constants';

/** @todo move these */
export { getFullName, formatPayload } from './format';

export * from './compareElig';

export const checkCMS = (email) => {
  if (!email) return false;

  if (
    email?.includes('lexi+cms') ||
    email?.includes('@cms.hhs.gov') ||
    email?.includes('@dutyfirst.com') ||
    email?.includes('@bah.com') ||
    email?.includes('steph+') ||
    email?.includes('cms') ||
    email?.includes('chtesting') ||
    email?.includes('null+2fa') ||
    /^\+2fa/.test(email)
  ) {
    return true;
  }

  return false;
};
