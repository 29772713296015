import { ReactElement } from 'react';
import { Copy } from '@types';

interface SimpleButtonProps {
  testID: string;
  key?: string;
  title: Copy;
  onPress?: () => void;
  type?: string;
  disabled?: boolean;
}

export type ToolbarType =
  | 'hidden'
  | 'continue'
  | 'skipOrContinue'
  | 'nextOrPrev'
  | Array<SimpleButtonProps>
  | ReactElement;

export const toolbarTypes: Record<string, ToolbarType> = {
  hidden: 'hidden',
  continue: 'continue',
  skipOrContinue: 'skipOrContinue',
  nextOrPrev: 'nextOrPrev',
};
