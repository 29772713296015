import gql from 'graphql-tag';
import {
  GoalStatus,
  GoalSlug,
  GoalType,
  GoalTypeName,
  RiskLevel,
  RiskComfort,
  CustomGoalIcons,
  RetirementAccountTypes,
} from '@types';

export interface UpsertGoalVars {
  input: {
    slug: GoalSlug;
    goalType?: GoalType;
    name?: string;
    status?: GoalStatus;
    paycheckPercentage?: number;
    federalPercentage?: number;
    statePercentage?: number;
    externalSavings?: number;
    riskLevel?: RiskLevel;
    riskComfort?: RiskComfort;
    portfolioID?: string;
    accountType?: RetirementAccountTypes;
    icon?: CustomGoalIcons;
  };
}

export interface UpsertGoalResponse {
  upsertGoal: {
    id: string;
    slug?: GoalSlug;
    goalType?: GoalType;
    name?: string;
    status?: GoalStatus;
    paycheckPercentage?: number;
    federalPercentage?: number;
    statePercentage?: number;
    totalBalance?: number;
    availableBalance?: number;
    pendingBalance?: number;
    pausedEndDate?: Date | null;
    externalSavings?: number;
    riskLevel?: RiskLevel;
    riskComfort?: RiskComfort;
    portfolioID?: string;
    accountType?: RetirementAccountTypes;
    __typename: GoalTypeName;
  };
}

/**@todo type the fragments */
export const UpsertGoal = gql`
  mutation UpsertGoal($input: UpsertGoalInput!) {
    upsertGoal(input: $input) {
      id
      slug
      goalType
      name
      status
      paycheckPercentage
      totalBalance
      availableBalance
      pendingBalance
      pausedEndDate
      __typename
      ... on RetirementGoal {
        hasUploadedSignature
        externalSavings
        portfolio {
          id
          ... on Portfolio {
            id
            name
            contents {
              name
              ticker
              weight
            }
          }
        }
        accountType
        riskLevel
        riskComfort
        wealthAccountID
        totalContributions
      }
      ... on TaxGoal {
        state
        autoTax
        federalPercentage
        statePercentage
        filingStatus
        FilingName
        YearOfLastCompletedFiling
        AddressOfLastCompletedFiling {
          street1
          street2
          city
          state
          zip
          country
        }
      }
      ... on CustomGoal {
        id
        slug
        status
        goalType
        paycheckPercentage
        icon
        name
        target
        endDate
        pausedEndDate
      }
      ... on PTOGoal {
        plannedTarget
        unplannedTarget
      }
      ... on FamilyLeaveGoal {
        endDate
        numDays
      }
    }
  }
`;
