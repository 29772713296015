import React, { useMemo } from 'react';

import { useCopy, breakdown, capitalizeAll } from '@util';
import { LineItem, Progress, Button } from '@uikit';
import { Stack, BasicLayout, Toolbar, Layout } from '@layouts';
import { routes } from '@navigate';

export const RetirementPortfolioSheet = ({ portfolio, onSubmit }) => {
  const { c, p } = useCopy('catch.module.retirement.PortfolioSelectionView');
  const { c: cWealth } = useCopy('catch.wealth');

  const items = useMemo(() => {
    if (!portfolio) return [];
    return [...portfolio?.contents]?.sort((a, b) => b.weight - a.weight);
  }, [portfolio, portfolio?.contents]);

  const { stocks, bonds } = breakdown[portfolio?.name] || {};

  return (
    <BasicLayout
      title={c(`${portfolio?.name}.title`)}
      subtitles={[
        `${stocks}% ${cWealth('stocks')} / ${bonds}% ${cWealth('bonds')}`,
        c(`${portfolio?.name}.description`, {
          esg: portfolio?.isESG ? c('esgClause') : '',
        }),
      ]}
      toolbar={
        <Toolbar>
          <Button testID="submit" inherit onPress={onSubmit}>
            Choose
          </Button>
        </Toolbar>
      }
    >
      <Layout bottomSpace>
        <Stack spacing="1b">
          {items.map((item) => (
            <Stack spacing="+gap" key={item.ticker}>
              <Progress progress={item.weight / 100} />
              <LineItem left={capitalizeAll(item?.name)} right={p(item.weight / 100)} />
            </Stack>
          ))}
        </Stack>
      </Layout>
    </BasicLayout>
  );
};

export const RetirementPortfolioTypeView = {
  name: routes.RETIREMENT_PORTFOLIO_TYPE,
  component: RetirementPortfolioSheet,
  options: {},
};

export default RetirementPortfolioSheet;
