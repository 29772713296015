import React, { useMemo } from 'react';
import { View } from 'react-native';
import { Text } from '@uikit/components/Text';
import Field from './Field';
import FieldArray from './FieldArray';
import { useDependentValues } from '../utils/useDependentValues';
import { dynamic } from '../utils/dynamicConfig';
import { useApplyCopy } from '@util/language/useApplyCopy';

/**
 * FieldElement is an element in a form config
 * It can be one of: fields array, field section, or individual field
 */
const FieldElement = ({ element, form, isFirst, isLast, grouped, remove, values }) => {
  // creates object of
  const dependentValues = useDependentValues({
    dependencies: element.dependencies,
    control: form.methods.control,
    prefix: element.prefix,
  });

  const applyCopy = useApplyCopy(element.copy || 'catch');

  // check if we should be displaying the section at all
  const show = useMemo(() => {
    return dynamic.display(element, dependentValues);
  }, [element?.display, dependentValues]);

  const required = useMemo(() => {
    return dynamic.required(element, dependentValues);
  }, [element?.required, dependentValues]);

  //allows for dynamic labeling on forms
  const label = useMemo(() => {
    return dynamic.label(element, dependentValues);
  }, [dependentValues, element.label]);

  const sublabel = useMemo(() => {
    return dynamic.sublabel(element, dependentValues);
  }, [dependentValues, element.sublabel]);

  const help = useMemo(() => {
    return dynamic.help(element, dependentValues);
  }, [dependentValues, element.help]);

  if (!show) {
    return null;
  }

  if (element.type === 'array') {
    return (
      <FieldArray
        name={element.name}
        subfields={element.subfields}
        dependencies={element.dependencies}
        disabled={form.disableFields}
        grouped={element.grouped}
        required={required}
        label={applyCopy(label)}
        sublabel={applyCopy(sublabel)}
        help={applyCopy(help)}
        optional={element.optional}
        form={form}
        Component={element.Component}
        Footer={element.Footer}
        isFirst={isFirst}
        isLast={isLast}
      />
    );
  }

  if (element.type === 'section') {
    return (
      <View style={{ paddingBottom: 16 }}>
        <Text size="h3">{applyCopy(element.title)}</Text>
        {element.subtitle && <Text>{applyCopy(element.subtitle)}</Text>}
        {element.fields.map((field, i) => (
          <FieldElement
            key={field.name}
            form={form}
            element={field}
            isFirst={isFirst}
            isLast={false}
          />
        ))}
      </View>
    );
  }

  return (
    <Field
      key={element.name}
      config={element}
      control={form.methods.control}
      isFirst={isFirst}
      isLast={false}
      setCurrentField={form.setCurrentField}
      setLocation={form.setLocation}
      setCurrentHelpText={form.setCurrentHelpText}
      goToNext={() => {}}
      autoSubmit={form.autoSubmit}
      hideLabel={form.hideLabels}
      submitForm={form.submitForm}
      reset={() => form.methods.resetField(element.name)}
      disabled={form.disableFields}
      containerStyle={grouped ? undefined : { paddingBottom: 24 }} // applies spacing between fields
      remove={remove}
      values={values}
    />
  );
};

export default FieldElement;
