import React from 'react';
import { Layout } from '@layouts';
import { OptionGroup } from '@uikit';
import { Option } from '@types';
import { InputProps } from '../types';

interface RadioInputProps<T> extends InputProps<T> {
  options: Array<Option>;
}

const RadioInput = <T extends any>({
  testID,
  name,
  label,
  value,
  onChange,
  onBlur,
  onKeyPress,
  options,
  disabled,
}: RadioInputProps<T>) => {
  return (
    <Layout bottomSpace>
      <Layout.Header title={label} titleColor="secondary" titleSize="smol" weight="regular" />
      <OptionGroup
        testID={testID}
        name={name}
        input={undefined}
        options={options}
        selectedOption={value}
        horizontal
        onPress={(val) => onChange(val)}
        multi
        disabled={disabled}
        onKeyPress={onKeyPress}
      />
    </Layout>
  );
};

export default RadioInput;
