function isWorkDetailsFilled(data) {
  const workType = data?.viewer?.user?.workType;
  const filingStatus = data?.viewer?.user?.filingStatus;
  const incomeState = data?.viewer?.incomeState;
  const estimated1099Income = data?.viewer?.income?.estimated1099Income;
  const estimatedW2Income = data?.viewer?.income?.estimatedW2Income;
  const spouseIncome = data?.viewer?.spouseIncome;

  // checks if all the data was saved in order to decide whether to skip
  // work details forms or not

  const hasWorkType = !!workType;
  const hasFilingStatus = !!filingStatus;
  const hasIncomeState = !!incomeState;
  const has1099Income = estimated1099Income !== null;
  const hasW2Income = estimatedW2Income !== null;
  const hasSpouseIncome = spouseIncome !== null;
  const hasAnyIncome = has1099Income || hasW2Income;
  const hasAllIncome = has1099Income && hasW2Income;
  const isMixed = workType === 'WORK_TYPE_DIVERSIFIED';
  const hasIncome = isMixed ? hasAllIncome : hasAnyIncome;
  const hasHousehold = filingStatus === 'MARRIED' ? hasSpouseIncome : true;

  return (
    hasWorkType &&
    hasFilingStatus &&
    hasIncomeState &&
    hasIncome &&
    hasHousehold
  );
}
export default isWorkDetailsFilled;
