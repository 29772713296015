import { renderQuestions, toDate, safeFormatDate } from '@app/utils';

export const handleApplicationData = (application, members) => {
  const applicationPhase = application?.applicationPhase;

  const parsedMembers =
    members?.map((m) => ({
      ...m,
      uiQuestionsToDisplay: m.uiQuestionsToDisplay,
      incomeSources: m.incomeSources?.map((src) => ({
        type: src.type,
        employerName: src.employerName,
        incomeAmount: src.incomeAmount,
        incomeFrequencyType: src.incomeFrequencyType,
        employerPhoneNumber: src.employerPhoneNumber,
        employerAddress: src.employerAddress,
        averageWeeklyWorkDays: src.averageWeeklyWorkDays,
        averageWeeklyWorkHours: src.averageWeeklyWorkHours,
        selfEmploymentIncomeDescription: src.selfEmploymentIncomeDescription,
        incomeDescription: src.incomeDescription,
        expirationDate: safeFormatDate(toDate(src.expirationDate), 'MM/dd/yyyy'),
        otherIncomeDescription: src.otherIncomeDescription,
        jobIncomeExplanationRequired: src.jobIncomeExplanationRequired,
        incomeStateExchangeFields: src.incomeStateExchangeFields,
      })),
      deductions: m.deductions?.map((ded) => ({
        incomeSourceType: ded.incomeSourceType,
        incomeAmount: ded.incomeAmount,
        incomeFrequencyType: ded.incomeFrequencyType,
        otherDeductionDescription: ded.otherDeductionDescription,
      })),
      annualTaxIncome: {
        ...m.annualTaxIncome,
        incomeAmount: m.annualTaxIncome?.incomeAmount,
        annualIncomeExplanationRequired: m.annualTaxIncome?.annualIncomeExplanationRequired,
        attestedAnnualizedAPTCIndividualIncomeAmount:
          m.annualTaxIncome?.attestedAnnualizedAPTCIndividualIncomeAmount,
      },

      // only renders the question as part of ARP changes for all members
      // if qID 300 is within the uiQuestionsToDisplay array,
      // which also checks whether they have already upserted UNEMPLOYMENT as income type
      renderUnemploymentQuestion: m?.uiQuestionsToDisplay?.[300],
    })) || [];

  const uiQuestionsToDisplay = application?.uiQuestionsToDisplay;

  const {
    parsedQuestions,
    renderMemberQuestions,
    renderImmigrationQuestions,
    renderUnemploymentQuestion,
  } = renderQuestions({ uiQuestionsToDisplay, applicationPhase });

  return {
    application,
    members: parsedMembers,
    parsedQuestions,
    renderMemberQuestions,
    renderImmigrationQuestions,
    renderUnemploymentQuestion,
  };
};
