import gql from 'graphql-tag';

export interface UnwatchAccountVars {
  accountID: string;
}

export interface UnwatchAccountResponse {
  unsetWatchAccount: {
    id: string;
    isWatched: boolean;
  };
}

export const UnwatchAccount = gql`
  mutation UnwatchAccount($accountID: ID!) {
    unsetWatchAccount(id: $accountID) {
      id
      isWatched
    }
  }
`;
