import gql from 'graphql-tag';

import { Log } from '@app/utils';
import { user, work } from '../queries';

const UPDATE_USER = gql`
  mutation _UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      email
      nickname
      givenName
      familyName
      dob
      ssn
      filingStatus
      phoneNumber
      workType
      workState
      jobTitle
      estimated1099Income
      estimatedW2Income
      spouseIncome
      avatarImage
      signupContext
      legalAddress {
        street1
        street2
        city
        state
        zip
        country
        coordinates {
          latitude
          longitude
        }
      }
      isControlPerson
      isFirmAffiliated
      isPoliticallyExposed
      kycSavings {
        status
        needed
      }
      residency {
        isUSCitizen
        isResidentAlien
        citizenshipCountry
      }
    }
  }
`;

const updateCache = (cache, { data }) => {
  const query = cache.readQuery({ query: user.document });
  const updateUser = data?.updateUser;
  Log.debug('update user query cache');
  try {
    cache.writeQuery({
      query: user.document,
      data: {
        viewer: {
          ...query.viewer,
          user: {
            ...query.viewer.user,
            employment: {
              ...query.viewer.user.employment,
            },
            legalAddress: {
              street1: updateUser?.legalAddress?.street1,
              street2: updateUser?.legalAddress?.street2,
              city: updateUser?.legalAddress?.city,
              state: updateUser?.legalAddress?.state,
              zip: updateUser?.legalAddress?.zip,
            },
            kycSavings: {
              ...query.viewer.user.kycSavings,
              ...updateUser?.kycSavings,
            },
            workType: updateUser?.workType,
            workState: updateUser?.workState,
            filingStatus: updateUser?.filingStatus,
            estimated1099Income: updateUser?.estimated1099Income,
            estimatedW2Income: updateUser?.estimatedW2Income,
            spouseIncome: updateUser?.spouseIncome,
            givenName: updateUser?.givenName,
            familyName: updateUser?.familyName,
            nickname: updateUser?.nickname,
            jobTitle: updateUser?.jobTitle,
            phoneNumber: updateUser?.phoneNumber,
            dob: updateUser?.dob,
          },
        },
      },
    });
  } catch (e) {
    Log.debug(e);
  }
};

export default {
  document: UPDATE_USER,
  updateCache,
  refetchQueries: [{ query: work.document }],
};
