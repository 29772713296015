import React from 'react';
import { View } from 'react-native';
import { useTheme } from '../hooks/useTheme';
import { CatchTheme } from '../themes/catch';

interface Props {
  color?: string;
  height?: number;
}

export const HR = ({ color, height = 1, mobileSnap }: Props) => {
  const { theme } = useTheme();

  return (
    <View
      style={[
        theme.hr,
        color && theme[`${color}Bg`],
        mobileSnap && { marginHorizontal: -CatchTheme.spacing.mobile.margins },
        { height },
      ]}
    />
  );
};

export default HR;
