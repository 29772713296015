import React, { useCallback, useMemo, useState } from 'react';

import { useQuery, queries, AccountSetupQueryData } from '@app/data';
import { benefitColorLookup } from '@app/utils/color';
import { useHealthLink } from '@app/hooks';
import {
  stacks,
  Stack,
  StackComponent,
  StackDefinition,
  useCurrentRoute,
  routes,
  navigate,
  start,
  Route,
} from '@navigate';

import { AccountSetupIntroView } from './accountSetup/AccountSetupIntroView';
import { AccountSetupDeniedView } from './accountSetup/AccountSetupDeniedView';
import { AccountSetupConfirmView } from '../shared/GoalConfirmView';
import { AccountSetupCodeView } from './accountSetup/AccountSetupCodeView';
import { AccountSetupPhoneView } from './accountSetup/AccountSetupPhoneView';
import { AccountSetupEmailView } from './accountSetup/AccountSetupEmailView';
import {
  AccountSetupAddressView,
  AccountSetupDOBView,
  AccountSetupNameView,
  AccountSetupSSNView,
} from './accountSetup/AccountSetupInfoView';

const config: StackDefinition = {
  stackName: stacks.ACCOUNT_SETUP_STACK,
  options: {
    layout: 'page',
    navMode: 'flow',
    presentation: 'largeSheet',
    buttons: { help: true },
    accentColor: ({ slug }) => (slug ? benefitColorLookup({ slug }) : 'brand'),
  },
  screens: [
    AccountSetupIntroView,
    AccountSetupNameView,
    AccountSetupPhoneView,
    AccountSetupEmailView,
    AccountSetupCodeView,
    AccountSetupDOBView,
    AccountSetupAddressView,
    AccountSetupSSNView,
    AccountSetupConfirmView,
    AccountSetupDeniedView,
  ],
};

interface NavigationUpdates {
  newPhone?: string;
  newEmail?: string;
  isRetirement?: boolean;
  isHealth?: boolean;
  hasApplications?: boolean;
  nextRoute?: Route;
}

/**
 * screen order, for reference
 *
 * LINK_ORDER = [
 *   routes.ACCOUNT_SETUP_DOB,
 *   routes.ACCOUNT_SETUP_SSN,
 *   routes.ACCOUNT_SETUP_NAME,
 *   // routes.ACCOUNT_SETUP_PHONE,
 *   // routes.ACCOUNT_SETUP_EMAIL,
 *   routes.ACCOUNT_SETUP_CODE,
 *   routes.ACCOUNT_SETUP_ADDRESS,
 *   routes.HEALTH_LINK_IDENTITY,
 * ]
 *
 * ONBOARDING_ORDER = [
 *   routes.ACCOUNT_SETUP_NAME, // always navigates to phone
 *   // routes.ACCOUNT_SETUP_PHONE,
 *   // routes.ACCOUNT_SETUP_EMAIL,
 *   routes.ACCOUNT_SETUP_CODE,
 *   routes.ACCOUNT_SETUP_DOB,
 *   routes.ACCOUNT_SETUP_ADDRESS,
 *   routes.ACCOUNT_SETUP_SSN,
 *   routes.ACCOUNT_SETUP_CONFIRM,
 * ]
 *
 */

const AccountSetupStack: StackComponent = () => {
  const [requestedPhone, setRequestedPhone] = useState('');
  const [requestedEmail, setRequestedEmail] = useState('');
  const route = useCurrentRoute();

  const { loading, data } = useQuery<AccountSetupQueryData>(queries.ACCOUNT_SETUP, {
    fetchPolicy: 'cache-first',
  });

  const { lookup, isInExchange, agree, loading: healthLinkLoading } = useHealthLink();

  const { isPhoneVerified, isEmailVerified } = useMemo(() => {
    return {
      isPhoneVerified: data?.viewer.isAliasMatchAndVerified.phoneMatchAndVerified,
      isEmailVerified: data?.viewer.isAliasMatchAndVerified.emailMatchAndVerified,
    };
  }, [data?.viewer?.isAliasMatchAndVerified]);

  const { phoneNumber, email, hasName, hasSSN } = useMemo(() => {
    return {
      phoneNumber: data?.viewer?.user?.phoneNumber,
      email: data?.viewer?.user?.email,
      hasDOB: !!data?.viewer?.user?.dob,
      hasName: !!data?.viewer?.user?.givenName,
      hasSSN: !!data?.viewer.user.ssn,
    };
  }, [data?.viewer?.user]);

  const handleNext = useCallback(
    (updates?: NavigationUpdates) => {
      switch (route) {
        case routes.ACCOUNT_SETUP_INTRO:
          navigate(routes.ACCOUNT_SETUP_NAME);
          break;
        case routes.ACCOUNT_SETUP_NAME:
          if (!isPhoneVerified) {
            navigate(routes.ACCOUNT_SETUP_PHONE);
          } else if (!isEmailVerified) {
            navigate(routes.ACCOUNT_SETUP_EMAIL);
          } else if (updates?.isHealth) {
            navigate(routes.ACCOUNT_SETUP_ADDRESS);
          } else {
            navigate(routes.ACCOUNT_SETUP_DOB);
          }
          break;
        case routes.ACCOUNT_SETUP_PHONE:
          setRequestedPhone(updates?.newPhone || '');
          navigate(routes.ACCOUNT_SETUP_CODE, {
            phoneNumber: updates?.newPhone,
            aliasType: 'phone',
          });
          break;
        case routes.ACCOUNT_SETUP_EMAIL:
          setRequestedEmail(updates?.newEmail || '');
          navigate(routes.ACCOUNT_SETUP_CODE, {
            email: updates?.newEmail,
            aliasType: 'email',
          });
          break;
        case routes.ACCOUNT_SETUP_CODE:
          // navigation order is different for link vs onboarding
          navigate(updates?.isHealth ? routes.ACCOUNT_SETUP_ADDRESS : routes.ACCOUNT_SETUP_DOB);
          break;
        case routes.ACCOUNT_SETUP_DOB:
          navigate(updates?.isHealth ? routes.ACCOUNT_SETUP_SSN : routes.ACCOUNT_SETUP_ADDRESS);
          break;
        case routes.ACCOUNT_SETUP_ADDRESS:
          navigate(
            !hasSSN
              ? routes.ACCOUNT_SETUP_SSN
              : updates?.isRetirement
              ? routes.RETIREMENT_CURRENT_SAVINGS
              : updates?.isHealth
              ? routes.HEALTH_LINK_IDENTITY
              : routes.ACCOUNT_SETUP_CONFIRM,
          );
          break;
        case routes.ACCOUNT_SETUP_SSN:
          if (updates?.isRetirement) {
            navigate(routes.RETIREMENT_CURRENT_SAVINGS);
          }
          if (updates?.isHealth) {
            if (updates?.canIDProof) {
              navigate(routes.HEALTH_LINK_IDENTITY);
            } else {
              navigate(routes.ACCOUNT_SETUP_NAME);
            }
          } else {
            navigate(routes.ACCOUNT_SETUP_CONFIRM);
          }
          break;
        case routes.ACCOUNT_SETUP_CONFIRM:
          start('MAIN_TABS', {});
          break;
        default:
          console.log('Route not handled', route);
          break;
      }
    },
    [route, isPhoneVerified, hasSSN, hasName, phoneNumber, isInExchange],
  );

  const stackData = useMemo(() => {
    return {
      loading,
      healthLinkLoading,
      isPhoneVerified,
      requestedPhone,
      phoneNumber,
      isEmailVerified,
      requestedEmail,
      email,
      handleNext,
      lookup,
      isInExchange,
      agree,
      hasSSN,
    };
  }, [
    loading,
    healthLinkLoading,
    isPhoneVerified,
    isEmailVerified,
    requestedPhone,
    requestedEmail,
    phoneNumber,
    email,
    handleNext,
    lookup,
    isInExchange,
    agree,
    hasSSN,
  ]);

  return (
    <Stack
      stackName={config.stackName}
      screens={config.screens}
      options={config.options}
      data={stackData}
    />
  );
};

AccountSetupStack.config = config;
export default AccountSetupStack;
