/**
 * IRA contribution limits
 * @see https://www.irs.gov/retirement-plans/plan-participant-employee/retirement-topics-ira-contribution-limits
 */

// Contributions for those under 50
const ANNUAL_CONTRIBUTION_LIMIT = {
  2019: 6000,
  2020: 6000,
  2021: 6000,
  2022: 6000,
  2023: 6500,
};

// Contributions for those under 50
const ANNUAL_CONTRIBUTION_LIMIT_OVER_50 = {
  2019: 7000,
  2020: 7000,
  2021: 7000,
  2022: 7000,
  2023: 7500,
};

export const getAnnualContributionLimit = (age = 33) => {
  const currentYear = `${new Date().getFullYear()}`;

  // no limit for those over age 70.5
  // ok boomer
  if (age > 70) return 10000000000000; // arbitrarily high number

  if (age >= 50) {
    return ANNUAL_CONTRIBUTION_LIMIT_OVER_50[currentYear];
  }

  return ANNUAL_CONTRIBUTION_LIMIT[currentYear];
};

/**
 * @todo update these right before 2023 because it's not "yeared"
 * _("versioned" but for years)_
 */
const ROTH_LIMITS = {
  DEFAULT: 140000,
  MARRIED_FILING_JOINTLY: 208000,
  MARRIED_FILING_SEPARATELY: 10000,
};

const ROTH_INCOME_LIMITS = {
  SINGLE: ROTH_LIMITS['DEFAULT'],
  MARRIED: ROTH_LIMITS['MARRIED_FILING_JOINTLY'],
  MARRIED_SEPARATELY: ROTH_LIMITS['MARRIED_FILING_SEPARATELY'],
  HEAD: ROTH_LIMITS['DEFAULT'],
};

const ROTH_WARNING_LEVELS = {
  DEFAULT: 125000,
  MARRIED_FILING_JOINTLY: 198000,
  MARRIED_FILING_SEPARATELY: 5000,
};

const ROTH_WARNING_LEVEL_MAP = {
  SINGLE: ROTH_WARNING_LEVELS['DEFAULT'],
  MARRIED: ROTH_WARNING_LEVELS['MARRIED_FILING_JOINTLY'],
  MARRIED_SEPARATELY: ROTH_WARNING_LEVELS['MARRIED_FILING_SEPARATELY'],
  HEAD: ROTH_WARNING_LEVELS['DEFAULT'],
};

export const getRothIncomeLimit = (filingStatus) => {
  return ROTH_INCOME_LIMITS[filingStatus];
};

export const getRothIncomeWarningLevel = (filingStatus) => {
  return ROTH_WARNING_LEVEL_MAP[filingStatus];
};
