import React, { useState } from 'react';

import { Narrow, Stack, Layout } from '@layouts';
import { LineItem, Gradient, Text, CatchTheme } from '@uikit';
import { useDeprecatedMutation } from '@data';
import { routes, navigate, replace } from '@navigate';
import Phone from '@svg/phone.svg';
import Checkmark from '@svg/checkmark.svg';
import ShieldCross from '@svg/shield-cross.svg';
import { StyleSheet } from 'react-native';
import { Linking } from 'react-native';
import { useResponsive } from '@uikit/hooks/useResponsive';
import { useHealthHandoff } from '@hooks';
import { HiccupBlueprint } from '@app/blueprints';
import { useCopy } from '@util';

/**
 * @deprecated
 * Handles ID proofing that requires additional proofing
 * Possible states include:
 *
 * 1) PENDING_FARS (needs to call experian)
 * 2) GOOD (shows identity verified screen)
 * 2) DENIED (should redirect to hc.gov)
 */

const EXPERIAN_HOURS = {
  Weekdays: '8:30am - Midnight EST',
  Saturday: '10am - 8pm EST',
  Sunday: '11am - 7pm EST',
};

const PHONE_NUMBER = '8665785409';

const IdVerify = ({ enrollId, applicantID, referenceNumber, status, handleStatus, handleExit }) => {
  if (!enrollId) {
    navigate(routes.HEALTH_RESUME);
  }

  const [loading, setLoading] = useState(null);

  const { isMobile } = useResponsive();

  const { c } = useCopy('catch.plans.PlanIdentityFailureView');

  const handlePhoneCall = () => {
    if (isMobile) {
      Linking.openURL(`tel:${PHONE_NUMBER}`);
    }
  };

  const [confirmVerifiedOffline] = useDeprecatedMutation('confirmVerifiedOffline', {
    onCompleted: ({ status }) => {
      if (status === 'GOOD') {
        setLoading('good');
        setTimeout(() => handleStatus({ status }), 3000);
      } else if (status === 'DENIED') {
        setLoading(null);
        handleStatus({ status });
      } else {
        setLoading('unchanged');
        setTimeout(() => setLoading(null), 3000);
        handleStatus({ status });
      }
    },
  });

  const confirm = () => {
    setLoading('loading');
    confirmVerifiedOffline(applicantID ? { variables: { applicantID } } : undefined);
  };

  const { handoff } = useHealthHandoff('HEALTHSHERPA', enrollId, {
    coverageYear: 2023 /** @todo */,
    onHandoff: () => {
      replace(routes.COVERAGE, { enrollId });
    },
  });

  const states = {
    PENDING_FARS: {
      title: "Couldn't verify your identity",
      icon: Phone,
      content:
        'Please call the Experian help desk and give them the code below to verify your identity.',
      code: referenceNumber,
      actions: isMobile
        ? [
            { label: 'Call Experian', onAction: handlePhoneCall },
            { label: 'Done', onAction: confirm, color: 'bgGray' },
          ]
        : [{ label: 'Done', onAction: confirm, color: 'bgGray' }],
    },
    GOOD: {
      title: 'Identity verified',
      icon: Checkmark,
      content: "Nothing to see here. We've already verified your identity, so you're good to go.",
      actions: [
        { label: 'Continue application', onAction: () => handleStatus({ status: 'GOOD' }) },
      ],
    },
    DENIED: {
      title: "You'll need to apply through our partner",
      icon: ShieldCross,
      content:
        'Unfortunately, it doesn’t look like we’ll be able to verify your identity. You’ll need to continue your application using our preferred partner.',
      actions: [
        { label: 'Apply now', onAction: handoff },
        { label: 'Done', onAction: handleExit, color: 'bgGray' },
      ],
    },
  };

  return (
    <HiccupBlueprint
      loading={loading}
      asset={{ svg: states[status]?.icon, gradient: 'coverage' }}
      title={states[status]?.title}
      subtitles={[states[status]?.content]}
      actions={states[status]?.actions}
      toolbarTitle={!isMobile && status === 'PENDING_FARS' && <Text>Call (866) 578-5409</Text>}
    >
      {status === 'PENDING_FARS' && (
        <>
          <Layout margins topSpace bottomSpace>
            <Gradient gradient="brandLight" style={styles.gradientStyle}>
              <Text align="center" loading={loading} size="lg">
                {referenceNumber}
              </Text>
            </Gradient>
          </Layout>
          <Layout topSpace bottomSpace>
            <Narrow>
              <Stack topSpace separatorComponent spacing="2">
                <Text align="center" size="fp">
                  {c('experianHours')}
                </Text>
                <Stack spacing="1">
                  {Object.entries(EXPERIAN_HOURS).map(([day, hour], idx) => (
                    <LineItem key={idx} left={day} right={hour} />
                  ))}
                </Stack>
              </Stack>
            </Narrow>
          </Layout>
        </>
      )}
    </HiccupBlueprint>
  );
};

const styles = StyleSheet.create({
  gradientStyle: {
    padding: 12,
    borderRadius: CatchTheme.corners.sm,
  },
});

export default IdVerify;
