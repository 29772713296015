import React, { useMemo } from 'react';
import { useCopy, createLogger } from '@app/utils';
import {
  mutations,
  useMutation,
  useQuery,
  HealthApplicationQueryData,
  queries,
  getMembers,
} from '@data';
import { navigate, routes } from '@navigate';
import { BasicFormBlueprint } from '@app/blueprints';
import { Card } from '@app/layouts';

const Log = createLogger('CiC:MemberStatus');

const CICMemberStatusChange = ({ applicationID, screenStep, change }) => {
  const isLaterPhase = screenStep === 'memberStatusChange';

  const fieldName =
    screenStep === 'memberFutureCoverageLoss'
      ? 'isWillLoseCoverageNext60Days'
      : 'isLostCoverageLast60Days';

  const { c } = useCopy(`catch.ede.CIC.${screenStep}`);

  const { loading, data } = useQuery<HealthApplicationQueryData>(queries.HEALTH_APPLICATION, {
    variables: { applicationID },
    skip: !applicationID,
  });

  const initialValues = useMemo(
    () => ({
      members: getMembers(data)?.map((m) => ({
        id: m?.id,
        legalName: m?.legalName,
      })),
    }),
    [data],
  );

  const [report, { loading: reporting }] = useMutation(mutations.REPORT_HEALTH_ENROLLMENT_CHANGES);

  const onSubmit = async ({ members }) => {
    try {
      const result = await report({ variables: { input: { memberInputs: members } } });

      // This selection includes the filer
      if (isLaterPhase) {
        navigate(routes.CIC_GOV_HANDOFF, { members });
      } else {
        const enrollment = result?.data?.reportHealthEnrollmentChanges?.insuranceEnrollment;

        navigate(routes.CIC_APP_REVIEW_INTRO, {
          change: screenStep,
          applicationID: enrollment?.applicationID,
        });
      }
    } catch (e) {
      Log.debug(e);
    }
  };

  return (
    <BasicFormBlueprint
      loading={loading}
      submitting={reporting}
      title={c(`title`, { change })}
      formConfig={{
        initialValues,
        fields: [
          {
            name: 'members',
            type: 'array',
            Component: ({ children }) => children,
            Footer: () => null,
            subfields: [
              {
                name: fieldName,
                type: 'person',
                bare: !isLaterPhase,
                dependencies: ['legalName'],
                label: ({ legalName }) => legalName,
                secondary: {
                  unchecked: '',
                  checked: '',
                },
              },

              {
                name: 'whenLostCoverage',
                type: 'date',
                format: 'YYYY-MM-DD',
                dependencies: ['legalName', fieldName],
                display: (values) => isLaterPhase && values[fieldName],
                label: c('dateLabel'),
                required: true,
              },
            ],
          },
        ],
        onSubmit,
      }}
    />
  );
};

export const CICMemberStatusChangeView = {
  name: routes.CIC_MEMBER_STATUS_CHANGE,
  component: CICMemberStatusChange,
  options: {},
};
